import React from 'react';
import "../../resources.css";
import {ResourceRoute} from '../../resource-routing';
import {Link} from "react-router-dom";
import NewsHeadings from "./common-action/news-heading";
import NewsSubscription from "./common-action/subscripbe-section";

class NewsIssue54 extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            pageEnabled: false,
        }

    }

    render() {
        return (
          <div className="newdesigned_issue_details">
            <NewsHeadings
              buttondDetail="Web3 & Blockchain"
              categoryType="web3"
              description="This Database Is Finally Holding AI Accountable | Vice"
            />
            <div className="newsissuedetailed">
              <div className="detailed-common">
                {/* <div className="back-to-common">
                    <span className="back-to-icon-hold">
                        <img src="/assets/img/resource/back-to-new.svg"  alt="back to new"></img>
                    </span>
                    <Link  to={ResourceRoute.NEWSLETTER}><span className="back-to-link">Newsletter</span></Link>
                </div> */}

                <div className="news-letter whats-on-hold">
                  <div className="issue-main-head">
                    <h4>What’s on?</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.theguardian.com/technology/2020/nov/27/new-uk-tech-regulator-to-limit-power-of-google-and-facebook"
                      >
                        <h4>
                          New UK tech regulator to limit power of Google and
                          Facebook| The Guardian
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        A new tech regulator will work to limit the power of
                        Google, Facebook and other tech platforms, the
                        government has announced, in an effort to ensure a level
                        playing field for smaller competitors and a fair market
                        for consumers. Under the plans, the Competition and
                        Markets Authority (CMA) will gain a dedicated Digital
                        Markets Unit, empowered to write and enforce a new code
                        of practice on technology companies which will set out
                        the limits of acceptable behaviour.
                      </p>
                    </div>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://cointelegraph.com/news/polish-city-becomes-first-to-adopt-ethereum-blockchain-for-emergency-services"
                      >
                        <h4>
                          Polish city becomes first to adopt Ethereum blockchain
                          for emergency services | Coin Telegraph
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Olsztyn, Poland is reportedly the first city in the
                        world to leverage Ethereum’s blockchain to aid the
                        provision of emergency services, offering yet another
                        tangible use case for distributed ledger technology.
                        Olsztyn has completed a successful trial run of
                        SmartKey, a bridging technology that connects blockchain
                        with physical assets, to aid in police, fire and
                        ambulance services.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="news-letter top-picks-hold">
                  <div className="issue-main-head">
                    <h4>Top Picks</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.vice.com/en/article/m7agjq/this-database-is-finally-holding-ai-accountable"
                      >
                        <h4>
                          This Database Is Finally Holding AI Accountable | Vice
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        The Artificial Intelligence Incident Database (AIID) is
                        a crowdsourced platform with intentions to wrangle in
                        the Wild West of AI. “Fool me once, shame on me; fool me
                        twice shame on you,” comes to mind, as the platform is
                        being used to document and compile AI failures so they
                        won’t happen again. A self-defined “systematized
                        collection of incidents where intelligent systems have
                        caused safety, fairness, or other real-world problems,”
                        the AIID’s foundation lies in creating a repository of
                        articles about different times AI has failed in
                        real-world applications.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="news-letter other-reads-hold">
                  <div className="issue-main-head">
                    <h4>Other Reads</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.wired.co.uk/article/ghana-ewaste-dump-electronics"
                      >
                        <h4>
                          Your old electronics are poisoning people at this
                          toxic dump in Ghana | Wired{" "}
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        A vast dump in central Accra is the final resting place
                        for e-waste from all over the world, but it takes a
                        serious toll on local residents. In Ghana, second-hand
                        refurbished phones and laptops, which can be bought for
                        a fraction of the original price, are hugely popular.
                        However, the shorter life-spans of these second-hand
                        electronics mean they become disposable quickly and end
                        up in places like Agbogbloshie. Eighty-five percent of
                        e-waste in Africa is due to such domestic consumption,
                        highlighting the difficulties in effectively regulating
                        the flow of global flow of electronics.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <NewsSubscription categoryType="Web3" />
          </div>
        );
    }

}

export default NewsIssue54;
