import React from 'react';
import "../../resources.css";
import {ResourceRoute} from '../../resource-routing';
import {Link} from "react-router-dom";
import NewsHeadings from "./common-action/news-heading";
import NewsSubscription from "./common-action/subscripbe-section";

class NewsIssue16 extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            pageEnabled: false,
        }

    }

    render() {
        return (
          <div className="newdesigned_issue_details">
            <NewsHeadings
              buttondDetail="Design"
              categoryType="design"
              description="Seven UX/UI Design Trends For Mobile Apps To Look Out For In 2020 | Forbes"
            />

            <div className="newsissuedetailed">
              <div className="detailed-common">
                {/* <div className="back-to-common">
                    <span className="back-to-icon-hold">
                    <img src="/assets/img/resource/back-to-new.svg"  alt="bact to new"></img>
                    </span>
                    <Link  to={ResourceRoute.NEWSLETTER}><span className="back-to-link">Newsletter</span></Link>
                </div> */}

                <div className="news-letter whats-on-hold">
                  <div className="issue-main-head">
                    <h4>What’s on?</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://cointelegraph.com/news/blockchain-technology-partners-unveil-smart-contracts-on-amazon-aws"
                      >
                        <h4>
                          Blockchain Technology Partners unveil smart contracts
                          on Amazon AWS | Coin Telegraph
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Customers can now integrate smart contracts on Amazon
                        Web Services’ Quantum Ledger Database, or QLDB, thanks
                        to tech company Blockchain Technology Partners.
                        Blockchain Technology Partners, or BTP, unveiled general
                        availability for its DAML incorporation, accessible
                        through its Sextant for DAML platform, allowing those
                        working with Amazon’s QLDB to harness smart contracts on
                        the platform, according to a Feb. 25 statement provided
                        to Cointelegraph.
                      </p>
                    </div>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://fortune.com/2020/02/25/amazon-cashierless-store-seattle/"
                      >
                        <h4>
                          Amazon opens its first cashier-less supermarket in
                          Seattle | Fortune
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Amazon is aiming to kill the supermarket checkout line.
                        The online retailing giant is opening its first
                        cashier-less supermarket, the latest sign that Amazon is
                        serious about shaking up the $800 billion grocery
                        industry. At the new store, opening Tuesday in Seattle,
                        shoppers can grab milk or eggs and walk out without
                        checking out or opening their wallets. Shoppers scan a
                        smartphone app to enter the store. Cameras and sensors
                        track what's taken off shelves. Items are charged to an
                        Amazon account after leaving.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="news-letter top-picks-hold">
                  <div className="issue-main-head">
                    <h4>Top Picks</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.forbes.com/sites/forbestechcouncil/2020/02/26/seven-uxui-design-trends-for-mobile-apps-to-look-out-for-in-2020/#1a96886b2473"
                      >
                        <h4>
                          Seven UX/UI Design Trends For Mobile Apps To Look Out
                          For In 2020 | Forbes
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        With or without 5G, we are going to see apps that are
                        lighter by design and more flexible. Elements that are
                        more data-intensive, such as immersive imagery, can be
                        stored on a server, while the lighter elements can be
                        stored locally on a phone or other device. Going into
                        2020, the changes with app design will be both
                        philosophical and technological. You'll need to plan
                        accordingly so your business can take advantage of the
                        changing tide.
                      </p>
                    </div>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://sdtimes.com/test/still-testing-like-its-1999/"
                      >
                        <h4>Still testing like its 1999? | SD Times</h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Over the last 20 years, we have seen massive changes to
                        how we design, develop, and deploy applications; from
                        monolithic applications to microservices, desktop
                        applications and static web applications to highly
                        interactive web and mobile applications. The changes
                        happened incrementally but the cumulative impact has
                        been dramatic
                      </p>
                    </div>
                  </div>
                </div>
                <div className="news-letter other-reads-hold">
                  <div className="issue-main-head">
                    <h4>Other Reads</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://fortune.com/2020/02/23/5g-manufacturing-automotive-health-care-industries/"
                      >
                        <h4>
                          5G will transform smartphones—but it won’t stop there
                          | Fortune
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Though the coronavirus outbreak forced the cancelation
                        of Mobile World Congress, one of the technology
                        industry’s biggest annual events, in Barcelona next
                        week, mobile innovation marches onward. Today we stand
                        on the cusp of 5G, the new generation of mobile
                        broadband rolling out globally. 5G smartphones will
                        allow consumers to download a movie in less than one
                        minute, browse the web 10 times faster, experience
                        life-like virtual and augmented reality, and stream 4K
                        video the same way users stream audio today. But while
                        5G smartphones will be remarkable, focusing only on the
                        5G smartphone user experience is limiting the
                        technology’s true potenti
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <NewsSubscription categoryType="Design" />
          </div>
        );
    }

}

export default NewsIssue16;
