import React from 'react';
import "../../resources.css";
import {ResourceRoute} from '../../resource-routing';
import {Link} from "react-router-dom";
import NewsHeadings from "./common-action/news-heading";
import NewsSubscription from "./common-action/subscripbe-section";

class NewsIssue38 extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            pageEnabled: false,
        }

    }

    render() {
        return (
          <div className="newdesigned_issue_details">
            <NewsHeadings
              buttondDetail="FinTech"
              categoryType="fintech"
              description="Cryptodollars Are A Good Start, But The U.S. Needs A Blockchain Policy | Forbes"
            />
            <div className="newsissuedetailed">
              <div className="detailed-common">
                {/* <div className="back-to-common">
                    <span className="back-to-icon-hold">
                    <img src="/assets/img/resource/back-to-new.svg"  alt="bact to new"></img>
                    </span>
                    <Link  to={ResourceRoute.NEWSLETTER}><span className="back-to-link">Newsletter</span></Link>
                </div> */}

                <div className="news-letter whats-on-hold">
                  <div className="issue-main-head">
                    <h4>What’s on?</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.coindesk.com/china-aims-to-be-the-worlds-dominant-blockchain-power-with-help-from-google-amazon-and-microsoft"
                      >
                        <h4>
                          China Aims to Be the World’s Dominant Blockchain Power
                          – With Help From Google, Amazon and Microsoft | Coin
                          Desk
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        A state-backed blockchain infrastructure project from
                        China aims to be the dominant internet services provider
                        for decentralized applications (dapps). Its first-mover
                        advantage is significant, as are the geopolitical risks.
                        The project, called the Blockchain-based Service Network
                        (BSN), is expanding globally as U.S.-China relations
                        become increasingly tense. BSN is a Chinese
                        state-sanctioned blockchain project, but few may realize
                        the network is supported by U.S. technology companies.
                      </p>
                    </div>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.nytimes.com/2020/07/23/technology/google-ecommerce-amazon.html"
                      >
                        <h4>
                          Google Takes Aim at Amazon. Again. | New York Times
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Google is getting serious about competing with Amazon in
                        online shopping — just as it did in 2013, 2014, 2017 and
                        2019. But in 2020, as the coronavirus pandemic continues
                        to grip America, the push to create an online shopping
                        marketplace to compete with Amazon has taken on new
                        urgency as consumers are avoiding stores and turning to
                        the internet to fill more of their shopping needs. Last
                        week, Google announced that it would take steps to bring
                        more sellers and products onto its shopping site by
                        waiving sales commissions and allowing retailers to use
                        popular third-party payment and order management
                        services like Shopify instead of the company’s own
                        systems. Currently, commissions on Google Shopping range
                        from a 5 percent to 15 percent cut depending on the
                        products.
                      </p>
                    </div>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.google.com/url?q=https://www.business2community.com/product-management/10-ways-ai-is-improving-new-product-development-02330833&source=gmail&ust=1599218378732000&usg=AFQjCNFGJjZcTy2MwXRzDN8ftbhaDZ-U2g"
                      >
                        <h4>
                          Verizon, 10 Ways AI Is Improving New Product
                          DevelopmentLaunch Joint Platform For IoT Application
                          Development | Business 2 Community
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        {" "}
                        From startups to enterprises racing to get new products
                        launched, AI and machine learning (ML) are making solid
                        contributions to accelerating new product development.
                        There are 15,400 job positions for DevOps and product
                        development engineers with AI and machine learning today
                        on Indeed, LinkedIn and Monster combined. Capgemini
                        predicts the size of the connected products market will
                        range between $519B to $685B this year with AI and
                        ML-enabled services revenue models becoming commonplace.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="news-letter top-picks-hold">
                  <div className="issue-main-head">
                    <h4>Top Picks</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.forbes.com/sites/seansteinsmith/2020/07/29/cryptodollars-are-a-good-start-but-the-us-needs-a-blockchain-policy/#79b51238207d"
                      >
                        <h4>
                          Cryptodollars Are A Good Start, But The U.S. Needs A
                          Blockchain Policy | Forbes
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Blockchain technology, and the crypto asset ecosystem,
                        might seem like an abstract idea or concept to business
                        people, individuals, or entrepreneurs who are not
                        immersed in the space on an everyday basis. That said,
                        it is difficult to overstate just how important
                        blockchain technology, and its related applications,
                        will be for the wider economy now and going forward.
                      </p>
                    </div>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://roboticsandautomationnews.com/2020/07/29/artificial-intelligence-and-the-future-of-web-design/34559/"
                      >
                        <h4>
                          Artificial Intelligence and the Future of Web Design |
                          Robotics & Automation
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        The web design industry is constantly changing as
                        technology matures and involves. Whether it’s changing
                        styles, new best practices, or the implementation of
                        innovative technology, we’ve seen the Internet evolve
                        over the last few decades. Major developments like
                        accessible high-speed internet have led to enhanced
                        experiences for most users. We’ve also seen multimedia
                        content and HTML5 become the new standards. But things
                        are starting to change for web design, and a new type of
                        technology is slowly emerging as the future of this
                        industry.This article shares how artificial intelligence
                        (AI) is quickly transforming the way websites are built
                        and how designers work.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="news-letter other-reads-hold">
                  <div className="issue-main-head">
                    <h4>Other Reads</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.google.com/url?q=https://www.wired.com/story/facebooks-red-team-hacks-ai-programs/&source=gmail&ust=1599218378733000&usg=AFQjCNGcVp3t-1tJgwFvZLedH1oewjYjGQ"
                      >
                        <h4>
                          Facebook’s ‘Red Team’ Hacks Its Own AI Programs |
                          Wired
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Facebook depends heavily on moderation powered by
                        artificial intelligence, and it says the tech is
                        particularly good at spotting explicit content. But some
                        users found they could sneak past Instagram’s filters by
                        overlaying patterns such as grids or dots on
                        rule-breaking displays of skin. That meant more work for
                        Facebook's human content reviewers. Facebook’s AI
                        engineers responded by training their system to
                        recognize banned images with such patterns, but the fix
                        was short-lived.{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <NewsSubscription categoryType="Fintech" />
          </div>
        );
    }

}

export default NewsIssue38;
