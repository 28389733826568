import React from 'react';
import "../../resources.css";
import { ResourceRoute } from '../../resource-routing';
import { Link } from "react-router-dom";
import NewsHeadings from "./common-action/news-heading";
import NewsSubscription from "./common-action/subscripbe-section";

class NewsIssue21 extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            pageEnabled: false,
        }

    }

    render() {
        return (
          <div className="newdesigned_issue_details">
            <NewsHeadings
              buttondDetail="Design"
              categoryType="design"
              description="16 of Google's best dev and design tools in 2020 | Creative Bloq"
            />

            <div className="newsissuedetailed">
              <div className="detailed-common">
                {/* <div className="back-to-common">
                    <span className="back-to-icon-hold">
                    <img src="/assets/img/resource/back-to-new.svg"  alt="bact to new"></img>
                    </span>
                    <Link  to={ResourceRoute.NEWSLETTER}><span className="back-to-link">Newsletter</span></Link>
                </div> */}

                <div className="news-letter whats-on-hold">
                  <div className="issue-main-head">
                    <h4>What’s on?</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.rollcall.com/2020/03/31/blockchain-could-transform-supply-chains-aid-in-covid-19-fight/"
                      >
                        <h4>
                          Blockchain could transform supply chains, aid in
                          COVID-19 fight | Roll Call{" "}
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Companies that specialize in moving goods from one place
                        to another are starting to use the technology that
                        powers cryptocurrency to streamline their work, and they
                        say it could help hospitals stay stocked and staffed
                        during pandemics like the one caused by COVID-19.
                      </p>
                    </div>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://cointelegraph.com/news/investment-in-blockchain-in-energy-markets-will-top-35-billion-by-2025"
                      >
                        <h4>
                          Investments in Blockchain Energy Markets will top $35
                          billion by 2025 | Coin Telegraph
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        According to a new report released by Premium Market
                        Insights (PMI), global investment in blockchain
                        technology in energy markets is set to reach $34.7
                        billion by 2025. Valued at just $156.5 million in 2016,
                        the sector is forecast to grow at a rate of 82% a year.
                        Although $35 billion seems high, it’s dwarfed by the net
                        worth of $1.85 trillion for the energy market as a
                        whole. Key players using blockchain and DLT in the field
                        include Accenture, AWS, Bigchaindb, Deloitte, IBM,
                        Infosys, Microsoft, Nodalblock, Oracle, SAP, Enosi, and
                        Electron.
                      </p>
                    </div>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.forbes.com/sites/simonchandler/2020/03/30/coronavirus-drives-72-rise-in-use-of-fintech-apps/#1889799566ed"
                      >
                        <h4>
                          Coronavirus Drives 72% Rise In Use Of FinTechApps |
                          Forbes{" "}
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        The coronavirus has driven a massive 72% rise in the use
                        of FinTechapps in Europe, according to new research
                        published today by deVere Group. At a time when most
                        sectors of the global economy are beginning to feel the
                        effects of what may already be a worldwide recession,
                        the sharp jump in app adoption and usage provides
                        encouraging news for the FinTechindustry. And it comes
                        as yet another indication that the long-term legacy of
                        the coronavirus will be an increasingly digital and
                        online society.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="news-letter top-picks-hold">
                  <div className="issue-main-head">
                    <h4>Top Picks</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.creativebloq.com/features/best-google-dev-design-tools"
                      >
                        <h4>
                          16 of Google's best dev and design tools in 2020 |
                          Creative Bloq
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Working on the web usually means you will be working
                        with Google in some shape or form. And seeing as Google
                        Chrome is streets ahead of the competition, designers
                        and developers need to think about how their project
                        will work with the browser. How will it look? What
                        technologies does it support, how secure is it and how
                        will it perform?{" "}
                      </p>
                    </div>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.technologyreview.com/2020/03/27/950247/ai-debate-gary-marcus-danny-lange/"
                      >
                        <h4>
                          A debate between AI experts shows a battle over the
                          technology’s future | MIT Review{" "}
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Since the 1950s, artificial intelligence has repeatedly
                        overpromised and under delivered. While recent years
                        have seen incredible leaps thanks to deep learning, AI
                        today is still narrow: it’s fragile in the face of
                        attacks, can’t generalize to adapt to changing
                        environments, and is riddled with bias. All these
                        challenges make the technology difficult to trust and
                        limit its potential to benefit society.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="news-letter other-reads-hold">
                  <div className="issue-main-head">
                    <h4>Other Reads</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.theguardian.com/news/2020/mar/30/looking-for-a-distraction-here-are-25-of-our-favourite-long-reads"
                      >
                        <h4>
                          Looking for a distraction? Here are 25 of our
                          favourite long reads | The Guardian{" "}
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        If you feel like reading about something other than
                        coronavirus – and filling some more time during lockdown
                        – then dive into a few of these highlights from the long
                        read archive by The Guardian.{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <NewsSubscription categoryType="Design" />
          </div>
        );
    }

}

export default NewsIssue21;
