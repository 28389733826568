import React from "react";
import "./readToSubscribe.css";
import * as contactAction from "../../../action/contactAction";
import { connect } from 'react-redux';
import { bindActionCreators } from "redux";
import { withRouter} from "react-router-dom";
import { ToastComponent } from "../../common/toast/toast";

class ReadToSubscribe extends React.Component {
    constructor(props){
      super(props)
      this.state = {
        email: "",
        emailErr: "",
        onShow: false,
    }
    // if(localStorage.getItem("subscribeEmail")){
    //     this.props.onRead(this.state.onShow = true);
    // }
    }
    componentDidMount(){
        if(localStorage.getItem("subscribeEmail")){
            this.props.onRead(this.state.onShow = true);
        }
    }
    // componentWillUnmount(){
    //     localStorage.removeItem("subscribeEmail");
    // }
    handleChange(e) {
        var val = e.target.value;
        val = val.replace(/^\s+/, '');
        this.setState({
            email: val,
            // onRead: false
        })
        localStorage.setItem("subscribeEmail", val);
    }
render(){
    // console.log(this.state.onRead, "subscribe")
    return(
        <div className="read-to-subscribe-wrap">
           <div className="custom-container">
              <div className="section-heading">
                <h3>
                    <span>To read this exclusive blog,</span>
                    Subscribe and join our community!
                </h3>
              </div>
              <div className="read-more-list">
                <div className="more-card">
                    <span>Thought Leadership</span>
                    <strong>Blogs</strong>
                </div>
                <div className="more-card">
                    <span>Exclusive Content like</span>
                    <strong>Whitepaper & Casestudies</strong>
                </div>
                <div className="more-card">
                    <span>Access to Monthly Curated</span>
                    <strong>Newsletter</strong>
                </div>
              </div>
              <div className="sign-up">
                <input type="text" placeholder="Enter your email..." name={"email"} value={this.state.email} onChange={this.handleChange.bind(this)}/>
                <button onClick={() => {
                            var emailRegx = /^(([a-zA-Z\d-_.]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(this.state.email);
                            if (emailRegx === false) {
                                this.setState({
                                    emailErr: "Invalid Email"
                                })
                            }
                            else {
                                this.props.sendNewsFeed(this.state.email).then((res) => {
                                    this.setState({
                                        email: "",
                                        emailErr: ""
                                    })
                                    if (res.status === 200) {
                                        // alert('asd')
                                        // this.props.onRead = true
                                        this.props.onRead(this.state.onShow = true);
                                        // <ToastComponent showToast={this.state.showToast}/>
                                        // console.log(this.props.onRead, "OnRead")
                                    }

                                }).catch((err) => {
                                    console.log(err)
                                })
                            }
                        }}>Sign Up</button>
                         {this.state.emailErr.length > 0 ? <div className="err-msg"><span>{this.state.emailErr}</span></div> : ""}
              </div>
           </div>
        </div>
    )
}
}
const mapStateToProps = (state, ownProps) => {
    return state.commonReducer;
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return bindActionCreators(contactAction, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter (ReadToSubscribe));;