import React from 'react';
import "../../resources.css";
import {ResourceRoute} from '../../resource-routing';
import {Link} from "react-router-dom";
import NewsHeadings from "./common-action/news-heading";
import NewsSubscription from "./common-action/subscripbe-section";

class NewsIssue38 extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            pageEnabled: false,
        }

    }

    render() {
        return (
          <div className="newdesigned_issue_details">
            <NewsHeadings
              buttondDetail="Design"
              categoryType="design"
              description="Microsoft teases its future Office UI | The Verge"
            />
            <div className="newsissuedetailed">
              <div className="detailed-common">
                {/* <div className="back-to-common">
                    <span className="back-to-icon-hold">
                    <img src="/assets/img/resource/back-to-new.svg"  alt="bact to new"></img>
                    </span>
                    <Link  to={ResourceRoute.NEWSLETTER}><span className="back-to-link">Newsletter</span></Link>
                </div> */}

                <div className="news-letter whats-on-hold">
                  <div className="issue-main-head">
                    <h4>What’s on?</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://cointelegraph.com/news/alibaba-payments-subsidiary-launches-antchain-blockchain-solution"
                      >
                        <h4>
                          Alibaba Payments Subsidiary Launches AntChain
                          Blockchain Solution | Coin Telegraph
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        The payments subsidiary of the Chinese e-commerce giant
                        Alibaba, Ant Group, today launched a new blockchain
                        solution dubbed AntChain. As part of the AntChain
                        initiative, the Ant Group has built a new workstation
                        similar in size to a laptop. It comes with built-in
                        software and hardware accelerator meant to smoothen the
                        functioning of blockchain applications.
                      </p>
                    </div>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.google.com/url?q=https://www.crn.com/news/networking/verizon-microsoft-launch-joint-platform-for-iot-application-development&source=gmail&ust=1599222754569000&usg=AFQjCNH8W3eI9JmpMKqiZkRQeR-_dGKneQ"
                      >
                        <h4>
                          Verizon, Microsoft Launch Joint Platform For IoT
                          Application Development | CRN
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Telecom giant Verizon has joined forces with Microsoft
                        Azure to help developers and service providers create
                        and develop IoT solutions faster. Verizon’s IoT
                        developer platform, ThingSpace, as well as the carrier’s
                        5G LTE network connectivity, have been integrated with
                        Microsoft Azure’s IoT Central, a platform that provides
                        cloud-based analytics and pre-built IoT use case
                        templates, the two companies announced on Thursday. The
                        jointly integrated platform is available globally to
                        developers today, according to Verizon.
                      </p>
                    </div>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.google.com/url?q=https://www.zdnet.com/article/flipkart-acquires-walmart-india-to-launch-wholesale-digital-marketplace/&source=gmail&ust=1599222754569000&usg=AFQjCNEENbeJpt5fFuJZ3p54XYZEqNTkCQ"
                      >
                        <h4>
                          Flipkart acquires Walmart India to launch wholesale
                          digital marketplace | ZDNet
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Flipkart announced on Thursday it has acquired Walmart
                        India to bolster its wholesale marketplace operations,
                        and in doing so, has made plans to launch a new digital
                        marketplace next month. The new digital marketplace,
                        called Flipkart Wholesale, will provide wholesale goods
                        to kiranas in India. Kiranas are independent retailers
                        and small businesses, similar to neighbourhood stores,
                        that sell a variety of retail products.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="news-letter top-picks-hold">
                  <div className="issue-main-head">
                    <h4>Top Picks</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.theverge.com/2020/7/21/21332483/microsoft-office-future-ui-design-ribbon-command-bar-interface"
                      >
                        <h4>
                          Microsoft teases its future Office UI | The Verge
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Microsoft is teasing the future of its Office UI and
                        design today, and it involves some big changes to the
                        traditional ribbon interface. The software giant has
                        been gradually improving Office with its Fluent Design
                        system over the past couple of years — adding new icons,
                        a dark mode, and overhauling the ribbon toolbar by
                        making it smaller and easier to use. The next stage of
                        Microsoft’s Office design sees the company focus even
                        further on simplicity.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="news-letter other-reads-hold">
                  <div className="issue-main-head">
                    <h4>Other Reads</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.google.com/url?q=https://www.nytimes.com/2020/07/14/climate/climate-facebook-fact-checking.html&source=gmail&ust=1599222754570000&usg=AFQjCNGReADUwkIX6u5jiZlh9QSnNL_bag"
                      >
                        <h4>
                          How Facebook Handles Climate Disinformation | NY Times
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        As Covid-19 spread across the globe early this year,
                        Facebook went into action to combat potentially
                        dangerous disinformation on its site. The company
                        labeled and suppressed misleading content, removed
                        disinformation and directed users to reputable sources,
                        including the Centers for Disease Control and Prevention
                        website. This swift action stood in sharp contrast to
                        Facebook’s stance on another divisive and complex
                        danger: climate change. That’s because, under the
                        company’s guidelines, climate content can be classified
                        as opinion and therefore exempted from fact-checking
                        procedures.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <NewsSubscription categoryType="Design" />
          </div>
        );
    }

}

export default NewsIssue38;
