export const CATERGORY = ['Web3', 'Healthtech', 'Design', 'Fintech', 'Technology'];
export const EVENT_MOCKUP = [
    {
        "content": "From Chaos to Clarity - Role of Design",
        "subContent": "Episode 2: Designing Interactive UI to Improve Traders' Efficiency",
        "authorName": [
            "Chippy Diac,"
        ],
        "date": "09 Nov 2023",
        "link1": "From Chaos to Clarity - Role of Design",
        "link2": "https://youtu.be/DFvG-rm7oIM",
        "href": "",
        "img": "/assets/img/health-img/web-design-banner1.png",
        "link": true,
        "alt": "From Chaos to Clarity - Role of Design",
        "title": "From Chaos to Clarity - Role of Design",
        "category": "Design"
    },
    {
        "content": "Chaos to Clarity | Role of Design",
        "subContent": "Episode 1: Designing Broker Dealer Products and Solutions",
        "authorName": [
            "Chippy Diac,"
        ],
        "date": "22 June 2023",
        "link1": "Chaos to Clarity | Role of Design",
        "link2": "https://www.youtube.com/watch?v=MIc4dtonBlQ",
        "href": "",
        "img": "/assets/img/health-img/web-design-banner.png",
        "link": true,
        "alt": "Chaos to Clarity | Role of Design",
        "title": "Chaos to Clarity | Role of Design",
        "category": "Design"
    },
    {
        "content": "The Evolution, Impact, and Opportunities of ePrescribing",
        "authorName": [
            "Dr. Mannas Samant,"
        ],
        "date": "22 June 2023",
        "link1": "The Evolution, Impact, and Opportunities of ePrescribing",
        "link2": "https://www.youtube.com/watch?v=4bFj3PBJDnQ",
        "href": "",
        "img": "/assets/img/health-img/e-prescribe-banner.jpg",
        "link": true,
        "alt": "The Evolution, Impact, and Opportunities of ePrescribing",
        "title": "The Evolution, Impact, and Opportunities of ePrescribing",
        "category": "Healthtech"
    },
    {
        "content": "The Evolving Role of AI in Diagnosing and Treating Patients",
        "authorName": [
            "Dr. Mannas Samant ,"
        ],
        "date": "13 April 2023",
        "link1": "The Evolving Role of AI in Diagnosing and Treating Patients",
        "link2": "https://www.youtube.com/watch?v=Ppc0rBR78BY",
        "href": "",
        "img": "/assets/img/health-img/evolve-of-ai.jpg",
        "link": true,
        "alt": "The Evolving Role of AI in Diagnosing and Treating Patients",
        "title": "The Evolving Role of AI in Diagnosing and Treating Patients",
        "category": "Healthtech"
    },
    {
        "content": "Technology’s Impact on Healthcare Ep 6: Healthcare AI",
        "authorName": [
            "Dr. Mannas Samant ,"
        ],
        "date": "04 November 2022",
        "link1": "Technology’s Impact on Healthcare Ep 6: Healthcare AI",
        "link2": "https://www.youtube.com/watch?v=6UfmzV9F8lc",
        "href": "",
        "img": "/assets/img/health-img/HCWebinar_Ep-6.jpg",
        "link": true,
        "alt": "Technology’s Impact on Healthcare Ep 6: Healthcare AI",
        "title": "Technology’s Impact on Healthcare Ep 6: Healthcare AI",
        "category": "Healthtech"
    },
    {
        "content": "Technology’s Impact on Healthcare Ep 5: Healthcare Security",
        "authorName": [
            "Dr. Mannas Samant ,"
        ],
        "date": "28 October 2022",
        "link1": "Technology’s Impact on Healthcare Ep 5: Healthcare Security",
        "link2": "https://www.youtube.com/watch?v=N1ONUTWnqyU",
        "href": "",
        "img": "/assets/img/health-img/HCWebinar_Ep-5.jpg",
        "link": true,
        "alt": "Technology’s Impact on Healthcare Ep 5: Healthcare Security",
        "title": "Technology’s Impact on Healthcare Ep 5: Healthcare Security",
        "category": "Healthtech"
    },
    {
        "content": "Technology’s Impact on Healthcare Ep 4: Medical Device Software",
        "authorName": [
            "Dr. Mannas Samant ,"
        ],
        "date": "21 October 2022",
        "link1": "Technology’s Impact on Healthcare Ep 4: Medical Device Software",
        "link2": "https://www.youtube.com/watch?v=i6pgH8Cf238",
        "href": "",
        "img": "/assets/img/health-img/HCWebinar_Ep-4.jpg",
        "link": true,
        "alt": "Technology’s Impact on Healthcare Ep 4: Medical Device Software",
        "title": "Technology’s Impact on Healthcare Ep 4: Medical Device Software",
        "category": "Healthtech"
    },
    {
        "content": "Technology’s Impact on Healthcare Ep 3: Remote Patient Monitoring",
        "authorName": [
            "Dr. Mannas Samant ,"
        ],
        "date": "14 October 2022",
        "link1": "Technology’s Impact on Healthcare Ep 3: Remote Patient Monitoring",
        "link2": "https://www.youtube.com/watch?v=lTYhSdPSXCU",
        "href": "",
        "img": "/assets/img/health-img/HCWebinar_Ep-3.jpg",
        "link": true,
        "alt": "Technology’s Impact on Healthcare Ep 3: Remote Patient Monitoring",
        "title": "Technology’s Impact on Healthcare Ep 3: Remote Patient Monitoring",
        "category": "Healthtech"
    },
    {
        "content": "Technology’s Impact on Healthcare Ep 2: FHIR/Interoperability",
        "authorName": [
            "Dr. Mannas Samant ,"
        ],
        "date": "07 October 2022",
        "link1": "Technology’s Impact on Healthcare Ep 2: FHIR/Interoperability",
        "link2": "https://www.youtube.com/watch?v=5jJAqOdKD_4",
        "href": "",
        "img": "/assets/img/health-img/HCWebinar_Ep-2.jpg",
        "link": true,
        "alt": "Technology’s Impact on Healthcare Ep 2: FHIR/Interoperability",
        "title": "Technology’s Impact on Healthcare Ep 2: FHIR/Interoperability",
        "category": "Healthtech"
    },
    {
        "content": "Technology's Impact on Healthcare Ep 1: EHRs",
        "authorName": [
            "Dr. Mannas Samant ,"
        ],
        "date": "30 September 2022",
        "link1": "Technology's Impact on Healthcare Ep 1: EHRs",
        "link2": "https://www.youtube.com/watch?v=1nF0K3lOjv4",
        "href": "",
        "img": "/assets/img/health-img/HCWebinar_Ep-1.jpg",
        "link": true,
        "alt": "Technology's Impact on Healthcare Ep 1: EHRs",
        "title": "Technology's Impact on Healthcare Ep 1: EHRs",
        "category": "Healthtech"
    },
    {
        "content": "Webinar On A Deep Dive into DeFi",
        "authorName": [
            "Shaan Ray ,"
        ],
        "date": "26 August 2022",
        "link1": "Webinar On A Deep Dive into DeFi",
        "link2": "https://www.youtube.com/watch?v=eIQi5BjkNm8",
        "href": "",
        "img": "/assets/img/resource/DAOs-SmartContracts.jpg",
        "link": true,
        "alt": "Webinar On A Deep Dive into DeFi",
        "title": "Webinar On A Deep Dive into DeFi",
        "category": "Web3"
    },
    {
        "content": "DAOs & Smart Contracts",
        "authorName": [
            "Shaan Ray ,"
        ],
        "date": "19 August 2022",
        "link1": "DAOs & Smart Contracts",
        "link2": "/assets/img/webinar-videos/DAOs-SmartContracts.mp4",
        "href": "",
        "img": "/assets/img/resource/DAOs-SmartContracts.jpg",
        "link": true,
        "alt": "DAOs & Smart Contracts",
        "title": "DAOs & Smart Contracts",
        "category": "Fintech"
    },
    {
        "content": "What is a DAO?",
        "authorName": [
            "Shaan Ray ,"
        ],
        "date": "12 August 2022",
        "link1": "What is a DAO?",
        "link2": "/assets/img/webinar-videos/DAO.mp4",
        "href": "",
        "img": "/assets/img/resource/DAO.jpg",
        "link": true,
        "alt": "What is a DAO?",
        "title": "What is a DAO?",
        "category": "Healthtech"
    },
    {
        "content": "Understanding Non Fungible Token Standards",
        "authorName": [
            "Shaan Ray ,"
        ],
        "date": "05 Aguest 2022",
        "link1": "Understanding Non Fungible Token Standards",
        "link2": "/assets/img/webinar-videos/non-fungilible-token.mp4",
        "href": "",
        "img": "/assets/img/resource/non-fungilible-token.jpg",
        "link": true,
        "alt": "Understanding Non Fungible Token Standards",
        "title": "Understanding Non Fungible Token Standards",
        "category": "Healthtech"
    },
    {
        "content": "Understanding Crypto Security",
        "authorName": [
            "Shaan Ray"
        ],
        "date": "28 October 2020",
        "link1": "Understanding Crypto Security",
        "link2": "/assets/img/webinar-videos/crypto-security-event.mp4",
        "href": "",
        "img": "/assets/img/webinar-videos/crypto-security-event.jpg",
        "link": true,
        "alt": "Understanding Crypto Security",
        "title": "Understanding Crypto Security",
        "category": "Fintech"
    },
    {
        "content": "Blockchain Applications in Online Marketplace",
        "authorName": [
            "Shaan Ray ,",
            "John Chappell"
        ],
        "date": "25 June 2020",
        "link1": "Blockchain Applications in Online Marketplace",
        "link2": "https://ix-website.s3.amazonaws.com/webinar-videos/blockchain-app-in-online-market-place(1).mp4",
        "href": "",
        "img": "/assets/img/resource/1.png",
        "link": true,
        "alt": "Blockchain Applications in Online Marketplace",
        "title": "Blockchain Applications in Online Marketplace",
        "category": "Fintech"
    },
    {
        "content": "Exploring the Possibilities of Blockchain in Healthcare",
        "authorName": [
            "Shaan Ray"
        ],
        "date": "25 March 2020",
        "link1": "Exploring the Possibilities of Blockchain in Healthcare",
        "link2": "https://ix-website.s3.amazonaws.com/webinar-videos/explore-the-possibilities-in-healthcare(2).mp4",
        "href": "",
        "img": "/assets/img/resource/2.png",
        "link": true,
        "alt": "Exploring the Possibilities of Blockchain in Healthcare",
        "title": "Exploring the Possibilities of Blockchain in Healthcare",
        "category": "Healthtech"
    },
    {
        "content": "How can blockchain be leveraged for fintech data aggregation?",
        "authorName": [
            "Shaan Ray"
        ],
        "date": "30 Jan 2020 & 31 Jan 2020",
        "link1": "How can blockchain be leveraged for fintech data aggregation?",
        "link2": "https://ix-website.s3.amazonaws.com/webinar-videos/how-can-blockchain-be-leveraged-for-fintech-data-aggregation-webinar-recording-Jan-30-31(3).mp4",
        "href": "",
        "link": true,
        "img": "/assets/img/resource/3.png",
        "alt": "How can blockchain be leveraged for fintech data aggregation?",
        "title": "How can blockchain be leveraged for fintech data aggregation?",
        "category": "Design"
    },
    {
        "content": "STABLE COINS: Currency for a digitized world (Repeat)",
        "authorName": [
            "Shaan Ray"
        ],
        "date": "17 Dec 2019",
        "link1": "STABLE COINS: Currency for a digitized world (Repeat)",
        "link2": "https://ix-website.s3.amazonaws.com/webinar-videos/how-can-blockchain-be-leveraged-for-fintech-data-aggregation-webinar-recording-Jan-30-31(3).mp4",
        "href": "",
        "link": true,
        "img": "/assets/img/resource/4.png",
        "alt": "STABLE COINS: Currency for a digitized world (Repeat)",
        "title": "STABLE COINS: Currency for a digitized world (Repeat)",
        "category": "Web3"
    },
    {
        "content": "STABLE COINS: Currency for a digitized world",
        "authorName": [
            "Shaan Ray"
        ],
        "date": "29 Nov 2019",
        "link1": "STABLE COINS: Currency for a digitized world (Repeat)",
        "link2": "https://ix-website.s3.amazonaws.com/webinar-videos/stable-coins-webinar(5).mp4",
        "href": "",
        "link": true,
        "img": "/assets/img/resource/5.png",
        "alt": "STABLE COINS: Currency for a digitized world",
        "title": "STABLE COINS: Currency for a digitized world",
        "category": "Healthtech"
    },
    {
        "content": "Co-creating an Effective Design Brief",
        "authorName": [
            "Chippy Diac Vivekanandah"
        ],
        "date": "07 Nov 2019",
        "link1": "",
        "link2": "",
        "href": "https://www.slideshare.net/ionixxtechnologies/cocreating-an-effective-design-brief",
        "img": "/assets/img/resource/6.jpeg",
        "link": false,
        "alt": "Co-creating an Effective Design Brief",
        "title": "Co-creating an Effective Design Brief",
        "category": "Fintech"
    },
    {
        "content": "Blockchain and the Future of Data Management",
        "authorName": [
            "Shaan Ray"
        ],
        "date": "13 Sep 2019",
        "link1": "Blockchain and the Future of Data Management",
        "link2": "/assets/img/webinar-videos/stable-coins-webinar(5).mp4",
        "href": "https://ix-website.s3.amazonaws.com/webinar-videos/Webinar_Blockchain and the Future of Data Management_Sep 13 2019 (online-video-cutter.com)(7).mp4",
        "link": true,
        "img": "/assets/img/resource/7.jpg",
        "alt": "Blockchain and the Future of Data Management",
        "title": "Blockchain and the Future of Data Management",
        "category": "Design"
    },
    {
        "content": "Blockchain Technology's Legal Implications",
        "authorName": [
            "Shaan Ray"
        ],
        "date": "26 Jun 2019",
        "link1": "",
        "link2": "",
        "href": "https://www.bigmarker.com/ionixx-technologies1/Blockchain-Technology-s-Legal-Implications3-2019-06-26-08-30-pm?bmid=1cb3556ba4fe",
        "img": "/assets/img/resource/8.jpg",
        "link": true,
        "alt": "Blockchain Technology's Legal Implications",
        "title": "Blockchain Technology's Legal Implications",
        "category": "Fintech"
    },
    {
        "content": "Blockchain Oracles",
        "authorName": [
            "Shaan Ray"
        ],
        "date": "23 Apr 2019",
        "link1": "",
        "link2": "",
        "href": "https://www.bigmarker.com/ionixx-technologies1/Blockchain-Oracles?bmid=1cb3556ba4fe",
        "link": true,
        "img": "/assets/img/resource/9.jpg",
        "alt": "Blockchain Oracles",
        "title": "Blockchain Oracles",
        "category": "Design"
    },
    {
        "content": "JPM Coin",
        "authorName": [
            "Shaan Ray"
        ],
        "date": "28 Feb 2019",
        "link1": "JPM Coin",
        "link2": "https://ix-website.s3.amazonaws.com/webinar-videos/stable-coins-webinar(5).mp4",
        "href": "https://ix-website.s3.amazonaws.com/webinar-videos/Webinar_Blockchain_JPM Coin_28 Feb 2019.mp4",
        "link": true,
        "img": "/assets/img/resource/10.jpg",
        "alt": "JPM Coin",
        "title": "JPM Coin",
        "category": "Design"
    }
]

export const NEWS_MOCKUP = [
    {
        "url": "https://www.forbes.com/sites/forbesbusinesscouncil/2020/03/25/15-smart-ways-you-can-save-money-as-a-new-business/#5fdfd7aa40fc",
        "imageUrl": "/assets/img/resource/forbes-new.svg",
        "title": "Smart ways to save money for a new business - Manju Mohan quoted in Forbes",
        "content_two": "20 Mar 2020",
        "category": "Web3"
    },
    {
        "url": "https://www.forbes.com/sites/forbesbusinesscouncil/2020/03/12/authenticating-food-through-blockchain",
        "imageUrl": "/assets/img/resource/forbes-new.svg",
        "title": "Authenticating Food Through Blockchain by Manju Mohan in Forbes Magazine",
        "content_two": "12 Mar 2020",
        "category": "Web3"
    },
    {
        "url": "https://www.cryptonewspoint.com/meet-the-100-most-inspirational-women-in-blockchain/",
        "imageUrl": "/assets/img/press/crpto-news-point.svg",
        "title": "Manju Mohan featured among 100 inspirational women in Blockchain",
        "content_two": "09 Mar 2020",
        "category": "Fintech"
    },
    {
        "url": "https://www.forbes.com/sites/forbesbusinesscouncil/2020/03/09/13-smart-ways-to-handle-internal-disputes-when-youre-a-growing-team/#14bf59995394",
        "imageUrl": "/assets/img/resource/forbes-new.svg",
        "title": "Smart Ways To Handle Internal Disputes - Manju Mohan quoted by Forbes Magazine",
        "content_two": "09 Mar 2020",
        "category": "Fintech"
    },
    {
        "url": "https://inc42.com/resources/how-data-aggregators-are-using-blockchain-to-empower-fintech-players/",
        "imageUrl": "/assets/img/resource/inc.png",
        "title": "How Data aggregators Are Using Blockchain To Empower Fintech Players - Shaan Ray | Inc 42 Magazine",
        "content_two": "01 Mar 2020",
        "category": "Healthtech"
    },
    {
        "url": "https://www.forbes.com/sites/forbesbusinesscouncil/2020/02/28/13-strategies-for-documenting-and-handling-your-spur-of-the-moment-ideas/#5200cb7073f4",
        "imageUrl": "/assets/img/resource/forbes-new.svg",
        "title": "Strategies for documenting and handling spur of the moment ideas - Manju Mohan quoted | Forbes.com",
        "content_two": "28 Feb 2020",
        "category": "Web3"
    },
    {
        "url": "https://indiacurrents.com/software-startups-are-in-manju-mohans-dna/",
        "imageUrl": "/assets/img/resource/india-currents-new.png",
        "title": "Software Startups in Manju Mohan's DNA - India Currents Magazine",
        "content_two": "22 Feb 2020",
        "category": "Fintech"
    },
    {
        "url": "https://www.forbes.com/sites/forbesbusinesscouncil/2020/02/13/creating-the-ground-for-sustainable-living-through-blockchain-technology/#4241d1ae2d41",
        "imageUrl": "/assets/img/resource/forbes-new.svg",
        "title": "Sustainable Living Through Blockchain Technology",
        "content_two": "14 Feb 2020",
        "category": "Web3"
    },
    {
        "url": "https://www.cryptoninjas.net/2019/12/12/ionixx-to-build-enterprise-dapps-through-blockapps-strato/",
        "imageUrl": "/assets/img/resource/forbes-new.svg",
        "title": "Media coverage received for BlockApps STRATO Ionixx partnership",
        "content_two": "17 Dec 2019",
        "category": "Healthtech"
    }
]
