import React from 'react';
import "../../resources.css";
import { ResourceRoute } from '../../resource-routing';
import { Link } from "react-router-dom";
import NewsHeadings from "./common-action/news-heading";
import NewsSubscription from "./common-action/subscripbe-section";

class NewsIssue23 extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            pageEnabled: false,
        }

    }

    render() {
        return (
          <div className="newdesigned_issue_details">
            <NewsHeadings
              buttondDetail="Web3 & Blockchain"
              categoryType="web3"
              description="Here’s when you can trust Zoom, and when you shouldn’t | Fast Company"
            />

            <div className="newsissuedetailed">
              <div className="detailed-common">
                {/* <div className="back-to-common">
                    <span className="back-to-icon-hold">
                    <img src="/assets/img/resource/back-to-new.svg"  alt="bact to new"></img>
                    </span>
                    <Link  to={ResourceRoute.NEWSLETTER}><span className="back-to-link">Newsletter</span></Link>
                </div> */}

                <div className="news-letter whats-on-hold">
                  <div className="issue-main-head">
                    <h4>What’s on?</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.theverge.com/interface/2020/4/14/21219289/apple-google-contact-tracing-app-android-ios-pros-cons-quarantine-testing"
                      >
                        <h4>
                          Pros & Cons of Google-Apple contact tracing | Tech
                          Verge{" "}
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Last week, Apple and Google surprised us with an
                        announcement that the companies are spinning up a system
                        to enable widespread contact tracing in an effort to
                        contain the COVID-19 pandemic. The effort is barely two
                        and a half weeks old, the companies said, and so there
                        are many open questions about how it will work
                      </p>
                    </div>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.theblockcrypto.com/post/62075/chinese-tech-giant-alibabas-subsidiary-launches-consortium-blockchain-platform"
                      >
                        <h4>
                          Chinese tech giant Alibaba’s subsidiary launches
                          consortium blockchain platform | The Block Crypto
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Ant Financial, the fintech arm of Chinese tech giant
                        Alibaba Group and the parent company of Alipay, has
                        launched its consortium blockchain platform. Dubbed
                        “OpenChain,” the platform is aimed at helping small and
                        medium-sized enterprises (SMEs) deploy blockchain
                        applications “with higher efficiency and lower cost.”
                      </p>
                    </div>
                  </div>
                </div>
                <div className="news-letter top-picks-hold">
                  <div className="issue-main-head">
                    <h4>Top Picks</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.fastcompany.com/90488717/can-you-trust-zoom"
                      >
                        <h4>
                          Here’s when you can trust Zoom, and when you shouldn’t
                          | Fast Company{" "}
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        In the midst of global pandemic, the nine-year-old
                        videoconferencing service Zoom skyrocketed into general
                        awareness. “To zoom” is now a verb. But among its rise
                        in stock and a twentyfold increase in usage between
                        December 2019 and March 2020, with 200 million daily
                        users now conducting meetings worldwide, a lot of other
                        verbs have been used with less affection about the
                        company’s software quality, installation methods,
                        security, ties to China, and privacy policies and
                        actions. At the same time, technologists have hailed the
                        company’s ability to deliver reliable service despite
                        the blistering increase in usage—a growth rate no
                        Internet firm has ever had to contend with under normal
                        circumstances—with the lion’s share producing no new
                        revenue.
                      </p>
                    </div>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.smashingmagazine.com/2020/04/structured-data-design-process/"
                      >
                        <h4>
                          Baking Structured Data Into The Design Process |
                          Smashing Magazine
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Search engine optimization (SEO) is essential for almost
                        every kind of website, but its finer points remain
                        something of a specialty. Even today SEO is often
                        treated as something that can be tacked on after the
                        fact. It can up to a point, but it really shouldn’t be.
                        Search engines get smarter every day and there are ways
                        for websites to be smarter too.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="news-letter other-reads-hold">
                  <div className="issue-main-head">
                    <h4>Other Reads</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://thenextweb.com/growth-quarters/2020/04/16/3-ways-to-maximize-your-work-station-at-home/"
                      >
                        <h4>
                          3 ways to maximize your work station at home | The
                          Next Web{" "}
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Working from home is great. There’s no commute and you
                        can have greater control about your work setup (heck,
                        you can even have your own whiteboard), but getting this
                        right can be tricky.So, here’s some top advice to help
                        you maximize your current home setup so that your body
                        doesn’t suffer
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <NewsSubscription categoryType="Web3" />
          </div>
        );
    }

}

export default NewsIssue23;
