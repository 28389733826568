import React, {lazy, Suspense} from 'react';
import ReactDOM from 'react-dom';
// import {Provider} from "react-redux";
// import configStore from "./store/configStore";
// import {HashRouter, Switch, Redirect, Route, BrowserRouter} from "react-router-dom";
// import {ROUTES} from './routes.js';
// import ScrollToTop from "./components/scroll-to-top/scroll-to-top";
import * as serviceWorker from './serviceWorker';
import App from "./App";
import 'bootstrap/dist/css/bootstrap.min.css';
import "./styles/common.css";
import "./styles/font.css";

// const ResourceRoute = lazy(()=>import ('./pages/resources/resource-routing'))
// const Home = lazy(() => import ('./pages/landing/landing.js'));
// const Resources = lazy(() => import ('./pages/resources/resources.js'));

// const store = configStore();
// const checkAccessToken = () => {
//   var user = sessionStorage.getItem("user");
//   if (user) {
//     if (JSON.parse(user).accessToken) {
//       return true;
//     } else {
//       return false;
//     }
//   } else return false;
// };

// const AuthRoute = ({ component: Component, ...rest }) => (
//   <Route
//     {...rest}
//     render={props =>
//       checkAccessToken() ? (
//         <Component {...props} />
//       ) : (
//           <Redirect
//             to={{
//               pathname: "/"
//             }}
//           />
//         )
//     }
//   />
// );
    ReactDOM.hydrate(
    <App />,
        document.getElementById('root')
    );


serviceWorker.unregister();
