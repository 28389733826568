import React from 'react';
import "../../resources.css";
import { ResourceRoute } from '../../resource-routing';
import { Link } from "react-router-dom";
import NewsHeadings from "./common-action/news-heading";
import NewsSubscription from "./common-action/subscripbe-section";

class NewsIssue57 extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            pageEnabled: false,
        }

    }

    render() {
        return (
          <div className="newdesigned_issue_details">
            <NewsHeadings
              buttondDetail="Web3 & Blockchain"
              categoryType="web3"
              description="5 key app sec trends for 2021: The shift is on for software teams | Techbeacon"
            />
            <div className="newsissuedetailed">
              <div className="detailed-common">
                {/* <div className="back-to-common">
                            <span className="back-to-icon-hold">
                                <img src="/assets/img/resource/back-to-new.svg" alt="back to new"></img>
                            </span>
                            <Link to={ResourceRoute.NEWSLETTER}><span className="back-to-link">Newsletter</span></Link>
                        </div> */}

                <div className="news-letter whats-on-hold">
                  <div className="issue-main-head">
                    <h4>What’s on?</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.google.com/url?q=https://cointelegraph.com/news/blockchain-data-gets-simpler-as-the-graph-launches-mainnet&source=gmail&ust=1608366722762000&usg=AFQjCNFN64RE6lkVH9kogodsOhRQgNt0fg"
                      >
                        <h4>
                          Blockchain data gets simpler as The Graph launches
                          mainnet | Coin Telegraph
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        The Graph Network launched its mainnet on Dec. 17,
                        allowing developers to easily search, index, use and
                        publish data from public blockchains. This is made
                        possible through a global and open network of
                        Application Programming Interfaces, or APIs, called
                        subgraphs. These form the basis of many of today’s most
                        popular decentralized applications.
                      </p>
                    </div>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://techbeacon.com/security/5-key-app-sec-trends-2021-shift-software-teams"
                      >
                        <h4>
                          5 key app sec trends for 2021: The shift is on for
                          software teams | Techbeacon
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        For many companies, 2020 was about accelerating their
                        move to the cloud. The pandemic drove a dramatic
                        expansion of remote work, developers focused more on
                        cloud-native deployments, and application security teams
                        had to adapt to a change in usage and, often, greater
                        demand. In 2021, many of those seeds will take root.
                        Businesses that accelerated digital transformations will
                        need to secure their infrastructure, developers working
                        remotely on cloud-native applications will have more
                        integrated security in their coding environments, and
                        application security teams will be tasked with
                        facilitating faster development cycles, rather than just
                        finding vulnerabilities.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="news-letter top-picks-hold">
                  <div className="issue-main-head">
                    <h4>Top Picks</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://techcrunch.com/2020/12/17/is-rising-usage-driving-cryptos-recent-price-boom/"
                      >
                        <h4>
                          Is rising usage driving Crypto's recent price boom |
                          Techcrunch{" "}
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        As 2020 comes to a close, the cryptocurrency world is
                        experiencing another late-year surge of consumer
                        interest as prices climb in value. Bitcoin is over
                        $23,000 as an all-time high. Ethereum’s cryptocurrency
                        has recovered sharply as well, returning to mid-2018
                        prices. These gains have created a huge amount of wealth
                        for crypto holders. According to CoinMarketCap, after
                        falling under $140 billion in mid-March during the
                        market selloff surrounding the beginning of COVID-19’s
                        battering of America, the value of all cryptos has
                        surged to nearly $659 billion.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="news-letter other-reads-hold">
                  <div className="issue-main-head">
                    <h4>Other Reads</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.nytimes.com/2020/12/12/opinion/joe-biden-america.html"
                      >
                        <h4>Build on Common Ground | NewYork Times </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Promises to pursue national healing and unity helped put
                        Joe Biden in the White House. Americans embraced that
                        vision. But the overall election results, with
                        Republicans gaining seats in the House and possibly
                        retaining control of the Senate, both exposed and
                        increased the magnitude of the incoming president’s
                        challenge. In a nation so politically divided, making
                        even modest progress on critical issues can be a slog.
                        Mr. Biden will need to rally the public behind a Decency
                        Agenda with broad-based appeal. That means first turning
                        down the temperature of the culture wars, backing a
                        policy agenda with broad public support and returning to
                        constitutional norms that served the nation well for so
                        long.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <NewsSubscription categoryType="Web3" />
          </div>
        );
    }

}

export default NewsIssue57;
