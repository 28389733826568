import React from 'react';
import "../../resources.css";
import {ResourceRoute} from '../../resource-routing';
import {Link} from "react-router-dom";
import NewsHeadings from "./common-action/news-heading";
import NewsSubscription from "./common-action/subscripbe-section";

class NewsIssue46 extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            pageEnabled: false,
        }

    }

    render() {
        return (
          <div className="newdesigned_issue_details">
            <NewsHeadings
              buttondDetail="Design"
              categoryType="design"
              description="The Role Of Emotions In UX/UI: Understanding Emotional Design | Forbes"
            />
            <div className="newsissuedetailed">
              <div className="detailed-common">
                {/* <div className="back-to-common">
                    <span className="back-to-icon-hold">
                    <img src="/assets/img/resource/back-to-new.svg"  alt="bact to new"></img>
                    </span>
                    <Link  to={ResourceRoute.NEWSLETTER}><span className="back-to-link">Newsletter</span></Link>
                </div> */}

                <div className="news-letter whats-on-hold">
                  <div className="issue-main-head">
                    <h4>What’s on?</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://cointelegraph.com/news/visa-goldman-sachs-and-mick-mulvaney-join-leading-blockchain-trade-association"
                      >
                        <h4>
                          Visa, Goldman Sachs and Mick Mulvaney join leading
                          blockchain trade association | Coin Telegraph
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        On Wednesday, the Chamber of Digital Commerce announced
                        the addition of Visa, Goldman Sachs and Six Digital
                        Exchange (SDX) to its executive committee. Mick
                        Mulvaney, a former White House Chief of Staff and a
                        co-founder of the Congressional Blockchain Caucus will
                        also be joining the chamber’s board of advisors
                      </p>
                    </div>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.businessinsider.com/walmart-goldman-sachs-partnering-on-smb-lending-2020-9?IR=T"
                      >
                        <h4>
                          Walmart and Goldman Sachs are partnering on a lending
                          product for Walmart Marketplace sellers through Marcus
                          |{" "}
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Walmart and Goldman Sachs are partnering on a lending
                        product for Walmart Marketplace sellers through Marcus.
                        This move opens up customer acquisition and overall
                        growth opportunities for Marcus. The bank will offer
                        credit lines through its digital-only offshoot Marcus to
                        small- and medium-sized businesses (SMBs) that sell on
                        Walmart's Marketplace platform.{" "}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="news-letter top-picks-hold">
                  <div className="issue-main-head">
                    <h4>Top Picks</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.forbes.com/sites/forbesagencycouncil/2020/09/21/the-role-of-emotions-in-uxui-understanding-emotional-design/#393ee53e25be"
                      >
                        <h4>
                          The Role Of Emotions In UX/UI: Understanding Emotional
                          Design | Forbes
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Humans are rational beings. But we are also driven by
                        emotions. How many times have you picked up a bag of
                        chips or a chocolate bar at the convenience store just
                        because they looked appealing? It is not just physical
                        products that can make us feel a certain way. It can
                        happen in the digital world, too.Don Norman, a
                        contemporary design visionary, in his book Emotional
                        Design: Why We Love (Or Hate) Everyday Things breaks
                        down people's emotional responses into three stages
                      </p>
                    </div>
                  </div>
                </div>
                <div className="news-letter other-reads-hold">
                  <div className="issue-main-head">
                    <h4>Other Reads</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://fortune.com/longform/jio-india-mukesh-ambani-tech-silicon-valley-facebook-alphabet-funding/"
                      >
                        <h4>
                          Will India’s Jio be the next tech giant? | Fortune{" "}
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Mammoth technology companies tend to blossom slowly.
                        Google grew quietly out of a garage, Facebook from a
                        dorm room, Amazon in a car rolling across the country.
                        The investments in such upstarts amount to grubstakes,
                        relatively paltry sums from risk-seeking investors who
                        know it could all come to naught. Yet when two of these
                        giants (Facebook and Google) plunk down billions of
                        dollars to back an emerging competitor to the third
                        (Amazon), a new tech colossus can be crowned nearly
                        instantaneously. That company, Jio Platforms, is a
                        four-year-old mobile phone company controlled by India’s
                        richest man, Mukesh Ambani. It has attracted so much
                        capital so quickly that while the rest of the world is
                        focused on a global pandemic, Jio suddenly has staked
                        its claim to becoming the first global tech giant to get
                        its start in India.{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <NewsSubscription categoryType="Design" />
          </div>
        );
    }

}

export default NewsIssue46;
