import React from 'react';
import "../../resources.css";
import { ResourceRoute } from '../../resource-routing';
import { Link } from "react-router-dom";
import NewsHeadings from "./common-action/news-heading";
import NewsSubscription from "./common-action/subscripbe-section";

class NewsIssue10 extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            pageEnabled: false,
        }

    }

    render() {
        return (
          <div className="newdesigned_issue_details">
            <NewsHeadings
              buttondDetail="Design"
              categoryType="design"
              description="The 3 biggest design trends at CES 2020 | Fast Company"
            />

            <div className="newsissuedetailed">
              <div className="detailed-common">
                {/* <div className="back-to-common">
                    <span className="back-to-icon-hold">
                        <img src="/assets/img/resource/back-to-new.svg"  alt="bact to new"></img>
                    </span>
                    <Link  to={ResourceRoute.NEWSLETTER}><span className="back-to-link">Newsletter</span></Link>
                </div> */}

                <div className="news-letter whats-on-hold">
                  <div className="issue-main-head">
                    <h4>What’s on?</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.coinspeaker.com/blockchain-skill-linkedin-2020/"
                      >
                        <h4>
                          Blockchain Is the Most Wanted ‘Hard Skill’ on LinkedIn
                          in 2020 | Coinspeaker.com
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        A report released by the professional social networking
                        platform LinkedIn has put blockchain as the most
                        sought-after “hard skill” for 2020. According to
                        LinkedIn, being skilled in blockchain technology will
                        ensure that such a person chased by recruiters. In 2018,
                        LinkedIn rated “Blockchain Developer” as the top
                        emerging job of the year.{" "}
                      </p>
                    </div>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://bitcoinist.com/worlds-first-blockchain-powered-phone-arrives-at-ces-2020/"
                      >
                        <h4>
                          A report released by the professional social
                          networking platform LinkedIn has put blockchain as the
                          most sought-after “hard skill” for 2020. According to
                          LinkedIn, being skilled in blockchain technology will
                          ensure that such a person chased by recruiters. In
                          2018, LinkedIn rated “Blockchain Developer” as the top
                          emerging job of the year.{" "}
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        A report released by the professional social networking
                        platform LinkedIn has put blockchain as the most
                        sought-after “hard skill” for 2020. According to
                        LinkedIn, being skilled in blockchain technology will
                        ensure that such a person chased by recruiters. In 2018,
                        LinkedIn rated “Blockchain Developer” as the top
                        emerging job of the year.{" "}
                      </p>
                    </div>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://fortune.com/2020/01/14/visa-plaid-acquisition-fintech/?utm_source=email&utm_medium=newsletter&utm_campaign=the-ledger&utm_content=2020011515pm"
                      >
                        <h4>
                          With Plaid acquisition, Visa makes a big play for the
                          ‘plumbing’ that connects the fintech world | Fortune{" "}
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        In late 2018, payments giants Visa and Mastercard both
                        invested in fintech startup Plaid through a $250 million
                        funding round that valued San Francisco-based firm at an
                        impressive $2.65 billion. Described as “strategic
                        investments,” the two financial services heavyweights
                        sought not only to provide Plaid with financial backing,
                        but also to leverage the fintech firm’s sprawling
                        technological capabilities to improve their own
                        services.{" "}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="news-letter top-picks-hold">
                  <div className="issue-main-head">
                    <h4>Top Picks</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.fastcompany.com/90450207/the-3-biggest-design-trends-at-ces-2020"
                      >
                        <h4>
                          The 3 biggest design trends at CES 2020 | Fast Company{" "}
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Every January, the annual Consumer Electronics Show
                        snaps the tech press out of its holiday stupor to fly to
                        Las Vegas and walk through aisle after aisle of new
                        televisions, robots, and plain old weird gadgets that
                        are all billed as the next big thing. Here’s what
                        happened at the biggest product show of the year that
                        you actually need to know about.{" "}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="news-letter other-reads-hold">
                  <div className="issue-main-head">
                    <h4>Other Reads</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.wired.com/story/opinion-conversational-ai-can-propel-social-stereotypes/"
                      >
                        <h4>
                          Conversational AI Can Propel Social Stereotypes |
                          Wired{" "}
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Alexa, Siri, Watson, and their talking AI siblings serve
                        to make our lives easier, but they also reinforce gender
                        stereotypes. Polite, subservient digital secretaries
                        like Alexa and Siri are presented as female. Assertive,
                        all-knowing Jeopardy! champion Watson is most often
                        referred to as “he.” New generations of AI are coming
                        that will make this problem more significant, and much
                        harder to avoid. As the field expands, designers need to
                        ensure they’re creating a more expansive world, and not
                        replicating a close-mindedly gendered one. Linguists can
                        help them get there.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <NewsSubscription categoryType="Web3" />
          </div>
        );
    }

}

export default NewsIssue10;
