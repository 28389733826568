import React from 'react';
import {Link} from "react-router-dom";
import { ROUTES } from '../../../../routes';
import {PortfolioRoute} from "../../../portfolio/route-porfolio";
import Helmet from 'react-helmet';
import ReactSEOMetaTags from "react-seo-meta-tags";

class CaseStudyP2e extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            downloadLink: '/assets/case-studies/P2E-case-study.pdf'
        }
    }

    

    render() {
        return (
            <>
              <Helmet>
                <meta charSet="utf-8" />
                <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1.0, shrink-to-fit=no, user-scalable=no, maximum-scale=1"
                />
                <title>Regulatory Compliance Management | Ionixx Tech</title>
                <meta name="description" content="Explore our case study on an all-encompassing regulatory management solution, showcasing how a design-first approach led to comprehensive compliance success." />
                <link rel="canonical" href="https://www.ionixxtech.com/case-studies/regulatory-compliance-management" />
                <meta
                    property="og:image"
                    content={
                    "https://www.ionixxtech.com/assets/img/SEO_OG_IMAGE/Regulatory Compliance OG image.png"
                    }
                />

            </Helmet>
            <ReactSEOMetaTags
          render={(el) => <Helmet>{el}</Helmet>}
          website={{
            name: "Ionixx Technologies",
            title:
              "Regulatory Compliance Management | Ionixx Tech",
            description:
              "Explore our case study on an all-encompassing regulatory management solution, showcasing how a design-first approach led to comprehensive compliance success.",
            type: "website",
            image: "https://www.ionixxtech.com/assets/img/SEO_OG_IMAGE/Regulatory Compliance OG image.png",
            url:
              "https://www.ionixxtech.com/case-studies/regulatory-compliance-management",

          }}
          twitter={{
            twitter:
              "https://www.ionixxtech.com/case-studies/regulatory-compliance-management",
          }}
        />
            <div class="Resources">
                <section class="section-2 ppr-dnld-custom-sec beige-bg">
                    <div class="custom-container">
                        <div class="tab-content">

            <div className="newsissuedetailed">
            <div className="detailed-common">
                <div className="back-to-common">
                    <span className="back-to-icon-hold">
                        <img src="/assets/img/resource/back-to-new.svg"  alt="bact to new"></img>
                    </span>
                    <Link to={ROUTES.PORTFOLIO}><span className="back-to-link">Portfolio</span></Link>
                </div>

                <div className="whats-on-hold">
                    <div className="ebook-sec">
						<div className="row">
							<div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 resp-apprch-col">
								<div className="custom-section work-with-custom-section">
                                    <div class="main-head-div">
                                        <h1 class="main-head-1">
                                            <span class="secondary-span">Streamlined Regulatory Compliance Management Framework</span>
                                        </h1>
                                    </div>
                                    <div className="main-para-div">
                                        <p>Our US-headquartered customer <Link to={PortfolioRoute.P2E}>P2e</Link> is a regulatory change solutions provider, keen on developing automation-driven technical solutions for handling regulatory changes and compliance functions. By delivering new technology capabilities in large-scale regulatory compliance-driven projects, our customer seeks to find effective solutions for augmenting regulatory change management processes.</p>
                                        <p> We built for our customer a user-friendly, highly-intuitive, end-to-end regulatory change management platform. We relegated the complexity of compliance management as a subject.Built with an intuitive UX, the feedback we received from our customer has been phenomenal on every front, from user experience and design to functionality.</p>
                                            {/* <a class="d-hide m-show" href="">View our software portfolio</a> */}
                                    </div>
								</div>
							</div>
							<div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 resp-apprch-col">
								<div class="ebook-form-sec">
                                    <div class="modal-title">
                                    <h4>Click download to get PDF</h4>
                                    </div>
                                    <a href={this.state.downloadLink} target="_blank">
                                            <button class="download-case-study contact-submit btn btn-primary">
                                                Download
                                            </button>
                                        </a>
                                </div>
							</div>
						</div>	
		            </div>
                </div>

            </div>
        </div> 
        
        </div>
        </div>
        </section>
        </div>
            </>
        );
    }

}

export default CaseStudyP2e;
