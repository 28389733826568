import React from 'react';
import "../../resources.css";
import { ResourceRoute } from '../../resource-routing';
import { Link } from "react-router-dom";
import NewsHeadings from "./common-action/news-heading";
import NewsSubscription from "./common-action/subscripbe-section";

class NewsIssue50 extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            pageEnabled: false,
        }

    }

    render() {
        return (
          <div className="newdesigned_issue_details">
            <NewsHeadings
              buttondDetail="Web3 & Blockchain"
              categoryType="web3"
              description="A radical new technique lets AI learn with practically no data | MIT Tech Review"
            />
            <div className="newsissuedetailed">
              <div className="detailed-common">
                {/* <div className="back-to-common">
                            <span className="back-to-icon-hold">
                                <img src="/assets/img/resource/back-to-new.svg" alt="bact to new"></img>
                            </span>
                            <Link to={ResourceRoute.NEWSLETTER}><span className="back-to-link">Newsletter</span></Link>
                        </div> */}

                <div className="news-letter whats-on-hold">
                  <div className="issue-main-head">
                    <h4>What’s on?</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a target={"_blank"} href="">
                        <h4>PayPal allows Bitcoin and crypto spending | BBC</h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        PayPal has entered the cryptocurrency market, announcing
                        that its customers will be able to buy and sell Bitcoin
                        and other virtual currencies using their PayPal
                        accounts. Those virtual coins could then be used to buy
                        things from the 26 million sellers which accept PayPal,
                        it said. PayPal plans to roll out buying options in the
                        US over the next few weeks, with the full rollout due
                        early next year.
                      </p>
                    </div>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.healthcareitnews.com/news/ibm-watson-health-launch-blockchain-powered-digital-health-pass"
                      >
                        <h4>
                          Infragistics Collaborates with Microsoft to Bring New
                          UI Toolkit to Blazor Users | Yahoo
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Infragistics, a software company responsible for the
                        UI/UX solutions powering the web, mobile and desktop
                        applications of some of the world’s largest enterprises,
                        today announces its official release of Ignite UI for
                        Blazor. The latest collaboration between Infragistics
                        and Microsoft marks the expansion of Infragistics’
                        already extensive UX/UI product portfolio. Now,
                        Infragistics’ enterprise UI toolkit is available to more
                        than 5 Million .NET developers who can build interactive
                        web applications without JavaScript, using Microsoft's
                        Blazor.{" "}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="news-letter top-picks-hold">
                  <div className="issue-main-head">
                    <h4>Top Picks</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a target={"_blank"} href="">
                        {" "}
                        <h4>
                          A radical new technique lets AI learn with practically
                          no data | MIT Tech Review
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Machine learning typically requires tons of examples. To
                        get an AI model to recognize a horse, you need to show
                        it thousands of images of horses. This is what makes the
                        technology computationally expensive—and very different
                        from human learning. A child often needs to see just a
                        few examples of an object, or even only one, before
                        being able to recognize it for life. Now a new paper
                        from the University of Waterloo in Ontario suggests that
                        AI models should also be able to do this—a process the
                        researchers call “less than one”-shot, or LO-shot,
                        learning. In other words, an AI model should be able to
                        accurately recognize more objects than the number of
                        examples it was trained on. That could be a big deal for
                        a field that has grown increasingly expensive and
                        inaccessible as the data sets used become ever larger.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="news-letter other-reads-hold">
                  <div className="issue-main-head">
                    <h4>Other Reads</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a target={"_blank"} href="">
                        <h4>
                          What Is Happening With the Antitrust Suit Against
                          Google? |The New York Times
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        The Justice Department sued Google on Tuesday, accusing
                        the company of illegally abusing its dominance in
                        internet search in ways that harm competitors and
                        consumers.The suit is the first antitrust action against
                        the company, owned by Alphabet, to result from
                        investigations by the Justice Department, Congress, and
                        50 states and territories. State attorneys general and
                        federal officials have also been investigating Google’s
                        behavior in the market for online advertising. And a
                        group of states is exploring a broader search case
                        against Google. Read the article to know about the suit.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <NewsSubscription categoryType="Web3" />
          </div>
        );
    }

}

export default NewsIssue50;
