import React, {useEffect, useState} from "react";
import {Button, Collapse, Modal, Form} from 'react-bootstrap';
import {withRouter} from "react-router-dom";
import PropTypes from "prop-types";
import WatchVideo from "../watch-video";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as contactAction from "../../action/contactAction";
import PhoneInput from 'react-phone-input-2'
// import 'react-phone-input-2/lib/style.css';
import "../../pages/talk-to-us/talk-to-us.css"
import {ROUTES} from "../../routes";

class ContactUS extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            name: '',
        email: '',
        companyName:'',
        phoneNumber: '',
        hearAboutUs: '',
        message: '',
        others:"",
        friendName:'',
        phoneErr: '',
        emailErr:"",
        processInformation: false,
        validated: false,
        shortLoader: false,
        hearAboutUsDropData: [
          "Google search",
          "Friend / Colleague",
          "You Tube",
         " Blog / Article",
          "Podcast / webinar",
          "Linkedin"
        ],
        hearAboutUsOpen:false
        }
        this.onSubmitHandler = this.onSubmitHandler.bind(this);
    }

    async onSubmitHandler(event) {
  
        if(this.state.phoneNumber.length < 8){
            this.setState({
                phoneErr: "Invalid phone number"
            })
        }else{
            const requestPayload = {
                emailID: this.state.email,
                name: this.state.name,
                companyName: this.state.companyName,
                mobileNumber: this.state.phoneNumber,
                message: this.state.message,
                refPerson: this.state.friendName,
                hearAboutUs: this.state.hearAboutUs,
                others:this.state.others,
                to: "info@ionixxtech.com",
                isMob:true
            };
            try {
                const response = await this.props.sendEmail(requestPayload);
                if(response.status === 200){
                    this.props.history.push({
                        pathname: ROUTES.TOAST,
                        contactDetails: true,
                        pdfDownload: false,
                        subscribeDetails:false
                      });
                    this.setState({
                        email: "",
                        name: "",
                        phoneNumber: "",
                        message: "",
                        hearAboutUs: "",
                        validated: false,
                        processInformation: false,
                        shortLoader: false
                    },()=>{
                        this.props.history.push(ROUTES.TOAST)
                    })
                    
                }
            }
            catch(err) {
                console.log(err);
            }
        }
           
        // }
    }

    render() {
        return (
            <React.Fragment>
           
            <Modal className="contact-modal theme-change" centered show={this.props.show} onHide={this.props.handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Connect with us</Modal.Title>
                    <span className="modal-side-close">
                    <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M19.6313 3.36869C17.4581 1.1955 14.5734 0 11.5 0C8.42656 0 5.54188 1.1955 3.36869 3.36869C1.1955 5.54188 0 8.42656 0 11.5C0 14.5734 1.1955 17.4581 3.36869 19.6313C5.54188 21.8045 8.42656 23 11.5 23C14.5734 23 17.4581 21.8045 19.6313 19.6313C21.8045 17.4581 23 14.5734 23 11.5C23 8.42656 21.8045 5.54188 19.6313 3.36869ZM18.7069 18.7069C16.7805 20.6332 14.2201 21.6932 11.5 21.6932C8.77988 21.6932 6.21949 20.6332 4.29314 18.7069C0.319444 14.7332 0.319444 8.26684 4.29314 4.29314C6.21949 2.36679 8.77988 1.30682 11.5 1.30682C14.2201 1.30682 16.7805 2.36679 18.7069 4.29314C22.6806 8.26684 22.6806 14.7332 18.7069 18.7069Z" fill="#C4C4C4"/>
                                            <path d="M16.5677 6.43238C16.3112 6.17586 15.8998 6.17586 15.6433 6.43238L11.5002 10.5755L7.35708 6.43238C7.10056 6.17586 6.68915 6.17586 6.43263 6.43238C6.1761 6.68891 6.1761 7.10031 6.43263 7.35684L10.5757 11.4999L6.43263 15.643C6.1761 15.8996 6.1761 16.311 6.43263 16.5675C6.55847 16.6933 6.72787 16.7611 6.89243 16.7611C7.057 16.7611 7.2264 16.6982 7.35224 16.5675L11.4953 12.4244L15.6384 16.5675C15.7643 16.6933 15.9337 16.7611 16.0983 16.7611C16.2677 16.7611 16.4322 16.6982 16.5581 16.5675C16.8146 16.311 16.8146 15.8996 16.5581 15.643L12.4246 11.4999L16.5677 7.35684C16.8243 7.10031 16.8243 6.68891 16.5677 6.43238Z" fill="#C4C4C4"/>
                                            </svg>
                    {/* <svg width="45" height="34" viewBox="0 0 45 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="5.5293" y="31.8203" width="45" height="3" transform="rotate(-45 5.5293 31.8203)"
                          fill="#444544"/>
                    <rect x="7.65039" width="45" height="3" transform="rotate(45 7.65039 0)" fill="#444544"/>
                    </svg> */}
                    </span>
                    {/* <img class="modal-side-close" src="assets/img/header/closeOrange.svg"></img> */}
                </Modal.Header>
                <Modal.Body>
                            <Form noValidate validated={this.state.validated} >
                        <Form.Group controlId="formBasicName">
                            <Form.Label className="contact-form-label">First Name*</Form.Label>
                            <Form.Control type="name" placeholder="Enter" title="First Name" value={this.state.name} required
                                          onChange={(e) => {
                                              this.setState({name: e.target.value})
                                          }} />
                           
                        </Form.Group>
                        <Form.Group controlId="formBasicEmail">
                            <Form.Label className="contact-form-label">Work email*</Form.Label>
                            <Form.Control type="email" placeholder="Enter" required
                                          onChange={(e) => {
                                            
                                              this.setState({email: e.target.value},()=>{
                                                let regex = new RegExp('^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$');
                                                if(!regex.test(this.state.email)){
                                                 this.setState({
                                                  emailErr:"Invalid Email"
                                                 })
                                                }else if(this.state.email.includes("gmail") || this.state.email.includes("yahoo")  ){
                                                  this.setState({
                                                    emailErr:"Please enter work email"
                                                   })
                                                }else{
                                                  this.setState({
                                                    emailErr:""
                                                   })
                                                }
                                              })
                                          }} onError={(err) => {
                                              console.log(err);
                                          }}/>
                                 {
                                this.state.emailErr.length >  0 ? <div className="err-msg">{this.state.emailErr}</div> : ""
                            }           
                         
                        </Form.Group>
                        <Form.Group controlId="formBasicEmail">
                            <Form.Label className="contact-form-label">Company name*</Form.Label>
                            <Form.Control type="name" placeholder="Enter" required
                                          onChange={(e) => {
                                              this.setState({companyName: e.target.value})
                                          }} onError={(err) => {
                                              console.log(err);
                                          }}/>
                            
                          
                        </Form.Group>
                        <Form.Group controlId="formBasicName" className="custom-rct-phn">
                            <Form.Label className="contact-form-label">Phone number*</Form.Label>
                            
                            <PhoneInput
                                country={'us'}
                                value={this.state.phoneNumber}
                                onChange={phoneNumber => this.setState({ phoneNumber, phoneErr:"" })}
                                enableSearch={false}
                                />              
                        
                            {
                                this.state.phoneErr.length >  0 ? <div className="err-msg">{this.state.phoneErr}</div> : ""
                            }
                        </Form.Group>

                      
                        <Form.Group controlId="formBasicName" className="c-form-select-grp">
                            <Form.Label className="contact-form-label">How did you hear about us?*</Form.Label>
                            <div className="d-flex justify-space-between c-form-select" as="select" placeholder="Select" onChange={(e) => {this.setState({hearAboutUs: e.target.value})}} 
                            onClick={()=>{
                              this.setState({
                                hearAboutUsOpen:!this.state.hearAboutUsOpen
                              })
                            }} >
                              <div className="selected-value">{this.state.others || this.state.hearAboutUs}</div>
                              <div className="cfsel-drop-span"><svg xmlns="http://www.w3.org/2000/svg" width="13" height="8" viewBox="0 0 13 8" fill="none">
<path fill-rule="evenodd" clip-rule="evenodd" d="M12.0694 1.55324L10.8357 0.442483L6.39331 5.37629L1.45943 0.933809L0.348677 2.16743L5.28255 6.60991L5.28195 6.61058L6.51557 7.72134L6.51617 7.72067L6.51678 7.72121L7.62754 6.48759L7.62693 6.48705L12.0694 1.55324Z" fill="#555555"/>
</svg>
                                </div>
                            </div>
                            
                            <div>
                              {
                                this.state.hearAboutUsOpen &&
                                <div className="select-option">
                              {this.state.hearAboutUsDropData?.map((data, i)=>{
                                  return <div className="d-block" onClick={()=>{
                                    this.setState({hearAboutUs:data,others:"", hearAboutUsOpen:false})
                                  }}>{data}</div>
                              })}
                               <Form.Control type="name" placeholder="Others"
                               value={this.state.others}
                                          onChange={(e) => {
                                              this.setState({others: e.target.value,hearAboutUs:""})
                                          }} 
                                          
                                          />
                            </div>
                              }
                            
                           
                            </div>
                           
                        </Form.Group>    
                        {
                          this.state.hearAboutUs === "Friend / Colleague" && 
                          <Form.Group controlId="formBasicName">
                          <Form.Label className="contact-form-label">Who is the awesome person? we can thank him later</Form.Label>
                          <Form.Control type="name" placeholder="Enter" value={this.state.friendName} 
                                        onChange={(e) => {
                                            this.setState({friendName: e.target.value})
                                        }} />
                        
                      </Form.Group>   
                        }
                                 
                        <Form.Group controlId="exampleForm.ControlTextarea1">
                            <Form.Label className="contact-form-label">Enter your message</Form.Label>
                            <Form.Control className="contact-form-txt-area" as="textarea" rows="3" maxlength="1000"
                                          value={this.state.message}
                                          onChange={(e) => {
                                              this.setState({message: e.target.value})
                                          }}/>
                        </Form.Group>
                        <Form.Group controlId="formBasicCheckbox">
                            <div className="form-element">
                                <div class="form-element sp-form pd-tb ripple-effect check-content ">
                                    <input class="inp-cbx" id="bx-2" type="checkbox"
                                           defaultChecked={this.state.processInformation}
                                           onChange={() => {
                                               this.setState((prevState) => ({processInformation: !prevState.processInformation}))
                                           }}>
                                    </input>
                                    <label class="cbx m-0 theme-change" for="bx-2">
                                {/* <span>
                                    <svg height="10px" viewBox="0 0 12 10" width="12px">
                                        <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                                    </svg>
                                </span> */}
                                        <span class="sp-txt-upload">&nbsp;</span>
                                        <span className="check-custom-text">I consent Ionixx to store and process the information I submit. </span>
                                    </label>
                                </div>
                            </div>
                            {/* <Form.Text className="text-muted">
                                We will never share your personal information with anyone else.
                            </Form.Text> */}
                        </Form.Group>
                        {/* <Form.Group className="contact-form-group">
                            <Button className="contact-submit" variant="primary" type="submit" disabled={!(this.state.processInformation && this.state.phoneNumber && this.state.email && this.state.name)}
                                    onClick={() => {
                                        this.setState({
                                            validated: true
                                        },()=>{
                                           this.onSubmitHandler
                                        })
                                    }}>
                                Submit {this.state.shortLoader ? <span className={"short-loader ml-2"}></span> :""}
                            </Button>
                        </Form.Group> */}
                    </Form>
                    <Button 
                    className="contact-submit" 
                    variant="primary" 
                    type="submit" 
                    disabled={!(this.state.processInformation && this.state.phoneNumber && this.state.email && this.state.name && this.state.companyName && (this.state.hearAboutUs || this.state.others) && !this.state.emailErr)}
                    onClick={()=>{this.onSubmitHandler()} }>
                      Submit {this.state.shortLoader ? <span className={"short-loader ml-2"}></span> :""}
                    </Button>
                </Modal.Body>
            </Modal>
            </React.Fragment>
        );
    }
}


ContactUS.defaultProps = {
    show: false,
    handleClose: () => {
    },
    toShowToast: () => {
    }
};

ContactUS.propTypes = {
    show: PropTypes.bool,
    handleClose: PropTypes.func,
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(contactAction, dispatch);
}
export default connect(null, mapDispatchToProps)(withRouter(ContactUS));

