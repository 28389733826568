import React, { useEffect } from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';

const RadioButtonsGroup = React.forwardRef(({postedValueChange,postedAt},ref) => {
  const [value, setValue] = React.useState('Today');

  useEffect(()=>{
    postedValueChange(value)
  },[value])
  useEffect(()=>{
    setValue(postedAt)
  },[])

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  return (
    <FormControl component="fieldset" className='dropdown-wrap' >
      {/* <FormLabel component="legend">Gender</FormLabel> */}
      <RadioGroup aria-label="gender" name="gender1" value={value} onChange={handleChange} ref={ref}>
        {/* <FormControlLabel value="Today" control={<Radio />} label="Today" /> */}
        <FormControlLabel value="Thisweek" control={<Radio />} label="This week" />
        <FormControlLabel value="Thismonth" control={<Radio />} label="This month" />
        <FormControlLabel value="Thisyear" control={<Radio />} label="This Year" />
        {/* <FormControlLabel value="Setup" control={<Radio />} label="Set up" /> */}
      </RadioGroup>
    </FormControl>
  );
});
export default  RadioButtonsGroup;
