import React from 'react';
import "../../resources.css";
import { ResourceRoute } from '../../resource-routing';
import { Link } from "react-router-dom";
import NewsHeadings from "./common-action/news-heading";
import NewsSubscription from "./common-action/subscripbe-section";

class NewsIssue70 extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            pageEnabled: false,
        }

    }

    render() {
        return (
          <div className="newdesigned_issue_details">
            <NewsHeadings
              categoryType="web3"
              buttondDetail="Web3 & Blockchain"
              description="Programming by Voice May Be the Next Frontier in Software Development | IEEE Spectrum"
            />

            <div className="newsissuedetailed">
              <div className="detailed-common">
                {/* <div className="back-to-common">
                            <span className="back-to-icon-hold">
                                <img src="/assets/img/resource/back-to-new.svg" alt="back to new"></img>
                            </span>
                            <Link to={ResourceRoute.NEWSLETTER}><span className="back-to-link">Newsletter</span></Link>
                        </div> */}

                <div className="news-letter whats-on-hold">
                  <div className="issue-main-head">
                    <h4>What’s on?</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.coindesk.com/ibm-crypto-custody-metaco-deutsche-bank"
                      >
                        <h4>
                          IBM Ventures Further Into Crypto Custody With METACO,
                          Deutsche Bank Tie-Ups | Coindesk.com
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        IBM, known for pitching banks and blue-chip firms on
                        private blockchain technology is taking decisive steps
                        toward working with the hard stuff. The 110-year-old
                        computing giant is licensing its software to METACO, a
                        Switzerland-based firm that specializes in custodying
                        digital assets for financial institutions, the companies
                        said Thursday. This is the business of safeguarding the
                        cryptographic private keys that control a cryptocurrency
                        wallet, the 21st-century version of protecting a vault
                        full of gold bars.
                      </p>
                    </div>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.darkreading.com/application-security/nearly-half-of-popular-android-apps-built-with-high-risk-components/d/d-id/1340522?_mc=rss_x_drr_edt_aud_dr_x_x-rss-simple"
                      >
                        <h4>
                          Nearly Half of Popular Android Apps Built With
                          High-Risk Components | Darkreading.com
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Almost all of the most popular Android applications use
                        open source components, but many of those components are
                        outdated and have at least one high-risk vulnerability,
                        according to an analysis of 3,335 mobile applications
                        published on Thursday by Synopsys. The software-security
                        firm analyzed the most popular Android apps in 18
                        categories — including gaming, financial, and
                        productivity apps — finding that 98% used open source
                        code, with an average of 20 components per application.{" "}
                      </p>
                    </div>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.businesswire.com/news/home/20210323006013/en/Citi-Launches-Automated-Securities-Lending-Solution-for-Wealth-Managers-in-Partnership-with-Fintech-Sharegain"
                      >
                        <h4>
                          {" "}
                          Citi Launches Automated Securities Lending Solution
                          for Wealth Managers in Partnership with Fintech
                          Sharegain | Businesswire.com{" "}
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Citi has launched the first fully automated securities
                        lending solution for wealth management firms they
                        custody, in partnership with FinTechfirm Sharegain.
                        Through Citi’s solution, wealth managers can deliver a
                        securities lending program to their customers, giving
                        them the opportunity to earn additional revenue on their
                        stocks, bonds and ETFs, without developing an internal
                        program from scratch.{" "}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="news-letter top-picks-hold">
                  <div className="issue-main-head">
                    <h4>Top Picks</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://spectrum.ieee.org/computing/software/programming-by-voice-may-be-the-next-frontier-in-software-development"
                      >
                        <h4>
                          Programming by Voice May Be the Next Frontier in
                          Software Development | IEEE Spectrum
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Increasingly, we’re interacting with our gadgets by
                        talking to them. Old friends like Alexa and Siri are now
                        being joined by automotive assistants like Apple CarPlay
                        and Android Auto, and even apps sensitive to voice
                        biometrics and commands. But what if the technology
                        itself could be built using voice? That’s the premise
                        behind voice coding, an approach to developing software
                        using voice instead of a keyboard and mouse to write
                        code.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="news-letter other-reads-hold">
                  <div className="issue-main-head">
                    <h4>Other Reads</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.nytimes.com/2021/03/25/opinion/biden-us-economy.html"
                      >
                        <h4>
                          The Biden Revolution Rolls On | The New York Times
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        The Biden administration is throwing up epic spending
                        plans at a bold, dazzling pace. The Biden administration
                        is transformational in two ways. First, it is fiscally
                        transformational. The second and more important
                        transformation is over the role of government. Should
                        government more actively direct investment? Should it
                        redistribute far more money to the disadvantaged for the
                        sake of common decency and to restore social cohesion?
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <NewsSubscription categoryType="Web3" />
          </div>
        );
    }

}

export default NewsIssue70;
