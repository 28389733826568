import React from 'react';
import "../../resources.css";
import {ResourceRoute} from '../../resource-routing';
import {Link} from "react-router-dom";
import NewsHeadings from "./common-action/news-heading";
import NewsSubscription from "./common-action/subscripbe-section";

class NewsIssue36 extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            pageEnabled: false,
        }

    }

    render() {
        return (
          <div className="newdesigned_issue_details">
            <NewsHeadings
              buttondDetail="FinTech"
              categoryType="fintech"
              description="Blockchain technology will remove barriers to instant international settlement | Banking Drive"
            />
            <div className="newsissuedetailed">
              <div className="detailed-common">
                {/* <div className="back-to-common">
                    <span className="back-to-icon-hold">
                    <img src="/assets/img/resource/back-to-new.svg"  alt="bact to new"></img>
                    </span>
                    <Link  to={ResourceRoute.NEWSLETTER}><span className="back-to-link">Newsletter</span></Link>
                </div> */}

                <div className="news-letter whats-on-hold">
                  <div className="issue-main-head">
                    <h4>What’s on?</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.theblockcrypto.com/linked/71992/beijing-blockchain-hub-2022"
                      >
                        <h4>
                          Beijing government releases its blueprint for becoming
                          a blockchain hub | The Block Crypto
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Beijing’s municipal government released a 145-page
                        blueprint today outlining a plan to become a hub for
                        blockchain innovation over the next two years. The
                        blueprint comes nearly a month after the city's
                        municipal government announced an initiative called the
                        “Beijing Blockchain Innovation Development Action Plan
                        (2020-2022),” which is aimed at making China’s capital
                        one of the first cities in the country to integrate
                        blockchain technology into the city’s economic
                        development
                      </p>
                    </div>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://cointelegraph.com/news/three-industries-that-will-be-blockchain-innovators-post-pandemic"
                      >
                        <h4>
                          Three Industries That Will Be Blockchain Innovators
                          Post-Pandemic | Coin Telegraph
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Fabio Canesin, co-founder of blockchain FinTechfirm
                        Nash, believes that as the world comes out of the
                        COVID-19 pandemic, blockchain technology offers a
                        “unique” opportunity to the public due to its
                        “borderless” nature. During an interview with
                        Cointelegraph, Canesin states that he sees the
                        “greatest” emerging use case in blockchain for three
                        core industries in a post-pandemic era: government,
                        nonprofit, and small-to-mid-size businesses.
                      </p>
                    </div>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.forbes.com/sites/forbestechcouncil/2020/07/13/tech-industry-get-ready-for-these-15-uiux-trends/#287b6b592ccb"
                      >
                        <h4>
                          Tech Industry: Get Ready For These 15 UI/UX Trends |
                          Forbes
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        There’s recently been a growing focus in the tech
                        industry on deliberate, thoughtful user interface/user
                        experience design, producing products and experiences
                        that are both easy to navigate and meaningful for users.
                        UI/UX design has emerged to become a science all its
                        own, and to remain competitive, companies need to stay
                        on top of trends.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="news-letter top-picks-hold">
                  <div className="issue-main-head">
                    <h4>Top Picks</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.bankingdive.com/news/blockchain-technology-instant-international-settlements/581726/"
                      >
                        <h4>
                          Blockchain technology will remove barriers to instant
                          international settlement | Banking Drive
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Barriers are springing up everywhere for remittance
                        companies and banks. Overcoming obstacles to
                        cross-border payments is especially daunting right now,
                        with slow settlement times, high fees and fresh
                        competition bringing new challenges. Participants on a
                        next-generation network can transact and have the
                        transaction visible and settled between parties in
                        minutes or even seconds instead of days, writes Haohan
                        Xu, the CEO of Apifiny.
                      </p>
                    </div>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://technical.ly/dc/2020/07/16/successful-cybersecurity-needs-good-user-experience-rarely-gets-it-scott-varho-3pillar-global-product-design/"
                      >
                        <h4>
                          Successful cybersecurity needs good user experience |
                          Technical.ly{" "}
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Cybersecurity is typically framed as too technical for
                        user experience. It is, after all, commonly designed by
                        engineers who know what they are doing for engineers
                        that know what they are doing. But isn’t that exactly
                        the thinking that led to the accident at Three Mile
                        Island? When it comes to cybersecurity, you can have the
                        best product on the market, but if it’s not easy to use
                        in the way the user is trying to use it, you are more
                        likely to confuse and frustrate your target users and
                        ultimately lead to a false set of security. The article
                        highlights the three questions any company should
                        consider when developing a software product.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="news-letter other-reads-hold">
                  <div className="issue-main-head">
                    <h4>Other Reads</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.theverge.com/interface/2020/7/15/21325708/twitter-hack-global-security-crisis-nuclear-war-bitcoin-scam"
                      >
                        <h4>
                          The massive Twitter hack could be a global security
                          crisis | The Verge
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Whatever Twitter eventually comes to say about the
                        events of July 15th, 2020, when it suffered the most
                        catastrophic security breach in company history, it must
                        be said that the events were set in motion years
                        ago.Among the hacked accounts were President Barack
                        Obama, Joe Biden, Amazon CEO Jeff Bezos, Bill Gates, the
                        Apple and Uber corporate accounts, and pop star Kanye
                        West.Within the first hours of the attack, people were
                        duped into sending more than $118,000 to the hackers. It
                        also seems possible that a great number of sensitive
                        direct messages could have been accessed by the
                        attackers.{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <NewsSubscription categoryType="FinTech" />
          </div>
        );
    }

}

export default NewsIssue36;
