import React from 'react';
import "./about-us.css"
import ContactUS from "../../components/contact-us";
// import { ToastComponent } from '../../components/common';
import * as contactAction from "../../action/contactAction";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ReactSEOMetaTags from 'react-seo-meta-tags';
import Helmet from 'react-helmet';
import {ROUTES} from "../../routes";
import {Link} from "react-router-dom";

class AboutUs extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            headerClassAdd: "",
            showContactUS:false,
            showToast: false
		}
		this.onScrollHeader = this.onScrollHeader.bind(this)
	}
	componentDidMount() {
		window.addEventListener('scroll', this.onScrollHeader);
	}
	
	componentWillUnmount() {
		window.removeEventListener('scroll', this.onScrollHeader);
	}
	onScrollHeader(){
	const winScroll =
    document.body.scrollTop || document.documentElement.scrollTop

  	const height =
		document.documentElement.scrollHeight -
		document.documentElement.clientHeight

	const scrolled = winScroll / height
			
	if(scrolled === 0){
		this.setState({
			headerClassAdd: ""
		})
	}else {
		this.setState({
			headerClassAdd: "scrollHeader"
		})
	}
    }
    sendToast(){
		this.setState({showContactUS:true})
	}
    render(){

        const metaTitle = "Ionixx Technologies Company Overview";
        const metaDescription = "Ionixx Technologies: A design-focused custom software solution company headquarted in USA, offering a consultative approach and on-time project delivery. Contact our expert team today.";
        return (
            <>	
            <Helmet>
                <title>{metaTitle}</title>
                <meta name="description" content={metaDescription} />
                <link rel="canonical" href="https://www.ionixxtech.com/company"/>
                <meta property="og:title" content={metaTitle} />
                <meta property="og:type" content={`website`} />
                <meta property="og:url" content={"https://www.ionixxtech.com/company"} />
                <meta property="og:description" content={metaDescription} />
                <meta property="og:image" content={"https://www.ionixxtech.com/assets/img/carousel-img/crypto-security.png"} />
            </Helmet>
            {/* <ReactSEOMetaTags
                render={(el) => <Helmet>{el}</Helmet>}
                website={{ 
                    name: "Ionixx Technologies", 
                    title:"Ionixx Technologies Company Overview", 
                    description: "Thank you for choosing Ionixx! Explore our custom software solutions and  tech expertise. Stay tuned for the latest insights and updates.",
                    type:"website",
                    image:'/assets/img/ionixx-logo.png',
                    url:"https://www.ionixxtech.com/company"
                }}
                twitter={{ 
                    twitter: "https://www.ionixxtech.com/company" }}
                />     */}
            <div className="about-us">
            <div className="section-1 common-hero-bg">
	        	<div className="custom-container">
		        	<div className="custom-section">
		        		<div className="main-head-div">
			        		<h1 className="main-head-1">
			        			<span className="secondary-span">Our Mission</span>
			        			<span className="primary-span">and Purpose</span>
			        		</h1>
		        		</div>
		        		<div className="main-para-div main-para-intro short-div">
		        			<p>It is our mission and purpose to deliver efficient, effective and elegant custom information technology <Link class="default-custom-link" to={ROUTES.HOME}>solutions</Link> and services to our customers - and we have succeeded in doing so by following our unique approach and upstanding principles.</p>
		        		</div>
						{/* <div className="main-para-div">
		        			<button className="btn btn-primary connect-btn" type=""><span>Connect with us</span></button>
		        		</div> */}
		        	</div>
	        	</div>
	        </div>
            <div className="section-2 blue-bg">
	        	<div className="custom-container">
		        	<div className="custom-section">
		        		<div className="main-head-div">
			        		<h4 className="main-head-1">
			        			<span className="secondary-span">Our Guiding</span>
			        			<span className="primary-span">Principles</span>
			        		</h4>
		        		</div>
                        <div className="principles-common-hold">
                            <ul className="principles-common-ul">
                                <li>
                                    <div className="principles-info-hold">
                                        <h4><span>Transparency</span></h4>
                                        <p>Visibility into the entire depth of the workings of a project and consistent communication with all team members keeps our customers well informed of not only their project but the culture and mindset of Ionixx.</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="principles-info-hold">
                                        <h4><span>Flexibility</span></h4>
                                        <p>We understand the requirements change in most projects. We make it easy to swap features, change the design, add a module, etc.  to ensure the application is immediately effective.</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="principles-info-hold">
                                        <h4><span>Mutually beneficial, long-term relationships</span></h4>
                                        <p>Over 80% of our customers give us repeat business because we find creative ways to meet their needs and enhance their experience.</p>
                                    </div>
                                </li>
                                <li>
                                    <div className="principles-info-hold">
                                        <h4><span>Highly skilled workforce</span></h4>
                                        <p>We are a set of bright individuals who not only excel in their field of expertise but are critical thinkers who will question and ponder to find superior <Link class="" to={ROUTES.HOME}>solutions.</Link> </p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="section-3 blue-bg pad-sec">
	        	<div className="custom-container">
		        	<div className="custom-section">
		        		<div className="main-head-div">
			        		<h4 className="main-head-1">
			        			<span className="secondary-span">Meet Our</span>
			        			<span className="primary-span">Team</span>
			        		</h4>
		        		</div>
                        <div className="team-profile-hold">
                            <ul className="team-profile-ul">
                                <li> 
                                    <div className="prof-pic-hold">
                                        <img src="assets/img/team-picturess/mohan.png" alt="Mohan Kumar Subramaniyam"></img>
                                    </div>
                                    <div className="prof-pic-info">
                                        <h4>Mohan Kumar Subramaniyam</h4>
                                        <p>Chairman</p>
                                    </div>
                                </li>
                                <li> 
                                    <div className="prof-pic-hold">
                                        <img src="assets/img/team-picturess/manju.png" alt="Manju Mohan"></img>
                                    </div>
                                    <div className="prof-pic-info">
                                        <h4>Manju Mohan</h4>
                                        <p>CEO & Co-Founder</p>
                                    </div>
                                </li>
                                <li> 
                                    <div className="prof-pic-hold">
                                        <img src="assets/img/team-picturess/swathi.png" alt="Swathi Mohan"></img>
                                    </div>
                                    <div className="prof-pic-info">
                                        <h4>Swathi Mohan</h4>
                                        <p>VP - Growth & Co-Founder</p>
                                    </div>
                                </li>
                                <li> 
                                    <div className="prof-pic-hold img-prof-pic">
                                        <img src="assets/img/team-picturess/chippy.png" alt="Chippy Diac"></img>
                                    </div>
                                    <div className="prof-pic-info">
                                        <h4>Chippy Diac</h4>
                                        <p>VP of Design</p>
                                    </div>
                                </li>
                                <li> 
                                    <div className="prof-pic-hold">
                                        <img src="assets/img/team-picturess/bala.png" alt="Bala S"></img>
                                    </div>
                                    <div className="prof-pic-info">
                                        <h4>Bala S</h4>
                                        <p>VP of Technology</p>
                                    </div>
                                </li>
                            </ul>
                        </div>
                        
                         <div className="expert-talk-hold">
                            <p>Interested to see what Ionixx can do for your company?</p>
                            <button className="connect-btn" type="" onClick={()=>this.setState({showContactUS:true})}><span>Talk to an expert</span></button>
                        </div>
                        
                    </div>
                </div>
            </div> */}
            <div className="section-4 beige-bg pad-sec">
	        	<div className="custom-container">
		        	<div className="custom-section">
		        		<div className="main-head-div">
			        		<h4 className="main-head-1">
			        			<span className="secondary-span">Our Team of</span>
			        			<span className="primary-span">Professionals</span>
			        		</h4>
		        		</div>
                        <div className="professionals-hold">
                            <ul className="professionals-ul">
                                <li>
                                    <div className="professionals-num">
                                         {/* <img src="assets/img/list.png"/> */}
                                         <span><svg width="36" height="42" viewBox="0 0 36 42" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M21.8162 8.32623C22.963 7.48588 24.4494 6.6302 26.4469 5.71716C28.7975 4.86938 30.4067 4.9449 31.3388 5.94447C31.5294 6.36721 31.6453 6.81938 31.6811 7.28099C31.7167 7.65718 31.5377 8.56617 31.3911 9.05121C30.2582 7.83102 26.2116 7.91635 22.369 9.70264C21.6549 10.0356 20.3936 9.35666 21.8153 8.32965" fill="#FFCC4A"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M19.6699 9.89779C19.8949 9.95772 20.097 10.0831 20.2508 10.2579C20.4045 10.4328 20.5029 10.6494 20.5334 10.8802C20.564 11.1111 20.5254 11.3458 20.4225 11.5547C20.3196 11.7635 20.157 11.9372 19.9554 12.0536C19.7537 12.17 19.522 12.224 19.2897 12.2087C19.0573 12.1934 18.8348 12.1095 18.6501 11.9676C18.4655 11.8257 18.3272 11.6322 18.2526 11.4116C18.178 11.191 18.1705 10.9533 18.2311 10.7285C18.3118 10.4275 18.5086 10.1709 18.7785 10.0152C19.0483 9.85937 19.3689 9.81716 19.6699 9.89779Z" fill="#FFCC4A"/>
<circle cx="18" cy="20" r="9" fill="#24A989"/>
<path d="M6.20092 32.2395C7.24614 31.7809 8.06345 31.3535 9.10404 30.7607C16.4597 26.5709 22.2584 21.879 28.2652 15.1024C29.1029 14.1653 29.7223 13.4097 30.4546 12.4388C31.3797 11.3478 33.0181 8.98526 32.6754 7.35881L32.375 6.22197L34.7087 10.0902C35.7113 11.7457 32.117 15.8796 30.678 17.808C28.7761 20.2107 25.9438 22.9606 22.7758 25.6206L20.132 27.7635C13.0381 33.2756 4.50425 39.3288 0.516611 32.2363L1.33431 32.9503C2.53484 33.874 5.00473 32.883 6.20092 32.2395Z" fill="#1E4B5E"/>
</svg>
</span>
                                    </div>
                                    <div className="professionals-info">
                                        <h4>UX Designers</h4> 
                                    </div>
                                </li>
                                <li>
                                <   div className="professionals-num">
                                         {/* <img src="assets/img/list.png"/> */}
                                         <span><svg width="36" height="42" viewBox="0 0 36 42" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M21.8162 8.32623C22.963 7.48588 24.4494 6.6302 26.4469 5.71716C28.7975 4.86938 30.4067 4.9449 31.3388 5.94447C31.5294 6.36721 31.6453 6.81938 31.6811 7.28099C31.7167 7.65718 31.5377 8.56617 31.3911 9.05121C30.2582 7.83102 26.2116 7.91635 22.369 9.70264C21.6549 10.0356 20.3936 9.35666 21.8153 8.32965" fill="#FFCC4A"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M19.6699 9.89779C19.8949 9.95772 20.097 10.0831 20.2508 10.2579C20.4045 10.4328 20.5029 10.6494 20.5334 10.8802C20.564 11.1111 20.5254 11.3458 20.4225 11.5547C20.3196 11.7635 20.157 11.9372 19.9554 12.0536C19.7537 12.17 19.522 12.224 19.2897 12.2087C19.0573 12.1934 18.8348 12.1095 18.6501 11.9676C18.4655 11.8257 18.3272 11.6322 18.2526 11.4116C18.178 11.191 18.1705 10.9533 18.2311 10.7285C18.3118 10.4275 18.5086 10.1709 18.7785 10.0152C19.0483 9.85937 19.3689 9.81716 19.6699 9.89779Z" fill="#FFCC4A"/>
<circle cx="18" cy="20" r="9" fill="#24A989"/>
<path d="M6.20092 32.2395C7.24614 31.7809 8.06345 31.3535 9.10404 30.7607C16.4597 26.5709 22.2584 21.879 28.2652 15.1024C29.1029 14.1653 29.7223 13.4097 30.4546 12.4388C31.3797 11.3478 33.0181 8.98526 32.6754 7.35881L32.375 6.22197L34.7087 10.0902C35.7113 11.7457 32.117 15.8796 30.678 17.808C28.7761 20.2107 25.9438 22.9606 22.7758 25.6206L20.132 27.7635C13.0381 33.2756 4.50425 39.3288 0.516611 32.2363L1.33431 32.9503C2.53484 33.874 5.00473 32.883 6.20092 32.2395Z" fill="#1E4B5E"/>
</svg>
</span>
                                    </div>
                                    <div className="professionals-info">
                                        <h4>UI Designers</h4> 
                                    </div>
                                </li>
                                <li>
                                <div className="professionals-num">
                                         {/* <img src="assets/img/list.png"/> */}
                                         <span><svg width="36" height="42" viewBox="0 0 36 42" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M21.8162 8.32623C22.963 7.48588 24.4494 6.6302 26.4469 5.71716C28.7975 4.86938 30.4067 4.9449 31.3388 5.94447C31.5294 6.36721 31.6453 6.81938 31.6811 7.28099C31.7167 7.65718 31.5377 8.56617 31.3911 9.05121C30.2582 7.83102 26.2116 7.91635 22.369 9.70264C21.6549 10.0356 20.3936 9.35666 21.8153 8.32965" fill="#FFCC4A"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M19.6699 9.89779C19.8949 9.95772 20.097 10.0831 20.2508 10.2579C20.4045 10.4328 20.5029 10.6494 20.5334 10.8802C20.564 11.1111 20.5254 11.3458 20.4225 11.5547C20.3196 11.7635 20.157 11.9372 19.9554 12.0536C19.7537 12.17 19.522 12.224 19.2897 12.2087C19.0573 12.1934 18.8348 12.1095 18.6501 11.9676C18.4655 11.8257 18.3272 11.6322 18.2526 11.4116C18.178 11.191 18.1705 10.9533 18.2311 10.7285C18.3118 10.4275 18.5086 10.1709 18.7785 10.0152C19.0483 9.85937 19.3689 9.81716 19.6699 9.89779Z" fill="#FFCC4A"/>
<circle cx="18" cy="20" r="9" fill="#24A989"/>
<path d="M6.20092 32.2395C7.24614 31.7809 8.06345 31.3535 9.10404 30.7607C16.4597 26.5709 22.2584 21.879 28.2652 15.1024C29.1029 14.1653 29.7223 13.4097 30.4546 12.4388C31.3797 11.3478 33.0181 8.98526 32.6754 7.35881L32.375 6.22197L34.7087 10.0902C35.7113 11.7457 32.117 15.8796 30.678 17.808C28.7761 20.2107 25.9438 22.9606 22.7758 25.6206L20.132 27.7635C13.0381 33.2756 4.50425 39.3288 0.516611 32.2363L1.33431 32.9503C2.53484 33.874 5.00473 32.883 6.20092 32.2395Z" fill="#1E4B5E"/>
</svg>
</span>
                                    </div>
                                    <div className="professionals-info">
                                        <h4>Graphic Designers</h4> 
                                    </div>
                                </li>
                                <li>
                                <div className="professionals-num">
                                         {/* <img src="assets/img/list.png"/> */}
                                         <span><svg width="36" height="42" viewBox="0 0 36 42" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M21.8162 8.32623C22.963 7.48588 24.4494 6.6302 26.4469 5.71716C28.7975 4.86938 30.4067 4.9449 31.3388 5.94447C31.5294 6.36721 31.6453 6.81938 31.6811 7.28099C31.7167 7.65718 31.5377 8.56617 31.3911 9.05121C30.2582 7.83102 26.2116 7.91635 22.369 9.70264C21.6549 10.0356 20.3936 9.35666 21.8153 8.32965" fill="#FFCC4A"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M19.6699 9.89779C19.8949 9.95772 20.097 10.0831 20.2508 10.2579C20.4045 10.4328 20.5029 10.6494 20.5334 10.8802C20.564 11.1111 20.5254 11.3458 20.4225 11.5547C20.3196 11.7635 20.157 11.9372 19.9554 12.0536C19.7537 12.17 19.522 12.224 19.2897 12.2087C19.0573 12.1934 18.8348 12.1095 18.6501 11.9676C18.4655 11.8257 18.3272 11.6322 18.2526 11.4116C18.178 11.191 18.1705 10.9533 18.2311 10.7285C18.3118 10.4275 18.5086 10.1709 18.7785 10.0152C19.0483 9.85937 19.3689 9.81716 19.6699 9.89779Z" fill="#FFCC4A"/>
<circle cx="18" cy="20" r="9" fill="#24A989"/>
<path d="M6.20092 32.2395C7.24614 31.7809 8.06345 31.3535 9.10404 30.7607C16.4597 26.5709 22.2584 21.879 28.2652 15.1024C29.1029 14.1653 29.7223 13.4097 30.4546 12.4388C31.3797 11.3478 33.0181 8.98526 32.6754 7.35881L32.375 6.22197L34.7087 10.0902C35.7113 11.7457 32.117 15.8796 30.678 17.808C28.7761 20.2107 25.9438 22.9606 22.7758 25.6206L20.132 27.7635C13.0381 33.2756 4.50425 39.3288 0.516611 32.2363L1.33431 32.9503C2.53484 33.874 5.00473 32.883 6.20092 32.2395Z" fill="#1E4B5E"/>
</svg>
</span>
                                    </div>
                                    <div className="professionals-info">
                                        <h4>Technology Architects</h4> 
                                    </div>
                                </li>
                                <li>
                                <div className="professionals-num">
                                         {/* <img src="assets/img/list.png"/> */}
                                         <span><svg width="36" height="42" viewBox="0 0 36 42" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M21.8162 8.32623C22.963 7.48588 24.4494 6.6302 26.4469 5.71716C28.7975 4.86938 30.4067 4.9449 31.3388 5.94447C31.5294 6.36721 31.6453 6.81938 31.6811 7.28099C31.7167 7.65718 31.5377 8.56617 31.3911 9.05121C30.2582 7.83102 26.2116 7.91635 22.369 9.70264C21.6549 10.0356 20.3936 9.35666 21.8153 8.32965" fill="#FFCC4A"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M19.6699 9.89779C19.8949 9.95772 20.097 10.0831 20.2508 10.2579C20.4045 10.4328 20.5029 10.6494 20.5334 10.8802C20.564 11.1111 20.5254 11.3458 20.4225 11.5547C20.3196 11.7635 20.157 11.9372 19.9554 12.0536C19.7537 12.17 19.522 12.224 19.2897 12.2087C19.0573 12.1934 18.8348 12.1095 18.6501 11.9676C18.4655 11.8257 18.3272 11.6322 18.2526 11.4116C18.178 11.191 18.1705 10.9533 18.2311 10.7285C18.3118 10.4275 18.5086 10.1709 18.7785 10.0152C19.0483 9.85937 19.3689 9.81716 19.6699 9.89779Z" fill="#FFCC4A"/>
<circle cx="18" cy="20" r="9" fill="#24A989"/>
<path d="M6.20092 32.2395C7.24614 31.7809 8.06345 31.3535 9.10404 30.7607C16.4597 26.5709 22.2584 21.879 28.2652 15.1024C29.1029 14.1653 29.7223 13.4097 30.4546 12.4388C31.3797 11.3478 33.0181 8.98526 32.6754 7.35881L32.375 6.22197L34.7087 10.0902C35.7113 11.7457 32.117 15.8796 30.678 17.808C28.7761 20.2107 25.9438 22.9606 22.7758 25.6206L20.132 27.7635C13.0381 33.2756 4.50425 39.3288 0.516611 32.2363L1.33431 32.9503C2.53484 33.874 5.00473 32.883 6.20092 32.2395Z" fill="#1E4B5E"/>
</svg>
</span>
                                    </div>
                                    <div className="professionals-info">
                                        <h4>Mobile Developers</h4> 
                                    </div>
                                </li>
                                <li>
                                <div className="professionals-num">
                                         {/* <img src="assets/img/list.png"/> */}
                                         <span><svg width="36" height="42" viewBox="0 0 36 42" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M21.8162 8.32623C22.963 7.48588 24.4494 6.6302 26.4469 5.71716C28.7975 4.86938 30.4067 4.9449 31.3388 5.94447C31.5294 6.36721 31.6453 6.81938 31.6811 7.28099C31.7167 7.65718 31.5377 8.56617 31.3911 9.05121C30.2582 7.83102 26.2116 7.91635 22.369 9.70264C21.6549 10.0356 20.3936 9.35666 21.8153 8.32965" fill="#FFCC4A"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M19.6699 9.89779C19.8949 9.95772 20.097 10.0831 20.2508 10.2579C20.4045 10.4328 20.5029 10.6494 20.5334 10.8802C20.564 11.1111 20.5254 11.3458 20.4225 11.5547C20.3196 11.7635 20.157 11.9372 19.9554 12.0536C19.7537 12.17 19.522 12.224 19.2897 12.2087C19.0573 12.1934 18.8348 12.1095 18.6501 11.9676C18.4655 11.8257 18.3272 11.6322 18.2526 11.4116C18.178 11.191 18.1705 10.9533 18.2311 10.7285C18.3118 10.4275 18.5086 10.1709 18.7785 10.0152C19.0483 9.85937 19.3689 9.81716 19.6699 9.89779Z" fill="#FFCC4A"/>
<circle cx="18" cy="20" r="9" fill="#24A989"/>
<path d="M6.20092 32.2395C7.24614 31.7809 8.06345 31.3535 9.10404 30.7607C16.4597 26.5709 22.2584 21.879 28.2652 15.1024C29.1029 14.1653 29.7223 13.4097 30.4546 12.4388C31.3797 11.3478 33.0181 8.98526 32.6754 7.35881L32.375 6.22197L34.7087 10.0902C35.7113 11.7457 32.117 15.8796 30.678 17.808C28.7761 20.2107 25.9438 22.9606 22.7758 25.6206L20.132 27.7635C13.0381 33.2756 4.50425 39.3288 0.516611 32.2363L1.33431 32.9503C2.53484 33.874 5.00473 32.883 6.20092 32.2395Z" fill="#1E4B5E"/>
</svg>
</span>
                                    </div>
                                    <div className="professionals-info">
                                        <h4>Web developers</h4> 
                                    </div>
                                </li>
                                <li>
                                <div className="professionals-num">
                                         {/* <img src="assets/img/list.png"/> */}
                                         <span><svg width="36" height="42" viewBox="0 0 36 42" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M21.8162 8.32623C22.963 7.48588 24.4494 6.6302 26.4469 5.71716C28.7975 4.86938 30.4067 4.9449 31.3388 5.94447C31.5294 6.36721 31.6453 6.81938 31.6811 7.28099C31.7167 7.65718 31.5377 8.56617 31.3911 9.05121C30.2582 7.83102 26.2116 7.91635 22.369 9.70264C21.6549 10.0356 20.3936 9.35666 21.8153 8.32965" fill="#FFCC4A"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M19.6699 9.89779C19.8949 9.95772 20.097 10.0831 20.2508 10.2579C20.4045 10.4328 20.5029 10.6494 20.5334 10.8802C20.564 11.1111 20.5254 11.3458 20.4225 11.5547C20.3196 11.7635 20.157 11.9372 19.9554 12.0536C19.7537 12.17 19.522 12.224 19.2897 12.2087C19.0573 12.1934 18.8348 12.1095 18.6501 11.9676C18.4655 11.8257 18.3272 11.6322 18.2526 11.4116C18.178 11.191 18.1705 10.9533 18.2311 10.7285C18.3118 10.4275 18.5086 10.1709 18.7785 10.0152C19.0483 9.85937 19.3689 9.81716 19.6699 9.89779Z" fill="#FFCC4A"/>
<circle cx="18" cy="20" r="9" fill="#24A989"/>
<path d="M6.20092 32.2395C7.24614 31.7809 8.06345 31.3535 9.10404 30.7607C16.4597 26.5709 22.2584 21.879 28.2652 15.1024C29.1029 14.1653 29.7223 13.4097 30.4546 12.4388C31.3797 11.3478 33.0181 8.98526 32.6754 7.35881L32.375 6.22197L34.7087 10.0902C35.7113 11.7457 32.117 15.8796 30.678 17.808C28.7761 20.2107 25.9438 22.9606 22.7758 25.6206L20.132 27.7635C13.0381 33.2756 4.50425 39.3288 0.516611 32.2363L1.33431 32.9503C2.53484 33.874 5.00473 32.883 6.20092 32.2395Z" fill="#1E4B5E"/>
</svg>
</span>
                                    </div>
                                    <div className="professionals-info">
                                        <h4>Backend Engineers</h4> 
                                    </div>
                                </li>
                                <li>
                                <div className="professionals-num">
                                         {/* <img src="assets/img/list.png"/> */}
                                         <span><svg width="36" height="42" viewBox="0 0 36 42" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M21.8162 8.32623C22.963 7.48588 24.4494 6.6302 26.4469 5.71716C28.7975 4.86938 30.4067 4.9449 31.3388 5.94447C31.5294 6.36721 31.6453 6.81938 31.6811 7.28099C31.7167 7.65718 31.5377 8.56617 31.3911 9.05121C30.2582 7.83102 26.2116 7.91635 22.369 9.70264C21.6549 10.0356 20.3936 9.35666 21.8153 8.32965" fill="#FFCC4A"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M19.6699 9.89779C19.8949 9.95772 20.097 10.0831 20.2508 10.2579C20.4045 10.4328 20.5029 10.6494 20.5334 10.8802C20.564 11.1111 20.5254 11.3458 20.4225 11.5547C20.3196 11.7635 20.157 11.9372 19.9554 12.0536C19.7537 12.17 19.522 12.224 19.2897 12.2087C19.0573 12.1934 18.8348 12.1095 18.6501 11.9676C18.4655 11.8257 18.3272 11.6322 18.2526 11.4116C18.178 11.191 18.1705 10.9533 18.2311 10.7285C18.3118 10.4275 18.5086 10.1709 18.7785 10.0152C19.0483 9.85937 19.3689 9.81716 19.6699 9.89779Z" fill="#FFCC4A"/>
<circle cx="18" cy="20" r="9" fill="#24A989"/>
<path d="M6.20092 32.2395C7.24614 31.7809 8.06345 31.3535 9.10404 30.7607C16.4597 26.5709 22.2584 21.879 28.2652 15.1024C29.1029 14.1653 29.7223 13.4097 30.4546 12.4388C31.3797 11.3478 33.0181 8.98526 32.6754 7.35881L32.375 6.22197L34.7087 10.0902C35.7113 11.7457 32.117 15.8796 30.678 17.808C28.7761 20.2107 25.9438 22.9606 22.7758 25.6206L20.132 27.7635C13.0381 33.2756 4.50425 39.3288 0.516611 32.2363L1.33431 32.9503C2.53484 33.874 5.00473 32.883 6.20092 32.2395Z" fill="#1E4B5E"/>
</svg>
</span>
                                    </div>
                                    <div className="professionals-info">
                                        <h4>Product Managers</h4> 
                                    </div>
                                </li>
                                <li>
                                <div className="professionals-num">
                                         {/* <img src="assets/img/list.png"/> */}
                                         <span><svg width="36" height="42" viewBox="0 0 36 42" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M21.8162 8.32623C22.963 7.48588 24.4494 6.6302 26.4469 5.71716C28.7975 4.86938 30.4067 4.9449 31.3388 5.94447C31.5294 6.36721 31.6453 6.81938 31.6811 7.28099C31.7167 7.65718 31.5377 8.56617 31.3911 9.05121C30.2582 7.83102 26.2116 7.91635 22.369 9.70264C21.6549 10.0356 20.3936 9.35666 21.8153 8.32965" fill="#FFCC4A"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M19.6699 9.89779C19.8949 9.95772 20.097 10.0831 20.2508 10.2579C20.4045 10.4328 20.5029 10.6494 20.5334 10.8802C20.564 11.1111 20.5254 11.3458 20.4225 11.5547C20.3196 11.7635 20.157 11.9372 19.9554 12.0536C19.7537 12.17 19.522 12.224 19.2897 12.2087C19.0573 12.1934 18.8348 12.1095 18.6501 11.9676C18.4655 11.8257 18.3272 11.6322 18.2526 11.4116C18.178 11.191 18.1705 10.9533 18.2311 10.7285C18.3118 10.4275 18.5086 10.1709 18.7785 10.0152C19.0483 9.85937 19.3689 9.81716 19.6699 9.89779Z" fill="#FFCC4A"/>
<circle cx="18" cy="20" r="9" fill="#24A989"/>
<path d="M6.20092 32.2395C7.24614 31.7809 8.06345 31.3535 9.10404 30.7607C16.4597 26.5709 22.2584 21.879 28.2652 15.1024C29.1029 14.1653 29.7223 13.4097 30.4546 12.4388C31.3797 11.3478 33.0181 8.98526 32.6754 7.35881L32.375 6.22197L34.7087 10.0902C35.7113 11.7457 32.117 15.8796 30.678 17.808C28.7761 20.2107 25.9438 22.9606 22.7758 25.6206L20.132 27.7635C13.0381 33.2756 4.50425 39.3288 0.516611 32.2363L1.33431 32.9503C2.53484 33.874 5.00473 32.883 6.20092 32.2395Z" fill="#1E4B5E"/>
</svg>
</span>
                                    </div>
                                    <div className="professionals-info">
                                        <h4>Quality Assurance Specialists</h4> 
                                    </div>
                                </li>
                                <li>
                                <div className="professionals-num">
                                         {/* <img src="assets/img/list.png"/> */}
                                         <span><svg width="36" height="42" viewBox="0 0 36 42" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M21.8162 8.32623C22.963 7.48588 24.4494 6.6302 26.4469 5.71716C28.7975 4.86938 30.4067 4.9449 31.3388 5.94447C31.5294 6.36721 31.6453 6.81938 31.6811 7.28099C31.7167 7.65718 31.5377 8.56617 31.3911 9.05121C30.2582 7.83102 26.2116 7.91635 22.369 9.70264C21.6549 10.0356 20.3936 9.35666 21.8153 8.32965" fill="#FFCC4A"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M19.6699 9.89779C19.8949 9.95772 20.097 10.0831 20.2508 10.2579C20.4045 10.4328 20.5029 10.6494 20.5334 10.8802C20.564 11.1111 20.5254 11.3458 20.4225 11.5547C20.3196 11.7635 20.157 11.9372 19.9554 12.0536C19.7537 12.17 19.522 12.224 19.2897 12.2087C19.0573 12.1934 18.8348 12.1095 18.6501 11.9676C18.4655 11.8257 18.3272 11.6322 18.2526 11.4116C18.178 11.191 18.1705 10.9533 18.2311 10.7285C18.3118 10.4275 18.5086 10.1709 18.7785 10.0152C19.0483 9.85937 19.3689 9.81716 19.6699 9.89779Z" fill="#FFCC4A"/>
<circle cx="18" cy="20" r="9" fill="#24A989"/>
<path d="M6.20092 32.2395C7.24614 31.7809 8.06345 31.3535 9.10404 30.7607C16.4597 26.5709 22.2584 21.879 28.2652 15.1024C29.1029 14.1653 29.7223 13.4097 30.4546 12.4388C31.3797 11.3478 33.0181 8.98526 32.6754 7.35881L32.375 6.22197L34.7087 10.0902C35.7113 11.7457 32.117 15.8796 30.678 17.808C28.7761 20.2107 25.9438 22.9606 22.7758 25.6206L20.132 27.7635C13.0381 33.2756 4.50425 39.3288 0.516611 32.2363L1.33431 32.9503C2.53484 33.874 5.00473 32.883 6.20092 32.2395Z" fill="#1E4B5E"/>
</svg>
</span>
                                    </div>
                                    <div className="professionals-info">
                                        <h4>Finance Consultants</h4> 
                                    </div>
                                </li>
                                <li>
                                <div className="professionals-num">
                                         {/* <img src="assets/img/list.png"/> */}
                                         <span><svg width="36" height="42" viewBox="0 0 36 42" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M21.8162 8.32623C22.963 7.48588 24.4494 6.6302 26.4469 5.71716C28.7975 4.86938 30.4067 4.9449 31.3388 5.94447C31.5294 6.36721 31.6453 6.81938 31.6811 7.28099C31.7167 7.65718 31.5377 8.56617 31.3911 9.05121C30.2582 7.83102 26.2116 7.91635 22.369 9.70264C21.6549 10.0356 20.3936 9.35666 21.8153 8.32965" fill="#FFCC4A"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M19.6699 9.89779C19.8949 9.95772 20.097 10.0831 20.2508 10.2579C20.4045 10.4328 20.5029 10.6494 20.5334 10.8802C20.564 11.1111 20.5254 11.3458 20.4225 11.5547C20.3196 11.7635 20.157 11.9372 19.9554 12.0536C19.7537 12.17 19.522 12.224 19.2897 12.2087C19.0573 12.1934 18.8348 12.1095 18.6501 11.9676C18.4655 11.8257 18.3272 11.6322 18.2526 11.4116C18.178 11.191 18.1705 10.9533 18.2311 10.7285C18.3118 10.4275 18.5086 10.1709 18.7785 10.0152C19.0483 9.85937 19.3689 9.81716 19.6699 9.89779Z" fill="#FFCC4A"/>
<circle cx="18" cy="20" r="9" fill="#24A989"/>
<path d="M6.20092 32.2395C7.24614 31.7809 8.06345 31.3535 9.10404 30.7607C16.4597 26.5709 22.2584 21.879 28.2652 15.1024C29.1029 14.1653 29.7223 13.4097 30.4546 12.4388C31.3797 11.3478 33.0181 8.98526 32.6754 7.35881L32.375 6.22197L34.7087 10.0902C35.7113 11.7457 32.117 15.8796 30.678 17.808C28.7761 20.2107 25.9438 22.9606 22.7758 25.6206L20.132 27.7635C13.0381 33.2756 4.50425 39.3288 0.516611 32.2363L1.33431 32.9503C2.53484 33.874 5.00473 32.883 6.20092 32.2395Z" fill="#1E4B5E"/>
</svg>
</span>
                                    </div>
                                    <div className="professionals-info">
                                        <h4>Healthcare & Clinical Consultants</h4> 
                                    </div>
                                </li>
                                <li>
                                <div className="professionals-num">
                                         {/* <img src="assets/img/list.png"/> */}
                                         <span><svg width="36" height="42" viewBox="0 0 36 42" fill="none" xmlns="http://www.w3.org/2000/svg">
<path fill-rule="evenodd" clip-rule="evenodd" d="M21.8162 8.32623C22.963 7.48588 24.4494 6.6302 26.4469 5.71716C28.7975 4.86938 30.4067 4.9449 31.3388 5.94447C31.5294 6.36721 31.6453 6.81938 31.6811 7.28099C31.7167 7.65718 31.5377 8.56617 31.3911 9.05121C30.2582 7.83102 26.2116 7.91635 22.369 9.70264C21.6549 10.0356 20.3936 9.35666 21.8153 8.32965" fill="#FFCC4A"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M19.6699 9.89779C19.8949 9.95772 20.097 10.0831 20.2508 10.2579C20.4045 10.4328 20.5029 10.6494 20.5334 10.8802C20.564 11.1111 20.5254 11.3458 20.4225 11.5547C20.3196 11.7635 20.157 11.9372 19.9554 12.0536C19.7537 12.17 19.522 12.224 19.2897 12.2087C19.0573 12.1934 18.8348 12.1095 18.6501 11.9676C18.4655 11.8257 18.3272 11.6322 18.2526 11.4116C18.178 11.191 18.1705 10.9533 18.2311 10.7285C18.3118 10.4275 18.5086 10.1709 18.7785 10.0152C19.0483 9.85937 19.3689 9.81716 19.6699 9.89779Z" fill="#FFCC4A"/>
<circle cx="18" cy="20" r="9" fill="#24A989"/>
<path d="M6.20092 32.2395C7.24614 31.7809 8.06345 31.3535 9.10404 30.7607C16.4597 26.5709 22.2584 21.879 28.2652 15.1024C29.1029 14.1653 29.7223 13.4097 30.4546 12.4388C31.3797 11.3478 33.0181 8.98526 32.6754 7.35881L32.375 6.22197L34.7087 10.0902C35.7113 11.7457 32.117 15.8796 30.678 17.808C28.7761 20.2107 25.9438 22.9606 22.7758 25.6206L20.132 27.7635C13.0381 33.2756 4.50425 39.3288 0.516611 32.2363L1.33431 32.9503C2.53484 33.874 5.00473 32.883 6.20092 32.2395Z" fill="#1E4B5E"/>
</svg>
</span>
                                    </div>
                                    <div className="professionals-info">
                                        <h4>Blockchain Consultants</h4> 
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>


            <div className="section-5 blue-bg pad-sec">
                    <div className="custom-container">
                        <div className="custom-section connect-with-custom-section">
                            <div className="main-head-div">
                                <h4 className="main-head-1">
                                    <span className="secondary-span">Join our</span>
                                    <span className="primary-span">Team</span>
                                </h4>
                            </div>
                            <div className="main-para-div short-div">
                                <p className="mb-3">Are you excited by a challenge? Passionate about problem solving? Do you question the status quo?</p>
                                <p className="mb-5">If so, we want you on our team!</p>
                                <p>Send your CV to <a target="_blank" href="mailto:hr@ionixxtech.com" className="mail-a">hr@ionixxtech.com</a></p>
                            </div>
                        </div>
                    </div>
            </div>

            <div className="section-3 beige-bg pad-sec">
	        	<div className="custom-container">
		        	<div className="custom-section">
		        		<div className="main-head-div">
			        		<h4 className="main-head-1">
			        			<span  className="secondary-span">Awards &</span>
			        			<span  className="primary-span">Accolades</span>
			        		</h4>
		        		</div>
                        <div className="award-common-hold">
                            <ul className="award-card-ul">
                                <li>
                                    <a href="https://blog.ionixxtech.com/ionixx-technologies-ranking-in-goodfirms-list-of-top-private-blockchain-technologies/" target="_blank">
                                        <div className="award-card ylow-bx">
                                            <div className="award-card-img-hold">
                                                <img src="assets/img/awards-new/award-1.svg" alt="Top Blockchain Development Company" title="Top Blockchain Development Company"></img>
                                            </div>
                                        </div>
                                        <div className="award-info">
                                            <h4 className="award-name">Top Private Blockchain Development Company</h4>
                                            <p className="award-year">2020</p>
                                        </div>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://themanifest.com/blockchain/companies" target="_blank">
                                        <div className="award-card ylow-bx">
                                            <div className="award-card-img-hold">
                                                <img src="assets/img/awards-new/award-7.png" alt="Manifest" title="Manifest"></img>
                                            </div>
                                        </div>
                                        <div className="award-info">
                                            <h4 className="award-name">Top 100 Blockchain Development Companies</h4>
                                            <p className="award-year">2020</p>
                                        </div>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.goodfirms.co/directory/languages/top-software-development-companies?page=22" target="_blank">
                                        <div className="award-card ylow-bx">
                                            <div className="award-card-img-hold">
                                                    <img src="assets/img/awards-new/award-2.svg" alt="Good Firms" title="Good Firms"></img>
                                                </div>
                                            </div>
                                        <div className="award-info">
                                            <h4 className="award-name">Top Software Development Company</h4>
                                            <p className="award-year">2020</p>
                                        </div>
                                        </a>
                                    </li>
                                <li>
                                <a href="https://www.goodfirms.co/directory/services/list-blockchain-technology-companies?page=5" target="_blank">
                                        <div className="award-card ylow-bx">
                                            <div className="award-card-img-hold">
                                                    <img src="assets/img/awards-new/award-1.svg" alt="Top Blockchain Development Company" title="Top Blockchain Development Company"></img>
                                                </div>
                                            </div>
                                        <div className="award-info">
                                            <h4 className="award-name">Top Blockchain Development Company</h4>
                                            <p className="award-year">2019</p>
                                        </div>
                                        </a>
                                </li>
                                <li>
                                <a href="https://blog.ionixxtech.com/ionixx-technologies-named-a-top-custom-software-developer-in-india-by-clutch-co/" target="_blank">
                                        <div className="award-card ylow-bx">
                                            <div className="award-card-img-hold">
                                                    <img src="assets/img/awards-new/award-4.png" alt="Clutch" title="Clutch"></img>
                                                </div>
                                            </div>
                                        <div className="award-info">
                                            <h4 className="award-name">Top Software Developers</h4>
                                            <p className="award-year">2018</p>
                                        </div>
                                        </a>
                                </li>
                                <li>
                                <a href="https://blog.ionixxtech.com/ionixx-technologies-featured-as-industry-leader-in-blockchain-development/" target="_blank">
                                        <div className="award-card ylow-bx">
                                            <div className="award-card-img-hold">
                                                    <img src="assets/img/awards-new/award-3.png" alt="Clutch" title="Clutch"></img>
                                                </div>
                                            </div>
                                        <div className="award-info">
                                            <h4 className="award-name">Top Developers</h4>
                                            <p className="award-year">2019</p>
                                        </div>
                                        </a>
                                </li>
                                <li>
                                <a href="https://blog.ionixxtech.com/ionixx-technologies-named-a-top-custom-software-developer-in-india-by-clutch-co/" target="_blank">
                                        <div className="award-card ylow-bx">
                                            <div className="award-card-img-hold">
                                                            <img src="assets/img/awards-new/award-5.png" alt="Award" title="Award"></img>
                                                </div>
                                            </div>
                                        <div className="award-info">
                                            <h4 className="award-name">Top Software Developers</h4>
                                            <p className="award-year">2018</p>
                                        </div>
                                        </a>
                                </li>
                                <li>
                                    <a href="https://clutch.co/press-releases/honors-top-1000-service-providers-2020-exclusive-1000-list " target="_blank">
                                        <div className="award-card ylow-bx">
                                            <div className="award-card-img-hold">
                                                        <img src="assets/img/awards-new/award-8.png" alt="Award" title="Award"></img>
                                            </div>
                                        </div>
                                        <div className="award-info">
                                            <h4 className="award-name">Top 1000 Companies</h4>
                                            <p className="award-year">2020</p>
                                        </div>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://clutch.co/press-releases/recognizes-top-b2b-providers-indian-emerging-tech-market-2021" target="_blank">
                                        <div className="award-card ylow-bx">
                                        <div className="award-card-img-hold">
                                                        <img src="assets/img/awards-new/emerge-2021.png" alt="Award" title="Award"></img>
                                            </div>
                                        </div>
                                        <div className="award-info">
                                            <h4 className="award-name">Top Emerging Technologies</h4>
                                            <p className="award-year">2021</p>
                                        </div>
                                    </a>
                                </li>
                                <li className="empty">
                                    {/* <div className="award-card ylow-bx">
                                        <div className="award-card-img-hold">
                                            <img src="assets/img/awards-new/award-3.png" alt="Clutch" title="Clutch"></img>
                                        </div>
                                    </div>
                                    <div className="award-info">
                                        <h4 className="award-name">Top Developers</h4>
                                        <p className="award-year">2019</p>
                                    </div> */}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>     
            
        <div className="our-location">
         <div className="section-2 blue-bg">
			<div className="custom-container">
		        	<div className="custom-section connect-with-custom-section">
						<div className="main-head-div">
			        		<h4 className="main-head-1">
                             <span  className="secondary-span">Our</span>
                              <span  className="primary-span">Locations</span>
			        		</h4>
		        		</div>

						<div className="card-sect">
							<div className="row">

								<div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 custom-add-row">
									<div className="add-group">
                                        <div className="add-head">
                                            <h4>USA</h4>
                                        </div>
                                        <div className="add-capt">
                                        <p>11801 Domain Blvd, Fl 3 Austin, <span className="new-ln-sp">TX 78758,</span> United States</p>
                                            <p>110 Wall St ,<span className="new-ln-sp">New York,</span><span className="new-ln-sp pl3">NY 10005</span></p>
                                        </div>
                                    </div>
								</div>

                                <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 custom-add-row">
									<div className="add-group">
                                        <div className="add-head">
                                            <h4>Canada</h4>
                                        </div>
                                        <div className="add-capt">
                                        <p>701 Evans Ave,<span className="new-ln-sp"> Suite 509,</span> Toronto,<span className="new-ln-sp">ON M9C 1A3</span></p>
                                        </div>
                                    </div>
								</div>

                                <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 custom-add-row">
									<div className="add-group">
                                        <div className="add-head">
                                            <h4>Singapore</h4>
                                        </div>
                                        <div className="add-capt">
                                        <p>10 Anson road<span className="new-ln-sp">#22-02,</span> International plaza,<span className="new-ln-sp">Singapore 079903</span></p>
                                        </div>
                                    </div>
								</div>

                                <div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 custom-add-row">
									<div className="add-group">
                                        <div className="add-head">
                                            <h4>India</h4>
                                        </div>
                                        <div className="add-capt">
                                        <p><span className="new-ln-sp">No. L 25 (Second Floor),</span> Dr. Vikram Sarabhai Instronics Estate,<span className="new-ln-sp"> SRP Tools,</span> Thiruvanmiyur, Chennai, <span className="new-ln-sp">TN 600041</span></p>
                                        </div>
                                    </div>
								</div>
								
							</div>
						</div>	
                        <div class="card-sect">
							<div class="row">

								<div class="col-xl-3 col-lg-3 col-md-3 col-sm-12 custom-add-row">
									<div class="add-group">
                                        <div class="add-head">
                                            <h4>Dubai</h4>
                                        </div>
                                        <div class="add-capt">
                                            <p>IONIXX TECHNOLOGIES FZCO, Premises Number : DSO - IFZA - 21639, <span className="new-ln-sp">Dubai Silicon Oasis, DDP, Building A2,</span>Dubai, United Arab Emirates</p>
                                        </div>
                                    </div>
								</div>
							</div>
						</div>
		        	</div>
	        	</div>
			</div>
	        </div>


            <div className="section-5 beige-bg">
                    <div className="custom-container">
                        <div className="custom-section connect-with-custom-section">
                            <div className="main-head-div">
                                <h4 className="main-head-1">
                                    <span  className="secondary-span">Speak to us about</span>
                                    <span  className="primary-span">building your product</span>
                                </h4>
                            </div>
                            <div className="main-para-div">
                            <Link to={ROUTES.CONTACTUS} className="btn btn-primary connect-btn">Talk to us</Link>
                            {/* <span onClick={this.sendToast.bind(this)} className="btn btn-primary connect-btn">Talk to us</span> */}
                            </div>
                        </div>
                    </div>
                </div>
            <ContactUS show={this.state.showContactUS} handleClose={()=>this.setState({showContactUS:false})} toShowToast={()=>{this.props.history.push(ROUTES.TOAST)}}/>
        </div>
            </>            
    );
}

}

const mapStateToProps = (state, ownProps) => {
	return state.commonReducer;
  };
  
  const mapDispatchToProps = (dispatch, ownProps) => {
	return bindActionCreators(contactAction, dispatch);
  };
  
  export default connect(
	mapStateToProps,
	mapDispatchToProps
  )(AboutUs);
