import React from "react";
import "../../resources.css";
import { ResourceRoute } from "../../resource-routing";
import { Link } from "react-router-dom";
import NewsHeadings from "./common-action/news-heading";
import NewsSubscription from "./common-action/subscripbe-section";

class NewsIssue76 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pageEnabled: false,
    };
  }

  render() {
    return (
      <div className="newdesigned_issue_details">
        <NewsHeadings
          buttondDetail="HealthTech"
          categoryType="healthtech"
          description="Healthcare Providers Continue to Adapt to Changing Care Environments | HealthTech Magazine"
        />

        <div className="newsissuedetailed">
          <div className="detailed-common">
            {/* <div className="back-to-common">
                    <span className="back-to-icon-hold">
                        <img src="/assets/img/resource/back-to-new.svg"  alt="back to new"></img>
                    </span>
                    <Link  to={ResourceRoute.NEWSLETTER}><span className="back-to-link">Newsletter</span></Link>
                </div> */}

            <div className="news-letter whats-on-hold">
              <div className="issue-main-head">
                <h4>What’s on?</h4>
              </div>
              <div className="issue-main-info">
                <div className="issue-sub-head">
                  <a
                    target={"_blank"}
                    href="https://www.scmagazine.com/home/security-news/software-developers-warm-up-to-automated-testing-as-security-cloud-rise-in-importance/"
                  >
                    <h4>
                      Software developers warm up to automated testing as
                      security, cloud rise in importance | SC Magazine
                    </h4>
                  </a>
                </div>
                <div className="issue-news-info">
                  <p>
                    Developers are frustrated about the sluggish pace of testing
                    code for security and functionality and are increasingly
                    incorporating automation and machine learning to ease
                    workloads, according to results from an annual survey on
                    software development trends from GitLab.The survey picks up
                    on the continuing problem that developers face around
                    testing the past few years, with a majority of respondents
                    saying delays due to code testing and review process was a
                    frequent source of delay in the development process.
                  </p>
                </div>
              </div>
              <div className="issue-main-info">
                <div className="issue-sub-head">
                  <a
                    target={"_blank"}
                    href="https://www.reuters.com/technology/ethereum-extends-gains-fresh-record-above-3400-2021-05-04/"
                  >
                    <h4>
                      'Speculative excess': ethereum finds new peak in sizzling
                      crypto market | Reuters
                    </h4>
                  </a>
                </div>
                <div className="issue-news-info">
                  <p>
                    Cryptocurrency ether rose to a fresh record peak on Tuesday
                    before dropping sharply as some investors pulled profits
                    from a white-hot market bulging with questionable new
                    entrants. A day after blowing past $3,000 for the first
                    time, ether, the token traded over the ethereum blockchain,
                    hit $3,457.64 on the bitstamp exchange before dropping 6% to
                    $3,244.
                  </p>
                </div>
              </div>
            </div>
            <div className="news-letter top-picks-hold">
              <div className="issue-main-head">
                <h4>Top Picks</h4>
              </div>
              <div className="issue-main-info">
                <div className="issue-sub-head">
                  <a
                    target={"_blank"}
                    href="https://healthtechmagazine.net/article/2021/05/healthcare-providers-continue-adapt-changing-care-environments"
                  >
                    <h4>
                      Healthcare Providers Continue to Adapt to Changing Care
                      Environments | HealthTech Magazine{" "}
                    </h4>
                  </a>
                </div>
                <div className="issue-news-info">
                  <p>
                    Even before the pandemic, many healthcare providers were at
                    various stages of a digital transformation featuring mobile
                    technology strategies that reached beyond hospital walls.
                    The pandemic forced them to accelerate efforts along two
                    crucial pathways: enabling remote work for employees and
                    offering telehealth services to patients. Based on what
                    they’ve gone through in the past year, some are adjusting or
                    expanding their mobile environments.
                  </p>
                </div>
              </div>
            </div>
            <div className="news-letter other-reads-hold">
              <div className="issue-main-head">
                <h4>Other Reads</h4>
              </div>
              <div className="issue-main-info">
                <div className="issue-sub-head">
                  <a
                    target={"_blank"}
                    href="https://www.theatlantic.com/health/archive/2021/05/long-covid-questions-answered/618817/"
                  >
                    <h4>
                      Podcast: Long COVID Questions Answered | The Atlantic{" "}
                    </h4>
                  </a>
                </div>
                <div className="issue-news-info">
                  <p>
                    What explains the strange constellation of symptoms that is
                    “long COVID?” Will it ever go away? And why does vaccination
                    seem to help? Writer F.T. Kola returns to the podcast Social
                    Distance to recount her experience with long COVID with
                    hosts James Hamblin and Maeve Higgins. They’re also joined
                    by Dr. Akiko Iwasaki, an immunologist investigating long
                    COVID at her Yale lab. She explains what we know about the
                    condition—and how two theories about its root cause mean the
                    difference between a cure and no clear end in sight.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <NewsSubscription categoryType="HealthTech" />
      </div>
    );
  }
}

export default NewsIssue76;
