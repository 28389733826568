import React from 'react';
import "../../resources.css";
import { ResourceRoute } from '../../resource-routing';
import { Link } from "react-router-dom";
import NewsHeadings from "./common-action/news-heading";
import NewsSubscription from "./common-action/subscripbe-section";

class NewsIssue49 extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            pageEnabled: false,
        }

    }

    render() {
        return (
          <div className="newdesigned_issue_details">
            <NewsHeadings
              buttondDetail="Web3 & Blockchain"
              categoryType="web3"
              description="What’s new in Angular 11 | Infoworld"
            />
            <div className="newsissuedetailed">
              <div className="detailed-common">
                {/* <div className="back-to-common">
                            <span className="back-to-icon-hold">
                                <img src="/assets/img/resource/back-to-new.svg" alt="bact to new"></img>
                            </span>
                            <Link to={ResourceRoute.NEWSLETTER}><span className="back-to-link">Newsletter</span></Link>
                        </div> */}

                <div className="news-letter whats-on-hold">
                  <div className="issue-main-head">
                    <h4>What’s on?</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.techerati.com/features-hub/opinions/how-aws-new-product-releases-optimise-application-development/"
                      >
                        <h4>
                          How AWS’ new product releases optimise application
                          development | Techerarti.com
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Amazon Web Services (AWS) is always busy introducing new
                        services, enhancing existing ones, and, quite often,
                        driving trends. However, the company has upped its game
                        even more in recent months. In the first half of 2020,
                        AWS announced an astonishing number of developments that
                        enable technology interoperability, simplifying legacy
                        feature removal, creating greater flexibility and
                        time-savings for developers, and putting new
                        capabilities at the fingertips of end-users.
                      </p>
                    </div>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.healthcareitnews.com/news/ibm-watson-health-launch-blockchain-powered-digital-health-pass"
                      >
                        <h4>
                          IBM Watson Health to launch blockchain-powered Digital
                          Health Pass | Healthcare IT
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        IBM Watson Health announced the launch of an IBM Digital
                        Health Pass to help organizations enable individuals to
                        return to work or school. The tool is built on IBM
                        blockchain technology aimed at allowing users to share
                        their verified health pass – established on criteria
                        such as COVID-19 test results – without exposing the
                        data used to generate it, according to the organization.
                        According to the IBM website, users manage their
                        information through an encrypted digital wallet on their
                        smartphone and control what they share, while
                        organizations can determine responses for health
                        statuses and design business rules. When health data
                        becomes available, individuals can scan a QR code and
                        add that information to a digital wallet. The data is
                        stored by that individual and not by those organizations
                        requesting health statuses. Individuals could, for
                        example, share their vaccination records but not their
                        health status
                      </p>
                    </div>
                  </div>
                </div>
                <div className="news-letter top-picks-hold">
                  <div className="issue-main-head">
                    <h4>Top Picks</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.infoworld.com/article/3584372/whats-new-in-angular-11.html"
                      >
                        {" "}
                        <h4>What’s new in Angular 11 | Infoworld</h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Development is proceeding on Angular 11, a planned
                        upgrade to the Google-developed web framework, with five
                        beta releases delivered in September and two more in
                        October. So far the proposed additions and changes
                        include stricter types, router performance improvements,
                        and the removal of support for certain Internet Explorer
                        browser versions. The beta releases are available in
                        GitHub. With Angular 11, stricter types would be added
                        for DatePipe and number pipes, in order to catch
                        misuses, such as passing an Observable or an array, at
                        compile time.{" "}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="news-letter other-reads-hold">
                  <div className="issue-main-head">
                    <h4>Other Reads</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://economictimes.indiatimes.com/news/company/corporate-trends/the-old-story-of-business-towards-a-better-capitalism/articleshow/78584921.cms"
                      >
                        <h4>
                          The Old Story of Business: Towards a better capitalism
                          | Economic Times
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        The traditional view of a business’s purpose is that
                        profits are all that matter. For at least the last 10
                        years that view, that we will call 'The Old Story of
                        Business', has come under increasing attack and
                        scrutiny. In reality, many scholars and business
                        thinkers have questioned this old Story for much longer
                        and have always thought there was a better way of
                        thinking about business, a new story. There are many
                        suggestions for a ‘New Story of Business’. Some suggest
                        Sustainability, or Corporate Social Responsibility, or
                        Socially Responsible Investing.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <NewsSubscription categoryType="Web3" />
          </div>
        );
    }

}

export default NewsIssue49;
