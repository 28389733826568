import React from "react";
import "../../resources.css";
import { ResourceRoute } from "../../resource-routing";
import { Link } from "react-router-dom";
import NewsHeadings from "./common-action/news-heading";
import NewsSubscription from "./common-action/subscripbe-section";
import ReactSEOMetaTags from "react-seo-meta-tags";
import Helmet from "react-helmet";
import { Modal } from "react-bootstrap";

class NewsIssue86 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pageEnabled: false,
      modalShow: false,
      letftStickyContnet: (
        <div className="news-sticky">
          <div className="left-text">
            ePrescribing <span>webinar</span> By Dr. Maanas Samant
          </div>
          <div className="news-pic">
            <img src="/assets/img/health-img/maanas.png" />
          </div>
        </div>
      ),
    };
  }
  handleClose() {
    this.setState({ modalShow: false });
  }
  handleShow() {
    this.setState({ modalShow: true });
  }
  render() {
    return (
      <>
        <Helmet>
          <title>Newsletter | Ionixx Technologies</title>
          <meta
            name="description"
            content="Explore ePrescription in healthcare with Dr. Maanas who takes us through the evolution, roadblocks, and future of eRx."
          />
          <link
            rel="canonical"
            href="https://www.ionixxtech.com/resources/newsletter"
          />
        </Helmet>
        <ReactSEOMetaTags
          render={(el) => <Helmet>{el}</Helmet>}
          website={{
            name: "Ionixx Technologies",
            title:
              "Evolution of ePrescribing: Revolutionizing Healthcare & Navigating the Digital Shift ",
            description:
              "Explore ePrescription in healthcare with Dr. Maanas who takes us through the evolution, roadblocks, and future of eRx.",
            type: "website",
            image: "/assets/newsletter_images/issue_85/banner.png",
            url: "https://www.ionixxtech.com/resources/newsletter/news-issue-85",
          }}
          twitter={{
            twitter:
              "https://www.ionixxtech.com/resources/newsletter/news-issue-85",
          }}
        />
        <div className="newdesigned_issue_details issue-86">
          <NewsHeadings
            buttondDetail="HealthTech"
            categoryType="healthtech"
            description="A New Era in Healthcare: AI's Evolving Role in Diagnostics and Treatment"
            newDetail={true}
          />
          {/* <div className='sticky-button sticky banner-modal-button'>
                    <button onClick={() => this.handleShow()}>ePrescription webinar with Dr.Maanas Samant</button>
                 </div> */}
          {/*modal content */}
          <Modal
            className="newsletter-modal theme-change"
            centered
            show={this.state.modalShow}
            onHide={() => this.handleClose()}
          >
            <Modal.Header closeButton>
              <div className="banner-section">
                <div className="banner-left">
                  <img
                    src="/assets/newsletter_images/issue_85/banner.png"
                    alt="defi"
                  />
                </div>
                <div className="banner-right">
                  <span>Webinar | 26 Sep 2023</span>
                  {/* <strong>Digital IDs and the Internet of Things: The Future of Identity & Security </strong> */}
                  <p>
                    ePrescribing: Join Dr. Maanas Samant for an In-Depth Journey
                    Through Its Evolution, Challenges, and Future Prospects in
                    This Insightful Webinar Replay.
                  </p>
                  <a
                    className="read-more"
                    href="https://www.bigmarker.com/ionixx-technologies1/The-Evolution-Impact-and-Opportunities-of-ePrescribing-006672d4a45239798841b834"
                    target="_blank"
                  >
                    Watch Now
                  </a>
                </div>
              </div>
            </Modal.Header>
          </Modal>

          <div className="newsissuedetailed">
            <div className="detailed-common">
              <div className="headlines-warp">
                <div className="top-news">
                  <i>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="234"
                      height="140"
                      viewBox="0 0 234 140"
                      fill="none"
                    >
                      <g clip-path="url(#clip0_462_1735)">
                        <path
                          d="M45.2302 29.1322L42.6203 21.6729H0.319092V29.1322V34.9968V130.329H140.56V29.1322H45.2302Z"
                          fill="#00AEEF"
                          stroke="#263238"
                          stroke-miterlimit="10"
                        />
                        <path
                          d="M137.93 39.0251H2.94177V124.135H137.93V39.0251Z"
                          fill="white"
                          stroke="#263238"
                          stroke-miterlimit="10"
                        />
                        <path
                          d="M112.278 31.947H45.2111V36.0325H112.278V31.947Z"
                          fill="white"
                          stroke="#263238"
                          stroke-miterlimit="10"
                        />
                        <path
                          d="M6.1898 31.2925L4.25629 33.1478L6.26 35.289"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M4.25629 33.1477H8.55086"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M17.1528 31.2925L19.0927 33.1478L17.0826 35.289"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M19.0927 33.1477H14.7917"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M28.3263 32.8999C28.1158 32.0612 27.2607 31.3687 26.3545 31.3687C25.2889 31.3687 24.421 32.2328 24.421 33.2939C24.421 34.3549 25.2889 35.2191 26.3545 35.2191C26.8204 35.2191 27.2479 35.0539 27.5797 34.7807"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M27.0245 33.116H28.5305V31.3369"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M38.581 32.487L36.7943 31.0447L35.0969 32.487V35.6131H38.581V32.487Z"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M37.4707 33.332H36.1115V35.505H37.4707V33.332Z"
                          fill="#263238"
                        />
                        <path
                          d="M35.0969 32.4871L34.0759 33.4147"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M38.581 32.4871L39.5382 33.3321"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M37.9365 25.5295L40.6549 28.3252"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M40.6996 25.574L37.8918 28.2807"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M130.822 32.6968H136.201"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M130.822 33.9041H136.201"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M130.822 35.1113H136.201"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M130.822 36.3184H136.201"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M105.622 7.77701L103.012 0.317627H60.7111V7.77701V13.6416V108.974H200.952V7.77701H105.622Z"
                          fill="#00AEEF"
                          stroke="#263238"
                          stroke-miterlimit="10"
                        />
                        <path
                          d="M198.329 17.6699H63.3402V102.779H198.329V17.6699Z"
                          fill="white"
                          stroke="#263238"
                          stroke-miterlimit="10"
                        />
                        <path
                          d="M172.67 10.5918H105.603V14.6773H172.67V10.5918Z"
                          fill="white"
                          stroke="#263238"
                          stroke-miterlimit="10"
                        />
                        <path
                          d="M66.5818 9.93726L64.6483 11.7926L66.6584 13.9338"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M64.6483 11.7925H68.9493"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M77.5512 9.93726L79.4847 11.7926L77.481 13.9338"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M79.4847 11.7925H75.1838"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M88.7248 11.5447C88.5142 10.706 87.6591 10.0134 86.753 10.0134C85.6873 10.0134 84.8195 10.8775 84.8195 11.9386C84.8195 12.9997 85.6873 13.8638 86.753 13.8638C87.2188 13.8638 87.6463 13.6986 87.9782 13.4254"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M87.423 11.7608H88.9226V9.98169"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M98.9731 11.1318L97.1863 9.68945L95.4889 11.1318V14.2642H98.9731V11.1318Z"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M97.8691 11.9768H96.5099V14.1498H97.8691V11.9768Z"
                          fill="#263238"
                        />
                        <path
                          d="M95.4889 11.1318L94.4679 12.0595"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M98.9731 11.1318L99.9366 11.9769"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M98.3285 4.18066L101.047 6.96999"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M101.092 4.21875L98.2902 6.92548"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M191.22 11.3416H196.6"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M191.22 12.5488H196.6"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M191.22 13.7561H196.6"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M191.22 14.9631H196.6"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M138.358 38.485L135.742 31.032H93.4468V38.485V44.3559V139.682H233.681V38.485H138.358Z"
                          fill="#00AEEF"
                          stroke="#263238"
                          stroke-miterlimit="10"
                        />
                        <path
                          d="M231.058 48.3779H96.0695V133.487H231.058V48.3779Z"
                          fill="white"
                          stroke="#263238"
                          stroke-miterlimit="10"
                        />
                        <path
                          d="M205.406 41.3062H138.339V45.3917H205.406V41.3062Z"
                          fill="white"
                          stroke="#263238"
                          stroke-miterlimit="10"
                        />
                        <path
                          d="M99.3176 40.6516L97.3777 42.5069L99.3878 44.6482"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M97.3777 42.5068H101.679"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M110.281 40.6516L112.214 42.5069L110.21 44.6482"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M112.214 42.5068H107.913"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M121.454 42.2591C121.244 41.4204 120.388 40.7278 119.482 40.7278C118.417 40.7278 117.549 41.5919 117.549 42.653C117.549 43.7141 118.417 44.5782 119.482 44.5782C119.948 44.5782 120.376 44.413 120.707 44.1398"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M120.152 42.4751H121.658V40.6897"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M131.702 41.8398L129.922 40.4038L128.218 41.8398V44.9722H131.702V41.8398Z"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M130.598 42.6848H129.239V44.8578H130.598V42.6848Z"
                          fill="#263238"
                        />
                        <path
                          d="M128.218 41.8398L127.197 42.7739"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M131.702 41.8398L132.666 42.6913"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M131.064 34.8887L133.783 37.678"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M133.821 34.9331L131.02 37.6398"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M223.95 42.0559H229.329"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M223.95 43.2632H229.329"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M223.95 44.4705H229.329"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M223.95 45.6775H229.329"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M116.668 91.6094H144.797"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M116.668 95.2246H144.797"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M116.668 98.8401H144.797"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M116.668 102.462H144.797"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M116.668 106.077H144.797"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M116.668 109.692H144.797"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M116.668 113.308H144.797"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M116.668 116.929H144.797"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M116.668 120.545H144.797"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M149.748 91.6094H177.871"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M149.748 95.2246H177.871"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M149.748 98.8401H177.871"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M149.748 102.462H177.871"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M149.748 106.077H177.871"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M149.748 109.692H177.871"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M149.748 113.308H177.871"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M149.748 116.929H177.871"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M149.748 120.545H177.871"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M149.748 62.96H177.871"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M149.748 66.5752H177.871"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M149.748 70.197H177.871"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M149.748 73.8123H177.871"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M149.748 77.4275H177.871"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M149.748 81.043H177.871"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M149.748 84.6646H177.871"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M149.748 88.28H177.871"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M182.688 91.6094H210.817"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M182.688 95.2246H210.817"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M182.688 98.8401H210.817"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M182.688 102.462H210.817"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M182.688 106.077H210.817"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M182.688 109.692H210.817"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M182.688 113.308H210.817"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M182.688 116.929H210.817"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M182.688 120.545H210.817"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M135.48 68.7228C135.627 68.3098 135.697 67.7825 135.697 67.3949C135.697 65.7492 134.025 65.6539 132.372 65.6539H131.958V65.2664H139.143V65.6539C137.273 65.6539 136.616 66.8358 136.01 68.6021L132.2 79.9818C131.907 80.8269 131.664 81.4559 130.771 81.4559H130.701L127.325 70.5845L124.173 79.9818C123.879 80.8269 123.636 81.4559 122.743 81.4559H122.673L117.772 65.6539H116.049V65.2664H122.36V65.6539H120.567L124.204 78.1201H124.351L127.095 69.8093L125.806 65.6539H124.083V65.2664H130.394V65.6539H128.601L132.238 78.1201H132.385L135.493 68.7228H135.48Z"
                          fill="#00AEEF"
                        />
                        <path
                          d="M211.085 61.6638H182.14V87.7145H211.085V61.6638Z"
                          fill="#00AEEF"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M211.085 72.5479C210.3 72.586 209.349 72.7067 208.194 72.9545C203.798 73.9076 202.649 80.1851 197.678 80.1851C193.275 80.1851 192.898 74.2825 186.587 73.52C184.679 73.2913 183.231 73.6725 182.133 74.3079V87.7017H211.079V72.5415L211.085 72.5479Z"
                          fill="#263238"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M191.756 67.6237C191.756 69.4092 190.301 70.8578 188.508 70.8578C186.715 70.8578 185.26 69.4092 185.26 67.6237C185.26 65.8383 186.715 64.3896 188.508 64.3896C190.301 64.3896 191.756 65.8383 191.756 67.6237Z"
                          fill="white"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_462_1735">
                          <rect width="234" height="140" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </i>
                  <h4>Top Blog</h4>
                  <span>
                    In the dynamic landscape of healthcare, technology’s role in
                    patient care continues to grow, and one of the key drivers
                    of this change is ePrescribing (eRx). ePrescribing is the
                    computer-based generation, transmission, and filling of a
                    medical prescription, replacing traditional paper and faxed
                    prescriptions.
                  </span>
                  <a
                    href="https://blog.ionixxtech.com/the-evolution-impact-and-opportunities-of-eprescribing/"
                    target="_blank"
                  >
                    Read More...
                  </a>
                </div>
                <div className="headlines-content">
                  <strong className="overall-heading">
                    HealthTech News Roundup
                  </strong>
                  <div className="headlines-list">
                    <a
                      href="https://www.meddeviceonline.com/doc/emerging-trends-in-microneedle-drug-delivery-systems-0001"
                      target="_blank"
                    >
                      Emerging Trends in Microneedle Drug Delivery Systems
                    </a>
                    <p>
                      Solid microneedles are solid structures that either carry
                      a preloaded drug or can be used to create temporary
                      pathways in the skin for drug absorption.
                    </p>
                  </div>
                  <div className="headlines-list">
                    <a
                      href="https://healthtechmagazine.net/article/2023/09/clinicians-are-key-data-driven-approaches-patient-care"
                      target="_blank"
                    >
                      Clinicians Are Key to Data-Driven Approaches to Patient
                      Care
                    </a>
                    <p>
                      It’s essential to include clinicians in discussions about
                      organizational goals, financial management, and
                      performance reviews to optimize strategies.{" "}
                    </p>
                  </div>
                  <div className="headlines-list">
                    <a href="https://www.healthcareitnews.com/news/hc3-lazaraus-group-malware-targets-health-systems-manageengine-vulnerabilities">
                      HC3: Lazarus Group Malware Targets Health Systems'
                      ManageEngine Vulnerabilities
                    </a>
                    <p>
                      The North Korean state-sponsored actor known for Ryuk
                      ransomware is deploying two new remote access trojans to
                      target hospitals and health systems through a critical
                      vulnerability affecting 24 products.
                    </p>
                  </div>
                  <div className="headlines-list">
                    <a
                      href="https://www.healthcareitnews.com/news/ai-enabled-efficiencies-can-improve-patient-outcomes-and-reduce-clinician-burnout"
                      target="_blank"
                    >
                      AI-enabled Efficiencies Can Improve Patient Outcomes And
                      Reduce Clinician Burnout
                    </a>
                    <p>
                      By prioritizing healthy data management practices through
                      the deployment of AI, IT leaders can make a huge impact on
                      the day-to-day experiences across a healthcare
                      organization.
                    </p>
                  </div>
                  <div className="headlines-list">
                    <a
                      href="https://www.forbes.com/sites/willskipworth/2023/09/21/covid-drug-paxlovid-now-less-effective-than-in-early-trials-but-its-still-great-at-preventing-death/?sh=6f7214cf3c44"
                      target="_blank"
                    >
                      Covid Drug Paxlovid Now Less Effective Than In Early
                      Trials—But It’s Still Great At Preventing Death
                    </a>
                    <p>
                      The observational study analyzed electronic health records
                      from thousands of patients at the Cleveland Clinic Health
                      System in 2022 and early 2023 to determine Paxlovid’s
                      effectiveness, using real-world data rather than the
                      clinical trials that were used before.
                    </p>
                  </div>
                  <div className="headlines-list">
                    <a
                      href="https://www.forbes.com/sites/antoniopequenoiv/2023/09/21/nearly-500000-people-were-wrongly-kicked-off-medicaid-due-to-bizarre-systems-issue-many-being-children/?sh=df15a6512c0b"
                      target="_blank"
                    >
                      Nearly 500,000 People Were Wrongly Kicked Off Medicaid Due
                      To Bizarre ‘Systems’ Issue—Many Being Children
                    </a>
                    <p>
                      Thirty states in total suffered from the issue and were
                      required by CMS to pause procedural dis enrollments for
                      those impacted unless “they could ensure all eligible
                      people are not improperly disenrolled due to this issue,”
                      according to a CMS statement.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="new-detail-option">
            <div className="news-first-section">
              <div className="heading">
                <div className="detail-heading">
                  <h5>
                    <span>1</span>The New Norm: Telemedicine's Emergence in the
                    Post-Pandemic Era
                  </h5>
                </div>
              </div>
              <div className="newsissuedetailed">
                <div className="news-left">
                  <i>
                    {" "}
                    <img
                      src="/assets/newsletter_images/issue_86/issue86-1.png"
                      alt="piggy-bank"
                    />
                  </i>
                  {/* <strong>Tackling Alert Fatigue</strong> */}
                  <b>
                    During the COVID-19 pandemic, the use of telemedicine
                    platforms increased by 3,000%, enabling remote consultations
                    and monitoring of patients. According to Accenture, AI
                    applications in healthcare could potentially save the
                    industry up to $150 billion annually in the United States by
                    2026.{" "}
                  </b>
                </div>
                <div className="news-right">
                  <p>
                    Telemedicine emerged as a lifesaver during the tumultuous
                    COVID-19 pandemic, evolving into an indispensable tool for
                    delivering healthcare services. Through video consultations,
                    patients gained the ability to access medical care from the
                    safety and comfort of their homes. As a result, the
                    transportation of elderly patients to medical appointments
                    has become a rapidly growing industry, with innovative
                    business models like Lyft for Healthcare and Uber Health
                    partnering with hospitals to ensure secure transportation.
                  </p>
                  <p>
                    The integration of AI into telemedicine is taking virtual
                    care to the next level, enhancing accuracy and efficiency in
                    diagnosis and treatment. For instance, AI assistants, akin
                    to ChatGPT, can serve as appointment coordinators, guiding
                    patients through the process, answering queries, and even
                    eliminating the need for initial GP consultations. Startups
                    like GYANT are adopting a similar approach with their
                    "virtual front door" chatbots, engaging patients
                    conversationally while directing them to the appropriate
                    specialist or doctor.
                  </p>
                  <p>
                    Moreover, some telemedicine services are sidestepping
                    traditional insurance plans, which often require referrals
                    and limit-covered physicians. Instead, they offer flat-rate
                    fees for virtual consultations. Teladoc Health, Doctor on
                    Demand, SteadyMD, PlushCare, and HealthTap are notable
                    examples, of expanding patient access to comprehensive
                    medical services while potentially reducing costs and
                    enhancing convenience.
                  </p>
                </div>
              </div>
            </div>

            <div className="news-first-section">
              <div className="heading">
                <div className="detail-heading">
                  <h5>
                    <span>2</span>Diving Deeper: AI's Diagnostic Potential
                  </h5>
                </div>
              </div>
              <div className="newsissuedetailed">
                <div className="news-left">
                  <i>
                    <img
                      src="/assets/newsletter_images/issue_86/issue86-2.png"
                      alt="bitcoin-logo"
                    />
                  </i>
                  {/* <strong>Tackling Alert Fatigue</strong> */}
                  <b>
                    AI can analyze medical images, such as X-rays and MRIs, with
                    remarkable accuracy. A study published in JAMA Oncology
                    found that an AI model could detect breast cancer in
                    mammograms with an accuracy of 94.0%, compared to 88.7% for
                    radiologists.{" "}
                  </b>
                </div>
                <div className="news-right">
                  <p>
                    {" "}
                    ePrescribing has etched a significant and lasting imprint,
                    extending its influence across healthcare providers,
                    pharmacists, and, most crucially, patients.
                  </p>
                  <ul className="stroke-list">
                    <li>
                      <span></span>
                      <b>
                        AI in Medical Imaging: The Quest for a Breakthroughs
                      </b>
                      <p>
                        For over a decade, AI's potential to revolutionize
                        medical imaging, including X-rays and CT scans, has
                        tantalized researchers and companies alike. Despite
                        early optimism, the field has seen limited breakthroughs
                        in recent years. Nuance Communications, acquired by
                        Microsoft, and the now-defunct IBM Watson Health both
                        invested billions in exploring AI's diagnostic
                        capabilities in this domain.
                      </p>
                      <p>
                        IBM Watson Health adopted a data-driven approach,
                        harnessing vast datasets to train a system for anomaly
                        detection in medical images like X-rays. Using
                        supervised learning, the system analyzed a plethora of
                        labeled X-ray images, learning to discern patterns
                        associated with anomalies. Once trained, it could
                        evaluate new, unseen X-ray images, potentially
                        identifying issues.
                      </p>
                    </li>
                    <li>
                      <span></span>
                      <b>
                        ﻿AI's Game-Changer: GPT-4's Role in Transforming
                        Healthcare Diagnosis"
                      </b>
                      <p>
                        With GPT-4's groundbreaking capabilities, a new era
                        unfolds in healthcare, extending AI's reach into the
                        realm of diagnostics. The recent leap forward in AI
                        capabilities, embodied by GPT-4, is largely attributed
                        to its undisclosed architecture—though it's known to
                        employ layers of Transformers, building on the Attention
                        is All You Need framework. GPT-4 leverages advanced
                        natural language processing models to excel in tasks
                        like text generation, question-answering, and
                        summarization.
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="news-first-section">
              <div className="heading">
                <div className="detail-heading">
                  <h5>
                    <span>3</span>The Triad of Healthcare AI: Personalization,
                    Remote Monitoring, and Ethical Considerations
                  </h5>
                </div>
              </div>
              <div className="newsissuedetailed">
                <div className="news-left">
                  <i>
                    {" "}
                    <i>
                      <img
                        src="/assets/newsletter_images/issue_86/issue86-3.png"
                        alt="cryptocurrency"
                      />
                    </i>
                  </i>
                  <b>
                    A study published in Nature Medicine showed that an AI model
                    was able to diagnose certain eye diseases with an accuracy
                    of 94.6%, outperforming human ophthalmologists. IBM's Watson
                    for Oncology analyzes patient records and medical literature
                    to recommend personalized cancer treatment options.{" "}
                  </b>
                </div>

                <div className="news-right">
                  <ul className="stroke-list">
                    <li>
                      <span></span>
                      <b>Personalization</b>
                      <p>
                        AI plays a pivotal role in healthcare by crafting
                        personalized treatment plans. By sifting through
                        extensive patient data, AI can pinpoint the most
                        effective treatment strategies tailored to an
                        individual's genetic profile, medical history, and
                        lifestyle choices. This level of customization not only
                        leads to improved patient outcomes but also streamlines
                        the allocation of healthcare resources. This efficiency
                        is particularly attractive to insurance companies, as it
                        promises a positive return on investment (ROI). As a
                        result, AI's influence in diagnosing and treating
                        patients continues to grow with each passing day,
                        shaping the future of healthcare.
                      </p>
                    </li>
                    <li>
                      <span></span>
                      <b>Remote Monitoring</b>
                      <p>
                        AI integration with remote monitoring devices enables
                        real-time tracking of patients' vital signs and health
                        metrics. AI algorithms analyze this data to predict
                        health issues, facilitating early intervention and
                        complications prevention. Companies like AliveCor
                        utilize Remote Patient Monitoring (RPM) for conditions
                        such as Atrial Fibrillation, employing proprietary
                        algorithms for rhythm assessment. This proactive
                        healthcare approach enhances patient outcomes and
                        reduces hospital readmissions.{" "}
                      </p>
                    </li>
                    <li>
                      <span></span>
                      <b>Ethical Considerations</b>
                      <p>
                        While the advantages of AI in telemedicine are evident,
                        addressing its ethical implications is crucial. Privacy
                        and security of sensitive patient data are major
                        concerns. Additionally, mitigating biases in AI
                        algorithms and ensuring equitable access to AI-powered
                        healthcare services are essential to prevent disparities
                        in care. Discussions on these ethical aspects are vital
                        to harnessing AI's potential responsibly in patient
                        care.
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="news-first-section"></div>

            <div className="news-first-section">
              <div className="heading">
                <div className="detail-heading">
                  <h5>
                    <span>4</span>Charting the Course: AI's Future in Healthcare
                  </h5>
                </div>
              </div>
              <div className="newsissuedetailed">
                <div className="newissuedetailed_banner">
                  <span className="banner-wall">
                    <img src="/assets/newsletter_images/ebook-notepad-clip.png" />
                  </span>
                  <p>
                    The future of healthcare is being shaped by the
                    transformative power of Artificial Intelligence (AI). AI has
                    the potential to revolutionize the way we approach
                    telemedicine and healthcare delivery, promising improvements
                    in diagnostic accuracy, personalized treatment plans, and
                    enhanced remote patient monitoring. A significant milestone
                    in this journey is the advent of GPT-4, which boasts a
                    revolutionary architecture compared to previous
                    image-processing supervised learning models, particularly in
                    healthcare applications.
                  </p>
                  <p>
                    However, alongside its immense promise, AI adoption in
                    healthcare brings ethical considerations and challenges that
                    cannot be overlooked. It is essential to strike a delicate
                    balance, ensuring that the benefits of AI are realized
                    without compromising patient privacy or exacerbating
                    existing healthcare disparities. As technology continues to
                    evolve at a rapid pace, healthcare providers, policymakers,
                    and patients must come together to harness the full
                    potential of AI for the betterment of global health. The
                    future of healthcare is AI-driven, and it is our collective
                    responsibility to chart this course thoughtfully and
                    ethically.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="final-section newsissuedetailed">
            <strong>
              That’s a wrap
              <br />
              <span>Hope you enjoyed it!</span> <br />
            </strong>
            <div className="checkout-interesting">
              <a href="https://blog.ionixxtech.com/">
                Check out our interesting Blogs
              </a>
            </div>
          </div>
          <div className="events-wrap">
            <strong>Events</strong>
            <div className="events-list">
              <div className="events">
                <img src="/assets/newsletter_images/issue_83_events.png" />
                <p>
                  Here’s team Ionixx at NFTNYC along with the awesome guys from
                  @badcryptopodcast.m{" "}
                </p>
              </div>
              <div className="events">
                <img src="/assets/newsletter_images/event-2.png" />
                <p>
                  Live from #HIMSS23 in Chicago: Ionixx's CMIO, Dr. Maanas
                  Samant, MD, is connecting with healthcare innovators from
                  around the world!
                </p>
              </div>
            </div>
          </div>
          <NewsSubscription categoryType="HealthTech" />
        </div>
      </>
    );
  }
}

export default NewsIssue86;
