import React from 'react';
import "../../resources.css";
import { ResourceRoute } from '../../resource-routing';
import { Link } from "react-router-dom";
import NewsHeadings from "./common-action/news-heading";
import NewsSubscription from "./common-action/subscripbe-section";

class NewsIssue62 extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            pageEnabled: false,
        }

    }

    render() {
        return (
          <div className="newdesigned_issue_details">
            <NewsHeadings
              buttondDetail="FinTech"
              categoryType="fintech"
              description="FivebalanceUSA officially relaunches its crypto self-improvement mobile app | Bravenewcoin.com"
            />
            <div className="newsissuedetailed">
              <div className="detailed-common">
                {/* <div className="back-to-common">
                            <span className="back-to-icon-hold">
                                <img src="/assets/img/resource/back-to-new.svg" alt="back to new"></img>
                            </span>
                            <Link to={ResourceRoute.NEWSLETTER}><span className="back-to-link">Newsletter</span></Link>
                        </div> */}

                <div className="news-letter whats-on-hold">
                  <div className="issue-main-head">
                    <h4>What’s on?</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.industryweek.com/technology-and-iiot/article/21153054/whats-ahead-for-product-development-innovation-in-2021"
                      >
                        <h4>
                          What’s Ahead for Product Development & Innovation in
                          2021 | Industry Week
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Like all industries, the design and manufacturing world
                        experienced a shockwave of changes in 2020 due to the
                        pandemic, and as a result many product development
                        companies had to suddenly become more agile to pivot in
                        new and innovative ways. Amidst the continued challenges
                        of COVID-19 in 2021, this need to be agile continues for
                        businesses of all sizes.
                      </p>
                    </div>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.theblockcrypto.com/post/92857/blockfolio-launches-crypto-trading-app"
                      >
                        <h4>
                          Blockfolio launches zero-fee crypto trading on its app
                          | Blockcrypto.com{" "}
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Nearly six months after FTX acquired the crypto price
                        tracking app Blockfolio in a $150 million deal, the
                        platform is now ready to roll-out a crypto trading
                        functionality. Blockfolio got its start in the crypto
                        market as a place where users could track the price of
                        various cryptos and the performance of their holdings.
                        The new functionality — which will be powered by FTX's
                        trading systems — will put the firm up against a long
                        list of players that offer crypto trading, such as
                        Coinbase and Robinhood.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="news-letter top-picks-hold">
                  <div className="issue-main-head">
                    <h4>Top Picks</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://bravenewcoin.com/insights/fivebalanceusa-officially-relaunches-its-crypto-self-improvement-mobile-app"
                      >
                        <h4>
                          FivebalanceUSA officially relaunches its crypto
                          self-improvement mobile app | Bravenewcoin.com
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        FiveBalanceUSA is a crypto startup company that has
                        developed a unique self-improvement application that is
                        available free on the Google Play store. The app intends
                        to serve people dealing with depression, by rewarding
                        them with FBN cryptocurrency upon achieving their
                        personal customized goals in 5F categories - Fit,
                        Financial, Family, Flair, and Faith.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="news-letter other-reads-hold">
                  <div className="issue-main-head">
                    <h4>Other Reads</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://economictimes.indiatimes.com/tech/technology/they-found-a-way-to-limit-big-techs-power-using-the-design-of-bitcoin/articleshow/80496050.cms"
                      >
                        <h4>
                          They found a way to limit Big Tech's power: Using the
                          design of Bitcoin | Economic Times{" "}
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Jack Dorsey, Twitter’s chief executive, wrestled this
                        month with the question of whether his social media
                        service had exercised too much power by cutting off
                        Donald Trump’s account. Dorsey wondered aloud if the
                        solution to that power imbalance was new technology
                        inspired by the cryptocurrency Bitcoin. When YouTube and
                        Facebook barred tens of thousands of Trump’s supporters
                        and white supremacists this month, many flocked to
                        alternative apps such as LBRY, Minds and Sessions.{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <NewsSubscription categoryType="FinTech" />
          </div>
        );
    }

}

export default NewsIssue62;
