import React from 'react';
import { Link } from "react-router-dom";
import { ROUTES } from "../../../routes";
// import "../oms/oms.css";
import "./trade-surveillance.css"
import ContactUS from "../../../components/contact-us";
import { PortfolioRoute } from "../../portfolio/route-porfolio";
// import { ToastComponent } from '../../components/common';
import * as contactAction from "../../../action/contactAction";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ReactSEOMetaTags from 'react-seo-meta-tags';
import Helmet from 'react-helmet';
import Testimonials from '../../../components/testimonials/testimonials';
import TestimonialsNew from '../../../components/testimonials-new/testimonialsNew';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Slider from 'react-slick';
class TradeSurveillance extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            headerClassAdd: "",
            showContactUS: false,
            showToast: false
        }
        this.onScrollHeader = this.onScrollHeader.bind(this)
    }
    componentDidMount() {
        window.addEventListener('scroll', this.onScrollHeader);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.onScrollHeader);
    }
    onScrollHeader() {
        const winScroll =
            document.body.scrollTop || document.documentElement.scrollTop

        const height =
            document.documentElement.scrollHeight -
            document.documentElement.clientHeight

        const scrolled = winScroll / height

        if (scrolled === 0) {
            this.setState({
                headerClassAdd: ""
            })
        } else {
            this.setState({
                headerClassAdd: "scrollHeader"
            })
        }
    }
    sendToast() {
        this.setState({ showContactUS: true })
    }
    render() {
        var blockSettings = {
            // className: "center",
            dots: false,
            arrows: true,
            infinite: true,
            autoplay: true,
            centerMode: false,
            speed: 3000,
            speed: 2000,
            autoplaySpeed: 5000,
            pauseOnHover: false,
            slidesToShow: 3,
            slidesToScroll: 1,
            initialSlide: 0,
            responsive: [
                {
                    breakpoint: 1240,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: false,
                    },
                },
                {
                    breakpoint: 1098,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: false,
                    },
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                    },
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        centerMode: false,
                    },
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        centerMode: false,
                    },
                },
            ],
        };
        return (
            <>
                <Helmet>
                    <title>Trade Surveillance Software Solutions - Ionixx Tech</title>
                    <meta name="description" content="Detect and prevent market abuses, insider trading, and compliance breaches seamlessly with Ionixx's trade surveillance software solutions and services." />
                    <link rel="canonical" href="https://www.ionixxtech.com/fintech-solutions" />
                    <meta property="og:image" content={"https://www.ionixxtech.com/assets/img/SEO_OG_IMAGE/Fintech-Solutions.jpg"} />

                </Helmet>
                <ReactSEOMetaTags
                    render={(el) => <Helmet>{el}</Helmet>}
                    website={{
                        name: "Ionixx Technologies",
                        title: "Trade Surveillance Software Solutions - Ionixx Tech",
                        description: "Detect and prevent market abuses, insider trading, and compliance breaches seamlessly with Ionixx's trade surveillance software solutions and services.",
                        type: "website",
                        image: 'https://www.ionixxtech.com/assets/img/SEO_OG_IMAGE/Fintech-Solutions.jpg',
                        url: "https://www.ionixxtech.com/fintech-solutions"
                    }}
                    twitter={{
                        twitter: "https://www.ionixxtech.com/fintech-solutions"
                    }}
                />
                <div className="Enterprise fintech-updaed-design Healthcare oms cap-market trade-automation trade-surveillance">
                    <div className="section-1 common-hero-bg white-bg">
                        <div className="custom-container">
                            <div className="custom-section">
                                <div className="main-head-div">
                                    <h1 className="main-head-1 custom-htech-head">
                                        <span className="primary-span">Real-time Trade Surveillance Solution</span>
                                    </h1>
                                    {/* <h2>with Ionixx’s Trade Automation Solutions</h2> */}
                                    <p>Start monitoring trades with ease and confidence</p>
                                </div>

                                <div className="main-para-div">
                                    <Link to={ROUTES.CONTACTUS} className="btn btn-primary connect-btn">Talk to us</Link>
                                </div>
                                
                            </div>
                        </div>
                        
                    </div>
                    <div className='section-2 beige-bg newcamp-section2'>
                        <div className='custom-container'>
                            <div className="custom-section">
                                <div className="row work-with-row">
                                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 work-with-col">
                                        <div className="left-info-hold">
                                            <div className="main-head-div custom-main-head-div title-center">
                                                <h2 className="main-head-1 main-head-2">
                                                    <span>Smart Trade Surveillance solution<br></br> to stay informed and compliant</span>
                                                </h2>
                                                <br></br>
                                                <p>At Ionixx, we revolutionize the way you manage and optimize your trades. Our cutting-edge<br></br> trade surveillance solution is designed to empower businesses of all sizes, providing<br></br> real-time insights and control over your trading activities.<br></br><br></br> We are committed to empowering traders with the tools they need to succeed<br></br> in today's competitive markets. Whether you're an individual trader, financial institution, or fund manager,<br></br> our solution is tailored to meet your specific monitoring needs.</p>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> 
                    </div>

                    <div className="company-info beige-bg newcamp-section3">
                        <div className="section-2 white-bg">
                            <div className="custom-container">
                                <div className="custom-section">
                                    <div className="row work-with-row">
                                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 work-with-col">
                                            <div className="left-info-hold">
                                                <div className="main-head-div custom-main-head-div">
                                                    <h3 className="main-head-1 main-head-2">
                                                        <span className="primary-span">Gain Transparency and Instant Access<br></br> to Key Insights</span>
                                                    </h3>
                                                </div>

                                            </div>
                                        </div>
                                        <div className='content-section'>
                                            <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                                                <div className='solution-list-item'>
                                                    <span className='oms-img-tag'>
                                                        <img src='/assets/img/trade-automation/key-off-ico1.svg' />
                                                    </span>
                                                    <div className='solution-card-data'>
                                                        <strong>Real-Time Monitoring and Insights</strong>
                                                        <span>Ionixx offers unparalleled real-time monitoring, providing live data feeds and actionable insights into market trends and performance metrics.</span>
                                                    </div>
                                                </div>
                                            </div>


                                            <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                                                <div className='solution-list-item'>
                                                    <span className='oms-img-tag'>
                                                        <img src='/assets/img/trade-automation/key-off-ico2.svg' />
                                                    </span>
                                                    <div className='solution-card-data'>
                                                        <strong>Customizable Alerts</strong>
                                                        <span>Set personalized alerts based on your criteria to ensure you never miss important market movements or compliance issues.</span>

                                                    </div>
                                                </div>
                                            </div>



                                            <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                                                <div className='solution-list-item'>
                                                    <span className='oms-img-tag'>
                                                        <img src='/assets/img/trade-automation/key-off-ico3.svg' />
                                                    </span>
                                                    <div className='solution-card-data'>
                                                        <strong>Advanced Analytics</strong>
                                                        <span>Dive deep into performance metrics, trends, and historical data to refine strategies and identify opportunities.</span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                                                <div className='solution-list-item'>
                                                    <span className='oms-img-tag'>
                                                        <img src='/assets/img/trade-automation/key-off-ico4.svg' />
                                                    </span>
                                                    <div className='solution-card-data'>
                                                        <strong>Risk Management Tools</strong>
                                                        <span>Utilize advanced risk management tools to monitor and mitigate potential risks associated with your trades, safeguarding your investments.</span>
                                                    </div>
                                                </div>
                                            </div>



                                            <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                                                <div className='solution-list-item'>
                                                    <span className='oms-img-tag'>
                                                        <img src='/assets/img/trade-automation/key-off-ico5.svg' />
                                                    </span>
                                                    <div className='solution-card-data'>
                                                        <strong>Compliance and Regulatory Adherence</strong>
                                                        <span>Ionixx provides compliance and regulatory automation tools to ensure that your trading operations meet stringent industry standards effortlessly.</span>

                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                                                <div className='solution-list-item'>
                                                    <span className='oms-img-tag'>
                                                        <img src='/assets/img/trade-automation/key-off-ico6.svg' />
                                                    </span>
                                                    <div className='solution-card-data'>
                                                        <strong>Comprehensive Reporting</strong>
                                                        <span>Access detailed reports and analytics to evaluate trade performance, identify trends, and make data-driven decisions for future trades.</span>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='newcamp-section4'>
                        <div className="section-3 section-2 beige-bg">
                            <div className="custom-container">
                                <div className="custom-section">
                                    <div className="row work-with-row">
                                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 work-with-col">
                                            <div className="left-info-hold">
                                                <div className="main-head-div custom-main-head-div">
                                                    <h2 className="main-head-1 main-head-2">
                                                        <span className="primary-span text-center">Trade Surveillance Highlights</span>
                                                        {/* <span className="secondary-span text-center">Everything</span> */}
                                                    </h2>
                                                </div>

                                            </div>
                                        </div>
                                        <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12 work-with-col">
                                            <div className='content-section oms-sol-pro'>
                                                <div className='col-lg-6 col-md-6 col-sm-12 solution-list'>
                                                    <i><img src='/assets/img/trade-automation/market-ico.svg' /></i>
                                                    <p>Trade Details & Position Overview</p>
                                                </div>
                                                <div className='col-lg-6 col-md-6 col-sm-12 solution-list'>
                                                    <i><img src='/assets/img/trade-automation/scale-flexi.svg' /></i>
                                                    <p>Smart Order Rules Management</p>
                                                </div>
                                                <div className='col-lg-6 col-md-6 col-sm-12 solution-list'>
                                                    <i><img src='/assets/img/trade-automation/team-member.svg' /></i>
                                                    <p>Monitor Firm Inventory PnL Realtime</p>
                                                </div>
                                                <div className='col-lg-6 col-md-6 col-sm-12 solution-list'>
                                                    <i><img src='/assets/img/trade-automation/data-feeds.svg' /></i>
                                                    <p>Manage allowable securities to Trade</p>
                                                </div>
                                                <div className='col-lg-6 col-md-6 col-sm-12 solution-list'>
                                                    <i><img src='/assets/img/trade-automation/money-bank.svg' /></i>
                                                    <p>Realtime metrics on orders processed, pending orders, etc</p>
                                                </div>
                                                <div className='col-lg-6 col-md-6 col-sm-12 solution-list'>
                                                    <i><img src='/assets/img/trade-automation/alerts-notify.svg' /></i>
                                                    <p>Monitor Concentration Risk Real-time</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-xl-4 col-lg-4 col-md-12 col-sm-12 work-with-col-right">
                                            <div className='trdae-surveillance-list'>
                                                <ul>
                                                    <li>
                                                        <p>Robust API allows access to all features</p>
                                                    </li>
                                                    <li>
                                                        <p>Controls are available in the admin console.</p>
                                                    </li>
                                                    <li>
                                                        <p>Risk Controls – Configure Pre-trade risk controls for market access</p>
                                                    </li>
                                                    <li>
                                                        <p>Custom rules-based order review</p>
                                                    </li>
                                                    <li>
                                                        <p>Compliance Controls – Integrated day trading monitoring, & order marking</p> 
                                                    </li>
                                                    <li>
                                                        <p>Multiple levels of user support & authentication</p>
                                                    </li>
                                                    <li>
                                                        <p>Connected to OMS real-time & can be integrated with the back office for account balance viewing</p> 
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='section-5 newcamp-section5'>
                        <div className="custom-container">
                            <div className="custom-section">
                                <div className="row work-with-row">
                                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 work-with-col">
                                        <div className="left-info-hold">
                                            <div className="main-head-div custom-main-head-div">
                                                <h2 className="main-head-1 main-head-2">
                                                    <span className="primary-span new-heading1">Benefits you can count on from<br></br> our Trade Surveillance Solutions</span>
                                                </h2>
                                                <p>Trade finance automation involves leveraging technology to streamline and<br></br> optimize various aspects of trade finance operations.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="compilance-section">
                                        <div className='compilance-list col-xl-6 col-lg-6 col-md-6 col-sm-12'>
                                            <i><img src='/assets/img/capital-market/Improved-efficiency.svg' /></i>
                                            <span>Enhanced Efficiency</span>
                                            <p>Streamline your trading operations and reduce manual efforts, saving time and resources.</p>
                                        </div>
                                        <div className='compilance-list col-xl-6 col-lg-6 col-md-6 col-sm-12'>
                                            <i><img src='/assets/img/trade-automation/AI-ico.svg' /></i>
                                            <span>Improved Compliance</span>
                                            <p>Stay compliant with industry regulations effortlessly, minimizing the risks of non-compliance.</p>
                                        </div>
                                        <div className='compilance-list col-xl-6 col-lg-6 col-md-6 col-sm-12'>
                                            <i><img src='/assets/img/capital-market/Reduced-time-to-marke.svg' /></i>
                                            <span>Reduced Risk Exposure</span>
                                            <p>Identify and manage potential risks effectively, minimizing the impact of adverse market movements on your portfolio.</p>
                                        </div>
                                        <div className='compilance-list col-xl-6 col-lg-6 col-md-6 col-sm-12'>
                                            <i><img src='/assets/img/capital-market/Optimized-costs.svg' /></i>
                                            <span>Optimized Performance</span>
                                            <p>Maximize profits and minimize losses through data-driven decisions and strategic insights.</p>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="company-info beige-bg newcamp-section5 newcamp-section9">
                        <div className="section-2 white-bg">
                            <div className="custom-container">
                                <div className="custom-section">
                                    <div className="row work-with-row">
                                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 work-with-col">
                                            <div className="left-info-hold">
                                                <div className="main-head-div custom-main-head-div">
                                                    <h2 className="main-head-1 main-head-2">
                                                        <span className="primary-span ">Why Choose Ionixx for<br></br> Trade Surveillance needs?</span>
                                                    </h2>
                                                </div>

                                            </div>
                                        </div>

                                        <div className='content-section'>
                                            <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                                                <div className='solution-list-item'>
                                                    <span className='oms-img-tag'>
                                                        <img src='/assets/img/capital-market/shild-icon.svg' />
                                                    </span>
                                                    <div className='solution-card-data'>
                                                        <strong>Comprehensive Solutions</strong>
                                                        <span>Maximize efficiency with a suite of holistic solutions that address trade surveillance and surveillance needs, consolidating tools and functionalities in one platform.</span>

                                                    </div>
                                                </div>
                                            </div>



                                            <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                                                <div className='solution-list-item'>
                                                    <span className='oms-img-tag'>
                                                        <img src='/assets/img/capital-market/shild-icon.svg' />
                                                    </span>
                                                    <div className='solution-card-data'>
                                                        <strong>Tailored Solutions for Diverse Strategies</strong>
                                                        <span>We understand that every trading strategy is unique. Our customizable solutions cater to a wide array of trading styles, empowering you with the flexibility to implement strategies that align perfectly with your specific objectives.</span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                                                <div className='solution-list-item'>
                                                    <span className='oms-img-tag'>
                                                        <img src='/assets/img/capital-market/shild-icon.svg' />
                                                    </span>
                                                    <div className='solution-card-data'>
                                                        <strong>Industry Experts & Support</strong>
                                                        <span>Benefit from our deep industry expertise and a dedicated team committed to helping you navigate the complexities of trade surveillance and surveillance effectively.</span>
                                                    </div>
                                                </div>
                                            </div>



                                            <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                                                <div className='solution-list-item'>
                                                    <span className='oms-img-tag'>
                                                        <img src='/assets/img/capital-market/shild-icon.svg' />
                                                    </span>
                                                    <div className='solution-card-data'>
                                                        <strong>Innovation</strong>
                                                        <span>Propel your business forward with our cutting-edge and innovative technologies, continuously evolving to meet the dynamic demands of the ever-changing market landscape.</span>

                                                    </div>
                                                </div>
                                            </div>

                                            <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                                                <div className='solution-list-item'>
                                                    <span className='oms-img-tag'>
                                                        <img src='/assets/img/capital-market/shild-icon.svg' />
                                                    </span>
                                                    <div className='solution-card-data'>
                                                        <strong>User-Friendly Interface</strong>
                                                        <span>Experience the convenience of an intuitive interface meticulously crafted for effortless use and seamless navigation, enhancing your overall user experience.
</span>
                                                    </div>
                                                </div>
                                            </div>



                                            <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                                                <div className='solution-list-item'>
                                                    <span className='oms-img-tag'>
                                                        <img src='/assets/img/capital-market/shild-icon.svg' />
                                                    </span>
                                                    <div className='solution-card-data'>
                                                        <strong>Compliance Assurance</strong>
                                                        <span>Rely on Ionixx to guarantee adherence to industry regulations, protect your reputation, and significantly mitigate regulatory risks for your business.</span>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                    


                                </div>
                            </div>
                        </div>
                    </div>
                    
                    

                    <div className="company-info beige-bg newcamp-section6">
                        <div className="section-2 white-bg grey-fintech-bg">
                            <div className='custom-container'>
                                <div className="marketting-teams">
                                    <div className="main-head-div main-head-1">
                                        <h4>
                                            <span className="primary-span">Get started today!</span>
                                        </h4>
                                    </div>
                                    <p>
                                    Experience the difference of proactive, data-driven surveillance that empowers you to stay ahead of the market. Get in touch with our trade surveillance experts.
                                    </p>
                                    <div className='team-detail'>
                                        <a href="https://calendly.com/ionixxtechnologies-office/30min?" target="_blank"><button name="Book a demo" className='Book-a-demo' >
                                            Book a demo
                                        </button></a>
                                        <a href="https://www.ionixxtech.com/contactus"><button className='consultation' name="Get a consultation" >
                                            Get a consultation
                                        </button></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="section-10 beige-bg company-info development-solution faq-listwrp">
                        <div className="custom-container">
                            <div className="custom-section">
                                <div className="main-head-div heading_lines">
                                    <h1 className="main-head-1">
                                        <span className="primary-span">

                                            Frequently <em>Asked</em> Questions
                                        </span>
                                    </h1>
                                </div>
                                <div className="row work-with-row">
                                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 work-with-col">
                                        <div className={`parent ${this.state.showMore && 'active'}`}>
                                            <Accordion className="faq-list">
                                                <AccordionSummary
                                                    expandIcon={<ExIcon />}
                                                    aria-controls="panel1a-content"
                                                    id="panel1a-header"
                                                    className="summary"
                                                >
                                                    <h2 className="summary-content">
                                                        <span className="query-content">How does Ionixx's Real-Time Trade Surveillance benefit businesses?</span></h2>
                                                </AccordionSummary>
                                                <AccordionDetails className="faq-details">
                                                    <Typography>
                                                        <span className="padding-left">
                                                            Real-time monitoring offers live data feeds and instant insights into market trends, allowing businesses to make timely and informed decisions, capitalize on opportunities, and swiftly respond to market movements.
                                                        </span>
                                                    </Typography>
                                                </AccordionDetails>
                                            </Accordion>
                                            <Accordion className="faq-list">
                                                <AccordionSummary
                                                    expandIcon={<ExIcon />}
                                                    aria-controls="panel1a-content"
                                                    id="panel1a-header"
                                                    className="summary"
                                                >
                                                    <h2 className="summary-content">
                                                        <span className="query-content">Can trade surveillance solutions be customized to our specific needs?</span></h2>
                                                </AccordionSummary>
                                                <AccordionDetails className="faq-details">
                                                    <Typography>
                                                        <span className="padding-left">Yes, many trade surveillance and monitoring solutions can be tailored to meet the specific needs of organizations based on trade volumes, compliance requirements, and operational processes.</span>
                                                    </Typography>
                                                </AccordionDetails>
                                            </Accordion>
                                            <Accordion className="faq-list">
                                                <AccordionSummary
                                                    expandIcon={<ExIcon />}
                                                    aria-controls="panel1a-content"
                                                    id="panel1a-header"
                                                    className="summary"
                                                >
                                                    <h2 className="summary-content">
                                                        <span className="query-content">What features are typically included in Ionixx’s trade surveillance and monitoring solution?</span></h2>
                                                </AccordionSummary>
                                                <AccordionDetails className="faq-details">
                                                    <Typography>
                                                        <span className="padding-left">Common features of a trade monitoring and surveillance solution may include real-time trade data monitoring, customizable alerts, risk management tools, comprehensive reporting, and trade pattern analysis. It depends on the business requirements.</span>

                                                    </Typography>

                                                </AccordionDetails>
                                            </Accordion>
                                            <Accordion className="faq-list">
                                                <AccordionSummary
                                                    expandIcon={<ExIcon />}
                                                    aria-controls="panel1a-content"
                                                    id="panel1a-header"
                                                    className="summary">
                                                    <h2 className="summary-content">
                                                        <span className="query-content">What Support and Expertise Does Ionixx Offer for Trade Surveillance Solutions?</span></h2>
                                                </AccordionSummary>
                                                <AccordionDetails className="faq-details">
                                                    <Typography className="padding-left">Ionixx provides dedicated support from industry experts to guide users in leveraging trade surveillance solutions effectively. Our team offers training, consultation, and ongoing assistance to ensure optimal use and understanding of the platform.</Typography>
                                                </AccordionDetails>
                                            </Accordion>

                                            <div className="child">
                                                <Accordion className="faq-list">
                                                    <AccordionSummary
                                                        expandIcon={<ExIcon />}
                                                        aria-controls="panel1a-content"
                                                        id="panel1a-header"
                                                        className="summary">
                                                        <h2 className="summary-content">
                                                            <span className="query-content">Can Trade Surveillance Solutions Help Identify Risks?</span></h2>
                                                    </AccordionSummary>
                                                    <AccordionDetails className="faq-details">
                                                        <Typography className="padding-left">Yes, trade surveillance solutions utilize advanced analytics and risk management tools to identify and mitigate potential risks associated with trades. They offer insights into anomalies, unusual patterns, or suspicious activities, helping in risk mitigation strategies.
                                                            
                                                        </Typography>
                                                    </AccordionDetails>
                                                </Accordion>
                                                <Accordion className="faq-list">
                                                    <AccordionSummary
                                                        expandIcon={<ExIcon />}
                                                        aria-controls="panel1a-content"
                                                        id="panel1a-header"
                                                        className="summary">
                                                        <h2 className="summary-content">
                                                            <span className="query-content">How Does Ionixx Ensure Data Security in Trade Surveillance Solutions?</span></h2>
                                                    </AccordionSummary>
                                                    <AccordionDetails className="faq-details">
                                                        <Typography className="padding-left">
                                                        Ionixx prioritizes data security and employs robust encryption methods, secure data storage practices, and access controls to safeguard sensitive trading information, ensuring the confidentiality and integrity of data.
                                                        </Typography>
                                                    </AccordionDetails>
                                                </Accordion>
                                                <Accordion className="faq-list">
                                                    <AccordionSummary
                                                        expandIcon={<ExIcon />}
                                                        aria-controls="panel1a-content"
                                                        id="panel1a-header"
                                                        className="summary">
                                                        <h2 className="summary-content">
                                                            <span className="query-content">
                                                            Is Ionixx's Trade Surveillance Solution Scalable for Businesses of Different Sizes?
                                                            </span></h2>
                                                    </AccordionSummary>
                                                    <AccordionDetails className="faq-details">
                                                        <Typography className="padding-left" >
                                                        Yes, Ionixx's trade surveillance solution is designed to scale, catering to businesses of varying sizes. Whether you're a small enterprise or a large corporation, our solution adapts to your needs, ensuring seamless integration and operation.
                                                        </Typography>
                                                    </AccordionDetails>
                                                </Accordion>

                                                <Accordion className="faq-list">
                                                    <AccordionSummary
                                                        expandIcon={<ExIcon />}
                                                        aria-controls="panel1a-content"
                                                        id="panel1a-header"
                                                        className="summary" >
                                                        <h2 className="summary-content">
                                                            <span className="query-content">
                                                            What Types of Alerts can be Set Up With Trade Surveillance and Monitoring Solutions?
                                                            </span></h2>
                                                    </AccordionSummary>
                                                    <AccordionDetails className="faq-details">
                                                        <Typography className="padding-left">
                                                        Alerts can be customized to notify users about significant market events, unusual trading activities, compliance violations, or specific trade execution conditions based on predefined criteria.
                                                        </Typography>
                                                    </AccordionDetails>
                                                </Accordion>

                                                <Accordion className="faq-list">
                                                    <AccordionSummary
                                                        expandIcon={<ExIcon />}
                                                        aria-controls="panel1a-content"
                                                        id="panel1a-header"
                                                        className="summary" >
                                                        <h2 className="summary-content">
                                                            <span className="query-content">
                                                            Can trade automation guarantee profits?
                                                            </span></h2>
                                                    </AccordionSummary>
                                                    <AccordionDetails className="faq-details">
                                                        <Typography className="padding-left">
                                                        While trade automation can enhance trading efficiency and improve decision-making, it does not guarantee profits. The success of your trades will depend on various factors, including market conditions, trading strategies, and risk management.  
                                                        </Typography>
                                                    </AccordionDetails>
                                                </Accordion>

                                                <Accordion className="faq-list">
                                                    <AccordionSummary
                                                        expandIcon={<ExIcon />}
                                                        aria-controls="panel1a-content"
                                                        id="panel1a-header"
                                                        className="summary" >
                                                        <h2 className="summary-content">
                                                            <span className="query-content">
                                                            How can trade automation benefit large financial institutions and businesses?
                                                            </span></h2>
                                                    </AccordionSummary>
                                                    <AccordionDetails className="faq-details">
                                                        <Typography className="padding-left">
                                                        For large financial institutions and businesses, trade automation brings enhanced scalability, efficiency, and risk management capabilities. It allows them to handle large-scale trading volumes, reduce human error, and execute trades seamlessly across multiple markets.
                                                        </Typography>
                                                    </AccordionDetails>
                                                </Accordion>

                                            </div>

                                        </div>

                                        <div className="btn-wrp">
                                            <button className="btn" onClick={() => { this.setState({ showMore: !this.state.showMore }) }}>{this.state.showMore ? 'View Less' : 'View More'} </button>
                                        </div>



                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    
                    

                    








                    <ContactUS show={this.state.showContactUS} handleClose={() => this.setState({ showContactUS: false })} />
                </div>
            </>
        );
    }

}


const mapStateToProps = (state, ownProps) => {
    return state.commonReducer;
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return bindActionCreators(contactAction, dispatch);
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TradeSurveillance);
function ExIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="14" viewBox="0 0 24 14" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M23.5227 3.04159L13.1523 13.412C12.5159 14.0484 11.4841 14.0484 10.8477 13.412L0.477287 3.04159C-0.159096 2.40521 -0.159096 1.37343 0.477287 0.737051C1.11367 0.100669 2.14545 0.100669 2.78183 0.737051L12 9.95522L21.2182 0.737052C21.8546 0.10067 22.8863 0.10067 23.5227 0.737052C24.1591 1.37343 24.1591 2.40521 23.5227 3.04159Z" fill="#42C2A2" />
        </svg>
    )
}
