import React from "react";
import "./contact-us.css";
import ContactUS from "../../components/contact-us";
import ReactSEOMetaTags from "react-seo-meta-tags";
import Helmet from "react-helmet";

import * as contactAction from "../../action/contactAction";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";
import { ROUTES } from "../../routes";
import WizardComponent from "../../components/wizard";
import ContactForm from "../../components/contact-form/contact-form";

class Contact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      headerClassAdd: "",
      showContactUS: false,
      showToast: false,
    };
    this.onScrollHeader = this.onScrollHeader.bind(this);
  }
  componentDidMount() {
    if (this.props.location?.state?.scrollAddress === true) {
      let addressContainer = document.getElementById("addressContainer");
      setTimeout(() => {
        window.scrollTo({ behavior: "auto", top: addressContainer.offsetTop });
      }, 50);
    }
    window.addEventListener("scroll", this.onScrollHeader);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.onScrollHeader);
  }

  onclickAddress = () => {
    let addressContainer = document.getElementById("addressContainer");
    window.scrollTo({ behavior: "smooth", top: addressContainer.offsetTop });
  };
  onScrollHeader() {
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;

    const height =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;

    const scrolled = winScroll / height;

    if (scrolled === 0) {
      this.setState({
        headerClassAdd: "",
      });
    } else {
      this.setState({
        headerClassAdd: "scrollHeader",
      });
    }
  }
  sendToast() {
    this.setState({ showContactUS: true });
  }
  render() {
    return (
      <>
        <Helmet>
          <title>Contact Us | Ionixx Technologies</title>
          <meta
            name="description"
            content="Contact Ionixx Technologies for custom software solutions including blockchain, UI/UX design, fintech, healthcare, and marketplace solutions. Fill out the form, and we'll get in touch with you shortly."
          />
          <link rel="canonical" href="https://www.ionixxtech.com/contactus" />
        </Helmet>
        <ReactSEOMetaTags
          render={(el) => <Helmet>{el}</Helmet>}
          website={{
            name: "Ionixx Technologies",
            title: "Contact Us | Ionixx Technologies",
            description:
              "Contact Ionixx Technologies for custom software solutions including blockchain, UI/UX design, fintech, healthcare, and marketplace solutions. Fill out the form, and we'll get in touch with you shortly.",
            type: "website",
            image: "/assets/img/ionixx-logo.png",
            url: "https://www.ionixxtech.com/contactus",
          }}
          twitter={{
            twitter: "https://www.ionixxtech.com/contactus",
          }}
        />
        <div className="contact-us">
          {/* <div class="custom-container">
                        <div class="custom-section connect-with-custom-section">
                            <div class="main-head-div mob-pad">
                                <h4 class="main-head-1">
                                    <span class="primary-span">Partner with us</span>
                                    <span class="secondary-span">to bring your digital goals to life</span>
                                </h4>
                            </div>
                            <div class="main-para-div main-button-div">
                            <span onClick={this.sendToast.bind(this)} className="btn btn-primary connect-btn">Talk to us</span>
                            </div>
                        </div>
                    </div> */}
          <div className="top-section">
            <div className="custom-container">
              <div className="main-head-div">
                <h1 className="main-head-1">
                  <span className="secondary-span heading">Contact Us</span>
                </h1>
                <h4 className="main-head-1">
                  <span className="secondary-span">
                    Please fill out the form and we will get
                    <br /> back to you ASAP
                  </span>
                </h4>
                {/* <div class="main-para-div main-button-div">
                   <span onClick={this.sendToast.bind(this)} className="btn btn-primary connect-btn">Talk to us</span>
              </div> */}
                {/* <div className='wizard-section'>
                <div className='client-feedback'>
                    <span>"Their range of <br/>
technical and <br/>
architectural skills <br/> 
really impressed us."</span>
<div className='client-info'>
    <div className='client-img'>
        <img src='/assets/img/contact-us/steve-brucato.png' />
    </div>
    <div className='client-detail'>
        <strong>Steven Brucato,</strong>
        <em>CTO & Co-founder, Bitsian Inc</em>
    </div>
</div>
                </div>
                <div className='wizard-main-content'>
              {/* <WizardComponent/> 
              </div>
              </div> */}
                <div className="contact-form custom-contact-form">
                  <ContactForm showService={false} />
                </div>
                <h4 className="main-head-1">
                  <span className="secondary-span">
                    <a href="tel:+1 (909) 757 - 3707" style={{ color: "#fff" }}>
                      Call us at +1 (909) 757 - 3707
                    </a>
                  </span>
                </h4>
              </div>
            </div>
          </div>

          <div class="section-4 sales-sec">
            <div class="custom-container">
              <div class="custom-section connect-with-custom-section">
                <div class="main-head-div">
                  <h4 class="main-head-1 main-head-2">
                    <span class="secondary-span">Our</span>
                    <span class="primary-span">Offices</span>
                  </h4>
                </div>

                <div class="card-sect">
                  <div class="row">
                    {/* <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12">
                                            <div class="add-group">
                                                <div class="add-head">
                                                    <h4>USA</h4>
                                                </div>
                                                <div class="add-capt">
                                                    <p>+1 (909) 757 - 3707</p>
                                                </div>
                                            </div>
                                        </div> */}
                    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 custom-add-row">
                      <div class="add-group">
                        <div class="add-head">
                          <h4>USA</h4>
                        </div>
                        <div class="add-capt">
                          <p>
                            11801 Domain Blvd, Fl 3 Austin,{" "}
                            <span className="new-ln-sp">TX 78758,</span> United
                            States
                          </p>
                          <p style={{ margin: "0", textAlign: "center" }}>&</p>
                          <p>
                            110 Wall St,{" "}
                            <span className="new-ln-sp">New York,</span>
                            <span className="new-ln-sp pl3">NY 10005</span>
                          </p>
                        </div>
                        <em>+1 (909) 757 - 3707</em>
                      </div>
                    </div>

                    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 custom-add-row">
                      <div class="add-group">
                        <div class="add-head">
                          <h4>Canada</h4>
                        </div>
                        <div class="add-capt">
                          <p>
                            701 Evans Ave,{" "}
                            <span className="new-ln-sp"> Suite 509,</span>{" "}
                            Toronto,
                            <br />
                            <span className="new-ln-sp">
                              ON, Canada,
                              <br /> M9C 1A3
                            </span>
                          </p>
                        </div>
                        <em>+1 (909) 757 - 3707</em>
                      </div>
                    </div>

                    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 custom-add-row">
                      <div class="add-group">
                        <div class="add-head">
                          <h4>Singapore</h4>
                        </div>
                        <div class="add-capt">
                          <p>
                            10 Anson Road
                            <br />
                            <span className="new-ln-sp">#22-02,</span>
                            <br /> International Plaza,
                            <br />
                            <span className="new-ln-sp">Singapore 079903</span>
                          </p>
                        </div>
                        <em>+1 (909) 757 - 3707</em>
                      </div>
                    </div>

                    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12 custom-add-row">
                      <div class="add-group">
                        <div class="add-head">
                          <h4>India</h4>
                        </div>
                        <div class="add-capt">
                          <p>
                            <span className="new-ln-sp">
                              No. L 25 (Second Floor),{" "}
                            </span>
                            <br /> Dr. Vikram Sarabhai Instronics Estate,
                            <span className="new-ln-sp"> SRP Tools,</span>{" "}
                            Thiruvanmiyur, Chennai,{" "}
                            <span className="new-ln-sp">TN 600041</span>
                          </p>
                        </div>
                        <em>+1 (909) 757 - 3707</em>
                      </div>
                    </div>

                    <div class="col-xl-4 col-lg-4 col-md-3 col-sm-12 custom-add-row">
                      <div class="add-group">
                        <div class="add-head">
                          <h4>Dubai</h4>
                        </div>
                        <div class="add-capt">
                          <p>
                            FZCO, <br /> Premises Number :<br /> DSO - IFZA -
                            21639,{" "}
                            <span className="new-ln-sp">
                              Dubai Silicon Oasis, DDP, Building A2,
                            </span>
                            Dubai, United Arab Emirates
                          </p>
                        </div>
                        {/* <em>+1 (909) 757 - 3707</em> */}
                      </div>
                    </div>

                    {/* <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                                            <div class="add-group">
                                                <div class="add-head">
                                                    <h4><span>USA</span>/<span>Canada</span></h4>
                                                </div>
                                                <div class="add-capt">
                                                    <p>+1 (909) 757 - 3707</p>
                                                    <p class="med-text">Toll free number:</p>
                                                    <p>1-888-202-7602</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                                            <div class="add-group">
                                                <div class="add-head">
                                                    <h4>Singapore</h4>
                                                </div>
                                                <div class="add-capt">
                                                    <p>+65 3158 4074</p>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                                            <div class="add-group">
                                                <div class="add-head">
                                                    <h4>India</h4>
                                                </div>
                                                <div class="add-capt">
                                                    <p>+91 7397385403</p>
                                                </div>
                                            </div>
                                        </div>
                                         */}

                    {/* <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                        <div class="enqui-div">
                                                <div class="main-head-div">
                                                    <h4 class="main-head-1 main-head-2">
                                                        <span class="secondary-span">For</span>
                                                        <span class="primary-span">Press/Media Inquiries</span>
                                                    </h4>
                                                </div>
                                                <div class="add-capt margin-class">
                                                    <p>+91 7397385403</p>
                                                </div>
                                                <div class="add-capt">
                                                    <p class="med-text">Email:</p>
                                                    <p>ramya.k@ionixxtech.com</p>
                                                </div>
                                            </div>
                                        </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div id={"addressContainer"} class="section-4 our-location">
            <div class="custom-container">
              <div class="custom-section connect-with-custom-section">
                <div class="main-head-div">
                  <h4 class="main-head-1">
                    <span class="secondary-span">For</span>
                    <span class="primary-span">Enquiries</span>
                  </h4>
                </div>

                <div className="for-enquiries">
                  <div class="row">
                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                      <strong>Sales</strong>
                      <div className="phone-num">
                        <b>Number:</b>
                        <span>
                          <a
                            className="link-to-connect"
                            href="tel:+1 (909) 757 - 3707"
                          >
                            +1 (909) 757 - 3707
                          </a>
                        </span>
                      </div>
                      <div className="phone-num">
                        <b>Email:</b>
                        <a href="mailto:info@ionixxtech.com">
                          info@ionixxtech.com
                        </a>
                      </div>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                      <strong>Careers</strong>
                      <div className="phone-num">
                        <b>Number:</b>
                        <span>
                          <a
                            className="link-to-connect"
                            href="tel:+91 7397385400"
                          >
                            +91 7397385400
                          </a>
                        </span>
                      </div>
                      <div className="phone-num">
                        <b>Email:</b>
                        <a href="mailto:hr@ionixxtech.com">hr@ionixxtech.com</a>
                      </div>
                    </div>
                    <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                      <strong>Media</strong>
                      <div className="phone-num">
                        <b>Number:</b>
                        <span>
                          <a
                            className="link-to-connect"
                            href="tel:+1 (909) 757 - 3707"
                          >
                            +1 (909) 757 - 3707
                          </a>
                        </span>
                      </div>
                      <div className="phone-num">
                        <b>Email:</b>
                        <a href="mailto:ixmarketing@ionixxtech.com">
                          ixmarketing@ionixxtech.com
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ContactUS
            show={this.state.showContactUS}
            handleClose={() => this.setState({ showContactUS: false })}
          />
        </div>
      </>
    );
  }
}

// export default ContactUs;

const mapStateToProps = (state, ownProps) => {
  return state.commonReducer;
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return bindActionCreators(contactAction, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps, null, {
  forwardRef: true,
})(Contact);
