export const ROUTES = {
  HOME: "/",
  NOT_FOUND: "/404",
  TECHNOLOGY: "/solution/software-development-company",
  MARKETPLACE: "/solution/online-marketplace-solution",
  STARTUP: "/solution/startup",
  ENTERPRISE: "/solution/enterprise",
  ABOUTUS: "/company",
  CONTACTUS: "/contactus",
  RESOURCES: "/resource",
  // resources
  NEW_RESOURCES: "/resources",
  EVENTSNEWS: "/events-news",
  WHITEPAPEREBOOK: "/whitepaper-ebooks",
  NEWSLETTER: "/newsletter",
  PORTFOLIO: "/portfolio",
  CASESTUDYP2E: "/case-studies/regulatory-compliance-management",
  CASESTUDYGADDIBAZZAR: "/case-studies/vehicle-trading-platform",
  CASESTUDYTENSHEY: "/case-studies/executive-coaching-platform",
  CASESTUDYDRIVEWEALTH: "/case-studies/post-trade-operations",
  CASESTUDYDUBUKU: "/case-studies/media-sharing-application",
  CASESTUDYCRYPTOSPACE: "/case-studies/cryptospace",
  // BLOCKCHAIN:"/solution/blockchain-development-company",
  BlOCKCHAIN_NEW: "/solution/web3-blockchain-solutions",
  HOMEDESIGN: "/home",
  DESIGN: "/solution/ui-ux-design-services-company",
  // BlockchainNew: "/solution/web3-blockchain-solutions ",
  HEALTHCARE: "/solution/healthcare-solutions",
  FINTECH: "/solution/fintech-solutions",
  CAPITAL_MARKET: "/solution/fintech/capital-markets",
  TRADE_AUTOMATION: "/solution/fintech/trade-automation",
  TRADE_SURVEILLANCE: "/solution/fintech/trade-surveillance",
  KYC: "/kyc",
  // CRYPTODESIGN: "/solution/crypto-trading-platform",
  CRYPTOOTC: "/solution/crypto-otc",
  ALGOBOT: "/crypto-bot-development",
  ALGOBOTDEVELOPMENT: "/crypto-bot-development/develop-crypto-trading-bot",
  CRYPTOOTCDEVELOPMENT: "/crypto-otc-development/crypto-otc-platform",
  OTCSOLUTION: "/crypto-otc-development",
  ALGOBOTVERSION_1: "/crypto-bot-development-version1",
  //HEALTHCARECONTEST:"/healthcare-contest",
  TOAST: "/thankyou",

  CRYPTOOTCWITHFORM: "/crypto-otc-withForm",
  ALGOBOTWITHFORM: "/algo-bot-withForm",
  ALGOBOTWITHFORMDEVELOPMENT: "/algo-bot-withForm-development",
  CRYPTOOTCWITHFORMDEVELOPMENT: "/crypto-otc-withForm-development",
  CAREERS: "/careers",
  BROKERAGE:"/solution/fintech/brokerage",
  // NEWDESIGN:"/new-design",
  TALKTOUS: "/talk-to-us",
  DIGITALBROKERAGE: "/solution/fintech/brokerage",
  OMS: "/solution/fintech/oms",
  POSTTRADE: "/solution/fintech/post-trade",
  SUBSCRIBE: "/subscribe/thankyou",
  MODERNPOSTTRADE: "/modern-post-trade-solutions",
  POSTTRADESERVICES: "/post-trade-services",
  REQUESTDEMOCALL: "/post-trade-services/thankyou",
  REQUESTDEMOCALL1: "/modern-post-trade-solutions/thankyou",
  NEURON: "/solution/healthcare-solutions/neuron",
  DEFISERVICE: "/defi-services",
  REQUESTDEMOCALLDEFI: "/defi-services/thankyou",
  DESIGNSTUDIO:"/design-studio",
  DESIGNPROCESS:"/design-process",
  DESIGNOURWORK:"/our-work"
};
