import React from 'react';
import ReactSEOMetaTags from 'react-seo-meta-tags';
import Helmet from 'react-helmet';

class GaadiBazar extends React.Component {
    constructor(props) {
        super(props)
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }


    render() {
        return (
			<>	
			<Helmet>
                <title>Multivendor online vehicle trading platform| Ionixx Techform</title>
                <meta name="description" content="Ionixx developed one of the best multi vendor online and vehicle trading marketplace platform for its client - Gaadi Bazaar. This portfolio describes how Ionixx built a streamlined vehicle trading ecosystem for buying and selling used commercial vehicles." />
                <link rel="canonical" href="https://www.ionixxtech.com/portfolio/gaadibazaar" />
            </Helmet>
            <ReactSEOMetaTags
                render={(el) => <Helmet>{el}</Helmet>}
                website={{ 
                    name: "Ionixx Technologies", 
                    title:"Multivendor online vehicle trading platform| Ionixx Techform", 
                    description: "Ionixx developed one of the best multi vendor online and vehicle trading marketplace platform for its client - Gaadi Bazaar. This portfolio describes how Ionixx built a streamlined vehicle trading ecosystem for buying and selling used commercial vehicles.",
					type:"website",
					image:'/assets/img/ionixx-logo.png',
                    url:"https://www.ionixxtech.com/portfolio/gaadibazaar"
                }}
                twitter={{ 
                    twitter: "https://www.ionixxtech.com/portfolio/gaadibazaar" }}
                /> 
                <div className="Gaadibazaar-portfolio">
                    <div className="row">
                        <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                            <div className="gd-portfolio-logo">
                                <img src="/assets/img/portfolio-img/gaadi-bazaar/gd-portfolio-logo.png"  alt="Gaadi bazaar logo" />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-6 col-sm-12 col-12 animatedParent p-0">
                            <div className="ix-gd-background animated fadeInRightBig">
                                <img src="/assets/img/portfolio-img/gaadi-bazaar/gd-topback.png"  alt="Portfolio"/>
                                <h1>PORTFOLIO</h1>
                            </div>
                        </div>
                       
                    </div>

	    <div className="ix-gd-container">
			<div className="row">
				<div className="col-lg-6 col-md-6 col-sm-12">
				 <div className="owner-of-site">
					<p>
					<strong>Gaadi Bazaar</strong> is an initiative of the <strong>Cholamandalam Investment and Finance Company</strong>, an Indian business conglomerate.
					</p>
					<p className="mb-0">
					Through this initiative, Gaadi Bazaar seeks to function as a highly competent and competitive online marketplace for buying and selling used commercial vehicles.
					</p>				
				  </div>
				  <a href="/assets/case-studies/Gaadi-Bazaar-case-study.pdf" download>
				  	<button className="btn btn-primary case-btn">Read Case Study</button>
				  </a>
				</div>
				<div className="col-lg-6 col-md-6 col-sm-12">
					<div  className="animated fadeInDownBig  gd-front-img">
						<img className="gaadi-bazaar-mob-top" src="/assets/img/portfolio-img/gaadi-bazaar/gd-mainscreen-img.png"  alt="Case study" />
					</div>
				</div>	


				<div className="col-lg-12 col-md-12 col-sm-12 col-12">
				<div className="gd-ecosystem">
				<h1 className="fadeInLeftBig animated">How we built a streamlined vehicle trading ecosystem</h1>
				<div className="bussiness-requritment">
					<div className="specifications"> 
					
					<div className="owner-of-site">
						<small>
							<svg version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 255.226 255.226" style={{enableBackground: "new 0 0 255.226 255.226;"}} >
	<g>
		<g>
			<g>
				<g>
					<path style={{fill:"#090509;"}} d="M76.532,27.975l-7.405-5.244l-0.254-0.191l-0.477-0.191l-5.403-2.574l0.159,0.381      c-5.117-2.098-10.488-3.591-16.336-3.591C20.945,16.565,0,38.336,0,65.192s20.976,48.627,46.816,48.627      c8.295,0,15.955-2.415,22.724-6.356c-2.32,25.998-15.415,63.915-63.406,102.594c-6.706,5.403-7.946,15.446-2.733,22.407      c3.051,4.1,7.596,6.198,12.173,6.198c3.305,0,6.611-1.112,9.439-3.337c58.893-47.547,73.608-96.364,75.579-128.973      c3.369-41.699-9.28-63.629-20.849-75.07L76.532,27.975z"></path>
					<path style={{fill:"#090509;"}} d="M233.76,31.28l-3.242-3.305l-7.374-5.244l-0.254-0.191l-0.509-0.191l-5.403-2.574l0.159,0.381      c-5.117-2.098-10.52-3.591-16.368-3.591c-25.839,0-46.752,21.771-46.752,48.627s20.913,48.627,46.816,48.627      c8.295,0,15.955-2.415,22.724-6.356c-2.288,25.998-15.383,63.915-63.374,102.594c-6.738,5.403-7.946,15.446-2.733,22.407      c3.051,4.1,7.596,6.198,12.173,6.198c3.305,0,6.611-1.112,9.439-3.337c58.925-47.547,73.608-96.364,75.611-128.973      C258.042,64.652,245.393,42.722,233.76,31.28z"></path>
				</g></g></g></g><g></g><g></g><g></g><g></g>
	<g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g></svg>
						</small>
					<p>
					<strong>Gaadi Bazaar</strong> approached Ionixx with a specific business requirement. They were looking to build a structured dealer/vendor trading ecosystem/ online marketplace, driven by values of transparency.
					
					</p>
					<small><svg version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 255.226 255.226" style={{enableBackground: "new 0 0 255.226 255.226;"}} >
	<g>
		<g>
			<g>
				<g>
					<path style={{fill:"#090509;"}} d="M76.532,27.975l-7.405-5.244l-0.254-0.191l-0.477-0.191l-5.403-2.574l0.159,0.381      c-5.117-2.098-10.488-3.591-16.336-3.591C20.945,16.565,0,38.336,0,65.192s20.976,48.627,46.816,48.627      c8.295,0,15.955-2.415,22.724-6.356c-2.32,25.998-15.415,63.915-63.406,102.594c-6.706,5.403-7.946,15.446-2.733,22.407      c3.051,4.1,7.596,6.198,12.173,6.198c3.305,0,6.611-1.112,9.439-3.337c58.893-47.547,73.608-96.364,75.579-128.973      c3.369-41.699-9.28-63.629-20.849-75.07L76.532,27.975z"></path>
					<path style={{fill:"#090509;"}} d="M233.76,31.28l-3.242-3.305l-7.374-5.244l-0.254-0.191l-0.509-0.191l-5.403-2.574l0.159,0.381      c-5.117-2.098-10.52-3.591-16.368-3.591c-25.839,0-46.752,21.771-46.752,48.627s20.913,48.627,46.816,48.627      c8.295,0,15.955-2.415,22.724-6.356c-2.288,25.998-15.383,63.915-63.374,102.594c-6.738,5.403-7.946,15.446-2.733,22.407      c3.051,4.1,7.596,6.198,12.173,6.198c3.305,0,6.611-1.112,9.439-3.337c58.925-47.547,73.608-96.364,75.611-128.973      C258.042,64.652,245.393,42.722,233.76,31.28z"></path>
				</g></g></g></g><g></g><g></g><g></g><g></g>
	<g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g></svg></small>
					</div>
						
					<ul className="wow">
						<li className="wow fadeInDown"><h6>Product Users</h6> </li>
						<li className="wow fadeInUp"><h6>Automobile Vendors</h6></li>
						<li className="wow fadeInDown"><h6>Traders</h6></li>
						<li className="wow fadeInUp"><h6>Consumers</h6></li>
					</ul>
					</div>
				</div>
				</div>	
			</div>
		</div>
	</div>
	<div className="row margin-minus">
		<div className="col-lg-6 col-md-6 col-sm-12">
		<div className="challenges challenges2">
		<h3 className="wow flipInY">Challenges</h3>
			<small>
				<svg version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 255.226 255.226" style={{enableBackground: "new 0 0 255.226 255.226;"}} className="left-svg">
<g>
	<g>
		<g>
			<g>
				<path style={{fill:"#090509;"}} d="M76.532,27.975l-7.405-5.244l-0.254-0.191l-0.477-0.191l-5.403-2.574l0.159,0.381      c-5.117-2.098-10.488-3.591-16.336-3.591C20.945,16.565,0,38.336,0,65.192s20.976,48.627,46.816,48.627      c8.295,0,15.955-2.415,22.724-6.356c-2.32,25.998-15.415,63.915-63.406,102.594c-6.706,5.403-7.946,15.446-2.733,22.407      c3.051,4.1,7.596,6.198,12.173,6.198c3.305,0,6.611-1.112,9.439-3.337c58.893-47.547,73.608-96.364,75.579-128.973      c3.369-41.699-9.28-63.629-20.849-75.07L76.532,27.975z"></path>
				<path style={{fill:"#090509;"}} d="M233.76,31.28l-3.242-3.305l-7.374-5.244l-0.254-0.191l-0.509-0.191l-5.403-2.574l0.159,0.381      c-5.117-2.098-10.52-3.591-16.368-3.591c-25.839,0-46.752,21.771-46.752,48.627s20.913,48.627,46.816,48.627      c8.295,0,15.955-2.415,22.724-6.356c-2.288,25.998-15.383,63.915-63.374,102.594c-6.738,5.403-7.946,15.446-2.733,22.407      c3.051,4.1,7.596,6.198,12.173,6.198c3.305,0,6.611-1.112,9.439-3.337c58.925-47.547,73.608-96.364,75.611-128.973      C258.042,64.652,245.393,42.722,233.76,31.28z"></path>
			</g></g></g></g><g></g><g></g><g></g><g></g>
<g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g></svg>
			</small>
			<p>
				Through our first-level research, we identified that 70% of vehicle buyers conducted their primary research online.Therefore, the primary challenge that we sought to address through our design process was to build trust, while managing customer expectations throughout the customer
				lifecycle & establishing a strong customer relationship.
			</p>
			<small>
				<svg version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 255.226 255.226" style={{enableBackground: "new 0 0 255.226 255.226;"}}>
<g>
	<g>
		<g>
			<g>
				<path style={{fill:"#090509;"}} d="M76.532,27.975l-7.405-5.244l-0.254-0.191l-0.477-0.191l-5.403-2.574l0.159,0.381      c-5.117-2.098-10.488-3.591-16.336-3.591C20.945,16.565,0,38.336,0,65.192s20.976,48.627,46.816,48.627      c8.295,0,15.955-2.415,22.724-6.356c-2.32,25.998-15.415,63.915-63.406,102.594c-6.706,5.403-7.946,15.446-2.733,22.407      c3.051,4.1,7.596,6.198,12.173,6.198c3.305,0,6.611-1.112,9.439-3.337c58.893-47.547,73.608-96.364,75.579-128.973      c3.369-41.699-9.28-63.629-20.849-75.07L76.532,27.975z"></path>
				<path style={{fill:"#090509;"}} d="M233.76,31.28l-3.242-3.305l-7.374-5.244l-0.254-0.191l-0.509-0.191l-5.403-2.574l0.159,0.381      c-5.117-2.098-10.52-3.591-16.368-3.591c-25.839,0-46.752,21.771-46.752,48.627s20.913,48.627,46.816,48.627      c8.295,0,15.955-2.415,22.724-6.356c-2.288,25.998-15.383,63.915-63.374,102.594c-6.738,5.403-7.946,15.446-2.733,22.407      c3.051,4.1,7.596,6.198,12.173,6.198c3.305,0,6.611-1.112,9.439-3.337c58.925-47.547,73.608-96.364,75.611-128.973      C258.042,64.652,245.393,42.722,233.76,31.28z"></path>
			</g></g></g></g><g></g><g></g><g></g><g></g>
<g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g></svg>
			</small>
		</div>
		
		</div>
		<div className="col-lg-6 col-md-6 col-sm-12">
		<div className="decision-making">
		<h3 className="headline "><span></span>SOLUTIONS </h3>
		<div className="our-desing">
			<h4>We helped Gaadi Bazaar users in their decision-making process through our design!</h4>

			<p>We devised a solution that comprised the following activities:</p>
			<p className="list-para"> Brainstorming </p>
			<p className="list-para"> Sketching and prototyping (Wireframing and Visual Design) </p>
			<p>
				Based on the brainstorming activities, wireframing & visual design, we identified how we could use design to build trust with the user.
			</p>
			<p>
				By designing a single platform embedded with search, research and compare functionalities, we sought to build credibility for the platform.
			</p>
				

		</div>
		</div>
		</div>
		<div className="we-provided">
		<div className="col-lg-6 col-md-6 col-sm-12">
			<div className="provide">
			<h2 className="wow">WE <span></span> PROVIDED <span className="joint"></span></h2>
			</div>
		</div>
		<div className="col-lg-6 col-md-6 col-sm-12">
			<div className="our-desing">
				<h6>Take a look at our detailed design process below.</h6>
			</div>
		</div>
		</div>
		</div>
		<div className="ix-gd-container">
		<div className="brainstorming">
		<h3 className="wow bounceIn">Brainstorming</h3>
		<img src="/assets/img/portfolio-img/gaadi-bazaar/gd-brainstorm.png"  alt="Brainstorming" className="gd-brain-storm" style={{width: "70%;"}}/>
		<h6 className="wow bounceInLeft">Project Lead</h6>
		<h4 className="wow bounceInRight">Designer / Developer</h4>
		<h5 className="wow bounceInUp">Design Lead</h5>
		
		</div>
		</div>
		<div className=" wire-frame">
		<div className="ix-gd-container">
		<div className="brainstorming wr">
		<h3 className="wow tada">Wireframes</h3>
		<div className="challenges m-0">	
			<p className="quote-p"><small><svg version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 255.226 255.226" style={{enableBackground: "new 0 0 255.226 255.226;"}} className="left-svg1">
<g>
	<g>
		<g>
			<g>
				<path style={{fill:"#090509;"}} d="M76.532,27.975l-7.405-5.244l-0.254-0.191l-0.477-0.191l-5.403-2.574l0.159,0.381      c-5.117-2.098-10.488-3.591-16.336-3.591C20.945,16.565,0,38.336,0,65.192s20.976,48.627,46.816,48.627      c8.295,0,15.955-2.415,22.724-6.356c-2.32,25.998-15.415,63.915-63.406,102.594c-6.706,5.403-7.946,15.446-2.733,22.407      c3.051,4.1,7.596,6.198,12.173,6.198c3.305,0,6.611-1.112,9.439-3.337c58.893-47.547,73.608-96.364,75.579-128.973      c3.369-41.699-9.28-63.629-20.849-75.07L76.532,27.975z"></path>
				<path style={{fill:"#090509;"}} d="M233.76,31.28l-3.242-3.305l-7.374-5.244l-0.254-0.191l-0.509-0.191l-5.403-2.574l0.159,0.381      c-5.117-2.098-10.52-3.591-16.368-3.591c-25.839,0-46.752,21.771-46.752,48.627s20.913,48.627,46.816,48.627      c8.295,0,15.955-2.415,22.724-6.356c-2.288,25.998-15.383,63.915-63.374,102.594c-6.738,5.403-7.946,15.446-2.733,22.407      c3.051,4.1,7.596,6.198,12.173,6.198c3.305,0,6.611-1.112,9.439-3.337c58.925-47.547,73.608-96.364,75.611-128.973      C258.042,64.652,245.393,42.722,233.76,31.28z"></path>
			</g></g></g></g><g></g><g></g><g></g><g></g>
<g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g></svg></small>
			Brainstorming ideas resulted in wireframes and hand-drawn sketches.
			Wireframing helped us see through errors upfront and also minimize redundancies.
			<small>
				<svg version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 255.226 255.226" style={{enableBackground: "new 0 0 255.226 255.226;"}} >
<g>
	<g>
		<g>
			<g>
				<path style={{fill:"#090509;"}} d="M76.532,27.975l-7.405-5.244l-0.254-0.191l-0.477-0.191l-5.403-2.574l0.159,0.381      c-5.117-2.098-10.488-3.591-16.336-3.591C20.945,16.565,0,38.336,0,65.192s20.976,48.627,46.816,48.627      c8.295,0,15.955-2.415,22.724-6.356c-2.32,25.998-15.415,63.915-63.406,102.594c-6.706,5.403-7.946,15.446-2.733,22.407      c3.051,4.1,7.596,6.198,12.173,6.198c3.305,0,6.611-1.112,9.439-3.337c58.893-47.547,73.608-96.364,75.579-128.973      c3.369-41.699-9.28-63.629-20.849-75.07L76.532,27.975z"></path>
				<path style={{fill:"#090509;"}} d="M233.76,31.28l-3.242-3.305l-7.374-5.244l-0.254-0.191l-0.509-0.191l-5.403-2.574l0.159,0.381      c-5.117-2.098-10.52-3.591-16.368-3.591c-25.839,0-46.752,21.771-46.752,48.627s20.913,48.627,46.816,48.627      c8.295,0,15.955-2.415,22.724-6.356c-2.288,25.998-15.383,63.915-63.374,102.594c-6.738,5.403-7.946,15.446-2.733,22.407      c3.051,4.1,7.596,6.198,12.173,6.198c3.305,0,6.611-1.112,9.439-3.337c58.925-47.547,73.608-96.364,75.611-128.973      C258.042,64.652,245.393,42.722,233.76,31.28z"></path>
			</g></g></g></g><g></g><g></g><g></g><g></g>
<g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g></svg>
			</small> </p>
			
		</div>
		<div className="row">
    <div className="col-lg-4 col-md-4 col-sm-4 col-4">
<div className="wire-frame-images">
		<img src="/assets/img/portfolio-img/gaadi-bazaar/wire-frame1.png" alt="wire-frame" className="wire-frame1  wow fadeInLeft"/>
		
		
		</div>
</div><div className="col-lg-4 col-md-4 col-sm-4 col-4">
<div className="wire-frame-images">
		<img src="/assets/img/portfolio-img/gaadi-bazaar/wireframe2.png" alt="wire-frame" className="wire-frame2 wow  fadeInDown"/>
		
		
		</div>
</div><div className="col-lg-4 col-md-4 col-sm-4 col-4">
<div className="wire-frame-images">
	<img src="/assets/img/portfolio-img/gaadi-bazaar/wireframe3.png" alt="wire-frame" className="wire-frame3 wow  fadeInRight"/>
		
		
		</div>
</div>
</div>
		</div>
		</div>
		</div>

		<div className=" wire-frame visual-design">
		<div className="ix-gd-container">
		<div className="brainstorming">
		<h3 className="wow swing">Visual Design</h3>
		<div className="challenges m-0">	
			<p className="quote-p"><small>	
				<svg version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 255.226 255.226" style={{enableBackground: "new 0 0 255.226 255.226;"}} className="left-svg1">
<g>
	<g>
		<g>
			<g>
				<path style={{fill:"#090509;"}} d="M76.532,27.975l-7.405-5.244l-0.254-0.191l-0.477-0.191l-5.403-2.574l0.159,0.381      c-5.117-2.098-10.488-3.591-16.336-3.591C20.945,16.565,0,38.336,0,65.192s20.976,48.627,46.816,48.627      c8.295,0,15.955-2.415,22.724-6.356c-2.32,25.998-15.415,63.915-63.406,102.594c-6.706,5.403-7.946,15.446-2.733,22.407      c3.051,4.1,7.596,6.198,12.173,6.198c3.305,0,6.611-1.112,9.439-3.337c58.893-47.547,73.608-96.364,75.579-128.973      c3.369-41.699-9.28-63.629-20.849-75.07L76.532,27.975z"></path>
				<path style={{fill:"#090509;"}} d="M233.76,31.28l-3.242-3.305l-7.374-5.244l-0.254-0.191l-0.509-0.191l-5.403-2.574l0.159,0.381      c-5.117-2.098-10.52-3.591-16.368-3.591c-25.839,0-46.752,21.771-46.752,48.627s20.913,48.627,46.816,48.627      c8.295,0,15.955-2.415,22.724-6.356c-2.288,25.998-15.383,63.915-63.374,102.594c-6.738,5.403-7.946,15.446-2.733,22.407      c3.051,4.1,7.596,6.198,12.173,6.198c3.305,0,6.611-1.112,9.439-3.337c58.925-47.547,73.608-96.364,75.611-128.973      C258.042,64.652,245.393,42.722,233.76,31.28z"></path>
			</g></g></g></g><g></g><g></g><g></g><g></g>
<g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g></svg>
			</small>
			Post-conceptualization, we prepared the visual design to get an idea on how the app would look and feel in real-time.
			<small>
				<svg  version="1.1" id="Capa_1" x="0px" y="0px" viewBox="0 0 255.226 255.226" style={{enableBackground: "new 0 0 255.226 255.226;"}}>
<g>
	<g>
		<g>
			<g>
				<path style={{fill:"#090509"}} d="M76.532,27.975l-7.405-5.244l-0.254-0.191l-0.477-0.191l-5.403-2.574l0.159,0.381      c-5.117-2.098-10.488-3.591-16.336-3.591C20.945,16.565,0,38.336,0,65.192s20.976,48.627,46.816,48.627      c8.295,0,15.955-2.415,22.724-6.356c-2.32,25.998-15.415,63.915-63.406,102.594c-6.706,5.403-7.946,15.446-2.733,22.407      c3.051,4.1,7.596,6.198,12.173,6.198c3.305,0,6.611-1.112,9.439-3.337c58.893-47.547,73.608-96.364,75.579-128.973      c3.369-41.699-9.28-63.629-20.849-75.07L76.532,27.975z"></path>
				<path style={{fill:"#090509;"}} d="M233.76,31.28l-3.242-3.305l-7.374-5.244l-0.254-0.191l-0.509-0.191l-5.403-2.574l0.159,0.381      c-5.117-2.098-10.52-3.591-16.368-3.591c-25.839,0-46.752,21.771-46.752,48.627s20.913,48.627,46.816,48.627      c8.295,0,15.955-2.415,22.724-6.356c-2.288,25.998-15.383,63.915-63.374,102.594c-6.738,5.403-7.946,15.446-2.733,22.407      c3.051,4.1,7.596,6.198,12.173,6.198c3.305,0,6.611-1.112,9.439-3.337c58.925-47.547,73.608-96.364,75.611-128.973      C258.042,64.652,245.393,42.722,233.76,31.28z"></path>
			</g></g></g></g><g></g><g></g><g></g><g></g>
<g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g></svg>
			</small> </p>
			
		</div>
		<div className="row">
		    <div className="col-lg-4 col-md-4 col-sm-4 col-4">
		<div className="wire-frame-images">
				<img src="/assets/img/portfolio-img/gaadi-bazaar/visual1.png" className="visual1 wow  fadeInLeft" alt="wire-frame"/>
				
				
				</div>
		</div><div className="col-lg-4 col-md-4 col-sm-4 col-4">
		<div className="wire-frame-images">
				<img src="/assets/img/portfolio-img/gaadi-bazaar/visual2.png" className="visual2 wow  fadeInDown" alt="wire-frame"/>
				
				
				</div>
		</div><div className="col-lg-4 col-md-4 col-sm-4 col-4">
		<div className="wire-frame-images">
			<img src="/assets/img/portfolio-img/gaadi-bazaar/visual3.png" className="visual3 wow  fadeInRight" alt="wire-frame"/>
				
				
				</div>
</div>
</div>
		</div>
		</div>
		</div>

		<div className="bussiness-value">
		<div className="row ix-gd-container">
		<div className="col-lg-12 col-md-12 col-sm-12 col-12 mob-p">
		<h5>Providing Business Value through Design</h5>
		<p>After our initial wireframing, we thought it fit to include the following features.</p>

		<div className="flex-wireframe">
			<div className=" wire-frame visual-design">
			<div className="brainstorming border-right">
			<h3 className="wow slideInLeft">Recommend a Car</h3>
			<div className="challenges m-0">	
				<p>
					The Recommend a Car feature would provide a cue to the
					user to initiate a task. This helped users understand where to
					begin through a useful prompt rather than spending a lot of
					time exploring the app to get a head start. This feature thus
					acted as a good starting point for users to navigate the app.
			</p>
				
			</div>
			<div className="row">
				<div className="col-lg-12 col-md-12 col-sm-12 col-12">
			<div className="wire-frame-images">
				<img src="/assets/img/portfolio-img/gaadi-bazaar/recommendcar.png" className=" recommendcar wow  fadeInLeft" alt="wire-frame"/>
			</div>
			</div>
			</div>
			</div>
			</div>

			<div className=" wire-frame visual-design">
		<div className="brainstorming border-left">
		<h3 className="wow slideInRight">Compare Cars</h3>
		<div className="challenges m-0">	
			<p>
			The Compare Cars feature would enable users to make an
			informed choice in their decision-making process. For instance,
			a user looking to buy a used vehicle with specific parameters in
			his search will directly be led to the right results without having
			to wade his way through scores of irrelevant data.
		 </p>
		</div>
		<div className="row">
		    <div className="col-lg-12 col-md-12 col-sm-12 col-12">
		<div className="wire-frame-images">
			<img src="/assets/img/portfolio-img/gaadi-bazaar/comparecars.png" className="comparecar wow fadeInRight" alt="wire-frame"/>
		</div>
	</div>
	</div>
		</div>
		
		</div>
		</div>
		
		</div>

	
		</div>
		</div>

		<div className=" wire-frame features">
		<div className="ix-gd-container">
		<div className="brainstorming">
		<h3 className="wow pulse">Added Features</h3>
		<div className="challenges">	
		<h6>With integrated insurance functions on the app, we helped Gaadi Bazaar users save time</h6>
			<p>
				We made up for the absence of a streamlined funding and insurance functionalities at a single touchpoint by integrating the Insurance feature in to the Gaadi Bazaar app.  </p>
				<p>
					This helped users and business owners to save time and have an uninterrupted and seamless user experience while carrying out their vehicle trading operations.
				</p>
			
		</div>
		<div className="row fdt">
		    <div className="col-lg-4 col-md-4 col-sm-4 col-4">
		<div className="wire-frame-images">
		<h6>EMI Calculator</h6>
			<img src="/assets/img/portfolio-img/gaadi-bazaar/gd-emi1.png" className="wow fadeInLeft" alt="wire-frame"/>
			<img src="/assets/img/portfolio-img/gaadi-bazaar/gd-emi1-1.png" alt="gd-emi1-1" className="gd-emi1-1 wow fadeInRight"/>
		</div>
		</div><div className="col-lg-4 col-md-4 col-sm-4 col-4">
		<div className="wire-frame-images">
			<h6>Bidding</h6>
			<img src="/assets/img/portfolio-img/gaadi-bazaar/gd-emi3.png" className="wow fadeInDown" alt="wire-frame"/>
			<img src="/assets/img/portfolio-img/gaadi-bazaar/gd-emi2-2.png" alt="gd-emi2-2" className="gd-emi2-2 wow fadeInUp"/>
		</div>
		</div><div className="col-lg-4 col-md-4 col-sm-4 col-4">
		<div className="wire-frame-images">
			<h6>Insurance</h6>
			<img src="/assets/img/portfolio-img/gaadi-bazaar/gd-emi2.png" className="wow fadeInRight" alt="wire-frame"/>
			<img src="/assets/img/portfolio-img/gaadi-bazaar/gd-emi3-3.png" alt="gd-emi3-3" className="gd-emi3-3 wow fadeInLeft"/>
		</div>
</div>
</div>
		</div>
		</div>
		</div>

{/* <div className="gd-footer">
<ul>

<li><img src="/assets/img/site-logo-sv.svg" className="logo-black"/> </li>
<li>
		<a href="#" className="playstore-app">
			<img src="/assets/img/portfolio-img/gaadi-bazaar/gd-google-trace.png" alt="google-trace"/>
		</a>
</li>
</ul>
</div> */}

     
</div> 
			</>
	    );
    }

}

export default GaadiBazar;
