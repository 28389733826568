import React from 'react';
import {Link} from "react-router-dom";
import { ROUTES } from '../../../../routes';
import Helmet from 'react-helmet';
import ReactSEOMetaTags from "react-seo-meta-tags";
import { Grid } from '@material-ui/core';
class CaseStudyCryptoSapce extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            downloadLink: '/assets/case-studies/Cryptospace.pdf'
        }
    }

    

    render() {
        return (
            <>
                       <Helmet>
                <meta charSet="utf-8" />
                <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1.0, shrink-to-fit=no, user-scalable=no, maximum-scale=1"
                />
                <title>Digital Transformation for Post-Trade Operations</title>
                <meta name="description" content="Ionixx designed a Regulatory Change Management system for a RegTech provider with technology-enabled framework that automates the entire regulatory change management lifecycle that further help companies stay ahead of changing regulations and mitigate the many risks of non-compliance." />
                <link rel="canonical" href="https://www.ionixxtech.com/case-studies/post-trade-operations" />
                <meta
                    property="og:image"
                    content={
                    "https://www.ionixxtech.com/assets/img/SEO_OG_IMAGE/case-study-post-trade.png"
                    }
                />

            </Helmet>
            <ReactSEOMetaTags
          render={(el) => <Helmet>{el}</Helmet>}
          website={{
            name: "Ionixx Technologies",
            title:
              "Digital Transformation for Post-Trade Operations  | Ionixx Technologies",
            description:
              "Ionixx designed a Regulatory Change Management system for a RegTech provider with technology-enabled framework that automates the entire regulatory change management lifecycle that further help companies stay ahead of changing regulations and mitigate the many risks of non-compliance.",
            type: "website",
            image: "https://www.ionixxtech.com/assets/img/SEO_OG_IMAGE/case-study-post-trade.png",
            url:
              "https://www.ionixxtech.com/case-studies/post-trade-operations",

          }}
          twitter={{
            twitter:
              "https://www.ionixxtech.com/case-studies/post-trade-operations",
          }}
        />
          <div className="section-2 beige-bg grey-bg">
      <div className="top-left-image">
        <img src="/assets/img/cypto-case-study/case-study-design.svg" alt="Top Left Image" />
      </div>
      <div className='content-padding'>
      <div className="trading-container">
  <div className="trading-grid">
    <div className="trading-content">
      <div className="header">
        <h1>Crypto Trading <br/>Made Simple</h1>
      </div>
      <div className='horizontal-line'>
      <hr />
      </div>
      <div className="crypto-content">
        <p>How Cryptospace Grew Their User Base<br/> with an Intuitive Trading Platform</p>
      </div>
    </div>
    <div className="right-content">
      <img src="/assets/img/cypto-case-study/crypto-trading.svg" alt="Right Side Image"/>
    </div>
  </div>
</div>
<div className='background'>
<div className = "background-header">
<h1>
  Background
</h1>
</div>
<div className='background-horizontal-line'>
      <hr />
      </div>
      <div className='background-content'>
        <div style={{paddingBottom : "20px"}}>
        <p>
        Many new traders are intimidated by
         complex verification steps, confusing crypto 
         terminology, and wallet <br/>integration hassles,
          leading to high abandonment rates. Cryptospace
           aimed to revolutionize this experience,<br/>
         <span style={{fontWeight : 700}}>
         making crypto trading user-friendly and approachable.
          </span>
        </p>
        </div>
        <div style={{paddingBottom : "20px"}}>
        <p>
        To tackle these challenges,
         Cryptospace partnered with 
         Ionixx Technologies and built an 
         OTC trading desk (integrated with BlockFills) for trading
          and order settlement.
        </p>
        </div>
        <div style={{paddingBottom : "20px"}}>
        <p>
        This collaboration focused on streamlining the platform
         to <span style={{fontWeight : 700}}>
         enhance accessibility
          </span > and ensure <span style={{fontWeight : 700}}>
          a smoother <br/>onboarding 
          experience for new users,
            </span> positioning
         Cryptospace as the go-to platform for novice traders.
          </p> 
        </div>
              </div>
              <div className='background-listContent'>
                <div>
                  <img
                  src="\assets\img\cypto-case-study\circle-arrow.svg"
                   alt="Right Side Image"
                  />
                </div>
                <div>
                  <p style={{paddingBottom : "20px"}}>
                  We built an intuitive, user-centric interface to 
                  minimize the steep learning <br/>
                  curve and simplify the crypto trading experience.
                  </p>
                </div>
              </div>
              <div className='background-listContent'>
                <div>
                  <img
                  src="\assets\img\cypto-case-study\circle-arrow.svg"
                   alt="Right Side Image"
                  />
                </div>
                <div>
                  <p>
                  The platform's <span style={{fontWeight : 700}}>
                    Microservices architecture</span> 
                    and Kubernetes clusters <br/>
                      ensured scalability and stability, 
                      <span style={{fontWeight : 700}} >
                      accommodating a growing user base<br/> with ease.
                      </span>   
                  </p>
                </div>
              </div>
              <div className='experience-box'>
                <div>
                  <img
                  src='\assets\img\cypto-case-study\upper-cotes.svg'
                    alt="upper-cotes-image"
                  />
                </div>
                <div className='experience-content'>
                  <p>
                  Our primary goal was to simplify the <span style={{color: "#FC9E1E"}}>
                  crypto 
                  trading experience,<br/>  
                    </span> 
                  ensuring even newcomers could quickly adapt and 
                  thrive in a <br/>fast-evolving market.
                  </p>
                </div>
              </div>
              </div>      
              </div>
              <div className='right-side-img'>
                <img
                src='\assets\img\cypto-case-study\right-side-desing.svg'
                alt = "right-side-image"/>
              </div>
              <div className='content-padding'>
                <div className='keyArea-heading'>
                  <h1>
                    Key Areas of Impact
                  </h1>
                  <div className='horizontal-line'>
                     <hr />
                     </div>
                </div>
                <div>
                </div>
              </div>
              <div className="percentage-box">
  <div className="percentage-item">
    <h2>40%</h2>
    <p>Reduction in user drop-offs</p>
  </div>
  <div className='vertical-line'>
    </div>
  <div className="percentage-item">
    <h2>30%</h2>
    <p>Increase in new sign-ups</p>
  </div>
  <div className='vertical-line'>
    </div>
  <div className="percentage-item">
    <h2>2X</h2>
    <p>Speed in transaction handling</p>
  </div>
</div>

<div className="key-container">
      <div className="key-features">
        <div className="features">
          <h2>Key Features</h2>
          <div className='key-horizontal-line'/>

          <ul>
            <li class="custom-li">Fiat-Crypto and Crypto-Fiat<br/>Transactions</li>
            <li class="custom-li">Automated KYC</li>
            <li class="custom-li">Integrated Wallet Management</li>
          </ul>
        </div>
        <div className="tech-stack">
          <h2>Tech Stack</h2>
          <div className='key-horizontal-line'/>
          <ul>
            <li class="custom-li">Backend: Python (Django)</li>
            <li class="custom-li">Architecture: Microservices</li>
            <li class="custom-li">Frontend: ReactJS</li>
            <li class="custom-li">Deployment & Monitoring: Kubernetes</li>
          </ul>
        </div>
      </div>
    </div>
    <div className='content-padding'>
      <div className='step-progress-header'>
        <h1>
        Optimizing Onboarding with <br/>
        a Simple Three-Step Process
        </h1>
      </div>
      <div className='progress-horizontal-line'/>
      <div className='key-progress-content'>
      <p>
      We focused on creating a smooth and frictionless onboarding experience 
      for new traders. We mapped out<br/> user journeys for different 
      trader profiles (both individual & institutional), creating 
      custom interactions that<br/> allowed both user groups to perform
       trading in <span style={{fontWeight : 700}}>
       three simple steps: 
        </span>
      </p>
      <div className='key-list-container'>
      <div className='key-listContent'>
                <div>
                  <img
                  src="\assets\img\cypto-case-study\circle-arrow.svg"
                   alt="Right Side Image"
                  />
                </div>
                <div className='list-content'>
                  <p>
                  Basic Account Setup
                  </p>
                </div>
              </div>
              <div className='key-listContent'>
                <div>
                  <img
                  src="\assets\img\cypto-case-study\circle-arrow.svg"
                   alt="Right Side Image"
                  />
                </div>
                <div className='list-content'>
                  <p>
                  Custom KYC Processing and Document Upload

                  </p>
                </div>
              </div>
              <div className='key-listContent'>
                <div>
                  <img
                  src="\assets\img\cypto-case-study\circle-arrow.svg"
                   alt="Right Side Image"
                  />
                </div>
                <div className='list-content'>
                  <p>
                  Integration with E-Wallets </p>
                </div>
              </div>
              </div>
    </div>
    </div>
    <div className="App">
      <div className="steps-container">
        <div className="parent">
          <div className="step">
      <div className="circle">{"Account Setup"}</div>
    </div>
          <img src='\assets\img\cypto-case-study\set13.svg' alt="Step 1" className="step-image img1" />
          <img src="\assets\img\cypto-case-study\img2.svg" alt="Step 1" className="step-image img2" />
          <div className="step-number">Step 1</div>
        </div>
        <div className="parent">
          <img src="\assets\img\cypto-case-study\img4.svg" alt = "Step 1" className="step-image img4" />
          <img src="\assets\img\cypto-case-study\set13.svg" alt="Step 1" className="step-image img1 rotate d-none-web" />
          <img src="\assets\img\cypto-case-study\img2.svg" alt="Step 1" className="step-image img2" />
          <div className="step-number step-number2">Step 2</div>
         
          <div className="step">
      <div className="circle">{"KYC Processing"}</div>
    </div>
        </div>
        <div className="parent">
          <img src = "\assets\img\cypto-case-study\set13.svg" alt="Step 1" className="step-image img1 rotate mobile d-none-web" />
       
          <div className="step">
      <div className="circle">{"E-Wallet Integration"}</div>
    </div>
          <div className="step-number">Step 3</div>
        </div>
      </div>
    </div>
    <div className='overall-grid-padding'>
  <div className='account-container'>
    <div className='account-grid'>
      <div className='account-grid-left-content'>
        <div className='account-heading'>
          <h1>Basic Account Setup</h1>
        </div>
        <div className='account-horizontal-line'></div>
        <div className='account-left-content'>
          <p>
            <span className='account-span-text'>Guided Sign-Up:</span> New users are welcomed with a
            friendly, easy-to-follow sign-up page. Instead of overwhelming users with a long form, we broke it
            down into bite-sized steps. Each step had clear instructions, such as "Enter your email address" and
            "Create a password."
          </p>
          <div style={{ paddingTop: "30px" }}>
            <p>
              <span className='account-span-text'>Tooltips and Help Icons:</span> We included tooltips and help
              icons next to fields that might be confusing, such as explaining the strength of a password.
            </p>
          </div>
        </div>
      </div>
      <div className='account-image'>
        <img src='/assets/img/cypto-case-study/account-img.svg' alt="account-setup-image" />
      </div>
    </div>
  </div>
</div>
<div className='kyc-overall-padding'>
<div className='account-container'>
    <div className='account-grid'>
      <div className='account-image'>
        <img src='/assets/img/cypto-case-study/kyc-img.svg' alt="account-setup-image" />
      </div>
      <div className='account-grid-left-content'>
        <div className='account-heading'>
          <h1>Custom KYC Processing<br/>and Document Upload</h1>
        </div>
        <div className='account-horizontal-line'></div>
        <div className='account-left-content'>
          <p>
            <span className='account-span-text'>Streamlined KYC Steps:</span>
           {" "} The KYC process was streamlined into simple, easy-to-understand steps.
            Users were prompted to provide the necessary information in small, manageable chunks, such as
            "Upload a photo ID" and "Take a selfie for verification."
          </p>
          <div style={{ paddingTop: "30px" }}>
            <p>
              <span className='account-span-text'>Visual Aids:</span>
              {" "}Visual aids and progress bars showed users how far they had progressed and what steps were remaining,
              reducing anxiety about the process.
            </p>
          </div>
          <div style={{ paddingTop: "30px" }}>
            <p>
              <span className='account-span-text'>Instant Feedback:</span>
              {" "}Users received instant feedback on their submissions, such as "Photo ID uploaded successfully" or
              "Selfie verified," ensuring they knew their progress in real time.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

    <div className='wallets-padding'>
    <div className='account-container'>
    <div className='account-grid'>
      <div className='account-grid-left-content'>
        <div className='account-heading'>
          <h1>Integration with E-Wallets</h1>
        </div>
        <div className='account-horizontal-line'></div>
        <div className='account-left-content'>
          <p>
            <span className='account-span-text'>Bank Account and Card Integration:</span>
            {" "}Users could easily integrate their bank 
            accounts or credit/debit cards with the platform. 
            The integration process was made as simple as linking 
            a bank account to an online shopping platform, with straightforward prompts 
            like "Enter your bank details" and "Link your card."

          </p>
          <div style={{ paddingTop: "30px" }}>
            <p>
              <span className='account-span-text'>Secure and User-Friendly:</span>
              {" "}Security was emphasized without complicating the process.
               Users were reassured with messages like 
              "Your bank details are securely encrypted."
            </p>
          </div>
        </div>
      </div>
      <div className='account-image'>
        <img src='\assets\img\cypto-case-study\integration-img3.svg' alt="account-setup-image" />
      </div>
    </div>
  </div>
    </div>
  <div className='three-step-process'>
    <div className='step-box'>
      <div className='step-content'>
        <p>
        This three-step process <span style={{fontWeight : 700}}>
        minimized information overload and <br/>
        provided a clear, guided path for new users,
          </span> significantly enhancing<br/> 
          user retention and satisfaction. 
        </p>
      </div>
    </div>
    <div className='user-interface-content'>
      <h1 className='interface-header'>
      Simplifying the User Interface for<br/>
      Easy Navigation
      </h1>
      <div className='interface-horizontal-line'/>
    </div>
    <div className='interface-content'>
      <p>
      We focused on enhancing user experience through unique platform
       features to cater to first-time user needs.<br/>
We helped reduce the learning curve by building <span style={{fontWeight : 700}}>distinct 
UX workflows for User Onboarding, Profile Setup,
<br/> and Trade Order Management.<br/>
  </span> 
Overall, we created a simple, unobtrusive interface that 
allowed users to navigate easily and stay engaged.
      </p>
    </div>
  </div>
  <div className="wallets-padding">
  <div className="information-container">
    <div className="information-grid">
      <div className="information-left-content">
        <p>
          <span className="information-span-text">Upfront Information:</span>
          {" "}Information like live pricing charts, summarized values, and commission details was displayed upfront, 
          <span style={{ fontWeight: 700 }}>so users didn’t have to dig through menus to find what they needed.</span>
        </p>
        <div style={{ paddingTop: "30px" }}>
          <p>
            <span className="information-span-text">Visual Aids:</span>
            {" "}The platform was designed with a minimalist approach, ensuring users could easily navigate different sections without getting lost or overwhelmed.
          </p>
        </div>
        <div style={{ paddingTop: "30px" }}>
          <p>
            Visually, we aligned the UI with Cryptospace’s brand colors, using bright orange for important Calls to <br/>Action.
          </p>
        </div>
      </div>
      <div className="information-image">
        <img src="\assets\img\cypto-case-study\information-img.svg" 
        alt="information-image" />
      </div>
    </div>
  </div>
</div>
<div className='more-users-padding'>
<div>
  <h1 className='more-users-heading'>
  Ensuring Platform Scalability to <br/>
Accommodate More Users
  </h1>
</div>
<div className='more-users-horizontal-line'/>
<div className='background-listContent'>
                <div>
                  <img
                  src="\assets\img\cypto-case-study\circle-arrow.svg"
                   alt="Right Side Image"
                  />
                </div>
                <div>
                  <p style={{paddingBottom : "20px"}}>
                  We built an intuitive, user-centric interface to 
                  minimize the steep learning <br/>
                  curve and simplify the crypto trading experience.
                  </p>
                </div>
              </div><div className='background-listContent'>
                <div>
                  <img
                  src="\assets\img\cypto-case-study\circle-arrow.svg"
                   alt="Right Side Image"
                  />
                </div>
                <div>
                  <p style={{paddingBottom : "20px"}}>
                  We built an intuitive, user-centric interface to 
                  minimize the steep learning <br/>
                  curve and simplify the crypto trading experience.
                  </p>
                </div>
              </div><div className='background-listContent'>
                <div>
                  <img
                  src="\assets\img\cypto-case-study\circle-arrow.svg"
                   alt="Right Side Image"
                  />
                </div>
                <div>
                  <p style={{paddingBottom : "20px"}}>
                  We built an intuitive, user-centric interface to 
                  minimize the steep learning <br/>
                  curve and simplify the crypto trading experience.
                  </p>
                </div>
              </div><div className='background-listContent'>
                <div>
                  <img
                  src="\assets\img\cypto-case-study\circle-arrow.svg"
                   alt="Right Side Image"
                  />
                </div>
                <div>
                  <p style={{paddingBottom : "20px"}}>
                  We built an intuitive, user-centric interface to 
                  minimize the steep learning <br/>
                  curve and simplify the crypto trading experience.
                  </p>
                </div>
              </div>  
              <div className='more-users-bottom-content'>
                <p>
                Overall, we helped build a <span style={{fontWeight : 700}}>
                high-performing,
                user-centric platform
                  </span> for Cryptospace, attracting more users<br/> and solidifying their market position as a reliable
                 and competitive player in the crypto trading industry.
                </p>
              </div>
</div>
<div className='about-content'>
<div>
  <h1 className= "about-header">
  About Ionixx Technologies
  </h1>
</div>
<div className='about-horizontal-line'/>
<div className='about-bottom-content'>
<p>
By focusing on user-centric, scalable solutions,
 we help FinTech companies achieve operational excellence<br/> 
 and set new industry standards. Specializing in FinTech, Crypto, and Web3 solutions, 
 we bring deep domain<br/> expertise to every project. Since 2017, when blockchain was still an emerging technology, 
we've been at the <br/>forefront of this revolution. 
</p>
</div>
</div>
<div className='service-box'>
  <div className='service-content'>
  <div >
<p>
More than just a service provider, 
we are your strategic ally in navigating
 the complexities of the Crypto and <br/>Web3 landscape.
</p>
<p>
  To get in touch, please email us at{" "}
  <span className='link-text'>
    <a href="mailto:info@ionixxtech.com" style={{ color: 'white' }} >info@ionixxtech.com</a>
  </span> or fill out the <span className='link-text'>
    <Link
    to={ROUTES.CONTACTUS}
    style = {{color : 'white'}} 
    >
    Contact Us
    </Link>
          
  </span> form.
</p>
</div>
  </div>

</div>


</div>
   </>
        );
    }

}

export default CaseStudyCryptoSapce;
