import React from 'react';
import "../../resources.css";
import {ResourceRoute} from '../../resource-routing';
import {Link} from "react-router-dom";
import NewsHeadings from "./common-action/news-heading";
import NewsSubscription from "./common-action/subscripbe-section";

class NewsIssue35 extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            pageEnabled: false,
        }

    }

    render() {
        return (
          <div className="newdesigned_issue_details">
            <NewsHeadings
              buttondDetail="Web3 & Blockchain"
              categoryType="web3"
              description="The hidden trackers in your phone, explained | Vox"
            />
            <div className="newsissuedetailed">
              <div className="detailed-common">
                {/* <div className="back-to-common">
                    <span className="back-to-icon-hold">
                    <img src="/assets/img/resource/back-to-new.svg"  alt="bact to new"></img>
                    </span>
                    <Link  to={ResourceRoute.NEWSLETTER}><span className="back-to-link">Newsletter</span></Link>
                </div> */}

                <div className="news-letter whats-on-hold">
                  <div className="issue-main-head">
                    <h4>What’s on?</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.forbes.com/sites/lukefitzpatrick/2020/07/06/fantoms-blockchain-tech-is-being-trialed-in-afghanistan-to-solve-a-surprising-world-health-organization-who-problem/#456dc3413844"
                      >
                        <h4>
                          Fantom’s Blockchain Tech Is Being Trialed To Solve ‘A
                          Surprising’ World Health Organization (WHO) Problem |
                          Forbes
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        According to the World Health Organization (WHO), 1 in
                        10 medical products “is substandard or falsified” in
                        developing nations. In Afghanistan, the numbers are
                        staggeringly higher and Afghanistan’s Ministry of Health
                        is looking to correct this predicament. In a bid to try
                        and rectify these issues, Afghanistan’s Ministry of
                        Health is set to commence trialing a blockchain-based
                        smart medicine pilot program on Fantom’s Opera mainnet.
                      </p>
                    </div>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://economictimes.indiatimes.com/tech/ites/tech-mahindra-to-launch-blockchain-based-platform-for-media-entertainment/articleshow/76852147.cms"
                      >
                        <h4>
                          Tech Mahindra to launch blockchain based platform for
                          media & entertainment | EconomicTimes
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Tech Mahindra a provider of digital transformation,
                        consulting and business reengineering services and
                        solutions on Wednesday announced the launch of a new
                        digital platform, branded as 'Blockchain based Contracts
                        and Rights Management System' (bCRMS) for the global
                        media and entertainment industry
                      </p>
                    </div>
                  </div>
                </div>
                <div className="news-letter top-picks-hold">
                  <div className="issue-main-head">
                    <h4>Top Picks</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.vox.com/recode/2020/7/8/21311533/sdks-tracking-data-location"
                      >
                        <h4>
                          The hidden trackers in your phone, explained | Vox{" "}
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        You probably know that the apps on your phone report
                        back tons of data about you to advertisers. Even
                        developers may not know (or care) when and how their
                        users’ privacy is being invaded.Companies want to put
                        their SDKs in as many apps as possible in order to
                        collect as much information from as many people as
                        possible.This article takes a deep dive into why apps
                        include so many software development kits, or SDKS, that
                        hoover up your personal info.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="news-letter other-reads-hold">
                  <div className="issue-main-head">
                    <h4>Other Reads</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.wired.co.uk/article/memory-palace-technique-explained"
                      >
                        <h4>
                          How to hack your brain to remember almost anything |
                          Wired
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Many people complain about having a terrible memory.
                        Shopping lists, friends’ birthdays, statistics for an
                        exam – they just don’t seem to stick in the brain. But
                        memory isn’t as set in stone as you might imagine. With
                        the right technique, you may well be able to remember
                        almost anything at all. Nelson Dellis is a four-time USA
                        Memory Champion and Grandmaster of Memory, says that
                        anyone can improve their memory by following these 5
                        steps.{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <NewsSubscription categoryType="Web3" />
          </div>
        );
    }

}

export default NewsIssue35;
