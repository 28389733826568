import COMMON_ACTION from "../action/actionTypes/commonActionType";
import initialState from "./common/initialState";

export default function commonReducer(state = initialState.commonReducer, action) {
    switch(action.type){
        case COMMON_ACTION.PAGE_SENDER:
            return {
                ...state,
                pageNumber: action.payload,
            };
        case COMMON_ACTION.TOAST:
            return {
                ...state,
                subscribeToast: action.payload
        };
        default:
            return state;
    }
}
