import React from "react";
import "../../resources.css";
import { ResourceRoute } from "../../resource-routing";
import { Link } from "react-router-dom";
import NewsHeadings from "./common-action/news-heading";
import NewsSubscription from "./common-action/subscripbe-section";

class NewsIssue73 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pageEnabled: false,
    };
  }

  render() {
    return (
      <div className="newdesigned_issue_details">
        <NewsHeadings
          buttondDetail="Web3 & Blockchain"
          categoryType="web3"
          description="Is Flutter Future-ready for Enterprise Apps? | AppInvetiv.com"
        />

        <div className="newsissuedetailed">
          <div className="detailed-common">
            {/* <div className="back-to-common">
                    <span className="back-to-icon-hold">
                        <img src="/assets/img/resource/back-to-new.svg"  alt="back to new"></img>
                    </span>
                    <Link  to={ResourceRoute.NEWSLETTER}><span className="back-to-link">Newsletter</span></Link>
                </div> */}

            <div className="news-letter whats-on-hold">
              <div className="issue-main-head">
                <h4>What’s on?</h4>
              </div>
              <div className="issue-main-info">
                <div className="issue-sub-head">
                  <a
                    target={"_blank"}
                    href="https://finance.yahoo.com/news/autodesk-acquire-upchain-accelerate-product-130000321.html"
                  >
                    <h4>
                      Autodesk to Acquire Upchain to Accelerate Product
                      Development Data and Processes in the Cloud | Yahoo
                      Finance
                    </h4>
                  </a>
                </div>
                <div className="issue-news-info">
                  <p>
                    Autodesk, Inc. today announced it has signed a definitive
                    agreement to acquire Upchain, a provider of instant-on,
                    cloud-based product lifecycle management (PLM) and product
                    data management (PDM) solutions. The acquisition positions
                    Autodesk to deliver more value for engineers, manufacturers,
                    suppliers and other product stakeholders by increasing
                    collaboration across the decentralized product value chain,
                    regardless of CAD system.
                  </p>
                </div>
              </div>
              <div className="issue-main-info">
                <div className="issue-sub-head">
                  <a
                    target={"_blank"}
                    href="https://www.crowdfundinsider.com/2021/04/174249-blockchain-company-apollo-fintech-launches-crypto-investment-platform-on-stratus/"
                  >
                    <h4>
                      Blockchain Company Apollo FinTechLaunches Crypto
                      Investment Platform on Stratus | CrowdfundInsider.com
                    </h4>
                  </a>
                </div>
                <div className="issue-news-info">
                  <p>
                    Apollo Fintech, a Hong Kong-based blockchain company,
                    announced on Wednesday it has launched its flagship
                    cryptocurrency investment platform, through its Stratus
                    social media ecosystem. According to Apollo, the platform
                    offers users instant access to buy and sell cryptocurrency.
                  </p>
                </div>
              </div>
              <div className="issue-main-info">
                <div className="issue-sub-head">
                  <a
                    target={"_blank"}
                    href="https://www.finextra.com/pressarticle/87100/pwc-unveils-fintech-marketplace"
                  >
                    <h4>PwC unveils FinTechmarketplace | Finextra.com</h4>
                  </a>
                </div>
                <div className="issue-news-info">
                  <p>
                    Ten of the UK’s leading FinTechs have been brought together
                    in a groundbreaking initiative by PwC which showcases how
                    the future of banking will rely on collaboration and
                    innovation, with personalisation at its heart. Three years
                    in the making, the 10 businesses have contributed to the
                    firm’s ‘Tysl’ ecosystem which incorporates a number of
                    solutions including software, automation, artificial
                    intelligence, data management and analytics, among others.
                  </p>
                </div>
              </div>
            </div>
            <div className="news-letter top-picks-hold">
              <div className="issue-main-head">
                <h4>Top Picks</h4>
              </div>
              <div className="issue-main-info">
                <div className="issue-sub-head">
                  <a
                    target={"_blank"}
                    href="https://appinventiv.com/blog/future-ready-flutter-for-enterprise-apps/"
                  >
                    <h4>
                      {" "}
                      Is Flutter Future-ready for Enterprise Apps? |
                      AppInvetiv.com
                    </h4>
                  </a>
                </div>
                <div className="issue-news-info">
                  <p>
                    Flutter is king, or at least it will be when it comes to
                    enterprise application development. This is the overwhelming
                    anthem of voices rising from the helm of the mobile app
                    development industry. As per Google, 500,000 developers use
                    its software development kit monthly. Most of the time the
                    question comes up about what to choose Flutter or React
                    native. With a rising popularity share, Flutter is rubbing
                    shoulders with its closest rival React Native, and will soon
                    overtake it courtesy of the renewed interest from Enterprise
                    mobile app developers.{" "}
                  </p>
                </div>
              </div>
            </div>
            <div className="news-letter other-reads-hold">
              <div className="issue-main-head">
                <h4>Other Reads</h4>
              </div>
              <div className="issue-main-info">
                <div className="issue-sub-head">
                  <a
                    target={"_blank"}
                    href="https://www.wsj.com/articles/microsofts-nuance-gambit-shows-healthcare-is-next-tech-battleground-11618314585"
                  >
                    <h4>
                      Microsoft’s Nuance Gambit Shows Healthcare Is Shaping Up
                      as Next Tech Battleground| Wall Street Journal{" "}
                    </h4>
                  </a>
                </div>
                <div className="issue-news-info">
                  <p>
                    Microsoft Corp.’s $16 billion deal for Nuance Communications
                    Inc., is the latest sign that the next battleground for
                    technology giants will be in healthcare, an industry whose
                    need to embrace data and software was underscored by the
                    pandemic. The acquisition will help Microsoft tap into
                    Nuance’s big business selling its software to healthcare
                    systems, according to analysts and healthcare executives.
                    Speech-recognition software like that developed by Nuance is
                    emerging as an important new opportunity in medicine as
                    doctors seek to speed up documentation of patient work with
                    dictation rather than getting bogged down taking notes,
                    executives said.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <NewsSubscription categoryType="Web3" />
      </div>
    );
  }
}

export default NewsIssue73;
