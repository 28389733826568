import React from 'react';
import "../../resources.css";
import {ResourceRoute} from '../../resource-routing';
import {Link} from "react-router-dom";
import NewsHeadings from "./common-action/news-heading";
import NewsSubscription from "./common-action/subscripbe-section";

class NewsIssue27 extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            pageEnabled: false,
        }

    }

    render() {
        return (
          <div className="newdesigned_issue_details">
            <NewsHeadings
              buttondDetail="Design"
              categoryType="design"
              description="A new normal for design | Abstract.com"
            />
            <div className="newsissuedetailed">
              <div className="detailed-common">
                {/* <div className="back-to-common">
                    <span className="back-to-icon-hold">
                    <img src="/assets/img/resource/back-to-new.svg"  alt="bact to new"></img>
                    </span>
                    <Link  to={ResourceRoute.NEWSLETTER}><span className="back-to-link">Newsletter</span></Link>
                </div> */}

                <div className="news-letter whats-on-hold">
                  <div className="issue-main-head">
                    <h4>What’s on?</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.forbes.com/sites/ronshevlin/2020/05/11/google-the-next-big-fintech-vendor/#62af51fe4cbd"
                      >
                        <h4>Google: The Next Big FinTechVendor | Forbes</h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        In an article titled Amazon’s Impending Invasion Of
                        Banking, it poised to be a vendor and not a competitor
                        to financial institutions. However, Google’s banking
                        forays signal that it, too, is following a similar path
                        and is on its way to becoming the next big fintech
                        vendor.
                      </p>
                    </div>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.technologyreview.com/2020/05/14/1001443/blockchains-next-frontier-shaping-the-business-model/"
                      >
                        <h4>
                          Blockchain’s next frontier: Shaping the business model
                          | MIT Technology Review
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        The story of blockchain market adoption closely
                        resembles the path taken by other disruptive
                        technologies: an initial industry explores what is
                        possible, others give form and substance to what is
                        plausible, and the marketplace helps define what is
                        practical. It’s no longer a question of whether the
                        technology will work—it does work. What’s at play now is
                        how each industry will tailor blockchain adoption to
                        meet its needs.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="news-letter top-picks-hold">
                  <div className="issue-main-head">
                    <h4>Top Picks</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.abstract.com/blog/overheard-may-2020"
                      >
                        <h4>A new normal for design | Abstract.com</h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        As remote design sprints, AMAs and webinars have become
                        the norm, we’re getting more under-the-hood peeks at
                        folks’ remote work setups, virtual brainstorms, design
                        systems, and more. While not everyone has the time or
                        energy to increase their outputs, there’s a realness to
                        the conversations happening around systems, processes,
                        career advancement and development, and why our default
                        understanding of collaboration is critically flawed in a
                        remote-first world.
                      </p>
                    </div>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://developer-tech.com/news/2020/may/13/attention-app-developers-android-is-no-longer-second-fiddle/"
                      >
                        <h4>
                          Attention app developers: Android is no longer second
                          fiddle | Developer - Tech.com
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        For a long time the app world was the iOS user’s oyster
                        – all of the best apps were designed for them. In fact,
                        within the US, smartphones and the apps market were
                        essentially synonymous with the iPhone and the App
                        Store. Throughout the rest of the world users preferred
                        Androids, and by 2015 they accounted for 80% of
                        smartphone shipments. It was only a matter of time
                        before this sleeping giant awakened here on our shores.
                        There are lots of reasons to assume that Android will no
                        longer play second fiddle to the iPhone. This has the
                        potential to shake things up in the entire mobile
                        market.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="news-letter other-reads-hold">
                  <div className="issue-main-head">
                    <h4>Other Reads</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.nytimes.com/2020/05/14/opinion/coronavirus-communication.html"
                      >
                        <h4>
                          How We Can Get the Next Phase of the Coronavirus Right
                          | New York Times{" "}
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Heading into our pandemic summer, biggest worry is that
                        in the effort to flatten the coronavirus curve, nobody
                        prepared the country for what comes next. In late
                        February “flatten the curve” became our collective
                        refrain — stay home, save lives. Charts showing how
                        social distancing can reduce the spread of the virus and
                        protect hospitals from overcrowding were simple to
                        interpret and they became a meme.{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <NewsSubscription categoryType="Design" />
          </div>
        );
    }

}

export default NewsIssue27;
