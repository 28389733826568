import React from 'react';
import { Switch, Route, BrowserRouter, StaticRouter} from "react-router-dom";
import {Provider} from "react-redux";
import configStore from "./store/configStore";
import {ROUTES} from './routes.js';
import Home from './pages/landing/landing.js';
import Resources from './pages/resources/resources.js'
import ScrollToTop from "./components/scroll-to-top/scroll-to-top";
import {ResourceRoute} from './pages/resources/resource-routing';
import landing from './pages/landing/landing.js';
// import CookieBot from 'react-cookiebot';
const store = configStore();
const PrivacyPolicy = "/documents/Ionixx-Privacy-Policy.pdf";

const AppRoutes = ({ store }) => (  
    <React.StrictMode>
        <ScrollToTop/>
        {/* <CookieBot domainGroupId={'3fb7312c-b052-4ec2-9048-94b7b24a9a9d'} /> */}
        <Switch>
            <Route exact path={ResourceRoute.BLOCKCHAINGUIDE} component={Home}/>
            <Route exact path={ResourceRoute.DATAAGGREGATION} component={Home}/>
            <Route exact path={ResourceRoute.MULTIEXCHANGECRYPTOCURRENCY} component={Home}/>
            <Route path={ROUTES.NEW_RESOURCES} component={landing}/>
            <Route path={ROUTES.RESOURCES} component={Resources}/>
            <Route path={"*"} component={Home} status={404}/>
            {/* <Redirect path='*' exact={true} component={errorcomponent} /> */}
        </Switch>
    </React.StrictMode>   
)

  
function App (props) {
    return (
      <Provider store={store}>
        {props.location ? (
          <StaticRouter location={props.location || "/"}>
            <AppRoutes />
          </StaticRouter>
        ) : (
          <BrowserRouter>
            <AppRoutes />
            {/* <CookieConsent 
              debug={true}
              style={{zIndex: "999", background:"#fff", 
              color: "#1E4B5E", fontWeight: "700", 
              filter: "drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.25))"}}
              enableDeclineButton={true}
              buttonText={"I Accept"}
              buttonStyle={{background:"#1E4B5E", color: "#FFCC4A", fontWeight:"700"}}
              declineButtonStyle={{fontWeight: "700", background:"transparent", border:"1px solid #007CC1", color:"#007CC1"}}
              expires={1}>
              This site uses Cookies. See our <a href={PrivacyPolicy} target='_blank'>Privacy policy</a> for more information.
            </CookieConsent> */}
          </BrowserRouter>
        )}
      </Provider>
    );
  }
  export default App;
