import React from 'react';
import "../../resources.css";
import { ResourceRoute } from '../../resource-routing';
import { Link } from "react-router-dom";
import NewsHeadings from "./common-action/news-heading";
import NewsSubscription from "./common-action/subscripbe-section";

class NewsIssue12 extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            pageEnabled: false,
        }

    }

    render() {
        return (
          <div className="newdesigned_issue_details">
            <NewsHeadings
              buttondDetail="Design"
              categoryType="design"
              description="A brief history of UX design and its evolution | The Next Web"
            />

            <div className="newsissuedetailed">
              <div className="detailed-common">
                {/* <div className="back-to-common">
                    <span className="back-to-icon-hold">
                    <img src="/assets/img/resource/back-to-new.svg"  alt="bact to new"></img>
                    </span>
                    <Link  to={ResourceRoute.NEWSLETTER}><span className="back-to-link">Newsletter</span></Link>
                </div> */}

                <div className="news-letter whats-on-hold">
                  <div className="issue-main-head">
                    <h4>What’s on?</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.technologyreview.com/2020/01/27/276077/why-private-micro-networks-could-be-the-future-of-how-we-connect/"
                      >
                        <h4>
                          Why private micro-networks could be the future of how
                          we connect | MIT Tech Review{" "}
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Why private micro-networks could be the future of how we
                        connect | MIT Tech Review{" "}
                      </p>
                    </div>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://finance.yahoo.com/news/more-700-blockchain-companies-launch-090011333.html"
                      >
                        <h4>
                          More than 700 new blockchain companies launch in China
                          in January alone | Yahoo Finance
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Recent data has revealed that the number of new
                        blockchain and crypto start-ups in China is still
                        growing at a decent pace despite fewer new companies
                        formed throughout 2019.The data, released by blockchain
                        research firm LongHash, has revealed that 714 new
                        distributed ledger technology companies have emerged
                        during January alone.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="news-letter top-picks-hold">
                  <div className="issue-main-head">
                    <h4>Top Picks</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://thenextweb.com/syndication/2020/01/26/a-brief-history-of-ux-design-and-its-evolution/"
                      >
                        <h4>
                          A brief history of UX design and its evolution | The
                          Next Web
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        When you think about user experience design it’s a term
                        we instantly associate with apps and websites. And
                        especially when considering a typical job description of
                        a UX designer, it can trick you into thinking that it’s
                        a modern concept. The term was first coined in 1993 by
                        the cognitive psychologist and designer Don Norman when
                        he worked at Apple Computer — but the UX field is older
                        than the term.
                      </p>
                    </div>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.theverge.com/tldr/2020/1/23/21078343/google-ad-desktop-design-change-favicon-icon-ftc-guidelines"
                      >
                        <h4>
                          Google’s ads just look like search results now | The
                          Verge{" "}
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Last week, Google began rolling out a new look for its
                        search results on desktop, which blurs the line between
                        organic search results and the ads that sit above them.
                        In what appears to be something of a purposeful dark
                        pattern, the only thing differentiating ads and search
                        results is a small black-and-white “Ad” icon next to the
                        former. It’s been formatted to resemble the new favicons
                        that now appear next to the search results you care
                        about. Early data collected by Digiday suggests that the
                        changes may already be causing people to click on more
                        ads.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="news-letter other-reads-hold">
                  <div className="issue-main-head">
                    <h4>Other Reads</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.weforum.org/agenda/2020/01/heres-what-i-learned-at-davos-2020/"
                      >
                        <h4>Here's what I learned at DAVOS 2020 | WEF.org </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Climate change and its financial impact dominated
                        conversations this year. Beneath the noise, I came away
                        with even higher conviction that climate risk analysis
                        of companies and portfolios is moving out of a
                        specialised niche and into the mainstream. The
                        Trumpification of global trade, the side effects of QE
                        Infinity and the continued rally in tech stocks were
                        also hotly debated. Europe hardly got a look in as
                        investors and policymakers were focused on more vibrant
                        growth and technology plays in the US and Asia
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <NewsSubscription categoryType="Design" />
          </div>
        );
    }

}

export default NewsIssue12;
