import React from 'react';
import "../../resources.css"
import {ResourceRoute} from '../../resource-routing';
import {Link} from "react-router-dom";

class FebruaryFortnight1 extends React.Component {
    constructor(props){
        super(props)

    }
    render(){
        return (
            <div className="BlockchainDetailed">
                                <div className="back-to-common">
                    <span className="back-to-icon-hold">
                        <img src="/assets/img/resource/back-to-new.svg"  alt="bact to new"></img>
                    </span>
                    <Link  to={ResourceRoute.BLOCKCHAIN}><span className="back-to-link">Blockchain Bulletin</span></Link>
                </div>
                <div className="download-common inner-section-1">
                    <div>
                    {/* <div className="col-xl-7 col-lg-7 col-md-7 col-sm-12"> */}
                        <div className="main-head-div">
                            <h4 className="main-head-1">
                                <span className="primary-span">February Fortnight 1 Round-up Blockchain & Crypto Brief</span>
                            </h4>
                            <p className="press-info">( February 11, 2020 )</p>
                        </div>
                    </div>    
                </div>
                <div class="bulletin-head-details bulletin-sub-heads">
                    <h4>
                        <span className="primary-span">Finance</span>
                    </h4>
                    <ul className="bulletin-links-ul">
                        <li>
                            <a href="https://www.bloomberg.com/news/articles/2020-01-29/swiss-ski-resort-zermatt-now-accepts-bitcoin-to-pay-taxes" target={'_blank'}>The Swiss municipality of Zermatt now accepts tax payments in bitcoin.</a>
                        </li>
                        <li>
                            <a href="https://news.bitcoin.com/zcash-to-distribute-20-of-mining-rewards/" target={'_blank'}>The Zcash community has voted to allocate 20% of the reward that comes from mining a new block towards further development of the blockchain system; the miners will get as of block 1,046,400.</a>
                        </li>
                        <li>
                            <a href="https://uk.reuters.com/article/uk-japan-economy-digital/japan-ruling-party-lawmakers-to-float-idea-of-issuing-digital-currency-idUKKBN1ZN0QK" target={'_blank'}>Japan ruling party lawmakers are working to propose the country to issue its own digital currency</a>
                        </li>
                        <li>
                            <a href="https://cointelegraph.com/news/asian-crypto-conferences-are-being-delayed-for-coronavirus-reasons" target={'_blank'}>Blockchain and crypto firms such as Binance and Krypital are helping Wuhan coronavirus victims by pledging to donate $1.44 million and launching a fundraising drive, respectively.</a>
                        </li>
                        <li>
                            <a href="https://www.bloomberg.com/news/articles/2020-01-31/want-to-own-a-ferrari-now-you-can-through-a-new-digital-token" target={'_blank'}>Investment platform CurioInvest and digital assets exchange MERJ have collaborated to offer tokenize rare and luxury cars, including Ferrari.</a>
                        </li>
                        <li>
                            <a href="https://uk.finance.yahoo.com/news/cambodia-central-bank-set-launch-095539191.html" target={'_blank'}>Cambodia’s central bank, the National Bank of Cambodia (NBC), is all set to launch a blockchain-based interbank payments platform, which has already received support from 11 banks.</a>
                        </li>
                    </ul>
                </div>
                <div class="bulletin-head-details bulletin-sub-heads">
                    <h4>
                        <span className="primary-span">Law</span>
                    </h4>
                    <ul className="bulletin-links-ul">
                        <li>
                            <a href="https://www.coindesk.com/singapore-announces-new-aml-rules-for-crypto-businesses" target={'_blank'}>The Monetary Authority of Singapore (MAS) has announced the enactment of the Payment Services Act, which gives the regulator formal supervisory authority over payments firms, including crypto. This means that all the crypto firms in the country will have to be registered and licensed.</a>
                        </li>
                    </ul>
                </div>
                <div class="bulletin-head-details bulletin-sub-heads">
                    <h4>
                        <span className="primary-span">Other</span>
                    </h4>
                    <ul className="bulletin-links-ul">
                        <li>
                            <a href="https://uk.finance.yahoo.com/news/eth-2-0-client-nimbus-140007544.html" target={'_blank'}>Ethereum infrastructure project Nimbus working on Ethereum 2.0 has received another $650,000 grant from the Ethereum Foundation.</a>
                        </li>
                        <li>
                            <a href="https://finance.yahoo.com/news/enterprise-blockchain-startup-settlemint-raises-102508492.html" target={'_blank'}>A Belgium-based enterprise blockchain startup SettleMint aims to expand globally by raising about $2.04 million in a new funding round.</a>
                        </li>
                        <li>
                            <a href="https://marketrebellion.com/publicly-traded-bitcoin-miner-hut-8-announces-pending-ceo-departure-reworked-deal-with-hardware-maker-bitfury/" target={'_blank'}>Hut 8, one of the few publicly-listed bitcoin mining companies, has announced the departure of its CEO Andrew Kiguel.</a>
                        </li>
                        <li>
                            <a href="https://www.theblockcrypto.com/linked/54182/saudi-aramco-energy-ventures-invests-5m-in-oil-blockchain-platform-vakt" target={'_blank'}>Saudi Aramco Energy Ventures have invested $5 million in oil blockchain platform Vakt and is now one of their 12 stakeholders.</a>
                        </li>
                        <li>
                            <a href="https://finance.yahoo.com/news/crypto-exchange-coinbase-hires-google-205436111.html" target={'_blank'}>Crypto exchange Coinbase has hired Google veteran Surojit Chatterjee as the chief product officer.</a>
                        </li>
                        <li>
                            <a href="https://www.theblockcrypto.com/post/54511/coinbase-custody-and-bison-trails-join-proof-of-stake-alliance-seeking-to-bring-tax-clarity" target={'_blank'}>Coinbase Custody and Bison Trails have joined the Proof of Stake Alliance to educate lawmakers and bring regulatory and tax clarity for PoS tokens.</a>
                        </li>
                        <li>
                            <a href="https://finance.yahoo.com/news/binance-hires-former-huobi-exec-095512003.html" target={'_blank'}>Binance has hired former Huobi executive Josh Goodbody for leading expansion in Europe and Latin America.</a>
                        </li>
                        <li>
                            <a href="https://uk.finance.yahoo.com/news/ripple-ipo-could-come-within-075548289.html" target={'_blank'}>Ripple’s CEO, Brad Garlinghouse, has indicated that the firm could go public in the next 12 months with its IPO.</a>
                        </li>
                        <li>
                            <a href="https://uk.finance.yahoo.com/news/samsung-salesforce-ventures-join-digital-194519027.html" target={'_blank'}>Samsung and Salesforce Ventures have joined Digital Asset’s Series C funding round as Microsoft veteran Susan Hauster joins as a member of the board of its directors</a>
                        </li>
                        <li>
                            <a href="https://uk.finance.yahoo.com/news/sony-financial-ventures-invests-six-155034513.html" target={'_blank'}>Sony Financial Ventures, a part of Sony Corporation, has invested a ‘six-figure- sum in token issuance tech firm Securitize.</a>
                        </li>
                        <li>
                            <a href="https://finance.yahoo.com/news/twitter-co-founder-backs-app-104533074.html" target={'_blank'}>Twitter co-founder, Christopher Isaac has invested in an app called Mode Banking that allows users to buy bitcoin. The app is available globally except in the U.S.</a>
                        </li>
                        <li>
                            <a href="https://www.globenewswire.com/news-release/2020/02/04/1979858/0/en/International-Money-Express-Inc-Announces-Partnership-with-Ripple.html" target={'_blank'}>Ripple has partnered with Nasdaq-listed remittance firm International Money Express (Intermex), which will utilize its On-Demand Liquidity solution leveraging XRP to settle cross-border payments.</a>
                        </li>
                        <li>
                            <a href="https://www.coindesk.com/ethereum-incubator-consensys-slashes-headcount-14-in-latest-strategic-shift" target={'_blank'}>Ethereum Incubator ConsenSys is downsizing and expected to reduce headcount by approximately 14%.</a>
                        </li>
                        <li>
                            <a href="https://uk.reuters.com/article/us-usa-fed-brainard/fedcoin-the-u-s-central-bank-is-looking-into-it-idUKKBN1ZZ2XF" target={'_blank'}>U.S. Federal Reserve governor Lael Brainard has said that the central bank is studying the potential for issuing its own digital currency.</a>
                        </li>
                        <li>
                            <a href="https://www.tokenpost.jp/article/view/105" target={'_blank'}>Crypto market maker Wintermute Trading has raised a huge seven-figure sum in its seed funding round, led by Blockchain.com ventures, with participation from FBG Capital and others.</a>
                        </li>
                        <li>
                            <a href="https://www.forbes.com/sites/michaeldelcastillo/2020/02/05/jack-dorsey-backed-lightning-labs-raises-10-million-to-build-visa-network-for-bitcoin/#5c5344d5edef" target={'_blank'}>Lightning Labs, backed by Twitter CEO and co-founder Jack Dorsey, has raised $10 million to build a visa network for bitcoin. The startup aims to expand its payments network that lets companies and individuals spend bitcoin as easily as traditional services.</a>
                        </li>

                    </ul>
                </div>
            </div>
            
        );
    }

}

export default FebruaryFortnight1;
