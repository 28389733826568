import React from 'react';
import "../../resources.css";
import {ResourceRoute} from '../../resource-routing';
import {Link} from "react-router-dom";

class JanuaryFortnight1 extends React.Component {
    constructor(props){
        super(props)

    }
    render(){
        return (
            <div className="BlockchainDetailed">
                                                <div className="back-to-common">
                    <span className="back-to-icon-hold">
                        <img src="/assets/img/resource/back-to-new.svg"  alt="bact to new"></img>
                    </span>
                    <Link  to={ResourceRoute.BLOCKCHAIN}><span className="back-to-link">Blockchain Bulletin</span></Link>
                </div>
                <div className="download-common inner-section-1">
                    <div>
                    {/* <div className="col-xl-7 col-lg-7 col-md-7 col-sm-12"> */}
                        <div className="main-head-div">
                            <h4 className="main-head-1">
                                <span className="primary-span">January Fortnight 1 Round-up Blockchain & Crypto Brief</span>
                            </h4>
                            <p className="press-info">( January 15, 2020 )</p>
                        </div>
                    </div>    
                </div>
                <div class="bulletin-head-details bulletin-sub-heads">
                    <h4>
                        <span className="primary-span">Finance</span>
                    </h4>
                    <ul className="bulletin-links-ul">
                        <li>
                            <a href="https://global.rakuten.com/corp/news/press/2019/1224_01.html?year=2019&month=12&category=corp%20ec%20fintech" target={'_blank'}>Japan’s Amazon equivalent Rakuten has launched a service to exchange loyalty points for crypto assets, currently supporting Bitcoin, Ether, and Bitcoin Cash.</a>
                        </li>
                        <li>
                            <a href="https://binance.zendesk.com/hc/en-us/articles/360037914692-Binance-Now-Supports-Purchasing-Crypto-by-Binding-Visa-Cards-to-Your-Account" target={'_blank'}>Cryptocurrency exchange Binance now allows you to buy crypto by directly adding Visa debit and credit cards to your account.</a>
                        </li>
                        <li>
                            <a href="https://www.theblockcrypto.com/daily/52625/bitcoindotcom-50-million-exchange-token-sale?" target={'_blank'}>Bitcoin.com seeks to raise $50 million in exchange token sale; the token won’t be available in some countries, including the United States and Japan.</a>
                        </li>
                        <li>
                            <a href="https://www.blackrock.com/corporate/investor-relations/larry-fink-ceo-letter" target={'_blank'}>The world’s largest asset manager BlackRock, which has nearly $7 trillion in client assets, has planned to exit investments that present high sustainability-related risk.</a>
                        </li>
                        <li>
                            <a href="https://tass.com/economy/1105453" target={'_blank'}>Venezuelan President Nicolas Maduro has said that the country will sell oil and part of its gold produces for its national cryptocurrency Petro.</a>
                        </li>
                    </ul>
                </div>
                <div class="bulletin-head-details bulletin-sub-heads">
                    <h4>
                        <span className="primary-span">Law</span>
                    </h4>
                    <ul className="bulletin-links-ul">
                        <li>
                            <a href="https://tass.com/economy/1105453" target={'_blank'}>A Virginian lawmaker has submitted a resolution that calls for a study on the use of blockchain in elections.</a>
                        </li>
                        <li>
                            <a href="https://www.theblockcrypto.com/linked/52389/former-bakkt-ceo-kelly-loeffler-to-help-oversee-cftc-raising-a-possible-conflict-of-interest?" target={'_blank'}>Former Bakkt CEO and a newly appointed U.S. Senator, Kelly Loeffler, has joined the committee overseeing the Commodity Futures Trading Commission (CFTC), likely to cause a conflict of interest.</a>
                        </li>
                        <li>
                            <a href="https://www.sec.gov/about/offices/ocie/national-examination-program-priorities-2020.pdf" target={'_blank'}>The U.S. Securities and Exchange Commission’s Office of Compliance Inspections and Examinations (OCIE) has outlined its priorities for 2020, including its views on the oversight needs posed by digital assets.</a>
                        </li>
                    </ul>
                </div>
                <div class="bulletin-head-details bulletin-sub-heads">
                    <h4>
                        <span className="primary-span">Other</span>
                    </h4>
                    <ul className="bulletin-links-ul">
                        <li>
                            <a href="https://www.coindesk.com/chinese-internet-giant-tencent-to-launch-digital-currency-research-team" target={'_blank'}>Chinese conglomerate Tencent with a market capitalization of over $460 billion is reported to be forming a team to research digital currencies.</a>
                        </li>
                        <li>
                            <a href="https://finance.yahoo.com/news/central-bank-bahamas-begin-piloting-185241492.html" target={'_blank'}>The Central Bank of The Bahamas has started piloting its digital currency tied to the Bahamian Dollar. The project, nicknamed Project Sand Dollar, begun in Exuma on December 27, 2019.</a>
                        </li>
                        <li>
                            <a href="https://cointelegraph.com/news/bitfinex-beefs-up-kyc-requirements-asks-users-for-additional-info" target={'_blank'}>Cryptocurrency exchange Bitfinex has tightened its KYC process, emailing users asking for additional information.</a>
                        </li>
                        <li>
                            <a href="https://finance.yahoo.com/news/huobi-launches-fiat-crypto-gateway-200604835.html" target={'_blank'}>Cryptocurrency exchange Huobi Global has launched a fiat-to-crypto gateway in Indonesia.</a>
                        </li>
                        <li>
                            <a href="https://coinrivet.com/facebooks-libra-is-a-failure-says-swiss-president/" target={'_blank'}>In an interview, Swiss president Ueli Maurer has stated that Facebook’s Libra doesn’t have a chance of being successful as it has failed in its current form.</a>
                        </li>
                    </ul>
                </div>
            </div>      
        );
    }

}

export default JanuaryFortnight1;
