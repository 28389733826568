import React from 'react';
import "../../resources.css";
import {ResourceRoute} from '../../resource-routing';
import {Link} from "react-router-dom";

class NovemberFortnight1 extends React.Component {
    constructor(props){
        super(props)

    }
    render(){
        return (
            <div className="BlockchainDetailed">
                                                <div className="back-to-common">
                    <span className="back-to-icon-hold">
                        <img src="/assets/img/resource/back-to-new.svg"  alt="bact to new"></img>
                    </span>
                    <Link  to={ResourceRoute.BLOCKCHAIN}><span className="back-to-link">Blockchain Bulletin</span></Link>
                </div>
                <div className="download-common inner-section-1">
                    <div>
                    {/* <div className="col-xl-7 col-lg-7 col-md-7 col-sm-12"> */}
                        <div className="main-head-div">
                            <h4 className="main-head-1">
                                <span className="primary-span">November Fortnight 1 Round-up Blockchain & Crypto Brief</span>
                            </h4>
                            <p className="press-info">( November 12, 2019 )</p>
                        </div>
                    </div>    
                </div>
                <div class="bulletin-head-details bulletin-sub-heads">
                    <h4>
                        <span className="primary-span">Finance</span>
                    </h4>
                    <ul className="bulletin-links-ul">
                        <li>
                            <a href="https://www.theblockcrypto.com/linked/45139/twitter-ceo-jack-dorsey-backs-10m-round-for-crypto-startup-coinlist" target={'_blank'}>T​oken sales platform Coin List is now backed by Twitter CEO Jack Dorsey with $10M funding.</a>
                        </li>
                        <li>
                            <a href="https://www.theblockcrypto.com/linked/45145/city-government-in-china-to-invest-1-4m-annually-in-public-blockchain-projects-without-token" target={'_blank'}>A local government agency in Guangzhou is planning to provide $1.4M subsidies to two blockchain projects per year.</a>
                        </li>
                        <li>
                            <a href="https://finance.yahoo.com/news/association-over-200-german-private-121517769.html" target={'_blank'}>The Association of German Banks, a group of more than 200 private commercial banks, has called for a programmable digital euro and a common pan-European payments platform.</a>
                        </li>
                        <li>
                            <a href="https://finance.yahoo.com/news/binance-adds-support-russian-rubles-085055430.html" target={'_blank'}>Binance has added support for Russian Rubles (RUB), allowing consumers a direct fiat-to-crypto trading facility.</a>
                        </li>
                        <li>
                            <a href="https://www.bloomberg.com/news/articles/2019-10-28/starbucks-crypto-consumer-app-targeted-for-first-half-of-2020" target={'_blank'}>You could soon be able to buy coffee with your cryptocurrency. Intercontinental Exchange Inc. to begin testing its consumer app for digital assets with partner Starbucks Inc. in the first half of 2020.</a>
                        </li>
                        <li>
                            <a href="https://cointelegraph.com/news/number-of-americans-owning-crypto-doubled-in-2019-finder" target={'_blank'}>The number of cryptocurrency-owning Americans has almost doubled in 2019 according to a new Finder survey.</a>
                        </li>
                        <li>
                            <a href="https://www.theblockcrypto.com/linked/48086/coinbase-custody-expands-tezos-staking-service-to-all-global-clients" target={'_blank'}>Cryptocurrency exchange Coinbase announced the offering of staking rewards for all U.S. customers, starting with Tezos. The customers holding Tezos will be eligible to collect rewards of approximately 5%.According to a press release by the US SEC, the organization has collected more than $4.3 billion in disgorgement and penalties in 2019.</a>
                        </li>
                        <li>
                            <a href="https://www.coindesk.com/china-central-bank-official-digital-yuan-should-have-controllable-anonymity" target={'_blank'}>Mu Changchun, the head of the digital currency research institute at the People’s Bank of China (PBoC) has revealed that the holders ofthe digital currency Yuan won’t be receiving interest payments</a>
                        </li>
                        <li>
                            <a href="https://news.yahoo.com/turkey-working-digital-currency-pilots-131202112.html" target={'_blank'}>Turkey is testing a Blockchain-based national digital currency, expected to be conducted and finalized by the end of 2020</a>
                        </li>
                        <li>
                            <a href="https://in.reuters.com/article/us-eu-cryptocurrency-regulations/alarmed-by-libra-eu-to-look-into-issuing-public-digital-currency-draft-idINKBN1XF1VC" target={'_blank'}>An EU draft document said that the European Central Bank should think about issuing a public digital currency, considering the threat by Facebook’s Libra.</a>
                        </li>
                        <li>
                            <a href="https://finance.yahoo.com/news/hong-kong-thailand-plan-release-132013232.html" target={'_blank'}>Hong Kong and Thailand plan to launch their digital currency project report by the first quarter of 2020.</a>
                        </li>
                        <li>
                            <a href="https://finance.yahoo.com/news/binance-adds-support-euro-bringing-094518624.html" target={'_blank'}>Binance has also added support for Euro (EUR) for its direct fiat-to-crypto trading facility.</a>
                        </li>
                    </ul>
                </div>
                <div class="bulletin-head-details bulletin-sub-heads">
                    <h4>
                        <span className="primary-span">Other</span>
                    </h4>
                    <ul className="bulletin-links-ul">
                        <li>
                            <a href="https://www.theblockcrypto.com/post/45116/escaping-fate-of-demolition-a-century-old-powerhouse-in-us-will-become-crypto-mining-center" target={'_blank'}>A 110-year-old powerhouse in Montana, U.S., has recently been approved by the Federal Energy Regulatory Commission to become a cryptocurrency mining center, escaping the fate of demolition after it stopped operating six years ago.</a>
                        </li>
                        <li>
                            <a href="https://news.bitcoin.com/mining-giant-bitmain-confidentially-files-for-u-s-based-ipo/" target={'_blank'}>Bitmain has reportedly filed an initial public offering application in the United States with the Securities and Exchange Commission.</a>
                        </li>
                        <li>
                            <a href="https://www.coindesk.com/binance-ceo-russia-is-our-key-market" target={'_blank'}>Binance CEO Changpeng Zhao has plans to expand into Russia, which he calls the company’s ‘key market.’</a>
                        </li>
                        <li>
                            <a href="https://www.coindesk.com/bakkt-to-launch-crypto-consumer-app-in-first-half-of-2020" target={'_blank'}>Bakkt has announced its plans to launch a consumer-facing app to help retail customers transact with cryptocurrencies.</a>
                        </li>
                        <li>
                            <a href="https://www.theblockcrypto.com/linked/45524/naspers-backed-crypto-exchange-luno-re-entering-singapore-after-banks-opened-its-accounts" target={'_blank'}>Cryptocurrency exchange Luno is going to re-enter Singapore, some years after it had stopped operating in the country.</a>
                        </li>
                        <li>
                            <a href="https://finance.yahoo.com/news/north-korea-suspected-using-blockchain-125936772.html" target={'_blank'}>A quarterly report from the UN Security Council’s Sanctions Committee says that North Korea could be laundering money using a Hong Kong-based blockchain firm.</a>
                        </li>
                        <li>
                            <a href="https://techcrunch.com/2019/10/23/zuckerberg-testimony/" target={'_blank'}>Bitmain’s ousted co-founder, Micree Zhan, has announced in an open letter that he will take legal action to get back in.</a>
                        </li>
                        <li>
                            <a href="https://in.reuters.com/article/uk-imf-worldbank-facebook/facebook-open-to-currency-pegged-stablecoins-for-libra-project-idINKBN1WZ0O5" target={'_blank'}>Facebook’s Libra head David Marcus has said that it could use cryptocurrencies​ ​ based on national currencies such as the dollar instead of a synthetic one it had​ ​ initially proposed.</a>
                        </li>
                        <li>
                            <a href="https://www.theblockcrypto.com/linked/46196/bitmains-ousted-co-founder-micree-zhan-says-he-will-take-legal-action-to-return-to-the-firm" target={'_blank'}>Bitmain, the Bitcoin mining company, has launched the world’s largest Bitcoin mining farm in Rockdale, Texas that’s been under construction since late 2018.</a>
                        </li>
                        <li>
                            <a href="https://finance.yahoo.com/news/huobi-argentina-plans-invest-100m-065633842.html" target={'_blank'}>Huobi Argentina, a subsidiary of the Huobi Group, is planning to invest $100M for creating a Blockchain data center.</a>
                        </li>
                        <li>
                            <a href="https://finance.yahoo.com/news/zcash-maker-passes-trademark-ownership-073759875.html" target={'_blank'}>The Zcash maker has passed the trademark ownership of the cryptocurrency to Zcash Foundation ‘at no cost.’</a>
                        </li>
                    </ul>
                </div>
            </div>            
        );
    }

}

export default NovemberFortnight1;