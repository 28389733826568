import React from 'react';
import "../../resources.css";
import { ResourceRoute } from '../../resource-routing';
import { Link } from "react-router-dom";
import NewsHeadings from "./common-action/news-heading";
import NewsSubscription from "./common-action/subscripbe-section";

class NewsIssue71 extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            pageEnabled: false,
        }

    }

    render() {
        return (
          <div className="newdesigned_issue_details">
            <NewsHeadings
              buttondDetail="FinTech"
              categoryType="fintech"
              description="Bitcoin has won over Institutional Investors: Could governments be next? | Bitcoinist.com"
            />

            <div className="newsissuedetailed">
              <div className="detailed-common">
                {/* <div className="back-to-common">
                            <span className="back-to-icon-hold">
                                <img src="/assets/img/resource/back-to-new.svg" alt="back to new"></img>
                            </span>
                            <Link to={ResourceRoute.NEWSLETTER}><span className="back-to-link">Newsletter</span></Link>
                        </div> */}

                <div className="news-letter whats-on-hold">
                  <div className="issue-main-head">
                    <h4>What’s on?</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.nbcnews.com/tech/tech-news/visa-moves-allow-payment-settlements-using-cryptocurrency-rcna534"
                      >
                        <h4>
                          Visa moves to allow payment settlements using
                          cryptocurrency | NBC News
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Visa said on Monday it will allow the use of the
                        cryptocurrency USD Coin to settle transactions on its
                        payment network, the latest sign of growing acceptance
                        of digital currencies by the mainstream financial
                        industry.The company told Reuters it had launched the
                        pilot program with payment and crypto platform
                        Crypto.com and plans to offer the option to more
                        partners later this year.
                      </p>
                    </div>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.mddionline.com/human-factors/ux-should-be-focus-start-when-designing-new-medical-devices"
                      >
                        <h4>
                          UX Should be the Focus from the Start When Designing
                          New Medical Devices | MDDIOnline.com{" "}
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        The user experience (UX) should be the starting point of
                        designing any product, according to Dorothy Shamonsky,
                        Ph.D., chief UX strategist at Integrated Computer
                        Solutions Inc., in an interview with MD+DI. “Although
                        some product ideas are born because a new technology is
                        developed, ideally the process then switches to focusing
                        on the user experience,” she said. “It still happens all
                        too often that a product is designed by engineers and
                        then once 90% complete, the user experience is
                        considered—too late to make any significant changes.”
                      </p>
                    </div>
                  </div>
                </div>
                <div className="news-letter top-picks-hold">
                  <div className="issue-main-head">
                    <h4>Top Picks</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://bitcoinist.com/bitcoin-has-won-over-institutional-investors-could-governments-be-next/"
                      >
                        <h4>
                          Bitcoin has won over Institutional Investors: Could
                          governments be next? | Bitcoinist.com{" "}
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        When Bitcoin surged to an all-time high of $17,000 in
                        late 2017, the cryptocurrency headlined media outlets
                        and financial journals — but not for the right reasons.
                        Analysts and major institutional figureheads bashed and
                        ostracized the promising digital asset, dubbing it a
                        “fraud” and “useless as a payment system.” Not soon
                        after, Bitcoin suffered a major correction, with prices
                        plummeting down 70% in a single year. For retail
                        investors, the warnings of doubtful institutions and
                        analysts that they previously ignored seemed to ring
                        truer than ever. Even at the time, one thing was for
                        certain: cryptocurrencies would never become mainstream
                        without institutional adoption.{" "}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="news-letter other-reads-hold">
                  <div className="issue-main-head">
                    <h4>Other Reads</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.technologyreview.com/2020/12/18/1013239/precision-agriculture-farmwise-remote-sensing-salinas/"
                      >
                        <h4>
                          How technology might finally start telling farmers
                          things they didn’t already know | MIT Tech Review
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        As a machine operator for the robotics startup FarmWise,
                        Diego Alcántar spends each day walking behind a hulking
                        robot that resembles a driverless Zamboni, helping it
                        learn to do the work of a 30-person weeding crew. On a
                        Tuesday morning in September, I met Alcántar in a
                        gigantic cauliflower field in the hills outside Santa
                        Maria, at the southern end of the vast checkerboard of
                        vegetable farms that line California’s central coast,
                        running from Oxnard north to Salinas and Watsonville.
                        Cooled by coastal mists rolling off the Pacific, the
                        Salinas valley is sometimes called America’s Salad Bowl.
                        Together with two adjacent counties to the south, the
                        area around Salinas produces the vast majority of
                        lettuce grown in the US during the summer months, along
                        with most of the cauliflower, celery, and broccoli, and
                        a good share of the berries.{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <NewsSubscription categoryType="FinTech" />
          </div>
        );
    }

}

export default NewsIssue71;
