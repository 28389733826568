import React from 'react';
import "../../resources.css";
import { ResourceRoute } from '../../resource-routing';
import { Link } from "react-router-dom";
import NewsHeadings from "./common-action/news-heading";
import NewsSubscription from "./common-action/subscripbe-section";

class NewsIssue15 extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            pageEnabled: false,
        }

    }

    render() {
        return (
          <div className="newdesigned_issue_details">
            <NewsHeadings
              buttondDetail="Design"
              categoryType="design"
              description="Is Neumorphism really 2020's hottest design trend? | Creative Bloq"
            />

            <div className="newsissuedetailed">
              <div className="detailed-common">
                {/* <div className="back-to-common">
                    <span className="back-to-icon-hold">
                    <img src="/assets/img/resource/back-to-new.svg"  alt="bact to new"></img>
                    </span>
                    <Link  to={ResourceRoute.NEWSLETTER}><span className="back-to-link">Newsletter</span></Link>
                </div> */}

                <div className="news-letter whats-on-hold">
                  <div className="issue-main-head">
                    <h4>What’s on?</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://fortune.com/2020/02/20/5g-economy-gap-mckinsey/"
                      >
                        <h4>
                          Why only one-quarter of the world will get true 5G
                          wireless, McKinsey says | Fortune
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        The wireless industry is spending tens of billions
                        dollars annually to install superfast 5G networks, but
                        the first wave of the technology isn't likely to reach
                        most people in the next decade, according to a new
                        report from consulting firm McKinsey.
                      </p>
                    </div>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://techxplore.com/news/2020-02-cloud-invisible-versatile-highly-profitable.html"
                      >
                        <h4>
                          Cloud computing: invisible, versatile and highly
                          profitable | Techxplore
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        With each passing quarter, Amazon, Microsoft and Google
                        have been setting new records, while cloud computing has
                        become the invisible backbone supporting much of our
                        daily lives. Its potential to become an even bigger part
                        of people's daily existence is sky-high.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="news-letter top-picks-hold">
                  <div className="issue-main-head">
                    <h4>Top Picks</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.creativebloq.com/news/neumorphism"
                      >
                        <h4>
                          Is Neumorphism really 2020's hottest design trend? |
                          Creative Bloq
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Everybody's talking about Neumorphism, a hot new design
                        trend that's appeared out of nowhere over the past
                        couple of months – but is it really all that? Designers
                        love a good trend, and there's much about Neumorphism
                        that suggests this could be a big one (people are
                        already talking about it as a potential look for iOS 14)
                        rather than an exercise in experimental design.{" "}
                      </p>
                    </div>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://cointelegraph.com/news/blockchain-for-the-environment-it-is-real-and-it-is-here"
                      >
                        <h4>
                          Blockchain for the Environment: It Is Real and It Is
                          Here | Coin Telegraph
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Every week sears a new image of climate change into our
                        collective consciousness. A few decades ago, we heard
                        about ozone holes and ice melting at slightly higher
                        rates than anticipated. Today, the reminders of climate
                        change are more visceral and immediate: California’s
                        hills and forests burn, Venice’s city council chamber
                        floods, and Iceland holds a funeral for a glacier.{" "}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="news-letter other-reads-hold">
                  <div className="issue-main-head">
                    <h4>Other Reads</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.usnews.com/news/elections/articles/2020-02-14/democratic-presidential-candidates-search-for-the-right-campaign-cash"
                      >
                        <h4>
                          The Left’s Search for the ‘Right’ Cash | US News
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        THEY NEED IT, BUT THEY resent that they need it. They've
                        acquired it, but are almost embarrassed by it. They rail
                        against it, but end virtually every speech or debate
                        closing remarks asking people to please give it to
                        them.The Democratic presidential contenders have a
                        love-hate relationship with money, which is essential to
                        running a presidential campaign but which – among
                        Democrats at least – carries a sort of dirty quality
                        that has contenders competing not just for dollars but
                        for dollars they claim are cleaner than everyone else's.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <NewsSubscription categoryType="Design" />
          </div>
        );
    }

}

export default NewsIssue15;
