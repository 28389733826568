import React from 'react';
import "../../resources.css";
import {ResourceRoute} from '../../resource-routing';
import {Link} from "react-router-dom";
import NewsHeadings from "./common-action/news-heading";
import NewsSubscription from "./common-action/subscripbe-section";

class NewsIssue26 extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            pageEnabled: false,
        }

    }

    render() {
        return (
          <div className="newdesigned_issue_details">
            <NewsHeadings
              buttondDetail="FinTech"
              categoryType="fintech"
              description="Bank as a service - the future of banks and FinTechstartups? | The Payers"
            />
            <div className="newsissuedetailed">
              <div className="detailed-common">
                {/* <div className="back-to-common">
                    <span className="back-to-icon-hold">
                    <img src="/assets/img/resource/back-to-new.svg"  alt="bact to new"></img>
                    </span>
                    <Link  to={ResourceRoute.NEWSLETTER}><span className="back-to-link">Newsletter</span></Link>
                </div> */}

                <div className="news-letter whats-on-hold">
                  <div className="issue-main-head">
                    <h4>What’s on?</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.globenewswire.com/news-release/2020/05/07/2029366/0/en/CoinLinked-Debuts-as-First-Ever-Blockchain-Based-Social-Network-and-Crypto-Commerce-Platform.html"
                      >
                        <h4>
                          CoinLinked Debuts as First-Ever Blockchain-Based
                          Social Network and Crypto-Commerce Platform | Global
                          News Wire
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        {" "}
                        CoinLinked®, a first-of-its-kind blockchain-based social
                        marketplace, is today revealed as a safe and easy-to-use
                        means for users to purchase any product from any website
                        in the world using cryptocurrency like Bitcoin, Ethereum
                        or stablecoins such as Tether, as well as Fiat. Founded
                        by Wall Street veteran and serial entrepreneur, Jenny Q.
                        Ta, who was also the creator of VCNetwork.Co, a platform
                        connecting VCs and startups, has combined her Wall
                        Street, crypto and social media industry expertise to
                        develop a dually centralized and decentralized social
                        marketplace meant to push crypto into the mainstream.
                      </p>
                    </div>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.coindesk.com/blockchain-bites-introducing-the-coindesk-50-and-a-roadmap-to-consensus-distributed"
                      >
                        <h4>
                          Blockchain Bites: Introducing the CoinDesk 50 and a
                          Roadmap to Consensus: Distributed | CoinDesk
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        {" "}
                        This new list features the most important, innovative
                        and viable projects in the crypto and blockchain
                        industry. In just 11 years, cryptocurrency and its
                        underlying technology has made serious in-roads,
                        promising to reinvent how the world transacts, how the
                        internet will function and how to more equitably
                        distribute resources. CoinDesk has been covering this
                        varied space for seven intense years. Today, we’re
                        honored to present the first 10 protocols, companies and
                        projects we believe have or will have the greatest
                        impact, not only on the industry, but the world at
                        large. This includes billion-dollar corporations like
                        Binance and Coinbase, but also aspirational projects
                        like Cosmos
                      </p>
                    </div>
                  </div>
                </div>
                <div className="news-letter top-picks-hold">
                  <div className="issue-main-head">
                    <h4>Top Picks</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.cio.com/article/3531389/scaling-agile-and-devops-for-digital-transformation.html"
                      >
                        <h4>
                          Scaling agile and DevOps for digital transformation |
                          CIO{" "}
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Many organizations see a move to agile software
                        development and then to DevOps as key steps in their
                        digital transformation journeys. But in many cases,
                        these transitions are tackled by smaller teams, thereby
                        limiting the overall impact they can have. Rolling out
                        these practices more broadly throughout the organization
                        can pay significant dividends by revamping processes on
                        a grander scale. But making that leap is challenging.
                      </p>
                    </div>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.finextra.com/blogposting/18734/bank-as-a-service---the-future-of-banks-and-fintech-startups"
                      >
                        <h4>
                          Bank as a service - the future of banks and fintech
                          startups? | The Payers
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Over half of Americans are refusing to use some products
                        or services due to personal privacy concerns, according
                        to survey results published on April 14, 2020 by Pew
                        Research Center, a nonpartisan American think tank based
                        in Washington. In light of this research you would think
                        that we would see Facebook and Google reporting large
                        losses in their subscriber base, but we don’t. Why? It's
                        commonly referred to as the ‘privacy paradox’, as John
                        Naughton wrote in The Guardian, ‘a dark shadow looms
                        over our networked world. It’s called the privacy
                        paradox’.{" "}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="news-letter other-reads-hold">
                  <div className="issue-main-head">
                    <h4>Other Reads</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.vox.com/2020/5/7/21248259/why-time-feels-so-weird-right-now-quarantine-coronavirus-pandemic"
                      >
                        <h4>Why time feels so weird right now | Vox </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        March was 30 years long and April was 30 minutes long.
                        What gives? We talked to a time philosopher to find out.
                        Wait. What happened to April? If you’re at all like me,
                        you might have flipped over your wall calendar to May
                        (because if you’re at all like me, you have several wall
                        calendars) and felt a perplexing confusion. How is it
                        that a whole month could have disappeared into the
                        gaping maw of quarantine, especially after March felt
                        like it dragged on for (conservatively) 72 years.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <NewsSubscription categoryType="FinTech" />
          </div>
        );
    }

}

export default NewsIssue26;
