import React from 'react';
import "../portfolio.css"
import {Link} from "react-router-dom";
import {ROUTES} from "../../../routes";
import {PortfolioRoute} from "../route-porfolio";
import { Toast } from 'react-bootstrap';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import {SlideDown} from 'react-slidedown'
import 'react-slidedown/lib/slidedown.css'
import { InputGroupRadio } from 'react-bootstrap/InputGroup';
// import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";

class DesignPortfolio extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            page: 1, 
            totalPage: 4, 
            dropDownValue:"All", 
            dropDownList: ['All','Fintech','Healthtech','Blockchain & Crypto', 'Online MarketPlace','Design','Development'],
            dropDownShow: false,
            start: 0,
            end: 3,
            buttonShow: false,
            height:"",
            cardList: [
                {
                    imgSrc: "/assets/img/p2e.png",
                    alt:"P2E",
                    title:"P2E",
                    cardName:"P2E",
                    cardDesc: "A modern regulatory compliance management solution with advanced automation and integration capabilities.",
                    linkTo: PortfolioRoute.P2E
                },
                {
                    imgSrc: "/assets/img/gaadibazaar.png",
                    alt:"Gaadi Bazaar",
                    title:"Gaadi Bazaar",
                    cardName:"Gaadi Bazaar",
                    cardDesc: "An intuitive online marketplace platform solution for hassle-free vehicle trading",
                    linkTo: PortfolioRoute.GaadiBazaar
                },
                {
                    imgSrc: "/assets/img/tenshey.png",
                    alt:"Tenshey",
                    title:"Tenshey",
                    cardName:"Tenshey",
                    cardDesc: "A user-centric executive coaching software solution built for tomorrow's leaders",
                    linkTo: PortfolioRoute.Tenshey
                },
                {
                    imgSrc: "/assets/img/dvs.png",
                    alt:"DVS",
                    title:"DVS",
                    cardName:"DVS",
                    cardDesc: "Blockchain-enabled document verification tool.",
                    linkTo: PortfolioRoute.Dvs
                },
                {
                    imgSrc: "/assets/img/portfolio-img/kyc-img/kyc-card-img.png",
                    alt:"KYC",
                    title:"KYC",
                    cardName:"IX-KYC",
                    cardDesc: "Easy Onboarding Solution for Successful Customer Engagement.",
                    linkTo: PortfolioRoute.Kyc
                },
                {
                    imgSrc: "/assets/img/bitsian.png",
                    alt:"Bitsian",
                    title:"Bitsian",
                    cardName:"Bitsian",
                    cardDesc: "Custom built Crypto exchange app and wallet.",
                    linkTo: "#"
                },
                {
                    imgSrc: "/assets/img/epl.png",
                    alt:"EPL",
                    title:"EPL",
                    cardName:"EPL",
                    cardDesc: "Personal loan processing solution.",
                    linkTo: PortfolioRoute.LoanProcessing
                },
                {
                    imgSrc: "/assets/img/Ixmfi.png",
                    alt:"ixMFI",
                    title:"ixMFI",
                    cardName:"ixMFI",
                    cardDesc: "End-to-end personal loan processing solution.",
                    linkTo: "#"
                },
                {
                    imgSrc: "/assets/img/espresso.png",
                    alt:"Espresso Capital",
                    title:"Espresso Capital",
                    cardName:"Espresso Capital",
                    cardDesc: "End to End Venture lending platform solution.",
                    linkTo: "#"
                },
                {
                    imgSrc: "/assets/img/chip.png",
                    alt:"Doc Talk",
                    title:"Doc Talk",
                    cardName:"Doc Talk",
                    cardDesc: "HIPAA-compliant Healthcare Communications App",
                    linkTo: "#"
                },
                {
                    imgSrc: "/assets/img/steth.png",
                    alt:"Steth IO",
                    title:"Steth IO",
                    cardName:"Steth IO",
                    cardDesc: "Patient centric medical consultation platform.",
                    linkTo: "#"
                },
                {
                    imgSrc: "/assets/img/hps.png",
                    alt:"HPS",
                    title:"HPS",
                    cardName:"HPS",
                    cardDesc: "Health and benefits administration software.",
                    linkTo: "#"
                }
            ]
        }
        this.handleDropDown = this.handleDropDown.bind(this)
        this.getDropDown = this.getDropDown.bind(this)
        this.handleOutsideClick = this.handleOutsideClick.bind(this);
    }
    componentDidMount() {
        // this.updateWindowDimensions();
        // window.addEventListener("resize", this.updateWindowDimensions);
        const height = this.divElement.clientHeight;
        this.setState({ height });
      }

    componentWillUnmount() {
        // window.removeEventListener("resize", this.updateWindowDimensions);
        window.removeEventListener('scroll', this.onScrollHeader);
    }

    // updateWindowDimensions = () => {
    //     this.setState({ width: window.innerWidth });
    // };
    viewMoreOnPress = () => {
       let arrLength = this.state.cardList.length;
       let remainder = this.state.cardList.slice(this.state.end, arrLength).length;
       const height = this.divElement.clientHeight;
        this.setState({
            buttonShow : !this.state.buttonShow,
            height: height
        },()=>{
            if(this.state.buttonShow === false && this.state.cardList.length > 3){
                this.setState({
                    end:  3
                })
                window.scrollTo(0,0);    
            }else{
               
                this.setState({
                    end: this.state.end + remainder
                   
                })
            }
        })
       
    }
    handleOutsideClick(e) {
        this.handleDropDown();
      }
    handleDropDown(){
        if (!this.state.dropDownShow) {
            // attach/remove event handler
            document.addEventListener('click', this.handleOutsideClick, false);
          } else {
            document.removeEventListener('click', this.handleOutsideClick, false);
          }
      
        this.setState({
            dropDownShow: !this.state.dropDownShow
        })
    }
    getDropDown(value){
        this.setState({
            dropDownValue:value,
            cardList: []
        },()=>{
            var filter;
            if(this.state.dropDownValue === "All"){
                 filter =  [
                    {
                        imgSrc: "/assets/img/p2e.png",
                        alt:"P2E",
                        title:"P2E",
                        cardName:"P2E",
                        cardDesc: "State-of-the-art regulatory change management system.",
                        linkTo: PortfolioRoute.P2E
                    },
                    {
                        imgSrc: "/assets/img/gaadibazaar.png",
                        alt:"Gaadi Bazaar",
                        title:"Gaadi Bazaar",
                        cardName:"Gaadi Bazaar",
                        cardDesc: "Trusted Online Vehicle Trading Platform.",
                        linkTo: PortfolioRoute.GaadiBazaar
                    },
                    {
                        imgSrc: "/assets/img/tenshey.png",
                        alt:"Tenshey",
                        title:"Tenshey",                                      
                        cardName:"Tenshey",
                        cardDesc: "Executive Coaching Platform for Leadership Training.",
                        linkTo: PortfolioRoute.Tenshey
                    },
                    {
                        imgSrc: "/assets/img/dvs.png",
                        alt:"DVS",
                        title:"DVS",                        
                        cardName:"DVS",
                        cardDesc: "Blockchain-enabled document verification tool.",
                        linkTo: PortfolioRoute.Dvs
                    },
                    {
                        imgSrc: "/assets/img/dvs.png",
                        alt:"KYC",
                        title:"KYC",
                        cardName:"KYC",
                        cardDesc: "Blockchain-enabled document verification tool.",
                        linkTo: PortfolioRoute.Kyc
                    },
                    {
                        imgSrc: "/assets/img/bitsian.png",
                        alt:"Bitsian",
                        title:"Bitsian",
                        cardName:"Bitsian",
                        cardDesc: "Custom built Crypto exchange app and wallet.",
                        linkTo: "#"
                    },
                    {
                        imgSrc: "/assets/img/epl.png",
                        alt:"EPL",
                        title:"EPL",
                        cardName:"EPL",
                        cardDesc: "End-to-end personal loan processing solution.",
                        linkTo: PortfolioRoute.LoanProcessing
                    },
                    {
                        imgSrc: "/assets/img/Ixmfi.png",
                        alt:"ixMFI",
                        title:"ixMFI",
                        cardName:"ixMFI",
                        cardDesc: "End-to-end personal loan processing solution.",
                        linkTo: "#"
                    },
                    {
                        imgSrc: "/assets/img/espresso.png",
                        alt:"Espresso Capital",
                        title:"Espresso Capital",
                        cardName:"Espresso Capital",
                        cardDesc: "End to End Venture lending platform solution.",
                        linkTo: "#"
                    },
                    {
                        imgSrc: "/assets/img/chip.png",
                        alt:"Doc Talk",
                        title:"Doc Talk",
                        cardName:"Doc Talk",
                        cardDesc: "HIPAA-compliant Healthcare Communications App.",
                        linkTo: "#"
                    },
                    {
                        imgSrc: "/assets/img/steth.png",
                        alt:"Steth IO",
                        title:"Steth IO",
                        cardName:"Steth IO",
                        cardDesc: "Patient centric medical consultation platform.",
                        linkTo: "#"
                    },
                    {
                        imgSrc: "/assets/img/hps.png",
                        alt:"HPS",
                        title:"HPS",
                        cardName:"HPS",
                        cardDesc: "Health and benefits administration software.",
                        linkTo: "#"
                    }
                ]
                this.setState({
                    cardList: [...filter]
                })
            }else if(this.state.dropDownValue === "Development"){
                filter =  [
                   {
                       imgSrc: "/assets/img/p2e.png",
                       alt:"P2E",
                       title:"P2E",
                       cardName:"P2E",
                       cardDesc: "State-of-the-art regulatory change management system.",
                       linkTo: PortfolioRoute.P2E
                   },
                   {
                       imgSrc: "/assets/img/gaadibazaar.png",
                       alt:"Gaadi Bazaar",
                       title:"Gaadi Bazaar",
                       cardName:"Gaadi Bazaar",
                       cardDesc: "Trusted Online Vehicle Trading Platform.",
                       linkTo: PortfolioRoute.GaadiBazaar
                   },
                   {
                       imgSrc: "/assets/img/tenshey.png",
                       alt:"Tenshey",
                       title:"Tenshey",                                      
                       cardName:"Tenshey",
                       cardDesc: "Executive Coaching Platform for Leadership Training.",
                       linkTo: PortfolioRoute.Tenshey
                   },
                   {
                       imgSrc: "/assets/img/dvs.png",
                       alt:"DVS",
                       title:"DVS",                        
                       cardName:"DVS",
                       cardDesc: "Blockchain-enabled document verification tool.",
                       linkTo: PortfolioRoute.Dvs
                   },
                   {
                       imgSrc: "/assets/img/bitsian.png",
                       alt:"Bitsian",
                       title:"Bitsian",
                       cardName:"Bitsian",
                       cardDesc: "Custom built Crypto exchange app and wallet.",
                       linkTo: "#"
                   },
                   {
                       imgSrc: "/assets/img/epl.png",
                       alt:"EPL",
                       title:"EPL",
                       cardName:"EPL",
                       cardDesc: "Personal loan processing solution.",
                       linkTo: PortfolioRoute.LoanProcessing
                   },
                   {
                       imgSrc: "/assets/img/Ixmfi.png",
                       alt:"ixMFI",
                       title:"ixMFI",
                       cardName:"ixMFI",
                       cardDesc: "End-to-end personal loan processing solution.",
                       linkTo: "#"
                   },
                   {
                       imgSrc: "/assets/img/espresso.png",
                       alt:"Espresso Capital",
                       title:"Espresso Capital",
                       cardName:"Espresso Capital",
                       cardDesc: "End to End Venture lending platform solution.",
                       linkTo: "#"
                   },
                   {
                       imgSrc: "/assets/img/chip.png",
                       alt:"Doc Talk",
                       title:"Doc Talk",
                       cardName:"Doc Talk",
                       cardDesc: "HIPAA-compliant Healthcare Communications App.",
                       linkTo: "#"
                   },
                   {
                       imgSrc: "/assets/img/steth.png",
                       alt:"Steth IO",
                       title:"Steth IO",
                       cardName:"Steth IO",
                       cardDesc: "Patient centric medical consultation platform.",
                       linkTo: "#"
                   },
                   {
                       imgSrc: "/assets/img/hps.png",
                       alt:"HPS",
                       title:"HPS",
                       cardName:"HPS",
                       cardDesc: "Health and benefits administration software.",
                       linkTo: "#"
                   }
               ]
               this.setState({
                   cardList: [...filter]
               })
           }else if(this.state.dropDownValue === "Design"){
            filter =  [
               {
                   imgSrc: "/assets/img/p2e.png",
                   alt:"P2E",
                   title:"P2E",
                   cardName:"P2E",
                   cardDesc: "State-of-the-art regulatory change management system.",
                   linkTo: PortfolioRoute.P2E
               },
               {
                   imgSrc: "/assets/img/gaadibazaar.png",
                   alt:"Gaadi Bazaar",
                   title:"Gaadi Bazaar",
                   cardName:"Gaadi Bazaar",
                   cardDesc: "Trusted Online Vehicle Trading Platform.",
                   linkTo: PortfolioRoute.GaadiBazaar
               },
               {
                   imgSrc: "/assets/img/tenshey.png",
                   alt:"Tenshey",
                   title:"Tenshey",                                      
                   cardName:"Tenshey",
                   cardDesc: "Executive Coaching Platform for Leadership Training.",
                   linkTo: PortfolioRoute.Tenshey
               },
               
               {
                   imgSrc: "/assets/img/epl.png",
                   alt:"EPL",
                   title:"EPL",
                   cardName:"EPL",
                   cardDesc: "End-to-end personal loan processing solution.",
                   linkTo: PortfolioRoute.LoanProcessing
               },
               {
                   imgSrc: "/assets/img/steth.png",
                   alt:"Steth IO",
                   title:"Steth IO",
                   cardName:"Steth IO",
                   cardDesc: "On-demand Healthcare Consultation App.",
                   linkTo: "#"
               }
           ]
           this.setState({
               cardList: [...filter]
           })
       }else if(this.state.dropDownValue === "Online MarketPlace"){
                filter =  [
                    
                    {
                        imgSrc: "/assets/img/gaadibazaar.png",
                        alt:"Gaadi Bazaar",
                        title:"Gaadi Bazaar",
                        cardName:"Gaadi Bazaar",
                        cardDesc: "Trusted Online Vehicle Trading Platform.",
                        linkTo: PortfolioRoute.GaadiBazaar
                    }
                ]
                this.setState({
                    cardList: [...filter]
                })
            }else if(this.state.dropDownValue === "Blockchain & Crypto"){
                filter =  [
                    {
                        imgSrc: "/assets/img/dvs.png",
                        alt:"DVS",
                        title:"DVS",
                        cardName:"DVS",
                        cardDesc: "Blockchain-enabled document verification tool.",
                        linkTo: PortfolioRoute.Dvs
                    },
                    {
                        imgSrc: "/assets/img/bitsian.png",
                        alt:"Bitsia",
                        title:"Bitsia",
                        cardName:"Bitsian",
                        cardDesc: "Custom built Crypto exchange app and wallet.",
                        linkTo: "#"
                    }
                    
                ]
                this.setState({
                    cardList: [...filter]
                })
            }else if(this.state.dropDownValue === "Fintech"){
                filter =  [
                    {
                        imgSrc: "/assets/img/epl.png",
                        alt:"EPL",
                        title:"EPL",
                        cardName:"EPL",
                        cardDesc: "End-to-end personal loan processing solution.",
                        linkTo: PortfolioRoute.LoanProcessing
                    },
                    {
                        imgSrc: "/assets/img/Ixmfi.png",
                        alt:"ixMFI",
                        title:"ixMFI",
                        cardName:"ixMFI",
                        cardDesc: "End-to-end personal loan processing solution.",
                        linkTo: "#"
                    },
                    {
                        imgSrc: "/assets/img/espresso.png",
                        alt:"Espresso Capital",
                        title:"Espresso Capital",
                        cardName:"Espresso Capital",
                        cardDesc: "End to End Venture lending platform solution.",
                        linkTo: "#"
                    }
                ]
                this.setState({
                    cardList: [...filter]
                })
             }else if(this.state.dropDownValue === "Healthtech"){  
                filter =  [
                    {
                        imgSrc: "/assets/img/chip.png",
                        alt:"Doc Talk",
                        title:"Doc Talk",
                        cardName:"Doc Talk",
                        cardDesc: "HIPAA-compliant Healthcare Communications App.",
                        linkTo: "#"
                    },
                    {
                        imgSrc: "/assets/img/steth.png",
                        alt:"Steth IO",
                        title:"Steth IO",
                        cardName:"Steth IO",
                        cardDesc: "Patient centric medical consultation platform.",
                        linkTo: "#"
                    },
                    {
                        imgSrc: "/assets/img/hps.png",
                        alt:"HPS",
                        title:"HPS",
                        cardName:"HPS",
                        cardDesc: "Health and benefits administration software.",
                        linkTo: "#"
                    }   
                ]
                this.setState({
                    cardList: [...filter]
                })
             }
        })
    }
     cardStyle = {
        opacity:  0,
        transition: "all 1s ease-in"
    };


    render() {
        // var settings = {
        //     dots: false,
        //     arrows:true,
        //     infinite: true,
        //     autoplay: true,
        //     speed: 3000,
        //     speed:2000,
        //     autoplaySpeed: 5000,
        //     pauseOnHover:true,
        //     slidesToShow: 2,
        //     slidesToScroll: 1,
        //     initialSlide: 0,
        //     responsive: [
        //         {
        //           breakpoint: 1098,
        //           settings: {
        //             slidesToShow: 1,
        //             slidesToScroll: 1,
        //             infinite: false,
        //             dots: false
        //           }
        //         },
        //         {
        //           breakpoint: 600,
        //           settings: {
        //             slidesToShow: 1,
        //             slidesToScroll: 1,
        //             initialSlide: 1,
        //             dots: false
        //           }
        //         },
        //         {
        //           breakpoint: 480,
        //           settings: {
        //             slidesToShow: 1,
        //             slidesToScroll: 1,
        //             dots: false
        //           }
        //         }
        //       ]
        //   };
        // console.log(this.state.height)
        return (
            <div className="tab-case" id="pills-profile" role="tabpanel" aria-labelledby="pills-profile-tab">
                {/* <div className="tab-head">
                    <div className="caption-sec">
                        <p>The design portfolio entries showcase our work in an easy-to-consume format.</p>
                    </div>
                    <div className="filter" ref={node => { this.node = node; }}>
                        <div className="dropdown portfolio-drop">
                            <button className="dropdown-toggle" type="button" id="dropdownMenuButton"
                                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" onClick={this.handleDropDown}>
                                Filter by : <span>{this.state.dropDownValue}</span>   
                            </button>
                            {
                                this.state.dropDownShow && <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                {/* <a className="dropdown-item">Action</a>
                                <a className="dropdown-item">Another action</a>
                                <a className="dropdown-item">Something else here</a> 
                                {
                                    this.state.dropDownList?.map((item)=>{
                                        return (
                                        <a className="dropdown-item" onClick={()=>this.getDropDown(item)}>{item}</a>
                                        )
                                    })
                                }
                            </div>
                            }
                            
                        </div>
                    </div>
                </div> */}

                <div className="tab-card porti-card">
                    {/* {
                        false  ?

                            <div className="port-slide" ref={ (divElement) => { this.divElement = divElement } } >
                                                        <Slider {...settings} >

                                 {
                                     this.state.cardList.length > 0 ? 
                                     this.state.cardList?.map((item)=>{
                                     return <div>
                                                <Link to={item.linkTo}>
                                                     <div className={"port-card "+(item.linkTo === "#" ? "cardClick" : "")} onClick={()=>{if(item.linkTo === "#"){this.props.popupCall()}}}>
                                                         <div className="whole-port">
                                                            //  {/* <div class="card-img">
                                                            //  <LazyLoadImage
                                                            //      alt={item.alt}
                                                            //      title={item.title}
                                                            //      effect="blur"
                                                            //      src={item.imgSrc} />
                                                            //  </div> 
 
                                                             <div className="card-img">
                                                                 <div className={"content"}>
                                                                     <div className="content-overlay"></div>
                                                                     <LazyLoadImage
                                                                     alt={item.alt}
                                                                     title={item.title}
                                                                     effect="blur"
                                                                     src={item.imgSrc} />
                                                                     <div className="content-details fadeIn-bottom">
                                                                         <p className="content-text">To know more about this project</p>
                                                                         <button className="port-btn">Talk to an expert</button>
                                                                     </div>
                                                                 </div>
                                                             </div>
                                                             <div class="card-head">
                                                             <h4>{item.cardName}</h4>
                                                             </div>
                                                             <div class="card-cap">
                                                                 <p>{item.cardDesc}</p>
                                                             </div>
                                                             <div class="more-text">
                                                             {
                                                                 item.linkTo !== "#" && 
                                                                 <Link to={item.linkTo}>Learn More</Link>
                                                             }
                                                             </div>
                                                             
                                                         </div>
                                                     </div>
                                                 </Link>
                                            </div>
                                          
                                     }) : 
                                     <div className="text-center no-data">Coming soon</div>
                                     
                             }
                                                    </Slider>
                         </div>*/}
                        {/* :  */}
                        <React.Fragment> 
                        <div className="row" ref={ (divElement) => { this.divElement = divElement } } >
                                 {
                                     this.state.cardList.length > 0 ? 
                                     this.state.cardList.slice(this.state.start, this.state.end)?.map((item)=>{
                                     return <div className="col-xl-4 col-lg-4 col-md-6 col-sm-12">
                                                 <Link to={item.linkTo}>
                                                     <div className={"port-card "+(item.linkTo === "#" ? "cardClick" : "")}>
                                                    {/* //  onClick={()=>{if(item.linkTo === "#"){this.props.popupCall()}}} */}
                                                         <div className="whole-port">
                                                             {/* <div class="card-img">
                                                             <LazyLoadImage
                                                                 alt={item.alt}
                                                                 title={item.title}
                                                                 effect="blur"
                                                                 src={item.imgSrc} />
                                                             </div> */}
 
                                                             <div className="card-img">
                                                                 <div className={"content"}>
                                                                     <div className="content-overlay"></div>
                                                                     <LazyLoadImage
                                                                     alt={item.alt}
                                                                     title={item.title}
                                                                     effect="blur"
                                                                     src={item.imgSrc} />
                                                                     <div className="content-details fadeIn-bottom">
                                                                         <p className="content-text">To know more about this project</p>
                                                                         <Link to={ROUTES.CONTACTUS} className="port-btn">Talk to an expert</Link>
                                                                     </div>
                                                                 </div>
                                                             </div>
                                                             {/* <div class="card-head">
                                                             <h4>{item.cardName}</h4>
                                                             </div> */}
                                                             <div class="card-cap">
                                                                 <p>{item.cardDesc}</p>
                                                             </div>
                                                             <div class="more-text">
                                                             {
                                                                 item.linkTo !== "#" && 
                                                                 <Link to={item.linkTo}>Learn More</Link>
                                                             }
                                                             </div>
                                                         </div>
                                                     </div>
                                                 </Link>
                                             </div>
                                         
                                     }) : 
                                     <div className="text-center no-data">Coming soon</div>
                                     
                             }
                             {
                                  this.state.cardList.length > 3  && this.state.buttonShow === true  ? <div className={"view-more"}>
                                     <button onClick={this.viewMoreOnPress} >
                                     {/* <span><img className={this.state.buttonShow ?"rotate-view-more mb-0 mt-0": "" }src="/assets/img/blue-arrow.svg" alt="Arrow"/></span> */}
                                     {this.state.cardList.length > 3 && !this.state.buttonShow ?  "View More" : "View Less" }
                                     </button>
                                 </div>  : ""
                             }
                         </div>
                         
                         
                         {
                             this.state.cardList.length > 3 && this.state.buttonShow === false ? <div className={"view-more"}>
                             <button onClick={this.viewMoreOnPress} >
                             {this.state.cardList.length > 3 && !this.state.buttonShow ?  "View More" : "View Less" }
                                 {/* <span><img className={this.state.buttonShow ?"rotate-view-more": "" }src="/assets/img/blue-arrow.svg" alt="Arrow"/></span> */}
                             </button>
                         </div>  : ""
                         } 
 
                     </React.Fragment>
                    
                   

                   </div>
                   
            </div>

        );
    }

}

export default DesignPortfolio;
