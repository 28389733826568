import React from "react";
import Zoom from "react-reveal/Fade";

const Testing = ({opacity, page}) => {

    return (
        <section id={'testing'} className={"section-2 blue-bg company-info "+(page===3?"":"")}  style={page===4 ?{opacity: 1}: {opacity: opacity}}>
            <div className="custom-container">
                <div className="custom-section">
                    {/* <Zoom delay={400} top key={page} when={page === 3}> */}
                        <div className="row work-with-row">
                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 des-tab-resp">
                                <div className="left-info-hold">
                                    <div className="overal-sec">
                                        <div className="design-section">
                                            <div className="step-count step-div">
                                                <h3>Step 4</h3>
                                            </div>
                                            <div className="highlight-text">
                                                <h4><span>Testing</span></h4>
                                            </div>
                                            <div className="main-para-div">
                                                <p>The only way to get UX design right is to test it.</p>
                                                <p>We run remote and live tests to uncover opportunities to further improve
                                                    the overall product design.</p>
                                            </div>
                                        </div>
                                        <div className="design-section mt-3">
                                            <div className="step-div">
                                                <h6>This leads to →</h6>
                                            </div>
                                            <div className="highlight-blue">
                                                <h4>Real User Validation</h4>
                                            </div>
                                            <div className="main-para-div">
                                                <p>Usability testing can help a team truly understand their user’s journey
                                                    through the product. This ensures we’ve designed the best possible
                                                    experience before we start coding.</p>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 comp-info-right-col">
                                <div className="right-img-hold">
                                    <div className="comp-info-right-img">
                                                <span className="company-offer-imgs">
                                                    <img src="/assets/img/design-img/testing.svg" alt="Testing" title="Testing"/>
                                                </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    {/* </Zoom> */}
                </div>
            </div>
        </section>
    )
}

export default Testing
