import React from 'react';
import "../../resources.css";
import {ResourceRoute} from '../../resource-routing';
import {Link} from "react-router-dom";
import NewsHeadings from "./common-action/news-heading";
import NewsSubscription from "./common-action/subscripbe-section";

class NewsIssue31 extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            pageEnabled: false,
        }

    }

    render() {
        return (
          <div className="newdesigned_issue_details">
            <NewsHeadings
              buttondDetail="Web3 & Blockchain"
              categoryType="web3"
              description="Microsoft releases a beta version of its decentralized identity tool on the Bitcoin blockchain | The Block Crypto"
            />
            <div className="newsissuedetailed">
              <div className="detailed-common">
                {/* <div className="back-to-common">
                    <span className="back-to-icon-hold">
                    <img src="/assets/img/resource/back-to-new.svg"  alt="bact to new"></img>
                    </span>
                    <Link  to={ResourceRoute.NEWSLETTER}><span className="back-to-link">Newsletter</span></Link>
                </div> */}

                <div className="news-letter whats-on-hold">
                  <div className="issue-main-head">
                    <h4>What’s on?</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.theverge.com/2020/6/8/21284683/ibm-no-longer-general-purpose-facial-recognition-analysis-software"
                      >
                        <h4>
                          IBM will no longer offer, develop, or research facial
                          recognition technology | The Verge
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        IBM will no longer offer general purpose facial
                        recognition or analysis software, IBM CEO Arvind Krishna
                        said in a letter to Congress today. The company will
                        also no longer develop or research the technology, IBM
                        tells The Verge. Krishna addressed the letter to Sens.
                        Cory Booker (D-NJ) and Kamala Harris (D-CA) and Reps.
                        Karen Bass (D-CA), Hakeem Jeffries (D-NY), and Jerrold
                        Nadler (D-NY)
                      </p>
                    </div>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://cointelegraph.com/news/indias-crypto-spring-as-wave-of-new-exchanges-launch"
                      >
                        <h4>
                          India’s ‘Crypto Spring’ as Wave of New Exchanges
                          Launch | Coin Telegraph
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        The repeal of the Reserve Bank of India’s (RBI) crypto
                        ban has driven a boom in exchanges launching in India,
                        offering hope that the growth of a robust local industry
                        will incentivize lawmakers to maintain India’s
                        permissive regulatory apparatus. In March, India’s
                        Supreme Court repealed the Reserve Bank of India’s (RBI)
                        ban on financial institutions providing banking service
                        to businesses operating with virtual currencies —
                        legitimizing India’s cryptocurrency firms.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="news-letter top-picks-hold">
                  <div className="issue-main-head">
                    <h4>Top Picks</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.theblockcrypto.com/linked/67922/microsoft-has-released-a-beta-version-of-its-decentralized-identity-tool-on-the-bitcoin-blockchain"
                      >
                        <h4>
                          Microsoft releases a beta version of its decentralized
                          identity tool on the Bitcoin blockchain | The Block
                          Crypto
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Microsoft's decentralized identity tool, called ION, is
                        moving to the Bitcoin mainnet for a public beta test,
                        according to an announcement from Microsoft.
                        Decentralized identity tools enable greater ownership
                        over one's digital identity credentials by creating
                        unique identifiers that are cryptographically
                        verifiable.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="news-letter other-reads-hold">
                  <div className="issue-main-head">
                    <h4>Other Reads</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.creativebloq.com/inspiration/9-ways-to-focus-a-wandering-mind"
                      >
                        <h4>
                          9 ways to focus a wandering mind | Creative Bloq
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Your mind has a tendency to wander at the best of times
                        – not least while working from home. From Netflix and
                        WhatsApp, to checking the contents of the fridge
                        (again), it's hard to stay vigilant against the
                        thousands of distractions begging for our attention. A
                        wandering mind isn't always a bad thing for creatives,
                        we might add, but if you need some focus right now,
                        you're in luck. Not only is there an abundance of
                        scientific research into how to avoid distraction, but a
                        handy infographic (below) translates the science into
                        nine easy steps to follow to help you stay focused
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <NewsSubscription categoryType="Web3" />
          </div>
        );
    }

}

export default NewsIssue31;
