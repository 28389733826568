import React from 'react';
import "../../resources.css";
import {ResourceRoute} from '../../resource-routing';
import {Link} from "react-router-dom";
import NewsHeadings from "./common-action/news-heading";
import NewsSubscription from "./common-action/subscripbe-section";

class NewsIssue51 extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            pageEnabled: false,
        }

    }

    render() {
        return (
          <div className="newdesigned_issue_details">
            <NewsHeadings
              buttondDetail="Design"
              categoryType="design"
              description="What “Digital Wellbeing” Means to Material Design | Material Design"
            />
            <div className="newsissuedetailed">
              <div className="detailed-common">
                {/* <div className="back-to-common">
                    <span className="back-to-icon-hold">
                        <img src="/assets/img/resource/back-to-new.svg"></img>
                    </span>
                    <Link  to={ResourceRoute.NEWSLETTER}><span className="back-to-link">Newsletter</span></Link>
                </div> */}

                <div className="news-letter whats-on-hold">
                  <div className="issue-main-head">
                    <h4>What’s on?</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.cnbc.com/2020/10/27/jpmorgan-creates-new-unit-for-blockchain-projects-as-it-says-the-technology-is-close-to-making-money.html"
                      >
                        <h4>
                          JPMorgan creates new unit for blockchain projects,
                          says the technology is close to making money | CNBC
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        After years of hype with little to show for it,
                        blockchain technology is on the cusp of a breakthrough:
                        making money in actual business applications. At
                        JPMorgan Chase, the firm’s digital currency JPM Coin is
                        being used commercially for the first time this week by
                        a large technology client to send payments around the
                        world, said Takis Georgakopoulos, the bank’s global head
                        of wholesale payments.
                      </p>
                    </div>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.windowscentral.com/windows-10-sun-valley-ui-october-2021-update"
                      >
                        <h4>
                          Microsoft plans big Windows 10 UI refresh in 2021
                          codenamed 'Sun Valley' | WindowsCentral.com
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Microsoft is preparing a major OS update for Windows 10
                        in 2021 that sources say will bring with it a
                        significant design refresh to the Windows UI. I'm told
                        that Microsoft is planning to update many top-level user
                        interfaces such as the Start menu, Action Center, and
                        even File Explorer, with consistent modern designs,
                        better animations, and new features. This UI project is
                        codenamed "Sun Valley" internally and is expected to
                        ship as part of the Windows 10 "Cobalt" release
                        scheduled for the holiday 2021 season.{" "}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="news-letter top-picks-hold">
                  <div className="issue-main-head">
                    <h4>Top Picks</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://material.io/blog/digital-wellbeing-design-systems/"
                      >
                        <h4>
                          What “Digital Wellbeing” Means to Material Design |
                          Material Design{" "}
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Products can promote wellbeing by focusing on
                        fundamental needs, not just clicks. If any interaction
                        with technology has the capacity to impact an
                        individual’s well-being, either positively or
                        negatively, what strategies can we use – as developers,
                        designers, researchers, and makers of all disciplines –
                        to build with digital wellbeing in mind?
                      </p>
                    </div>
                  </div>
                </div>
                <div className="news-letter other-reads-hold">
                  <div className="issue-main-head">
                    <h4>Other Reads</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://hbr.org/2020/11/how-apple-is-organized-for-innovation"
                      >
                        <h4>How Apple Is Organized for Innovation | HBR.org</h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Apple has hundreds of specialist teams across the
                        company, dozens of which may be needed for even one key
                        component of a new product offering. For example, the
                        dual-lens camera with portrait mode required the
                        collaboration of no fewer than 40 specialist teams:
                        silicon design, camera software, reliability
                        engineering, motion sensor hardware, video engineering,
                        core motion, and camera sensor design, to name just a
                        few. How on earth does Apple develop and ship products
                        that require such coordination? The answer is
                        collaborative debate. Because no function is responsible
                        for a product or a service on its own, cross-functional
                        collaboration is crucial.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <NewsSubscription categoryType="Design" />
          </div>
        );
    }

}

export default NewsIssue51;
