import React from "react";
import "../../resources.css";
import { ResourceRoute } from "../../resource-routing";
import { Link } from "react-router-dom";
import NewsHeadings from "./common-action/news-heading";
import NewsSubscription from "./common-action/subscripbe-section";
import ReactSEOMetaTags from "react-seo-meta-tags";
import Helmet from "react-helmet";
import { Modal } from "react-bootstrap";

class NewsIssue84 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pageEnabled: false,
      modalShow: false,
      letftStickyContnet: "FinTechWebinar by Shaan",
    };
  }
  handleClose() {
    this.setState({ modalShow: false });
  }
  handleShow() {
    this.setState({ modalShow: true });
  }

  render() {
    return (
      <>
        <Helmet>
          <title>Newsletter | Ionixx Technologies</title>
          <meta
            name="description"
            content="Ix Digest is a weekly tech newsletter featuring technology,blockchain news, and other interesting tech insights."
          />
          <link
            rel="canonical"
            href="https://www.ionixxtech.com/resources/newsletter"
          />
        </Helmet>
        <ReactSEOMetaTags
          render={(el) => <Helmet>{el}</Helmet>}
          website={{
            name: "Ionixx Technologies",
            title:
              "A Safer IoT Ecosystem: Harnessing Blockchain for Enhanced Security of Digital IDs.",
            description:
              "Projected $1 trillion IoT investments by 2026, growing complexity brings security risks. Join our webinar with Shaan Ray to uncover IoT security challenges, blockchain-based digital IDs, and Ionixx's proactive blockchain solutions.",
            type: "website",
            image: "/assets/newsletter_images/issue_84/webinar_shaan-08.jpg",
            url: "https://www.ionixxtech.com/resources/newsletter/news-issue-84",
          }}
          twitter={{
            twitter:
              "https://www.ionixxtech.com/resources/newsletter/news-issue-84",
          }}
        />
        <div className="newdesigned_issue_details issue_83_design">
          <NewsHeadings
            buttondDetail="Web3"
            categoryType="web3"
            description="A Safer IoT Ecosystem: Harnessing Blockchain for Enhanced Security of Digital IDs."
            newDetail={true}
          />
          {/* <div className='sticky-button banner-modal-button'>
                    <button onClick={() => this.handleShow()}>FinTechWebinar with Mr.Shaan Ray</button>
                 </div> */}
          {/*modal content */}
          <Modal
            className="newsletter-modal theme-change"
            centered
            show={this.state.modalShow}
            onHide={() => this.handleClose()}
          >
            <Modal.Header closeButton>
              <div className="banner-section">
                <div className="banner-left">
                  <img
                    src="/assets/newsletter_images/issue_84/webinar_shaan-08.jpg"
                    alt="defi"
                  />
                </div>
                <div className="banner-right">
                  <span>Webinar | 16 August 2023</span>
                  {/* <strong>Digital IDs and the Internet of Things: The Future of Identity & Security </strong> */}
                  <p>
                    Projected $1 trillion IoT investments by 2026, growing
                    complexity brings security risks. Join our webinar with
                    Shaan Ray to uncover IoT security challenges,
                    blockchain-based digital IDs, and Ionixx's proactive
                    blockchain solutions.
                  </p>
                  <a
                    className="read-more"
                    href="https://bit.ly/44INlXa"
                    target="_blank"
                  >
                    Register Now
                  </a>
                </div>
              </div>
            </Modal.Header>
          </Modal>

          <div className="newsissuedetailed">
            <div className="detailed-common">
              {/* <div className="back-to-common">
                            <span className="back-to-icon-hold">
                                <img src="/assets/img/resource/back-to-new.svg" alt="back to new"></img>
                            </span>
                            <Link to={ResourceRoute.NEWSLETTER}><span className="back-to-link">Newsletter</span></Link>
                        </div> */}

              <div className="headlines-warp">
                <div className="top-news">
                  <i>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="234"
                      height="140"
                      viewBox="0 0 234 140"
                      fill="none"
                    >
                      <g clip-path="url(#clip0_462_1735)">
                        <path
                          d="M45.2302 29.1322L42.6203 21.6729H0.319092V29.1322V34.9968V130.329H140.56V29.1322H45.2302Z"
                          fill="#00AEEF"
                          stroke="#263238"
                          stroke-miterlimit="10"
                        />
                        <path
                          d="M137.93 39.0251H2.94177V124.135H137.93V39.0251Z"
                          fill="white"
                          stroke="#263238"
                          stroke-miterlimit="10"
                        />
                        <path
                          d="M112.278 31.947H45.2111V36.0325H112.278V31.947Z"
                          fill="white"
                          stroke="#263238"
                          stroke-miterlimit="10"
                        />
                        <path
                          d="M6.1898 31.2925L4.25629 33.1478L6.26 35.289"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M4.25629 33.1477H8.55086"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M17.1528 31.2925L19.0927 33.1478L17.0826 35.289"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M19.0927 33.1477H14.7917"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M28.3263 32.8999C28.1158 32.0612 27.2607 31.3687 26.3545 31.3687C25.2889 31.3687 24.421 32.2328 24.421 33.2939C24.421 34.3549 25.2889 35.2191 26.3545 35.2191C26.8204 35.2191 27.2479 35.0539 27.5797 34.7807"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M27.0245 33.116H28.5305V31.3369"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M38.581 32.487L36.7943 31.0447L35.0969 32.487V35.6131H38.581V32.487Z"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M37.4707 33.332H36.1115V35.505H37.4707V33.332Z"
                          fill="#263238"
                        />
                        <path
                          d="M35.0969 32.4871L34.0759 33.4147"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M38.581 32.4871L39.5382 33.3321"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M37.9365 25.5295L40.6549 28.3252"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M40.6996 25.574L37.8918 28.2807"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M130.822 32.6968H136.201"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M130.822 33.9041H136.201"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M130.822 35.1113H136.201"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M130.822 36.3184H136.201"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M105.622 7.77701L103.012 0.317627H60.7111V7.77701V13.6416V108.974H200.952V7.77701H105.622Z"
                          fill="#00AEEF"
                          stroke="#263238"
                          stroke-miterlimit="10"
                        />
                        <path
                          d="M198.329 17.6699H63.3402V102.779H198.329V17.6699Z"
                          fill="white"
                          stroke="#263238"
                          stroke-miterlimit="10"
                        />
                        <path
                          d="M172.67 10.5918H105.603V14.6773H172.67V10.5918Z"
                          fill="white"
                          stroke="#263238"
                          stroke-miterlimit="10"
                        />
                        <path
                          d="M66.5818 9.93726L64.6483 11.7926L66.6584 13.9338"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M64.6483 11.7925H68.9493"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M77.5512 9.93726L79.4847 11.7926L77.481 13.9338"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M79.4847 11.7925H75.1838"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M88.7248 11.5447C88.5142 10.706 87.6591 10.0134 86.753 10.0134C85.6873 10.0134 84.8195 10.8775 84.8195 11.9386C84.8195 12.9997 85.6873 13.8638 86.753 13.8638C87.2188 13.8638 87.6463 13.6986 87.9782 13.4254"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M87.423 11.7608H88.9226V9.98169"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M98.9731 11.1318L97.1863 9.68945L95.4889 11.1318V14.2642H98.9731V11.1318Z"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M97.8691 11.9768H96.5099V14.1498H97.8691V11.9768Z"
                          fill="#263238"
                        />
                        <path
                          d="M95.4889 11.1318L94.4679 12.0595"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M98.9731 11.1318L99.9366 11.9769"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M98.3285 4.18066L101.047 6.96999"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M101.092 4.21875L98.2902 6.92548"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M191.22 11.3416H196.6"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M191.22 12.5488H196.6"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M191.22 13.7561H196.6"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M191.22 14.9631H196.6"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M138.358 38.485L135.742 31.032H93.4468V38.485V44.3559V139.682H233.681V38.485H138.358Z"
                          fill="#00AEEF"
                          stroke="#263238"
                          stroke-miterlimit="10"
                        />
                        <path
                          d="M231.058 48.3779H96.0695V133.487H231.058V48.3779Z"
                          fill="white"
                          stroke="#263238"
                          stroke-miterlimit="10"
                        />
                        <path
                          d="M205.406 41.3062H138.339V45.3917H205.406V41.3062Z"
                          fill="white"
                          stroke="#263238"
                          stroke-miterlimit="10"
                        />
                        <path
                          d="M99.3176 40.6516L97.3777 42.5069L99.3878 44.6482"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M97.3777 42.5068H101.679"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M110.281 40.6516L112.214 42.5069L110.21 44.6482"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M112.214 42.5068H107.913"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M121.454 42.2591C121.244 41.4204 120.388 40.7278 119.482 40.7278C118.417 40.7278 117.549 41.5919 117.549 42.653C117.549 43.7141 118.417 44.5782 119.482 44.5782C119.948 44.5782 120.376 44.413 120.707 44.1398"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M120.152 42.4751H121.658V40.6897"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M131.702 41.8398L129.922 40.4038L128.218 41.8398V44.9722H131.702V41.8398Z"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M130.598 42.6848H129.239V44.8578H130.598V42.6848Z"
                          fill="#263238"
                        />
                        <path
                          d="M128.218 41.8398L127.197 42.7739"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M131.702 41.8398L132.666 42.6913"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M131.064 34.8887L133.783 37.678"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M133.821 34.9331L131.02 37.6398"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M223.95 42.0559H229.329"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M223.95 43.2632H229.329"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M223.95 44.4705H229.329"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M223.95 45.6775H229.329"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M116.668 91.6094H144.797"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M116.668 95.2246H144.797"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M116.668 98.8401H144.797"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M116.668 102.462H144.797"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M116.668 106.077H144.797"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M116.668 109.692H144.797"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M116.668 113.308H144.797"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M116.668 116.929H144.797"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M116.668 120.545H144.797"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M149.748 91.6094H177.871"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M149.748 95.2246H177.871"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M149.748 98.8401H177.871"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M149.748 102.462H177.871"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M149.748 106.077H177.871"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M149.748 109.692H177.871"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M149.748 113.308H177.871"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M149.748 116.929H177.871"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M149.748 120.545H177.871"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M149.748 62.96H177.871"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M149.748 66.5752H177.871"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M149.748 70.197H177.871"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M149.748 73.8123H177.871"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M149.748 77.4275H177.871"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M149.748 81.043H177.871"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M149.748 84.6646H177.871"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M149.748 88.28H177.871"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M182.688 91.6094H210.817"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M182.688 95.2246H210.817"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M182.688 98.8401H210.817"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M182.688 102.462H210.817"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M182.688 106.077H210.817"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M182.688 109.692H210.817"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M182.688 113.308H210.817"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M182.688 116.929H210.817"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M182.688 120.545H210.817"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M135.48 68.7228C135.627 68.3098 135.697 67.7825 135.697 67.3949C135.697 65.7492 134.025 65.6539 132.372 65.6539H131.958V65.2664H139.143V65.6539C137.273 65.6539 136.616 66.8358 136.01 68.6021L132.2 79.9818C131.907 80.8269 131.664 81.4559 130.771 81.4559H130.701L127.325 70.5845L124.173 79.9818C123.879 80.8269 123.636 81.4559 122.743 81.4559H122.673L117.772 65.6539H116.049V65.2664H122.36V65.6539H120.567L124.204 78.1201H124.351L127.095 69.8093L125.806 65.6539H124.083V65.2664H130.394V65.6539H128.601L132.238 78.1201H132.385L135.493 68.7228H135.48Z"
                          fill="#00AEEF"
                        />
                        <path
                          d="M211.085 61.6638H182.14V87.7145H211.085V61.6638Z"
                          fill="#00AEEF"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M211.085 72.5479C210.3 72.586 209.349 72.7067 208.194 72.9545C203.798 73.9076 202.649 80.1851 197.678 80.1851C193.275 80.1851 192.898 74.2825 186.587 73.52C184.679 73.2913 183.231 73.6725 182.133 74.3079V87.7017H211.079V72.5415L211.085 72.5479Z"
                          fill="#263238"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M191.756 67.6237C191.756 69.4092 190.301 70.8578 188.508 70.8578C186.715 70.8578 185.26 69.4092 185.26 67.6237C185.26 65.8383 186.715 64.3896 188.508 64.3896C190.301 64.3896 191.756 65.8383 191.756 67.6237Z"
                          fill="white"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_462_1735">
                          <rect width="234" height="140" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </i>
                  <h4>Top Blog</h4>
                  <span>
                    Exploring Blockchain's Impact on Global Supply Chains:
                    Transparency, Efficiency, and Security Unveiled. Delve into
                    blockchain's role, benefits, and potential breakthrough in
                    reshaping logistics.
                  </span>
                  <a
                    href="https://blog.ionixxtech.com/how-blockchain-technology-can-usher-in-a-new-era-in-supply-chain__trashed/"
                    target="_blank"
                  >
                    Read More...
                  </a>
                </div>
                <div className="headlines-content">
                  <strong className="overall-heading">Web3 News Roundup</strong>
                  <div className="headlines-list">
                    <a
                      href="https://cointelegraph.com/news/how-are-farmers-using-blockchain-ai-and-iot-ama-with-dimitra"
                      target="_blank"
                    >
                      Dimitra Leveraging Blockchain and IoT: AgTech and Smart
                      Farming
                    </a>
                    <p>
                      Dimitra, an AgTech firm leverages the power of sensors,
                      drones, GIS, farm-management software, robotics, machine
                      learning, and blockchain in their day-to-day work, giving
                      farmers full control over their farms.
                    </p>
                  </div>
                  <div className="headlines-list">
                    <a
                      href="https://www.weforum.org/agenda/2023/03/digital-id-privacy/"
                      target="_blank"
                    >
                      WEF Introduces Blockchain Digital ID Initiative to Create
                      An International Framework for Privacy-preserving Digital
                      ID
                    </a>
                    <p>
                      An estimated 850 million people lack official
                      identification. A coordinated policy approach is necessary
                      to deliver institutional trust and operational
                      interoperability at scale. The World Economic Forum’s
                      Digital ID Initiative is catalyzing an international
                      framework to realize financial, health, and social impact.
                    </p>
                  </div>
                  <div className="headlines-list">
                    <a href="https://decrypt.co/news-explorer?pinned=253250&title=shiba-inu-is-working-on-a-blockchain-based-digital-identity-system">
                      Shiba Inu Is Working on a Blockchain-based Digital
                      Identity System
                    </a>
                    <p>
                      Digital identity verification will be a part of all Shiba
                      Inu developments in the future, including on the upcoming
                      Shibarium layer 2 blockchain. Such steps could fuel demand
                      for SHIB tokens as the project is viewed more legitimately
                      by serious investors.
                    </p>
                  </div>
                  <div className="headlines-list">
                    <a
                      href="https://www.coindesk.com/web3/4-actual-use-cases-for-blockchain-and-ai-that-are-more-than-just-hype/"
                      target="_blank"
                    >
                      4 Actual Use Cases for Blockchain and AI That Are More
                      Than Just Hype
                    </a>
                    <p>
                      Blockchain and AI could be the 21st century's most
                      culturally significant pairing that could change the way
                      we think about creativity, identity, and verification.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="new-detail-option">
            <div className="news-first-section">
              <div className="heading">
                <div className="detail-heading">
                  <h5>
                    <span>1</span>Blockchain for Enhancing IoT Security
                  </h5>
                </div>
              </div>
              <div className="newsissuedetailed">
                <div className="news-left">
                  <i>
                    {" "}
                    <img
                      src="/assets/newsletter_images/issue_84/issue84-1.png"
                      alt="piggy-bank"
                    />
                  </i>
                  {/* <strong>Tackling Alert Fatigue</strong> */}
                  <b>
                    A recent forecast from the International Data Corporation
                    (IDC) estimates that there will be 41.6 billion connected
                    IoT devices, or “things,” generating 79.4 zettabytes (ZB) of
                    data in 2025.{" "}
                  </b>
                </div>
                <div className="news-right">
                  <p>
                    As IoT devices become integral to our daily lives,
                    safeguarding their functionalities and the sensitive data
                    they generate has become critical.
                  </p>
                  <p>
                    Blockchain introduces an unprecedented level of transparency
                    and immutability to the realm of IoT. It transforms the
                    concept of digital identity from being a vulnerable link to
                    a fortified chain, capable of withstanding unauthorized
                    access and tampering."
                  </p>
                  <p>
                    Blockchain acts as a distributed ledger, storing device
                    identities and transaction records in an unalterable manner.
                    That prevents fraudulent activities and ensures the
                    integrity of data between IoT devices. That metamorphoses
                    blockchain from mere chains of transactions to powerful
                    shields guarding against intrusions.
                  </p>
                  <p>
                    Blockchain's decentralized nature mitigates the risks of a
                    single point of failure that traditional centralized systems
                    are prone to. By distributing identity management across the
                    network, blockchain significantly reduces the vulnerability
                    surface for cyberattacks.
                  </p>
                  <p>
                    The intersection of blockchain with IoT goes beyond
                    security; it enables the creation of autonomous device
                    interactions. Devices with blockchain-based digital
                    identities can seamlessly authenticate and communicate. The
                    convergence of blockchain and IoT heralds a future where
                    chains evolve into formidable shields, guarding against an
                    increasingly sophisticated array of threats.
                  </p>
                </div>
              </div>
            </div>

            <div className="news-first-section">
              <div className="heading">
                <div className="detail-heading">
                  <h5>
                    <span>2</span>Beyond Theory: How Blockchain's Digital
                    Identity Redefines the Norm
                  </h5>
                </div>
              </div>
              <div className="newsissuedetailed">
                <div className="news-left">
                  <i>
                    <img
                      src="/assets/newsletter_images/issue_84/issue84-2.png"
                      alt="bitcoin-logo"
                    />
                  </i>
                  {/* <strong>Tackling Alert Fatigue</strong> */}
                  <b>
                    Digital identity's prominence surges as our digital lives
                    expand. In healthcare, blockchain secures medical data.
                    Supply chains benefit from verified authenticity. Smart
                    homes gain secure, empowered access control.
                  </b>
                </div>
                <div className="news-right">
                  <p>
                    In an era where digital interactions saturate our daily
                    lives, the notion of identity has transcended basic
                    credentials. Amid the expansive realm of digital
                    transactions, communication, and interconnected devices, The
                    demand for a resilient digital identity has surged
                    significantly.
                  </p>
                  <ul className="stroke-list">
                    <li>
                      <span></span>
                      <b>Healthcare</b>
                      <p>
                        Consider healthcare, a domain fraught with data breaches
                        and unauthorized access. Blockchain-based digital
                        identity presents a secure avenue for managing and
                        sharing medical records. Patients can upload their
                        medical records, lab results, and genetic information,
                        all authenticated and safeguarded by their unique
                        digital identity stored on the blockchain.
                      </p>
                    </li>
                    <li>
                      <span></span>
                      <b>Supply chains</b>
                      <p>
                        The manufacturing and supply chain industry is another
                        realm where the practical impact of blockchain-based
                        digital identity is palpable. Each product can be
                        assigned a unique digital identity, securely stored on
                        the blockchain. This digital ID serves as an indelible
                        marker, allowing manufacturers, distributors, and
                        consumers to verify the authenticity of goods and track
                        their journey across the supply chain.
                      </p>
                    </li>
                    <li>
                      <span></span>
                      <b>Smart homes</b>
                      <p>
                        In the world of smart homes, where devices are
                        constantly communicating and sharing data, the need for
                        a secure digital identity is paramount. The
                        democratization of identity empowers individuals to
                        grant temporary access to specified individuals,
                        eliminating the need for intermediaries and third-party
                        oversight.
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="news-first-section">
              <div className="heading">
                <div className="detail-heading">
                  <h5>
                    <span>3</span>The IoT Spectrum: Balancing Threats and
                    Opportunities
                  </h5>
                </div>
              </div>
              <div className="newsissuedetailed">
                <div className="news-left">
                  <i>
                    {" "}
                    <i>
                      <img
                        src="/assets/newsletter_images/issue_84/issue84-3.png"
                        alt="cryptocurrency"
                      />
                    </i>
                  </i>
                  <b>
                    IoT's transformative era sparks security concerns.
                    Vulnerabilities like centralized storage and weak
                    authentication risk digital identity. Centralized access
                    management struggles, while blockchain offers enhanced
                    security, privacy, user control, fraud prevention, and
                    decentralized trust.{" "}
                  </b>
                </div>

                <div className="news-right">
                  <p>
                    The proliferation of interconnected devices marks a
                    transformative era in the digital realm. However, this
                    digital interconnectedness also brings forth security
                    concerns. We delve into the paradox of IoT, where the
                    exchange of data and information can inadvertently create
                    avenues for security vulnerabilities.{" "}
                  </p>
                  <ul className="stroke-list">
                    <li>
                      <span></span>
                      <b>
                        Vulnerabilities in Security: The Weakest Link in IoT
                        Systems.
                      </b>
                      <p>
                        The widespread connection of IoT devices makes them
                        attractive targets for cybercriminals. Centralized
                        credential storage, software vulnerabilities, and weak
                        authentication heighten the risk to digital identity
                        security.
                      </p>
                    </li>
                    <li>
                      <span></span>
                      <b>
                        Centralized Access Control: A Potential Disaster in the
                        Making.
                      </b>
                      <p>
                        Conventional centralized access management systems find
                        it challenging to adapt to the scale and dynamics of the
                        IoT environment. The extensive volume of devices,
                        combined with frequent connections and disconnections,
                        intensifies the difficulty. Decentralized access
                        management frameworks, disseminating identity management
                        across multiple layers, emerge as a solution. This
                        approach not only enhances security and resilience but
                        also mitigates the shortcomings of conventional
                        centralized models.
                      </p>
                    </li>
                    <li>
                      <span></span>
                      <b>
                        Embracing the Future: The Advantages of Blockchain's
                        Digital Identity.
                      </b>
                      <p>
                        <em>Enhanced Security:</em>
                        Blockchain's unalterable nature fortifies digital
                        identity, thwarting unauthorized access and data
                        breaches.
                      </p>
                      <p>
                        <em>Amplified Privacy: </em>
                        Immutable records on the blockchain safeguard personal
                        information, curtailing exposure to intrusive
                        surveillance.
                      </p>
                      <p>
                        <em>User Empowerment:</em>
                        Individuals wield authority over their data, granting
                        them unprecedented control over its usage and sharing.{" "}
                      </p>
                      <p>
                        <em>Fraud Mitigation:</em>
                        Decentralization removes the reliance on intermediaries,
                        fostering genuine and secure user authentication.{" "}
                      </p>
                      <p>
                        <em>Decentralized Trust: </em>
                        Blockchain's unalterable nature fortifies digital
                        identity, thwarting unauthorized access and data
                        breaches.
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="news-first-section"></div>

            <div className="news-first-section">
              <div className="heading">
                <div className="detail-heading">
                  <h5>
                    <span>4</span>Destined for Security: The Future Shaped by
                    Blockchain-based Digital Identity
                  </h5>
                </div>
              </div>
              <div className="newsissuedetailed">
                <div className="newissuedetailed_banner">
                  <span className="banner-wall">
                    <img src="/assets/newsletter_images/ebook-notepad-clip.png" />
                  </span>
                  <p>
                    As we propel into an interconnected era, the integration of
                    blockchain-based digital identity into IoT devices is a
                    pivotal stride toward a secure and unified future.
                  </p>
                  <p>
                    Amidst evolving technology, blockchain-based digital
                    identity emerges as a scalable, decentralized remedy for
                    fortified IoT device security. In conclusion, the surging
                    presence of IoT devices demands a resilient security
                    framework against emergent threats.
                  </p>
                  <p>
                    Blockchain-based digital identity emerges as a
                    transformative solution, instilling trust, transparency, and
                    heightened security. By harnessing decentralized
                    architecture, blockchain-backed digital identities guarantee
                    secure authentication, seamless data sharing, and harmonious
                    interoperability. With industries embracing these emerging
                    technologies, the future of IoT security radiates optimism,
                    charting a course toward an interconnected world safeguarded
                    for all.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="final-section newsissuedetailed">
            <strong>
              That’s a wrap
              <br />
              <span>Hope you enjoyed it!</span> <br />
            </strong>
            <div className="checkout-interesting">
              <a href="https://blog.ionixxtech.com/">
                Check out our interesting Blogs
              </a>
            </div>
          </div>
          <div className="events-wrap">
            <strong>Events</strong>
            <div className="events-list">
              <div className="events">
                <img src="/assets/newsletter_images/issue_83_events.png" />
                <p>
                  Here’s team Ionixx at NFTNYC along with the awesome guys from
                  @badcryptopodcast.m{" "}
                </p>
              </div>
              <div className="events">
                <img src="/assets/newsletter_images/event-2.png" />
                <p>
                  Live from #HIMSS23 in Chicago: Ionixx's CMIO, Dr. Maanas
                  Samant, MD, is connecting with healthcare innovators from
                  around the world!
                </p>
              </div>
            </div>
          </div>
          <NewsSubscription categoryType="Web3" />
        </div>
      </>
    );
  }
}

export default NewsIssue84;
