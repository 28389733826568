import React from 'react';
import "../../resources.css";
import {ResourceRoute} from '../../resource-routing';
import {Link} from "react-router-dom";
import NewsHeadings from "./common-action/news-heading";
import NewsSubscription from "./common-action/subscripbe-section";

class NewsIssue61 extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            pageEnabled: false,
        }

    }

    render() {
        return (
          <div className="newdesigned_issue_details">
            <NewsHeadings
              buttondDetail="Web3 & Blockchain"
              categoryType="web3"
              description="Chrome 88 Is Rolling Out Now With A Heavy Focus On Security, Web App Features, And More | Chromeunboxed.com"
            />

            <div className="newsissuedetailed">
              <div className="detailed-common">
                {/* <div className="back-to-common">
                    <span className="back-to-icon-hold">
                        <img src="/assets/img/resource/back-to-new.svg"  alt="back to new"></img>
                    </span>
                    <Link  to={ResourceRoute.NEWSLETTER}><span className="back-to-link">Newsletter</span></Link>
                </div> */}

                <div className="news-letter whats-on-hold">
                  <div className="issue-main-head">
                    <h4>What’s on?</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.eurekalert.org/pub_releases/2021-01/tiot-btt011921.php"
                      >
                        <h4>
                          Blockchain technology to optimize P2P energy trading |
                          Eurekalert.com
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        A Tokyo Tech research team led by Specially Appointed
                        Professor Takuya Oda of the Institute of Innovative
                        Research and Professor Keisuke Tanaka of the School of
                        Computing, in collaboration with Mitsubishi Electric
                        Corporation, has developed a new technology an original
                        blockchain technology that can optimize peer-to-peer
                        (P2P) energy trading. The technology is expected to
                        contribute to more effective use of surplus electricity
                        from renewable energy by creating trading environments
                        that flexibly respond to shared trading needs,
                        particularly to maximize the amount of surplus
                        electricity available in the market at any given time.
                      </p>
                    </div>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://news.bitcoin.com/demand-crypto-soars-bitcoin-funds-records-goldman-sachs/"
                      >
                        <h4>
                          Demand for Crypto Soars: Bitcoin Funds Break Records,
                          Goldman Sachs Wants In | Bitcoin.com{" "}
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Investors are increasingly seeking exposure to bitcoin
                        following the recent months-long price rally. Bitcoin
                        exchange-traded products are seeing record trading
                        volumes. Meanwhile, more big banks are reportedly trying
                        to get into the crypto space, including Goldman Sachs.
                        With the price of bitcoin rapidly rising over recent
                        months, more investors are seeking exposure to the
                        cryptocurrency. The price of bitcoin rose about 24%
                        since the beginning of the year and over 90% since the
                        beginning of December.{" "}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="news-letter top-picks-hold">
                  <div className="issue-main-head">
                    <h4>Top Picks</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://chromeunboxed.com/whats-new-chrome-88"
                      >
                        <h4>
                          Chrome 88 Is Rolling Out Now With A Heavy Focus On
                          Security, Web App Features, And More |
                          Chromeunboxed.com
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Chrome 87 dropped back in November, and with it, a load
                        of improvements to the browser’s speed and battery. In
                        fact, it featured the largest jump in performance that
                        we’ve seen in Chrome for years! Now, Chrome 88 is
                        rolling out and has shifted gears to focus heavily on
                        security features, though it takes on several other
                        tasks as well. Let’s take a look at what you’ll receive
                        when you upgrade to Chrome 88.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="news-letter other-reads-hold">
                  <div className="issue-main-head">
                    <h4>Other Reads</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.cnet.com/news/biden-is-sworn-in-as-president-what-it-will-mean-for-tech/"
                      >
                        <h4>
                          Biden is sworn in as president: What it will mean for
                          tech | The Guardian
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        President Biden will have his hands full in coming days
                        as he wrestles with a slate of executive orders and an
                        ongoing pandemic -- but some of the most pressing
                        questions concern Big Tech. The pandemic has pushed
                        technology issues, including net neutrality, rural
                        broadband and online privacy, to the sideline. But the
                        violence at the Capitol -- fueled by disinformation
                        spreading on social media platforms like Facebook and
                        Twitter -- has many lawmakers looking at ways to rein in
                        the tech giants.{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <NewsSubscription categoryType="Web3" />
          </div>
        );
    }

}

export default NewsIssue61;
