import React from 'react';
import "../../resources.css";
import {ResourceRoute} from '../../resource-routing';
import {Link} from "react-router-dom";
import NewsHeadings from "./common-action/news-heading";
import NewsSubscription from "./common-action/subscripbe-section";

class NewsIssue69 extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            pageEnabled: false,
        }

    }

    render() {
        return (
          <div className="newdesigned_issue_details">
            <NewsHeadings
              buttondDetail="Design"
              categoryType="design"
              description="Why Amazon, Google, and Microsoft Are Designing Their Own Chips | Bloomberg.com"
            />

            <div className="newsissuedetailed">
              <div className="detailed-common">
                {/* <div className="back-to-common">
                    <span className="back-to-icon-hold">
                        <img src="/assets/img/resource/back-to-new.svg"  alt="back to new"></img>
                    </span>
                    <Link  to={ResourceRoute.NEWSLETTER}><span className="back-to-link">Newsletter</span></Link>
                </div> */}

                <div className="news-letter whats-on-hold">
                  <div className="issue-main-head">
                    <h4>What’s on?</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.techrepublic.com/article/enterprise-it-leaders-are-aggressively-moving-legacy-business-systems-to-the-cloud/"
                      >
                        <h4>
                          Enterprise IT leaders are aggressively moving legacy
                          business systems to the cloud | Tech Republic
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Some 77% of IT leaders said their primary motivation for
                        migrating legacy systems to cloud infrastructure was
                        either a desire to secure data, maintain data access or
                        save money, a new survey finds. Optimizing storage
                        resources and accelerating digital transformation were
                        other top reasons, according to the Lemongrass 2021
                        Legacy-to-Cloud survey.The study found that enterprises
                        are anxious to achieve the many business, technical and
                        financial benefits of moving legacy systems to the
                        cloud.
                      </p>
                    </div>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.pymnts.com/blockchain/2021/beyond-crypto-getting-back-to-the-basics-of-blockchain/"
                      >
                        <h4>
                          Beyond Crypto: Getting Back To The Basics Of
                          Blockchain | Pymnts.com
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Despite the hoopla surrounding blockchain-based
                        investments, the goal of the technology is quite simple:
                        to provide a secure record of transactions. Blockchains
                        are basically super-secure ledgers that store encrypted
                        sets of letters and numbers known as hashes in a chain
                        of other transactions. The transactions are validated by
                        all other computers involved with the chain, are
                        time-stamped, and are virtually incorruptible due to the
                        fact that the hashes from one block are contained in the
                        previous block, which is linked to the block before it,
                        and so on.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="news-letter top-picks-hold">
                  <div className="issue-main-head">
                    <h4>Top Picks</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.bloomberg.com/news/articles/2021-03-17/why-amazon-amzn-google-googl-microsoft-msft-are-designing-own-chips"
                      >
                        <h4>
                          Why Amazon, Google, and Microsoft Are Designing Their
                          Own Chips | Bloomberg.com{" "}
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        In the process of transforming itself from an online
                        bookstore into a cloud computing giant, Amazon.com Inc.
                        became one of the world’s largest purchasers of the
                        computer chips that power data centers. As its cloud
                        business has expanded, the company has become
                        increasingly fixated on designing its own chips instead
                        of buying them. The shift could have potentially drastic
                        implications for a critical aspect of the technology
                        industry—and could prove threatening for traditional
                        chipmakers such as Intel Corp. and Advanced Micro
                        Devices Inc.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="news-letter other-reads-hold">
                  <div className="issue-main-head">
                    <h4>Other Reads</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.bbc.com/news/world-us-canada-56405009"
                      >
                        <h4>
                          What is happening with migrant children at the
                          southern US border? | BBC World
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        The US is bracing for a 20-year high in numbers of
                        migrants arriving at the southern border, including
                        thousands of children who are being kept in
                        government-run detention facilities that critics say are
                        inhumane. A pandemic health order means that most adults
                        and families are being summarily turned away, but the
                        Biden administration has allowed unaccompanied children
                        under the age of 18 to enter the US while their claims
                        are processed.The record-breaking influx of children
                        being held in these camps has led US officials to send
                        in the Federal Emergency Management Agency - a
                        government organisation that normally deals with major
                        emergencies like natural disasters.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <NewsSubscription categoryType="Design" />
          </div>
        );
    }

}

export default NewsIssue69;
