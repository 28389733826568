import React, {Component} from "react";
import "../resources.css";
import WatchVideo from "../../../components/watch-video";
import { Link } from "react-router-dom";
import { SlideDown } from "react-slidedown";
import "react-slidedown/lib/slidedown.css";
import ReactSEOMetaTags from "react-seo-meta-tags";
import Helmet from "react-helmet";
import { VIDEO_LINK } from "../../../utilities/constants";
import ResourceTop from "../resource-top/resource-top";
import ScrollableTabsButtonAuto from "../../../components/common/dynamicTab/dynamicTab";
import DynamicTabs from "../../../components/common/dynamicTab/dynamicTab";
import { CATERGORY, EVENT_MOCKUP } from "../events-news/event-mockup";
import NewsLetter from "../internal-components/news/news-letter";
import { NEWS_LETTER } from "./newsletter-mock";
import { connect } from "react-redux";
import { withRouter} from "react-router-dom";
import { bindActionCreators } from "redux";
import * as contactAction from "../../../action/contactAction";
class NewsletterComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      headerClassAdd: "",
      showVideo: false,
      videoModalUrl: "",
      videoModalTitle: "",
      pageEnabled: false,
      buttonShow: true,
      start: 0,
      end: 3,
      cardList: NEWS_LETTER,
      paginatedCardList: [],
      filteredCardList: NEWS_LETTER,
      PER_PAGE: 12,
      pageCount: NEWS_LETTER.length
    };
  }
  viewMoreOnPress = () => {
    let arrLength = this.state.cardList.length;
    let remainder = this.state.cardList.slice(this.state.end, arrLength).length;
    this.setState(
      {
        buttonShow: !this.state.buttonShow,
      },
      () => {
        if (this.state.buttonShow) {
          this.setState({
            end: 3,
          });
          window.scrollTo(0, 850);
        } else {
          this.setState({
            end: this.state.end + remainder,
          });
        }
      }
    );
  };
  configureVideoModalState = (videoModalTitle, videoModalUrl) => {
    this.setState({ videoModalTitle, videoModalUrl, showVideo: true });
  };

debounce = (callback, delay) => {
    let timer;
    return (...args) => {
        clearInterval(timer);
        timer = setTimeout(() => callback(...args), delay);
    }
}

// hanldeDebounce = (searchValue) => {
//     if(searchValue) {
//         const result = [...this.state.cardList].filter(item => {
//             if(item.title.toLowerCase().includes(searchValue.toLowerCase())) {
//                 return item;
//             }
//         });
//         this.setState({ filteredCardList: result });
//     } else {
//         this.setState({ filteredCardList: this.state.cardList });
//     }
//     console.log('result', searchValue);
// };

// setDebounce = this.debounce(seachVal => this.hanldeDebounce(seachVal), 2000);

//   handleSearch = (searchValue) => {
//     this.setDebounce(searchValue);
//   }

  // handleSelect = (selectedValue) => {
  //   let result = [];
  //   if(selectedValue) {
  //       result = [...this.state.cardList].filter(item => {
  //           if(item.category.toLowerCase() === selectedValue.toLowerCase()) {
  //               return item;
  //           }
  //       });
  //       this.setState({ filteredCardList: result });
  //   } else {
  //       this.setState({ filteredCardList: this.state.cardList });
  //   }
  //   console.log('result', result);
  // }

  // handlePageChange = (begin, end) => {
  //   const result = [...this.state.cardList].slice(begin, end);
  //   this.setState({ paginatedCardList: result });
  // }

  handlePageChange = (begin, end, data = []) => {
    let result;
    if (data && data.length > 0) {
        result = [...data].slice(begin, end);
    } else {
        result = [...this.state.filteredCardList].slice(begin, end);
    }
    this.setState({ paginatedCardList: result });
  }

  handleChange = (data) => { // cat change
    console.log("🚀 ~ file: newsletter-component.js:119 ~ NewsletterComponent ~ data:", data)
    this.setState({ filteredCardList: data });
  }

  render() {
    return (
      <>
        <Helmet>
          <title>
            Events,Webinars,Workshops & Conferences for Ionixx Technologies{" "}
          </title>
          <meta
            name="description"
            content="Keep updated on our Events, Webinars, Workshops, Conferences and other useful resources. Ionixx organizes various activities for easy participation and access to all information in one place."
          />
          <link
            rel="canonical"
            href="https://www.ionixxtech.com/resources/events"
          />
        </Helmet>
        <ReactSEOMetaTags
          render={(el) => <Helmet>{el}</Helmet>}
          website={{
            name: "Ionixx Technologies",
            title:
              "Events,Webinars,Workshops & Conferences for Ionixx Technologies",
            description:
              "Keep updated on our Events, Webinars, Workshops, Conferences and other useful resources. Ionixx organizes various activities for easy participation and access to all information in one place. ",
            type: "website",
            image: "/assets/img/ionixx-logo.png",
            url: "https://www.ionixxtech.com/resources/events",
          }}
          twitter={{
            twitter: "https://www.ionixxtech.com/resources/events",
          }}
        />
        <div className="resources-wrapper Events">
          <div className="events-wrapper Resources">
            <ResourceTop />
            {/* <div className="events-inner">
              <p>
                Lorem Ipsum has evolved as a filler text for prototyping in the
                English language. As it is written in a Latin looking language
                that has no meaning, it does not distract from analysing the
                layout of the page.{" "}
              </p>
              <div className="events-top-wrap">
                <div className="categories">
                  
                </div>
              </div>
            </div> */}
            <div className="section-2">
            {/* {console.log('filteredCardList', this.state.filteredCardList)} */}
            <DynamicTabs
                    tabData={[
                      {
                        label: "Featured Articles",
                        content: <NewsLetter cardData={this.state.paginatedCardList} filterData={this.state.cardList} onChange={this.handleChange}/>
                      },
                    ]}
                    onSearch={() => {}}
                    catOptions={null}
                    onSelect={() => {}}
                    pageCount={this.state.filteredCardList.length}
                    onPageChange={this.handlePageChange}
                    onTabChange={() => {}}
                    perPage={this.state.PER_PAGE}
                    selectedTab={0}
                    isCatEnable={false}
                    isSearchEnable={false}
                  ></DynamicTabs>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(contactAction, dispatch);
}
export default connect(null, mapDispatchToProps)(withRouter(NewsletterComponent));
