import React from 'react';
//import Header from "../../components/header/header";
//import Testimonials from '../../components/testimonials/testimonials';
// import "./market-place.css";
//import StepperProgressBar from "../../components/step-progress";
import "./design.css"
import {} from 'react-bootstrap'
import ScrollableContainer from './component'
//import Testing from './component/testing'
import WorkWithUs from './work-with-us'
import { animateScroll as scroll } from 'react-scroll'
import ContactUS from "../../components/contact-us";
// import { ToastComponent } from '../../components/common';
//import ReactSEOMetaTags from 'react-seo-meta-tags';
import {Helmet} from 'react-helmet';
import * as contactAction from "../../action/contactAction";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
//import SEO from "../../components/common/seo/seo";
import {withRouter} from 'react-router';
import {Link} from "react-router-dom";
import {ROUTES} from "../../routes";
class MarketPlace extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            page: 0,
            scrollStatus: '',
            showContactUS: false,
            showToast: false
        }
        this._timeout = null;
    }

    componentDidMount() {
        // factors
        if(window.factors) {
            window.factors.addUserProperties({'visited_design_page': 'yes'});
        }
        window.addEventListener('scroll', this.onScroll);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.onScroll);
    }

    onScroll = (event) => {
        if (this._timeout) { //if there is already a timeout in process cancel it
            clearTimeout(this._timeout);
        }
        this._timeout = setTimeout(() => {
            this._timeout = null;
            this.setState({
                scrollStatus: 'scroll stopped'
            },()=>{
                // this.checkCurrentElement()
            });
        }, 50);
        if (this.state.scrollStatus !== 'scrolling') {
            this.setState({
                scrollStatus: 'scrolling'
            });
        }
        // this.checkCurrentElement()
    }


    checkCurrentElement = () => {
        // designSprints
        let discovery = document.getElementById('discovery')
        let designSprints = document.getElementById('designSprints')
        let userDeepDive = document.getElementById('userDeepDive')
        let testing = document.getElementById('testing')

        let isDiscovery = this.isScrolledIntoView(discovery)
        let isDesignSprints = this.isScrolledIntoView(designSprints)
        let isUserDeepDive = this.isScrolledIntoView(userDeepDive)
        let isTesting = this.isScrolledIntoView(testing)

        if (isDiscovery) {
            this.setState({page: 0})
            this.scrollToElement(discovery)
        } else if (isUserDeepDive) {
            this.setState({page: 1})
            this.scrollToElement(userDeepDive)
        } else if (isDesignSprints) {
            this.setState({page: 2})
            this.scrollToElement(designSprints)
        } else if (isTesting) {
            this.setState({page: 3})
            this.scrollToElement(testing)
        }else{
            this.setState({page: 4})
        }
    }

    scrollToElement = (element) => {
        if (this.state.scrollStatus === 'scroll stopped' && window.innerWidth > 425) {
            scroll.scrollTo(element.offsetTop - (document.documentElement.clientHeight / 18), {duration: 500})
            // window.scrollTo(0, element.offsetTop - (document.documentElement.clientHeight / 3));
        }
    }


    isScrolledIntoView = (el) => {
        if (!el) {
            return;
        }
        if (document.documentElement.scrollTop === 0) { //In safari scrolling to top makes it to discovery automatically.
            return;
        }
        var optionalOffset = -el.clientHeight / 2;
        var docViewTop = document.documentElement.scrollTop + optionalOffset;
        var docViewBottom = docViewTop + document.documentElement.scrollHeight

        var elemTop = el.offsetTop;
        var elemBottom = elemTop + el.clientHeight;
        return ((elemBottom <= docViewBottom) && (elemTop >= docViewTop))

    }
    onClickProgress = (index) => {
        let discovery = document.getElementById('discovery')
        let userDeepDive = document.getElementById('userDeepDive')
        let designSprints = document.getElementById('designSprints')
        let testing = document.getElementById('testing')

        let currentElement = null
        if (index === 0) {
            currentElement = discovery
        }
        if (index === 1) {
            currentElement = userDeepDive
        }
        if (index === 2) {
            currentElement = designSprints
        }
        if (index === 3) {
            currentElement = testing
        }
        if (currentElement != null) {
            scroll.scrollTo(currentElement.offsetTop - (document.documentElement.clientHeight / 18))
            // window.scrollTo(0, currentElement.offsetTop - (document.documentElement.clientHeight / 3));
        }

    }
    sendToast(){
		this.setState({showContactUS:true})
	}
    render() {
        const metaTitle = "Ionixx's User-Centric UI/UX Design Services & Solutions";
        const metaDescription = "Ionixx Technologies provides UI/UX design services to help you create user-centered designs that enhance user experience, increase engagement, and drive conversions. Boost your business with stunning design solutions."
        return (
            <div>
            {/* <SEO 
                title={metaTitle}
                description={metaDescription}
                pathname={this.props?.location?.pathname}
                image={"/assets/img/ionixx-logo.png"}
            /> */}
            {/* <Helmet defer={false}>
                <meta charSet="utf-8" />
                <meta
                    name="viewport"
                    content="width=device-width,initial-scale=1,shrink-to-fit=no,viewport-fit=cover"
                />
                <title>Ionixx's User-Centric UI/UX Design Services & Solutions</title>
                <meta name="description" content="Ionixx Technologies provides UI/UX design services to help you create user-centered designs that enhance user experience, increase engagement, and drive conversions. Boost your business with stunning design solutions." />
                <link rel="canonical" href="https://www.ionixxtech.com/ui-ux-design-services-company" />
                <meta property="og:title" content={metaTitle} />
                <meta property="og:type" content={`website`} />
                <meta property="og:url" content={"https://www.ionixxtech.com/ui-ux-design-services-company"} />
                <meta property="og:description" content={metaDescription} />
            </Helmet> */}
            {/* <ReactSEOMetaTags
                render={(el) => <Helmet>{el}</Helmet>}
                website={{ 
                    name: "Ionixx Technologies", 
                    title:"UI UX Design & Consulting Services | Web and Mobile application design | Ionixx Technologies", 
                    description: "Ionixx Technologies provides UI/UX design services to help you create user-centered designs that enhance user experience, increase engagement, and drive conversions. Boost your business with stunning design solutions.",
                    type:"website",
                    image:'/assets/img/ionixx-logo.png',
                    url:"https://www.ionixxtech.com/ui-ux-design-services-company"
                }}
                twitter={{ 
                    twitter: "https://www.ionixxtech.com/ui-ux-design-services-company" }}
                /> */}
            <div className="Design-content" onScroll={this.onScroll}>
                {/*  */}
                <section class="section-1 design-page">
                    <div class="custom-container">
                        <div class="custom-section">
                            <div class="main-head-div">
                            <h4 class="main-head-1">
                                    <span class="secondary-span">At Ionixx, we believe</span>
                                </h4>
                                <h1 class="main-head-1">
                                    <span class="primary-span">design is a mindset.</span>
                                </h1>
                            </div>
                            <div class="main-para-div short-div">
                                <p>In a world where rapid change and disruption are the new normal, we are committed to
                                    designing products that meet user needs today and into the future. </p>
                                <p>Our senior designers bring 10+ years of industry experience to each project,
                                    supporting you at every step of our robust design process. </p>
                                <p>We’ve built a collaborative culture that combines your team’s market expertise with
                                    thoughtful design to create exceptional digital experiences.</p>
                            </div>
                            <div  className="main-para-div">
                            <Link to={ROUTES.CONTACTUS} className="btn btn-primary connect-btn">Talk to us</Link>
                            </div>
                        </div>
                    </div>
                </section>
                <ScrollableContainer page={this.state.page} onClickProgress={this.onClickProgress} />
                <WorkWithUs/>

                <div className="section-5 blue-bg">
                    <div className="custom-container">
                        <div className="custom-section connect-with-custom-section">
                            <div className="main-head-div">
                                <h4 className="main-head-1">
                                <span className="secondary-span">Let's team up to</span>
                                    <span className="primary-span">build your product</span>
                                </h4>
                            </div>
                            <div className="main-para-div">
                            <Link to={ROUTES.CONTACTUS} className="btn btn-primary connect-btn">Talk to us</Link>
                            
                            </div>
                        </div>
                    </div>
                </div>
                <ContactUS show={this.state.showContactUS} handleClose={()=>this.setState({showContactUS:false})} />
            </div>
            </div>       
        );
    }

}


  const mapStateToProps = (state, ownProps) => {
    return state.commonReducer;
  };
  
  const mapDispatchToProps = (dispatch, ownProps) => {
    return bindActionCreators(contactAction, dispatch);
  };
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(withRouter(MarketPlace));

  
