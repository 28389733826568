import React from 'react';
import "../../resources.css";
import { ResourceRoute } from '../../resource-routing';
import { Link } from "react-router-dom";
import NewsHeadings from './common-action/news-heading';
import NewsSubscription from "./common-action/subscripbe-section";

class NewsIssue01 extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            pageEnabled: false,
        }

    }

    render() {
        return (
          <div className="newdesigned_issue_details">
            <NewsHeadings
              buttondDetail="Design"
              categoryType="design"
              description="Q&A: Dark Design — the good, the bad and the very attractive | Digital Journal"
            />
            <div className="newsissuedetailed">
              <div className="detailed-common">
                {/* <div className="back-to-common">
                            <span className="back-to-icon-hold">
                                <img src="/assets/img/resource/back-to-new.svg" alt="bact to new"></img>
                            </span>
                            <Link to={ResourceRoute.NEWSLETTER}><span className="back-to-link">Newsletter</span></Link>
                        </div> */}

                <div className="news-letter whats-on-hold">
                  <div className="issue-main-head">
                    <h4>What’s on?</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        href="https://www.usnews.com/news/best-countries/articles/2019-11-05/2020-will-bring-more-data-more-ai-and-more-regulation"
                        target={"_blank"}
                      >
                        <h4>More Data and AI in 2020 | US News </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        THE UPCOMING YEAR WILL witness yet more change in
                        technology, according to a newly released report, with
                        advancements in data investment and literacy, automation
                        and artificial intelligence, as well as increased
                        regulation around the world. The overriding focus in
                        2020 will be the connection between energy consumption
                        and values, say analysts from Forrester, a market
                        research company in Cambridge, Mass.
                      </p>
                    </div>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        href="https://www.coindesk.com/crypto-and-blockchain-jobs-have-increased-by-26-since-2018-indeed"
                        target={"_blank"}
                      >
                        <h4>
                          Crypto and Blockchain Jobs Have Increased By 26% Since
                          2018: Research | Coindesk{" "}
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        The number of bitcoin, blockchain and crypto-related
                        employment ads in shares per million on the popular job
                        listing site rose by 26 percent from 2018–2019,
                        following a four-year trend of 1,457 percent growth in
                        the sector, according to a “Seen by Indeed” study. The
                        top 5 employers listing blockchain jobs are Deloitte,
                        IBM, Accenture, Cisco and Collins Aerospace, coming in
                        1st through 5th, respectively. “Big Four” firm Ernst &
                        Young joins Deloitte in the top 10.
                      </p>
                    </div>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        href="https://www.theblockcrypto.com/linked/46597/singapores-central-bank-jpmorgan-develop-a-blockchain-system-for-cross-border-payments"
                        target={"_blank"}
                      >
                        <h4>
                          JPMorgan and Singapore’s central bank develop a
                          blockchain system for cross-border payments | The
                          Block Crypto{" "}
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        The Monetary Authority of Singapore (MAS), the country’s
                        central bank, and investment banking giant JPMorgan have
                        developed a blockchain prototype for cross-border
                        payments. Announcing the news, MAS said that the
                        prototype enables payments to be carried out in
                        different currencies on the same network.
                      </p>
                    </div>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        href="https://www.theverge.com/2019/11/11/20959865/google-chrome-slow-sites-badge-system-chrome-dev-summit-2019"
                        target={"_blank"}
                      >
                        <h4>
                          Google plans to give slow websites a new badge of
                          shame in Chrome | The Verge{" "}
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Google is experimenting with a badge of shame for
                        websites that load slowly in Chrome. “In the future,
                        Chrome may identify sites that typically load fast or
                        slow for users with clear badging,” explains a blog post
                        from the Chrome team. “This may take a number of forms
                        and we plan to experiment with different options, to
                        determine which provides the most value to our users.”{" "}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="news-letter top-picks-hold">
                  <div className="issue-main-head">
                    <h4>Top Picks</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        href="http://www.digitaljournal.com/tech-and-science/technology/q-a-dark-design-the-good-the-bad-and-the-very-attractive/article/561484"
                        target={"_blank"}
                      >
                        <h4>
                          Q&A: Dark Design — the good, the bad and the very
                          attractive | Digital Journal{" "}
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Dark pattern design is becoming more common, influencing
                        the user experience promoted by many companies. Bhav
                        Chohan explains the good and bad points about this
                        approach, plus some notorious examples of UX. “Dark
                        pattern design” is the practice of using software design
                        to influence the behavior of users. The practice is
                        becoming so large that the U.S. Senate is planning to
                        pass a bill to control its use, which can lead to
                        addiction in some extreme cases
                      </p>
                    </div>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        href="https://www.nytimes.com/2019/11/11/technology/artificial-intelligence-bias.html"
                        target={"_blank"}
                      >
                        <h4>
                          We Teach A.I. Systems Everything, Including Our Biases
                          | New York Times{" "}
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Researchers say computer systems are learning from lots
                        and lots of digitized books and news articles that could
                        bake old attitudes into new technology. Last fall,
                        Google unveiled a breakthrough artificial intelligence
                        technology called BERT that changed the way scientists
                        build systems that learn how people write and talk. But
                        BERT, which is now being deployed in services like
                        Google’s internet search engine, has a problem: It could
                        be picking up on biases in the way a child mimics the
                        bad behavior of his parents.
                      </p>
                    </div>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        href="https://www.business.com/articles/progressive-web-apps/"
                        target={"_blank"}
                      >
                        <h4>
                          How Progressive Apps are changing businesses |
                          Business.com{" "}
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Technology progresses quickly, and now there is a new
                        way to engage with your website visitors in a more
                        effective and engaging way, which is through the use of
                        progressive web apps. First announced by Google four
                        years ago, the format has already been adopted by many
                        big brands, including Twitter, Forbes, and Housing.com.
                        However, thanks to the maturity of the tech required for
                        their creation, PWAs are now becoming accessible to all,
                        including small businesses – even those who could not
                        have afforded to have their own app developed from
                        scratch, which is a big part of their appeal.{" "}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="news-letter other-reads-hold">
                  <div className="issue-main-head">
                    <h4>Other Reads</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        href="https://www.theglobeandmail.com/world/article-bolivia-evo-morales-exit-election-explainer/"
                        target={"_blank"}
                      >
                        <h4>
                          What’s going on in Bolivia? A guide to Morales’s exit,
                          who’s in charge and what happens now | The Globe and
                          Mail{" "}
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Bolivia is in a volatile era of political uncertainty as
                        President Evo Morales, facing weeks of massive protests,
                        resigned after nearly 14 years in power and fled the
                        country with Mexico’s help. To opposition parties and
                        the Bolivians who celebrated in the streets Sunday
                        night, his exit is a triumph against alleged electoral
                        fraud; but Mr. Morales, his supporters and allies in
                        other Latin American nations have denounced it as a
                        disguised coup brought about under military pressure.{" "}
                      </p>
                    </div>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        href="https://www.wired.com/story/opinion-china-is-pushing-toward-global-blockchain-dominance/"
                        target={"_blank"}
                      >
                        <h4>
                          Opinion: China is Pushing Toward Global Blockchain
                          Dominance | Wired{" "}
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Kevin Werbach, professor of legal studies and business
                        ethics at the Wharton School, University of
                        Pennsylvania, opines on Chinese leader Xi Jinping
                        declaring blockchain “an important breakthrough,” and
                        promising that China would “seize the opportunity.” He
                        writes in detail on why the significance by the Chinese
                        government shouldn’t be underestimated, as Xi is the
                        first world leader to issue such a strong endorsement of
                        the technology.{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <NewsSubscription categoryType="Design"/>
          </div>
        );
    }

}

export default NewsIssue01;
