import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import "./dynamicTab.css";
import "../tab/tab.css";
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Pagination from '@material-ui/lab/Pagination';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      className='tab-content'
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

export default function DynamicTabs({
  tabData = [],
  onSearch,
  catOptions,
  onSelect,
  pageCount,
  onPageChange,
  onTabChange,
  perPage,
  selectedTab,
  isCatEnable = true,
  isSearchEnable = true
}) {
  // console.log("🚀 ~ file: dynamicTab.js:51 ~ DynamicTabs ~ pageCount:", pageCount)
  // const classes = useStyles();
  const [value, setValue] = React.useState(0);
  const [search, setSearchValue] = React.useState(null);
  const [category, setCategory] = React.useState("");
  const PER_PAGE = perPage;
  const count = Math.ceil(pageCount / PER_PAGE);
  // pagination
  let [page, setPage] = React.useState(1);
  const [currentPage, setCurrentPage] = React.useState(1);
  const maxPage = Math.ceil(pageCount / PER_PAGE);
  // dropdown toggle
  const menuRef = useRef(null);
  const [toggle, setToggle] = React.useState(false);

  useEffect(() => {
      if(document.getElementById('cat-menu')){
          function handleOutsideClick(e){
              if (menuRef.current && !menuRef.current.contains(e.target)) {
                setToggle(false);
              }
          }
          document.addEventListener("click" , handleOutsideClick);
      }
  },[])

  useEffect(() => {
    const begin = (currentPage - 1) * PER_PAGE;
    const end = begin + PER_PAGE;
    onPageChange(begin, end);
  }, [currentPage, PER_PAGE, selectedTab, pageCount]);

  useEffect(() => {
    // reset filters
    setCategory("");
    setSearchValue("");
    if (onSelect) {
      onSelect("");
    }
    if (onSearch) {
      onSearch("");
    }
  }, [selectedTab]);

  useEffect(() => {
    if (page > 1) {
      handlePageChange(null, 1);
    }
  }, [selectedTab, pageCount])

  function jump(page) {
    const pageNumber = Math.max(1, page);
    setCurrentPage((currentPage) => Math.min(pageNumber, maxPage));
  }

  const handlePageChange = (e, p) => {
    setPage(p);
    jump(p);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
    onTabChange(newValue);
  };

  // const handleSearch = (event) => {
  //   setSearchValue(event.target.value);
  // };

  const handleSelect = (value) => {
    setCategory(value);
    onSelect(value);
    setPage(1);
    setCurrentPage(1);
  };

  const toggleDropdown = () => {
    setToggle(value => !value);
  }

  useEffect(() => {
    if (search === null) return;
    setPage(1);
    onSearch(search);
  }, [search]);

  return (
    <>
      <div className="resources-tab-view">
        <div className={"resource-tab-wrapper"}>
          {isCatEnable && <FormControl>
            {/* <Select
          labelId="demo-simple-select-placeholder-label-label"
          id="demo-simple-select-placeholder-label"
          value={category}
          onChange={handleSelect}
          displayEmpty
        >
          <MenuItem value="">
            <em>Categories</em>
          </MenuItem>
          {catOptions.map((item) => (
            <MenuItem key={item} value={item}>{item}</MenuItem>
          ))}
        </Select> */}
            {/* <FormHelperText>Label + placeholder</FormHelperText> */}
            <div 
            id="cat-menu"
            className="category-list"
            ref={menuRef}
            onClick={toggleDropdown}>
              <span>{category ? category: 'Categories'}</span>
              {toggle && <ul>
                  <li onClick={() => handleSelect("")}>All</li>
                  {catOptions.map((item) => (
                    <li className={(item === category) ? 'selected-option': ''} key={item} value={item} onClick={() => handleSelect(item)}>{item}</li>
                  ))}
              </ul>}
            </div>
          </FormControl>}
          <AppBar position="static" color="default">
            <Tabs
              value={selectedTab}
              onChange={handleChange}
              indicatorColor="primary"
              textColor="primary"
              variant="scrollable"
              scrollButtons="auto"
              aria-label="scrollable auto tabs example"
            >
              {tabData.map((tab, index) => (
                <Tab
                  key={tab.label + index}
                  label={tab.label}
                  {...a11yProps(index)}
                />
              ))}
            </Tabs>
          </AppBar>
          {tabData.map((tab, index) => (
            <TabPanel
              key={tab.label + index + "content"}
              value={value}
              index={index}
            >
              {tab.content}
            </TabPanel>
          ))}
        </div>

        {isSearchEnable && <div className="search-box">
          {/* <label>Search</label> */}
          <input
            value={search}
            type="text"
            placeholder="Search here"
            onChange={(e) => {
              setSearchValue(e.target.value);
            }}
          />
          <i>
            <img src={"/assets/img/events/search-icon.svg"} />
          </i>
        </div>}
      </div>
      {count ? (
        <Pagination
          page={page}
          count={count}
          color="primary"
          onChange={handlePageChange}
        />
      ) : <div className='no-card-record'>
        <b><img src='/assets/img/resource/search.png'/></b>
        We couldn't find anything for <strong>"{search || category}"</strong>
        <em>Try different or less specific keywords.</em>
        </div>}
    </>
  );
}
