import React, {useState} from "react";
import { ROUTES } from '../../routes';
import * as contactAction from '../../action/contactAction';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from "react-router-dom";

const ContactForm = (props) => {
    // console.log(props, "props")
    const [error, setError] = useState("");
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        phoneNumber: "",
        message: ""
    });

    const [formErrorData, setFormErrorData] = useState({});
    const [selectedValue, setSelectedValue] = useState('');

    const handleSelect = (e) => {
        setSelectedValue(e.target.value);
    }
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value.replace(/^\s+/, "")
        });
    };

    async function handleSubmit(e){
        e.preventDefault();
        // setValidationErr(state => {
        //     return { ...state, submitted: true }
        //   });
        const newErrors = {};
        if (!formData.name) newErrors.name = 'Name is required.';
        if (!formData.email || !/\S+@\S+\.\S+/.test(formData.email)) newErrors.email = 'Email is invalid.';
        if (formData.email === '') newErrors.email = 'Email is required.';

        // if (formData.password.length < 6) newErrors.password = 'Password must be at least 6 characters long.';
    
        setFormErrorData(newErrors);
    
        // Check if there are any errors
        if (Object.keys(newErrors).length === 0) {
          // No errors, submit the form
        //   console.log('Form data:', formData);
          // Here you would handle the form submission, e.g., sending data to a server
        }
          const requestPayload = {
            emailID: formData.email,
            name: formData.name,
            mobileNumber: selectedValue || 'Service',
            message: formData.message,
            // discussionTopic: conversation.hearAboutUs,
            // others: conversation.others,
            to: "info@ionixxtech.com , praveenkumar.ganapathi@ionixxtech.com",
            // isMob: true
          };
        //   console.log('requestPayload', requestPayload);
          try {
            const response = await props.sendEmail(requestPayload);
            if (response.status === 200) {
                // alert("test");
              props.history.push({
                pathname: ROUTES.TOAST,
                // contactDetails: true,
                // pdfDownload: false,
                // subscribeDetails: false
              });
              setFormData({
                name: "",
                email: "",
                phoneNumber: "",
                message: ""
              }, () => {
                props.history.push(ROUTES.TOAST)
              })
      
            }
          }
          catch (err) {
            console.log(err);
          }
        }
    return(
        <>
        <form >
                                        <div className="form-group">
                                            <input type="text" name="name" value={formData.name} onChange={handleChange} className={`input ${formData.name ? 'filled' : ''}`} placeholder={props.showService ? "" : "Please enter your full name"}/>
                                            <label className="label">Name{props.showService ? "" : "*"}</label>
                                            {formErrorData.name && <p className="error">{formErrorData.name}</p>}
                                        </div>
                                        <div className="form-group">
                                            <input type="email" name="email" value={formData.email} onChange={handleChange} className={`input ${formData.email ? 'filled' : ''}`} placeholder={props.showService ? "" : "Please provide your business email address"}/>
                                            <label className="label">Email{props.showService ? "" : "*"}</label>
                                            {formErrorData.email && <p className="error">{formErrorData.email}</p>}
                                        </div>
                                        {props?.showService && <div className="form-group">
                                            <select className="input select-box" value={selectedValue} onChange={handleSelect}>
                                                <option>Service</option>
                                                <option value={"User Experience"}>User Experience</option>
                                                <option value={"Productization"}>Productization</option>
                                                <option value={"Service Design & Customer Experience"}>Service Design & Customer Experience</option>
                                                <option value={"Design Workshops"}>Design Workshops</option>
                                                <option value={"Others"}>Others</option>
                                            </select>
                                            {/* <input type="tel" name="phoneNumber" value={formData.phoneNumber} onChange={handleChange} className={`input ${formData.phoneNumber ? 'filled' : ''}`} /> */}
                                            {/* <label className="label">Phone Number</label> */}
                                        </div>}
                                        
                                        <div className="form-group">
                                            <textarea name="message" value={formData.message} onChange={handleChange} className={`input ${formData.message ? 'filled' : ''}`} placeholder={props.showService ? "" : "Please provide additional information so we can better respond to your request"}/>
                                            <label className="label">Message{props.showService ? "" : "*"}</label>
                                        </div>
                                        <div className="form-group">
                                            <button type="button" onClick={handleSubmit}>{props.showService ? "Send" : "Submit"}</button>
                                        </div>
                                        {error && <p className="error-message">{error}</p>}
                                    </form>
        </>
    )
}

const mapDispatchToProps = (dispatch) => {
    return bindActionCreators(contactAction, dispatch);
  }
export default connect(null, mapDispatchToProps)(withRouter(ContactForm));
// export default ContactForm;
