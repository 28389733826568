import React from 'react';
import "../../resources.css";
import {ResourceRoute} from '../../resource-routing';
import {Link} from "react-router-dom";

class OctoberFortnight2 extends React.Component {
    constructor(props){
        super(props)

    }
    render(){
        return (
            <div className="BlockchainDetailed">
                                                <div className="back-to-common">
                    <span className="back-to-icon-hold">
                        <img src="/assets/img/resource/back-to-new.svg"  alt="bact to new"></img>
                    </span>
                    <Link  to={ResourceRoute.BLOCKCHAIN}><span className="back-to-link">Blockchain Bulletin</span></Link>
                </div>
                <div className="download-common inner-section-1">
                    <div>
                    {/* <div className="col-xl-7 col-lg-7 col-md-7 col-sm-12"> */}
                        <div className="main-head-div">
                            <h4 className="main-head-1">
                                <span className="primary-span">October Fortnight 2 Round-up Blockchain & Crypto Brief</span>
                            </h4>
                            <p className="press-info">( October 31, 2019 )</p>
                        </div>
                    </div>    
                </div>
                <div class="bulletin-head-details bulletin-sub-heads">
                    <h4>
                        <span className="primary-span">Finance</span>
                    </h4>
                    <ul className="bulletin-links-ul">
                        <li>
                            <a href="https://www.coindesk.com/bermuda-now-accepts-usdc-crypto-for-taxes-and-government-services" target={'_blank'}>Bermuda has become the first national government to accept taxes and payments for other government services using the USDC stablecoin.</a>
                        </li>
                        <li>
                            <a href="https://finance.yahoo.com/news/defi-project-kava-looking-raise-111135519.html" target={'_blank'}>DeFi project Kava Labs is looking to raise $3 million via there initial exchange offering (IEO) opened on Oct. 23 on Binance Launchpad.</a>
                        </li>
                        <li>
                            <a href="https://finance.yahoo.com/news/venezuelas-petro-cryptocurrency-now-accepted-204955527.html" target={'_blank'}>Venezuela's state-backed cryptocurrency, PetroPago, is now slowly being adopted as a means of payment by merchants and retail stores across the country.</a>
                        </li>
                        <li>
                            <a href="https://www.theblockcrypto.com/post/43564/binance-just-crossed-1-billion-in-cumulative-profit" target={'_blank'}>Binance has crossed the $1 billion mark in cumulative profits, with a profit of $183.5 million in Q3 of 2019 alone.</a>
                        </li>
                        <li>
                            <a href="https://www.theblockcrypto.com/linked/43575/bitfinex-launches-a-loyalty-program-offering-users-to-earn-unlimited-commissions" target={'_blank'}>Bitfinex has launched the Bitfinex Affiliated Program, which offers users to earn unlimited commission for up to three degrees of connection.</a>
                        </li>
                        <li>
                            <a href="https://www.theblockcrypto.com/linked/43750/huobi-launching-fiat-to-crypto-gateway-in-turkey-later-this-year" target={'_blank'}>Huobi Global will soon allow Turkish residents to buy cryptocurrencies with their local fiat currency, the Turkish Iira.</a>
                        </li>
                        <li>
                            <a href="https://www.theblockcrypto.com/linked/43756/bitcoin-lightning-startup-acinq-launches-a-wallet-for-faster-payments-under-3-minutes" target={'_blank'}>Bitcoin Lightning startup ACINQ has launched Phoenix, a 2nd-generation Lightning Wallet, for faster payments under 3-minutes.</a>
                        </li>
                        <li>
                            <a href="https://www.coindesk.com/opera-browser-adds-bitcoin-payments-to-android-update" target={'_blank'}>The Opera Internet browser now supports Bitcoin payments post its latest Android update.</a>
                        </li>
                        <li>
                            <a href="https://blockonomi.com/11-blockchain-worth-over-1-billion/" target={'_blank'}>At least 11 cryptocurrency companies are unicorns now with over $1billion valuation, including Binance, Ripples, and Coinbase.</a>
                        </li>
                        <li>
                            <a href="https://finance.yahoo.com/news/binance-com-add-fiat-pairs-100020308.html" target={'_blank'}>Binance is going to add fiat-pairs starting with Russian rubles in about a week, which they will provide by using third-party.</a>
                        </li>
                        <li>
                            <a href="https://cryptonews.com/exclusives/alec-baldwin-hired-by-etoro-but-baldwins-are-not-new-to-cryp-4965.html" target={'_blank'}>Israel-based trading platform, eToro, has launched its CopyTrader feature for cryptocurrency traders in the U.S., which will allow them to automatically copy all the trades of top cryptocurrency traders on its platform. It has hired actor Alec Baldwin to promote it.</a>
                        </li>
                    </ul>
                </div>
                <div class="bulletin-head-details bulletin-sub-heads">
                    <h4>
                        <span className="primary-span">Law</span>
                    </h4>
                    <ul className="bulletin-links-ul">
                        <li>
                            <a href="https://finance.yahoo.com/news/cftc-chairman-commodity-become-security-205207896.html" target={'_blank'}>The CFTC chairman Heath Tarbert has confirmed that a security can be turned into a commodity and vice versa.</a>
                        </li>
                        <li>
                            <a href="https://cointelegraph.com/news/us-congress-considers-a-draft-bill-claiming-stablecoins-are-securities" target={'_blank'}>Rep. Sylvia Garcia has proposed to the House Financial Services Committee to regulate stablecions under the Securities Act of 1933 to classify them as securities in a draft bill.</a>
                        </li>
                    </ul>
                </div>
                <div class="bulletin-head-details bulletin-sub-heads">
                    <h4>
                        <span className="primary-span">Other</span>
                    </h4>
                    <ul className="bulletin-links-ul">
                        <li>
                            <a href="https://news.bitcoin.com/bitcoins-smallest-unit-satoshi-added-to-oxford-english-dictionary/" target={'_blank'}>Satoshi, Bitcoin's smallest unit, now has now been added to the Oxford English Dictionary as The smallest monetary unit in the Bitcoin digital payment system, equal to one hundred millionth of a bitcoin.</a>
                        </li>
                        <li>
                            <a href="https://www.cnbc.com/2019/10/15/facebook-libra-cryptocurrency-a-catalyst-for-reform-swedens-riksbank.html" target={'_blank'}>Swedish central bank chief, Stefan Ingves, says that Facebook's Libra is an incredibly important catalytic events for reform.</a>
                        </li>
                        <li>
                            <a href="https://www.technologyreview.com/f/614578/bitcoin-surveillance-helped-feds-take-down-a-massive-child-porn-site/" target={'_blank'}>Bitcoin surveillance helped US federal prosecutors take down a massive child sexual abuse site called Welcome To Video and indict 23-year-old Jong Woo Son of South Korea for operating it.</a>
                        </li>
                        <li>
                            <a href="https://www.coindesk.com/ciphertraces-blockchain-forensics-service-now-covers-700-crypto-assets" target={'_blank'}>CipherTrace's blockchain forensic services now allow users to search the transactions of over 700 cryptocurrencies.</a>
                        </li>
                        <li>
                            <a href="https://www.theblockcrypto.com/linked/43591/owner-of-burj-khalifa-worlds-tallest-building-launching-its-native-token-on-jpmorgans-blockchain" target={'_blank'}>Emaar Group, the owner of Burj Khalifa, is launching its native token EMR later this year on JPMorgan's blockchain.</a>
                        </li>
                        <li>
                            <a href="https://www.coindesk.com/htcs-latest-blockchain-phone-can-run-a-full-bitcoin-node" target={'_blank'}>HTC, the Taiwanese electronics company, is going to release The EXODUS 1s, a new smartphone that will have full node capabilities for the Bitcoin blockchain to allow users to verify transactions through their device.</a>
                        </li>
                        <li>
                            <a href="https://www.theblockcrypto.com/linked/44119/crypto-exchange-bittrex-moving-headquarters-from-malta-to-liechtenstein" target={'_blank'}>Bittrex International operated out of Malta has closed down on October 29, which coincided with the launch of Bittrex Global - a new exchange partner utilizing the Bittrex technology platform, headquartered at Liechtenstein. The reason, however, hasn't been disclosed yet.</a>
                        </li>
                        <li>
                            <a href="https://techcrunch.com/2019/10/23/zuckerberg-testimony/" target={'_blank'}>Mark Zuckerberg has said in his testimony submitted to the U.S. House committee prior to hearing that Facebook would leave the Libra Association if it isn't approved by U.S. regulators.</a>
                        </li>
                        <li>
                            <a href="https://in.reuters.com/article/uk-imf-worldbank-facebook/facebook-open-to-currency-pegged-stablecoins-for-libra-project-idINKBN1WZ0O5" target={'_blank'}>Facebook's Libra head David Marcus has said that it could use cryptocurrencies? ? based on national currencies such as the dollar instead of a synthetic one it had? ? initially proposed.</a>
                        </li>
                        <li>
                            <a href="https://in.reuters.com/article/uk-imf-worldbank-facebook/facebook-open-to-currency-pegged-stablecoins-for-libra-project-idINKBN1WZ0O5" target={'_blank'}>Bitmain, the Bitcoin mining company, has launched the world's largest Bitcoin mining farm in Rockdale, Texas that's been under construction since late 2018.</a>
                        </li>
                        <li>
                            <a href="https://in.reuters.com/article/uk-imf-worldbank-facebook/facebook-open-to-currency-pegged-stablecoins-for-libra-project-idINKBN1WZ0O5" target={'_blank'}>Xi Jinping, China's President, wants the country to take the leading position in the emerging technology of blockchain.</a>
                        </li>
                    </ul>
                </div>
            </div>       
        );
    }

}

export default OctoberFortnight2;