import React from "react";
import Zoom from "react-reveal/Fade";

const DesignSprint = ({opacity, page}) => {

    return (<section id={"designSprints"} className={"section-2 beige-bg company-info " + (page===2 ? "" :"")} style={{opacity: opacity}}>
            <div className="custom-container">
                <div className="custom-section">
                    {/* <Zoom delay={400} key={page} top when={page === 2}> */}
                    <div className="row work-with-row">
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 des-tab-resp">
                            <div className="left-info-hold">
                                <div className="overal-sec">
                                    <div className="design-section">
                                        <div className="step-div step-count">
                                            <h3>Step 3</h3>
                                        </div>
                                        <div className="highlight-text">
                                            <h4><span>Design Sprints</span></h4>
                                        </div>
                                        <div className="main-para-div">
                                            <p>We take a rapid prototyping approach to generate feedback early and pivot
                                                accordingly.</p>
                                            <p>Clickable prototypes and visual design bring your ideas life. Your team
                                                can test product features, explore UI flows, and provide feedback on
                                                branding before we write any code.</p>
                                        </div>
                                    </div>
                                    <div className="design-section mt-3">
                                        <div className="step-div">
                                            <h6>This leads to →</h6>
                                        </div>
                                        <div className="highlight-blue">
                                            <h4>Continuous Product Improvement</h4>
                                        </div>
                                        <div className="main-para-div">
                                            <p>Multiple feedback loops help test your assumptions and pivot design early
                                                saving time and money.</p>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 comp-info-right-col">
                            <div className="right-img-hold">
                                <div className="comp-info-right-img">
                                            <span className="company-offer-imgs">
                                                <img src="/assets/img/design-img/design.svg" alt="Design Sprints" title="Design Sprints"/>
                                            </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* </Zoom> */}
                </div>
            </div>
        </section>
    )
}

export default DesignSprint
