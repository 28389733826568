import React from 'react';
import "../../resources.css";
import {ResourceRoute} from '../../resource-routing';
import {Link} from "react-router-dom";
import NewsHeadings from "./common-action/news-heading";
import NewsSubscription from "./common-action/subscripbe-section";

class NewsIssue38 extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            pageEnabled: false,
        }

    }

    render() {
        return (
          <div className="newdesigned_issue_details">
            <NewsHeadings
              buttondDetail="Web3 & Blockchain"
              categoryType="web3"
              description="Reddit is testing Community Points, a blockchain based system to encourage on platform engagement | Digital Information World"
            />
            <div className="newsissuedetailed">
              <div className="detailed-common">
                {/* <div className="back-to-common">
                    <span className="back-to-icon-hold">
                    <img src="/assets/img/resource/back-to-new.svg"  alt="bact to new"></img>
                    </span>
                    <Link  to={ResourceRoute.NEWSLETTER}><span className="back-to-link">Newsletter</span></Link>
                </div> */}

                <div className="news-letter whats-on-hold">
                  <div className="issue-main-head">
                    <h4>What’s on?</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.theguardian.com/technology/2020/may/20/apple-google-phone-app-trace-coronavirus"
                      >
                        <h4>
                          Apple and Google release phone technology to notify
                          users of coronavirus exposure | The Guardian
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Apple and Google have released long-awaited smartphone
                        technology to automatically notify people if they might
                        have been exposed to the coronavirus.The companies had
                        announced the unprecedented collaboration to leverage
                        their technology to help trace and contain the spread of
                        coronavirus last month, and say 22 countries and several
                        US states are already planning to build voluntary phone
                        apps using their software
                      </p>
                    </div>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://designmodo.com/material-design-color-tool/"
                      >
                        <h4>
                          Google Updates Material Design and Adds Color Tool |
                          Design Modo
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Launched three years ago, Material Design, Google’s
                        in-house design language influenced the shift from
                        ancient user interfaces to the modern look of the
                        contemporary web.The latest Material Design guidelines
                        focus on bringing more color to the web. The updates aim
                        to help designers and developers to a better job in
                        choosing colors for their projects. In addition to that,
                        Google introduces a new color tool for designers to
                        experiment with.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="news-letter top-picks-hold">
                  <div className="issue-main-head">
                    <h4>Top Picks</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.digitalinformationworld.com/2020/05/reddit-is-testing-community-points-a-blockchain-based-system-to-encourage-on-platform-engagement.html"
                      >
                        <h4>
                          Reddit is testing Community Points, a blockchain based
                          system to encourage on platform engagement | Digital
                          Information World
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Social media platforms have not yet been able to
                        integrate blockchain-based technology in their
                        operations in any practical way. Facebook is working on
                        its Libra Project, while Twitter CEO Jack Dorsey is seen
                        to be a significant advocate for cryptocurrencies.
                        However, it seems that Reddit has found a way to use
                        this technology. This week, the platform announced the
                        launch of the new ‘Community Points,’ a blockchain-based
                        system, rolled out as a test and will enable Reddit
                        moderators to assign scores to Reddit community members.
                        The points will be allocated to members based on their
                        engagement within their respective subreddits.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="news-letter other-reads-hold">
                  <div className="issue-main-head">
                    <h4>Other Reads</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.theverge.com/2020/5/19/21262934/google-alphabet-ceo-sundar-pichai-interview-pandemic-coronavirus"
                      >
                        <h4>
                          Sundar Pichai on Managing Google through the pandemic
                          - The Interview | The Verge
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Like all big tech companies, Google and Alphabet are
                        playing an outsized role in our lives as the coronavirus
                        pandemic continues. Whether it’s helping people find
                        reliable information in search, working with the
                        government on testing, building an exposure-tracking
                        system into Android and iOS in partnership with Apple,
                        or battling misinformation on YouTube, Google’s
                        capability — and responsibility — has never been
                        greater. Google and Alphabet CEO Sundar Pichai, in this
                        interview, talks about the challenges Google faces
                        during this time, including a shift in its core ad
                        business and the challenges of managing the company
                        remotely. Pichai is himself adapting to remote work;
                        he’s actively blocking out more time on his calendar to
                        read and think, something he used to do during his
                        commute.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <NewsSubscription categoryType="Web3" />
          </div>
        );
    }

}

export default NewsIssue38;
