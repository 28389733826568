import React from 'react';
import "../../resources.css";
import {ResourceRoute} from '../../resource-routing';
import {Link} from "react-router-dom";
import NewsHeadings from "./common-action/news-heading";
import NewsSubscription from "./common-action/subscripbe-section";

class NewsIssue39 extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            pageEnabled: false,
        }

    }

    render() {
        return (
          <div className="newdesigned_issue_details">
            <NewsHeadings
              buttondDetail="Design"
              categoryType="design"
              description="Outdated Website Design Trends/Practices: What 2020 Taught Us | Digital Information World"
            />
            <div className="newsissuedetailed">
              <div className="detailed-common">
                {/* <div className="back-to-common">
                    <span className="back-to-icon-hold">
                    <img src="/assets/img/resource/back-to-new.svg"  alt="bact to new"></img>
                    </span>
                    <Link  to={ResourceRoute.NEWSLETTER}><span className="back-to-link">Newsletter</span></Link>
                </div> */}

                <div className="news-letter whats-on-hold">
                  <div className="issue-main-head">
                    <h4>What’s on?</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.google.com/url?q=https://www.dbta.com/Editorial/News-Flashes/Whats-New-in-the-Next-Generation-of-Oracle-Blockchain-Platform-Cloud-Service-142224.aspx&source=gmail&ust=1599218376616000&usg=AFQjCNHTljqLuEbUXcKDdpXMvAny5c65qg"
                      >
                        <h4>
                          What’s New in the Next Generation of Oracle Blockchain
                          Platform Cloud Service | Database
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Oracle has announced an update to the Oracle Blockchain
                        Platform Cloud Service, leveraging the advantages of
                        Oracle Cloud Infrastructure (OCI) and new improvements
                        by the Hyperledger community in Hyperledger Fabric.
                        According to Oracle, its blockchain customers are
                        increasingly moving their applications into production,
                        and existing production customers are scaling up their
                        deployments and ramping up their transaction volumes.
                      </p>
                    </div>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://cointelegraph.com/news/how-blockchain-benefits-from-artificial-intelligence"
                      >
                        <h4>
                          How Blockchain Benefits From Artificial Intelligence |
                          Coin Telegraph
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        From performing simple commands on smartphones using
                        Alexa or Siri to high-end technical operations in big
                        tech firms, one thing is sure: Ease is a necessity in
                        the modern human experience. The 21st century has marked
                        a rapid advancement of technology in every aspect of
                        human life and interactions. Despite being around for
                        many decades, the replication of human intelligence in
                        machines — artificial intelligence — has now become
                        popularized. With many tech companies rushing to adopt
                        the technology, the market size is expected to grow to
                        $126 billion in 2025.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="news-letter top-picks-hold">
                  <div className="issue-main-head">
                    <h4>Top Picks</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.google.com/url?q=https://www.digitalinformationworld.com/2020/07/outdated-website-design-trends-practices-what-2020-taught-us.html&source=gmail&ust=1599218376616000&usg=AFQjCNFXBOfuAjConQ_xzaC3pEQPQ05lZA"
                      >
                        <h4>
                          Outdated Website Design Trends/Practices: What 2020
                          Taught Us | Digital Information World
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        We are only halfway through 2020 and there are several
                        web design trends and practices that were popular just a
                        few months ago that now appear outdated. This is due in
                        part to the fact that every few months our mobile
                        devices and mobile technology improves. Something that
                        was considered a good interface just a few months ago
                        now seems outdated. As technology keeps moving forward,
                        website design needs to continue to improve. Web
                        designers are constantly looking at the websites they
                        create from a UI and UX point of view. They are finding
                        that a lot of the design aspects that were popular just
                        a year ago or even a few months ago are outdated and
                        useless. Here is what 2020 has taught us so far about
                        web design practices and trends.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="news-letter other-reads-hold">
                  <div className="issue-main-head">
                    <h4>Other Reads</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.google.com/url?q=https://www.technologyreview.com/2020/08/03/1005891/search-for-quantum-proof-encryption-computing-nist/&source=gmail&ust=1599218376617000&usg=AFQjCNEg0mevn-HRxwWMg6o2rkPuNcO6GA"
                      >
                        <h4>
                          The quest for quantum-proof encryption just made a
                          leap forward | MIT Review{" "}
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Many of the things you do online every day are protected
                        by encryption so that no one else can spy on it. Your
                        online banking and messages to your friends are likely
                        encrypted, for example—as are government secrets. But
                        that protection is under threat from the development of
                        quantum computers, which threaten to render modern
                        encryption methods useless.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <NewsSubscription categoryType="Design" />
          </div>
        );
    }

}

export default NewsIssue39;
