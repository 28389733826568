import React, { Component } from "react";
import {ROUTES} from "../../routes";


class PageNotFound extends Component {
    render() {
        return (
            <React.Fragment>
                {/* <section class="no-page-section">
                    <div class="custom-container">
                        <div class="custom-section">
                            <div className="not-found-container">
                                <h4 className="no-page-numb-text">404</h4>
                                <h4 className="not-found-head">Error - Page Not Found</h4>
                                <div class="back-home-btn">
                                    <button  onClick={()=>{this.props.history.push(ROUTES.HOME)}}>Back to Homepage</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </section> */}
            </React.Fragment>
        );
    }
}

export default PageNotFound;
