import {ROUTES} from '../../routes';

export const PortfolioRoute = {
    P2E: ROUTES.PORTFOLIO + '/p2e',
    GaadiBazaar: ROUTES.PORTFOLIO + '/gaadibazaar',
    Tenshey:ROUTES.PORTFOLIO + '/tenshey',
    Dvs: ROUTES.PORTFOLIO + '/dvs',
    Kyc: ROUTES.PORTFOLIO + '/kyc',
    LoanProcessing: ROUTES.PORTFOLIO + '/personal-loan-processing-solution',
};
