import React from 'react';
import "../../resources.css";
import {ResourceRoute} from '../../resource-routing';
import {Link} from "react-router-dom";
import NewsHeadings from "./common-action/news-heading";
import NewsSubscription from "./common-action/subscripbe-section";

class NewsIssue42 extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            pageEnabled: false,
        }

    }

    render() {
        return (
          <div className="newdesigned_issue_details">
            <NewsHeadings
              buttondDetail="Design"
              categoryType="design"
              description="Android Jetpack Compose to make app development easier, now in alpha | Slashgear"
            />
            <div className="newsissuedetailed">
              <div className="detailed-common">
                {/* <div className="back-to-common">
                    <span className="back-to-icon-hold">
                    <img src="/assets/img/resource/back-to-new.svg"  alt="bact to new"></img>
                    </span>
                    <Link  to={ResourceRoute.NEWSLETTER}><span className="back-to-link">Newsletter</span></Link>
                </div> */}

                <div className="news-letter whats-on-hold">
                  <div className="issue-main-head">
                    <h4>What’s on?</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.google.com/url?q=https://www.msn.com/en-us/finance/markets/jpmorgan-chase-sells-blockchain-technology-platform-takes-stake-in-buyer/ar-BB18rzrF&source=gmail&ust=1599218368920000&usg=AFQjCNH6LSbLxKLFMFk3BgnkMfMUuSZ9Iw"
                      >
                        <h4>
                          JPMorgan Chase Sells Blockchain Technology Platform,
                          Takes Stake in Buyer | MSN{" "}
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        JPMorgan Chase has divested from Quorum, the blockchain
                        platform it developed, but will still indirectly hold a
                        piece of the business. A privately held FinTechcompany,
                        ConsenSys, has purchased Quorum; it is now known as
                        ConsenSys Quorum. As part of the deal, JPMorgan Chase is
                        also investing strategically in ConsenSys.
                      </p>
                    </div>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.forbes.com/sites/seansteinsmith/2020/08/26/blockchain-the-bahamas-and-future-directions-in-cryptocurrency-reporting/#6ed026fd50f8"
                      >
                        <h4>
                          Blockchain, The Bahamas, And Future Directions In
                          Cryptocurrency Reporting | Forbes
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Cryptocurrencies have now officially made a debut on the
                        balance sheet of a central bank; could this lead to an
                        entirely new cryptoasset reporting framework? Recently
                        it was discovered that Central Bank of the Bahamas had
                        included its newly created cryptocurrency, known as the
                        Sand Dollar, on its balance sheet during April 2020.
                        Although the amount that was actually listed was only
                        equivalent to $48,000, the implications of this
                        inclusion are profound.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="news-letter top-picks-hold">
                  <div className="issue-main-head">
                    <h4>Top Picks</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.google.com/url?q=https://www.slashgear.com/android-jetpack-compose-to-make-app-development-easier-now-in-alpha-27635287/&source=gmail&ust=1599218368920000&usg=AFQjCNGQ8XoqzkK-WSry9IZEXrdwApryKw"
                      >
                        {" "}
                        <h4>
                          Android Jetpack Compose to make app development
                          easier, now in alpha | Slashgear
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        We live in an app-centric world, not just on smartphones
                        but also on desktops and even on TVs. We have, in other
                        words, become dependent on these apps, as well as the
                        services that power then remotely, and, in the final
                        analysis, on the developers that create these
                        experiences. Although statistically the mobile platform,
                        Android apps haven’t exactly been the most enjoyable to
                        develop, something that Google’s new Jetpack Compose
                        toolkit is aiming to fix once and for all.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="news-letter other-reads-hold">
                  <div className="issue-main-head">
                    <h4>Other Reads</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.google.com/url?q=https://www.vox.com/recode/2020/7/16/21323458/palantir-ipo-hhs-protect-peter-thiel-cia-intelligence&source=gmail&ust=1599218368920000&usg=AFQjCNEUmLl3oGdQ5pf-zCruE1lHicmySA"
                      >
                        {" "}
                        <h4>
                          Everything you need to know about Palantir, the
                          secretive company coming for all your data | Recode
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        In the earlier days of the Covid-19 pandemic, many of
                        the country’s public health departments, still reliant
                        on fax machines, were woefully unprepared for the
                        massive amounts of data they needed to process. Looking
                        for a tidy private-sector solution to a messy government
                        problem, the Department of Health and Human Services
                        (HHS) paid a shadowy Silicon Valley company with ties to
                        the Trump administration to build something new. That
                        company is called Palantir Technologies, and if you
                        don’t know much about it, that’s by design. A lot of
                        that could change in the coming months, however:
                        Palantir is about to go public.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <NewsSubscription categoryType="Design" />
          </div>
        );
    }

}

export default NewsIssue42;
