import React from 'react';
import "../../resources.css";
import {ResourceRoute} from '../../resource-routing';
import {Link} from "react-router-dom";

class JanuaryFortnight2 extends React.Component {
    constructor(props){
        super(props)

    }
    render(){
        return (
            <div className="BlockchainDetailed">
                                                <div className="back-to-common">
                    <span className="back-to-icon-hold">
                        <img src="/assets/img/resource/back-to-new.svg"  alt="bact to new"></img>
                    </span>
                    <Link  to={ResourceRoute.BLOCKCHAIN}><span className="back-to-link">Blockchain Bulletin</span></Link>
                </div>
                <div className="download-common inner-section-1">
                    <div>
                    {/* <div className="col-xl-7 col-lg-7 col-md-7 col-sm-12"> */}
                        <div className="main-head-div">
                            <h4 className="main-head-1">
                                <span className="primary-span">January Fortnight 2 Round-up Blockchain & Crypto Brief</span>
                            </h4>
                            <p className="press-info">( January 29, 2020 )</p>
                        </div>
                    </div>    
                </div>
                <div class="bulletin-head-details bulletin-sub-heads">
                    <h4>
                        <span className="primary-span">Finance</span>
                    </h4>
                    <ul className="bulletin-links-ul">
                        <li>
                            <a href="https://cryptopys.com/2020/01/21/digix-likely-to-distribute-64m-in-eth-to-dao-token-holders-via-smart-contract-says-rep/" target={'_blank'}>Digix may distribute $64M in ETH to DAO token holders via a smart contract.</a>
                        </li>
                        <li>
                            <a href="https://www.coindesk.com/6-central-banks-form-digital-currency-use-case-working-group" target={'_blank'}>The central banks of the EU, Canada, England, Japan, Sweden, and Switzerland have formed a group to study the potential use cases for central bank digital currency.</a>
                        </li>
                        <li>
                            <a href="https://www.weforum.org/press/2020/01/central-banks-waking-up-to-digital-currency-create-new-framework-for-cbdc-deployment-with-world-economic-forum-177ca5d9ee/" target={'_blank'}>The World Economic Forum has developed a framework for central bank digital currencies (CBDCs), which provides a guide for central banks around the world to help them decide if CBDC is right for them.</a>
                        </li>
                        <li>
                            <a href="https://www.theblockcrypto.com/post/53092/commission-free-crypto-broker-voyager-to-offer-interest-on-stablecoins-including-tether" target={'_blank'}>Voyager Digital has added support for three stablecoins on its platform - Tether (USDT), USD Coin (USDC), and True USD (TUSD).</a>
                        </li>
                    </ul>
                </div>
                <div class="bulletin-head-details bulletin-sub-heads">
                    <h4>
                        <span className="primary-span">Law</span>
                    </h4>
                    <ul className="bulletin-links-ul">
                        <li>
                            <a href="https://www.theblockcrypto.com/post/53281/new-congressional-bill-seeks-de-minimis-tax-exemption-for-smaller-crypto-transactions" target={'_blank'}>A new bill seeking to create a de minimis tax exemption for day-to-day cryptocurrency purchases has started doing rounds in the halls of the US Congress.</a>
                        </li>
                        <li>
                            <a href="https://uk.finance.yahoo.com/news/oklahoma-lawmaker-proposes-state-chartered-184119403.html" target={'_blank'}>An Oklahoma lawmaker has proposed creating a state-chartered financial institution that would serve as the central depository for the virtual currency used by governmental agencies in this state if approved</a>
                        </li>
                    </ul>
                </div>
                <div class="bulletin-head-details bulletin-sub-heads">
                    <h4>
                        <span className="primary-span">Other</span>
                    </h4>
                    <ul className="bulletin-links-ul">
                        <li>
                            <a href="https://techcrunch.com/2020/01/15/anchorage-trading-from-custody/?guccounter=1&guce_referrer=aHR0cHM6Ly93d3cuZ29vZ2xlLmNvbS8&guce_referrer_sig=AQAAANG-A03Puah5UOcwUSlkYR3gcs3ni6xHPMUHKBrhbq6OtGKSvH43Nh9TUzmjjUgsWGp8YhQvoGGoQssY6jn0N2exfcY_iFKBG06GysOyU5b9kabfYDfRECf972JAgODP3DtMTmTtWnGO_QhyjOhBtbtZ-Ipapb7kfRhVrs4tCMgt" target={'_blank'}>Anchorage, a cryptocurrency custodian, has acquired a data analytics firm, Merkle Data, for an undisclosed amount.</a>
                        </li>
                        <li>
                            <a href="https://bitcoinexchangeguide.com/crypto-asset-broker-voyager-adds-three-interest-earning-stablecoins-usdt-usdc-tusd/" target={'_blank'}>Voyager Digital has added support for three stablecoins on its platform - Tether (USDT), USD Coin (USDC), and True USD (TUSD).</a>
                        </li>
                        <li>
                            <a href="https://www.theblockcrypto.com/post/53082/binances-fiat-gateway-partner-banxa-raises-2m-in-series-a-also-plans-ipo-in-canada" target={'_blank'}>Binance’s fiat gateway infrastructure provider Banxa has raised $2 million in Series A and also looks forward to IPO in Canada</a>
                        </li>
                        <li>
                            <a href="https://www.technologyreview.com/2020/01/15/130874/dont-attend-north-koreas-blockchain-conference-warn-un-sanctions-experts/" target={'_blank'}>UN sanctions experts have warned against attending North Korea’s blockchain conference next month.</a>
                        </li>
                        <li>
                            <a href="https://www.theblockcrypto.com/linked/52793/us-treasury-department-testing-blockchain-tech-for-tracking-federal-grants" target={'_blank'}>The US Treasury Department is about to complete a proof-of-concept project that tests a distributed ledger for tracking federal grants</a>
                        </li>
                        <li>
                            <a href="https://www.newsbreak.com/news/0Nrmckvz/kadena-launches-chainweb-a-hybrid-platform-to-connect-public-private-blockchains" target={'_blank'}>Kadena, a blockchain startup founded by former JPMorgan employees, has launched a public blockchain that they say can be used along with a private chain to form “hybrid” smart contract systems for businesses.</a>
                        </li>
                        <li>
                            <a href="https://uk.reuters.com/article/uk-crypto-currencies-fidelity/fidelity-investments-crypto-arm-makes-first-push-into-europe-idUKKBN1ZD1PT" target={'_blank'}>Fidelity Digital Assets, the cryptocurrency-focused arm of Fidelity Investments, has agreed to make its first foray into Europe. It is going to be a custodian for Bitcoin held by a London-based investment firm.</a>
                        </li>
                    </ul>
                </div>
            </div>         
        );
    }

}

export default JanuaryFortnight2;