import React from 'react';
import "../../resources.css";
import {ResourceRoute} from '../../resource-routing';
import {Link} from "react-router-dom";
import NewsHeadings from "./common-action/news-heading";
import NewsSubscription from "./common-action/subscripbe-section";
class NewsIssue03 extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            pageEnabled: false,
        }

    }

    render() {
        return (
          <div className="newdesigned_issue_details">
            <NewsHeadings
              buttondDetail="Web3 & Blockchain"
              categoryType="web3"
              description="AI for software development is already here | AI News"
            />

            <div className="newsissuedetailed">
              <div className="detailed-common">
                {/* <div className="back-to-common">
                    <span className="back-to-icon-hold">
                        <img src="/assets/img/resource/back-to-new.svg"  alt="bact to new"></img>
                    </span>
                    <Link  to={ResourceRoute.NEWSLETTER}><span className="back-to-link">Newsletter</span></Link>
                </div> */}

                <div className="news-letter whats-on-hold">
                  <div className="issue-main-head">
                    <h4>What’s on?</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        href="https://www.bbc.com/news/technology-50567751"
                        target={"_blank"}
                      >
                        <h4>
                          Twitter prepares for huge cull of inactive users | BBC{" "}
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Twitter will begin deleting accounts that have been
                        inactive for more than six months, unless they log in
                        before an 11 December deadline. The cull will include
                        users who stopped posting to the site because they died
                        - unless someone with that person's account details is
                        able to log-in.
                      </p>
                    </div>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        href="https://www.cnbc.com/2019/11/27/blockchain-to-save-food-industry-31-billion-new-research-says.html"
                        target={"_blank"}
                      >
                        <h4>
                          Blockchain to ‘save food industry $31 billion,’ new
                          research says | CNBC
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Blockchain will facilitate $31 billion in “food fraud
                        savings” by the year 2024, according to new data from
                        Juniper Research. According to the research, which was
                        released earlier this week, blockchain, along with
                        “internet of things” trackers and sensors, would help to
                        drive down costs for retailers. This would be achieved
                        through the streamlining of supply chains, efficient
                        food recall processes and “simpler regulatory
                        compliance.” The research is contained within the
                        “Blockchain: Key Vertical Opportunities, Trends &
                        Challenges 2019-2030” report.
                      </p>
                    </div>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        href="https://www.theverge.com/2019/11/28/20986838/jony-ive-last-day-apple"
                        target={"_blank"}
                      >
                        <h4>
                          End of an Era - Jony Ive has left Apple | The Verge{" "}
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Famed designer Jony Ive has disappeared from Apple’s
                        Leadership page, signaling an end to his time in
                        Cupertino. Ive joined Apple in 1992 and led the design
                        team from 1996. Jony Ive’s last day at Apple was always
                        a bit of a mystery. The June press release originally
                        announcing his departure only said that it would occur
                        “later this year.” Some would say Ive checked out of
                        Apple product design a long time ago after becoming
                        distracted by the design and construction of the
                        company’s new spaceship headquarters.{" "}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="news-letter top-picks-hold">
                  <div className="issue-main-head">
                    <h4>Top Picks</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        href="https://artificialintelligence-news.com/2019/11/25/opinion-ai-software-development-here/"
                        target={"_blank"}
                      >
                        <h4>
                          AI for software development is already here | AI News{" "}
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Another week, another round of Crypto Tidbits. The past
                        seven days were rather painful for Bitcoin (BTC), with
                        the leading cryptocurrency tumbling by some 4% according
                        to Coin360. Altcoins have posted similar losses. As of
                        the time of writing this, BTC is trading for $8,500,
                        seemingly poised to break down further as bulls fail to
                        step in.{" "}
                      </p>
                    </div>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        href="https://www.techrepublic.com/article/the-most-important-cloud-advances-of-the-decade/"
                        target={"_blank"}
                      >
                        <h4>
                          The most important cloud advances of the decade | Tech
                          Republic
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Cloud computing has been perhaps the most transformative
                        technology in the past several years and has changed the
                        nature of how organizations work. Although cloud took
                        root before 2010, the technology gained serious momentum
                        in the 2010s, as evidenced by the number of offerings
                        and companies that started moving some workloads
                        off-premises— followed by most or all of their
                        businesses.{" "}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="news-letter other-reads-hold">
                  <div className="issue-main-head">
                    <h4>Other Reads</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        href="https://www.forbes.com/sites/davidphelan/2019/11/27/how-technology-is-improving-the-future-of-home-service-installations/#232ca2e06800"
                        target={"_blank"}
                      >
                        <h4>
                          How Technology Is Improving The Future Of Home Service
                          Installations | Forbes
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        As winter sets in, a warm home is important. In the
                        U.K., home heating is almost exclusively done with a
                        boiler, which conjures images of cantankerous elderly
                        equipment that fails at the worst moment. If you think
                        that home heating is a relic of the analog world and
                        technology wants nothing to do with it, think again.
                      </p>
                    </div>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        href="https://www.theguardian.com/commentisfree/2019/nov/28/the-climate-crisis-leaders-debate-what-did-we-learn"
                        target={"_blank"}
                      >
                        <h4>
                          The climate crisis leaders' debate: what did we learn?
                          | The Guardian
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Zoe Williams, Guardian Journalist, Alice Bell, Co
                        Director - Climate charity possible, John Vidal, Former
                        Environment Editor and Eli o Maegan, Environment
                        journalist respond on how the leaders of main parties,
                        minus Boris Johnson addressed the biggest issue of our
                        times. Read this insight to know more.{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <NewsSubscription categoryType="Web3" />
          </div>
        );
    }

}

export default NewsIssue03;
