import React from 'react';
import { Link } from "react-router-dom";
import { ROUTES } from "../../routes";
import "./neuron.css"
import ContactUS from "../../components/contact-us";
import { PortfolioRoute } from "../portfolio/route-porfolio";
// import { ToastComponent } from '../../components/common';
import * as contactAction from "../../action/contactAction";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ReactSEOMetaTags from 'react-seo-meta-tags';
import Helmet from 'react-helmet';
import Testimonials from '../../components/testimonials/testimonials';
import TestimonialsNew from '../../components/testimonials-new/testimonialsNew';
import Slider from 'react-slick';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

class Neuroncomponent extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            headerClassAdd: "",
            showContactUS: false,
            showToast: false,
            show: false,
        }
        this.onScrollHeader = this.onScrollHeader.bind(this);
        // this.handleShow = this.handleShow.bind(this);
    }
    componentDidMount() {
        // factors
        if(window.factors) {
            window.factors.addUserProperties({'visited_healthcare_solutions_page': 'yes'});
        }
        window.addEventListener('scroll', this.onScrollHeader);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.onScrollHeader);
    }
    onScrollHeader() {
        const winScroll =
            document.body.scrollTop || document.documentElement.scrollTop

        const height =
            document.documentElement.scrollHeight -
            document.documentElement.clientHeight

        const scrolled = winScroll / height

        if (scrolled === 0) {
            this.setState({
                headerClassAdd: ""
            })
        } else {
            this.setState({
                headerClassAdd: "scrollHeader"
            })
        }
    }
    sendToast() {
        this.setState({ showContactUS: true })
    }

     handleClose(){
        this.setState({show: false})
     };
     handleShow(){
        this.setState({show: true})
     };
    render() {
        var blockSettings = {
            // className: "center",
            dots: false,
            arrows: true,
            infinite: true,
            autoplay: true,
            centerMode: false,
            speed: 3000,
            speed: 2000,
            autoplaySpeed: 5000,
            pauseOnHover: false,
            slidesToShow: 3,
            slidesToScroll: 1,
            initialSlide: 0,
            responsive: [
                {
                    breakpoint: 1240,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: false,
                    },
                },
                {
                    breakpoint: 1098,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: false,
                    },
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                    },
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        centerMode: false,
                    },
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        centerMode: false,
                    },
                },
            ],
        };
        var webinarSettings = {
            // className: "center",
            dots: false,
            arrows: true,
            infinite: true,
            autoplay: true,
            centerMode: false,
            speed: 3000,
            speed: 2000,
            autoplaySpeed: 5000,
            pauseOnHover: false,
            slidesToShow: 3,
            slidesToScroll: 1,
            initialSlide: 0,
            responsive: [
                {
                    breakpoint: 1240,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: false,
                    },
                },
                {
                    breakpoint: 1098,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: false,
                    },
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                    },
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        centerMode: false,
                    },
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        centerMode: false,
                    },
                },
            ],
        };
        return (
            <>
                <Helmet>
                    <meta charSet="utf-8" />
                    <meta
                        name="viewport"
                        content="width=device-width, initial-scale=1.0, shrink-to-fit=no, user-scalable=no, maximum-scale=1"
                    />
                    <title>Neuron: Powering the Future of Population Health Insights | IonixxTech</title>
                    <meta name="description" content="Ionixx helps customers with custom digital healthcare development solutions that are interoperable and help improve patient care and outcomes in the healthcare industry." />
                    <link rel="canonical" href="https://www.ionixxtech.com/healthcare-solutions" />
                </Helmet>
                <ReactSEOMetaTags
                    render={(el) => <Helmet>{el}</Helmet>}
                    website={{
                        name: "Ionixx Technologies",
                        title: "Neuron: Powering the Future of Population Health Insights | IonixxTech ",
                        description: "Ionixx helps customers with custom digital healthcare development solutions that are interoperable and help improve patient care and outcomes in the healthcare industry.",
                        type: "website",
                        image: 'assets/img/SEO_OG_IMAGE/HealthTech-Solutions.jpg',
                        url: "https://www.ionixxtech.com/healthcare-solutions"
                    }}
                    twitter={{
                        twitter: "https://www.ionixxtech.com/healthcare-solutions"
                    }}
                />
                <div className="Enterprise mob-view Healthcare neuron">
                    <div className="section-1 common-hero-bg white-bg">
                        <div className="custom-container">
                            <div className="custom-section">
                                <div className="main-head-div">
                                    <h1 className="main-head-1 custom-htech-head">
                                        <span className="primary-span">Neuron: Powering the Future of Population Health Insights</span>
                                        {/* <span className="secondary-span">We craft solutions for excellent patient outcomes</span> */}
                                    </h1>
                                </div>
                                <div className="main-para-div short-div">
                                    <p>Unlock the full potential of your healthcare data ecosystem with Neuron. Our modern cloud based platform is <br></br>designed to provide real-time health insights while ensuring regulatory
compliance.</p>
                                </div>
                                {/* <div className="main-para-div talk-to-us">
                                    <Link to={ROUTES.CONTACTUS} className="btn btn-primary connect-btn">Talk to us</Link>
                                </div> */}
                            </div>
                        </div>
                    </div>

                    <div className="company-info">
                        <div className="section-2 beige-bg">
                            <div className="custom-container">
                                <div className="custom-section">
                                    <div className="row work-with-row">
                                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 work-with-col">
                                            <div className="left-info-hold">
                                                <div className="main-head-div custom-main-head-div">
                                                    <h2 className="main-head-1 main-head-2">
                                                        <span className="primary-span">Overview</span>
                                                    </h2>
                                                </div>
                                                <div className="neuron-overview">
                                                    <p>Neuron is a population health insights data engine that enables seamless sharing of valuable
                healthcare data across your entire care data ecosystem. Harness the power of modern cloud
                infrastructure and meet regulatory requirements effortlessly.</p>
                                </div>
                                               
                                            </div>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                   
        <div className="section-4">
            <div className="custom-container">
              <div className="custom-section connect-with-custom-section">
                <div className="main-head-div">
                  <h4 className="main-head-1 main-head-2">
                    <span className="secondary-span">Key</span>
                    <span className="primary-span">Features</span>
                  </h4>
                </div>
                
                <div className="features-common-hold">
                  <ul className="features-common-ul">
                    <li>
                      <div className="features-info-hold">
                        <h3>
                          
                            <span>Low Monthly Subscriptions</span>
                          
                        </h3>
                        <p>
                          Access top-tier analytics at a fraction of the cost, enabling healthcare organizations of all sizes to benefit.
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="features-info-hold">
                        <h3>
                          <span>Modern Cloud Infrastructure</span>
                        </h3>
                        <p>
                          Enjoy the convenience and reliability of our state-of-the-art cloud infrastructure for seamless data analysis and collaboration.
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="features-info-hold">
                        <h3>
                            <span>Scalable to the Masses</span>
                        </h3>
                        <p>
                          Grow your healthcare data ecosystem without constraints. Neuron is built to handle increasing data volumes effortlessly.
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="features-info-hold">
                        <h3>
                          <span>Deployable Anywhere</span>
                        </h3>
                        <p>
                          Choose your preferred environment to deploy Neuron, be it on-premises, in a private cloud, or with a public cloud provider.
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
                    
        <div className="section-5 benifits-section">
            <div className="custom-container">
                <div className="custom-section connect-with-custom-section">
                <div className="main-head-div">
                  <h4 className="main-head-1 main-head-2">
                    <span className="primary-span">Benefits</span>
                  </h4>
                </div>
                
                <div className="features-common-hold">
                  <ul className="features-common-ul">
                    <li>
                      <div className="features-info-hold">
                        <h3>
                          
                            <span>Shared Insights across Care Data Ecosystem</span>
                          
                        </h3>
                        <p>
                          Ensure seamless sharing of valuable insights between care providers, improving patient outcomes and care coordination.
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="features-info-hold">
                        <h3>
                          <span>Improved Patient Outcomes</span>
                        </h3>
                        <p>
                          Leverage Neuron's advanced analytics to identify trends and optimize care delivery, leading to better patient outcomes.
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="features-info-hold">
                        <h3>
                            <span>Enhanced Care Coordination</span>
                        </h3>
                        <p>
                          Promote collaborationand coordinated care among healthcareproviders, resulting inimproved patient care and reduced costs.
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="features-info-hold">
                        <h3>
                          <span>Choose from a Variety of Popular Runtime Environments</span>
                        </h3>
                        <p>
                          Run Neuron jobs in your favorite runtime environment, providing flexibility and ease of integration within your existing infrastructure.
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="features-info-hold">
                        <h3>
                          <span>Instantly Layer on Top of Your Favorite Data Warehouse Solution</span>
                        </h3>
                        <p>
                          Seamlessly integrate Neuron with your preferred data warehouse for unified data management and streamlined analytics.
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
        </div>

        <div className="section-6 benifits-section use-cases">
            <div className="custom-container">
                <div className="custom-section connect-with-custom-section">
                <div className="main-head-div">
                  <h4 className="main-head-1 main-head-2">
                    <span className="primary-span">Use Cases</span>
                  </h4>
                </div>
                
                <div className="features-common-hold">
                  <ul className="features-common-ul">
                    <li>
                      <div className="features-info-hold">
                        <h3>
                          
                            <span>Hospitals and Health Systems</span>
                          
                        </h3>
                        <p>
                          Optimize population health management and drive operational efficiency in healthcare settings.
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="features-info-hold">
                        <h3>
                          <span>Health Insurance Companies</span>
                        </h3>
                        <p>
                          Uncover actionable insights to improve risk assessment, care coordination, and enhance customer experience.
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="features-info-hold">
                        <h3>
                            <span>Research Organizations</span>
                        </h3>
                        <p>
                          Accelerate scientific discoveries and medical breakthroughs through the analysis of largescale healthcare data.
                        </p>
                      </div>
                    </li>
                    <li>
                      <div className="features-info-hold">
                        <h3>
                          <span>Public Health Agencies</span>
                        </h3>
                        <p>
                          Utilize advanced analytics to track and monitor epidemiological data for disease surveillance and outbreak management.
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
        </div>
                    
    <div className="section-7 benifits-section get-start">
            <div className="custom-container">
                <div className="custom-section connect-with-custom-section">
                <div className="main-head-div">
                  <h4 className="main-head-1 main-head-2">
                    <span className="primary-span">Get Started</span>
                  </h4>
                </div>
                
                <div className="features-common-hold">
                  <ul className="features-common-ul">
                    <li>
                      <div className="features-info-hold">
                        <h3>
                            <span>Request a Demo</span>
                        </h3>
                        <p>
                          See firsthand how Neuron can revolutionize your healthcare data insights. Get in touch to schedule a personalized demo.
                        </p>
                        <Link
                            to={ROUTES.CONTACTUS}
                            className="btn btn-primary connect-btn"
                          >
                            Let's connect
                          </Link>
                      </div>
                    </li>
                    <li>
                      <div className="features-info-hold">
                        <h3>
                          <span>Contact Us</span>
                        </h3>
                        <p>
                          Have questions or need more information? Reach out to our team of experts who are ready to assist you.
                        </p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
        </div>
                    
                    

                    
                    {/* <ContactUS show={this.state.showContactUS} handleClose={() => this.setState({ showContactUS: false })} /> */}
                    <Modal size='lg' show={this.state.show} onHide={() => this.handleClose()} centered>
                        <Modal.Header closeButton>
                        <Modal.Title className='health-care-heading'>Neuron</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <ul className='health-care-list'>
                                <li><strong>Dynamic Data Integration:</strong> Neuron's platform seamlessly integrates with popular cloud environments and on-prem systems, enabling real-time population health insights without data migration hassles.</li>

<li><strong>Democratization of Data:</strong> Anchored by a design philosophy focused on data democratization, Neuron ensures fast, cost-effective analytics deployment, fostering a streamlined end-to-end experience.</li>

<li><strong>Flexible & Compliant:</strong> Crafted with regulatory standards in mind, Neuron scales to handle vast health populations while ensuring easy configuration, compliance, and adaptability across diverse care data ecosystems.</li>
                            </ul>
                        </Modal.Body>
                        {/* <Modal.Footer>
                        <Button variant="secondary" onClick={this.handleClose}>
                            Close
                        </Button>
                        <Button variant="primary" onClick={this.handleClose}>
                            Save Changes
                        </Button>
                        </Modal.Footer> */}
                    </Modal>                          
                </div >
            </>
        );
    }

}


const mapStateToProps = (state, ownProps) => {
    return state.commonReducer;
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return bindActionCreators(contactAction, dispatch);
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Neuroncomponent);
