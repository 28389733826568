import React from 'react';
import "../../resources.css";
import { ResourceRoute } from '../../resource-routing';
import { Link } from "react-router-dom";
import NewsHeadings from "./common-action/news-heading";
import NewsSubscription from "./common-action/subscripbe-section";

class NewsIssue58 extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            pageEnabled: false,
        }

    }

    render() {
        return (
          <div className="newdesigned_issue_details">
            <NewsHeadings
              buttondDetail="Web3 & Blockchain"
              categoryType="web3"
              description="The built environment will be one of tech’s next big platforms | Techcrunch"
            />
            <div className="newsissuedetailed">
              <div className="detailed-common">
                {/* <div className="back-to-common">
                            <span className="back-to-icon-hold">
                                <img src="/assets/img/resource/back-to-new.svg" alt="back to new"></img>
                            </span>
                            <Link to={ResourceRoute.NEWSLETTER}><span className="back-to-link">Newsletter</span></Link>
                        </div> */}

                <div className="news-letter whats-on-hold">
                  <div className="issue-main-head">
                    <h4>What’s on?</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.cnbc.com/2020/12/17/largest-us-cryptocurrency-exchange-coinbase-files-for-ipo-as-bitcoin-soars-past-23000.html"
                      >
                        <h4>
                          Largest U.S. cryptocurrency exchange Coinbase files
                          for IPO as bitcoin soars past $23,000 | CNBC.com
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        As 2020 comes to a close, the cryptocurrency world is
                        experiencing another late-year surge of consumer
                        interest as prices climb in value. Bitcoin is over
                        $23,000 as an all-time high. Ethereum’s cryptocurrency
                        has recovered sharply as well, returning to mid-2018
                        prices. These gains have created a huge amount of wealth
                        for crypto holders. According to CoinMarketCap, after
                        falling under $140 billion in mid-March during the
                        market selloff surrounding the beginning of COVID-19’s
                        battering of America, the value of all cryptos has
                        surged to nearly $659 billion.
                      </p>
                    </div>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://appinventiv.com/blog/flutter-1-22-business-benefits/"
                      >
                        <h4>
                          Why Should Businesses Care about Flutter 1.22 Version
                          | Appinventive.com
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Google in October 2020 released the latest Flutter
                        version – Flutter 1.22, giving developers and businesses
                        alike a more prominent reason to migrate their
                        cross-platform application on the SDK. Although the
                        success of brands like Realtor.com, Tencent, The New
                        York Times, and Square, etc. have already on many fronts
                        validated the efficiency of the Google cross-platform
                        SDK, for businesses that were still on the fence between
                        Flutter and React Native, Flutter 1.22 release has come
                        as an answer to make the move in its favor.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="news-letter top-picks-hold">
                  <div className="issue-main-head">
                    <h4>Top Picks</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://techcrunch.com/2020/12/24/the-built-environment-will-be-one-of-techs-next-big-platforms/"
                      >
                        <h4>
                          The built environment will be one of tech’s next big
                          platforms | Techcrunch
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        From the beginning, the plan for Sidewalk Labs (a
                        subsidiary of Alphabet and — by extension — a relative
                        of Google) to develop a $1.3 billion tech-enabled real
                        estate project on the Toronto waterfront was
                        controversial. Privacy advocates had justified concerns
                        about the Google-adjacent company’s ability to capture a
                        near-total amount of data from the residents of the
                        development or any city-dweller that wandered into its
                        high-tech panopticon.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="news-letter other-reads-hold">
                  <div className="issue-main-head">
                    <h4>Other Reads</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.cnet.com/news/big-tech-in-2021-washington-is-ready-to-lay-down-the-law/"
                      >
                        <h4>
                          Big Tech in 2021: Washington is ready to lay down the
                          law | CNET.com{" "}
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        For more than a decade, lawmakers and regulators have
                        taken a hands-off approach to Silicon Valley. But that's
                        all likely to change for Big Tech companies like Amazon,
                        Apple, Google, Facebook and Twitter as the folks in
                        charge in Washington look to rein in their power and
                        influence. nd over the past two months, Google and
                        Facebook have been hit with lawsuits from dozens of
                        states all over the country. Meanwhile, President Donald
                        Trump's Department of Justice is going after Google, and
                        a Republican-led Federal Trade Commission has filed suit
                        against Facebook. As President-elect Joe Biden prepares
                        to take office in January and a new Congress gets to
                        work, the days of unchecked power for Big Tech look like
                        they're numbered. Here's a look at the three big issues
                        facing Big Tech in the coming year.{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <NewsSubscription categoryType="Web3" />
          </div>
        );
    }

}

export default NewsIssue58;
