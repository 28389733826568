import {createStore, applyMiddleware} from "redux";
import thunk from "redux-thunk";
import rootReducer from "../reducer/common/rootReducer";
import {composeWithDevTools} from "redux-devtools-extension";
const enhancer = global.window && global.window.__REDUX_DEVTOOLS_EXTENSION__ && global.window.__REDUX_DEVTOOLS_EXTENSION__();

const composeEnhancers = composeWithDevTools({});

const middlewares = [thunk];

if (process.env.NODE_ENV === `development`) {
    const { logger } = require(`redux-logger`);

    middlewares.push();
}

export default function confiqureStore() {
    return createStore(rootReducer, enhancer, composeEnhancers(applyMiddleware(...middlewares)));
}
