import React from 'react';
import "../../resources.css";
import { ResourceRoute } from '../../resource-routing';
import { Link } from "react-router-dom";
import NewsHeadings from "./common-action/news-heading";
import NewsSubscription from "./common-action/subscripbe-section";

class NewsIssue59 extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            pageEnabled: false,
        }

    }

    render() {
        return (
          <div className="newdesigned_issue_details">
            <NewsHeadings
              buttondDetail="Web3 & Blockchain"
              categoryType="web3"
              description="Tech That Will Change Your Life in 2021 | The Wall Street Journal"
            />
            <div className="newsissuedetailed">
              <div className="detailed-common">
                {/* <div className="back-to-common">
                            <span className="back-to-icon-hold">
                                <img src="/assets/img/resource/back-to-new.svg" alt="back to new"></img>
                            </span>
                            <Link to={ResourceRoute.NEWSLETTER}><span className="back-to-link">Newsletter</span></Link>
                        </div> */}

                <div className="news-letter whats-on-hold">
                  <div className="issue-main-head">
                    <h4>What’s on?</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.crowdfundinsider.com/2020/12/170706-simplify-asset-management-set-to-announce-new-exchange-traded-funds-for-fintech-pop-culture-robocar-cloud-cybersecurity/"
                      >
                        <h4>
                          Simplify Asset Management Set to Announce New
                          Exchange-Traded Funds For Fintech, Pop Culture,
                          RoboCar, & Cloud/Cybersecurity| Crowdfundinsider.com
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Simplify Asset Management, a U.S.-based investment
                        adviser, announced on Monday the launch of four
                        exchange-traded funds (ETFs) for fintech, pop culture,
                        robocar, cloud/cybersecurity. Founded earlier this year,
                        Simplify was created to help advisors tackle pressing
                        portfolio challenges with option-based strategies.
                      </p>
                    </div>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.americanbanker.com/list/how-crypto-and-blockchain-made-inroads-into-financial-services-in-2020"
                      >
                        <h4>
                          How crypto and blockchain made inroads into financial
                          services in 2020 | American Banker
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Government officials and many bankers are wary of
                        digital currencies because they fear bad actors could
                        find ways to take advantage. But bitcoin is not going
                        away, and blockchain technology and crypto are slowly
                        but steadily becoming more embedded in the financial
                        services industry. Here are some of the key developments
                        in 2020 that suggest how entwined these nascent sectors
                        are with the future of banking — including new
                        applications for the technology, the Federal Reserve’s
                        interest in creating a digital currency, notable moves
                        by Visa and Mastercard, major advances with regulatory
                        acceptance, and the milestone achievement of several
                        crypto firms securing bank charters.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="news-letter top-picks-hold">
                  <div className="issue-main-head">
                    <h4>Top Picks</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.androidheadlines.com/2020/12/how-will-iot-transform-future-custom-mobile-app-development.html"
                      >
                        <h4>
                          How Will IoT Transform The Future Of Custom Mobile App
                          Development? | Android Headlines
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        With a heavy dependence on mobile devices and a need for
                        quick data, both businesses and consumers are looking
                        for updated, convenient technology. From adjusting your
                        HVAC on your phone from miles away to checking health
                        monitors and controlling a drone, our world is becoming
                        increasingly interconnected. With these increased
                        capabilities, IoT development requires a more complex
                        approach, incorporating cloud and analytics, unlike many
                        other mobile applications. Thus, IoT apps are the new
                        gold standard for mobile applications and are the future
                        of software development. So, what does that future look
                        like? Here’s a look at how IoT is changing the
                        application development space.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="news-letter other-reads-hold">
                  <div className="issue-main-head">
                    <h4>Other Reads</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.wsj.com/articles/tech-that-will-change-your-life-in-2021-11609519215"
                      >
                        <h4>
                          Tech That Will Change Your Life in 2021 | The Wall
                          Street Journal{" "}
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        A pandemic that ravaged the world and accelerated the
                        digital transformation of, well, everything? Not even
                        the best of futurists or Magic 8 ball-shaking psychics
                        could have predicted the year that was 2020. And yet
                        while we may have missed the biggest news, our
                        predictions for what would occur in the tech world held
                        up decently. Now, 2020 has become the lens through which
                        all our 2021 predictions are glimpsed. As we continue to
                        live in a pandemic-fighting world, innovators will aim
                        tech solutions at our personal and professional lives,
                        from at-home streaming movie debuts to an overdue
                        evolutionary leap of the laptop. Read on to know what to
                        look out for this year.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <NewsSubscription categoryType="Web3" />
          </div>
        );
    }

}

export default NewsIssue59;
