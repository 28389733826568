import React from 'react';
import {Link} from "react-router-dom";
import { ROUTES } from '../../../../../routes';
import {ResourceRoute} from '../../../resource-routing';
import PaperBooksDownload from "./../paper-books-download";

class FormationOfBlockChain extends React.Component {
    constructor(props) {
        super(props)
    }
    render() {
        return (
            <>
                        
                        <div className="newsissuedetailed">
            <div className="detailed-common">
                <div className="back-to-common">
                    <span className="back-to-icon-hold">
                        <img src="/assets/img/resource/back-to-new.svg"  alt="bact to new"></img>
                    </span>
                    <Link to={ResourceRoute.WHITEPAPER_TAB}><span className="back-to-link">Whitepapers & e-Books</span></Link>
                </div>

                <div className="whats-on-hold">
                    
                    <div className="ebook-sec">
						<div className="row">
							<div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 resp-apprch-col">
								<div className="custom-section work-with-custom-section">
                                <div class="main-head-div">
                                    <h1 class="main-head-1">
                                        <span class="secondary-span">Formation of Blockchain</span>
                                    </h1>
                                </div>
									<div className="main-para-div">
                                    <p>As a decentralized ledger, <Link to={ROUTES.BlOCKCHAIN_NEW}>blockchain</Link> is formed consisting of three elements in order to offer several benefits. They are: Data, nonce, and a hash. As an especially promising and revolutionary technology, the formation of blockchain is primarily to help reduce risk, mitigate fraud, and bring transparency in a scalable way for myriad use cases.</p>
                            <p>Sign up for our free eBook today and get a thorough understanding of how a blockchain is formed, and how it helps build transparency and offers security at its helm.</p>
										{/* <a class="d-hide m-show" href="">View our software portfolio</a> */}
									</div>
								</div>
							</div>
							<div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 resp-apprch-col">
								<div class="ebook-form-sec">
                                  <div class="modal-title">
                                    <h4>Tell us a little about you</h4>
                                  </div>
                                  <div class="ebook-dwnld-form">
                                        <PaperBooksDownload pdfUrl={this.props?.location?.state} />

                                    </div>
                            
                                </div>
							</div>
						</div>	
		            </div>
                </div>

            </div>
        </div> 

            </>
        );
    }

}

export default FormationOfBlockChain;
