import React from "react";
import "../../resources.css";
import { ResourceRoute } from "../../resource-routing";
import { Link } from "react-router-dom";
import NewsHeadings from "./common-action/news-heading";
import NewsSubscription from "./common-action/subscripbe-section";

class NewsIssue68 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pageEnabled: false,
    };
  }

  render() {
    return (
      <div className="newdesigned_issue_details">
        <NewsHeadings
          categoryType="healthtech"
          buttondDetail="HealthTech"
          description="How Technology Can Enhance Behavioral Healthcare Design and Care | DallasInnovates.com"
        />

        <div className="newsissuedetailed">
          <div className="detailed-common">
            {/* <div className="back-to-common">
                    <span className="back-to-icon-hold">
                        <img src="/assets/img/resource/back-to-new.svg"  alt="back to new"></img>
                    </span>
                    <Link  to={ResourceRoute.NEWSLETTER}><span className="back-to-link">Newsletter</span></Link>
                </div> */}

            <div className="news-letter whats-on-hold">
              <div className="issue-main-head">
                <h4>What’s on?</h4>
              </div>
              <div className="issue-main-info">
                <div className="issue-sub-head">
                  <a
                    target={"_blank"}
                    href="https://securityintelligence.com/articles/why-demand-for-application-development-security-skills-exploding/"
                  >
                    <h4>
                      Why the Demand for Application Development Security Skills
                      Is Exploding| Security Intelligence
                    </h4>
                  </a>
                </div>
                <div className="issue-news-info">
                  <p>
                    Application development security is a key task when it comes
                    to looking to the future of cybersecurity. A recent industry
                    study shows it is the fastest-growing cybersecurity skill
                    for the year ahead. Demand is expected to increase by 164%
                    over the next five years. Such growth would bump up the
                    total number of job openings requiring this skill from
                    29,635 in 2020 to 48,601 a few years from now.
                  </p>
                </div>
              </div>
              <div className="issue-main-info">
                <div className="issue-sub-head">
                  <a
                    target={"_blank"}
                    href="https://insidebigdata.com/2021/03/06/ai-innovations-to-enhance-ux/"
                  >
                    <h4>AI Innovations to Enhance UX | Insidebigdata.com</h4>
                  </a>
                </div>
                <div className="issue-news-info">
                  <p>
                    Artificial intelligence – AI – was a mere computational
                    theory back in the 1950s when Alan Turing designed the first
                    Turing Test to measure a machine’s intelligence. In just
                    half a century, AI’s role in society has become firmly
                    established. It has transformed both UI and UX design,
                    breaking down the physical barriers that exist between
                    humans and digital interfaces. AI inhabits consumer
                    electronics in the form of Siri, Cortana, Alexa and Google
                    Assistant – these forms of AI remove the need for humans to
                    interact with information ourselves – the AI does that for
                    us.
                  </p>
                </div>
              </div>
            </div>
            <div className="news-letter top-picks-hold">
              <div className="issue-main-head">
                <h4>Top Picks</h4>
              </div>
              <div className="issue-main-info">
                <div className="issue-sub-head">
                  <a
                    target={"_blank"}
                    href="https://insidebigdata.com/2021/03/06/ai-innovations-to-enhance-ux/"
                  >
                    <h4>
                      How Technology Can Enhance Behavioral Healthcare Design
                      and Care | DallasInnovates.com{" "}
                    </h4>
                  </a>
                </div>
                <div className="issue-news-info">
                  <p>
                    Technology has significantly pushed the capabilities of
                    medicine forward, with everything from computers that
                    sequence genes to robots that assist in minimally-invasive
                    surgery. However, one speciality of medicine with untapped
                    potential when it comes to utilizing cutting-edge tech is
                    mental healthcare. Yet as the pandemic has shown, there are
                    great opportunities within the realm of mental healthcare to
                    take advantage of existing and upcoming tech advances.{" "}
                  </p>
                </div>
              </div>
            </div>
            <div className="news-letter other-reads-hold">
              <div className="issue-main-head">
                <h4>Other Reads</h4>
              </div>
              <div className="issue-main-info">
                <div className="issue-sub-head">
                  <a
                    target={"_blank"}
                    href="https://dallasinnovates.com/how-technology-can-enhance-behavioral-healthcare-design-and-care/"
                  >
                    <h4>
                      What to expect from the FinTechindustry in 2021 | Fortune
                    </h4>
                  </a>
                </div>
                <div className="issue-news-info">
                  <p>
                    The new normal created by the pandemic brings huge
                    opportunities for the industry. FinTechwill have to focus on
                    a few important priorities in 2021 as we work toward serving
                    more users and gaining widespread acceptance. Foremost among
                    these is how technology can be used to enhance mutual trust
                    and deepen cooperation among multiple parties globally,
                    strengthen efforts at financial inclusion to heal economic
                    divisions worsened by the pandemic, and take a more active
                    role in shaping the green behavior of individuals and
                    businesses to address the urgent issue of climate change.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <NewsSubscription categoryType="HealthTech" />
      </div>
    );
  }
}

export default NewsIssue68;
