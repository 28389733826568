import React from 'react';
import "../../resources.css";
import {ResourceRoute} from '../../resource-routing';
import {Link} from "react-router-dom";
import NewsHeadings from "./common-action/news-heading";
import NewsSubscription from "./common-action/subscripbe-section";

class NewsIssue33 extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            pageEnabled: false,
        }

    }

    render() {
        return (
          <div className="newdesigned_issue_details">
            <NewsHeadings
              buttondDetail="Design"
              categoryType="design"
              description="How ‘Sustainable’ Web Design Can Help Fight Climate Change | Wired"
            />
            <div className="newsissuedetailed">
              <div className="detailed-common">
                {/* <div className="back-to-common">
                    <span className="back-to-icon-hold">
                    <img src="/assets/img/resource/back-to-new.svg"  alt="bact to new"></img>
                    </span>
                    <Link  to={ResourceRoute.NEWSLETTER}><span className="back-to-link">Newsletter</span></Link>
                </div> */}

                <div className="news-letter whats-on-hold">
                  <div className="issue-main-head">
                    <h4>What’s on?</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.coindesk.com/binance-backed-blockchain-auditing-firm-partners-with-hyundai-subsidiary-to-track-internet-of-things-devices"
                      >
                        <h4>
                          Binance-Backed Blockchain Auditing Firm Partners With
                          Hdac to Track Internet of Things Devices | Coindesk
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Auditing firm CertiK and Hdac are teaming up to bring
                        Internet of Things (IoT) devices onto a blockchain.
                        Hdac’s blockchain platform attempts to combine secure
                        authentication, seamless mapping and machine-to-machine
                        transactions in a blockchain network with IoT devices.
                        CertiK’s team of blockchain engineers worked closely
                        with Hdac to audit the design and implementation of its
                        codebase, which is expected to be released in the near
                        future.
                      </p>
                    </div>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://techcrunch.com/2020/06/24/aws-launches-amazon-honeycode-a-no-code-mobile-and-web-app-builder/"
                      >
                        <h4>
                          AWS launches Amazon Honeycode, a no-code mobile and
                          web app builder | Techcrunch
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        AWS announced the beta launch of Amazon Honeycode, a
                        new, fully managed low-code/no-code development tool
                        that aims to make it easy for anybody in a company to
                        build their own applications. All of this, of course, is
                        backed by a database in AWS and a web-based,
                        drag-and-drop interface builder.Developers can build
                        applications for up to 20 users for free. After that,
                        they pay per user and for the storage their applications
                        take up.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="news-letter top-picks-hold">
                  <div className="issue-main-head">
                    <h4>Top Picks</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.wired.com/story/sustainable-software-design-climate-change/"
                      >
                        <h4>
                          How ‘Sustainable’ Web Design Can Help Fight Climate
                          Change | Wired
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Danny Van Kooten, a Dutch programmer decided to reduce
                        his carbon output by no longer eating beef or flying.
                        Then, five months ago, he made a change that had an even
                        bigger impact—and it took only a few keystrokes. Read on
                        the link to know Van Kooten's aha moment that is
                        popularly being shared by web designers around the
                        planet. They call it “sustainable” software design, and
                        it's propelled by technologists measuring the energy
                        budget of nearly every swipe and click in our
                        information ecosystem.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="news-letter other-reads-hold">
                  <div className="issue-main-head">
                    <h4>Other Reads</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.technologyreview.com/2020/06/24/1004432/ai-help-crisis-new-kind-ethics-machine-learning-pandemic/"
                      >
                        <h4>
                          If AI is going to help us in a crisis, we need a new
                          kind of ethics | MIT Review
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Jess Whittlestone at the Leverhulme Centre for the
                        Future of Intelligence at the University of Cambridge
                        and her colleagues published a comment piece in Nature
                        Machine Intelligence this week arguing that if
                        artificial intelligence is going to help in a crisis, we
                        need a new, faster way of doing AI ethics, which they
                        call ethics for urgency. Read the experts to know how AI
                        will be quicker to deploy when needed if it is made with
                        ethics built in
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <NewsSubscription categoryType="Design" />
          </div>
        );
    }

}

export default NewsIssue33;
