import React from 'react';
import "../../resources.css";
import {ResourceRoute} from '../../resource-routing';
import {Link} from "react-router-dom";
import NewsHeadings from "./common-action/news-heading";
import NewsSubscription from "./common-action/subscripbe-section";

class NewsIssue63 extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            pageEnabled: false,
        }

    }

    render() {
        return (
          <div className="newdesigned_issue_details">
            <NewsHeadings
              buttondDetail="Design"
              categoryType="design"
              description="The UI/UX Patterns You Literally Can’t Use | One Zero/Medium"
            />
            <div className="newsissuedetailed">
              <div className="detailed-common">
                {/* <div className="back-to-common">
                    <span className="back-to-icon-hold">
                        <img src="/assets/img/resource/back-to-new.svg"  alt="back to new"></img>
                    </span>
                    <Link  to={ResourceRoute.NEWSLETTER}><span className="back-to-link">Newsletter</span></Link>
                </div> */}

                <div className="news-letter whats-on-hold">
                  <div className="issue-main-head">
                    <h4>What’s on?</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.channelfutures.com/cloud-2/ibms-hybrid-cloud-build-team-helps-partners-like-never-before"
                      >
                        <h4>
                          IBM’s Hybrid Cloud Build Team Helps Partners ‘Like
                          Never Before’ | ChannelFutures.com{" "}
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        IBM continues to count on hybrid cloud as the path to
                        better financial results and long-term viability. As
                        part of that multipronged strategy, Big Blue on Thursday
                        unveiled its Hybrid Cloud Build Team, available to
                        channel partners for free. The initiative features more
                        than 100 cloud architects, data scientists, cloud
                        developers, security specialists and developer advocates
                        who create platforms for partners and their clients.
                      </p>
                    </div>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://techcrunch.com/2021/02/03/bsn-china-national-blockchain/"
                      >
                        <h4>
                          China's national blockchain network embraces global
                          developers | Techcrunch{" "}
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        While China bans cryptocurrency exchanges and initial
                        coin offerings, the government is set to leverage the
                        underpinning technology — often without the
                        decentralized part. Blockchain, for instance, could help
                        track the shipment of luxury goods and authenticate
                        court evidence. In the process of adopting blockchain
                        applications in its own interest, China also wants to
                        become a world leader of the new technology..
                      </p>
                    </div>
                  </div>
                </div>
                <div className="news-letter top-picks-hold">
                  <div className="issue-main-head">
                    <h4>Top Picks</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://onezero.medium.com/the-ui-ux-patterns-you-literally-cant-use-64875a5c965f"
                      >
                        <h4>
                          The UI/UX Patterns You Literally Can’t Use | One
                          Zero/Medium
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        You love thinking big. You love looking at other tech
                        companies to see how they designed their products, or
                        their navigation system, or that one animation
                        transition you love. You aspire to design something like
                        that. You keep bookmarks in your head: If you needed an
                        expanding carousel for your entertainment app, you could
                        take inspiration from Netflix. Or maybe not. There are
                        in fact some patterns you can’t use. Of course,
                        companies are smart and don’t give a lot of written
                        detail about how the design would be used or in what
                        product, since they don’t want to leak pre-releases. The
                        only clues you have are the dotted designs in the black
                        and white diagrams that serve as a contextual
                        environment, and the title, which will tell you if they
                        are talking about an animation or a static design. Over
                        a year since my last review, let’s look at some of the
                        new patents in play and explore some trending companies.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="news-letter other-reads-hold">
                  <div className="issue-main-head">
                    <h4>Other Reads</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.technologyreview.com/2021/02/03/1017242/google-project-zero-day-flaw-security/"
                      >
                        <h4>
                          Google says it’s too easy for hackers to find new
                          security flaws | MIT Tech Review
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Security researchers detected similar,
                        previously-unknown flaws, known as zero-day
                        vulnerabilities, in Microsoft products in December 2018,
                        September 2019, November 2019, January 2020, and April
                        2020. This saga is emblematic of a much bigger problem
                        in cybersecurity, according to new research from Maddie
                        Stone, a security researcher at Google: that it’s far
                        too easy for hackers to keep exploiting insidious
                        zero-days because tech companies are not doing a good
                        job of permanently shutting down flaws and loopholes.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <NewsSubscription categoryType="Design" />
          </div>
        );
    }

}

export default NewsIssue63;
