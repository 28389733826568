import React from "react";
import "../../resources.css";
import { ResourceRoute } from "../../resource-routing";
import { Link } from "react-router-dom";
import NewsHeadings from "./common-action/news-heading";
import NewsSubscription from "./common-action/subscripbe-section";

class NewsIssue81 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pageEnabled: false,
    };
  }

  render() {
    return (
      <div className="newdesigned_issue_details">
        <NewsHeadings
          buttondDetail="HealthTech"
          categoryType="healthtech"
          description="Medical data security: Top 5 things to know | Tech Republic"
        />
        <div className="newsissuedetailed">
          <div className="detailed-common">
            {/* <div className="back-to-common">
                            <span className="back-to-icon-hold">
                                <img src="/assets/img/resource/back-to-new.svg" alt="back to new"></img>
                            </span>
                            <Link to={ResourceRoute.NEWSLETTER}><span className="back-to-link">Newsletter</span></Link>
                        </div> */}

            <div className=" news-letter whats-on-hold">
              <div className="issue-main-head">
                <h4>What’s on?</h4>
              </div>
              <div className="issue-main-info">
                <div className="issue-sub-head">
                  <a
                    target={"_blank"}
                    href="https://www.wsj.com/articles/el-salvador-becomes-first-country-to-approve-bitcoin-as-legal-tender-11623234476"
                  >
                    <h4>
                      El Salvador Becomes First Country to Approve Bitcoin as
                      Legal Tender | The Wall Street Journal
                    </h4>
                  </a>
                </div>
                <div className="issue-news-info">
                  <p>
                    President says move will bring inclusion to Salvadorans who
                    don’t have access to financial services; analysts warn of
                    economic risks from bitcoin’s extreme volatility.
                  </p>
                </div>
              </div>
              <div className="issue-main-info">
                <div className="issue-sub-head">
                  <a
                    target={"_blank"}
                    href="https://www.theguardian.com/environment/2021/jun/09/hook-to-plate-how-blockchain-tech-can-turn-the-tide-for-sustainable-fishing-aoe"
                  >
                    <h4>
                      How blockchain tech could turn the tide for sustainable
                      fishing | The Guardia
                    </h4>
                  </a>
                </div>
                <div className="issue-news-info">
                  <p>
                    In recent weeks, a new $50m (£35m) hybrid vessel set sail
                    from Mauritius and headed out into the Southern Ocean where
                    the crew will spend three months longline fishing for the
                    Patagonian toothfish. The technology enabling this is
                    blockchain.
                  </p>
                </div>
              </div>
              <div className="issue-main-info">
                <div className="issue-sub-head">
                  <a
                    target={"_blank"}
                    href="https://economictimes.indiatimes.com/markets/cryptocurrency/interactive-brokers-to-offer-crypto-trading-by-the-end-of-summer/articleshow/83373188.cms"
                  >
                    <h4>
                      Interactive Brokers to offer crypto trading by the end of
                      summer | Economic Times
                    </h4>
                  </a>
                </div>
                <div className="issue-news-info">
                  <p>
                    Interactive Brokers Group Inc plans to begin offering
                    cryptocurrency trading through its brokerage within a few
                    months, the company's chairman said on Wednesday. "Customers
                    are asking for it and we expect to be ready to offer it to
                    them by the end of the summer," Interactive Brokers founder
                    and chairman Thomas Peterffy said at a conference held by
                    Piper Sandler.
                  </p>
                </div>
              </div>
            </div>
            <div className="news-letter  top-picks-hold">
              <div className="issue-main-head">
                <h4>Top Picks</h4>
              </div>
              <div className="issue-main-info">
                <div className="issue-sub-head">
                  <a
                    target={"_blank"}
                    href="https://www.techrepublic.com/videos/top-5-things-to-know-about-medical-data-security/"
                  >
                    <h4>
                      Medical data security: Top 5 things to know | Tech
                      Republic
                    </h4>
                  </a>
                </div>
                <div className="issue-news-info">
                  <p>
                    Medical data is a valuable commodity that needs to be
                    protected from cybersecurity threats. Tom Merritt lists five
                    things to know about medical data security.
                  </p>
                </div>
              </div>
            </div>
            <div className="news-letter other-reads-hold">
              <div className="issue-main-head">
                <h4>Other Reads</h4>
              </div>
              <div className="issue-main-info">
                <div className="issue-sub-head">
                  <a
                    target={"_blank"}
                    href="https://www.chicagoreader.com/chicago/ten-best-crypto-exchanges/Content?oid=89185430"
                  >
                    <h4>Ten best crypto exchanges | Chicago Reader</h4>
                  </a>
                </div>
                <div className="issue-news-info">
                  <p>
                    Bitcoin. Ethereum. Litecoin. And now Dogecoin. You’ve heard
                    about these cryptocurrencies and want to dip your toes in.
                    You may think it’s too late, but it’s not. Investing in
                    crypto has proven to be a lucrative income stream for many
                    investors. No longer considered a fringe investment, crypto
                    has gone mainstream and is widely invested in by major
                    financial institutions
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <NewsSubscription categoryType="HealthTech" />
      </div>
    );
  }
}

export default NewsIssue81;
