import React from 'react';
import "../../resources.css";
import {ResourceRoute} from '../../resource-routing';
import {Link} from "react-router-dom";
import NewsHeadings from "./common-action/news-heading";
import NewsSubscription from "./common-action/subscripbe-section";

class NewsIssue32 extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            pageEnabled: false,
        }

    }

    render() {
        return (
          <div className="newdesigned_issue_details">
            <NewsHeadings
              buttondDetail="Web3 & Blockchain"
              categoryType="web3"
              description="Unforgettable: How Blockchain Will Fundamentally Change the Human Experience | Coindesk"
            />
            <div className="newsissuedetailed">
              <div className="detailed-common">
                {/* <div className="back-to-common">
                    <span className="back-to-icon-hold">
                    <img src="/assets/img/resource/back-to-new.svg"  alt="bact to new"></img>
                    </span>
                    <Link  to={ResourceRoute.NEWSLETTER}><span className="back-to-link">Newsletter</span></Link>
                </div> */}

                <div className="news-letter whats-on-hold">
                  <div className="issue-main-head">
                    <h4>What’s on?</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.enterpriseai.news/2020/06/18/sap-introduces-new-enhancements-to-its-business-technology-platform/"
                      >
                        <h4>
                          SAP Introduces New Enhancements to its Business
                          Technology Platform | Enterprise AI
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        SAP SE announced new capabilities, enhancements and
                        further unification of the Business Technology Platform
                        portfolio to help companies transform data into business
                        value.SAP’s Business Technology Platform enables
                        developers and business users to access integrated,
                        end-to-end services and tools to realize value along
                        three critical areas of IT: data excellence, process
                        integration and extensions. The announcement was made at
                        the SAPPHIRE NOW Converge conference, being held online
                        June 15–19, 2020.
                      </p>
                    </div>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://cointelegraph.com/news/deloitte-39-of-companies-worldwide-now-have-blockchain-in-production"
                      >
                        <h4>
                          Deloitte: 39% of Companies Worldwide Now Have
                          Blockchain in Production | Coin Telegraph
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        According to the results of Deloitte’s 2020 Global
                        Blockchain Survey, 39% of 1,488 senior executives and
                        practitioners in 14 countries said they have already
                        incorporated blockchain into production at their
                        companies — a 16% increase on last year’s figures. The
                        number rises to 41% when considering companies with over
                        $100 million in revenue. The auditing giant surveyed
                        respondents from Brazil, Canada, China, Germany, Hong
                        Kong, Ireland, Israel, Mexico, Singapore, South Africa,
                        Switzerland, the United Arab Emirates, the United
                        Kingdom, and the United States who have “at least a
                        broad understanding of blockchain, digital assets, and
                        distributed ledger technology”.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="news-letter top-picks-hold">
                  <div className="issue-main-head">
                    <h4>Top Picks</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://cointelegraph.com/magazine/2020/06/18/unforgettable-blockchain-human-experience"
                      >
                        <h4>
                          Unforgettable: How Blockchain Will Fundamentally
                          Change the Human Experience | Coindesk
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        From the invention of the wheel to the printing press,
                        new technology has changed the human experience. Our
                        comprehension of the world is no longer limited to a
                        village. Our collective knowledge grows by inconceivable
                        exabytes of data every day. And our memories, our very
                        recollections of the events that shape our lives, are
                        changing too.In fact, according to neurobiologist Dr.
                        James L. McGaugh, a researcher specializing in learning
                        and memory, technological advancements right up to the
                        advent of the internet have made it less necessary for
                        humans to construct lasting records of our own memories.
                      </p>
                    </div>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://uxplanet.org/circular-design-according-to-ideo-399693f27141"
                      >
                        <h4>Circular Design According to IDEO | UX Planet </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Circular Design is a way to apply the concept of a
                        circular economy, stemming from different currents of
                        thought including regenerative design. Traditionally,
                        the economy has followed a linear model: making,
                        producing, consuming, throwing (“take, make, consume,
                        dispose”). This situation leads to ecological problems
                        (overexploitation of natural resources, waste, rejection
                        of materials toxic to the environment). Hence the
                        solution of the circular economy, which requires a new
                        way of thinking about design.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="news-letter other-reads-hold">
                  <div className="issue-main-head">
                    <h4>Other Reads</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://peoplemanagingpeople.com/general/proven-tips-for-working-from-home/"
                      >
                        <h4>
                          32 Proven Tips For Working From Home | People Managing
                          People
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        During the COVID-19 crisis, millions of people found
                        themselves working from home for the first time. Twitter
                        CEO Jack Dorsey went so far as to tell employees that
                        they could work from home permanently. What most people
                        don’t realize, however, is that working from home is
                        significantly different than working in an office. You
                        can’t take what you did at the office and simply
                        transfer it to your home. New skills and routines are
                        needed—a new approach to managing your teams during
                        crisis, and managing your own work, too. This article
                        provides you with 32 proven, time-tested tips for
                        succeeding while working from home.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <NewsSubscription categoryType="Web3" />
          </div>
        );
    }

}

export default NewsIssue32;
