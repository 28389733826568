import React from 'react';
import "../../resources.css"
import {ResourceRoute} from '../../resource-routing';
import ReactSEOMetaTags from 'react-seo-meta-tags';
import Helmet from 'react-helmet';

import {SlideDown} from 'react-slidedown';
import 'react-slidedown/lib/slidedown.css';

class Press extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            pageEnabled: false,
        }
    }
    pushToStory = () =>{
        this.props.history.push(ResourceRoute.STORY);
    }
    pushToStoryStrato = () =>{
        this.props.history.push(ResourceRoute.STORYSTRATO);
    }
    render(){
        return (
            <>	
            <Helmet>
                <title>Press & Media | Ionixx Technologies</title>
                <meta name="description" content="Stay updated with the latest news and discussions making the rounds in the industry. Read our news highlights to get a quick overview." />
                <link rel="canonical" href="https://www.ionixxtech.com/resources/press" />
            </Helmet>
            <ReactSEOMetaTags
                render={(el) => <Helmet>{el}</Helmet>}
                website={{ 
                    name: "Ionixx Technologies", 
                    title:"Press & Media | Ionixx Technologies", 
                    description: "Stay updated with the latest news and discussions making the rounds in the industry. Read our news highlights to get a quick overview.",
                    type:"website",
                    image:'/assets/img/ionixx-logo.png',
                    url:"https://www.ionixxtech.com/resources/press"
                }}
                twitter={{ 
                    twitter: "https://www.ionixxtech.com/resources/press" }}
                />
            <div className="Resources">
                <div id="press">
                    <div className="press-common-hold">
                        <div className="main-head-div">
                            <h4 className="main-head-1">
                                <span className="primary-span">Press & Media</span>
                            </h4>
                            <p className="press-info">Our Press team loves working with journalists around the world to share
                                complelling & unique stories. If you would like to get in touch with us send a mail to
                                <span className="press-contact">press@ionixxtech.com</span>
                            </p>
                        </div>
                        {/* <div className="filter-button-hold">
                            <div class="filter"><div class="dropdown portfolio-drop">
                                <button class="dropdown-toggle" type="button" id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">Filter By : <span>None</span></button>
                                    <div class="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                        <a class="dropdown-item" href="#">Action</a>
                                        <a class="dropdown-item" href="#">Another action</a>
                                        <a class="dropdown-item" href="#">Something else here</a>
                                    </div>
                                </div>
                            </div>
                        </div> */}
                    </div>
                    <div className="tab-card porti-card">
                        <div className="row press-row">
                       
                            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 press-col">
                                <div className="port-card press-card" onClick={this.pushToStory}>
                                    <div className="whole-port">
                                        <div className="card-img">
                                            <img className="" src="/assets/img/press/manju-mohan-forbes-jpg" title="Forbes" alt="Forbes"></img>
                                        </div>
                                        <div className="press-card-type">
                                            <span className="press-green-bg">BUSINESS</span>
                                            {/* <span className="press-blue-bg">CLOUD TECH</span> */}
                                        </div>
                                        <div className="detailed-card-head">
                                            <h4>Manju Mohan, CEO & Co-Founder accepted into Forbes Business Council</h4>
                                        </div>
                                        <div className="press-content-author">
                                            <p>
                                                {/* <span>-</span>
                                                <span className="author-name">Baskar Natrajan,</span> */}
                                                <span className="created-date">13 Jan 2020</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 press-col">
                                <div className="port-card press-card" onClick={this.pushToStoryStrato}>
                                    <div className="whole-port">
                                        <div className="card-img">
                                            <img className="" src="/assets/img/press/ionix-blockapps-750x375-jpg" title="BlockApps" alt="BlockApps"></img>
                                        </div>
                                        <div className="press-card-type press-orange-bg">
                                            <span className="press-orange-bg">BLOCK CHAIN</span>
                                        </div>
                                        <div className="detailed-card-head">
                                            <h4>BlockApps and Ionixx Technologies announce partnership to build successful blockchain applications for businesses through STRATO Network</h4>
                                        </div>
                                        <div className="press-content-author">
                                            <p>
                                                {/* <span>-</span>
                                                <span className="author-name"> Vivek Tamilarasan,</span> */}
                                                <span className="created-date">12 Dec 2019</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 press-col">
                                <div className="port-card" onClick={this.pushToStory}>
                                    <div className="whole-port">
                                        <div className="card-img">
                                            <img className="" src="assets/img/resource/3.png"></img>
                                        </div>
                                        <div className="press-card-type">
                                            <span className="press-green-bg">BUSINESS</span>
                                        </div>
                                        <div className="detailed-card-head">
                                            <h4>Tips to Organize Your Small Business During the COVID-19 Pandemic</h4>
                                        </div>
                                        <div className="press-content-author">
                                            <p>
                                                <span>-</span>
                                                <span className="author-name"> Shiv Nanda,</span>
                                                <span className="created-date">13 Aug 2020</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                        </div>
                    </div>
                    <div className="in-news-common">
                        <div className="main-head-div">
                            <h4 className="main-head-1">
                                <span className="primary-span">In the News</span>
                            </h4>
                        </div>
                        <div className="news-cards-common">
                            
                                {
                                    !this.state.pageEnabled ? 
                                    <ul><li>
                                        <div className="in-news-card press-card card">
                                        <a target={'_blank'} href="https://www.forbes.com/sites/forbesbusinesscouncil/2020/03/25/15-smart-ways-you-can-save-money-as-a-new-business/#5fdfd7aa40fc">
                                            <div className="in-news-card-body">
                                                <div className="news-logo-hold">
                                                    <img src="/assets/img/resource/forbes-new.svg" title="Forbes" alt="Forbes"></img>
                                                </div>
                                                <div className="news-card-content">
                                                    <p>Smart ways to save money for a new business - Manju Mohan quoted in Forbes</p>
                                                </div>
                                                <div className="press-content-author">
                                                    <p>
                                                        <span className="created-date">20 Mar 2020</span>
                                                    </p>
                                                </div>
                                            </div>
                                            </a>
                                        </div>
                                </li>
                                <li>
                                   
                                        <div className="in-news-card press-card card">
                                        <a target={'_blank'} href="https://www.forbes.com/sites/forbesbusinesscouncil/2020/03/12/authenticating-food-through-blockchain">
                                            <div className="in-news-card-body">
                                                <div className="news-logo-hold">
                                                    <img src="/assets/img/resource/forbes-new.svg" title="Forbes" alt="Forbes"></img>
                                                </div>
                                                <div className="news-card-content">
                                                    <p>Authenticating Food Through Blockchain by Manju Mohan in Forbes Magazine</p>
                                                </div>
                                                <div className="press-content-author">
                                                    <p>
                                                        <span className="created-date">12 Mar 2020</span>
                                                    </p>
                                                </div>
                                            </div>
                                            </a>
                                        </div>
                                </li>
                                <li>

                                    <div className="in-news-card  press-card card">
                                    <a target={'_blank'} href="https://www.cryptonewspoint.com/meet-the-100-most-inspirational-women-in-blockchain/">
                                        <div className="in-news-card-body">
                                            <div className="news-logo-hold crypt-logo">
                                                <img src="/assets/img/press/crpto-news-point.svg" title="cryptonews" alt="cryptonews"></img>
                                            </div>
                                            <div className="news-card-content">
                                                <p>Manju Mohan featured among 100 inspirational women in Blockchain</p>
                                            </div>
                                            <div className="press-content-author">
                                                <p>
                                                    <span className="created-date">09 Mar 2020</span>
                                                </p>
                                            </div>
                                        </div>
                                        </a>
                                    </div>

                                </li>
                                <li>
                                  
                                    <div className="in-news-card press-card card">
                                    <a target={'_blank'} href="https://www.forbes.com/sites/forbesbusinesscouncil/2020/03/09/13-smart-ways-to-handle-internal-disputes-when-youre-a-growing-team/#14bf59995394">
                                        <div className="in-news-card-body">
                                            <div className="news-logo-hold">
                                                <img src="/assets/img/resource/forbes-new.svg" title="Forbes" alt="Forbes"></img>
                                            </div>
                                            <div className="news-card-content">
                                                <p>Smart Ways To Handle Internal Disputes - Manju Mohan quoted by Forbes Magazine</p>
                                            </div>
                                            <div className="press-content-author">
                                                <p>
                                                    <span className="created-date">09 Mar 2020</span>
                                                </p>
                                            </div>
                                        </div>
                                        </a>
                                    </div>
                                </li></ul> : 
                                    <ul><li>
                                  
                                        <div className="in-news-card press-card card">
                                        <a target={'_blank'} href="https://www.forbes.com/sites/forbesbusinesscouncil/2020/03/25/15-smart-ways-you-can-save-money-as-a-new-business/#5fdfd7aa40fc">
                                            <div className="in-news-card-body">
                                                <div className="news-logo-hold">
                                                    <img src="/assets/img/resource/forbes-new.svg" title="Forbes" alt="Forbes"></img>
                                                </div>
                                                <div className="news-card-content">
                                                    <p>Smart ways to save money for a new business - Manju Mohan quoted in Forbes</p>
                                                </div>
                                                <div className="press-content-author">
                                                    <p>
                                                        <span className="created-date">20 Mar 2020</span>
                                                    </p>
                                                </div>
                                            </div>
                                            </a>
                                        </div>

                                </li>
                                <li>
                                   
                                        <div className="in-news-card press-card card">
                                        <a target={'_blank'} href="https://www.forbes.com/sites/forbesbusinesscouncil/2020/03/12/authenticating-food-through-blockchain">
                                            <div className="in-news-card-body">
                                                <div className="news-logo-hold">
                                                    <img src="/assets/img/resource/forbes-new.svg" title="Forbes" alt="Forbes"></img>
                                                </div>
                                                <div className="news-card-content">
                                                    <p>Authenticating Food Through Blockchain by Manju Mohan in Forbes Magazine</p>
                                                </div>
                                                <div className="press-content-author">
                                                    <p>
                                                        <span className="created-date">12 Mar 2020</span>
                                                    </p>
                                                </div>
                                            </div>
                                            </a>
                                        </div>

                                </li>
                                <li>

                                    <div className="in-news-card press-card card">
                                    <a target={'_blank'} href="https://www.cryptonewspoint.com/meet-the-100-most-inspirational-women-in-blockchain/">
                                        <div className="in-news-card-body">
                                            <div className="news-logo-hold crypt-logo">
                                                <img src="/assets/img/press/crpto-news-point.svg" title="cryptonews" alt="cryptonews"></img>
                                            </div>
                                            <div className="news-card-content">
                                                <p>Manju Mohan featured among 100 inspirational women in Blockchain</p>
                                            </div>
                                            <div className="press-content-author">
                                                <p>
                                                    <span className="created-date">09 Mar 2020</span>
                                                </p>
                                            </div>
                                        </div>
                                        </a>
                                    </div>

                                </li>
                                <li>
                                  
                                    <div className="in-news-card press-card card">
                                    <a target={'_blank'} href="https://www.forbes.com/sites/forbesbusinesscouncil/2020/03/09/13-smart-ways-to-handle-internal-disputes-when-youre-a-growing-team/#14bf59995394">
                                        <div className="in-news-card-body">
                                            <div className="news-logo-hold">
                                                <img src="/assets/img/resource/forbes-new.svg" title="Forbes" alt="Forbes"></img>
                                            </div>
                                            <div className="news-card-content">
                                                <p>Smart Ways To Handle Internal Disputes - Manju Mohan quoted by Forbes Magazine</p>
                                            </div>
                                            <div className="press-content-author">
                                                <p>
                                                    <span className="created-date">09 Mar 2020</span>
                                                </p>
                                            </div>
                                        </div>
                                        </a>
                                    </div>

                                </li><li>
                                  
                                     <div className="in-news-card press-card card">
                                     <a target={'_blank'} href="https://inc42.com/resources/how-data-aggregators-are-using-blockchain-to-empower-fintech-players/">
                                        <div className="in-news-card-body">
                                            <div className="news-logo-hold">
                                                <img src="/assets/img/resource/inc.png" title="Inc" alt="Inc"></img>
                                            </div>
                                            <div className="news-card-content">
                                                <p>How Data aggregators Are Using Blockchain To Empower Fintech Players - Shaan Ray | Inc 42 Magazine</p>
                                            </div>
                                            <div className="press-content-author">
                                                <p>
                                                    <span className="created-date">01 Mar 2020</span>
                                                </p>
                                            </div>
                                        </div>
                                        </a>
                                    </div>

                                </li>
                                <li>
                                  
                                    <div className="in-news-card press-card card">
                                    <a target={'_blank'} href="https://www.forbes.com/sites/forbesbusinesscouncil/2020/02/28/13-strategies-for-documenting-and-handling-your-spur-of-the-moment-ideas/#5200cb7073f4">
                                        <div className="in-news-card-body">
                                            <div className="news-logo-hold">
                                                <img src="/assets/img/resource/forbes-new.svg" title="Forbes" alt="Forbes"></img>
                                            </div>
                                            <div className="news-card-content">
                                                <p>Strategies for documenting and handling spur of the moment ideas - Manju Mohan quoted | Forbes.com</p>
                                            </div>
                                            <div className="press-content-author">
                                                <p>
                                                    <span className="created-date">28 Feb 2020</span>
                                                </p>
                                            </div>
                                        </div>
                                        </a>
                                    </div>

                                </li>
                                <li>

                                    <div className="in-news-card press-card card">
                                    <a target={'_blank'} href="https://indiacurrents.com/software-startups-are-in-manju-mohans-dna/">
                                        <div className="in-news-card-body">
                                            <div className="news-logo-hold india-current">
                                                <img src="/assets/img/resource/india-currents-new.png" title="INDIA CURRENTS" alt="INDIA CURRENTS"></img>
                                            </div>
                                            <div className="news-card-content">
                                                <p>Software Startups in Manju Mohan's DNA - India Currents Magazine</p>
                                            </div>
                                            <div className="press-content-author">
                                                <p>
                                                    <span className="created-date">22 Feb 2020</span>
                                                </p>
                                            </div>
                                        </div>
                                        </a>
                                    </div>

                                </li>
                                <li>
                                
                                    <div className="in-news-card press-card card">
                                    <a target={'_blank'} href="https://www.forbes.com/sites/forbesbusinesscouncil/2020/02/13/creating-the-ground-for-sustainable-living-through-blockchain-technology/#4241d1ae2d41">
                                        <div className="in-news-card-body">
                                            <div className="news-logo-hold">
                                                <img src="/assets/img/resource/forbes-new.svg" title="Forbes" alt="Forbes"></img>
                                            </div>
                                            <div className="news-card-content">
                                                <p>Sustainable Living Through Blockchain Technology</p>
                                            </div>
                                            <div className="press-content-author">
                                                <p>
                                                    <span className="created-date">14 Feb 2020</span>
                                                </p>
                                            </div>
                                        </div>
                                        </a>
                                    </div>

                                </li>
                                <li>
                                   
                                     <div className="in-news-card press-card card">
                                     <a target={'_blank'} href="https://www.cryptoninjas.net/2019/12/12/ionixx-to-build-enterprise-dapps-through-blockapps-strato/">
                                        <div className="in-news-card-body">
                                            <div className="news-logo-hold">
                                                <img src="/assets/img/resource/forbes-new.svg" title="Forbes" alt="Forbes"></img>
                                            </div>
                                            <div className="news-card-content">
                                                <p>Media coverage received for BlockApps STRATO Ionixx partnership</p>
                                            </div>
                                            <div className="press-content-author">
                                                <p>
                                                    <span className="created-date">17 Dec 2019</span>
                                                </p>
                                            </div>
                                        </div>
                                        </a>
                                    </div>

                                </li></ul>
                                }
                                
                                
                        </div>
                        {
                        this.state.pageEnabled === true ?
                        <div className="view-more">
                        <button onClick={() => {
                            this.setState((prevState) => ({pageEnabled: !prevState.pageEnabled}))
                            window.scrollTo(0,500);
                        }}>
                            <span><img className={this.state.pageEnabled ? 'rotate-view-more mb-0 mt-0' : ''} src="/assets/img/blue-arrow.svg"  alt="arrow"/></span>
                            {this.state.pageEnabled ? 'View Less' : 'View More'}
                        </button>
                    </div> : ""
                    }
                    </div>
                    {/* <div className="tab-card porti-card">
                        <div className="row press-row">
                            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 press-col">
                                <div className="port-card press-card">
                                    <div className="whole-port">
                                        <div className="card-img">
                                            <img className="" src="/assets/img/resource/1.png"></img>
                                        </div>
                                        <div className="press-card-type">
                                            <span className="press-blue-bg">CLOUD TECH</span>
                                        </div>
                                        <div className="detailed-card-head">
                                            <h4>Why cloud-based trading systems will be the best solution to “Trade From Home” during a
                                                pandemic</h4>
                                        </div>
                                        <div className="press-content-author">
                                            <p>
                                                <span>-</span>
                                                <span className="author-name">Baskar Natrajan,</span>
                                                <span className="created-date">13 Aug 2020</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 press-col">
                                <div className="port-card">
                                    <div className="whole-port">
                                        <div className="card-img">
                                            <img className="" src="/assets/img/resource/2.png"></img>
                                        </div>
                                        <div className="press-card-type">
                                            <span className="press-orange-bg">BLOCK CHAIN</span>
                                        </div>
                                        <div className="detailed-card-head">
                                            <h4>ICO Vs IEO Vs STO— Understanding the Nuances in Tokenized Fund Raising</h4>
                                        </div>
                                        <div className="press-content-author">
                                            <p>
                                                <span>-</span>
                                                <span className="author-name"> Vivek Tamilarasan,</span>
                                                <span className="created-date">13 Aug 2020</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 press-col">
                                <div className="port-card">
                                    <div className="whole-port">
                                        <div className="card-img">
                                            <img className="" src="/assets/img/resource/3.png"></img>
                                        </div>
                                        <div className="press-card-type">
                                              <span className="press-green-bg">BUSINESS</span>
                                        </div>
                                        <div className="detailed-card-head">
                                            <h4>Tips to Organize Your Small Business During the COVID-19 Pandemic</h4>
                                        </div>
                                        <div className="press-content-author">
                                            <p>
                                                <span>-</span>
                                                <span className="author-name"> Shiv Nanda,</span>
                                                <span className="created-date">13 Aug 2020</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    {
                        this.state.pageEnabled === false ?
                        <div className="view-more">
                        <button onClick={() => {
                            this.setState((prevState) => ({pageEnabled: !prevState.pageEnabled}))
                        }}>{this.state.pageEnabled ? 'View Less' : 'View More'}
                            <span><img className={this.state.pageEnabled ? 'rotate-view-more' : ''} src="/assets/img/blue-arrow.svg"  alt="arrow"/></span>
                        </button>
                    </div> : ""
                    }
                    
                </div>
            </div>
            </>        
        );
    }

}

export default Press;
