import axios from "axios";

const setHeaders = isAuthRequired => {
    return new Promise((resolve, reject) => {
        if(isAuthRequired){
            resolve({
                "Content-Type": "application/json",
            })
            
        }
        else {
            resolve({
                "Content-Type": "application/json"
            })
        }
    })
}

const logError = error => {
    if(error["response"]){
        console.log("Status code", error.response.status);
        console.log("Response data", error.response.data);
    }
    else{
        console.log("Most likely a server timeout or an internet connection error");
    }
}

class NetworkManager {
    // static get(url, isAuthRequired = true, params = {}) {
    //     let config = {};
    //     return setHeaders(isAuthRequired)
    //         .then(headers => {
    //             config["headers"] = headers;
    //             config["params"] = new URLSearchParams(params);
    //             console.log("Axio URL", url);
    //             return axios.get(url, config);
    //         })
    //         .catch(err => {
    //             if (err.response && (err.response.status == 401 || err.response.status == 403)) {
    //                 window.location.assign(window.location.origin + "/");
    //             }
    //             logError(err);
    //             throw err;
    //         });
    // }
    static get(url) {
        return axios({
                    url: url,
                    method: "GET",
                });
    }
    static post(url, data, isAuthRequired = true, params = {}) {
        let config = {};
        return setHeaders(isAuthRequired)
            .then(headers => {
                config["headers"] = { "Content-Type": "application/json"};
                config["params"] = params;
                return axios.post(url, data, config);
            })
            .catch(err => {
                if (!url.includes("authenticate") || (data && data["grantType"] === "refresh_token")) {
                    if (err.response && (err.response.status == 401 || err.response.status == 403)) {
                        window.location.assign(window.location.origin + "/");
                    }
                }
                logError(err);
                throw err;
            });
    }

    static postMultipart(url, data, isAuthRequired = true, params = {}) {
        let config = {};
        return setHeaders(isAuthRequired)
            .then(headers => {
                config["headers"] = {
                    "Content-Type": "multipart/form-data; boundary=MultipartBoundary",
                    // Authorization: "Bearer " + JSON.parse(window.sessionStorage.getItem("user")).accessToken
                };
                return axios.post(url, data, config);
            })
            .catch(err => {
                if (err.response && (err.response.status == 401 || err.response.status == 403)) {
                    window.location.assign(window.location.origin + "/");
                }
                logError(err);
                throw err;
            });
    }

    // static put(url, data, isAuthRequired = true, params = {}) {
    //     let config = {};
    //     return setHeaders(isAuthRequired)
    //         .then(headers => {
    //             config["headers"] = headers;
    //             config["params"] = params;
    //             return axios.put(url, data, config);
    //         })
    //         .catch(err => {
    //             if (err.response && (err.response.status == 401 || err.response.status == 403)) {
    //                 window.location.assign(window.location.origin + "/");
    //             }
    //             logError(err);
    //             throw err;
    //         });
    // }
    // static delete(url, isAuthRequired = true, params = {}) {
    //     let config = {};
    //     return setHeaders(isAuthRequired)
    //         .then(headers => {
    //             config["headers"] = headers;
    //             config["params"] = params;
    //             return axios.delete(url, config);
    //         })
    //         .catch(err => {
    //             if (err.response && (err.response.status == 401 || err.response.status == 403)) {
    //                 window.location.assign(window.location.origin + "/");
    //             }
    //             logError(err);
    //             throw err;
    //         });
    // }


    // static downloadLocally(url){
    //     return axios({
    //         url: url,
    //         method: "GET",
    //         responseType: "blob"
    //     });
    // }
}

export default NetworkManager;