import React, {useCallback, useEffect, useRef, useState} from "react";
import "./design.css";
import UserDeepDrive from "./user-deep-drive";
import DesignSprint from "./design-sprint";
import OverView from "./overview";
import Testing from "./testing";
import Progress from "./progress";

// https://24ways.org/2019/beautiful-scrolling-experiences-without-libraries/
const ScrollableContainer = ({page, onClickProgress}) => {
    const [autoScroll, setAutoScroll] = useState(undefined)
    const scrollRef = useRef(null)
    let timeoutRef = useRef(null)

    return (
    
        <div className={"scrollable-container "}>     
        <div className={"column "}>
        {/* + ((page === 0 || page === 2 ) ? "beige-bg" : "blue-bg") */}
            {/* <div className={" company-info sticky-title design-header" }>
                
                <Progress index={page} onClick={onClickProgress}/>
            </div> */}
                <div>
                    <OverView page={page}  opacity={1}/>
                    <UserDeepDrive page={page} opacity={1}/>
                    <DesignSprint page={page}  opacity={1}/>
                    <Testing page={page} opacity={1}/>
                </div>
            </div>
        </div>
    )
}

export default ScrollableContainer