import React from 'react';
import "../../resources.css";
import { ResourceRoute } from '../../resource-routing';
import { Link } from "react-router-dom";
import NewsHeadings from "./common-action/news-heading";
import NewsSubscription from "./common-action/subscripbe-section";

class NewsIssue80 extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            pageEnabled: false,
        }

    }

    render() {
        return (
          <div className="newdesigned_issue_details">
            <NewsHeadings
              buttondDetail="Web3 & Blockchain"
              categoryType="web3"
              description="What are the most dreaded antipatterns in web development? | Tech Republic"
            />
            <div className="newsissuedetailed">
              <div className="detailed-common">
                {/* <div className="back-to-common">
                    <span className="back-to-icon-hold">
                        <img src="/assets/img/resource/back-to-new.svg"  alt="back to new"></img>
                    </span>
                    <Link  to={ResourceRoute.NEWSLETTER}><span className="back-to-link">Newsletter</span></Link>
                </div> */}

                <div className="news-letter whats-on-hold">
                  <div className="issue-main-head">
                    <h4>What’s on?</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.iotworldtoday.com/2021/05/31/how-blockchain-technology-can-benefit-the-internet-of-things/"
                      >
                        <h4>
                          How Blockchain Technology Can Benefit the Internet of
                          Things| IOT World Today
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Blockchain technology has already gone through a peak of
                        hype, then a “trough of disillusionment,” but it may be
                        on an upward climb again. While the roller-coaster ride
                        of cryptocurrencies such as bitcoin have also cast
                        aspersions on blockchain technology and it has—recent
                        events such as COVID-19 and its impact on the connected
                        economy has created new imperatives of digitizing
                        transactions..{" "}
                      </p>
                    </div>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.cnbc.com/2021/06/02/kraken-launched-mobile-app-in-us-for-bitcoin-and-ethereum-purchases.html"
                      >
                        <h4>
                          Kraken launched mobile app in U.S. for bitcoin and
                          ethereum purchases | CNBC.com{" "}
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Less than two months after Coinbase’s stock market
                        debut, rival crypto exchange Kraken is bringing its
                        mobile app to the U.S. as retail investors flock to
                        digital currencies. Starting Wednesday, the new Kraken
                        App will allow many users across the U.S. to securely
                        buy and sell more than 50 crypto tokens from their
                        mobile phones.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="news-letter top-picks-hold">
                  <div className="issue-main-head">
                    <h4>Top Picks</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.techrepublic.com/article/what-are-the-most-dreaded-antipatterns-in-web-development/"
                      >
                        <h4>
                          What are the most dreaded antipatterns in web
                          development? | Tech Republic
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Ever since Tim Berners-Lee wrote the first webpage in
                        1991, web developers have delivered seemingly endless
                        innovation. Most of that innovation is great--but not
                        always. Begin cofounder Brian LeRoux asked an innocent
                        question on Twitter: "What is the most dreaded web
                        development antipattern?" An antipattern is a "commonly
                        reinvented but bad solution to a problem." In other
                        words, it's developers applying fixes that are worse
                        than the problems. What does that mean?
                      </p>
                    </div>
                  </div>
                </div>
                <div className="news-letter other-reads-hold">
                  <div className="issue-main-head">
                    <h4>Other Reads</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.cbc.ca/news/canada/saskatoon/sask-artists-non-fungible-tokens-blockchain-technology-1.6045893"
                      >
                        <h4>
                          High-tech art: How these Sask. artists are using
                          blockchain technology in their work | CBC.ca
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        NFTs use blockchain technology, a digital ledger, to
                        authenticate the originality and ownership of digital
                        assets. It's the same technology underlying
                        cryptocurrencies like Bitcoin, and cryptocurrencies are
                        used to mint (or to create) new NFTs.In March, the
                        artist Beeple made history by selling his digital art
                        for $69 million using a non-fungible token, or NFT.
                        Shortly after that, Twitter CEO Jack Dorsey sold his
                        first tweet for more than $2.9 million. And scores of
                        other artists, musicians and cryptocurrency enthusiasts
                        jumped into the hyped-up market.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <NewsSubscription categoryType="Web3" />
          </div>
        );
    }

}

export default NewsIssue80;
