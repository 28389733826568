import React from 'react';
import "../../resources.css";
import {ResourceRoute} from '../../resource-routing';
import {Link} from "react-router-dom";
import NewsHeadings from "./common-action/news-heading";
import NewsSubscription from "./common-action/subscripbe-section";

class NewsIssue34 extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            pageEnabled: false,
        }

    }

    render() {
        return (
          <div className="newdesigned_issue_details">
            <NewsHeadings
              buttondDetail="Design"
              categoryType="design"
              description="Why you shouldn’t overlook user testing methods in UX design | The Next Web"
            />
            <div className="newsissuedetailed">
              <div className="detailed-common">
                {/* <div className="back-to-common">
                    <span className="back-to-icon-hold">
                    <img src="/assets/img/resource/back-to-new.svg"  alt="bact to new"></img>
                    </span>
                    <Link  to={ResourceRoute.NEWSLETTER}><span className="back-to-link">Newsletter</span></Link>
                </div> */}

                <div className="news-letter whats-on-hold">
                  <div className="issue-main-head">
                    <h4>What’s on?</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.google.com/url?q=https://techcrunch.com/2020/07/02/reliance-jio-platforms-launches-jiomeet-video-conference-service/&source=gmail&ust=1599233242504000&usg=AFQjCNH0FbKJ5EQ3Ut9YPy3-lCgCCNdKYw"
                      >
                        <h4>India’s richest man takes on Zoom | Techcrunch</h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        India’s Reliance Jio Platforms, which recently concluded
                        a $15.2 billion fundraise run, is ready to enter a new
                        business: Video conferencing. On Thursday evening, the
                        firm — backed by Mukesh Ambani, India’s richest man —
                        formally launched JioMeet, its video-conference service
                        that looks uncannily like Zoom. Like Zoom and Google
                        Meet, JioMeet offers unlimited number of free calls in
                        high definition (720p) to users and supports as many as
                        100 participants on a call.
                      </p>
                    </div>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.coindesk.com/cnet-uphold-bank-blockchain-network"
                      >
                        <h4>
                          Salesforce Co-Founder Launches Public Blockchain
                          Network With Dollars Baked In | Coindesk
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        The latest venture from Salesforce co-founder and CNET
                        founder Halsey Minor looks a lot like an attempt to
                        recreate the legacy monetary system on a blockchain.
                        Launched commercially last week, Public Mint says its
                        “fiat native” blockchain makes transactions efficient
                        and accessible. The platform is described as a
                        programmable blockchain with fiat funds held in insured
                        banks, enabling users to create their own “digital money
                        systems.”
                      </p>
                    </div>
                  </div>
                </div>
                <div className="news-letter top-picks-hold">
                  <div className="issue-main-head">
                    <h4>Top Picks</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://thenextweb.com/dd/2020/06/26/why-you-shouldnt-overlook-user-testing-methods-in-ux-design/"
                      >
                        <h4>
                          Why you shouldn’t overlook user testing methods in UX
                          design | The Next Web
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        The fundamental purpose of user testing is to better
                        understand and empathize with the core users of a
                        digital product. Unfortunately, user testing is often an
                        afterthought. From card sorting to usability studies,
                        user testing methods utilized in UX design are developed
                        to include the user in the decision-making process.
                        However, many projects are with only stakeholder
                        feedback of a prototype.
                      </p>
                    </div>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.forbes.com/sites/davidbirch/2020/06/26/bye-fintech-hello-techfin/#2b289f3e52bc"
                      >
                        <h4>Bye Fintech. Hello Techfin. | Forbes</h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        There’s a very interesting discussion to be had about
                        whether the changes induced by the COVID-19 crisis are
                        short- or long-term and to what extent those changes are
                        an acceleration of existing trends (as I think they are,
                        largely) or new directions for the sector. The author
                        predicts that we are leaving the FinTechera and
                        entering the open banking era. Visa V’s multi-billion
                        purchase of Plaid and Mastercard MA’s purchase of
                        Fincity, the pandemic has accelerated the transition
                        between the eras. FinTechwill not vanish, but innovate
                        and operate in a completely different way.{" "}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="news-letter other-reads-hold">
                  <div className="issue-main-head">
                    <h4>Other Reads</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.nytimes.com/2020/07/02/opinion/coronavirus-masks.html"
                      >
                        <h4>Seriously, Just Wear Your Mask | New York Times</h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        As the nation plunges for a second time into the depths
                        of this brutal pandemic, officials worry we’ll soon have
                        as many as 100,000 new cases every day. Summer won’t
                        save us. Neither will bluster or bleach. So, Seriously,
                        just wear your Mask. Almost any mask will do, really.
                        N95, surgical, spandex, homespun cotton. For people who
                        aren’t front-line health care workers, what matters is
                        whatever you can get your hands on that fits over your
                        nose and mouth.{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <NewsSubscription categoryType="Design" />
          </div>
        );
    }

}

export default NewsIssue34;
