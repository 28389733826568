import React from 'react';
import "../../resources.css";
import {ResourceRoute} from '../../resource-routing';
import {Link} from "react-router-dom";
import NewsHeadings from "./common-action/news-heading";
import NewsSubscription from "./common-action/subscripbe-section";

class NewsIssue44 extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            pageEnabled: false,
        }

    }

    render() {
        return (
          <div className="newdesigned_issue_details">
            <NewsHeadings
              buttondDetail="Web3 & Blockchain"
              categoryType="web3"
              description="Blockchain Players Beat Gold and Bitcoin in the Pandemic | Bloomberg.com"
            />
            <div className="newsissuedetailed">
              <div className="detailed-common">
                {/* <div className="back-to-common">
                    <span className="back-to-icon-hold">
                    <img src="/assets/img/resource/back-to-new.svg"  alt="bact to new"></img>
                    </span>
                    <Link  to={ResourceRoute.NEWSLETTER}><span className="back-to-link">Newsletter</span></Link>
                </div> */}

                <div className="news-letter whats-on-hold">
                  <div className="issue-main-head">
                    <h4>What’s on?</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://cointelegraph.com/news/walmart-canada-s-blockchain-freight-supply-chain-proving-its-value"
                      >
                        <h4>
                          {" "}
                          Walmart Canada’s blockchain freight supply chain
                          proving its value | Coin Telegraph{" "}
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        One of the biggest retailers in the world is using
                        blockchain to transform its supply chain. A report from
                        Everest Group Research published in June shows that
                        while 79% of projects have completed their pilot phases,
                        only 7% have been deployed to production in 2020. That
                        being said, it’s impressive that one of the world’s
                        largest retailers has successfully deployed and
                        implemented a blockchain solution. Walmart Canada has
                        adopted DL Freight, a blockchain-based supply chain
                        platform, as its national standard for freight invoices
                        and payment management to be used among 60-plus
                        transportation carriers.
                      </p>
                    </div>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://siliconangle.com/2020/09/02/postman-launches-web-client-api-development-platform/"
                      >
                        <h4>
                          Postman launches web client of its API development
                          platform | Siliconangle.com
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Postman Inc. said it’s making its popular namesake
                        application programming interface development
                        environment available via the web. Postman sells what it
                        calls a “collaboration platform” for API development
                        that’s used by developers to test API calls and validate
                        that their responses are accurate. APIs are key to the
                        ability of applications to tap into other applications
                        for added features. Previously available only as a
                        desktop app, Postman is now being made available
                        directly through web browsers. The new platform comes
                        after numerous requests from users, who say downloading
                        an app can slow down onboarding.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="news-letter top-picks-hold">
                  <div className="issue-main-head">
                    <h4>Top Picks</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.bloomberg.com/opinion/articles/2020-09-09/blockchain-basket-beats-gold-and-bitcoin-during-pandemic"
                      >
                        {" "}
                        <h4>
                          Blockchain Players Beat Gold and Bitcoin in the
                          Pandemic | Bloomberg.com{" "}
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Gold and its digital counterpart, Bitcoin, have had a
                        pretty good run among investors seeking a haven or those
                        willing to take a punt on cryptocurrency. But they’ve
                        been pipped in the past year by another investment
                        thesis that’s perhaps a little more tangible,
                        highlighting the old adage that it’s smartest to be
                        selling shovels in a gold rush.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="news-letter other-reads-hold">
                  <div className="issue-main-head">
                    <h4>Other Reads</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://arstechnica.com/tech-policy/2020/09/climate-change-may-wreck-economy-unless-we-act-soon-federal-report-warns/"
                      >
                        {" "}
                        <h4>
                          Climate change may wreck economy unless we act soon,
                          federal report warns | ARS Technica
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        The ever-worsening climate crisis is already causing
                        waves of human suffering—both internationally and here
                        in the United States. And now, a new report from a US
                        financial regulator finds that climate change is also
                        poised to do major damage to some of the institutions
                        with the most power to help mitigate it: Wall Street
                        banks and investors. Climate change "poses a major risk
                        to the stability of the US financial system and to its
                        ability to sustain the American economy," the report
                        (196-page{" "}
                        <a
                          target={"_blank"}
                          href="https://cdn.arstechnica.net/wp-content/uploads/2020/09/Managing-Climate-Risk-in-the-U.S.-Financial-System.pdf"
                        >
                          PDF
                        </a>
                        ) from the US Commodity Futures Trading Commission
                        (CFTC) begins. Regulators "must recognize that climate
                        change poses serious emerging risks to the US financial
                        system, and they should move urgently and decisively to
                        measure, understand, and address these risks."
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <NewsSubscription categoryType="Web3" />
          </div>
        );
    }

}

export default NewsIssue44;
