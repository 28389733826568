import React from 'react';
import ReactSEOMetaTags from 'react-seo-meta-tags';
import Helmet from 'react-helmet';


class P2E extends React.Component {
    constructor(props) {
        super(props)
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }


    render() {
        return (
			<>	
			<Helmet>
                <title>P2e portfolio | Regulatory Change Management - IonixxTech</title>
                <meta name="description" content="Ionixx developed a RegTech system that automates regulatory change management to ensure compliance and mitigate risks." />
                <link rel="canonical" href="https://www.ionixxtech.com/portfolio/p2e" />
            </Helmet>
           <ReactSEOMetaTags
                render={(el) => <Helmet>{el}</Helmet>}
                website={{ 
                    name: "Ionixx Technologies", 
                    title:"P2e portfolio | Regulatory Change Management - IonixxTech", 
                    description: "Ionixx developed a RegTech system that automates regulatory change management to ensure compliance and mitigate risks. ",
					type:"website",
					image:'/assets/img/ionixx-logo.png',
                    url:"https://www.ionixxtech.com/portfolio/p2e"
                }}
                twitter={{ 
                    twitter: "https://www.ionixxtech.com/portfolio/p2e" }}
                /> 
			<div className="p2e-portfolio">
  <div className="first-section custom-pad-top bb">
  	<div className="container">
  	<div className="logo">
  		<img src="/assets/img/portfolio-img/p2e/logo.png" alt="logo" className="wow pulse animated"/>
  	</div>
  	<div className="left-content">
  	<div className="inner-management-process">
  		<h1 className="inner-heading wow fadeInLeftBig animated">The system was built to simplify and better handle change management processes.</h1>
  		<p>P2E is a technology-enabled regulatory change management framework. It seeks to automate the entire regulatory change management lifecycle.</p>
  		<p><b>This technology was built to help</b> companies stay ahead of changing regulations and mitigate the many risks of non-compliance.</p>
  		<p><b>A passion and innovation of</b> JACQUELINE MADUNEME, JD, CFA, CAIA Chief Exective Officer and Founder.</p>
  		<i><img src="/assets/img/portfolio-img/p2e/top-bottom-bg.svg"/></i>
  	</div>
  	</div>
  	<div className="right-content">
  		<img src="/assets/img/portfolio-img/p2e/desktop.png" className="wow fadeInRightBig animated" alt="to-do list"/>
  	</div>
  </div>
  </div>
  <div className="second-section bb">
  	<div className="container">
  		<h2 className="heading wow zoomIn">Design methodology is an intermediary between information and understanding</h2>
  		<div className="left-content">
  			<div className="need-img wow fadeInLeft"><img src="/assets/img/portfolio-img/p2e/the-need-icon.svg" alt="need icon"/></div>
  			<div className="challenge-img wow fadeInLeft"><img src="/assets/img/portfolio-img/p2e/challenges-icon.svg" alt="challenges icon"/></div>
  		</div>
  		<div className="right-content">
  			<div className="the-need card wow fadeInRightBig animated">
  				<strong>The Need:</strong>
  				<p>Financial services firms need to keep track and pace with thousands of regulatory changes per year.</p>
  				<p>They have to effectively identity, document, and summarize relevant changes/new regulations.</p>
  			</div>
  			<div className="these-challenges card wow fadeInRightBig animated">
  				<strong>We addressed these challenges by designing:</strong>
  				<p>A highly-configuarble change management system that facilitates a workflow-enabled, closed-looped regulatory change management process.</p>
  				<p>Our content-rich solution enables the capturing of regulatory updates in real-time to initiate necessary action.</p>
  			</div>
  		</div>
  	</div>
  </div>
  <div className="third-section bb">
  	<div className="inner-third-section blue-bg bb">
  		<div className="container">
  		<h2 className="heading">How we approached the design process</h2>
  		<div className="left-content">
  			<img src="/assets/img/portfolio-img/p2e/design-process.png" className="wow bounceIn animated" alt="design process"/>
  		</div>
  		<div className="right-content">
  			<h3 className="sub-heading">1. Empathy for the people we are trying to design for</h3>
  			<p>Empathy is crucial to a human-centered design process.</p>
  			<p>In our interactions with our customer, we made sure we approached our customer’s challenge with empathy to gain more insights into it by observing, engaging, and empathizing with them.</p>
  			<p>With empathy, we were able to understand their experiences and motivations better. This approach helped us get to the core of the customer’s problem and devise a solution that speciﬁcally addressed that.</p>
  			<p><b>Our resultant solution:</b> We identiﬁed that there was a need to automate workﬂows in order to help various stakeholders keep a track of several regulatory changes.
  			</p>
  		</div>
  	</div>
  	</div>
  	<div className="container">
  		<div className="inner-user-story bb">
  	<div className="user-stories">
  		<h3 className="sub-heading">2. User stories are – obviously – user-centered</h3>
  		<p>A user story is short, speciﬁc, and goal-oriented. It is a one-sentence statement that tends to have the following structure: “As a..., I want to... so that ...”.</p>
  		<p className="user-ex">For this project, we identiﬁed that there were four distinct user roles collaborating to complete a task. We prepared user stories as representations of small instances of each user role performing a speciﬁc task. The user stories were written to answer both the ‘yes’ and ‘no’ statement of the user.</p>
  		<p><b>For example:</b> As a Business manager, I want to view all the documents submitted by the project manager, so that I can validate the document.</p>
  	</div>
<div className="user-admin">
  	<ul className="wow super-admin pulse animated">
  		<label className="main-label">USER</label>
  		<li><label>As a</label><button>Super admin</button></li>
  		<li><label>I need to</label><span>Manage Organization</span></li>
  		<li><label>So that</label><span>View Organization</span><span>Add Organization</span><span>Edit Organization</span></li>
  		<li><label>Task</label><span>See the list of 
organizations and 
view entire details</span>
<span>Can assign/map the
regulation changes to
the organization and 
users</span>
<span>To make updates to the details of the
organization</span>
</li>
  	</ul>
  	<ul className="organization-admin wow pulse animated">
  		<label className="main-label">USER</label>
  		<li><button>Organization admin</button></li>
  		<li><span>Manage User</span></li>
  		<li><span>View User List</span><span>Add a New User</span><span>Edit User Details</span></li>
  		<li><span>To view the user 
details and view in 
which project he is 
currently working on</span>
<span>Enter the details of a 
new user and map the 
related regulation to the
user</span>
<span>Make updation on the details of the user and reassign project if required</span>
</li>
  	</ul>
  </div>
</div>
</div>
  	<div className="ideation blue-bg bb">
  		<div className="container">
  			<div className="inner-ideation">
  			<div className="left-content">
  				<img src="/assets/img/portfolio-img/p2e/ideation-img.png" className="wow flash" alt="ideation"/>
  			</div>
  			<div className="right-content">
  				<h3 className="sub-heading">3. Ideation - each idea as a piece of a puzzle</h3>
  				<p>At this stage, we started to look for alternative ways of viewing a customer problem.</p>
<p>We listed potential questions that could occur to a user in managing a workﬂow.</p> 
<p>This user-centric, step-by-step approach enabled us to get to the minute details and address each question for its merit. </p>
<p>With these inputs, we were able to set a clear expectation for the user to track progress within a complex process.
</p>
  			</div>
  		</div>
  		</div>
  	</div>
  	<div className="task-flow bb">
  		<div className="container">
  			<div className="inner-task-flow">
  			<div className="left-content">
  				 <h3 className="sub-heading">4. Task ﬂow analysis helped decision-making easier</h3>
  				 <strong>What</strong>
  				 <p className="what-p">To help us validate potential solutions with a granular focus, we documented a series of steps that traced the user’s possible paths via sequences of tasks and decision points in achieving a goal.</p>

  				 <strong>How we did it </strong>
  				 <p className="how-p">Based on our user stories, we identiﬁed target users’ goals that needed to be analyzed. </p>
  				 <p>We produced diagrams with each task and decision point.</p>
  				 <p> In collaboration with users and/or subject matter experts, we annotated the task ﬂow diagram to pinpoint areas of interest, risks, or potential frustration.</p>
  			</div>
  			<div className="right-content">
  				<img src="/assets/img/portfolio-img/p2e/task-flow-img.png" className="wow bounceIn animated" alt="task-flow"/>
  			</div>
  		</div>
  		</div>
  	</div>
  	<div className="wireframe custom-wireframe blue-bg bb">
  		<div className="container">
  			<div className="inner-wireframe">
  			<div className="left-content">
  				<img src="/assets/img/portfolio-img/p2e/wireframe-img.png" className=" wow slideInLeft animated" alt="wireframe-img"/>
  			</div>
  			<div className="right-content">
  				<h3 className="sub-heading">5. Wireframes - making a better product</h3>
  				<strong>The elements we focused on during wireframing:</strong>
  				<p className="entire-p">Entries and Exits Steps</p>
  				<p>Eg: user action, system action, an alternative path </p>
  				<p><b>Connections:</b> Indicate the desired path and diﬀerent possible ﬂows Decision
  				</p>
  				<p><b> Points:</b> A point where a user must choose from 2 or more options</p>
  			</div>
  		</div>
  		</div>
  	</div>
  	<div className="visual-design bb">
  		<div className="container">
  			<div className="inner-visual">
  			<div className="left-content">
  				<div className="inner-visual-content">
  				<h3 className="sub-heading">6. Visual design - a bridge between functionality and user experience</h3>
  				<p>Through visual design, we aimed to shape and improve the user experience by adding elements such as illustrations, photographs, typeface, negative space, layouts, and color. These were added to improve the usability of the product apart from adding a high degree of aesthetic appeal.</p>
  			</div>
  			</div>
  			<div className="right-content">
  				<img src="/assets/img/portfolio-img/p2e/visual-img.png" className="wow slideInRight animated" alt="visual-design"/>
  			</div>
  		</div>
  		</div>
  	</div>
  </div>
  <div className="fourth-section">
  	<div className="container">
  		<h2 className="heading">Our design process helps add business value</h2>
  			<ul className="business-value">
  			<li><i><img src="/assets/img/portfolio-img/p2e/diamond.svg" className="wow zoomIn"/></i><span>Through our design process, we were able to enhance the business value for our customer owing to the following distinguishable features</span></li>
  			<li><i><img src="/assets/img/portfolio-img/p2e/clock.svg" className="wow zoomIn"/></i><span>Repeatable change management methodology that closes the loop on the compliance lifecycle.</span></li>
  			<li><i><img src="/assets/img/portfolio-img/p2e/report.svg" className="wow zoomIn"/></i><span>Built-in audit trails, robust reporting, and risk proﬁling for better preparedness.</span></li>
  			<li><i><img src="/assets/img/portfolio-img/p2e/timer.svg" className="wow zoomIn"/></i><span>Real-time identiﬁcation of regulatory changes for immediate action.</span></li>
  			</ul>
  	</div>
  </div>
</div>
			</>		
		);
    }

}

export default P2E;
