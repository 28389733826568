import React from 'react';
import "../../resources.css";
import {ResourceRoute} from '../../resource-routing';
import {Link} from "react-router-dom";
import NewsHeadings from "./common-action/news-heading";
import NewsSubscription from "./common-action/subscripbe-section";

class NewsIssue66 extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            pageEnabled: false,
        }

    }

    render() {
        return (
          <div className="newdesigned_issue_details">
            <NewsHeadings
              buttondDetail="Web3 & Blockchain"
              categoryType="web3"
              description="The state of Java software development in 2021 | JAX Enter"
            />

            <div className="newsissuedetailed">
              <div className="detailed-common">
                {/* <div className="back-to-common">
                    <span className="back-to-icon-hold">
                        <img src="/assets/img/resource/back-to-new.svg"  alt="back to new"></img>
                    </span>
                    <Link  to={ResourceRoute.NEWSLETTER}><span className="back-to-link">Newsletter</span></Link>
                </div> */}

                <div className="news-letter whats-on-hold">
                  <div className="issue-main-head">
                    <h4>What’s on?</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.nasdaq.com/articles/solving-privacy-and-compliance-challenges-will-advance-enterprise-blockchain-adoption-2021"
                      >
                        <h4>
                          Solving Privacy and Compliance Challenges Will Advance
                          Enterprise Blockchain Adoption | NASDAQ.com{" "}
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        A few years ago, enterprise blockchain appeared to offer
                        significant promise, particularly for the financial
                        sector. The industry is heavily dependent on trusted
                        intermediaries, along with meticulous record-keeping and
                        stringent compliance requirements. While these are all
                        necessary to a functioning finance landscape, the result
                        is that transactions are often slow and costly with most
                        systems extremely cumbersome to navigate.{" "}
                      </p>
                    </div>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://cointelegraph.com/news/crypto-leaders-back-mit-s-four-year-initiative-to-harden-bitcoin-s-security"
                      >
                        <h4>
                          Crypto leaders back MIT’s four-year initiative to
                          harden Bitcoin’s security | Coin Telegraph{" "}
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        The Massachusetts Institute of Technology’s Digital
                        Currency Initiative has revealed a new “Bitcoin Software
                        and Security Effort” intended to foster research into
                        bolstering the Bitcoin network’s defenses. The
                        open-source initiative has received support from a
                        diverse group of crypto industry leaders, including
                        Gemini’s Cameron and Tyler Winklevoss, MicroStrategy’s
                        CEO Michael Saylor, Square CEO Jack Dorsey, and major
                        European digital asset manager, CoinShares.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="news-letter top-picks-hold">
                  <div className="issue-main-head">
                    <h4>Top Picks</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://jaxenter.com/java-development-2021-173870.html"
                      >
                        <h4>
                          The state of Java software development in 2021 | JAX
                          Enter{" "}
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        The recent results from the 9th annual global developer
                        productivity report gives us insights to the current
                        state of Java in 2021. Over 850 Java developers
                        responded to questions about their experiences,
                        preferred tools and technologies, and what issues they
                        currently face. Topics covered include the nature of
                        Java teams, challenges they experience, preferred tools,
                        and success of said tools and technologies. The key
                        finding shows that despite increasing use of
                        microservices, developers are still facing long redeploy
                        times and interservice functionality issues.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="news-letter other-reads-hold">
                  <div className="issue-main-head">
                    <h4>Other Reads</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.technologyreview.com/2021/02/24/1014369/10-breakthrough-technologies-2021/"
                      >
                        <h4>
                          10 Breakthrough Technologies 2021 | MIT Tech Review
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        This list marks 20 years since we began compiling an
                        annual selection of the year’s most important
                        technologies. Some, such as mRNA vaccines, are already
                        changing our lives, while others are still a few years
                        off. Below, you’ll find a brief description along with a
                        link to a feature article that probes each technology in
                        detail. We hope you’ll enjoy and explore—taken together,
                        we believe this list represents a glimpse into our
                        collective future.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <NewsSubscription categoryType="Web3" />
          </div>
        );
    }

}

export default NewsIssue66;
