import React from 'react';
import "../../resources.css";
import { ResourceRoute } from '../../resource-routing';
import { Link } from "react-router-dom";
import NewsHeadings from "./common-action/news-heading";
import NewsSubscription from "./common-action/subscripbe-section";

class NewsIssue22 extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            pageEnabled: false,
        }

    }

    render() {
        return (
          <div className="newdesigned_issue_details">
            <NewsHeadings
              buttondDetail="Web3 & Blockchain"
              categoryType="web3"
              description="Happy developers write secure code, report claims | Computer Weekly"
            />

            <div className="newsissuedetailed">
              <div className="detailed-common">
                {/* <div className="back-to-common">
                    <span className="back-to-icon-hold">
                    <img src="/assets/img/resource/back-to-new.svg"  alt="bact to new"></img>
                    </span>
                    <Link  to={ResourceRoute.NEWSLETTER}><span className="back-to-link">Newsletter</span></Link>
                </div> */}

                <div className="news-letter whats-on-hold">
                  <div className="issue-main-head">
                    <h4>What’s on?</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://cointelegraph.com/news/nestle-rises-to-dlt-challenge-expands-ibm-food-trust-blockchain-tool-to-coffee"
                      >
                        <h4>
                          Nestle expands IBM food trust blockchain tool to
                          coffee | Coin Telegraph{" "}
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Global implementation of blockchain technology in the
                        food industry continues to grow as the world’s biggest
                        food and beverage company, Nestlé, doesn’t put its
                        blockchain efforts on hold. On April 6, Nestlé announced
                        that the company is expanding its long-running IBM Food
                        Trust blockchain technology platform to its coffee brand
                        Zoégas
                      </p>
                    </div>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.theverge.com/2020/4/9/21214750/5g-coronavirus-conspiracy-theories-radio-waves-virus-internet"
                      >
                        <h4>
                          Why the 5G coronavirus conspiracy theories don’t make
                          sense | The Verge
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Online conspiracy theories have been trying to link the
                        novel coronavirus pandemic to the rollout of 5G
                        technology recently. Despite there being no scientific
                        links, multiple 5G towers have been set on fire in the
                        UK. Theories shared on Facebook, Nextdoor, and Instagram
                        are being widely spread, leading the US Federal
                        Emergency Management Agency (FEMA) and Federal
                        Communications Commission (FCC) to very clearly state:
                        “5G technology does NOT cause coronavirus.”
                      </p>
                    </div>
                  </div>
                </div>
                <div className="news-letter top-picks-hold">
                  <div className="issue-main-head">
                    <h4>Top Picks</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.computerweekly.com/news/252481215/Happy-developers-write-secure-code-report-claims"
                      >
                        <h4>
                          Happy developers write secure code, report claims |
                          Computer Weekly
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Developers who are happy and satisfied in their work are
                        3.6 times less likely to neglect security considerations
                        in their code, 2.3 times more likely to have automated
                        security tools in place, and 1.3 times more likely to
                        follow open source security best practice, according to
                        new research conducted on behalf of DevOps services
                        supplier Sonatype.
                      </p>
                    </div>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.wallpaper.com/technology/a-visual-history-of-the-internet"
                      >
                        <h4>
                          Above the fold: a visual history of the internet |
                          Wallpaper{" "}
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Regarded as the ‘biggest thing to happen since the
                        industrial revolution’, the web has changed the world.
                        Here, we look to Taschen’s Web Design: The evolution of
                        the digital world 1990 – today to celebrate the moments,
                        sites and people who have made the biggest impact in its
                        30-year history.{" "}
                      </p>
                    </div>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.cio.com/article/3530320/what-is-human-centered-design-a-product-framework-that-embraces-empathy.html"
                      >
                        <h4>
                          What is human-centered design? A product framework
                          that embraces empathy | CIO
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Human-centered design (HCD) is a design framework for
                        creating products, services, hardware and software that
                        are built to meet the specific needs of clients, users
                        or customers. HCD is typically used in technology when
                        developing products or services that are intended to
                        alleviate problems or issues, especially when those
                        problems are health-related.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="news-letter other-reads-hold">
                  <div className="issue-main-head">
                    <h4>Other Reads</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.theatlantic.com/family/archive/2020/04/how-increase-happiness-according-research/609619/"
                      >
                        <h4>
                          The Three Equations for a Happy Life, Even During a
                          Pandemic | The Atlantic{" "}
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        It seems strange to launch a column on happiness during
                        a pandemic. The timing is, well, awkward, isn’t it?
                        Maybe not. We’re stuck at home; our lives on COVID time
                        have slowed to a near halt. This creates all sorts of
                        obvious inconveniences, of course. But in the
                        involuntary quiet, many of us also sense an opportunity
                        to think a little more deeply about life. In our
                        go-go-go world, we rarely get the chance to stop and
                        consider the big drivers of our happiness and our sense
                        of purpose. Here’s a new column - “How to Build a Life”
                        that aims to give you the tools you need to construct a
                        life that feels whole and meaningful.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <NewsSubscription categoryType="Web3" />
          </div>
        );
    }

}

export default NewsIssue22;
