import React from "react";
import "./whitepaper-ebook.css";
import "../resources.css";
import WatchVideo from "../../../components/watch-video";
import { Link } from "react-router-dom";
import { SlideDown } from "react-slidedown";
import "react-slidedown/lib/slidedown.css";
import ReactSEOMetaTags from "react-seo-meta-tags";
import Helmet from "react-helmet";
import { VIDEO_LINK } from "../../../utilities/constants";
import ResourceTop from "../resource-top/resource-top";
import ScrollableTabsButtonAuto from "../../../components/common/dynamicTab/dynamicTab";
import DynamicTabs from "../../../components/common/dynamicTab/dynamicTab";
import { CATERGORY } from "../events-news/event-mockup";
import { EBOOK, WHITE_PAPAER } from "./whitepaper-mock";
import { connect } from "react-redux";
import { withRouter} from "react-router-dom";
import { bindActionCreators } from "redux";
import * as contactAction from "../../../action/contactAction";
// import usePagination from "../../../hooks/pagination";

const ContentData = ({latestEvent, data = []}) => {
  return (
    <>
      {(data && data.length > 0) ? <div className="whitepaper-inner-content events-main-wrapper">
        <div className="past-events">
          {/* <h3>Past Events</h3> */}
          {data?.map(item => (<div className="whitepaper-card-wrapper">
            <div className="whitepaper-img">
              <img src={item.img} />
            </div>
            <div className="whitepaper-content">
              <div className="content-top">
                <span className="keyword">{item?.category}</span>
                <strong>
                  {item?.content1}
                </strong>
                <span>
                  {item?.content2.substring(0, 175) + "..."}
                </span>
                <div className="card-detail">
                {/* <ul className="card-info">
                  <li>Author Name</li>
                  <li>MM.DD.YYYY</li>
                  <li>X min read</li>
                </ul> */}
                <Link to={{
                  pathname: item?.downloadPage,
                  state: item.downloadLink
                }} className={item?.downloadPage ? '': 'disable-click'}>Read More..</Link>
                </div>
              </div>
            </div>
          </div>))}
        </div>
      </div>: null}
    </>
  );
};

const ContentDataTwo = ({latestEvent, data = []}) => {
  return (
    <>
      {(data && data.length > 0) ? <div className="whitepaper-inner-content events-main-wrapper">
        <div className="past-events">
          {/* <h3>Past Events</h3> */}
          {data?.map(item => (<div className="whitepaper-card-wrapper">
            <div className="whitepaper-img">
              <img src={item.img} />
            </div>
            <div className="whitepaper-content">
              <div className="content-top">
                <span className="keyword">{item?.category}</span>
                <strong>
                  {item?.content1}
                </strong>
                <span>
                  {item?.content2.substring(0, 175) + "..."}
                </span>
                <div className="card-detail">
                {/* <ul className="card-info">
                  <li>Author Name</li>
                  <li>MM.DD.YYYY</li>
                  <li>X min read</li>
                </ul> */}
                <Link to={{
                  pathname: item?.downloadPage,
                  state: item.downloadLink
                }} className={item?.downloadPage ? '': 'disable-click'}>Read More..</Link>
                </div>
              </div>
            </div>
          </div>))}
        </div>
      </div>: null}
    </>
  );
};
class WhitepaperEbook extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      headerClassAdd: "",
      showVideo: false,
      videoModalUrl: "",
      videoModalTitle: "",
      pageEnabled: false,
      buttonShow: true,
      start: 0,
      end: 4,
      // White paper tab
      cardList: WHITE_PAPAER,
      filteredCardList: WHITE_PAPAER,
      paginatedCardList: [],
      pageCount: WHITE_PAPAER.length,
      selectedTab: 0,
      filterApplied: false,
      PER_PAGE: 4
      // ebook tab
      // ebookList: EBOOK,
      // filteredEbookList: EBOOK,
      // paginatedEbookList: [],
      // ebookCount: EBOOK.length,
    };
  }
  viewMoreOnPress = () => {
    let arrLength = this.state.cardList.length;
    let remainder = this.state.cardList.slice(this.state.end, arrLength).length;
    this.setState(
      {
        buttonShow: !this.state.buttonShow,
      },
      () => {
        if (this.state.buttonShow) {
          this.setState({
            end: 4,
          });
          window.scrollTo(0, 850);
        } else {
          this.setState({
            end: this.state.end + remainder,
          });
        }
      }
    );
  };
  configureVideoModalState = (videoModalTitle, videoModalUrl) => {
    this.setState({ videoModalTitle, videoModalUrl, showVideo: true });
  };

debounce = (callback, delay) => {
    let timer;
    return (...args) => {
        clearInterval(timer);
        timer = setTimeout(() => callback(...args), delay);
    }
}

hanldeDebounce = (searchValue) => {
    if(searchValue) {
        const result = [...this.state.cardList].filter(item => {
            if(item.content1.toLowerCase().includes(searchValue.toLowerCase())) {
                return item;
            }
        });
        this.setState({ filteredCardList: result, filterApplied: true });
        this.handlePageChange(0, this.state.PER_PAGE, result);
    } else {
        this.setState({ filteredCardList: this.state.cardList });
        this.handlePageChange(0, this.state.PER_PAGE, this.state.cardList);
    }
    // console.log('result', searchValue);
};

setDebounce = this.debounce(seachVal => this.hanldeDebounce(seachVal), 2000);

  handleSearch = (searchValue) => {
    this.setDebounce(searchValue);
  }

  handleSelect = (selectedValue) => {
    let result = [];
    if(selectedValue) {
        result = [...this.state.cardList].filter(item => {
            if(item.category.toLowerCase() === selectedValue.toLowerCase()) {
                return item;
            }
        });
        this.setState({ filteredCardList: result, pageCount: result.length });
        this.handlePageChange(0, this.state.PER_PAGE, result);
    } else {
        this.setState({ filteredCardList: this.state.cardList, pageCount: this.state.cardList.length });
        this.handlePageChange(0, this.state.PER_PAGE, this.state.cardList);
    }
    console.log('result', result);
  }

  handlePageChange = (begin, end, data = []) => {
    let result;
    if (data && data.length > 0) {
        result = [...data].slice(begin, end);
    } else {
        result = [...this.state.filteredCardList].slice(begin, end);
    }
    this.setState({ paginatedCardList: result });
  }
  
  handleTabChange = (value) => {
    // console.log("🚀 ~ file: events-news.js:399 ~ Events ~ value:", value)
    this.setState({selectedTab: value});
    if(value === 0) {
      this.setState({
        cardList: WHITE_PAPAER,
        filteredCardList: WHITE_PAPAER,
        paginatedCardList: [],
        pageCount: WHITE_PAPAER.length,
      })
    } else {
      this.setState({
        cardList: EBOOK,
        filteredCardList: EBOOK,
        paginatedCardList: [],
        pageCount: EBOOK.length,
      })
    }
  }

  render() {
    return (
      <>
        {console.log('pageChange', this.state.filteredCardList)}
        <Helmet>
          <title>
            Events,Webinars,Workshops & Conferences for Ionixx Technologies{" "}
          </title>
          <meta
            name="description"
            content="Keep updated on our Events, Webinars, Workshops, Conferences and other useful resources. Ionixx organizes various activities for easy participation and access to all information in one place."
          />
          <link
            rel="canonical"
            href="https://www.ionixxtech.com/resources/events"
          />
        </Helmet>
        <ReactSEOMetaTags
          render={(el) => <Helmet>{el}</Helmet>}
          website={{
            name: "Ionixx Technologies",
            title:
              "Events,Webinars,Workshops & Conferences for Ionixx Technologies",
            description:
              "Keep updated on our Events, Webinars, Workshops, Conferences and other useful resources. Ionixx organizes various activities for easy participation and access to all information in one place. ",
            type: "website",
            image: "/assets/img/ionixx-logo.png",
            url: "https://www.ionixxtech.com/resources/events",
          }}
          twitter={{
            twitter: "https://www.ionixxtech.com/resources/events",
          }}
        />
        <div className="resources-wrapper Events">
          <div className="events-wrapper">
            <ResourceTop />
            <div className="events-inner">
              <h1>
              Stay informed and inspired with our thoughtfully curated whitepapers and ebooks.{" "}
              </h1>
              <div className="events-top-wrap">
                <div className="categories">
                  <DynamicTabs
                    tabData={[
                      {
                        label: "Whitepapers",
                        content: <ContentData
                        data={this.state.paginatedCardList}/>,
                      },
                      {
                        label: "E-books",
                        content: <ContentDataTwo
                        data={this.state.paginatedCardList}/>,
                      },
                    ]}
                    onSearch={this.handleSearch}
                    catOptions={CATERGORY}
                    onSelect={this.handleSelect}
                    pageCount={this.state.filteredCardList.length}
                    onPageChange={this.handlePageChange}
                    onTabChange={this.handleTabChange}
                    perPage={this.state.PER_PAGE}
                    selectedTab={this.state.selectedTab}
                  ></DynamicTabs>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(contactAction, dispatch);
}
export default connect(null, mapDispatchToProps)(withRouter(WhitepaperEbook));
