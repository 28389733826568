import React from 'react';
import "../../resources.css";
import { ResourceRoute } from '../../resource-routing';
import { Link } from "react-router-dom";
import NewsHeadings from "./common-action/news-heading";
import NewsSubscription from "./common-action/subscripbe-section";

class NewsIssue20 extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            pageEnabled: false,
        }

    }

    render() {
        return (
          <div className="newdesigned_issue_details">
            <NewsHeadings
              buttondDetail="FinTech"
              categoryType="fintech"
              description="This Unstable Moment Is a Chance for Crypto to Go Mainstream | Coindesk.com"
            />

            <div className="newsissuedetailed">
              <div className="detailed-common">
                {/* <div className="back-to-common">
                    <span className="back-to-icon-hold">
                    <img src="/assets/img/resource/back-to-new.svg"  alt="bact to new"></img>
                    </span>
                    <Link  to={ResourceRoute.NEWSLETTER}><span className="back-to-link">Newsletter</span></Link>
                </div> */}

                <div className="news-letter whats-on-hold">
                  <div className="issue-main-head">
                    <h4>What’s on?</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.nytimes.com/2020/03/23/technology/coronavirus-facebook-amazon-youtube.html"
                      >
                        <h4>
                          Big Tech Could Emerge From Coronavirus Crisis Stronger
                          Than Ever | New York Times
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        While the rest of the economy is tanking from the
                        crippling impact of the coronavirus, business at the
                        biggest technology companies is holding steady — even
                        thriving. Amazon said it was hiring 100,000 warehouse
                        workers to meet surging demand. Mark Zuckerberg,
                        Facebook’s chief executive, said traffic for video
                        calling and messaging had exploded. Microsoft said the
                        numbers using its software for online collaboration had
                        climbed nearly 40 percent in a week. With people told to
                        work from home and stay away from others, the pandemic
                        has deepened reliance on services from the technology
                        industry’s biggest companies while accelerating trends
                        that were already benefiting them.
                      </p>
                    </div>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://cointelegraph.com/news/indian-tech-giant-mahindra-speeds-up-cross-border-transactions-with-blockchain"
                      >
                        <h4>
                          Indian Tech Giant Mahindra Speeds Up Cross Border
                          Transactions with Blockchain | Coin Telegraph{" "}
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Tech Mahindra, the IT subsidiary of Indian conglomerate
                        Mahindra Group, has become the first Indian business to
                        use R3’s blockchain-based Marco Polo Network for
                        conducting cross-border transactions. It said the
                        platform had resulted in faster reconciliation and
                        ambiguity resolution, as well as a significant reduction
                        of paperwork and time required for the entire
                        transaction cycle.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="news-letter top-picks-hold">
                  <div className="issue-main-head">
                    <h4>Top Picks</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.coindesk.com/this-unstable-moment-is-a-chance-for-crypto-to-go-mainstream"
                      >
                        <h4>
                          This Unstable Moment Is a Chance for Crypto to Go
                          Mainstream | Coindesk.com
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        With the impacts of COVID-19 still ravaging financial
                        markets and trillions being deployed by governments and
                        central banks to stem the economic carnage, one cannot
                        help but wonder if this is cryptocurrency’s breakout
                        moment. This unstable environment seems ripe for
                        cryptocurrency to come out of the shadows and become a
                        viable asset class and legitimate alternative to our
                        fiat-based economies. With the proposals for a digital
                        dollar, some legislators in the U.S. Congress seem to
                        think so.
                      </p>
                    </div>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.forbes.com/sites/forbesagencycouncil/2020/03/25/accessibility-of-design-designing-for-every-experience/#4462a9a81ec9"
                      >
                        <h4>
                          Accessibility Of Design: Designing For Every
                          Experience | Forbes
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p></p>
                    </div>
                  </div>
                </div>
                <div className="news-letter other-reads-hold">
                  <div className="issue-main-head">
                    <h4>Other Reads</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://au.reachout.com/articles/10-ways-to-take-care-of-yourself-during-coronavirus"
                      >
                        <h4>
                          10 ways to take care of yourself during the COVID
                          outbreak | Reachout.com{" "}
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        The world is pretty topsy-turvy right now, because of
                        the global panic around coronavirus (COVID-19). If
                        you’re feeling overwhelmed or stressed by it all, be
                        reassured that this is a very normal response. However,
                        it’s important to go easy on yourself and to take time
                        for self-care. We’ve put together this list of self-care
                        activities that you can do from home. They’ll help you
                        feel a little better and give you a sense of control
                        during a very uncertain time.
                      </p>
                    </div>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://hbr.org/2020/03/build-your-resiliency-in-the-face-of-a-crisis"
                      >
                        <h4>
                          Build Your Resilience in the Face of a Crisis |
                          HBR.org{" "}
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        As the spread and far-reaching impacts of Covid-19
                        dominate the world news, we have all been witnessing and
                        experiencing the parallel spread of worry, anxiety, and
                        instability. Indeed, in a crisis, our mental state often
                        seems only to exacerbate an already extremely
                        challenging situation, becoming a major obstacle in
                        itself. Why is this and how can we change it?. Mental
                        resilience, especially in challenging times like the
                        present, means managing our minds in a way that
                        increases our ability to face the first arrow and to
                        break the second before it strikes us. Resilience is the
                        skill of noticing our own thoughts, unhooking from the
                        non-constructive ones, and rebalancing quickly. Visit
                        the link to read three effective strategies to nurture
                        your skills and get trained.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <NewsSubscription categoryType="FinTech" />
          </div>
        );
    }

}

export default NewsIssue20;
