import React from 'react';
import "../../resources.css";
import { ResourceRoute } from '../../resource-routing';
import { Link } from "react-router-dom";
import NewsHeadings from "./common-action/news-heading";
import NewsSubscription from "./common-action/subscripbe-section";

class NewsIssue75 extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            pageEnabled: false,
        }

    }

    render() {
        return (
          <div className="newdesigned_issue_details">
            <NewsHeadings
              buttondDetail="Web3 & Blockchain"
              categoryType="web3"
              description="Things You Need To Know if You Want To Improve Node.js Web Development | Tech Bullion"
            />

            <div className="newsissuedetailed">
              <div className="detailed-common">
                {/* <div className="back-to-common">
                    <span className="back-to-icon-hold">
                        <img src="/assets/img/resource/back-to-new.svg"  alt="back to new"></img>
                    </span>
                    <Link  to={ResourceRoute.NEWSLETTER}><span className="back-to-link">Newsletter</span></Link>
                </div> */}

                <div className="news-letter whats-on-hold">
                  <div className="issue-main-head">
                    <h4>What’s on?</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.paymentsjournal.com/jpmorgan-dbs-and-temasek-form-new-blockchain-firm-to-improve-cross-border-payments/"
                      >
                        <h4>
                          JPMorgan, DBS, And Temasek Form New Blockchain Firm to
                          Improve Cross-Border Payments | Payments Journal
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        The cross-border payments space, especially with regard
                        to B2B scenarios, has been and will continue to be a
                        hotbed of change, spurred on by digital tech and ongoing
                        demand for easier and less expensive methods of value
                        exchange. In a recently posted article by The Block
                        provides an overview of a new blockchain venture being
                        launched by JPMorgan, DBS, and Temasek, the
                        Singapore-based PE firm. The company will be called
                        Partior and is another on the growing list of
                        blockchain-based initiatives to improve cross-border
                        payments between businesses.
                      </p>
                    </div>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.reuters.com/technology/mastercard-digital-currency-exchange-gemini-launch-crypto-rewards-credit-card-2021-04-27/"
                      >
                        <h4>
                          Mastercard and digital currency exchange Gemini to
                          launch crypto rewards credit card | Reuters
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Mastercard Inc and U.S. cryptocurrency exchange Gemini
                        will launch the first credit card that gives users
                        digital currency "rewards" on purchases this year,
                        deepening the involvement of major payment firms in the
                        emerging asset class. Users of the card will from this
                        summer receive up to 3% in bitcoin or other
                        cryptocurrencies on their purchases, the companies said
                        in a statement, with the digital currency deposited in
                        the cardholder's Gemini account.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="news-letter top-picks-hold">
                  <div className="issue-main-head">
                    <h4>Top Picks</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://techbullion.com/things-you-need-to-know-if-you-want-to-improve-node-js-web-development/"
                      >
                        <h4>
                          Things You Need To Know if You Want To Improve Node.js
                          Web Development | Tech Bullion
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Different programming languages each have their
                        advantages. However, when it comes to web development,
                        JavaScript is the core programming language of web
                        technology. Node.js is an open-source server capable of
                        building web applications by executing JavaScript. It
                        has gained immense popularity among web developers
                        worldwide due to its versatility and flexibility.
                        However, like with many other systems, there is a need
                        to improve the efficiency of Node.js web development to
                        help you achieve optimal results with minimal resources.{" "}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="news-letter other-reads-hold">
                  <div className="issue-main-head">
                    <h4>Other Reads</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://fortune.com/2021/04/17/silicon-valley-diversity-in-tech-bevy-derek-andersen/"
                      >
                        <h4>
                          How one Silicon Valley company addressed its diversity
                          problem—and got results | Fortune
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        The corporate reaction to the death of George Floyd and
                        the push for racial equity in the United States was met
                        with swift statements and black squares to show
                        solidarity on social media. While words can be powerful,
                        actions are ultimately what drives long-term change.
                        Last year, Derek Andersen, the CEO and cofounder of
                        Bevy, a technology company that helps some of the
                        largest corporations in the world build global
                        communities through virtual and in-person events,
                        realized his company could do better when it comes to
                        both the diversity of its new hires and its investors.{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <NewsSubscription categoryType="Web3" />
          </div>
        );
    }

}

export default NewsIssue75;
