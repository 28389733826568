import React from 'react';
import "../portfolio.css"
import {SlideDown} from 'react-slidedown'
import 'react-slidedown/lib/slidedown.css';
import {ROUTES} from "../../../routes";
import {Link} from "react-router-dom";

class CaseStudies extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            page: 1, totalPage: 4,
            dropDownValue:"All", 
            dropDownList: ['All','Fintech','Healthtech','Blockchain & Crypto','Online MarketPlace', 'Design', 'Development'],
            dropDownShow: false,
            buttonShow: true,
            start: 0,
            end: 3,
            cardList: [
                {
                    cardName:"Drive Wealth",
                    cardDesc: "Ionixx partnered with Drivewealth to lead the digital transformation of their back-office trading operations.",
                    downloadLink: '/assets/img/case-study-images/post-trade-operations.pdf',
                    routeLink: ROUTES.CASESTUDYDRIVEWEALTH
                },
                {
                    cardName:"P2E",
                    cardDesc: "P2E Technologies is a regulatory change solution provider,keen on developing automation-driven technical solutions for handling regulatory changes and compliance functions.",
                    downloadLink: '/assets/case-studies/P2E-case-study.pdf',
                    routeLink: ROUTES.CASESTUDYP2E
                },
                {
                    cardName:"Gaadi Bazaar",
                    cardDesc: "Gaadi Bazaar, an initiative of the Cholamandalam Investment and Finance Company Limited, approached Ionixx with a specific business requirement.",
                    downloadLink: '/assets/case-studies/Gaadi-Bazaar-case-study.pdf',
                    routeLink: ROUTES.CASESTUDYGADDIBAZZAR
                },
                {
                    cardName:"Tenshey",
                    cardDesc: "Tenshey is a start-up dedicated to advancing gender diversity through executive coaching,one female leader at a time.",
                    downloadLink: '/assets/case-studies/Tenshey-case-study.pdf',
                    routeLink: ROUTES.CASESTUDYTENSHEY
                },
                {
                    cardName:"Dubuqu",
                    cardDesc: "We collaborated with our customer to envision an app that not just worked as a standalone media sharing application - but would eventually scale up to function as a default offline gallery across all smartphones.",
                    downloadLink: '/assets/case-studies/Dubuqu-Case-Study.pdf',
                    routeLink: ROUTES.CASESTUDYDUBUKU
                },
                {
                    cardName:"Cryptospace",
                    cardDesc: "Cryptospace is a dynamic startup in the cryptocurrency trading space, looking to build scalable and innovative trading platforms for a wide range of users.",
                    downloadLink: '/assets/case-studies/Cryptospace.pdf',
                    routeLink: ROUTES.CASESTUDYCRYPTOSPACE
                }
            ]
        }
        this.handleDropDown = this.handleDropDown.bind(this)
        this.getDropDown = this.getDropDown.bind(this)
        this.handleOutsideClick = this.handleOutsideClick.bind(this);

    }

    viewMoreOnPress = () => {
        let arrLength = this.state.cardList.length;
        let remainder = this.state.cardList.slice(this.state.end, arrLength).length;
         this.setState({
             buttonShow : !this.state.buttonShow
         },()=>{
             if(this.state.buttonShow){
                 this.setState({
                     end:  3
                 })
             window.scrollTo(0,0);        
             }else{
                 this.setState({
                     end: this.state.end + remainder
                 })
             }
         })
        
     }
     handleOutsideClick(e) {
         this.handleDropDown();
       }
     handleDropDown(){
         if (!this.state.dropDownShow) {
             // attach/remove event handler
             document.addEventListener('click', this.handleOutsideClick, false);
           } else {
             document.removeEventListener('click', this.handleOutsideClick, false);
           }
       
         this.setState({
             dropDownShow: !this.state.dropDownShow
         })
     }
     getDropDown(value){
         this.setState({
             dropDownValue:value,
             cardList: []
         },()=>{
             var filter;
             if(this.state.dropDownValue === "All"){
                  filter =  [
                    {
                        cardName:"Drive Wealth",
                        cardDesc: "Ionixx partnered with Drivewealth to lead the digital transformation of their back-office trading operations.",
                        downloadLink: '/assets/img/case-study-images/post-trade-operations.pdf',
                        routeLink: ROUTES.CASESTUDYDRIVEWEALTH
                    },
                      {
                          cardName:"P2E",
                          cardDesc: "P2E Technologies is a regulatory change solution provider,keen on developing automation-driven technical solutions for handling regulatory changes and compliance functions.",
                          downloadLink: '/assets/case-studies/P2E-case-study.pdf',
                          routeLink: ROUTES.CASESTUDYP2E
                      },
                      {
                          cardName:"Gaadi Bazaar",
                          cardDesc: "Gaadi Bazaar, an initiative of the Cholamandalam Investment and Finance Company Limited, approached Ionixx with a specific business requirement.",
                          downloadLink: '/assets/case-studies/Gaadi-Bazaar-case-study.pdf',
                          routeLink: ROUTES.CASESTUDYGADDIBAZZAR
                      },
                      {
                          cardName:"Tenshey",
                          cardDesc: "Tenshey is a start-up dedicated to advancing gender diversity through executive coaching,one female leader at a time.",
                          downloadLink: '/assets/case-studies/Tenshey-case-study.pdf',
                          routeLink: ROUTES.CASESTUDYTENSHEY
                      },
                      {
                          cardName:"Dubuqu",
                          cardDesc: "We collaborated with our customer to envision an app that not just worked as a standalone media sharing application - but would eventually scale up to function as a default offline gallery across all smartphones.",
                          downloadLink: '/assets/case-studies/Dubuqu-Case-Study.pdf',
                          routeLink: ROUTES.CASESTUDYDUBUKU
                      }
                 ]
                 this.setState({
                     cardList: [...filter]
                 })
             }
             if(this.state.dropDownValue === "Online MarketPlace"){
                 filter =  [
                    {
                        cardName:"Gaadi Bazaar",
                        cardDesc: "Gaadi Bazaar, an initiative of the Cholamandalam Investment and Finance Company Limited, approached Ionixx with a specific business requirement.",
                        downloadLink: '/assets/case-studies/Gaadi-Bazaar-case-study.pdf'
                    }
                 ]
                 this.setState({
                     cardList: [...filter]
                 })
             }else if(this.state.dropDownValue === "Development"){
                 filter =  [
                    {
                        cardName:"Dubuqu",
                        cardDesc: "We collaborated with our customer to envision an app that not just worked as a standalone media sharing application - but would eventually scale up to function as a default offline gallery across all smartphones.",
                        downloadLink: '/assets/case-studies/Dubuqu-Case-Study.pdf',
                    }
                 ]
                 this.setState({
                     cardList: [...filter]
                 })
             }else if(this.state.dropDownValue === "Fintech"){
                 filter =  [
                    {
                        cardName:"Tenshey",
                        cardDesc: "Tenshey is a start-up dedicated to advancing gender diversity through executive coaching,one female leader at a time.",
                        downloadLink: '/assets/case-studies/Tenshey-case-study.pdf'
                    }
                 ]
                 this.setState({
                     cardList: [...filter]
                 })
             }else if(this.state.dropDownValue === "Design"){
                filter =  [
                    {
                        cardName:"P2E",
                        cardDesc: "P2E Technologies is a regulatory change solution provider,keen on developing automation-driven technical solutions for handling regulatory changes and compliance functions.",
                        downloadLink: '/assets/case-studies/P2E-case-study.pdf'
                    },
                    {
                        cardName:"Gaadi Bazaar",
                        cardDesc: "Gaadi Bazaar, an initiative of the Cholamandalam Investment and Finance Company Limited, approached Ionixx with a specific business requirement.",
                        downloadLink: '/assets/case-studies/Gaadi-Bazaar-case-study.pdf'
                    },
                    {
                        cardName:"Tenshey",
                        cardDesc: "Tenshey is a start-up dedicated to advancing gender diversity through executive coaching,one female leader at a time.",
                        downloadLink: '/assets/case-studies/Tenshey-case-study.pdf'
                    }
                ]
                this.setState({
                    cardList: [...filter]
                })
            }
         })
     }
    render(){
        return (
            <div className="tab-design" id="pills-home" role="tabpanel" aria-labelledby="pills-home-tab">
                <div className="tab-head">
                    <div className="caption-sec">
                        <p>Detailed Case Studies including all the intricacies of how we solved the problem for our esteemed customers.</p>
                    </div>
                    <div className="filter" ref={node => { this.node = node; }}>
                        <div className="dropdown portfolio-drop">
                            <button className="dropdown-toggle" type="button" id="dropdownMenuButton"
                                    data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" onClick={this.handleDropDown}>
                                Filter by : <span>{this.state.dropDownValue}</span>   
                            </button>
                            {
                                this.state.dropDownShow && <div className="dropdown-menu" aria-labelledby="dropdownMenuButton">
                                {/* <a className="dropdown-item">Action</a>
                                <a className="dropdown-item">Another action</a>
                                <a className="dropdown-item">Something else here</a> */}
                                {
                                    this.state.dropDownList?.map((item)=>{
                                        return (
                                        <a className="dropdown-item" onClick={()=>this.getDropDown(item)}>{item}</a>
                                        )
                                    })
                                }
                            </div>
                            }
                            
                        </div>
                    </div>
                </div>

                    <div className="tab-card porti-card">
                        {
                            this.state.cardList.length > 0 ? this.state.cardList.slice(this.state.start, this.state.end)?.map((item)=>{
                                return (
                                    <div className="list">
                                    <div className="list-capt">
                                    <h4><span>{item.cardName}</span></h4>
                                    <p>{item.cardDesc}</p>
                                    </div>
                                    <div className="download-btn">
                                        {/* <a href={item.downloadLink} download={item.downloadLink.split('/').pop()}>
                                            <button><span><img src="/assets/img/download.svg"  alt="download " /></span>
                                                Download
                                            </button>
                                        </a> */}
                                        {/* <a href={item.routeLink}>
                                            <span class="case-study-read-more">
                                                Read more
                                            </span>
                                        </a> */}
                                        <Link to={item.routeLink}>
                                            <span class="case-study-read-more">
                                                Read more
                                            </span>
                                        </Link>
                                    </div>
                                </div>
                                )
                            }) : 
                            <div className="text-center no-data">Comming soon</div>
                        }
                         {
                        this.state.cardList.length > 3 && this.state.buttonShow === true? <div className="view-more">
                        <button onClick={this.viewMoreOnPress} >
                        {this.state.cardList.length > 3 && this.state.buttonShow ?  "View More" : "View Less" }
                            <span><img className={!this.state.buttonShow ?"rotate-view-more": "" }src="/assets/img/blue-arrow.svg"  alt="Arrow" /></span>
                        </button>
                    </div>  : ""
                    }    
                    </div> 

                
                 
                    {
                        this.state.cardList.length > 3 && this.state.buttonShow === false? <div className="view-more">
                        <button onClick={this.viewMoreOnPress} >
                        <span><img className={!this.state.buttonShow ?"rotate-view-more mt-0 mb-0": "" }src="/assets/img/blue-arrow.svg"  alt="Arrow"/></span>    
                        {this.state.cardList.length > 3 && this.state.buttonShow ?  "View More" : "View Less" }
                        </button>
                    </div>  : ""
                    }    
            </div>
        );
    }

}

export default CaseStudies;
