import React from 'react';
import "../../resources.css";
import {ResourceRoute} from '../../resource-routing';
import {Link} from "react-router-dom";
import NewsHeadings from "./common-action/news-heading";
import NewsSubscription from "./common-action/subscripbe-section";

class NewsIssue45 extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            pageEnabled: false,
        }

    }

    render() {
        return (
          <div className="newdesigned_issue_details">
            <NewsHeadings
              buttondDetail="Web3 & Blockchain"
              categoryType="web3"
              description="How Data Centralization Ends by 2030 | Coindesk"
            />
            <div className="newsissuedetailed">
              <div className="detailed-common">
                {/* <div className="back-to-common">
                    <span className="back-to-icon-hold">
                    <img src="/assets/img/resource/back-to-new.svg"  alt="bact to new"></img>
                    </span>
                    <Link  to={ResourceRoute.NEWSLETTER}><span className="back-to-link">Newsletter</span></Link>
                </div> */}

                <div className="news-letter whats-on-hold">
                  <div className="issue-main-head">
                    <h4>What’s on?</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.cnbc.com/2020/09/16/market-value-of-big-fintech-companies-rises-to-1-trillion-more-than-the-largest-banks.html"
                      >
                        <h4>
                          Market value of big FinTechcompanies rises to $1
                          trillion, more than the largest banks | CNBC.com
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        There may be a changing of the guard underway in
                        financials. The market caps of payment stocks like Visa
                        and MasterCard have eclipsed the value of Wall Street’s
                        biggest banks, even as their balance sheets remain
                        significantly smaller in comparison. Square, Visa,
                        PayPal and MasterCard are collectively worth $1.07
                        trillion, while the “big six” banks, which include
                        JPMorgan, Bank of America, Wells Fargo, Citigroup,
                        Morgan Stanley and Goldman Sachs are worth less than
                        $900 billion in total.
                      </p>
                    </div>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://cointelegraph.com/news/binance-usd-is-coming-to-dapper-labs-flow-blockchain"
                      >
                        <h4>
                          Binance USD is coming to Dapper Labs’ Flow blockchain
                          | Coin Telegraph
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        The U.S. dollar stablecoin of top crypto exchange
                        Binance, BUSD, has become the latest stable token to
                        announce it will launch on Dapper Labs’ Flow blockchain.
                        The news comes just days after the platform added the
                        rival USD Coin (USDC) stablecoin. Dapper Labs, the team
                        behind pioneering non-fungible token (NFT)-powered games
                        CryptoKitties and NBA TopShot, began working on Flow to
                        address the scaling issues with Ethereum (ETH) that
                        CryptoKitties starkly exposed after launching in late
                        2017
                      </p>
                    </div>
                  </div>
                </div>
                <div className="news-letter top-picks-hold">
                  <div className="issue-main-head">
                    <h4>Top Picks</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.coindesk.com/data-centralization-2030"
                      >
                        {" "}
                        <h4>How Data Centralization Ends by 2030 | Coindesk</h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        The next 10 years will witness the systematic
                        manipulation of human life at a scale unrivaled in
                        history. For all the recent controversies over privacy
                        and surveillance, the real threat is ahead of us. Unless
                        new approaches to online identity and data management
                        take hold, both governments and private actors will move
                        inexorably from knowing you to shaping you.
                        Blockchain-enabled decentralization will develop as the
                        only viable response to the iron logic of data
                        centralization.
                      </p>
                    </div>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://builtin.com/design-ux/10-insights-googles-material-design-guidelines"
                      >
                        {" "}
                        <h4>
                          Google's Material design guidelines offer a wealth of
                          useful information for designers. Here are ten key
                          takeaways that can improve your practice. |
                          Builtin.com
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Google is one of the biggest and most influential
                        players in the tech space, so it’s no mystery why its
                        design system has a tremendous impact on how digital
                        products are made. The company’s Material design
                        guidelines outline worthwhile principles for every
                        designer to follow — regardless of whether they’re
                        designing for iOS or Android. In this piece, I’ll share
                        my key takeaways from Material’s guidelines and provide
                        my take on how best to execute their rules and methods.
                        I’ll review best practices as laid out in the Material
                        guidelines and share tips for applying them to your own
                        products drawn from my experience.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="news-letter other-reads-hold">
                  <div className="issue-main-head">
                    <h4>Other Reads</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.theverge.com/2020/9/16/21439929/facebook-ar-smart-glasses-ray-ban-announcement"
                      >
                        {" "}
                        <h4>
                          Facebook’s first ‘smart glasses’ will be Ray-Bans,
                          coming next year | Tech Verge
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Facebook says its first pair of consumer “smart glasses”
                        will be releasing next year as a branded Ray-Ban
                        product, CEO Mark Zuckerberg announced during the
                        opening keynote of its all-virtual Facebook Connect
                        conference. It’s not clear what features the device will
                        have, but Facebook has confirmed to The Verge that the
                        device will not be classified as an AR device, and it
                        will not have an integrated display of any kind. That
                        suggests they may be closer to something like Snap
                        Spectacles or perhaps Amazon’s Echo Frames.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <NewsSubscription categoryType="Web3" />
          </div>
        );
    }

}

export default NewsIssue45;
