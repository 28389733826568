import React from 'react';
import { Link } from "react-router-dom";
import { ROUTES } from "../../routes";
import "../oms/oms.css";
import "./fin-tech.css";
import ContactUS from "../../components/contact-us";
import { PortfolioRoute } from "../portfolio/route-porfolio";
// import { ToastComponent } from '../../components/common';
import * as contactAction from "../../action/contactAction";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ReactSEOMetaTags from 'react-seo-meta-tags';
import Helmet from 'react-helmet';
import Testimonials from '../../components/testimonials/testimonials';
import TestimonialsNew from '../../components/testimonials-new/testimonialsNew';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Slider from 'react-slick';
class OMS extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            headerClassAdd: "",
            showContactUS: false,
            showToast: false
        }
        this.onScrollHeader = this.onScrollHeader.bind(this)
    }
    componentDidMount() {
        window.addEventListener('scroll', this.onScrollHeader);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.onScrollHeader);
    }
    onScrollHeader() {
        const winScroll =
            document.body.scrollTop || document.documentElement.scrollTop

        const height =
            document.documentElement.scrollHeight -
            document.documentElement.clientHeight

        const scrolled = winScroll / height

        if (scrolled === 0) {
            this.setState({
                headerClassAdd: ""
            })
        } else {
            this.setState({
                headerClassAdd: "scrollHeader"
            })
        }
    }
    sendToast() {
        this.setState({ showContactUS: true })
    }
    render() {
        var blockSettings = {
            // className: "center",
            dots: false,
            arrows: true,
            infinite: true,
            autoplay: true,
            centerMode: false,
            speed: 3000,
            speed: 2000,
            autoplaySpeed: 5000,
            pauseOnHover: false,
            slidesToShow: 3,
            slidesToScroll: 1,
            initialSlide: 0,
            responsive: [
                {
                    breakpoint: 1240,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: false,
                    },
                },
                {
                    breakpoint: 1098,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: false,
                    },
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                    },
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        centerMode: false,
                    },
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        centerMode: false,
                    },
                },
            ],
        };
        return (
            <>
                <Helmet>
                    <title>Custom Fintech Software Development Solutions | Ionixx Tech</title>
                    <meta name="description" content="Elevate your financial services with Ionixx's custom Fintech software development solutions. Tailored to your fintech needs, our innovative technology ensures seamless integration & unparalleled security." />
                    <link rel="canonical" href="https://www.ionixxtech.com/fintech-solutions" />
                    <meta property="og:image" content={"https://www.ionixxtech.com/assets/img/SEO_OG_IMAGE/Fintech-Solutions.jpg"} />

                </Helmet>
                <ReactSEOMetaTags
                    render={(el) => <Helmet>{el}</Helmet>}
                    website={{
                        name: "Ionixx Technologies",
                        title: "Custom Fintech Software Development Solutions | Ionixx Tech",
                        description: "Elevate your financial services with Ionixx's custom Fintech software development solutions. Tailored to your fintech needs, our innovative technology ensures seamless integration & unparalleled security.",
                        type: "website",
                        image: 'https://www.ionixxtech.com/assets/img/SEO_OG_IMAGE/Fintech-Solutions.jpg',
                        url: "https://www.ionixxtech.com/fintech-solutions"
                    }}
                    twitter={{
                        twitter: "https://www.ionixxtech.com/fintech-solutions"
                    }}
                />
                <div className="Enterprise fintech-updaed-design Healthcare oms">
                    <div className="section-1 common-hero-bg white-bg">
                        <div className="custom-container">
                            <div className="custom-section">
                                <div className="main-head-div">
                                    <h2 className="main-head-1 custom-htech-head">
                                        <span className="primary-span">Enabling End-to-end FinTech Software <br></br>
                                        Innovation with Emerging Tech</span>
                                    </h2>
                                    <h2>Optimised. Secure. Tailored to Your Needs</h2>
                                </div>

                                <div className="main-para-div">
                                    <Link to={ROUTES.CONTACTUS} className="btn btn-primary connect-btn">Talk to us</Link>
                                </div>
                                
                            </div>
                        </div>
                        
                    </div>
                    <div className="main-para-div short-div">
                        <ul>
                            <li> <a href='' target='_self'> Financial Services </a></li>
                            <li> <a href='https://www.ionixxtech.com/solution/fintech/brokerage' target='_self'>Digital Brokerage</a></li>
                            <li><a href='https://www.ionixxtech.com/solution/fintech/post-trade' target='_self'>Post Trade </a>   </li>
                            <li>  <a href='https://www.ionixxtech.com/solution/fintech/oms' target='_self'>    OMS</a></li>
                        </ul>
                    </div>
                    <div className="company-info beige-bg">
                        <div className="section-2 grey-fintech-bg"   
                        style={{ background: "linear-gradient(90deg, #FFFBF1 0%, #F9F9F9 50%), #F9F9F9" }}
                        >
                            <div className='custom-container'>
                                <div className="marketting-teams">
                                    <h1 className="main-head-1"> <span className="primary-span">Our Financial Software Development Services</span>
                                    </h1>
                                    <p>
                                        In the rapidly evolving landscape of financial services, Ionixx paves your path to financial success with tailored Fintech solutions that empower you to adapt, optimize, and thrive in progressive fintech industry.
                                    </p>
                                    <p> Our Fintech subject matter experts have been instrumental in successfully delivering 50+ comprehensive fintech applications for our valued customers ranging from financial institutions, startups, to enterprises.
                                    </p>
                                    <div className='team-detail'>
                                        <a href="https://calendly.com/ionixxtechnologies-office/30min?" target="_blank"><button name="Book a demo" className='Book-a-demo' >
                                            Book a demo
                                        </button></a>
                                        <a href="https://www.ionixxtech.com/contactus"><button className='consultation' name="Get a consultation" >
                                            Get a consultation
                                        </button></a>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="section-2 white-bg">
                            <div className="custom-container">
                                <div className="custom-section">
                                    <div className="row work-with-row">
                                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 work-with-col">
                                            <div className="left-info-hold">
                                                <div className="main-head-div custom-main-head-div">
                                                    <h2 className="main-head-1 main-head-2">
                                                        <span className="primary-span "> Ionixx’s FinTech Solutions</span>
                                                    </h2>
                                                    <p>Our expert fintech developers creates tailored financial software solutions leveraging the latest tech innovation to steamline complex workflows, reduce operational costs, and drive efficiency all while ensuring transparent and secure fintech software development.
                                                    </p><p>
                                                        Our expertise in fintech application development spans solutions ranging from brokerage to crypto apps and products.</p>
                                                </div>

                                            </div>
                                        </div>
                                        <div className='content-section'>
                                            <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                                                <div className='solution-list-item'>
                                                    <span className='oms-img-tag'>
                                                        <img src='/assets/img/fintech-img/borkerage.png' />
                                                    </span>
                                                    <div className='solution-card-data'>
                                                        <strong>Brokerage</strong>
                                                        <span>Experience streamlined trading processes and enhanced market insights with our Brokerage solutions, empowering your financial institution to thrive in dynamic markets.</span>
                                                    </div>
                                                </div>
                                            </div>


                                            <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                                                <div className='solution-list-item'>
                                                    <span className='oms-img-tag'>
                                                        <img src='/assets/img/fintech-img/post-trade.png' />
                                                    </span>
                                                    <div className='solution-card-data'>
                                                        <strong>Post trade</strong>
                                                        <span>Our post-trade solutions ensure efficient clearing, settlement & reporting, allowing you to reduce risk and achieve operational excellence in the post-trade process.</span>

                                                    </div>
                                                </div>
                                            </div>



                                            <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                                                <div className='solution-list-item'>
                                                    <span className='oms-img-tag'>
                                                        <img src='/assets/img/fintech-img/order-mgnt.png' />
                                                    </span>
                                                    <div className='solution-card-data'>
                                                        <strong>Order Management <br></br> System</strong>
                                                        <span>Maximize order execution efficiency and accuracy with our OMS (Order Management Solution), designed to optimize your investment management operations.</span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                                                <div className='solution-list-item'>
                                                    <span className='oms-img-tag'>
                                                        <img src='/assets/img/fintech-img/blockchain.png' />
                                                    </span>
                                                    <div className='solution-card-data'>
                                                        <strong>Blockchain &<br></br> Cryptocurrency Solutions</strong>
                                                        <span>Unlock the potential of blockchain and digital currencies with our cutting-edge solutions, revolutionizing the way you transact and secure assets.</span>
                                                    </div>
                                                </div>
                                            </div>



                                            <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                                                <div className='solution-list-item'>
                                                    <span className='oms-img-tag'>
                                                        <img src='/assets/img/fintech-img/regulatory.png' />
                                                    </span>
                                                    <div className='solution-card-data'>
                                                        <strong>Regulatory Technology <br></br>(RegTech)</strong>
                                                        <span>Stay compliant effortlessly with our RegTech solutions, simplifying your regulatory reporting, KYC, and AML processes while reducing compliance risks.</span>

                                                    </div>
                                                </div>
                                            </div>

                                            <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                                                <div className='solution-list-item'>
                                                    <span className='oms-img-tag'>
                                                        <img src='/assets/img/fintech-img/payment-solution.png' />
                                                    </span>
                                                    <div className='solution-card-data'>
                                                        <strong>Payment Solutions</strong>
                                                        <span>Enhance payment processing with our Payment Solutions, offering secure, convenient, and scalable payment options to meet evolving market demands.</span>
                                                    </div>
                                                </div>
                                            </div>




                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <div className="">
                        <div className='section-7 financial-industries'>
                        <div className='custom-container'>
                                <div className="main-head-div mb-2">
                                    <h3 className="main-head-1">
                                        <span className="primary-span new-heading">Financial Industries We serve</span>
                                    </h3>
                                </div>
                                <div className="main-head-div mb-0">
                                    <p>We drive revolution with our custom fintech solutions curated specifically  to empower businesses in a dynamic financial landscape. Partner with us to lead in industry innovation.</p>
                                </div>
                                </div>
                            <div className='custom-container'>
                               

                                <div className='health-blogs'>
                                    <Slider {...blockSettings}>
                                        <div className='health-wrap'>
                                            <div className='health-blog-list'>
                                                <strong>Capital<br></br> Markets</strong>
                                                <p>Elevate your Capital Markets operations with our cutting-edge fintech solutions and fintech software development expertise. We empower you to stay ahead in the fast-paced world of financial trading.</p>
                                                {/* <div className='readmore'>
                                                    <a href='https://blog.ionixxtech.com/6-key-benefits-of-an-automated-trade-order-management-system/'>Read more</a>
                                                </div> */}
                                            </div>
                                        </div>
                                        <div className='health-wrap'>
                                            <div className='health-blog-list'>
                                                <strong>Asset<br></br> Managers</strong>
                                                <p>Asset Managers rely on our fintech application development to optimize portfolio management and client service. Our fintech solutions are tailored to help you navigate evolving market dynamics with confidence.</p>

                                            </div>
                                        </div>
                                        <div className='health-wrap'>
                                            <div className='health-blog-list'>
                                                <strong>Investment<br></br> Banking</strong>
                                                <p>Our fintech software development services create innovative tools for deal execution and relationship management. Stay competitive and agile with our fintech solutions designed for this dynamic sector.</p>

                                            </div>
                                        </div>
                                        <div className='health-wrap'>
                                            <div className='health-blog-list'>
                                                <strong>Hedge<br></br> Funds</strong>
                                                <p>Hedge Funds leverage our fintech expertise for precision in asset allocation and real-time data analytics, empowering you to make data-driven decisions and enhance profitability through our fintech application development services.</p>
                                            </div>
                                        </div>

                                    </Slider>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="section-5 white-bg">

                        <div className="custom-container">
                            <div className="custom-section">
                                <div className="row work-with-row">
                                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 work-with-col">
                                        <div className="left-info-hold">
                                            <div className="main-head-div custom-main-head-div">
                                                <h3 className="main-head-1 main-head-2">
                                                    <span className="primary-span ">Ionixx helps businesses <br></br>throughout the financial lifecycle</span>
                                                </h3>

                                            </div>

                                        </div>
                                    </div>
                                    <div className='content-section'>
                                        <div className='solution-list-item'>

                                            <div className='solution-card-data'>
                                                <strong>
                                                    <span className='oms-img-tag'>
                                                        <img src='/assets/img/fintech-img/Increase.png' />
                                                    </span>
                                                </strong>
                                                <span>Comprehensive<br></br> Market Analysis</span>
                                            </div>
                                        </div>


                                        <div className='solution-list-item'>

                                            <div className='solution-card-data'>
                                                <strong>
                                                    <span className='oms-img-tag'>
                                                        <img src='/assets/img/fintech-img/Private.png' />
                                                    </span>
                                                </strong>
                                                <span>Regulatory<br></br> Compliance</span>

                                            </div>
                                        </div>



                                        <div className='solution-list-item'>

                                            <div className='solution-card-data'>
                                                <strong>
                                                    <span className='oms-img-tag'>
                                                        <img src='/assets/img/fintech-img/GDPR.png' />
                                                    </span>
                                                </strong>
                                                <span>Preventing<br></br> Fraud</span>
                                            </div>
                                        </div>

                                        <div className='solution-list-item'>

                                            <div className='solution-card-data'>
                                                <strong>
                                                    <span className='oms-img-tag'>
                                                        <img src='/assets/img/fintech-img/Fingerprint.png' />
                                                    </span>
                                                </strong>
                                                <span>Verification and <br></br> Issue Resolution</span>
                                            </div>
                                        </div>



                                        <div className='solution-list-item'>

                                            <div className='solution-card-data'>
                                                <strong>
                                                    <span className='oms-img-tag'>
                                                        <img src='/assets/img/fintech-img/Growth.png' />
                                                    </span>
                                                </strong>
                                                <span>Scalability and <br></br>Adaptability</span>

                                            </div>
                                        </div>

                                        <div className='solution-list-item'>
                                            <div className='solution-card-data'>
                                                <strong>
                                                    <span className='oms-img-tag'>
                                                        <img src='/assets/img/fintech-img/Group of Projects.png' />
                                                    </span>
                                                </strong>
                                                <span>Enhancing Portfolio <br></br>Performance <br></br>and Efficiency</span>
                                            </div>
                                        </div>

                                        <div className='solution-list-item'>
                                            <div className='solution-card-data'>
                                                <strong>
                                                    <span className='oms-img-tag'>
                                                        <img src='/assets/img/fintech-img/Rules Book.png' />
                                                    </span>
                                                </strong>
                                                <span>Risk Evaluation and Efficient Decision-Making</span>
                                            </div>
                                        </div>

                                        <div className='solution-list-item'>
                                            <div className='solution-card-data'>
                                                <strong>
                                                    <span className='oms-img-tag'>
                                                        <img src='/assets/img/fintech-img/Security Shield.png' />
                                                    </span>
                                                </strong>
                                                <span>Data Security and <br></br>Future-Ready Solution</span>
                                            </div>
                                        </div>



                                        <div className='solution-list-item'>
                                            <div className='solution-card-data'>
                                                <strong>
                                                    <span className='oms-img-tag'>
                                                        <img src='/assets/img/fintech-img/Goal.png' />
                                                    </span>
                                                </strong>
                                                <span>Innovation and<br></br> Competitive <br></br>Edge</span>
                                            </div>
                                        </div>

                                        <div className='solution-list-item'>
                                            <div className='solution-card-data'>
                                                <strong>
                                                    <span className='oms-img-tag'>
                                                        <img src='/assets/img/fintech-img/Business Group.png' />
                                                    </span>
                                                </strong>
                                                <span>Business Solutions for Startups and Enterprises</span>
                                            </div>
                                        </div>




                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>






                    <div className="section-6 white-bg">
                        <div className="custom-container">
                            <div className="custom-section">
                                <div className="row work-with-row">
                                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 work-with-col">
                                        <div className="left-info-hold">
                                            <div className="main-head-div custom-main-head-div">
                                                <h4 className="main-head-1 main-head-2">
                                                    <span className="primary-span">Our Curated Technology Stack</span>
                                                </h4>
                                            </div>

                                        </div>
                                    </div>
                                    <div className='content-section oms-sol-pro'>
                                        <img src='/assets/img/fintech-img/Animesh- Corporate deck ppt 1.png' />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="section-7 grey-bg">
                        <div className="custom-container">
                            <div className="custom-section">
                                <div className="row work-with-row">
                                    <div className="left-info-hold">
                                            <div className="main-head-div custom-main-head-div">
                                                <h3 className="main-head-1 main-head-2">
                                                    <span className="primary-span">Our Success Stories on Financial Services</span>
                                                    <span className="secondary-span">Fintech Software Development Case Studies</span>

                                                </h3>
                                            </div>
                                        </div>
                                    {/* <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 work-with-col">
                                    </div> */}
                                    <div className='content-section row'>
                                        <div className='col-lg-4 col-md-4 col-sm-12 col-12'>
                                            <div className='solution-list-item'>
                                                <strong>
                                                    <img src='/assets/img/portfolio-img/icons/cryptospace.svg' />
                                                </strong>
                                                <p>Ionixx partnered with Cryptospace to develop a scalable crypto trading platform for first time users</p>
                                                <a href='https://www.ionixxtech.com/case-studies/cryptospace' target='_blank'> Read more...</a>
                                            </div>
                                        </div>

                                        <div className='col-lg-4 col-md-4 col-sm-12 col-12'>
                                            <div className='solution-list-item'>
                                                <strong>
                                                    <img src='/assets/img/portfolio-img/icons/postTrade.svg' />
                                                </strong>
                                                <p>Ionixx partnered with a Brokerage firm to lead the digital transformation of their back-office trading operations.</p>
                                                <a href='https://www.ionixxtech.com/case-studies/post-trade-operations'  target='_blank'> Read more...</a>
                                            </div>
                                        </div>

                                        <div className='col-lg-4 col-md-4 col-sm-12 col-12'>
                                            <div className='solution-list-item'>
                                                <strong>
                                                    <img src='/assets/img/portfolio-img/icons/p2e.svg' />
                                                </strong>
                                                <p>Ionixx worked with P2E to build a cutting-edge compliance management soluti</p>
                                                <a  href='https://www.ionixxtech.com/portfolio/p2e'  target='_blank'> Read more...</a>
                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="section-8 grey-bg">
                        <div className="custom-container">
                            <div className="custom-section">
                                <div className="row work-with-row">
                                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 work-with-col">
                                        <div className="left-info-hold">
                                            <div className="main-head-div custom-main-head-div">
                                                <h2 className="main-head-1 main-head-2">
                                                    <span className="primary-span">Why Choose Ionixx for your Fintech<br></br> Software Development?</span>
                                                </h2>
                                                <p>When it comes to Fintech Software Development, Ionixx emerges as the<br></br> undeniable choice for a multitude of reasons</p>
                                            </div>

                                        </div>
                                    </div>
                                    <div className='content-section row'>
                                        <div className='col-lg-4 col-md-4 col-sm-12 col-12'>
                                            <div className='solution-list-item'>
                                                <strong>
                                                    Unparalleled<br></br> Expertise                                                                        </strong>
                                                <p>We bring this extensive experience to every project, ensuring that your fintech solution is crafted with the utmost precision and insight.</p>
                                            </div>
                                        </div>

                                        <div className='col-lg-4 col-md-4 col-sm-12 col-12'>
                                            <div className='solution-list-item'>
                                                <strong>
                                                    Fortified<br></br> Security
                                                </strong>
                                                <p>We implement state-of-the-art security measures, protecting your software against cyber threats, fraud, and data breaches.</p>
                                            </div>
                                        </div>

                                        <div className='col-lg-4 col-md-4 col-sm-12 col-12'>
                                            <div className='solution-list-item'>
                                                <strong>
                                                    Regulatory<br></br> Assurance
                                                </strong>
                                                <p>We navigate the complex web of fintech regulations with ease, ensuring that your software not only meets but exceeds the necessary standards for security and trust.</p>
                                            </div>
                                        </div>

                                        <div className='col-lg-12 col-md-12 col-sm-12 col-12'>
                                            <div className='solution-list-item'>
                                                <strong>
                                                    Continuous Innovation                                                                        </strong>
                                                <p>With Ionixx as your partner, you gain access to cutting-edge fintech software development techniques and technologies, positioning your business at the forefront of industry innovation.</p>
                                            </div>
                                        </div>

                                        <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                                            <div className='solution-list-item'>
                                                <strong>Scalability at its Core   </strong>
                                                <p>We craft solutions with scalability at their core, guaranteeing that your technology can seamlessly grow alongside your business, accommodating increasing user volumes and evolving market demands</p>

                                            </div>
                                        </div>

                                        <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                                            <div className='solution-list-item'>
                                                <strong>User-Centric Design </strong>
                                                <p>We prioritize user experiences. Ionixx, fintech company delivers intuitive and engaging user interfaces that enhance customer engagement and satisfaction.</p>

                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="section-9 beige-bg">
                        <div className="custom-container">
                            <div className="custom-section">
                                <div className="left-info-hold">
                                    <div className="main-head-div custom-main-head-div">
                                            <h3 className="main-head-1 main-head-2">
                                                <span className="primary-span">Building FinTech of The Future</span>
                                            </h3>
                                            <p>We custom build  full-stack FinTech software solutions that are resilient, scalable, and high-performing.</p>
                                    </div>
                                </div>
                                <div className='content-section'>
                                    <div className="main-para-div">
                                        <Link to={ROUTES.CONTACTUS} className="btn btn-primary connect-btn">Talk to us</Link>
                                    </div>
                                 </div>
                                {/* <div className="row work-with-row">
                                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 work-with-col">
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>

                    <div className="section-10 beige-bg company-info development-solution faq-listwrp">
                        <div className="custom-container">
                            <div className="custom-section">
                                <div className="main-head-div heading_lines">
                                    <h1 className="main-head-1">
                                        <span className="primary-span">

                                            Frequently <em>Asked</em> Questions
                                        </span>
                                    </h1>
                                </div>
                                <div className="row work-with-row">
                                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 work-with-col">
                                        <div className={`parent ${this.state.showMore && 'active'}`}>
                                            <Accordion className="faq-list">
                                                <AccordionSummary
                                                    expandIcon={<ExIcon />}
                                                    aria-controls="panel1a-content"
                                                    id="panel1a-header"
                                                    className="summary"
                                                >
                                                    <h2 className="summary-content">
                                                        <span className="query-content">What industries benefit from Ionixx's fintech software solutions?</span></h2>
                                                </AccordionSummary>
                                                <AccordionDetails className="faq-details">
                                                    <Typography>
                                                        <span className="padding-left">Ionixx's fintech software solutions cater to a wide range of industries, including banking, healthcare, blockchain, insurance, investment, and more. We create customized solutions to suit the specific needs of each sector.
                                                        </span>
                                                    </Typography>
                                                </AccordionDetails>
                                            </Accordion>
                                            <Accordion className="faq-list">
                                                <AccordionSummary
                                                    expandIcon={<ExIcon />}
                                                    aria-controls="panel1a-content"
                                                    id="panel1a-header"
                                                    className="summary"
                                                >
                                                    <h2 className="summary-content">
                                                        <span className="query-content"> What is the expected timeline for the development of Fintech Software? </span></h2>
                                                </AccordionSummary>
                                                <AccordionDetails className="faq-details">
                                                    <Typography>
                                                        <span className="padding-left"> The development timeline for your fintech app is determined by its complexity and specific requirements. Being a fintech software development company, our initial step involves a thorough understanding of our client's needs, followed by the creation of a comprehensive roadmap aligned with their business goals and objectives.</span>
                                                    </Typography>
                                                </AccordionDetails>
                                            </Accordion>
                                            <Accordion className="faq-list">
                                                <AccordionSummary
                                                    expandIcon={<ExIcon />}
                                                    aria-controls="panel1a-content"
                                                    id="panel1a-header"
                                                    className="summary"
                                                >
                                                    <h2 className="summary-content">
                                                        <span className="query-content">Can Ionixx assist in blockchain and cryptocurrency solutions?</span></h2>
                                                </AccordionSummary>
                                                <AccordionDetails className="faq-details">
                                                    <Typography>
                                                        <span className="padding-left">  Yes, We, Ionixx offer expertise in blockchain and cryptocurrency solutions. We help businesses harness the potential of these technologies to enhance security and efficiency in financial transactions.</span>

                                                    </Typography>

                                                </AccordionDetails>
                                            </Accordion>
                                            <Accordion className="faq-list">
                                                <AccordionSummary
                                                    expandIcon={<ExIcon />}
                                                    aria-controls="panel1a-content"
                                                    id="panel1a-header"
                                                    className="summary"
                                                >
                                                    <h2 className="summary-content">
                                                        <span className="query-content">
                                                            What advantages does Ionixx offer for fintech software development?</span></h2>
                                                </AccordionSummary>
                                                <AccordionDetails className="faq-details">
                                                    <Typography className="padding-left">
                                                        Ionixx brings extensive expertise in fintech software development, ensuring innovative, secure, and customized solutions tailored to your unique business needs.                                                                            </Typography>
                                                </AccordionDetails>
                                            </Accordion>

                                            <div className="child">
                                                <Accordion className="faq-list">
                                                    <AccordionSummary
                                                        expandIcon={<ExIcon />}
                                                        aria-controls="panel1a-content"
                                                        id="panel1a-header"
                                                        className="summary"
                                                    >
                                                        <h2 className="summary-content">
                                                            <span className="query-content">
                                                                What are the services Ionixx provides in fintech solutions?</span></h2>
                                                    </AccordionSummary>
                                                    <AccordionDetails className="faq-details">
                                                        <Typography className="padding-left">
                                                            <span>Ionixx provides a comprehensive range of services in fintech solutions, including</span>
                                                            <div className="inline-accordion-details">
                                                                <p>Fintech Software Development</p>
                                                                <p>Fintech Application Development</p>
                                                                <p>Fintech Consulting</p>
                                                                <p>Blockchain and Cryptocurrency Solutions</p>
                                                                <p>Regulatory Technology (RegTech)</p>
                                                                <p>Payment Solutions</p>
                                                                <p>User-Centric Design</p>
                                                                <p>Data Security</p>
                                                                <p>Customization</p>
                                                                <p>Support and Maintenance</p>

                                                            </div>
                                                        </Typography>
                                                    </AccordionDetails>
                                                </Accordion>
                                                <Accordion className="faq-list">
                                                    <AccordionSummary
                                                        expandIcon={<ExIcon />}
                                                        aria-controls="panel1a-content"
                                                        id="panel1a-header"
                                                        className="summary"
                                                    >
                                                        <h2 className="summary-content">
                                                            <span className="query-content">
                                                                What is the cost range of developing a fintech software?
                                                            </span></h2>

                                                    </AccordionSummary>
                                                    <AccordionDetails className="faq-details">
                                                        <Typography className="padding-left">
                                                            The cost range of fintech software applications varies according to the demands and requirements of any client for their features and security add-ons. Contact our experts to get a quote for fintech software.
                                                        </Typography>
                                                    </AccordionDetails>
                                                </Accordion>
                                                <Accordion className="faq-list">
                                                    <AccordionSummary
                                                        expandIcon={<ExIcon />}
                                                        aria-controls="panel1a-content"
                                                        id="panel1a-header"
                                                        className="summary"
                                                    >
                                                        <h2 className="summary-content">
                                                            <span className="query-content">
                                                                How can I initiate a partnership with Ionixx for fintech solutions?
                                                            </span></h2>
                                                    </AccordionSummary>
                                                    <AccordionDetails className="faq-details">
                                                        <Typography className="padding-left" >
                                                            Beginning a partnership with Ionixx is straightforward. You can contact us through our website or directly connect with our team to discuss your fintech software development needs and explore collaboration possibilities.                                                                                </Typography>
                                                    </AccordionDetails>
                                                </Accordion>

                                                <Accordion className="faq-list">
                                                    <AccordionSummary
                                                        expandIcon={<ExIcon />}
                                                        aria-controls="panel1a-content"
                                                        id="panel1a-header"
                                                        className="summary"
                                                    >
                                                        <h2 className="summary-content">
                                                            <span className="query-content">
                                                                What do Ionixx’s fintech software developers do?
                                                            </span></h2>
                                                    </AccordionSummary>
                                                    <AccordionDetails className="faq-details">
                                                        <Typography className="padding-left">
                                                            Our Fintech software developers are responsible for creating, implementing, and managing financial software solutions. They leverage their expertise in mobile and web development, DevOps, cloud computing, AI, IoT, and blockchain to design and maintain these solutions. They work closely with clients to tailor the software to their unique needs and ensure it stays current with the ever-evolving fintech landscape and emerging technologies.                                                                                </Typography>
                                                    </AccordionDetails>
                                                </Accordion>

                                            </div>

                                        </div>

                                        <div className="btn-wrp">
                                            <button className="btn" onClick={() => { this.setState({ showMore: !this.state.showMore }) }}>{this.state.showMore ? 'View Less' : 'View More'} </button>
                                        </div>



                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>








                    <ContactUS show={this.state.showContactUS} handleClose={() => this.setState({ showContactUS: false })} />
                </div>
            </>
        );
    }

}


const mapStateToProps = (state, ownProps) => {
    return state.commonReducer;
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return bindActionCreators(contactAction, dispatch);
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(OMS);
function ExIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="14" viewBox="0 0 24 14" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M23.5227 3.04159L13.1523 13.412C12.5159 14.0484 11.4841 14.0484 10.8477 13.412L0.477287 3.04159C-0.159096 2.40521 -0.159096 1.37343 0.477287 0.737051C1.11367 0.100669 2.14545 0.100669 2.78183 0.737051L12 9.95522L21.2182 0.737052C21.8546 0.10067 22.8863 0.10067 23.5227 0.737052C24.1591 1.37343 24.1591 2.40521 23.5227 3.04159Z" fill="#42C2A2" />
        </svg>
    )
}
