import React from 'react';
import "../../resources.css";
import {ResourceRoute} from '../../resource-routing';
import {Link} from "react-router-dom";
import NewsHeadings from "./common-action/news-heading";
import NewsSubscription from "./common-action/subscripbe-section";

class NewsIssue53 extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            pageEnabled: false,
        }

    }

    render() {
        return (
          <div className="newdesigned_issue_details">
            <NewsHeadings
              buttondDetail="Web3 & Blockchain"
              categoryType="web3"
              description="How Low Code Is Transforming Software Development | DevOps.com"
            />
            <div className="newsissuedetailed">
              <div className="detailed-common">
                {/* <div className="back-to-common">
                    <span className="back-to-icon-hold">
                        <img src="/assets/img/resource/back-to-new.svg"  alt="back to new"></img>
                    </span>
                    <Link  to={ResourceRoute.NEWSLETTER}><span className="back-to-link">Newsletter</span></Link>
                </div> */}

                <div className="news-letter whats-on-hold">
                  <div className="issue-main-head">
                    <h4>What’s on?</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://cointelegraph.com/news/blockchain-based-ecosystem-to-decentralize-banking-services-globally"
                      >
                        <h4>
                          Blockchain-based ecosystem to decentralize banking
                          services globally | Cointelegraph
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        A new blockchain-based payment system is aiming to
                        create infrastructure that will digitize banking like
                        never before — bringing together banks, regulators and
                        consumers.Cryptoenter says its goal is to blur the lines
                        between fiat and crypto. The platform will deliver
                        services that take inspiration from DeFi, all while
                        providing levels of reliability that traditional banks
                        have become renowned for.
                      </p>
                    </div>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.bloomberg.com/news/storythreads/2020-11-18/the-bitcoin-comeback-is-crypto-finally-going-mainstream"
                      >
                        <h4>
                          The Bitcoin Comeback: Is Crypto Finally Going
                          Mainstream? | Bloomberg.com{" "}
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        After a couple of big crashes that destroyed billions in
                        value, the digital currency has rebounded to its highest
                        value since January 2018, crossing $18,000 this week.
                        The cause: a flurry of developments that suggest Bitcoin
                        has taken some big steps toward going mainstream. In
                        October, global payments giant PayPal Holdings Inc.
                        started permitting its customers to buy and sell Bitcoin
                        and other cryptocurrencies from their accounts. A number
                        of tech companies, including payments player Square
                        Inc., have begun holding portions of their cash reserves
                        in Bitcoin
                      </p>
                    </div>
                  </div>
                </div>
                <div className="news-letter top-picks-hold">
                  <div className="issue-main-head">
                    <h4>Top Picks</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://devops.com/how-low-code-is-transforming-software-development/"
                      >
                        <h4>
                          How Low Code Is Transforming Software Development |
                          DevOps.com{" "}
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Low code/no code is a business catalyst enabling
                        technologies to automate processes and allowing
                        developers to create real-time solutions for their
                        business. With low code, businesses are able to build,
                        customize and deploy apps with little to no coding.In
                        this interview, Rackspace CTO Jeff DeVerter explains how
                        low code is transforming software development and how
                        Robotic Process Automation (RPA) complements low-code
                        application platforms
                      </p>
                    </div>
                  </div>
                </div>
                <div className="news-letter other-reads-hold">
                  <div className="issue-main-head">
                    <h4>Other Reads</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.technologyreview.com/2020/11/12/1011944/artificial-intelligence-replication-crisis-science-big-tech-google-deepmind-facebook-openai/1111"
                      >
                        <h4>
                          AI is wrestling with a replication crisis | MIT
                          Technology Review
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Last month Nature published a damning response written
                        by 31 scientists to a study from Google Health that had
                        appeared in the journal earlier this year. Google was
                        describing successful trials of an AI that looked for
                        signs of breast cancer in medical images. But according
                        to its critics, the Google team provided so little
                        information about its code and how it was tested that
                        the study amounted to nothing more than a promotion of
                        proprietary tech.The problem is not simply academic. A
                        lack of transparency prevents new AI models and
                        techniques from being properly assessed for robustness,
                        bias, and safety. AI moves quickly from research labs to
                        real-world applications, with direct impact on people’s
                        lives. But machine-learning models that work well in the
                        lab can fail in the wild—with potentially dangerous
                        consequences.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <NewsSubscription categoryType="Web3" />
          </div>
        );
    }

}

export default NewsIssue53;
