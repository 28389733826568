import React, {useEffect} from "react";
import Zoom from 'react-reveal/Fade';

const UserDeepDrive = ({opacity, page}) => {

    return (<section id={'userDeepDive'} className={"section-2 blue-bg company-info "+(page===1?"":"")}  style={{opacity: opacity}}>
            <div className="custom-container">
                <div className="custom-section">
                    {/* <Zoom delay={400} key={page} top when={page === 1}> */}
                    <div className="row work-with-row">
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 des-tab-resp">
                            <div className="left-info-hold">
                                <div className="overal-sec">
                                    <div className="design-section">
                                        <div className="step-count step-div">
                                            <h3>Step 2</h3>
                                        </div>
                                        <div className="highlight-text">
                                            <h4><span>User Deep Dive</span></h4>
                                        </div>
                                        <div className="main-para-div">
                                            <p>We dive into the lived experiences of users to design with their
                                                motivations, views, and values in mind.</p>
                                            <p>Our design team develops comprehensive user journeys and scenarios. We
                                                prepare a design brief to map all possible user functions and work
                                                closely with your team to test product prototypes.</p>
                                        </div>
                                    </div>
                                    <div className="design-section mt-3">
                                        <div className="step-div">
                                            <h6>This leads to →</h6>
                                        </div>
                                        <div className="highlight-blue">
                                            <h4>Humanized Technology</h4>
                                        </div>
                                        <div className="main-para-div">
                                            <p>Users compare you to their last best experience. Our process ensures your
                                                product seamlessly integrates into their digital lives.</p>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 comp-info-right-col">
                            <div className="right-img-hold">
                                <div className="comp-info-right-img">
                                            <span className="company-offer-imgs">
                                                <img src="/assets/img/design-img/user-deep-drive.svg" alt="User Deep Dive" title="User Deep Dive"/>
                                            </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* </Zoom> */}
                </div>
            </div>
        </section>
    )
}

export default UserDeepDrive
