import React from 'react';
import {Link} from "react-router-dom";
import { ROUTES } from '../../../../../routes';
import {ResourceRoute} from '../../../resource-routing';
import PaperBooksDownload from "./../paper-books-download";

class ChallengeSecurityBlockChain extends React.Component {
    constructor(props) {
        super(props)
    }

    render() {
        return (
            <>
                        
        <div className="newsissuedetailed">
            <div className="detailed-common">
                <div className="back-to-common">
                    <span className="back-to-icon-hold">
                        <img src="/assets/img/resource/back-to-new.svg"  alt="bact to new"></img>
                    </span>
                    <Link to={ResourceRoute.WHITEPAPER_TAB}><span className="back-to-link">Whitepapers & e-Books</span></Link>
                </div>

                <div className="whats-on-hold">
                   
                    <div className="ebook-sec">
						<div className="row">
							<div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 resp-apprch-col">
								<div className="custom-section work-with-custom-section">
                                <div class="main-head-div">
                                    <h1 class="main-head-1">
                                        <span class="secondary-span">Challenges of Performance and Scalability Issues with Blockchain</span>
                                    </h1>
                                </div>
									<div className="main-para-div">
                                    <p>As many benefits that <Link to={ROUTES.BlOCKCHAIN_NEW}>blockchain</Link> promises, there are a few inherent challenges that it poses. The primary challenge lies with how it is still in its nascent stages and therefore brings to the fore several security issues.</p>
                            <p>Some argue that while no technology is completely secure, no one has yet managed to break the encryption and decentralised architecture of a blockchain.</p>
                            <p>Sign up for our free eBook today and get a thorough understanding of the security concerns that cloud blockchain technology.</p>
										{/* <a class="d-hide m-show" href="">View our software portfolio</a> */}
									</div>
								</div>
							</div>
							<div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 resp-apprch-col">
								<div class="ebook-form-sec">
                                  <div class="modal-title">
                                    <h4>Tell us a little about you</h4>
                                  </div>
                                  <div class="ebook-dwnld-form">
                                  <PaperBooksDownload pdfUrl={this.props?.location?.state} />
                                  </div>
                            
                                </div>
							</div>
						</div>	
		            </div>
                </div>

            </div>
        </div> 
            </>
        );
    }

}

export default ChallengeSecurityBlockChain;
