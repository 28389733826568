import React from 'react';
import "../../resources.css";
import { ResourceRoute } from '../../resource-routing';
import { Link } from "react-router-dom";
import NewsHeadings from "./common-action/news-heading";
import NewsSubscription from "./common-action/subscripbe-section";

class NewsIssue14 extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            pageEnabled: false,
        }

    }

    render() {
        return (
          <div className="newdesigned_issue_details">
            <NewsHeadings
              buttondDetail="Web3 & Blockchain"
              categoryType="web3"
              description="Can Blockchain Survive Mass Adoption? Future Perils Disclosed | Coin Telegraph"
            />

            <div className="newsissuedetailed">
              <div className="detailed-common">
                {/* <div className="back-to-common">
                            <span className="back-to-icon-hold">
                                <img src="/assets/img/resource/back-to-new.svg" alt="bact to new"></img>
                            </span>
                            <Link to={ResourceRoute.NEWSLETTER}><span className="back-to-link">Newsletter</span></Link>
                        </div> */}

                <div className="news-letter whats-on-hold">
                  <div className="issue-main-head">
                    <h4>What’s on?</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://fortune.com/2020/02/13/verizon-5g-mobile-network-double-number-of-cities/"
                      >
                        <h4>
                          Verizon to double the number of cities with its 5G
                          mobile service this year | Fortune
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Verizon plans to double the number of cities covered by
                        its superfast 5G wireless network by the end of the year
                        while expanding coverage in the areas where it already
                        has service.The company said on Thursday that it hopes
                        to offer 5G for mobile customers in 60 cities (up from
                        31 at the end of 2019) and 10 cities for home 5G
                        Internet service (up from five).
                      </p>
                    </div>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://techcrunch.com/2020/02/13/facebooks-latest-experiment-is-hobbi-an-app-to-document-your-personal-projects/"
                      >
                        <h4>
                          Facebook’s latest experiment is Hobbi, an app to
                          document your personal projects | Techcrunch
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Facebook is adding another app to its group of
                        experimental projects from the NPE Team, an initiative
                        it announced last year focused on rapidly trying out new
                        ideas in social to see how users react. This week, the
                        team released its fourth app experiment with the launch
                        of Hobbi, a photo and video sharing app designed for
                        documenting your personal projects and hobbies.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="news-letter top-picks-hold">
                  <div className="issue-main-head">
                    <h4>Top Picks</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://cointelegraph.com/news/can-blockchain-survive-mass-adoption-future-perils-disclosed"
                      >
                        <h4>
                          Can Blockchain Survive Mass Adoption? Future Perils
                          Disclosed | Coin Telegraph
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Prediction can be a bit of a gamble. And when
                        cryptocurrency and blockchain are involved, it wouldn’t
                        be surprising to see people shying away from scrying
                        experiments of any sort. Blockchain gets a bad rep
                        because of its relation to Bitcoin (BTC), the
                        cryptocurrency that many love to hate due to its
                        apparently volatile value.
                      </p>
                    </div>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.infoworld.com/article/3526480/whats-next-for-serverless-architecture.html"
                      >
                        <h4>
                          What’s next for serverless architecture? | InfoWorld
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Serverless services are everywhere. The driving force
                        behind an evolution towards a new way of programming,
                        serverless offerings come in all forms and shapes
                        including application hosting platforms, serverless
                        databases, CDNs, security offerings, etc. Automatic
                        distribution of logic and data to the edge will bring
                        minimal latency to end users, without provisioning,
                        scaling, or configuration worries for developers.{" "}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="news-letter other-reads-hold">
                  <div className="issue-main-head">
                    <h4>Other Reads</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.wired.com/story/wired-guide-internet-of-things/"
                      >
                        <h4>
                          The WIRED Guide to the Internet of Things | Wired
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        How many engineers does it take to change a lightbulb?
                        Depends on whether or not that lightbulb is connected to
                        Wi-Fi. Lightbulbs, along with refrigerators, coffee
                        makers, microwave ovens, baby monitors, security
                        cameras, speakers, televisions, and thermostats have, in
                        the past few decades, transformed from ordinary objects
                        into conduits for the future.{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <NewsSubscription categoryType="Web3" />
          </div>
        );
    }

}

export default NewsIssue14;
