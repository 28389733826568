import React from "react";
import {ROUTES} from '../../routes.js';
import {Link} from "react-router-dom";

const WorkWithUs = ({}) => {

    return (  <div className="crypto-service section-2 beige-bg">
            <div className="custom-container">
                <div className="custom-section">
                    <div className="main-head-div custom-main-head-div">
                        <h4 className="main-head-1">
                            <span className="primary-span">Let’s get started!</span>
                            <span className="secondary-span short-div">We’ve supported teams with projects at every stage of development. </span>
                        </h4>
                        <p className="work-p">Work with us to:</p>
                    </div>
                    <div className="row">
                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                            <div className="service-img ylow-bx" title="Bring your vision to life">
                                <img src="/assets/img/design-img/Vision.svg" alt="Bring your vision to life" title="Bring your vision to life"/>
                            </div>
                            <div className="service-head">
                                <h3>Bring your vision to life</h3>
                            </div>
                            <div className="main-para-div">
                                <p>As your product partners, we can help you map out a <Link class="" to={ROUTES.HOME}>development</Link> plan that turns your ideas into a designed and user-tested product.</p>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                            <div className="service-img ylow-bx" title="Pivot to meet customer demands">
                                <img src="/assets/img/design-img/Pivot.svg" alt="Pivot to meet customer demands"/>
                            </div>
                            <div className="service-head">
                                <h3>Pivot to meet customer demands</h3>
                            </div>
                            <div className="main-para-div">
                                <p>Need a redesign? Our usability analysts can help identify opportunities to better meet the needs of users in today’s ever-evolving market.</p>
                            </div>
                        </div>
                        <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                            <div className="service-img ylow-bx" title="Design your MVP">
                                <img src="/assets/img/design-img/mvp.svg" alt="Design your MVP"/>
                            </div>
                            <div className="service-head">
                                <h3>Design your MVP</h3>
                            </div>
                            <div className="main-para-div">
                                <p>Save time and resources by testing your ideas early. Our team can help you strip it down to the minimum features needed to validate your assumptions before you begin development - saving you time and resources.</p>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default WorkWithUs
