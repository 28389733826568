import React, {} from "react";
import {Button, Collapse, Modal, Form} from 'react-bootstrap';
import {withRouter} from "react-router-dom";
import ReactPlayer from 'react-player'
import PropTypes from 'prop-types';

class WatchVideo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {show: this.props.show, playing: false}
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.show != this.props.show) {
            this.setState({show: this.props.show})
        }
    }

    ref = player => {
        this.player = player
    }

    render() {
        return (
            <Modal className="webinar-modal" size={'lg'} centered show={this.props.show} onHide={this.props.handleClose}>
                <Modal.Header className="webinar-modal-header" closeButton>
                    <Modal.Title>{this.props.title}</Modal.Title>
                    <span className="modal-side-close">
                    <svg width="45" height="34" viewBox="0 0 45 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect x="5.5293" y="31.8203" width="45" height="3" transform="rotate(-45 5.5293 31.8203)"
                          fill="#444544"/>
                    <rect x="7.65039" width="45" height="3" transform="rotate(45 7.65039 0)" fill="#444544"/>
                    </svg>
                    </span>
                </Modal.Header>
                <Modal.Body className="webinar-modal-body">
                    <Form>
                        <ReactPlayer
                            ref={this.ref}
                            className='react-player'
                            width='100%'
                            height='100%'
                            url={this.props.videoUrl ? this.props.videoUrl : '/assets/img/webinar-videos/blockchain-app-in-online-market-place(1).mp4'}
                            // url={'https://test-videos.co.uk/vids/bigbuckbunny/mp4/h264/360/Big_Buck_Bunny_360_10s_1MB.mp4'}
                            // 1st vid. src:assets/img/webinar-videos/blockchain-app-in-online-market-place(1).mp4
                            // 2nd vid. src:assets/img/webinar-videos/explore-the-possibilities-in-healthcare(2).mp4
                            // 3rd vid. src:assets/img/webinar-videos/how-can-blockchain-be-leveraged-for-fintech-data-aggregation-webinar-recording-Jan-30-31(3).mp4
                            // 4th vid. src:assets/img/webinar-videos/how-can-blockchain-be-leveraged-for-fintech-data-aggregation-webinar-recording-Jan-30-31(3).mp4
                            // 5th vid. src:assets/img/webinar-videos/stable-coins-webinar(5).mp4
                            // 7th vid. src:assets/img/webinar-videos/Webinar_Blockchain and the Future of Data Management_Sep 13 2019 (online-video-cutter.com)(7).mp4
                            // 10th vid. src:assets/img/webinar-videos/Webinar_Blockchain_JPM Coin_28 Feb 2019.mp4
                            onReady={() => console.log('onReady')}
                            onStart={() => console.log('onStart')}
                            onBuffer={() => console.log('onBuffer')}
                            onSeek={e => console.log('onSeek', e)}
                            onEnded={() => {
                            }}
                            controls={true}
                            onError={e => console.log('onError', e)}
                            onProgress={() => {
                            }}
                            onDuration={() => {
                            }}
                        />
                    </Form>
                </Modal.Body>
            </Modal>

        );
    }


}

WatchVideo.defaultProps = {
    title: '',
    videoUrl: '',
};

WatchVideo.propTypes = {
    title: PropTypes.string.isRequired,
    videoUrl: PropTypes.string.isRequired,
}


export default WatchVideo;

