import React from 'react';
import "../../resources.css";
import {ResourceRoute} from '../../resource-routing';
import {Link} from "react-router-dom";
import NewsHeadings from "./common-action/news-heading";
import NewsSubscription from "./common-action/subscripbe-section";

class NewsIssue02 extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            pageEnabled: false,
        }

    }

    render() {
        return (
          <div className="newdesigned_issue_details">
            <NewsHeadings
              buttondDetail="Web3 & Blockchain"
              categoryType="web3"
              description="Crypto Tidbits: RBC May Embrace Bitcoin, China Bashes Cryptocurrency, Ethereum DeFi Booming | News BTC"
            />

            <div className="newsissuedetailed">
              <div className="detailed-common">
                {/* <div className="back-to-common">
                    <span className="back-to-icon-hold">
                        <img src="/assets/img/resource/back-to-new.svg"  alt="bact to new"></img>
                    </span>
                    <Link  to={ResourceRoute.NEWSLETTER}><span className="back-to-link">Newsletter</span></Link>
                </div> */}

                <div className="news-letter whats-on-hold">
                  <div className="issue-main-head">
                    <h4>What’s on?</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        href="https://www.cnbc.com/2019/11/15/china-technology-trends-from-blockchain-to-ai-and-fintech.html"
                        target={"_blank"}
                      >
                        <h4>
                          China throws its weight behind A.I. and blockchain as
                          it aims to be the world’s tech leader | CNBC{" "}
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        China, once seen as an imitator when it came to
                        technology, is now looking to take the lead in areas
                        from blockchain to artificial intelligence (AI),
                        much-hyped technologies that are seen as critical to the
                        future. Despite the U.S.-China trade war, experts say
                        the world’s second-largest economy will continue pushing
                        its domestic technology sector.{" "}
                      </p>
                    </div>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        href="https://decrypt.co/11562/bric-nations-propose-cryptocurrency-for-payment-settlement-system"
                        target={"_blank"}
                      >
                        <h4>
                          BRIC nations propose cryptocurrency for payment
                          settlement system | Decrypt{" "}
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        BRIC nations this week proposed a cryptocurrency for
                        payment settlements between its member countries—Brazil,
                        China, India, Russia, and South Africa—at an annual
                        summit held in Brazil, BRIC member told reporters
                        Thursday. According to Russian media outlet
                        RosBiznesConsulting (RBC), the director of the Russian
                        Direct Investment Fund, General Kirill Dmitriev, said
                        that payment transactions would go through the system
                        and its associated cryptocurrency.{" "}
                      </p>
                    </div>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        href="https://www.businessinsider.com/amazon-may-use-go-technology-in-larger-format-in-2020-2019-11?IR=T"
                        target={"_blank"}
                      >
                        <h4>
                          Amazon plans to open a supermarket format that uses
                          its Go technology as soon as 2020 | Business Insider{" "}
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Google is experimenting with a badge of shame for
                        websites that load slowly in Chrome. “In the future,
                        Chrome may identify sites that typically load fast or
                        slow for users with clear badging,” explains a blog post
                        from the Chrome team. “This may take a number of forms
                        and we plan to experiment with different options, to
                        determine which provides the most value to our users.”
                      </p>
                    </div>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        href="https://www.andnowuknow.com/headlines/walmart-canada-introduces-blockchain-based-supply-chain-DLT-Labs-John-Bayliss-Loudon-Owen/chandler-james/65854"
                        target={"_blank"}
                      >
                        <h4>
                          Walmart launches ‘world’s largest’ blockchain-based
                          freight-and-payment network | Computer World{" "}
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        The Canadian division of Walmart has launched a
                        blockchain-based supply chain that includes freight
                        tracking and payment processing for 70 trucking
                        companies whose goods are transported to more than 400
                        retail stores. The system is now live and all of Walmart
                        Canada's third-party freight carriers are scheduled to
                        be on the network by Feb. 1, 2020, the company said in a
                        statement. Walmart claims the blockchain network is the
                        largest of its kind in the world, a claim not disputed
                        by industry analysts.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="news-letter top-picks-hold">
                  <div className="issue-main-head">
                    <h4>Top Picks</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        href="https://www.newsbtc.com/2019/11/16/crypto-tidbits-rbc-may-embrace-bitcoin-china-bashes-cryptocurrency-ethereum-defi-booming/"
                        target={"_blank"}
                      >
                        <h4>
                          Crypto Tidbits: RBC May Embrace Bitcoin, China Bashes
                          Cryptocurrency, Ethereum DeFi Booming | News BTC{" "}
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Another week, another round of Crypto Tidbits. The past
                        seven days were rather painful for Bitcoin (BTC), with
                        the leading cryptocurrency tumbling by some 4% according
                        to Coin360. Altcoins have posted similar losses. As of
                        the time of writing this, BTC is trading for $8,500,
                        seemingly poised to break down further as bulls fail to
                        step in.
                      </p>
                    </div>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        href="https://www.finsmes.com/2019/11/why-millennials-trust-cryptocurrency-more-than-the-stock-market.html"
                        target={"_blank"}
                      >
                        <h4>
                          Why Millennials Trust Cryptocurrency More Than The
                          Stock Market| Finsmes.com{" "}
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Millennials are the best hunters the world has ever
                        seen. Do you realize how many entire industries they’ve
                        been killing? Business Insider even wrote a piece where
                        the headline said Millennials are killing “countless”
                        industries — and then went on to list 19 of them. A few
                        of the biggest are Real Estate, department stores, and
                        the billion-dollar cereal industry. Well, it may be time
                        to add another industry to the list: the stock market.
                        Yes, millennials, may be gearing up to kill Wall Street.
                        But why? And how? The article goes through a list of 5
                        reasons Wall Street may soon become Broke Street — and
                        how cryptocurrencies may be the reason
                      </p>
                    </div>
                  </div>
                </div>
                <div className="news-letter other-reads-hold">
                  <div className="issue-main-head">
                    <h4>Other Reads</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        href="https://www.wired.com/story/tech-companies-banks/"
                        target={"_blank"}
                      >
                        <h4>
                          Every Tech Company Wants to Be a Bank—Someday, At
                          Least | WIRED
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Most tech companies seem to be treading more carefully
                        than Facebook. That’s why you’re seeing cooperation, not
                        a competition, with banks—things like cobranded credit
                        cards and checking accounts. The big tech firms get the
                        consumer lock-in and business benefits they want,
                        without the regulatory headaches. Caesar Sengupta, a
                        Google payments executive, told The Wall Street Journal
                        that the search giant plans to partner with banks to get
                        its products off the ground—a somewhat pointed
                        statement, one month after Zuckerberg was hauled into
                        Congress after failing to do just that.
                      </p>
                    </div>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        href="https://www.newyorker.com/news/our-columnists/the-extraordinary-impeachment-testimony-of-fiona-hill"
                        target={"_blank"}
                      >
                        <h4>
                          The Extraordinary Impeachment Testimony of Fiona Hill
                          | The New Yorker
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Fiona Hill, the former National Security Council
                        official who testified at the impeachment hearings on
                        Thursday, was born in Bishop Auckland, a hardscrabble
                        former coal town in County Durham, in the northeast of
                        England. Her father was a miner; her mother was a nurse.
                        As she noted in her testimony before the House
                        Intelligence Committee, her modest roots and
                        working-class accent would have been a career handicap
                        in the Britain she grew up in, but in the late
                        nineteen-eighties she escaped. After attending the
                        University of St. Andrews, in Scotland, she applied for
                        a graduate scholarship to Harvard and was called for an
                        interview. “I was so nervous, I walked into a broom
                        closet by accident,” she later recalled.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <NewsSubscription categoryType="Web3" />
          </div>
        );
    }

}

export default NewsIssue02;
