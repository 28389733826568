import React from "react";
import "./career.css";
import ContactUS from "../../components/contact-us";
// import { ToastComponent } from '../../components/common';
import * as contactAction from "../../action/contactAction";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ReactSEOMetaTags from "react-seo-meta-tags";
import Helmet from "react-helmet";
import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import LocationOnIcon from "@material-ui/icons/LocationOn";

class Careers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      headerClassAdd: "",
      showContactUS: false,
      showToast: false,
      expanded: false,
    };
    this.onScrollHeader = this.onScrollHeader.bind(this);
  }

  //   const handleChange = (panel) => (event, isExpanded) => {
  //     setExpanded(isExpanded ? panel : false);
  //   };

  handleChange(panel) {
    return (event, isExpanded) => {
      this.setState({
        expanded: isExpanded ? panel : false,
      });
      // setExpanded(isExpanded ? panel : false);
    };
  }
  componentDidMount() {
    window.addEventListener("scroll", this.onScrollHeader);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.onScrollHeader);
  }
  onScrollHeader() {
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;

    const height =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;

    const scrolled = winScroll / height;

    if (scrolled === 0) {
      this.setState({
        headerClassAdd: "",
      });
    } else {
      this.setState({
        headerClassAdd: "scrollHeader",
      });
    }
  }
  sendToast() {
    this.props.toastShow(false, "");
    this.setState({ showContactUS: true });
  }

  render() {
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <title> Careers | Ionixx Technologies </title>
          <meta
            name="description"
            content="We drive innovation through human-centered technology! Come join us in building efficient, elegant, and effective technology and design solutions."
          />
          <link rel="canonical" href="https://www.ionixxtech.com/company" />
        </Helmet>
        <ReactSEOMetaTags
          render={(el) => <Helmet>{el}</Helmet>}
          website={{
            name: "Ionixx Technologies",
            title: "Ionixx Technologies Company Overview",
            description:
              "Thank you for choosing Ionixx! Explore our custom software solutions and  tech expertise. Stay tuned for the latest insights and updates.",
            type: "website",
            image: "/assets/img/ionixx-logo.png",
            url: "https://www.ionixxtech.com/company",
          }}
          twitter={{
            twitter: "https://www.ionixxtech.com/company",
          }}
        />
        <div className="career">
          {/* <ToastComponent showToast={this.state.showToast}/> */}
          <div className="section-1 blue-bg">
            <div className="custom-container">
              <div className="main-head-div mb-0">
                <h4 className="main-head-1">
                  <span className="secondary-span">Open Positions</span>
                  <span className="primary-span">
                    Come join the Ionixx family!
                  </span>
                  <b>Contact: <a href="mailto:hr@ionixxtech.com">hr@ionixxtech.com </a></b>
                </h4>
              </div>
            </div>
          </div>
          <div className="section-2 beige-bg">
            <div className="custom-container">
              <div className="career-inner">
                {/* <Accordion
                  expanded={this.state.expanded === "panel1"}
                  onChange={this.handleChange("panel1")}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                  >
                    <Typography className="heading">
                      Senior UX Designer
                    </Typography>
                    <Typography className="secondaryHeading">
                      <LocationOnIcon />
                      Toronto, Canada
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Typography>
                      We are seeking a Senior User Experience Designer to join
                      our team in Toronto. We're looking for a senior designer
                      with experience in designing enterprise products, who can
                      help craft the big picture while never losing sight of
                      important details. You will conceptualize, plan, and
                      deliver end-to-end user experiences and next-generation
                      workflows that will help not only solve but also define
                      our client's design strategy. The ideal candidate would
                      possess an excellent sense of creative strategy that
                      enables them to communicate, negotiate, and drive design
                      solutions for our products. You will need to travel to our
                      customer's office whenever required.
                    </Typography>
                    <strong>Role Description</strong>
                    <ul>
                      <li>
                        Translate complex design problems, user pain points, and
                        research findings into practical and impactful design
                        solutions.
                      </li>
                      <li>
                        Proactively engage with multiple project stakeholders,
                        including product managers, engineers, and other
                        designers, in drafting and brainstorming initial
                        concepts.
                      </li>
                      <li>
                        Rapidly visualize and prototype ideas to communicate
                        concepts, conveying design ideas via sketches,
                        wireframes, hi-fidelity mockups, prototypes, and/or
                        animations.
                      </li>
                      <li>
                        Conduct primary and/or secondary research, as well as
                        quick rounds of usability tests.
                      </li>
                      <li>
                        Create iterative designs that meet product objectives,
                        fit within our clients' design guidelines, and respond
                        to feedback and usability testing.
                      </li>
                      <li>Improve the design guidelines.</li>
                      <li>
                        Strive to elevate the product by crafting elegant visual
                        design systems from modern UI patterns and trends, from
                        concept to execution.
                      </li>
                      <li>
                        Support and collaborate with the remote design team
                        located in India and development teams throughout the
                        implementation cycle.
                      </li>
                      <li>
                        Present design decisions and participate in regular peer
                        reviews.
                      </li>
                    </ul>
                    <strong>Requisite Skills</strong>
                    <ul>
                      <li>
                        4 to 6 years of experience in UX design including 3 or
                        more years with enterprise products in financial
                        services.
                      </li>
                      <li>
                        Ability to clearly articulate a problem space and design
                        solutions that meet users needs and business goals
                      </li>
                      <li>
                        Ability to visualize data and develop conceptual
                        infographics and dashboards.
                      </li>
                      <li>
                        Ability to think holistically about how the UI will
                        scale in the future and integrate with other systems
                      </li>
                      <li>
                        Experience in design thinking and conducting workshops
                        is a plus
                      </li>
                      <li>Experience conducting and analyzing user research</li>
                      <li>
                        Strong interpersonal, written, and oral communication
                        skills
                      </li>
                      <li>
                        Ability to accurately set, manage, and meet deadlines
                      </li>
                      <li>
                        Comfortable working in a loosely defined agile and lean
                        design environment
                      </li>
                      <li>
                        Skilled in design software such as Figma, Adobe XD, or
                        similar tools.
                      </li>
                    </ul>
                    <Typography>
                      If this role excites you, write to us at{" "}
                      <a href="mailto:hr@ionixxtech.com">hr@ionixxtech.com</a>{" "}
                      or{" "}
                      <a href="mailto:chippydiac@ionixxtech.com">
                        chippydiac@ionixxtech.com
                      </a>{" "}
                      with your resume and portfolio with the subject line ‘Job:
                      Senior UX Designer'.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  expanded={this.state.expanded === "panel2"}
                  onChange={this.handleChange("panel2")}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2bh-content"
                    id="panel2bh-header"
                  >
                    <Typography className="heading">
                      Information Security Manager (ISM)
                    </Typography>
                    <Typography className="secondaryHeading">
                      <LocationOnIcon />
                      Chennai, India
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="job-details">
                      <span className="job-info">
                        <b>Location:</b> <i>Chennai</i>
                      </span>
                      <span className="job-info">
                        <b>Mode:</b> <i>Full Time - Permanent</i>
                      </span>
                      <span className="job-info">
                        <b>Qualification:</b> <i>Engineering degree or MCA</i>
                      </span>
                      <span className="job-info">
                        <b>Experience:</b> <i>8 + Years</i>
                      </span>
                      <span className="job-info">
                        <b>Salary:</b> <i>As per industry standards</i>
                      </span>
                    </div>
                    <strong>Job Responsibilities</strong>
                    <ul>
                      <li>
                        Provide information security awareness training to
                        organization personnel.
                      </li>
                      <li>
                        Oversee information security audits, whether performed
                        by an organization or third-party personnel, or client.
                      </li>
                      <li>
                        Conduct thorough risk assessment across all
                        functions/domains and identify gaps/areas for
                        improvement.
                      </li>
                      <li>
                        Create and maintain policy/procedure documents that
                        support ISMS.
                      </li>
                      <li>
                        Manage security team members and all other information
                        security personnel.
                      </li>
                      <li>
                        Provide training to information security personnel
                        during onboarding.
                      </li>
                      <li>
                        Evaluate department budget and costs associated with the
                        maintenance of ISMS.
                      </li>
                      <li>
                        Manage the changes required in the organization that
                        store or process the data and security impacts are
                        assessed on those changes before implementation.
                      </li>
                      <li>
                        Serve as a SPOC for the information security team and
                        the customer or organization.
                      </li>
                      <li>
                        Coordinate with managers of divergent functions and
                        domains in the organization for effective implementation
                        and maintenance of ISMS.
                      </li>
                      <li>
                        Design and implement internal security controls that are
                        required and ensure 100% compliance with company
                        security policies.
                      </li>
                      <li>
                        Strategize with leadership on the direction of the
                        security operations program.
                      </li>
                      <li>
                        Holistically deploy, maintain, and tune new security
                        controls and alerts critical to the security mission of
                        the organization. Work with other teams to identify,
                        resolve, and mitigate vulnerabilities and risks.
                      </li>
                    </ul>
                    <strong>Desired Candidate Profile</strong>
                    <ul>
                      <li>
                        Bachelors or master's degree in engineering or MCA with
                        overall 8+ years of experience and at least 5 years in
                        Infosec implementation and auditing
                      </li>
                      <li>
                        Excellent communication, documentation, and stakeholder
                        management skills
                      </li>
                      <li>
                        Strategic thinker and problem solver with excellent
                        analytical and reporting skills
                      </li>
                      <li>
                        Must have exposure in Information Security Management
                        (ISM) & consultation with a certification body
                      </li>
                      <li>
                        Must assist CISO in all his responsibilities in managing
                        the ISMS framework, in accordance with ISO 27001:2013
                      </li>
                      <li>
                        Must have exposure in reporting (MR) to CISO and
                        Steering committee on risks and threats posed to the
                        company, including Information Security-related issues,
                        Infosec metrics, and incidents.
                      </li>
                      <li>
                        Certified Lead Implementer &amp; Internal Auditor in ISO
                        27001:2013 or Certified Lead Auditor in ISO27001:2013 is
                        a plus; Certified (CISSP, CISM, CEH, CISA, CRISC, etc.)
                        professional
                      </li>
                    </ul>
                    <Typography>
                      If this interests you, please mail your CV to{" "}
                      <a href="mailto:hr@ionixxtech.com">hr@ionixxtech.com</a>{" "}
                      with the subject line ‘Job: Information Security Manager'.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  expanded={this.state.expanded === "panel3"}
                  onChange={this.handleChange("panel3")}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel3bh-content"
                    id="panel3bh-header"
                  >
                    <Typography className="heading">
                      Senior Automation Tester
                    </Typography>
                    <Typography className="secondaryHeading">
                      <LocationOnIcon />
                      Chennai, India
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="job-details">
                      <span className="job-info">
                        <b>Mode:</b> <i>Full Time - Permanent</i>
                      </span>
                      <span className="job-info">
                        <b>Qualification:</b> <i>Any Degree</i>
                      </span>
                      <span className="job-info">
                        <b>Experience:</b> <i>5 + Years</i>
                      </span>
                      <span className="job-info">
                        <b>Salary:</b> <i>As per industry standards</i>
                      </span>
                    </div>
                    <strong>Requisite Skills</strong>
                    <ul>
                      <li>
                        5 - 7 years of experience in Manual and Automation
                        Testing
                      </li>
                      <li>
                        At least 3 - 4 years of Selenium Automation experience
                        with Core java programming
                      </li>
                      <li>
                        Understanding Business requirement and documenting it
                      </li>
                      <li>Strong understanding of Java and OOPs principles</li>
                      <li>
                        Hands-on experience writing OOP code in Java and
                        JavaScript
                      </li>
                      <li>
                        Possess a good understanding of QA methodologies and
                        processes
                      </li>
                      <li>Building automation test frameworks</li>
                      <li>
                        Experience in open-source tools such as Selenium Web
                        Driver, Postman, Rest Assured, API, TestNG, BDD,
                        JUnit/Mockito, Maven, Jenkins, and Sonar for continuous
                        integration/deployment and test automation
                      </li>
                      <li>
                        Building, customizing, deploying, and managing the
                        environment/ test automation frameworks
                      </li>
                      <li>Strong communication skills</li>
                      <li>
                        Should also be ready to work on Manual Testing on
                        project need basis
                      </li>
                      <li>Agile Scrum experience</li>
                      <li>
                        Good Knowledge and Understanding of Trade Exchange with
                        order execution
                      </li>
                      <li>Knowledge of Post Trade Systems</li>
                    </ul>
                    <Typography>
                      If this role excites you, mail your CV to{" "}
                      <a href="mailto:hr@ionixxtech.com">hr@ionixxtech.com</a>{" "}
                      with the subject line ‘Job: Senior Automation Tester'.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                <Accordion
                  expanded={this.state.expanded === "panel4"}
                  onChange={this.handleChange("panel4")}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel4bh-content"
                    id="panel4bh-header"
                  >
                    <Typography className="heading">
                      Business Analyst - Corporate Development
                    </Typography>
                    <Typography className="secondaryHeading">
                      <LocationOnIcon />
                      Chennai, India
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="job-details">
                      <span className="job-info">
                        <b>Mode:</b> <i>Full Time - Permanent</i>
                      </span>
                      <span className="job-info">
                        <b>Qualification:</b>{" "}
                        <i>MBA (Business Administration, Finance)</i>
                      </span>
                      <span className="job-info">
                        <b>Experience:</b> <i>3 - 5 years</i>
                      </span>
                      <span className="job-info">
                        <b>Salary:</b> <i>As per industry standards</i>
                      </span>
                    </div>
                    <strong>Job Description</strong>
                    <ul>
                      <li>
                        Support activities for the development of strategic
                        market and business cases to support performance and
                        growth in target market segments.
                      </li>
                      <li>
                        Provide and deliver strategic data analysis, insights
                        and presentations to senior leadership and functional
                        teams.
                      </li>
                      <li>
                        Identify, cultivate, and analyze qualitative and
                        quantitative data in the external competitive market as
                        well as from within the organization (e.g. financials)
                        to support business needs.
                      </li>
                      <li>
                        Contribute to improving the department's internal
                        systems, processes and tools particularly as they
                        enhance the vision of strategy development, analytics,
                        and/or innovation
                      </li>
                      <li>
                        Update data from multiple sources on a regular basis to
                        provide information to management to make strategic
                        business decisions.
                      </li>
                      <li>
                        Assist with project management and
                        coordination/development of work plans, deliverables,
                        and activities.
                      </li>
                      <li>
                        Maintain report forms, formats, information dashboards,
                        data generators, reports and other resources.
                      </li>
                      <li>
                        Support activities for the development of strategic
                        market and product line business cases to support
                        performance and growth in target market segments
                      </li>
                      <li>
                        Analyze market and internal data to identify
                        trends/opportunities, develop strategic options based on
                        the insights synthesized from the data analyzed, and
                        create compelling presentations identifying the key
                        business implications and recommendations.
                      </li>
                    </ul>
                    <strong>Requisite Skills and Qualifications</strong>
                    <ul>
                      <li>
                        MBA in Business Administration, Finance, or related
                        field
                      </li>
                      <li>
                        Typically requires 3-5 years of experience with market
                        research, business analysis, and project management
                      </li>
                      <li>Proficient in G Suite and MS office applications</li>
                      <li>
                        Demonstrated experience in quantitative and qualitative
                        data research and analysis
                      </li>
                      <li>Excellent communication and writing skills</li>
                    </ul>
                    <Typography>
                      If this role excites you, mail your CV to{" "}
                      <a href="mailto:hr@ionixxtech.com">hr@ionixxtech.com</a>{" "}
                      with the subject line ‘Job: Business Analyst - Corporate
                      Development'.
                    </Typography>
                  </AccordionDetails>
                </Accordion> */}
                <Accordion
                  expanded={this.state.expanded === "panel5"}
                  onChange={this.handleChange("panel5")}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel5bh-content"
                    id="panel5bh-header"
                  >
                    <Typography className="heading">
                      Lead Visual Designer
                    </Typography>
                    <Typography className="secondaryHeading">
                      <LocationOnIcon />
                      Chennai, India
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="job-details">
                      <span className="job-info">
                        <b>Mode:</b> <i>Full Time - Permanent</i>
                      </span>
                      <span className="job-info">
                        <b>Qualification:</b> <i>Any Degree</i>
                      </span>
                      <span className="job-info">
                        <b>Experience:</b> <i>7 + Years</i>
                      </span>
                      <span className="job-info">
                        <b>Salary:</b> <i>As per industry standards</i>
                      </span>
                    </div>
                    <strong>Requisite Skills</strong>
                    <ul>
                      <li>7 to 10 years of experience in visual design</li>
                      <li>
                        Must have a degree in visual communication or graphic
                        design
                      </li>
                      <li>
                        Portfolio is a must to apply, and must showcase a
                        diverse set of projects
                      </li>
                      <li>
                        A minimum of 4 years of experience in creating beautiful
                        and detailed interfaces
                      </li>
                      <li>A good understanding of visual branding is a plus</li>
                      <li>
                        Must be comfortable with both working remotely. Travel
                        might be required from time to time
                      </li>
                      <li>
                        Should be able to communicate their approach and visual
                        styles clearly to clients
                      </li>
                    </ul>
                    <Typography>
                      If this role excites you, mail your CV to{" "}
                      <a href="mailto:hr@ionixxtech.com">hr@ionixxtech.com</a>{" "}
                      with the subject line ‘Job: Lead Visual Designer'.
                    </Typography>
                  </AccordionDetails>
                </Accordion>
                {/* <Accordion
                  expanded={this.state.expanded === "panel6"}
                  onChange={this.handleChange("panel6")}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel6bh-content"
                    id="panel6bh-header"
                  >
                    <Typography className="heading">Head of Finance</Typography>
                    <Typography className="secondaryHeading">
                      <LocationOnIcon />
                      Chennai, India
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="job-details">
                      <span className="job-info">
                        <b>Mode:</b> <i>Full Time - Permanent</i>
                      </span>
                      <span className="job-info">
                        <b>Qualification:</b>{" "}
                        <i>Chartered Accountant (Qualified)</i>
                      </span>
                      <span className="job-info">
                        <b>Experience:</b> <i>13 + Years</i>
                      </span>
                      <span className="job-info">
                        <b>Salary:</b> <i>As per industry standards</i>
                      </span>
                    </div>
                    <strong>Roles &amp; Responsibilities</strong>
                    <b>Finance &amp; Accounting:</b>
                    <ul>
                      <li>
                        Responsible for all financial reports, budgets, and
                        forecasts including PNL, Invoicing calendar, aging
                        reports.
                      </li>
                      <li>Effective Cash Flow and fund flow management</li>
                      <li>
                        Building strong relationships with clients, guiding the
                        team for regular AR follow-ups, and setting company
                        objectives.
                      </li>
                      <li>Manage timely invoice submission.</li>
                      <li>
                        Liaise with Banks for financial requirements, overdraft
                        facilities, and term loans.
                      </li>
                      <li>Ensure control of debtors &amp; creditors.</li>
                      <li>Working capital management.</li>
                      <li>
                        Managing all inventories &amp; material indenting.
                      </li>
                      <li>Handling direct &amp; indirect tax matters.</li>
                      <li>Handling statutory &amp; internal auditors.</li>
                      <li>
                        Manage &amp; appraise the capital budgeting processes.
                      </li>
                      <li>
                        Provide Financial Information &amp; support to the CEO.
                      </li>
                      <li>
                        Report financial results to the board of directors.
                      </li>
                      <li>
                        Construct and monitor reliable internal financial
                        control systems.
                      </li>
                    </ul>
                    <b>Compliance:</b>
                    <ul>
                      <li>
                        File various Statutory Forms, Returns, etc with ROC,
                        MCA, etc. within the stipulated time period.
                      </li>
                      <li>
                        Provide legal and administrative support and guidance to
                        the board of directors.
                      </li>
                      <li>
                        Responsible for ensuring compliance with all applicable
                        laws to the Company.
                      </li>
                      <li>
                        Maintain the statutory registers of the company
                        (register of directors and secretary, register of
                        members, register of directors- and secretaries-
                        interests, and register of debenture holders) and make
                        them available for inspection to those entitled.
                      </li>
                      <li>
                        Overall management of direct and indirect taxes
                        including payment of taxes and filing returns.
                      </li>
                      <li>
                        Handle RBI and FEMA compliances for Foreign Direct
                        Investment and Overseas Direct Investment and filing
                        annual returns with RBI.
                      </li>
                      <li>
                        Liaise with consultants for matters for corporate tax
                        and transfer pricing, including tax audits, scrutiny
                        assessments, TDS assessments, inquiries, and other
                        proceedings.
                      </li>
                      <li>Handle representation before the tax authorities.</li>
                      <li>
                        Work in coordination with tax consultants in the United
                        States &amp; Canada for managing tax compliance for
                        holding companies based in North America to ensure
                        compliance with local laws.
                      </li>
                      <li>
                        HandleTransfer Pricing compliances which include
                        Preparation and review of Corporate and Transaction
                        Analysis, Function, Assets, and Risk (FAR) Analysis.
                      </li>
                      <li>
                        Prepare Transfer Pricing Documents, including data
                        collection and coordination with consultants to complete
                        the TP compliance
                      </li>
                      <li>Handle payroll and related compliances</li>
                    </ul>
                    <strong>Desired Candidate Profile</strong>
                    <ul>
                      <li>
                        Minimum{" "}
                        <em>13 years experience in Accounts &amp; Finance</em>
                      </li>
                      <li>
                        Should have been in a leadership or very senior role for
                        a minimum of 7 years
                      </li>
                      <li>
                        Preferably from <em>IT Sector</em>
                      </li>
                      <li>
                        Qualified in Company Secretary (ACS) is an advantage
                      </li>
                      <li>A good understanding of visual branding is a plus</li>
                      <li>
                        Must be comfortable with both working remotely. Travel
                        might be required from time to time
                      </li>
                      <li>
                        Should be able to communicate their approach and visual
                        styles clearly to clients
                      </li>
                    </ul>
                    <Typography>
                      If this role excites you, mail your CV to{" "}
                      <a href="mailto:hr@ionixxtech.com">hr@ionixxtech.com</a>{" "}
                      with the subject line ‘Job: Head of Finance'.
                    </Typography>
                  </AccordionDetails>
                </Accordion> */}
              </div>
            </div>
          </div>
          <ContactUS
            show={this.state.showContactUS}
            handleClose={() => this.setState({ showContactUS: false })}
            toShowToast={() => {
              this.props.toastShow(true, "");
            }}
          />
        </div>
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return state.commonReducer;
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return bindActionCreators(contactAction, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Careers);
