import React from 'react';
import "../../resources.css";
import { ResourceRoute } from '../../resource-routing';
import { Link } from "react-router-dom";
import NewsHeadings from "./common-action/news-heading";
import NewsSubscription from "./common-action/subscripbe-section";

class NewsIssue18 extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            pageEnabled: false,
        }

    }

    render() {
        return (
          <div className="newdesigned_issue_details">
            <NewsHeadings
              buttondDetail="HealthTech"
              categoryType="healthtech"
              description="Why computers won’t be reading your mind any time soon | Wired.com"
            />

            <div className="newsissuedetailed">
              <div className="detailed-common">
                {/* <div className="back-to-common">
                    <span className="back-to-icon-hold">
                    <img src="/assets/img/resource/back-to-new.svg"  alt="bact to new"></img>
                    </span>
                    <Link  to={ResourceRoute.NEWSLETTER}><span className="back-to-link">Newsletter</span></Link>
                </div> */}

                <div className="news-letter whats-on-hold">
                  <div className="issue-main-head">
                    <h4>What’s on?</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://cointelegraph.com/news/alibaba-files-for-a-blockchain-transaction-system-in-brazil"
                      >
                        <h4>
                          Alibaba Files for a Blockchain Transaction System in
                          Brazil | Coin Telegraph
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Chinese e-commerce giant Alibaba has registered a
                        blockchain-based system patent in Brazil under the
                        category of "insurance," "financial business" and
                        "monetary business,” according to the March 10th edition
                        of the Industrial Property Magazine (NPI). As reported
                        by the NPI, the name of the mechanism is “Ant Unicorn.”
                        Regarding the financial solution, the patent covers real
                        estate services, credit card payment systems, invoice
                        processing and the strengthening of security to process
                        transactions.
                      </p>
                    </div>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.vox.com/recode/2020/3/9/21171473/amazon-go-cashierless-just-walk-out-technology-retailers-no-checkout"
                      >
                        <h4>
                          Amazon is selling its cashierless technology to other
                          vendors, starting with airports | Vox Magazine
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Amazon and airport vendor OTG announced on Wednesday
                        that Amazon’s technology would be installed in several
                        CIBO Express stores at Newark Liberty and LaGuardia
                        airports.This news comes two days after Amazon announced
                        that it had begun selling its so-called “just walk out”
                        technology — which combines ceiling cameras, computer
                        vision, and weight sensors — to other retailers, in a
                        move that could someday impact the millions of Americans
                        who hold cashier jobs today.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="news-letter top-picks-hold">
                  <div className="issue-main-head">
                    <h4>Top Picks</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.wired.co.uk/article/brain-computer-interfaces"
                      >
                        <h4>
                          Why computers won’t be reading your mind any time soon
                          | Wired.com{" "}
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Headlines about mind-reading computers are overblown,
                        but by working out what we're trying to say they could
                        give a voice to people who can't speak
                      </p>
                    </div>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.zdnet.com/article/if-every-company-is-a-software-company-then-ux-design-is-everyones-job/"
                      >
                        <h4>
                          If every company is a software company, then UX design
                          is everyone's job | ZDNet.com{" "}
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        In an insightful analysis of 2,411 links with 358,917
                        designers all around the world, Fabricio Teixeira and
                        Caio Braga, both with @uxdesigncc, find there is a
                        growing movement toward design-driven thinking, but
                        there's still a lot of work to be done. It's time to
                        embrace the inner designer in all of us. Among the key
                        trends the co-authors tracked, the following have
                        enterprise-scale implications for the way applications
                        needs to be designed and presented to internal end-users
                        as well as customers
                      </p>
                    </div>
                  </div>
                </div>
                <div className="news-letter other-reads-hold">
                  <div className="issue-main-head">
                    <h4>Other Reads</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://fortune.com/2020/03/11/5-tech-tips-coronavirus/"
                      >
                        <h4>
                          5 tech tips to keep yourself safe from Coronavirus |
                          Fortune{" "}
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        With the coronavirus outbreak largely unchecked, now is
                        a time when many people are looking to get in touch with
                        their inner recluse. Helped by technology, however,
                        people may be able to reduce their exposure to the
                        virus. After all, it's difficult to catch a virus (the
                        non-computer kind) by staring at a screen all day while
                        avoiding human contact. Read to know few hints for using
                        tech to increase your chances of staying healthy.{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <NewsSubscription categoryType="HealthTech" />
          </div>
        );
    }

}

export default NewsIssue18;
