import React from 'react';
import "../../resources.css";
import { ResourceRoute } from '../../resource-routing';
import { Link } from "react-router-dom";
import NewsHeadings from "./common-action/news-heading";
import NewsSubscription from "./common-action/subscripbe-section";

class NewsIssue17 extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            pageEnabled: false,
        }

    }

    render() {
        return (
          <div className="newdesigned_issue_details">
            <NewsHeadings
              buttondDetail="Web3 & Blockchain"
              categoryType="web3"
              description="What Does A Foldable Web Actually Mean? | Smashing Magazine"
            />

            <div className="newsissuedetailed">
              <div className="detailed-common">
                {/* <div className="back-to-common">
                    <span className="back-to-icon-hold">
                    <img src="/assets/img/resource/back-to-new.svg"  alt="bact to new"></img>
                    </span>
                    <Link  to={ResourceRoute.NEWSLETTER}><span className="back-to-link">Newsletter</span></Link>
                </div> */}

                <div className="news-letter whats-on-hold">
                  <div className="issue-main-head">
                    <h4>What’s on?</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.coindesk.com/indias-supreme-court-ruling-is-a-win-for-the-whole-blockchain-industry"
                      >
                        <h4>
                          India’s Supreme Court Ruling Is a Win for the Whole
                          Blockchain Industry | Coin Desk
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        On Wednesday, the Supreme Court of India overturned the
                        Central Bank’s ban on trading in virtual currencies.
                        This order is clearly a major victory not only for the
                        digital asset industry but also for India’s fast-growing
                        fintech and technology industries. This progressive
                        decision paves the way for measured and progressive
                        regulation that will allow India, a leading software
                        exporter, and market for fintech, to benefit from rapid
                        innovation in blockchain technology and digital assets.
                      </p>
                    </div>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://techcrunch.com/2020/03/04/google-assistant-on-android-can-now-read-entire-web-pages-to-you/"
                      >
                        <h4>
                          Google Assistant on Android can now read entire web
                          pages to you | Techcrunch
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Just a few weeks back at CES, Google gave a sneak peek
                        of a feature that would let your Android devices read
                        entire web pages aloud to you — perfect for when you
                        don’t have a hand free to scroll but still need to catch
                        up on some text, or for when you just don’t feel like
                        looking at your screen anymore. You’d say, “Hey Google,
                        read this page,” and they’d spin up Google Assistant’s
                        neural networks to generate a pretty dang spot-on
                        reading of it.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="news-letter top-picks-hold">
                  <div className="issue-main-head">
                    <h4>Top Picks</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.smashingmagazine.com/2020/02/foldable-web-meaning/"
                      >
                        <h4>
                          What Does A Foldable Web Actually Mean? | Smashing
                          Magazine
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Foldable devices have brought with them talk of a
                        ‘foldable web,’ and the idea that long-standing web
                        conventions may be on the verge of a serious shakeup. Is
                        it all hype, or is it time to get flexible?
                      </p>
                    </div>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.wired.com/story/botnet-social-network-where-everyones-influencer/"
                      >
                        <h4>
                          Welcome to Botnet, Where Everyone’s an Influencer |
                          Wired Tech
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Ever wondered what it's like to be a celebrity on the
                        internet? You can find out for yourself with Botnet—a
                        social media platform filled with bots ready to shower
                        you in adoration. Simply post a selfie or an update on
                        your life, and watch the likes and comments roll in. The
                        idea is to simulate mega-fame on the internet, except
                        without the trolls—if you want to be abused, you'll have
                        to pay 99 cents
                      </p>
                    </div>
                  </div>
                </div>
                <div className="news-letter other-reads-hold">
                  <div className="issue-main-head">
                    <h4>Other Reads</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.theguardian.com/global-development/2020/mar/05/nine-out-of-10-people-found-to-be-biased-against-women"
                      >
                        <h4>
                          Nine out of 10 people found to be biased against women
                          | The Guardian
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        It’s nearly International Women’s Day! Here’s a new
                        survey by - The United Nations Development Programme
                        (UNDP) analyzed data from respondents from 75 countries.
                        More than half of them feel men are better political
                        leaders, some 40% believe men are better business
                        executives, and a third believe that it’s fine for a man
                        to beat his wife. Despite progress in closing the
                        equality gap, 91% of men and 86% of women hold at least
                        one bias against women in relation to politics,
                        economics, education, violence or reproductive rights.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <NewsSubscription categoryType="Web3" />
          </div>
        );
    }

}

export default NewsIssue17;
