// import React, { useState } from "react";
// import { Link } from "react-router-dom";
// import "./tab.css";

// export default function Tab() {
//   const [selectedTab, setSelectedTab] = useState(0);

//   const handleTab = (index) => {
//     setSelectedTab(index)
//   };

//   return (
//     <>
//       <div className="tag-group">
        
//         <div className="tab-header">
//           <ul>
//             <li className={selectedTab === 0 ? 'active': ''} onClick={() => handleTab(0)}><img src="/assets/img/portfolio-img/icons/drivewealth.svg"/></li>
//             <li className={selectedTab === 1 ? 'active': ''} onClick={() => handleTab(1)}><img src="/assets/img/portfolio-img/icons/p2e.svg"/></li>
//             <li className={selectedTab === 2 ? 'active': ''} onClick={() => handleTab(2)}><img src="/assets/img/portfolio-img/icons/gaadibazaar.svg"/></li>
//             <li className={selectedTab === 3 ? 'active': ''} onClick={() => handleTab(3)}><img src="/assets/img/portfolio-img/icons/tenshey.svg"/></li>
//             <li className={selectedTab === 4 ? 'active': ''} onClick={() => handleTab(4)}><img src="/assets/img/portfolio-img/icons/dubuqu.svg"/></li>
//             <li className={selectedTab === 5 ? 'active': ''} onClick={() => handleTab(5)}><img src="/assets/img/portfolio-img/icons/cryptospace.svg"/></li>
//           </ul>
//         </div>
//         <div className="tab-content">
//           <div className={selectedTab === 0 ? 'tab-active': 'tab-inactive'}>
//             <div className="inner-content">
//               <img src="/assets/img/portfolio-img/dw-bg.png"/>
//               <div className="inner-detail">
//                 <b>Ionixx partnered with Drivewealth to lead the digital transformation of their back-office trading operations.</b>
//                 <Link>Here is the success story</Link>
//               </div>
//             </div>
//           </div>
//           <div className={selectedTab === 1 ? 'tab-active': 'tab-inactive'}>
//             <div className="inner-content">
//               <img src="/assets/img/portfolio-img/p2e-bg.png"/>
//               <div className="inner-detail">
//                 <b>Ionixx partnered with Drivewealth to lead the digital transformation of their back-office trading operations.</b>
//                 <Link>Here is the success story</Link>
//               </div>
//             </div>
//           </div>
//           <div className={selectedTab === 2 ? 'tab-active': 'tab-inactive'}>
//           <div className="inner-content">
//               <img src="/assets/img/portfolio-img/gb-bg.png"/>
//               <div className="inner-detail">
//                 <b>Ionixx partnered with Drivewealth to lead the digital transformation of their back-office trading operations.</b>
//                 <Link>Here is the success story</Link>
//               </div>
//           </div>
//           </div>
//           <div className={selectedTab === 3 ? 'tab-active': 'tab-inactive'}>
//             <div className="inner-content">
//               <img src="/assets/img/portfolio-img/tenshey-bg.png"/>
//               <div className="inner-detail">
//                 <b>Ionixx partnered with Drivewealth to lead the digital transformation of their back-office trading operations.</b>
//                 <Link>Here is the success story</Link>
//               </div>
//             </div>
//           </div>
//           <div className={selectedTab === 4 ? 'tab-active': 'tab-inactive'}>
//           <div className="inner-content">
//               <img src="/assets/img/portfolio-img/dubuqu-bg.png"/>
//               <div className="inner-detail">
//                 <b>Ionixx partnered with Drivewealth to lead the digital transformation of their back-office trading operations.</b>
//                 <Link>Here is the success story</Link>
//               </div>
              
//             </div>
//           </div>
//           <div className={selectedTab === 5 ? 'tab-active': 'tab-inactive'}>
//           <div className="inner-content">
//               <img src="/assets/img/portfolio-img/cs-bg.png"/>
//               <div className="inner-detail">
//                 <b>Ionixx partnered with Drivewealth to lead the digital transformation of their back-office trading operations.</b>
//                 <Link>Here is the success story</Link>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </>
//   )
// }

import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import { Link } from "react-router-dom";
import { ROUTES } from "../../../routes";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
}));

export default function ScrollableTabsButtonAuto() {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const dwImage = <img src="/assets/img/portfolio-img/icons/postTrade.svg"/>;
  const p2eImage = <img src="/assets/img/portfolio-img/icons/p2e.svg"/>;
  const gbImage = <img src="/assets/img/portfolio-img/icons/gaadibazaar.svg"/>;
  const tensheyImage = <img src="/assets/img/portfolio-img/icons/tenshey.svg"/>;
  const duImage = <img src="/assets/img/portfolio-img/icons/dubuqu.svg"/>;
  const csImage = <img src="/assets/img/portfolio-img/icons/cryptospace.svg"/>;

  return (
    <div className={classes.root}>
      <AppBar position="static" color="default">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="primary"
          textColor="primary"
          variant="scrollable"
          scrollButtons="auto"
          aria-label="scrollable auto tabs example"
        >
          <Tab icon={dwImage} {...a11yProps(0)} />
          <Tab icon={p2eImage} {...a11yProps(1)} />
          <Tab icon={gbImage} {...a11yProps(2)} />
          <Tab icon={tensheyImage} {...a11yProps(3)} />
          <Tab icon={duImage} {...a11yProps(4)} />
          <Tab icon={csImage} {...a11yProps(5)} />
          {/* <Tab label="Item Seven" {...a11yProps(6)} /> */}
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0}>
      <div className="inner-content" style={{background:'url(/assets/img/portfolio-img/post-trade-banner.png)',backgroundRepeat:'no-repeat',backgroundSize:'cover'}}>

       {/* <img src="/assets/img/portfolio-img/dw-bg.png"/>               */}
        <div className="inner-detail" >
           <b>Ionixx partnered with a Brokerage firm to lead the digital transformation of their back-office trading operations.</b>
           <Link to={ROUTES.CASESTUDYDRIVEWEALTH}>Here is the success story<i><img src='/assets/img/portfolio-img/arrow.svg'/></i></Link>
        </div>
          </div>
      </TabPanel>
      <TabPanel value={value} index={1}>
      <div className="inner-content" style={{background:'url(/assets/img/portfolio-img/p2e.png)',backgroundRepeat:'no-repeat',backgroundSize:'cover'}}>
               {/* <img src="/assets/img/portfolio-img/p2e-bg.png"/> */}
               <div className="inner-detail">
                 <b>Ionixx worked with P2E to build a cutting-edge regulatory compliance management solution to streamline compliance processes and stay on top of regulation.</b>
                 <Link to={ROUTES.CASESTUDYP2E}>Here is the success story<i><img src='/assets/img/portfolio-img/arrow.svg'/></i></Link>
               </div>
             </div>
      </TabPanel>
      <TabPanel value={value} index={2}>
      <div className="inner-content" style={{background:'url(/assets/img/portfolio-img/gb.png)',backgroundRepeat:'no-repeat',backgroundSize:'cover'}}>
               {/* <img src="/assets/img/portfolio-img/gb-bg.png"/> */}
               <div className="inner-detail">
                <b>We designed and developed a user-friendly online marketplace platform for hassle-free vehicle trading and enhancing the automotive trading experience.</b>
                 <Link to={ROUTES.CASESTUDYGADDIBAZZAR}>Here is the success story<i><img src='/assets/img/portfolio-img/arrow.svg'/></i></Link>
              </div>
           </div>
      </TabPanel>
      <TabPanel value={value} index={3}>
      <div className="inner-content" style={{background:'url(/assets/img/portfolio-img/tenshey.png)',backgroundRepeat:'no-repeat',backgroundSize:'cover'}}>
              {/* <img src="/assets/img/portfolio-img/tenshey-bg.png"/> */}
               <div className="inner-detail">
                 <b>Ionixx crafted a user-centric executive coaching software solution for Tenshey’s executive leaders.</b>
                <Link to={ROUTES.CASESTUDYTENSHEY}>Here is the success story<i><img src='/assets/img/portfolio-img/arrow.svg'/></i></Link>
               </div>
             </div>
      </TabPanel>
      <TabPanel value={value} index={4}>
      <div className="inner-content" style={{background:'url(/assets/img/portfolio-img/dubuqu.png)',backgroundRepeat:'no-repeat',backgroundSize:'cover'}}>
               {/* <img src="/assets/img/portfolio-img/dubuqu-bg.png"/> */}
               <div className="inner-detail">
                 <b>An innovative media-sharing app, scaling up to be the default offline gallery for smartphones.</b>
                <Link to={ROUTES.CASESTUDYDUBUKU}>Here is the success story<i><img src='/assets/img/portfolio-img/arrow.svg'/></i></Link>
              </div>
        </div>
      </TabPanel>
      <TabPanel value={value} index={5}>
      <div className="inner-content" style={{background:'url(/assets/img/portfolio-img/cs-bg.png)',backgroundRepeat:'no-repeat',backgroundSize:'cover'}}>
               {/* <img src="/assets/img/portfolio-img/cs-bg.png"/> */}
               <div className="inner-detail">
                 <b>Ionixx partnered with Cryptospace, a dynamic cryptocurrency startup, to develop a scalable crypto trading platform catering to first-time users.</b>
                 <Link to={ROUTES.CASESTUDYCRYPTOSPACE}>Here is the success story <i><img src='/assets/img/portfolio-img/arrow.svg'/></i></Link>
               </div>
             </div>
      </TabPanel>
      {/* <TabPanel value={value} index={6}>
        Item Seven
      </TabPanel> */}
    </div>
  );
}
