import React from "react";
import "./events-news.css";
import "../resources.css";
import WatchVideo from "../../../components/watch-video";
import { Link } from "react-router-dom";
import { SlideDown } from "react-slidedown";
import "react-slidedown/lib/slidedown.css";
import ReactSEOMetaTags from "react-seo-meta-tags";
import Helmet from "react-helmet";
import { VIDEO_LINK } from "../../../utilities/constants";
import ResourceTop from "../resource-top/resource-top";
import ScrollableTabsButtonAuto from "../../../components/common/dynamicTab/dynamicTab";
import DynamicTabs from "../../../components/common/dynamicTab/dynamicTab";
import { CATERGORY, EVENT_MOCKUP, NEWS_MOCKUP } from "./event-mockup";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { connect } from "react-redux";
import { withRouter} from "react-router-dom";
import { bindActionCreators } from "redux";
import * as contactAction from "../../../action/contactAction";
// import usePagination from "../../../hooks/pagination";

const ContentData = ({latestEvent, data = [], configureModal}) => {
  return (
    <>
      {(data && data.length > 0) ? <div className="events-inner-content events-main-wrapper">
        <div className="latest-events">
          <h3>Latest Events</h3>
          <div className="event-card-wrapper">
            <div className="event-img">
              <img src={latestEvent.img} />
            </div>
            <div className="event-content">
              <div className="content-top">
                <span>{latestEvent.title}</span>
                <strong>
                    {latestEvent.content}
                </strong>
                <span className="subtext-content">{latestEvent.Subtext}</span>

                <a className="event-link" href="https://www.bigmarker.com/ionixx-technologies1/Navigating-Value-Based-Care-with-Data-Analytics?utm_bmcr_source=Events" target={'_blank'}>
                  Register Now{" "}
                  <i>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="12"
                      height="10"
                      viewBox="0 0 12 10"
                      fill="none"
                    >
                      <path
                        d="M7.43364 8.76003C7.29528 8.59742 7.22551 8.38338 7.22551 8.17073C7.22551 7.95669 7.29528 7.74404 7.43364 7.58143C7.43364 7.58143 8.37496 6.4751 9.19802 5.50637L0.709596 5.50637C0.318168 5.50637 5.99032e-05 5.13388 5.99204e-05 4.67245C5.99375e-05 4.2124 0.318169 3.83853 0.709597 3.83853L9.19802 3.83853L7.43364 1.76486C7.15692 1.43963 7.15692 0.91148 7.43364 0.584866C7.71036 0.259634 8.15973 0.259634 8.43763 0.584866L11.413 4.08315C11.5466 4.2402 11.6211 4.45146 11.6211 4.67245C11.6211 5.01852 11.4804 5.18392 11.2852 5.41325L8.43645 8.76003C8.15973 9.08526 7.71036 9.08526 7.43364 8.76003Z"
                        fill="black"
                      />
                    </svg>
                  </i>
                </a>
              </div>
              <div className="content-bottom">
                <em>Healthtech | 30 November 2023 | 10 AM EST</em>
              </div>
            </div>
          </div>
        </div>
        <div className="past-events">
          <h3>Past Events</h3>
          {data?.map(item => (
            <Link onClick={() => configureModal(item.link1, item.link2)}>
              <div className="event-card-wrapper">
                <div className="event-img">
                  <img src={item.img} />
                </div>
                <div className="event-content">
                  <div className="content-top">
                    <span>{item.category}</span>
                    <strong>
                      {item.content}
                    </strong>
                   {item.subContent && <p>{item.subContent}</p>} 
                    <a href={item.link2} target="_blank">View Event</a>
                  </div>
                </div>
              </div>
            </Link>
          ))}
        </div>
      </div>: null}
    </>
  );
};

const ContentDataTwo = ({latestEvent, data = []}) => {
  var settings = {
    dots: false,
    arrows:true,
    infinite: true,
    autoplay: true,
    speed: 3000,
    speed:2000,
    autoplaySpeed: 5000,
    pauseOnHover:true,
    slidesToShow: 2,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
        {
          breakpoint: 1098,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            infinite: false,
            dots: true
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            initialSlide: 1,
            dots: false
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: false
          }
        }
      ]
  };
  return (
    <div className="news-wrapper">
       <div className="latest-news">
        <h3>Latest News</h3>
        <div className="latest-news-wrapper">
          <Slider {...settings}>
          {latestEvent?.map(item => (
            <div onClick={() => {
              window.open(item?.url, '_blank')
            }} className="latest-news-card">
              <div className="card-img">
                <img src={item?.imageUrl} />
              </div>
              <span>{item?.category}</span>
              <b>{item?.content_one}</b>
              <div className="news-location">
                {/* <em>Location</em> */}
                <em>{item?.content_two}</em>
              </div>
            </div>
          ))}
          {/* <div className="latest-news-card">
            <div className="card-img"></div>
            <span>Channel Partner</span>
            <b>News Title</b>
            <div className="news-location">
              <em>Location</em>
              <em>MM.DD.YYYY</em>
            </div>
          </div> */}
          {/* <div className="latest-news-card">
            <div className="card-img"></div>
            <span>Channel Partner</span>
            <b>News Title</b>
            <div className="news-location">
              <em>Location</em>
              <em>MM.DD.YYYY</em>
            </div>
          </div> */}
          </Slider>
        </div>
       </div>
       <div className="past-news">
        <h3>Past News</h3>
        <div className="news-card-wrapper">
          {data?.map(item => (
            <div onClick={() => {
              if(!item?.url) return;
              window.open(item?.url, '_blank');
            }} className="news-card">
                <div className="news-img">
                  <img src={item?.imageUrl} />
                </div>
                <div className="news-category">
                  <label>Channel Partner</label>
                </div>
                <div className="news-title">
                  <b>{item?.title}</b>
                </div>
                <div className="news-location">
                  {/* <span>Location</span> */}
                  <span>{item?.content_two}</span>
                </div>
              </div>
          ))}
          </div>
          {/* <div className="news-card">
            <div className="news-img"></div>
            <div className="news-category">
              <label>Channel partner</label>
            </div>
            <div className="news-title">
              <b>News Title</b>
            </div>
            <div className="news-location">
              <span>Location</span>
              <span>MM.DD.YYYY</span>
            </div>
          </div>
          <div className="news-card">
            <div className="news-img"></div>
            <div className="news-category">
              <label>Channel partner</label>
            </div>
            <div className="news-title">
              <b>News Title</b>
            </div>
            <div className="news-location">
              <span>Location</span>
              <span>MM.DD.YYYY</span>
            </div>
          </div>
          <div className="news-card">
            <div className="news-img"></div>
            <div className="news-category">
              <label>Channel partner</label>
            </div>
            <div className="news-title">
              <b>News Title</b>
            </div>
            <div className="news-location">
              <span>Location</span>
              <span>MM.DD.YYYY</span>
            </div>
          </div>
          <div className="news-card">
            <div className="news-img"></div>
            <div className="news-category">
              <label>Channel partner</label>
            </div>
            <div className="news-title">
              <b>News Title</b>
            </div>
            <div className="news-location">
              <span>Location</span>
              <span>MM.DD.YYYY</span>
            </div>
          </div>
          <div className="news-card">
            <div className="news-img"></div>
            <div className="news-category">
              <label>Channel partner</label>
            </div>
            <div className="news-title">
              <b>News Title</b>
            </div>
            <div className="news-location">
              <span>Location</span>
              <span>MM.DD.YYYY</span>
            </div>
          </div> */}
       </div>
    </div>
  );
};
class NewsEvents extends React.Component {News
  constructor(props) {
    super(props);
    this.state = {
      headerClassAdd: "",
      showVideo: false,
      videoModalUrl: "",
      videoModalTitle: "",
      pageEnabled: false,
      buttonShow: true,
      start: 0,
      end: 3,
      // event tab
      cardList: EVENT_MOCKUP,
      latestCard: {
        "content": "Navigating Value-Based Care with Data Analytics",
        "Subtext":"Unlocking the Potential of Value-Based Care Through Data Analytics: With Dr. Maanas Samant",
        "authorName": [
            "Dr. Mannas Samant,"
        ],
        "date": "09 November 2023",
        "link1": "Navigating Value-Based Care with Data Analytics ",
        "link2": "https://www.bigmarker.com/ionixx-technologies1/Navigating-Value-Based-Care-with-Data-Analytics?utm_bmcr_source=Events",
        "href": "",
        "img": "/assets/img/banner-img/Manas-Webinar_Website-Banner-mob.png",
        "link": true,
        "alt": "Navigating Value-Based Care with Data Analytics ",
        "title": "HealthTech",
        "category": "HealthTech"
      },
      filteredCardList: EVENT_MOCKUP,
      paginatedCardList: [],
      pageCount: EVENT_MOCKUP.length,
      // news tab
      // newsCardList: NEWS_MOCKUP,
      latestNewsCard: [
        {
        "url": "https://www.forbes.com/sites/forbesbusinesscouncil/2020/03/25/15-smart-ways-you-can-save-money-as-a-new-business/#5fdfd7aa40fc",
        "imageUrl": "/assets/img/press/manju-mohan-forbes-jpg",
        "content_one": "Manju Mohan, CEO & Co-Founder accepted into Forbes Business Council",
        "content_two": "20 Mar 2020",
        "category": "Web3"
        },
        {
          "url": "https://www.forbes.com/sites/forbesbusinesscouncil/2020/03/12/authenticating-food-through-blockchain",
          "imageUrl": "/assets/img/press/ionix-blockapps-750x375-jpg",
          "content_one": "BlockApps and Ionixx Technologies announce partnership to build successful blockchain applications for businesses through STRATO Network",
          "content_two": "12 Mar 2020",
          "category": "Web3"
        },
      ],
      // newFilteredList: NEWS_MOCKUP,
      // paginatedNewsList: [],
      // pageNewsCount: EVENT_MOCKUP.length,
      selectedTab: 0,
      PER_PAGE: 3
    };
  }
  viewMoreOnPress = () => {
    let arrLength = this.state.cardList.length;
    let remainder = this.state.cardList.slice(this.state.end, arrLength).length;
    this.setState(
      {
        buttonShow: !this.state.buttonShow,
      },
      () => {
        if (this.state.buttonShow) {
          this.setState({
            end: 3,
          });
          window.scrollTo(0, 850);
        } else {
          this.setState({
            end: this.state.end + remainder,
          });
        }
      }
    );
  };
  configureVideoModalState = (videoModalTitle, videoModalUrl) => {
    this.setState({ videoModalTitle, videoModalUrl, showVideo: true });
  };

debounce = (callback, delay) => {
    let timer;
    return (...args) => {
        clearInterval(timer);
        timer = setTimeout(() => callback(...args), delay);
    }
}

hanldeDebounce = (searchValue) => {
    if(searchValue) {
        const result = [...this.state.cardList].filter(item => {
            if(item?.title?.toLowerCase().includes(searchValue.toLowerCase())) {
                return item;
            }
        });
        console.log('result', result);
        this.setState({ filteredCardList: result, pageCount: result.length });
        this.handlePageChange(0, this.state.PER_PAGE, result);
    } else {
        this.setState({ filteredCardList: this.state.cardList, pageCount: this.state.cardList.length });
        this.handlePageChange(0, this.state.PER_PAGE, this.state.cardList);
    }
};

setDebounce = this.debounce(seachVal => this.hanldeDebounce(seachVal), 1000);

  handleSearch = (searchValue) => {
    this.setDebounce(searchValue);
  }

  handleSelect = (selectedValue) => {
    let result = [];
    if(selectedValue) {
        result = [...this.state.cardList].filter(item => {
            if(item.category.toLowerCase() === selectedValue.toLowerCase()) {
                return item;
            }
        });
        this.setState({ filteredCardList: result, pageCount: result.length });
        this.handlePageChange(0, this.state.PER_PAGE, result);
    } else {
        this.setState({ filteredCardList: this.state.cardList, pageCount: this.state.cardList.length });
        this.handlePageChange(0, this.state.PER_PAGE, this.state.cardList);
    }
  }

  handlePageChange = (begin, end, data = []) => {
    let result;
    if (data && data.length > 0) {
        result = [...data].slice(begin, end);
    } else {
        result = [...this.state.filteredCardList].slice(begin, end);
    }
    this.setState({ paginatedCardList: result });
    console.log('pageChange', begin, end, data, result);
  }

  handleTabChange = (value) => {
    console.log("🚀 ~ file: events-news.js:399 ~ Events ~ value:", value)
    this.setState({selectedTab: value, PER_PAGE: value ? 6: 3});
    if(value === 0) {
      this.setState({
        cardList: EVENT_MOCKUP,
        filteredCardList: EVENT_MOCKUP,
        paginatedCardList: [],
        pageCount: EVENT_MOCKUP.length,
      })
    } else {
      this.setState({
        cardList: NEWS_MOCKUP,
        filteredCardList: NEWS_MOCKUP,
        paginatedCardList: [],
        pageCount: NEWS_MOCKUP.length,
      })
    }
  }

  render() {
    return (
      <>
        {/* {console.log('pageChange', this.state.filteredCardList)} */}
        <Helmet>
          <title>
            Events,Webinars,Workshops & Conferences for Ionixx Technologies{" "}
          </title>
          <meta
            name="description"
            content="Keep updated on our Events, Webinars, Workshops, Conferences and other useful resources. Ionixx organizes various activities for easy participation and access to all information in one place."
          />
          <link
            rel="canonical"
            href="https://www.ionixxtech.com/resources/events"
          />
        </Helmet>
        <ReactSEOMetaTags
          render={(el) => <Helmet>{el}</Helmet>}
          website={{
            name: "Ionixx Technologies",
            title:
              "Events,Webinars,Workshops & Conferences for Ionixx Technologies",
            description:
              "Keep updated on our Events, Webinars, Workshops, Conferences and other useful resources. Ionixx organizes various activities for easy participation and access to all information in one place. ",
            type: "website",
            image: "/assets/img/ionixx-logo.png",
            url: "https://www.ionixxtech.com/resources/events",
          }}
          twitter={{
            twitter: "https://www.ionixxtech.com/resources/events",
          }}
        />
        <div className="resources-wrapper Events">
          <div className="events-wrapper">
            <ResourceTop />
            <div className="events-inner">
              <h1>
              Explore All Our Events in One Place.{" "}
              </h1>
              <div className="events-top-wrap">
                <div className="categories">
                  <DynamicTabs
                    tabData={[
                      {
                        label: "Events",
                        content: <ContentData
                        
                        latestEvent={this.state.latestCard} 
                        data={this.state.paginatedCardList}
                        configureModal={this.configureVideoModalState}
                        />,
                        
                      },
                      {
                        label: "News",
                        content: <ContentDataTwo
                        latestEvent={this.state.latestNewsCard} 
                        data={this.state.paginatedCardList}/>,
                      },
                    ]}
                    onSearch={this.handleSearch}
                    catOptions={CATERGORY}
                    onSelect={this.handleSelect}
                    pageCount={this.state.filteredCardList.length}
                    onPageChange={this.handlePageChange}
                    onTabChange={this.handleTabChange}
                    perPage={this.state.PER_PAGE}
                    selectedTab={this.state.selectedTab}
                    isCatEnable={!this.state.selectedTab}
                    // isSearchEnable={!this.state.selectedTab}
                  ></DynamicTabs>
                </div>
              </div>
            </div>
          </div>
          <WatchVideo show={this.state.showVideo} title={this.state.videoModalTitle}
                      videoUrl={this.state.videoModalUrl} handleClose={() => {
              this.setState({showVideo: false})
          }}/>
        </div>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(contactAction, dispatch);
}
export default connect(null, mapDispatchToProps)(withRouter(NewsEvents));
