import React from 'react';
import {Link} from "react-router-dom";
import { ROUTES } from '../../../../routes';
import Helmet from 'react-helmet';
import ReactSEOMetaTags from "react-seo-meta-tags";

class CaseStudyDubbuku extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            downloadLink: '/assets/case-studies/Dubuqu-Case-Study.pdf'
        }
    }

    

    render() {
        return (
            <>
                <Helmet>
            <meta charSet="utf-8" />
            <meta
                name="viewport"
                content="width=device-width, initial-scale=1.0, shrink-to-fit=no, user-scalable=no, maximum-scale=1"
            />
            <title>Personalized Media Sharing Application | Ionixx Tech</title>
            <meta name="description" content="Looking for a dynamic application that doubles up as a photo gallery?Our personalized media sharing app is here to help!" />
            <link rel="canonical" href="https://www.ionixxtech.com/case-studies/media-sharing-application" />
            <meta
                property="og:image"
                content={
                "https://www.ionixxtech.com/assets/img/SEO_OG_IMAGE/Dubuqu OG image.png"
                }
            />

        </Helmet>
        <ReactSEOMetaTags
      render={(el) => <Helmet>{el}</Helmet>}
      website={{
        name: "Ionixx Technologies",
        title:
          "Personalized Media Sharing Application | Ionixx Tech",
        description:
          "Looking for a dynamic application that doubles up as a photo gallery?Our personalized media sharing app is here to help!",
        type: "website",
        image: "https://www.ionixxtech.com/assets/img/SEO_OG_IMAGE/Dubuqu OG image.png",
        url:
          "https://www.ionixxtech.com/case-studies/media-sharing-application",

      }}
      twitter={{
        twitter:
          "https://www.ionixxtech.com/case-studies/media-sharing-application",
      }}
    />
            <div class="Resources">
                <section class="section-2 ppr-dnld-custom-sec beige-bg">
                    <div class="custom-container">
                        <div class="tab-content">


            <div className="newsissuedetailed">
            <div className="detailed-common">
                <div className="back-to-common">
                    <span className="back-to-icon-hold">
                        <img src="/assets/img/resource/back-to-new.svg"  alt="bact to new"></img>
                    </span>
                    <Link to={ROUTES.PORTFOLIO}><span className="back-to-link">Portfolio</span></Link>
                </div>

                <div className="whats-on-hold">
                    <div className="ebook-sec">
						<div className="row">
							<div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 resp-apprch-col">
								<div className="custom-section work-with-custom-section">
                                    <div class="main-head-div">
                                        <h1 class="main-head-1">
                                            <span class="secondary-span">Collaborative Cross Platform Media Sharing Solution: Dubuqu NXT Gen</span>
                                        </h1>
                                    </div>
                                    <div className="main-para-div">
                                        <p>Dubuqu NXT Gen, a  <Link to={ROUTES.PORTFOLIO}>media sharing</Link>, social networking mobile application was built on Android to work as a standalone media application – but also eventually scaled up to function as a default offline gallery across all smart mobile devices, irrespective of the devices’ capabilities. </p>
                                            {/* <a class="d-hide m-show" href="">View our software portfolio</a> */}
                                    </div>
								</div>
							</div>
							<div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 resp-apprch-col">
								<div class="ebook-form-sec">
                                    <div class="modal-title">
                                        <h4>Click download to get PDF</h4>
                                    </div>
                                    <div>

                                    <a href={this.state.downloadLink} target="_blank">
                                            <button class="download-case-study contact-submit btn btn-primary">
                                                Download
                                            </button>
                                        </a>                                </div>
                                </div>
							</div>
						</div>	
		            </div>
                </div>

            </div>
        </div> 
        
        </div>
        </div>
        </section>
        </div>
            </>
        );
    }

}

export default CaseStudyDubbuku;
