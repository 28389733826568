import "../../resources.css"
import React, {useEffect, useState} from "react";
import {Button, Collapse, Modal, Form} from 'react-bootstrap';
import {withRouter} from "react-router-dom";
import * as contactAction from "../../../../action/contactAction";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import {ROUTES} from "../../../../routes"

class PaperBooksDownload extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            name: '',
            email: '',
            companyName: '',
            agreement: false,
            processInformation: false,
            validated: false,
            shortLoader:false
        }
        this.onSubmitHandler = this.onSubmitHandler.bind(this);
        this.onDownloadPressed = this.onDownloadPressed.bind(this)
    }

    onDownloadPressed = () => {
        let message = '';
        if (this.state.name === '' || this.state.email === '') {
            this.setState({validated: true})
            return;
        }
        const pdfUrl = this.props.pdfUrl;
        if (pdfUrl) {
            let fileName = pdfUrl.split('/').pop()
            let link = document.createElement("a");
            link.download = fileName;
            link.target = '_blank'
            link.href = pdfUrl
            link.click();
        } else {
            alert('Url not found to start download');
        }

    }

    async onSubmitHandler() {
        
            const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            console.log(re.test(String(this.state.email).toLowerCase()))
            if(re.test(String(this.state.email).toLowerCase())){
                this.setState({
                    shortLoader: true
                })
                const requestPayload = {
                    name: this.state.name,
                    businessEmailID:this.state.email,
                    companyName:this.state.companyName,
                    to: "info@ionixxtech.com",
                    isMob:false
                };
                //social@ionixxtech.com
                try {
                    const response = await this.props.sendEmail(requestPayload);
                    if(response.status === 200){
                        window.open(this.props?.pdfUrl)
                       // this.onDownloadPressed()
                       // this.props.closePopup();
                        this.setState({
                            name: '',
                            email: '',
                            companyName: '',
                            agreement: false,
                            processInformation: false,
                            validated: false,
                            shortLoader: false,
                            emailErr:false
                        },()=>{
                            this.props.history.push({
                                pathname: ROUTES.TOAST,
                                pdfDownload: true,
                                contactDetails: false,
                                subscribeDetails: false
                            })
                        })
                    }
                }
                catch(err) {
                    console.log(err);
                    this.setState({
                        shortLoader:false,
                        emailErr:false
                    })
                }
            }else{
                this.setState({
                    emailErr: true
                })
            }
            
        //}
    }

    render() {
        return (
            <div className="PaperBookDownload">
                {/* <div className="download-common inner-section-1">
                    <div className="row">
                        <div className="col-xl-7 col-lg-7 col-md-7 col-sm-12">
                            <div className="main-head-div">
                                <h4 className="main-head-1">
                                    <span className="primary-span">Your one-stop shop for everything related to technology, blockchain, UX/UI design, and digital transformation.</span>
                                </h4>
                                <p className="press-info">An extensive curation of useful information tailored to suit
                                    your specific needs. We aim to provide our audience an enjoyable learning experience
                                    through our insightful and comprehensive collection of tech resources.</p>
                                <p className="press-info">Happy reading!</p>
                            </div>
                        </div>
                    </div>
                </div> */}
                {/* <div className="inner-section-2">
                    <div className="main-para-div">
                        <button className="btn btn-primary connect-btn" type=""><span>Read Now</span></button>
                    </div>
                </div> */}
                {/* <div className="download-common inner-section-1 read-story-section">
                    <div className="row">
                        <div className="col-xl-7 col-lg-7 col-md-7 col-sm-12">
                            <div className="main-head-div">
                                <h4 className="main-head-1">
                                    <span className="primary-span">READ THE STORY NOW</span>
                                </h4>
                                <p className="press-info">Fill out the form to get your download</p>
                            </div>
                        </div>
                    </div>
                </div> */}
                <div className="">
                    {/* <div className="row"> */}
                        {/* <div className="col-xl-7 col-lg-7 col-md-7 col-sm-12"> */}
                            <Form validated={this.state.validated}>
                                <Form.Group controlId="formBasicName">
                                    <Form.Label className="contact-form-label">Name</Form.Label>
                                    <Form.Control type="text" placeholder="Enter your name"
                                                  required
                                                  value={this.state.name}
                                                  onChange={(e) => {
                                                      this.setState({name: e.target.value})
                                                  }}/>
                                    {/* <Form.Control.Feedback type="invalid">
                                        First name cannot be empty.
                                    </Form.Control.Feedback> */}
                                </Form.Group>

                                <Form.Group controlId="formBasicEmail">
                                    <Form.Label className="contact-form-label">Business Email Id</Form.Label>
                                    <Form.Control type="email" placeholder="Enter email"
                                                  required
                                                  value={this.state.email}
                                                  onChange={(e) => {
                                                      this.setState({email: e.target.value})
                                                  }}/>
                                    {
                                        this.state.emailErr && <div class="invalid-div">Invalid email</div>
                                    }
                                    {/* <Form.Control.Feedback type="invalid">
                                        Email address cannot be empty.
                                    </Form.Control.Feedback> */}
                                </Form.Group>

                             
                                <Form.Group controlId="formBasicName">
                                    <Form.Label className="contact-form-label">Company name (optional)</Form.Label>
                                    <Form.Control type="cname" placeholder="Enter company name"
                                                  value={this.state.companyName}
                                                  onChange={(e) => {
                                                      this.setState({companyName: e.target.value})
                                                  }}/>
                                </Form.Group>




                                {/* <Form.Group className="contact-form-group">
                                    
                                </Form.Group> */}
                                 {/* <a href={this.props?.pdfUrl} target="_blank"> */}
                                     <Button className="contact-submit" variant="primary"  disabled={!(this.state.email && this.state.name)}  onClick={
                                         this.onSubmitHandler
                                        }>
                                {/* onClick={this.onSubmitHandler}> */}
                                      Download 
                                      {this.state.shortLoader ? <span className={"short-loader ml-2"}></span> :""}
                                </Button>
                                {/* </a> */}
                            </Form>
                        {/* </div> */}
                    {/* </div> */}
                </div>
            </div>
        );
    }

}


const mapStateToProps = (state, ownProps) => {
    return state.commonReducer;
  };
  
  const mapDispatchToProps = (dispatch, ownProps) => {
    return bindActionCreators(contactAction, dispatch);
  };
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(withRouter(PaperBooksDownload));