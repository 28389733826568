import React from 'react';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as contactAction from "../../../action/contactAction";
import { ROUTES } from '../../../routes';

 class ToastComponent extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            subscribeToast: false
        }
    }
    componentDidMount(){
        // setTimeout(()=>{
        //     this.props.history.push('/')
        // },10000)
    }
    componentDidUpdate(prevProps){
        if(prevProps.subscribeToast !== this.props.subscribeToast){
            this.setState({
                subcribeToast:this.props.subscribeToast
            })
        }
    }
    render(){
        const contactDetails = this.props?.location ? this.props?.location?.contactDetails: false;
        const pdfDownload = this.props?.location ? this.props?.location?.pdfDownload: false;
        const  subscribeDetails = this.props?.location ? this.props?.location?.subscribeDetails : false;
        return (
        // <Toast onClose={()=>{this.setState({show:false})}} show={this.state.show} autohide delay={10000}>
        //     <Toast.Body>
            <div className="thankyou-section section-1 common-hero-bg beige-bg">
                <div className="Toast custom-toast">
                    <div className="custom-toast-hold">
                        <div className="inner-toast-content">
                            <div className="toast-check-anim">
                                <span>
                                <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52"><circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none"/><path class="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/></svg>
                                </span>
                            </div>
                            <h1 className="inner-toast-head m-3">Thank You</h1>
                            <p className="inner-toast-message">
                                {
                                contactDetails && !pdfDownload && !subscribeDetails && !this.state.subcribeToast ? <p className="inner-toast-message">We look forward to connecting with you.</p> : 
                                !contactDetails && pdfDownload && !subscribeDetails && !this.state.subcribeToast ? "PDF downloaded successfully" : 
                                !contactDetails && !pdfDownload && subscribeDetails && !this.state.subcribeToast ? <p className="thank-para-head">You're now subscribed to our newsletter ! You'll hear from us shortly.</p>:
                                !contactDetails && !pdfDownload && !subscribeDetails && !this.state.subcribeToast ? <div><p className="thank-para-head">We have received your idea! Our team will get in touch with you soon.</p><span>Find more about</span> <a onClick={()=>{this.props.history.push(ROUTES.DESIGNSTUDIO)}}> <span className="thank-design-at-inx">Design at Ionixx</span></a> </div>: 
                                this.state.subcribeToast ? <p className="thank-para-head">You're now subscribed to our newsletter ! You'll hear from us shortly.</p> : ""
                                }
                                
                            </p>
                                {
                                    contactDetails || pdfDownload || subscribeDetails || this.state.subcribeToast &&                                 <div className="backto-link-hold" onClick={()=>{this.props.history.push('/')}}><a><span class="back-to-icon-hold"><img src="/assets/img/back-to-home-green.svg"/></span><span className="thank-back-to-home-text">Back to home</span></a></div>

                                }
                                {/* //"PDF downloaded successfully" : !this.props.downloadToast && this.props.subscribeToast ? "For your subscription" : "We look forward to connecting with you." }</p> */}
                            
                            
                        </div>
                    </div>
                </div>
            </div>
        //     </Toast.Body>
        // </Toast>
        )
    }
}

const mapStateToProps = (state, ownProps) => {
    return state.commonReducer;
};

const mapDispatchToProps = (dispatch, ownProps) => {
return bindActionCreators(contactAction, dispatch);
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(ToastComponent);
