import React from 'react';
import "../../resources.css";
import { ResourceRoute } from '../../resource-routing';
import { Link } from "react-router-dom";
import NewsHeadings from "./common-action/news-heading";
import NewsSubscription from "./common-action/subscripbe-section";

class NewsIssue24 extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            pageEnabled: false,
        }

    }

    render() {
        return (
          <div className="newdesigned_issue_details">
            <NewsHeadings
              buttondDetail="FinTech"
              categoryType="fintech"
              description="The coronavirus crisis is fintech’s biggest test yet—and greatest opportunity to go mainstream | Fortune"
            />

            <div className="newsissuedetailed">
              <div className="detailed-common">
                {/* <div className="back-to-common">
                    <span className="back-to-icon-hold">
                    <img src="/assets/img/resource/back-to-new.svg"  alt="bact to new"></img>
                    </span>
                    <Link  to={ResourceRoute.NEWSLETTER}><span className="back-to-link">Newsletter</span></Link>
                </div> */}

                <div className="news-letter whats-on-hold">
                  <div className="issue-main-head">
                    <h4>What’s on?</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://coingeek.com/chinas-largest-bank-releases-blockchain-whitepaper/"
                      >
                        <h4>
                          China’s largest bank releases blockchain whitepaper |
                          Coin Geek
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        The Industrial and Commercial Bank of China (ICBC) has
                        released a whitepaper that delves into the use of
                        blockchain technology in finance. The bank, considered
                        to be the largest in the world by assets, looked at the
                        progress China is making in blockchain, the challenges
                        that lie ahead and how it can integrate blockchain with
                        emerging technology such as the Internet of Things (IoT)
                        and artificial intelligence (AI).
                      </p>
                    </div>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.theverge.com/2020/4/21/21230643/facebook-reliance-jio-investment-stake-whatsapp"
                      >
                        <h4>
                          Facebook takes $5.7 billion stake in Indian internet
                          giant Jio | Tech Verge{" "}
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Facebook has made a huge bet on India by investing $5.7
                        billion into Jio Platforms, the tech subsidiary of
                        multinational conglomerate Reliance Industries. Jio
                        variously operates broadband, mobile services, and
                        online commerce platforms, and Facebook’s investment
                        gives it around a 10 percent stake in the company,
                        making it the largest minority shareholder.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="news-letter top-picks-hold">
                  <div className="issue-main-head">
                    <h4>Top Picks</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://fortune.com/2020/04/15/fintech-coronavirus-stimulus-checks-loans-paypal-square-chime-stripe-sofi/"
                      >
                        <h4>
                          The coronavirus crisis is fintech’s biggest test
                          yet—and greatest opportunity to go mainstream |
                          Fortune
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        As the novel coronavirus presents the world with its
                        biggest economic challenge in more than a decade,
                        FinTechis having a moment of truth. Companies like SoFi,
                        Robinhood, Chime, and others were built on promises of
                        providing consumers and businesses with easier access to
                        money in all its forms—investments, credit,
                        person-to-person payments—via the Internet, and often
                        without dealing with a brick-and-mortar bank. With the
                        global economy largely on pause, millions of people
                        abruptly out of work, and thousands of bank branches
                        shuttered, the time for FinTechto deliver on those
                        promises has arrived.
                      </p>
                    </div>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://cointelegraph.com/news/10-things-every-blockchain-engineer-should-know-in-2020"
                      >
                        <h4>
                          10 Things Every Blockchain Engineer Should Know in
                          2020 | Coin Telegraph
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Blockchain is no longer just the backbone of Bitcoin
                        (BTC) or cryptocurrencies. It is more than that. Its
                        application in various industries is as wide as the
                        seas. And despite the technology’s limitations and
                        challenges, demand for blockchain engineers increased by
                        more than 500% between 2018 and 2019. Many engineers are
                        wanting to jump on the blockchain bandwagon. Blockchain
                        engineers can have a head start or stay relevant if they
                        know these 10 aspects of the industry. Maybe not all of
                        them. But the more they know, the better.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="news-letter other-reads-hold">
                  <div className="issue-main-head">
                    <h4>Other Reads</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://longreads.com/2020/04/21/no-time-like-the-present/"
                      >
                        <h4>No Time like The Present </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        You don’t know what day it is, do you?Writer, Performer
                        and Musician- Robert Burke Warren digs into ‘the Oddball
                        Effect’ and fascinating brain data that may help explain
                        why.{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <NewsSubscription categoryType="FinTech" />
          </div>
        );
    }

}

export default NewsIssue24;
