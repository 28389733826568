import React from 'react';
import "../../resources.css";
import { ResourceRoute } from '../../resource-routing';
import { Link } from "react-router-dom";
import NewsHeadings from "./common-action/news-heading";
import NewsSubscription from "./common-action/subscripbe-section";

class NewsIssue09 extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            pageEnabled: false,
        }

    }

    render() {
        return (
          <div className="newdesigned_issue_details">
            <NewsHeadings
              buttondDetail="Design"
              categoryType="design"
              description="The next decade of design will be about fixing the last century of excess | Fast Company"
            />

            <div className="newsissuedetailed">
              <div className="detailed-common">
                {/* <div className="back-to-common">
                    <span className="back-to-icon-hold">
                        <img src="/assets/img/resource/back-to-new.svg"  alt="bact to new"></img>
                    </span>
                    <Link  to={ResourceRoute.NEWSLETTER}><span className="back-to-link">Newsletter</span></Link>
                </div> */}

                <div className="news-letter whats-on-hold">
                  <div className="issue-main-head">
                    <h4>What’s on?</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://cointelegraph.com/news/chinas-nationwide-blockchain-network-bsn-will-launch-in-april-2020"
                      >
                        <h4>
                          China's nationwide Blockchain network BSN will launch
                          in April 2020 | Coin Desk
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        China’s nationwide blockchain network, the
                        Blockchain-based Ser­vice Net­work (BSN), will launch in
                        April 2020, six months after it was rolled out for
                        testing. Backed by Chinese government policy think tank
                        the State In­for­ma­tion Cen­ter (SIC), the ambitious
                        project aims to provide a trusted and scalable
                        infrastructure for supporting new blockchain projects as
                        well as the development of smart cities and the digital
                        economy.
                      </p>
                    </div>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://techcrunch.com/2020/01/07/heres-everything-google-announced-at-ces-2020/"
                      >
                        <h4>
                          Here’s everything Google announced at CES 2020 |
                          TechCrunch
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Another year, another blast of Google Assistant news on
                        the first official day of CES. Google slimmed things
                        down a touch for CES this year, though they’ve still got
                        a big presence here. While they didn’t build a whole
                        damn amusement park ride this time around, they’ve still
                        got a massive two-story booth (complete with slides?)
                        parked right outside the front doors of the Las Vegas
                        Convention Center.
                      </p>
                    </div>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://money.com/ethereum-bitcoin-blockchain-predictions/"
                      >
                        <h4>20 Blockchain Predictions for 2020 | Money.com </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Andrew Keys, Managing Partner at Digital Asset Risk
                        Management Advisors (DARMA Capital), and former Head of
                        Global Business Development at blockchain software
                        powerhouse ConsenSys, writes on the rapid development of
                        blockchain technology, the extreme volatility of crypto
                        markets, and the emerging ecosystem and culture of
                        decentralization.{" "}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="news-letter top-picks-hold">
                  <div className="issue-main-head">
                    <h4>Top Picks</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.fastcompany.com/90444298/the-next-decade-of-design-is-about-fixing-a-century-of-excess"
                      >
                        <h4>
                          The next decade of design will be about fixing the
                          last century of excess | Fast Company{" "}
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        The 1980s have gone down in history as the decade of
                        excess (as if the gas-guzzling V8 cars of the 1970s or
                        the rise of fast fashion in the 1990s never happened).
                        But the 2010s have put it all to shame. This was the
                        decade where convenience crushed everything else. Prime
                        two-day shipping became not a luxury, but a way of
                        life—that would give way to one-day shipping, then
                        same-day shipping.{" "}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="news-letter other-reads-hold">
                  <div className="issue-main-head">
                    <h4>Other Reads</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.wired.com/story/australia-is-blazing-into-the-pyrocene-the-age-of-fire/"
                      >
                        <h4>
                          Australia is blazing into the Pyrocene - The Age of
                          Fire | Wired{" "}
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Huge bushfires have torched 14.5 million acres since
                        September, killing at least 18. Vast plumes of smoke are
                        pouring into major cities along the east coast, imposing
                        a dire respiratory health hazard on millions of people.
                        And Australia’s fire season is just getting started.{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <NewsSubscription categoryType="Design" />
          </div>
        );
    }

}

export default NewsIssue09;
