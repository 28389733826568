import React from 'react';
import "../../resources.css";
import { ResourceRoute } from '../../resource-routing';
import { Link } from "react-router-dom";
import NewsHeadings from "./common-action/news-heading";
import NewsSubscription from "./common-action/subscripbe-section";

class NewsIssue72 extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            pageEnabled: false,
        }

    }

    render() {
        return (
          <div className="newdesigned_issue_details">
            <NewsHeadings
              buttondDetail="Design"
              categoryType="design"
              description="Designing with accessibility in mind | UX planet"
            />

            <div className="newsissuedetailed">
              <div className="detailed-common">
                {/* <div className="back-to-common">
                            <span className="back-to-icon-hold">
                                <img src="/assets/img/resource/back-to-new.svg" alt="back to new"></img>
                            </span>
                            <Link to={ResourceRoute.NEWSLETTER}><span className="back-to-link">Newsletter</span></Link>
                        </div> */}

                <div className="news-letter whats-on-hold">
                  <div className="issue-main-head">
                    <h4>What’s on?</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://cointelegraph.com/news/defi-s-money-markets-are-finally-luring-in-institutional-investors"
                      >
                        <h4>
                          DeFi’s money markets are finally luring in
                          institutional investors | Coin Telegraph
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        As Bitcoin’s (BTC) bullish trend persists,
                        interest-bearing crypto products have become all the
                        rage. Some services offer up to 8% returns on Bitcoin
                        holdings. For investors who are already expecting a rise
                        in value, this can be incredibly useful for maintaining
                        cash flow without selling any assets.
                      </p>
                    </div>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://finance.yahoo.com/news/75-leaders-lack-confidence-application-162500335.html?guccounter=1"
                      >
                        <h4>
                          75% of IT Leaders Lack Confidence in Their Web
                          Application Security | Yahoo Finance{" "}
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Cymatic today released new research detailing the state
                        of web application security. Although IT leaders appear
                        somewhat confident in the solutions they currently have
                        in place, reliance on multiple products makes seamless
                        integration of new tools—and therefore overall cyber
                        threat prevention—a significant challenge.
                      </p>
                    </div>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.livemint.com/companies/news/tech-mahindra-to-launch-blockchain-solution-for-bfsi-clients-11617605916045.html"
                      >
                        <h4>
                          Tech Mahindra to launch blockchain solution for BFSI
                          clients | Mint
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Tech Mahindra Ltd has partnered Quantoz,
                        Netherlands-based blockchain application incubator, to
                        launch ‘stablecoin-as-a-service’ blockchain solution for
                        global banks and financial institutions. The usage of
                        Stablecoins as a payment mechanism has significant
                        potential to reduce transaction costs and processing
                        time, while providing auditability and enhancing
                        security.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="news-letter top-picks-hold">
                  <div className="issue-main-head">
                    <h4>Top Picks</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://uxplanet.org/designing-with-accessibility-in-mind-f25a3f70b8c0"
                      >
                        <h4>
                          Designing with accessibility in mind | UX planet
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Accessibility is rising in importance when it comes to
                        designing for digital experiences. Did you know a
                        business can be sued if their website doesn’t meet
                        accessibility standards? If you’re new to design, you
                        might think you can design and create a website in any
                        you can imagine it to be. While that’s technically true,
                        there are important factors to consider like the user
                        experience, and most importantly, are you designing with
                        accessibility in mind?
                      </p>
                    </div>
                  </div>
                </div>
                <div className="news-letter other-reads-hold">
                  <div className="issue-main-head">
                    <h4>Other Reads</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.zdnet.com/article/what-googles-api-copyright-win-over-oracle-means/"
                      >
                        <h4>
                          What Google's API copyright win over Oracle means|
                          ZDNet.com
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        If you listen to Oracle, then the Supreme Court of the
                        United States (SCOTUS) deciding that application
                        programming interfaces (API) can't be strictly
                        copyrighted because fair use applies was an awful
                        decision. Google, the victor, disagrees. But, while this
                        was a major win for Google, it was an even bigger win
                        for all software developers -- yes, even Oracle's -- and
                        for open API and open-source software in particular.{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <NewsSubscription categoryType="Design" />
          </div>
        );
    }

}

export default NewsIssue72;
