import React from 'react';
import "../../resources.css";
import {ResourceRoute} from '../../resource-routing';
import {Link} from "react-router-dom";
import NewsHeadings from "./common-action/news-heading";
import NewsSubscription from "./common-action/subscripbe-section";

class NewsIssue43 extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            pageEnabled: false,
        }

    }

    render() {
        return (
          <div className="newdesigned_issue_details">
            <NewsHeadings
              buttondDetail="HealthTech"
              categoryType="healthtech"
              description="STAT Health Tech: Google’s government contract to use AI in cancer diagnosis & new data on virtual diabetes coaching | STAT Health Tech"
            />
            <div className="newsissuedetailed">
              <div className="detailed-common">
                {/* <div className="back-to-common">
                    <span className="back-to-icon-hold">
                    <img src="/assets/img/resource/back-to-new.svg"  alt="bact to new"></img>
                    </span>
                    <Link  to={ResourceRoute.NEWSLETTER}><span className="back-to-link">Newsletter</span></Link>
                </div> */}

                <div className="news-letter whats-on-hold">
                  <div className="issue-main-head">
                    <h4>Just In</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://clutch.co/profile/ionixx-technologies#review-1514406"
                      >
                        <h4>
                          {" "}
                          Ionixx Technologies Client Review by P2E Technologies{" "}
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        It always feels great to share some happy news, isn't
                        it? Ionixx is delighted to inform that our client P2E
                        Technologies have shared a positive review of our work.
                        Indeed, we are proud to say that Team Ionixx worked in
                        sprints to develop a successful end-to-end regulatory
                        change management platform by paving through every
                        challenge to improvise and deliver the final product.
                        Read the complete review by visiting the link.{" "}
                      </p>
                    </div>
                  </div>
                </div>

                <div className="news-letter whats-on-hold">
                  <div className="issue-main-head">
                    <h4>What’s on?</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.pymnts.com/news/retail/2020/online-marketplace-dynamics-and-retail-reinvention/"
                      >
                        <h4>
                          Online Marketplace Dynamics And Retail Reinvention |
                          PYMNTS.com{" "}
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Marketplace models have powered and scaled commerce for
                        millennia, but the digital shift combined with the
                        online marketplace options now available to buyers and
                        sellers are reenergizing marketplace dynamics for Main
                        Street, where small and medium businesses (SMBs) are
                        reinventing themselves with new digital storefronts and
                        strategies. Marketplaces as Retail’s Front Door: What
                        Sellers Need to Thrive in This New Digital World, a
                        PYMNTS and Visa collaboration, surveyed nearly 1,100
                        individual and business marketplace sellers from across
                        the U.S. to understand how marketplaces are being used,
                        what the popular products are and how payouts are sent
                        and received
                      </p>
                    </div>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.streetinsider.com/Corporate+News/Infosys+%28INFY%29+to+Acquire+Product+Design+and+Development+firm%2C+Kaleidoscope+Innovation/17323566.html"
                      >
                        <h4>
                          Infosys (INFY) to Acquire Product Design and
                          Development firm, Kaleidoscope Innovation | Street
                          Insider{" "}
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Infosys, a global leader in next-generation digital
                        services and consulting, today announced a definitive
                        agreement to acquire Kaleidoscope Innovation, a
                        full-spectrum product design, development and insights
                        firm innovating across medical, consumer and industrial
                        markets, bolstering capabilities in the design of smart
                        products. This acquisition demonstrates Infosys'
                        commitment to innovate for its clients, and make a
                        meaningful impact on human lives by combining
                        cutting-edge technologies and experiences to
                        revolutionize patient care, treatment, diagnostics and
                        consumer health across the world.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="news-letter top-picks-hold">
                  <div className="issue-main-head">
                    <h4>Top Picks</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.statnews.com/2020/09/03/omada-onduo-diabetes-data/"
                      >
                        {" "}
                        <h4>
                          STAT Health Tech: Google’s government contract to use
                          AI in cancer diagnosis & new data on virtual diabetes
                          coaching | STAT Health Tech
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        The U.S. Department of Defense has struck a deal with
                        Google Cloud to use its AI tools to improve cancer
                        diagnosis. The project will use AI to analyze pathology
                        slides on augmented reality microscopes, and test its
                        ability to make a difference in a clinical setting. It
                        will be rolled out at treatment facilities operated by
                        the Defense Health Agency and the Department of Veterans
                        Affairs, with the hope of reducing the 12 million
                        misdiagnoses every year.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="news-letter other-reads-hold">
                  <div className="issue-main-head">
                    <h4>Other Reads</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.technologyreview.com/2020/08/19/1006441/china-data-privacy-hong-yanqing-gdpr/"
                      >
                        {" "}
                        <h4>
                          Inside China’s unexpected quest to protect data
                          privacy | MIT Tech Review
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Over the last few years the Chinese government, seeking
                        to strengthen consumers’ trust and participation in the
                        digital economy, has begun to implement privacy
                        protections that in many respects resemble those in
                        America and Europe today. Even as the government has
                        strengthened consumer privacy, however, it has ramped up
                        state surveillance. It uses DNA samples and other
                        biometrics, like face and fingerprint recognition, to
                        monitor citizens throughout the country. Can a system
                        endure with strong protections for consumer privacy, but
                        almost none against government snooping? The answer
                        doesn’t affect only China. Its technology companies have
                        an increasingly global footprint, and regulators around
                        the world are watching its policy decisions.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <NewsSubscription categoryType="HealthTech" />
          </div>
        );
    }

}

export default NewsIssue43;
