import React from "react";
import "../../resources.css";
import { ResourceRoute } from "../../resource-routing";
import { Link } from "react-router-dom";
import * as contactAction from "../../../../action/contactAction";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
class StoryStrato extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      emailErr: "",
    };
  }
  handleChange(e) {
    var val = e.target.value;
    val = val.replace(/^\s+/, "");
    this.setState({
      email: val,
    });
  }
  render() {
    return (
      <div className="Resources">
        <div className="detailed-news-common">
          <div className="detailed-news">
            <div className="back-to-common">
              <span className="back-to-icon-hold">
                <img
                  src="/assets/img/resource/back-to-new.svg"
                  alt="back to new"
                ></img>
              </span>
              <Link to={ResourceRoute.PRESS}>
                <span className="back-to-link">Press and Media</span>
              </Link>
            </div>
            <div className="detailed-news-main-info">
              <h4 className="">
                BlockApps and Ionixx Technologies announce partnership to build
                successful blockchain applications for businesses through STRATO
                Network
              </h4>
              <p>
                <span>12 Dec 2019</span>
              </p>
            </div>
            <div className="detialed-news-sub-info">
              <h4 className="news-sub-head">Brooklyn, Los Angeles & Chennai</h4>
              <p className="full-new-content">
                BlockApps, the world’s first, most trusted blockchain (BaaS)
                platform has announced partnership with Ionixx Technologies
                Inc., a leading software development firm specializing in
                mobile, web and blockchain technologies to develop and implement
                successful blockchain projects for startups & enterprise
                businesses.
              </p>
              <p className="full-new-content">
                Launched in 2015, the BlockApps STRATO platform allows
                businesses of all verticals to build blockchain solutions,
                applications and networks for their industry. Partners and
                clients are able to integrate with their existing enterprise
                systems and begin building scalable blockchain applications with
                ease. Based on the Ethereum protocol, BlockApps STRATO provides
                enterprise-grade enhancements including API integration
                capabilities, configurable consensus algorithms, and the
                capability to query and report on blockchain data using a
                traditional SQL database.
              </p>
              <p className="full-new-content">
                One of STRATO’s key advantage that it can be deployed on any
                major cloud provider and can be configured for many types of
                business activities while providing clear control over data
                access and privacy.
              </p>
              <p className="full-new-content">
                Ionixx will be joining the BlockApps Partner Network, an
                ecosystem of technology and service providers combined with
                blockchain experts across industries throughout the globe. This
                Network enables rapid application development, integration,
                deployment and enhancement for BlockApps' STRATO customers and
                partners. Through this partnership, customers of Ionixx and
                BlockApps will benefit from:{" "}
              </p>
              <p className="full-new-content">
                Faster deployment (on both cloud and hybrid infrastructures) and
                stronger integration capabilities for customer’s blockchain
                solutionsNew solutions and applications that leverage the
                flexible programming capabilities of Ethereum smart
                contractsEase of scale, both horizontally and
                verticallyProvision of a robust standard for role-based access
                controlGranular permission control to contain network access to
                specific members within the consortium networks, and simple
                assignment of application-level entitlements
              </p>
              <p className="full-new-content">
                Announcing the partnership, Kieren James-Lubin , President &
                CEO, BlockApps, said, “We are proud to announce this
                foundational partnership with Ionixx. STRATO customers and
                Ionixx clients can now leverage end-to-end blockchain technology
                solutions provided by Ionixx and supported by the broader STRATO
                partner network which includes tech giants such as Microsoft,
                Amazon Web Services, and Google. Ionixx' expertise will help
                STRATO platform value for startups and Fortune 500's alike.”
              </p>
              <p className="full-new-content">
                “Since its inception, Ionixx has assured to deliver agile tech
                services for brands and businesses across multiple industries
                and geographies. And, now, partnering with BlockApps STRATO will
                help us achieve transformational and innovative products and
                digital platforms, that will enable our clients to strategize
                and grow through the ongoing tech revolution -‘Blockchain 2.0’”
                said Manju Mohan, CEO & Co-Founder, Ionixx Technologies Inc.
              </p>
            </div>
            <div className="detialed-news-sub-info">
              <h4 className="news-sub-head">About BlockApps</h4>
              <p className="full-new-content">
                BlockApps was the first company incubated out of Consensys in
                2015 and has created several industry innovations including the
                launch the best, easy-to-use, most powerful Blockchain as a
                Service (BaaS) platform on the market called STRATO. Since
                launching STRATO on Microsoft Azure in 2015, BlockApps has
                become first blockchain company to partner with all major cloud
                platforms (Azure, Amazon Web Services, Google Cloud Platform)
                and is a founding member of the Enterprise Ethereum Alliance
                (the world’s largest open standard blockchain organization). The
                company recently raised its Series A Funding led by Fitz Gate
                Ventures with participation from Elliptic Curve Ventures,
                Bloccelerate, Fenbushi Capital, Galaxy Digital Ventures and a
                follow-on investment from ConsenSys.
              </p>
            </div>
            <div className="detialed-news-sub-info">
              <h4 className="news-sub-head">About Ionixx Technologies</h4>
              <p className="full-new-content">
                Ionixx Technologies Founded in 2013, Ionixx Technologies Inc., a
                brainchild of Manju Mohan, Co-Founder & CEO and her father Mohan
                Kumar Subramanyam, Chairman & Co-Founder, is a full-stack,
                design-driven product development company that offers mobile,
                cloud technologies, and custom end-to-end tech solutions to
                startups, enterprises and high-growth companies across all
                industries. It acts as a one-stop platform that oversees
                development techniques and provides all the resource
                requirements under one roof and delivers successful products and
                practices innovative tech applications across all platforms of
                any complexity. Their specialty is essentially on Blockchain
                Technology, Design, Product Development, Mobile & Web
                Development, Cloud Enablement, Testing, DevOps and Internet of
                Things. Headquartered in Los Angeles (USA), the company has its
                presence and operates out of Toronto (Canada), Chennai (India)
                and Singapore. India office ofIonixx, also the development
                center, hosts 80+ in-house qualified designers, architects,
                programmers and quality specialists with expertise in iOS,
                Android, Web, Blockchain, Analytics, and other emerging
                technologies.
              </p>
            </div>
            <div className="detialed-news-sub-info media-info">
              <h4 className="media-contact">Media Contact</h4>
              <p class="media-contact-info-hold">
                <span>BlockApps -</span>
                <span>Mark Caraway |</span>
                <span>Head of Partnerships |</span>
                <span> partners@blockapps.net</span>
              </p>
              <p class="media-contact-info-hold">
                <span>Ionixx Tech -</span>
                <span>Ramya K |</span>
                <span>Sr PR & Communications Specialist |</span>
                <span>ramya.k@ionixxtech.com</span>
              </p>
            </div>
          </div>
          <div className="news-floating-sidebar">
            <div className="stories-card card">
              <div className="stories-card-body card-body">
                <h4>More Stories</h4>
                <ul className="stories-ul">
                  <Link to={ResourceRoute.STORY}>
                    <li>
                      <div>
                        <span className="story-type story-green-bg">
                          BUSINESS
                        </span>
                        <p className="story-desc">
                          Manju Mohan, CEO & Co-Founder accepted into Forbes
                          Business Council
                        </p>
                      </div>
                    </li>
                  </Link>
                  {/* <li>
                                        <div>
                                            <span className="story-type story-orange-bg">BLOCKCHAIN</span>
                                            <p className="story-desc">ICO Vs IEO Vs STO— Understanding the Nuances in
                                                Tokenized Fund Raising</p>
                                        </div>
                                    </li> */}
                </ul>
              </div>
            </div>
            <div className="email-signup-common">
              <h4>Newsletter</h4>
              <p className="signup-head">Sign up for Ionixx emails</p>
              <div className="form-group newsletter-form-group">
                <input
                  type="text"
                  className="form-control"
                  id=""
                  placeholder="Your email"
                  name={"email"}
                  value={this.state.email}
                  onChange={this.handleChange.bind(this)}
                ></input>
                <div
                  className="newsletter-arrow"
                  onClick={() => {
                    var emailRegx =
                      /^(([a-zA-Z\d-_.]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                        this.state.email
                      );
                    if (emailRegx === false) {
                      this.setState({
                        emailErr: "Invalid Email",
                      });
                    } else {
                      this.props
                        .subscribeForNewsLetter({
                          emailID: this.state.email,
                          name: this.state.name,
                          category: "",
                          url: this.props.location.pathname,
                        })
                        .then((res) => {})
                        .catch((err) => {
                          console.log(err);
                        });
                      this.props
                        .sendNewsFeed(this.state.email)
                        .then((res) => {
                          this.setState({
                            email: "",
                            emailErr: "",
                          });
                          if (res.status === 200) {
                          }
                        })
                        .catch((err) => {
                          console.log(err);
                        });
                    }
                  }}
                >
                  <span>
                    <svg
                      width="17"
                      height="16"
                      viewBox="0 0 17 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1 7C0.447715 7 0 7.44772 0 8C0 8.55228 0.447715 9 1 9V7ZM16.7071 8.70711C17.0976 8.31658 17.0976 7.68342 16.7071 7.29289L10.3431 0.928932C9.95262 0.538408 9.31946 0.538408 8.92893 0.928932C8.53841 1.31946 8.53841 1.95262 8.92893 2.34315L14.5858 8L8.92893 13.6569C8.53841 14.0474 8.53841 14.6805 8.92893 15.0711C9.31946 15.4616 9.95262 15.4616 10.3431 15.0711L16.7071 8.70711ZM1 9H16V7H1V9Z"
                        fill="#1E4B5E"
                      />
                    </svg>
                  </span>
                </div>
              </div>
              {this.state.emailErr.length > 0 ? (
                <div className="err-msg">
                  <span>{this.state.emailErr}</span>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return state.commonReducer;
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return bindActionCreators(contactAction, dispatch);
};
export default connect(mapStateToProps, mapDispatchToProps)(StoryStrato);
