import React from 'react';
import { bindActionCreators } from "redux";
import * as contactAction from "../../action/contactAction";
import { connect } from "react-redux";
import "./crypto-otc.css";
import { Button, Collapse, Modal, Form } from 'react-bootstrap';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css';
import { SimpleSelect } from '../../components/common';
import ReactSEOMetaTags from 'react-seo-meta-tags';
import Helmet from 'react-helmet';
import ReactPlayer from 'react-player'
import { ROUTES } from "../../routes";

// import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
    class CryptoOTCwithForm extends React.Component {
        constructor(props) {
        super(props)
        this.state = {
            name: '',
            message: '',
            email: '',
            emailErr: '',
            phoneNumber: '',
            phoneErr: '',
            successMessage: false,
            countDown: 5,
            // videoModalUrl: 'https://www.dropbox.com/s/xmaun14tzgdur2r/Ionixx%20-%20Crypto%20Bot.mp4?dl=0'
        }
        this.onSubmitHandler = this.onSubmitHandler.bind(this);
    }

    async onSubmitHandler() {

        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        console.log(re.test(String(this.state.email).toLowerCase()))
        if (re.test(String(this.state.email).toLowerCase())) {
            const requestPayload = {
                businessEmailID: this.state.email,
                campaignName: "crypto-bot-campaign"
            };
            if (this.state.phoneNumber) {
                requestPayload['mobileNumber'] = this.state.phoneNumber;
            }
            //social@ionixxtech.com
            try {
                const response = await this.props.sendEmailForContact(requestPayload);
                if (response.status === 200) {
                    // this.onDownloadPressed()
                    // this.props.closePopup();
                    this.setState({
                        name: '',
                        message: '',
                        email: '',
                        emailErr: '',
                        phoneNumber: '',
                        phoneErr: '',
                        successMessage: true,
                        // videoModalUrl: 'https://www.dropbox.com/s/xmaun14tzgdur2r/Ionixx%20-%20Crypto%20Bot.mp4?dl=0'
                    }, () => {
                        /* this.props.history.push({
                            pathname: ROUTES.TOAST,
                            contactDetails: false,
                            subscribeDetails: true
                        });
                        this.props.toastShow(true) */
                        setInterval(() => {
                            this.setState({ ...this.state, countDown: this.state.countDown - 1 })
                        }, 1000);
                        setTimeout(() => {
                            this.setState({ ...this.state, successMessage: false })
                            this.props.history.push(ROUTES.ALGOBOTDEVELOPMENT);
                        }, 5000)
                    });

                }
            }
            catch (err) {
                console.log(err);
                this.setState({
                    ...this.state,
                    emailErr: false
                })
            }
        } else {
            this.setState({
                ...this.state,
                emailErr: true
            })
        }

        //}
    }

    render() {

        const metaTitle = " Crypto Trading Bot Development Company | Ionixx Tech";
        const metaDescription = "Automate your crypto trading strategies with crypto trading algorithmic bots. Build proficient algorithmic software with Ionixx.";

        return (


            <>
                <Helmet>
                    <title>{metaTitle}</title>
                    <meta name="description" content={metaDescription} />
                    <link rel="canonical" href="https://www.ionixxtech.com/crypto-bot-development" />
                    <meta property="og:title" content={metaTitle} />
                    <meta property="og:type" content={`website`} />
                    <meta property="og:url" content={"https://www.ionixxtech.com/crypto-bot-development"} />
                    <meta property="og:description" content={metaDescription} />
                    <meta property="og:image" content={"https://www.ionixxtech.com/assets/img/SEO_OG_IMAGE/crypto-bot-development.png"} />
                </Helmet>
                <div className="about-us section">
                    <div className="crypto-otc-withForm">
                        <div className="crypto-banner-otc white-bg">
                            <div className="">
                                <div className="custom-section">
                                    <div className="main-head-div">
                                        <h1 className="main-head-1">
                                            <span className="primary-span">OTC Solutions <em>by Ionixx</em></span>
                                        </h1>
                                    </div>
                                </div>
                                <div className="row">

                                    <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                                        <div className="professionals-hold">
                                            <ul className="professionals-ul">
                                                <li>
                                                 
                                                    <div className="professionals-info">
                                                        <strong> OTC platform solutions</strong>
                                                        <span>Customized Console, Credit Manager, Enables Multiple Accounts & User Management.</span>
                                                    </div>
                                                </li>

                                                <li>
                                                    
                                                    <div className="professionals-info">
                                                        <strong>No Price Slippage</strong>
                                                        <span>Settle High-Volume Orders Without Affecting Token Prices.</span>
                                                    </div>
                                                </li>

                                                <li>
                                                    
                                                    <div className="professionals-info">
                                                        <strong>Automation</strong>
                                                        <span>Support quick order settlements and auto backfilling based on clients’ needs.</span>
                                                    </div>
                                                </li>

                                                <li>
                                                   
                                                    <div className="professionals-info">
                                                        <strong>Intuitive & Secure Desk </strong>
                                                        <span>Add strong KYC, AML and 2FA support for robust risk management. Aid easy bulk trade.</span>
                                                    </div>
                                                </li>

                                               
                                                {/* <li>
                                                    <div class="main-para-div">
                                                        <a target="_blank"><button class="btn btn-primary connect-btn" type="">
                                                           Read More</button></a>
                                                    </div>

                                                </li> */}
                                            </ul>

                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                                        <div className="contact-details">
                                            <div className="main-head-div">
                                                <img src="/assets/img/crypto-otc-development/otc-banner.png"></img>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="lets-connect-form responsive-mode">
                            <div className="heading main-para-div">
                                <div className="main-head-1">
                                    <span className="secondary-span">Have a Query</span>
                                    <span className="primary-span">Let’s Connect</span>
                                </div>
                            </div>
                            <div className="contact-modal ">
                                <Form noValidate validated={this.state.validated} >
                                    <Form.Group controlId="formBasicName ">
                                        <Form.Label className="contact-form-label">Name</Form.Label>
                                        <Form.Control type="name" placeholder="Enter your name" value={this.state.name} required
                                            onChange={(e) => {
                                                this.setState({ ...this.state, name: e.target.value })
                                            }} />
                                        <Form.Control.Feedback type="invalid">
                                            Name cannot be empty.
                            </Form.Control.Feedback>
                                    </Form.Group>
                                    <Form.Group controlId="formBasicEmail" className="w-70">
                                        <Form.Label className="contact-form-label">Email address</Form.Label>
                                        <Form.Control type="email" placeholder="Your email" required
                                            onChange={(e) => {
                                                this.setState({ ...this.state, email: e.target.value })
                                            }} onError={(err) => {
                                                console.log(err);
                                            }} />

                                        <Form.Control.Feedback type="invalid">
                                            Email invalid.
                            </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group controlId="formBasicName" className="custom-rct-phn w-30">
                                        <Form.Label className="contact-form-label">Phone number</Form.Label>

                                        <PhoneInput
                                            country={'us'}
                                            value={this.state.phoneNumber}
                                            onChange={phoneNumber => this.setState({ ...this.state, phoneNumber, phoneErr: "" })}
                                            enableSearch={false}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Phone Number cannot be empty.
                            </Form.Control.Feedback>
                                        {
                                            this.state.phoneErr.length > 0 ? <div className="err-msg">{this.state.phoneErr}</div> : ""
                                        }
                                    </Form.Group>

                                    <Form.Group controlId="exampleForm.ControlTextarea1">
                                        <Form.Label className="contact-form-label">Description* <i>(250 characters max)</i></Form.Label>
                                        <Form.Control className="contact-form-txt-area" as="textarea" rows="3" maxlength="1000"
                                            value={this.state.message}
                                            onChange={(e) => {
                                                this.setState({ ...this.state, message: e.target.value })
                                            }} />
                                    </Form.Group>
                                    {/* <Form.Group controlId="formBasicCheckbox">
                                        <div className="form-element">
                                            <div class="form-element sp-form pd-tb ripple-effect check-content ">
                                                <input class="inp-cbx" id="bx-2" type="checkbox"
                                                    defaultChecked={this.state.processInformation}
                                                    onChange={() => {
                                                        this.setState((prevState) => ({ processInformation: !prevState.processInformation }))
                                                    }}>
                                                </input>
                                                <label class="cbx m-0 theme-change" for="bx-2">
                                                    <span>
                                                        <svg height="10px" viewBox="0 0 12 10" width="12px">
                                                            <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                                                        </svg>
                                                    </span>
                                                    <span class="sp-txt-upload">&nbsp;</span>
                                                    <span className="check-custom-text ">I consent Ionixx to store and process the information I submit. We will never share your personal information with anyone else.</span>
                                                </label>
                                            </div>
                                        </div>
                                    </Form.Group> */}
                                </Form>
                                <Button className="contact-submit" variant="primary" type="submit"
                                    disabled={!(this.state.message && this.state.phoneNumber && this.state.email && this.state.name)}
                                    onClick={
                                        () => { this.onSubmitHandler() }

                                    }>
                                    Submit {this.state.shortLoader ? <span className={"short-loader ml-2"}></span> : ""}
                                </Button>

                            </div>
                        </div>

                        <div>

                        </div>
                    </div>
                </div>


            </>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return state.commonReducer;
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return bindActionCreators(contactAction, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps, null,
    { forwardRef: true }
)(CryptoOTCwithForm);
