import React from 'react';
import "../../resources.css";
import {ResourceRoute} from '../../resource-routing';
import {Link} from "react-router-dom";
import NewsHeadings from "./common-action/news-heading";
import NewsSubscription from "./common-action/subscripbe-section";

class NewsIssue48 extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            pageEnabled: false,
        }

    }

    render() {
        return (
          <div className="newdesigned_issue_details">
            <NewsHeadings
              buttondDetail="FinTech"
              categoryType="fintech"
              description="Crypto interoperability evolves: From blockchain bridges to DeFi transfers | Coin Telegraph"
            />
            <div className="newsissuedetailed">
              <div className="detailed-common">
                {/* <div className="back-to-common">
                    <span className="back-to-icon-hold">
                    <img src="/assets/img/resource/back-to-new.svg"  alt="bact to new"></img>
                    </span>
                    <Link  to={ResourceRoute.NEWSLETTER}><span className="back-to-link">Newsletter</span></Link>
                </div> */}

                <div className="news-letter whats-on-hold">
                  <div className="issue-main-head">
                    <h4>What’s on?</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://cointelegraph.com/news/google-cloud-bets-on-eos-blockchain-venturing-further-into-crypto"
                      >
                        <h4>
                          Google Cloud bets on EOS blockchain, venturing further
                          into crypto | Coin Telegraph
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        As more companies continue to explore how blockchain can
                        help achieve digital transformation, major cloud service
                        providers are showing increased interest. Google Cloud
                        recently became a network block producer by joining the
                        EOS blockchain community. Block producers on EOS are
                        elected by token holders to add transactions to blocks,
                        add blocks to the blockchain, and implement software
                        changes. While Google Cloud must still be elected to
                        become one of the 21 active EOS block producers, Allen
                        Day, developer advocate at Google Cloud, told
                        Cointelegraph that Google is broadly interested in
                        open-source protocols and distributed ledger technology,
                        or DL.
                      </p>
                    </div>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://news.bitcoin.com/a-solution-to-the-blockchain-scalability-problem-meet-jax-network/"
                      >
                        <h4>
                          A Solution To the Blockchain Scalability Problem –
                          Meet Jax.Network | Bitcoin.com
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        The biggest obstacle of Blockchain technology is a
                        limited transaction processing capacity. Existing
                        blockchains like Bitcoin and Ethereum are only able to
                        do about 7-10 transactions per second. This
                        technological limitation has been called the blockchain
                        scalability trilemma. Researchers around the world have
                        come up with different proposals for solving the
                        blockchain scalability problem. Yet, there is no common
                        vision on how to implement it. Jax.Network believes its
                        solution has solved the scalability trilemma and has the
                        potential to become a consumer payment system, unlike
                        other cryptocurrencies that experience delays and
                        failures due to throughput restrictions.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="news-letter top-picks-hold">
                  <div className="issue-main-head">
                    <h4>Top Picks</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://cointelegraph.com/news/crypto-interoperability-evolves-from-blockchain-bridges-to-defi-transfers"
                      >
                        {" "}
                        <h4>
                          Crypto interoperability evolves: From blockchain
                          bridges to DeFi transfers | Coin Telegraph{" "}
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        There is one thing that distinguishes traditional
                        financial systems from cryptocurrencies: advanced
                        interoperability. Thanks to interoperability,
                        cardholders can make payments anywhere in the world
                        without worrying about device compatibility and currency
                        conversion. However, with the growing popularity of
                        decentralized finance in 2020, the issue of
                        cryptocurrency compatibility is getting more relevant
                        than ever. The thing is that the DeFi industry itself
                        was conceived as a single financial ecosystem, the
                        products of which could be compatible with each other.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="news-letter other-reads-hold">
                  <div className="issue-main-head">
                    <h4>Other Reads</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://fortune.com/2020/10/06/google-india-monopoly-paytm-ceo-play-store-fee-commission/"
                      >
                        <h4>
                          Tech firms in India coalesce around a common foe:
                          Google’s ‘monopoly’ | Fortune{" "}
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        On Sept. 28, Google announced a new global policy that
                        would require app developers on its Google Play Store to
                        use Google’s billing platform starting in September
                        2021. The policy effectively closed a loophole that
                        allowed some developers to use an alternative,
                        non-Google payment systems for digital purchases on
                        Google’s app store. In a statement, Google said that the
                        “clarification will not affect the vast majority of
                        developers with apps on Google Play” given that 97% of
                        developers on the store already use Google’s billing
                        system. In India, where Google dominates the market for
                        smartphone operating systems, tech firms saw it
                        differently. They decried the policy as an example of
                        Google’s monopolistic power, with Paytm, a digital
                        payments app and India’s largest tech unicorn, taking
                        the lead in criticizing the American tech giant.{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <NewsSubscription categoryType="FinTech" />
          </div>
        );
    }

}

export default NewsIssue48;
