import React from 'react';
import "../../resources.css";
import {ResourceRoute} from '../../resource-routing';
import {Link} from "react-router-dom";
import NewsHeadings from "./common-action/news-heading";
import NewsSubscription from "./common-action/subscripbe-section";

class NewsIssue41 extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            pageEnabled: false,
        }

    }

    render() {
        return (
          <div className="newdesigned_issue_details">
            <NewsHeadings
              buttondDetail="FinTech"
              categoryType="fintech"
              description="Blockchain-Based Crypto Payments Need To Focus On User Experience To Go Mainstream | Forbes"
            />
            <div className="newsissuedetailed">
              <div className="detailed-common">
                {/* <div className="back-to-common">
                    <span className="back-to-icon-hold">
                    <img src="/assets/img/resource/back-to-new.svg"  alt="bact to new"></img>
                    </span>
                    <Link  to={ResourceRoute.NEWSLETTER}><span className="back-to-link">Newsletter</span></Link>
                </div> */}

                <div className="news-letter whats-on-hold">
                  <div className="issue-main-head">
                    <h4>What’s on?</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.entrepreneur.com/article/355084"
                      >
                        <h4>
                          Wipro Builds Blockchain Trading Platform for German
                          Energy Major Uniper | Entrepreneur
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Wipro on Thursday announced implementation of a
                        blockchain-based small-scale liquefied natural gas
                        (ssLNG) trading platform for Uniper Global Commodities
                        SE, the energy and power commodity trading arm of
                        Germany-based Uniper. Built in collaboration with
                        Uniper, the platform employs a consortium model for all
                        ssLNG players to streamline the trading market, which in
                        turn helps traders save costs and brings in more
                        efficiency in the market.
                      </p>
                    </div>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://finance.yahoo.com/news/vechain-introduces-blockchain-enabled-sustainability-130000718.html"
                      >
                        <h4>
                          VeChain Introduces New Blockchain-enabled
                          Sustainability Solution To Power "Green Business" For
                          Enterprises | Yahoo
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        As consumer behaviors shift towards greener and more
                        sustainable products due to climate change awareness,
                        companies are increasingly looking to integrate a more
                        sustainable approach to their businesses. Market-leaders
                        such as Nike, Adidas, H&M & Amazon are already placing
                        sustainability at the forefront of their global product
                        and marketing strategies.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="news-letter top-picks-hold">
                  <div className="issue-main-head">
                    <h4>Top Picks</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.forbes.com/sites/seansteinsmith/2020/08/19/blockchain-based-crypto-payments-need-to-focus-on-user-experience-to-go-mainstream/#755d0bee3d22"
                      >
                        <h4>
                          Blockchain-Based Crypto Payments Need To Focus On User
                          Experience To Go Mainstream | Forbes
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Blockchain and blockchain-enabled payments are the
                        future of transactions, but there are several issues
                        that need to be resolved first. Ever since the idea of
                        blockchain and cryptographically secured currency
                        entered the marketplace, the benefits of widespread
                        adoption have been discussed and analyzed at great
                        length. Despite this engagement, however, the
                        utilization of cryptocurrency as a legitimate fiat
                        alternative, i.e., using crypto as money, remains
                        something of an oddity, with only a relatively small
                        percentage of cryptocurrencies being used as a medium of
                        exchange.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="news-letter other-reads-hold">
                  <div className="issue-main-head">
                    <h4>Other Reads</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.nytimes.com/2020/08/19/opinion/microsoft-flight-simulator.html"
                      >
                        <h4>
                          I Tried Microsoft’s Flight Simulator. The Earth Never
                          Seemed So Real. | NY Times
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Microsoft’s latest version of Flight Simulator is so
                        realistic that it suggests a new way of understanding
                        the digital world.Microsoft has just released a new
                        version of Flight Simulator, an institution in the
                        gaming world that made its debut in 1982, back in the
                        primordial age of video games. The update was released
                        this week, and Microsoft provided it to some journalists
                        and Flight Sim enthusiasts as a preview version weeks
                        ago. It’s meant to show off the what’s possible in
                        computing — in particular, how the increasing fidelity
                        of virtual worlds might alter how we understand the
                        “real” one.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <NewsSubscription categoryType="Fintech" />
          </div>
        );
    }

}

export default NewsIssue41;
