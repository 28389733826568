
import {ResourceRoute} from '../resource-routing';
export const NEWS_LETTER = [
  {
    content1:
      "A New Era in Healthcare: AI's Evolving Role in Diagnostics and Treatment",
    content2:
    "A New Era in Healthcare: AI's Evolving Role in Diagnostics and Treatment",
    title: " 26 Sep 2023",
    issue: "Issue No. 86",
    Link: ResourceRoute.NEWSISSUE86,
    category: "Healthtech",
    postedAt: " 26 Sep 2023",
  },
  {
    content1:
      "Evolution of ePrescribing: Revolutionizing Healthcare & Navigating the Digital Shift",
    content2:
      "Evolution of ePrescribing: Revolutionizing Healthcare & Navigating the Digital Shift",
    title: " 22 Aug 2023",
    issue: "Issue No. 85",
    Link: ResourceRoute.NEWSISSUE85,
    category: "Healthtech",
    postedAt: " 22 Aug 2023",
  },
  {
    content1:
      "A Safer IoT Ecosystem: Harnessing Blockchain for Enhanced Security of Digital IDs.",
    content2:
      "A Safer IoT Ecosystem: Harnessing Blockchain for Enhanced Security of Digital IDs.",
    title: "16 Aug 2023",
    issue: "Issue No. 84",
    Link: ResourceRoute.NEWSISSUE84,
    category: "Web3",
    postedAt: "16 Aug 2023",
  },
  {
    content1:
      "Can the current US banking crisis drive the demand and adoption of cryptocurrencies?",
    content2:
      "Can the current US banking crisis drive the demand and adoption of cryptocurrencies?",
    title: "12 Jun 2023",
    issue: "Issue No. 83",
    Link: ResourceRoute.NEWSISSUE83,
    category: "Web3",
    postedAt: "12 Jun 2023",
  },
    {
      content1:
        "The Role of Machine Learning in Medical Decision-Making",
      content2:
        "The Role of Machine Learning in Medical Decision-Making",
      title: "26 May 2023",
      issue: "Issue No. 82",
      Link: ResourceRoute.NEWSISSUE82,
      category: "Healthtech",
      postedAt: "26 May 2023",
    },
    {
      content1:
        "Medical data security: Top 5 things to know | Tech Republic",
      content2:
        "Medical data is a valuable commodity that needs to be protected from cybersecurity threats. Tom Merritt lists five things to know about medical data security.",
      title: "11 Jun 2021",
      issue: "Issue No. 81",
      Link: ResourceRoute.NEWSISSUE81,
      category: "Healthtech",
      postedAt: "11 Jun 2021",
    },
    {
      content1:
        "What are the most dreaded antipatterns in web development? | Tech Republic",
      content2:
        "Ever since Tim Berners-Lee wrote the first webpage in 1991, web developers have delivered seemingly endless innovation. Most of that innovation is great--but not always. Begin cofounder Brian LeRoux asked an innocent question on Twitter:What is the most dreaded web development antipattern?An antipattern is a commonly reinvented but bad solution to a problem. In other words, it's developers applying fixes that are worse than the problems.What does that mean? ",
      title: "04 Jun 2021",
      issue: "Issue No. 80",
      Link: ResourceRoute.NEWSISSUE80,
      category: "Web3",
      postedAt: "04 Jun 2021",
    },
    {
      content1:
        "Android 12 preview: First look at Google's radical new design | The Verge",
      content2:
        "Google announced the latest beta for Android 12 at Google I/O. It has an entirely new design based on a system called “Material You,” featuring big, bubbly buttons, shifting colors, and smoother animations. It is “the biggest design change in Android’s history,” according to Sameer Samat, VP of product management, Android and Google Play. That might be a bit of hyperbole, especially considering how many design iterations Android has seen over the past decade, but it’s justified.",
      title: "28 May 2021",
      issue: "Issue No. 79",
      Link: ResourceRoute.NEWSISSUE79,
      category: "Design",
      postedAt: "28 May 2021",
    },
    {
      content1:
        "Why is Mobile App Security “Shifting Left?”| DevproJournal.com",
      content2:
        "As COVID continues to ravage the globe, companies across industries have pivoted to moving brands and employees online and onto mobile devices through mobile applications. Since many of these companies have chosen to prioritize the user experience and time-to-market for a variety of reasons, mobile application security has fallen behind.                    ",
      title: "21 May 2021",
      issue: "Issue No. 78",
      Link: ResourceRoute.NEWSISSUE78,
      category: "Web3",
      postedAt: "21 May 2021",
    },
    {
      content1:
        "'Speculative excess': ethereum finds new peak in sizzling crypto market | Reuters",
      content2:
        "Cryptocurrency ether rose to a fresh record peak on Tuesday before dropping sharply as some investors pulled profits from a white-hot market bulging with questionable new entrants.A day after blowing past $3,000 for the first time, ether, the token traded over the ethereum blockchain, hit $3,457.64 on the bitstamp exchange before dropping 6% to $3,244.",
      title: "14 May 2021",
      issue: "Issue No. 77",
      Link: ResourceRoute.NEWSISSUE77,
      category: "Fintech",
      postedAt: "14 May 2021",
    },
    {
      content1:
        "Healthcare Providers Continue to Adapt to Changing Care Environments | HealthTech Magazine ",
      content2:
        "Even before the pandemic, many healthcare providers were at various stages of a digital transformation featuring mobile technology strategies that reached beyond hospital walls. The pandemic forced them to accelerate efforts along two crucial pathways: enabling remote work for employees and offering telehealth services to patients. Based on what they’ve gone through in the past year, some are adjusting or expanding their mobile environments.",
      title: "7 May 2021",
      issue: "Issue No. 76",
      Link: ResourceRoute.NEWSISSUE76,
      category: "Healthtech",
      postedAt: "7 May 2021",
    },
    {
      content1:
        "Things You Need To Know if You Want To Improve Node.js Web Development  | Tech Bullion",
      content2:
        "Different programming languages each have their advantages. However, when it comes to web development, JavaScript is the core programming language of web technology. Node.js is an open-source server capable of building web applications by executing JavaScript. It has gained immense popularity among web developers worldwide due to its versatility and flexibility. However, like with many other systems, there is a need to improve the efficiency of Node.js web development to help you achieve optimal results with minimal resources. ",
      title: "30 Apr 2021",
      issue: "Issue No. 75",
      Link: ResourceRoute.NEWSISSUE75,
      category: "Web3",
      postedAt: "30 Apr 2021",
    },
    {
      content1:
        "Amazon’s cashierless checkout technology is coming to its new supermarkets | CNBC.com",
      content2:
        "Amazon’s cashierless checkout technology could soon be coming to its growing line of Fresh grocery stores. Features of Amazon’s automated checkout technology called “Just Walk Out,” appear in planning documents for a store under construction in a suburban shopping plaza in Brookfield, Connecticut. The technology allows customers to skip the checkout line by tracking any items they grab and charging them when they leave.",
      title: "23 Apr 2021",
      issue: "Issue No. 74",
      Link: ResourceRoute.NEWSISSUE74,
      category: "Fintech",
      postedAt: "23 Apr 2021",
    },
    {
      content1:
        "Is Flutter Future-ready for Enterprise Apps? | AppInvetiv.com",
      content2:
        "Flutter is king, or at least it will be when it comes to enterprise application development. This is the overwhelming anthem of voices rising from the helm of the mobile app development industry. As per Google, 500,000 developers use its software development kit monthly. Most of the time the question comes up about what to choose Flutter or React native. With a rising popularity share, Flutter is rubbing shoulders with its closest rival React Native, and will soon overtake it courtesy of the renewed interest from Enterprise mobile app developers.",
      title: "16 Apr 2021",
      issue: "Issue No. 73",
      Link: ResourceRoute.NEWSISSUE73,
      category: "Web3",
      postedAt: "16 Apr 2021"
    },
    {
      content1: "Designing with accessibility in mind | UX planet",
      content2:
        "Accessibility is rising in importance when it comes to designing for digital experiences. Did you know a business can be sued if their website doesn’t meet accessibility standards? If you’re new to design, you might think you can design and create a website in any you can imagine it to be. While that’s technically true, there are important factors to consider like the user experience, and most importantly, are you designing with accessibility in mind?",
      title: "9 Apr 2021",
      issue: "Issue No. 72",
      Link: ResourceRoute.NEWSISSUE72,
      category: "Design",
      postedAt: "9 Apr 2021",
    },
    {
      content1:
        "Bitcoin has won over Institutional Investors: Could governments be next? | Bitcoinist.com ",
      content2:
        "When Bitcoin surged to an all-time high of $17,000 in late 2017, the cryptocurrency headlined media outlets and financial journals — but not for the right reasons. Analysts and major institutional figureheads bashed and ostracized the promising digital asset, dubbing it a “fraud” and “useless as a payment system.” Not soon after, Bitcoin suffered a major correction, with prices plummeting down 70% in a single year. For retail investors, the warnings of doubtful institutions and analysts that they previously ignored seemed to ring truer than ever. Even at the time, one thing was for certain: cryptocurrencies would never become mainstream without institutional adoption.",
      title: "4 Apr 2021",
      issue: "Issue No. 71",
      Link: ResourceRoute.NEWSISSUE71,
      category: "Fintech",
      postedAt: "4 Apr 2021"
    },
    {
      content1:
        "Programming by Voice May Be the Next Frontier in Software Development | IEEE Spectrum",
      content2:
        "Increasingly, we’re interacting with our gadgets by talking to them. Old friends like Alexa and Siri are now being joined by automotive assistants like Apple CarPlay and Android Auto, and even apps sensitive to voice biometrics and commands. But what if the technology itself could be built using voice? That’s the premise behind voice coding, an approach to developing software using voice instead of a keyboard and mouse to write code.",
      title: "26 Mar 2021",
      issue: "Issue No. 70",
      Link: ResourceRoute.NEWSISSUE70,
      category: "Web3",
      postedAt: "26 Mar 2021"
    },
    {
      content1:
        "Why Amazon, Google, and Microsoft Are Designing Their Own Chips | Bloomberg.com  ",
      content2:
        "In the process of transforming itself from an online bookstore into a cloud computing giant, Amazon.com Inc. became one of the world’s largest purchasers of the computer chips that power data centers. As its cloud business has expanded, the company has become increasingly fixated on designing its own chips instead of buying them. The shift could have potentially drastic implications for a critical aspect of the technology industry—and could prove threatening for traditional chipmakers such as Intel Corp. and Advanced Micro Devices Inc.",
      title: "19 Mar 2021",
      issue: "Issue No. 69",
      Link: ResourceRoute.NEWSISSUE69,
      category: "Design",
      postedAt: "19 Mar 2021"
    },
    {
      content1:
        "How Technology Can Enhance Behavioral Healthcare Design and Care | DallasInnovates.com ",
      content2:
        "Technology has significantly pushed the capabilities of medicine forward, with everything from computers that sequence genes to robots that assist in minimally-invasive surgery. However, one speciality of medicine with untapped potential when it comes to utilizing cutting-edge tech is mental healthcare. Yet as the pandemic has shown, there are great opportunities within the realm of mental healthcare to take advantage of existing and upcoming tech advances. ",
      title: "12 Mar 2021",
      issue: "Issue No. 68",
      Link: ResourceRoute.NEWSISSUE68,
      category: "Healthtech",
      postedAt: "12 Mar 2021",
    },
    {
      content1: "Building User Trust In UX Design | Smashing Magazine ",
      content2:
        "Building trust is one of the central goals of user experience design. And yet trust is a concept that’s very hard to define in a precise manner. We all know it when we feel it but often fall short of putting it in words. Being able to turn elusive and intangible into actionable and concrete steps, however, is exactly what makes UX so crucial in the modern business ecosystem. Although a product experience that is useful and coherent is what fundamentally builds a sense of security and satisfaction, there’s a lot more nuance that goes building it. That’s what this article is about. We’ll take a deeper dive into users’ trust and how we can use UX to build a lasting relationship with your clientele.",
      title: "5 Mar 2021",
      issue: "Issue No. 67",
      Link: ResourceRoute.NEWSISSUE67,
      category: "Design",
      postedAt: "5 Mar 2021"
    },
    {
      content1:
        "The state of Java software development in 2021 | JAX Enter ",
      content2:
        "The recent results from the 9th annual global developer productivity report gives us insights to the current state of Java in 2021. Over 850 Java developers responded to questions about their experiences, preferred tools and technologies, and what issues they currently face. Topics covered include the nature of Java teams, challenges they experience, preferred tools, and success of said tools and technologies. The key finding shows that despite increasing use of microservices, developers are still facing long redeploy times and interservice functionality issues.",
      title: "26 Feb 2021",
      issue: "Issue No. 66",
      Link: ResourceRoute.NEWSISSUE66,
      category: "Web3",
      postedAt: "26 Feb 2021"
    },
    {
      content1:
        "Blockchain firm aims to build crypto city in Nevada desert | Coin Telegraph",
      content2:
        "Blockchain incubator and investment firm Blockchains LLC is stepping up its plans to build a smart city in the Nevada desert east of Reno. In an interview with the Associated Press released Saturday, Blockchains CEO Jeffrey Berns said he had asked the state of Nevada to let him form a local government on the 67,000 acres the firm owns in Storey County.",
      title: "19 Feb 2021",
      issue: "Issue No. 65",
      Link: ResourceRoute.NEWSISSUE65,
      category: "Web3",
      postedAt: "19 Feb 2021"
    },
    {
      content1:
        "The Future Of Customer Experience: Zero User Interfaces | Security Boulevard",
      content2:
        "Customer experience stands to be a major transformative element across disciples; from retail to telehealth, to education to manufacturing, every industry is demanding a unique customer experience (CX) strategy. Many companies are taking steps to adjust their CX toward the future and are getting ready to rebuild their CX strategy.  According to findings from Capgemini, 77% of customers expect nontouch interactions for customer experiences. More than 62% expect the same touchless experience even after the current pandemic subsides.",
      title: "12 Feb 2021",
      issue: "Issue No. 64",
      Link: ResourceRoute.NEWSISSUE64,
      category: "Design",
      postedAt: "12 Feb 2021"
    },
    {
      content1:
        "The UI/UX Patterns You Literally Can’t Use | One Zero/Medium",
      content2:
        "You love thinking big. You love looking at other tech companies to see how they designed their products, or their navigation system, or that one animation transition you love. You aspire to design something like that. You keep bookmarks in your head: If you needed an expanding carousel for your entertainment app, you could take inspiration from Netflix. Or maybe not. There are in fact some patterns you can’t use. Of course, companies are smart and don’t give a lot of written detail about how the design would be used or in what product, since they don’t want to leak pre-releases. The only clues you have are the dotted designs in the black and white diagrams that serve as a contextual environment, and the title, which will tell you if they are talking about an animation or a static design. Over a year since my last review, let’s look at some of the new patents in play and explore some trending companies.",
      title: "5 Feb 2021",
      issue: "Issue No. 63",
      Link: ResourceRoute.NEWSISSUE63,
      category: "Design",
      postedAt: "5 Feb 2021"
    },
    {
      content1:
        "FivebalanceUSA officially relaunches its crypto self-improvement mobile app | Bravenewcoin.com",
      content2:
        "FiveBalanceUSA is a crypto startup company that has developed a unique self-improvement application that is available free on the Google Play store. The app intends to serve people dealing with depression, by rewarding them with FBN cryptocurrency upon achieving their personal customized goals in 5F categories - Fit, Financial, Family, Flair, and Faith.",
      title: "29 Jan 2021",
      issue: "Issue No. 62",
      Link: ResourceRoute.NEWSISSUE62,
      category: "Fintech",
      postedAt: "29 Jan 2021"
    },
    {
      content1:
        "Chrome 88 Is Rolling Out Now With A Heavy Focus On Security, Web App Features, And More | Chromeunboxed.com",
      content2:
        "Chrome 87 dropped back in November, and with it, a load of improvements to the browser’s speed and battery. In fact, it featured the largest jump in performance that we’ve seen in Chrome for years! Now, Chrome 88 is rolling out and has shifted gears to focus heavily on security features, though it takes on several other tasks as well. Let’s take a look at what you’ll receive when you upgrade to Chrome 88.",
      title: "22 Jan 2021",
      issue: "Issue No. 61",
      Link: ResourceRoute.NEWSISSUE61,
      category: "Web3",
      postedAt: "22 Jan 2021"
    },
    {
      content1:
        "5 Proven Ways To Speed Up Digital Product Development | Hackernoon",
      content2:
        "The faster you can develop a product, the sooner it gets to market, and the more sales you make. Your brand improves its market position and credibility by beating competitors to the punch. It is important, however, not to sacrifice product quality. This list of proven strategies will help you achieve those things.",
      title: "8 Jan 2021,",
      issue: "Issue No. 60",
      Link: ResourceRoute.NEWSISSUE60,
      category: "Design",
      postedAt: "8 Jan 2021"
    },
    {
      content1:
        "Tech That Will Change Your Life in 2021 | The Wall Street Journal ",
      content2:
        "A pandemic that ravaged the world and accelerated the digital transformation of, well, everything? Not even the best of futurists or Magic 8 ball-shaking psychics could have predicted the year that was 2020. And yet while we may have missed the biggest news, our predictions for what would occur in the tech world held up decently. Now, 2020 has become the lens through which all our 2021 predictions are glimpsed. As we continue to live in a pandemic-fighting world, innovators will aim tech solutions at our personal and professional lives, from at-home streaming movie debuts to an overdue evolutionary leap of the laptop. Read on to know what to look out for this year.",
      title: "2 Jan 2021",
      issue: "Issue No. 59",
      Link: ResourceRoute.NEWSISSUE59,
      category: "Web3",
      postedAt: "2 Jan 2021"
    },
    {
      content1:
        "The built environment will be one of tech’s next big platforms | Techcrunch",
      content2:
        "From the beginning, the plan for Sidewalk Labs (a subsidiary of Alphabet and — by extension — a relative of Google) to develop a $1.3 billion tech-enabled real estate project on the Toronto waterfront was controversial. Privacy advocates had justified concerns about the Google-adjacent company’s ability to capture a near-total amount of data from the residents of the development or any city-dweller that wandered into its high-tech panopticon.",
      title: "25 Dec 2020",
      issue: "Issue No. 58",
      Link: ResourceRoute.NEWSISSUE58,
      category: "Web3",
      postedAt: "25 Dec 2020"
    },
    {
      content1:
        "5 key app sec trends for 2021: The shift is on for software teams  | Techbeacon",
      content2:
        "For many companies, 2020 was about accelerating their move to the cloud. The pandemic drove a dramatic expansion of remote work, developers focused more on cloud-native deployments, and application security teams had to adapt to a change in usage and, often, greater demand. In 2021, many of those seeds will take root. Businesses that accelerated digital transformations will need to secure their infrastructure, developers working remotely on cloud-native applications will have more integrated security in their coding environments, and application security teams will be tasked with facilitating faster development cycles, rather than just finding vulnerabilities.",
      title: "18 Dec 2020,",
      issue: "Issue No. 57",
      Link: ResourceRoute.NEWSISSUE57,
      category: "Web3",
      postedAt: "18 Dec 2020,"
    },
    {
      content1: "IoT goes: 5G, whiz | 2020-11-11   | DC Velocity ",
      content2:
        "The IoT (internet of things) is a fairly new item in the logistics toolbox, but technology experts across the industry say it’s about to get a major upgrade, thanks to the national rollout of fifth-generation (5G) wireless networks. Supply chains have relied on IoT technology for several years, using its collection of distributed sensors to monitor variables like open dock doors at a DC, the speed of conveyor belts in a fulfillment center, and the location of trailers in a yard. Those applications have proved the technology’s business case, demonstrating the IoT’s value in connecting physical supply chain assets to the digital web.",
      title: "11 Dec 2020",
      issue: "Issue No. 56",
      Link: ResourceRoute.NEWSISSUE56,
      category: "Web3",
      postedAt: "11 Dec 2020"
    },
    {
      content1:
        "These two trends will transform product development in 2021  | Technical.ly",
      content2:
        "In recent years, innovation has led to revenue gains and customer adoption and loyalty — think Tesla cars or Peloton bikes. These products are excellent examples of how technologies like software-run cars and digital workout platforms can form lasting customer bonds. Yet while these products are technically impressive, great marketing made them beloved by the masses. In 2021, CMOs will model the success of these and other companies by becoming closely aligned with product development.",
      title: "4 Dec 2020",
      issue: "Issue No. 55",
      Link: ResourceRoute.NEWSISSUE55,
      category: "Design",
      postedAt: "4 Dec 2020"
    },
    {
      content1: "This Database Is Finally Holding AI Accountable | Vice ",
      content2:
        "The Artificial Intelligence Incident Database (AIID) is a crowdsourced platform with intentions to wrangle in the Wild West of AI. “Fool me once, shame on me; fool me twice shame on you,” comes to mind, as the platform is being used to document and compile AI failures so they won’t happen again. A self-defined “systematized collection of incidents where intelligent systems have caused safety, fairness, or other real-world problems,” the AIID’s foundation lies in creating a repository of articles about different times AI has failed in real-world applications.",
      title: "27 Nov 2020",
      issue: "Issue No. 54",
      Link: ResourceRoute.NEWSISSUE54,
      category: "Web3",
      postedAt: "27 Nov 2020,"
    },
    {
      content1:
        "How Low Code Is Transforming Software Development  | DevOps.com ",
      content2:
        "Low code/no code is a business catalyst enabling technologies to automate processes and allowing developers to create real-time solutions for their business. With low code, businesses are able to build, customize and deploy apps with little to no coding.In this interview, Rackspace CTO Jeff DeVerter explains how low code is transforming software development and how Robotic Process Automation (RPA) complements low-code application platforms.",
      title: "20 Nov 2020",
      issue: "Issue No. 53",
      Link: ResourceRoute.NEWSISSUE53,
      category: "Web3",
      postedAt: "20 Nov 2020"
    },
    {
      content1:
        "Banking, Fintech And The Second Pandemic Wave: What's Next For 2020?  | Forbes  ",
      content2:
        "Now, with Covid-19 casualties having surpassed the one million mark worldwide, it's clear that the crisis is far from over. Conversely, the second wave is just beginning. So, what does this mean for businesses moving forward, and in particular, the banking and fintech sectors? What measures can be taken to protect businesses in the future? ",
      title: "06 Nov 2020",
      issue: "Issue No. 52",
      Link: ResourceRoute.NEWSISSUE52,
      category: "Fintech",
      postedAt: "06 Nov 2020"
    },
    {
      content1:
        "What “Digital Wellbeing” Means to Material Design  | Material Design  ",
      content2:
        "Products can promote wellbeing by focusing on fundamental needs, not just clicks. If any interaction with technology has the capacity to impact an individual’s well-being, either positively or negatively, what strategies can we use – as developers, designers, researchers, and makers of all disciplines – to build with digital wellbeing in mind? ",
      title: "30 Oct 2020",
      issue: "Issue No. 51",
      Link: ResourceRoute.NEWSISSUE51,
      category: "Design",
      postedAt: "30 Oct 2020"
    },
    {
      content1:
        "A radical new technique lets AI learn with practically no data  | MIT Tech Review ",
      content2:
        "Machine learning typically requires tons of examples. To get an AI model to recognize a horse, you need to show it thousands of images of horses. This is what makes the technology computationally expensive—and very different from human learning. A child often needs to see just a few examples of an object, or even only one, before being able to recognize it for life. Now a new paper from the University of Waterloo in Ontario suggests that AI models should also be able to do this—a process the researchers call “less than one”-shot, or LO-shot, learning. In other words, an AI model should be able to accurately recognize more objects than the number of examples it was trained on. That could be a big deal for a field that has grown increasingly expensive and inaccessible as the data sets used become ever larger. ",
      title: "23 Oct 2020",
      issue: "Issue No. 50",
      Link: ResourceRoute.NEWSISSUE50,
      category: "Web3",
      postedAt: "23 Oct 2020"
    },
    {
      content1: "What’s new in Angular 11 | Infoworld ",
      content2:
        "Development is proceeding on Angular 11, a planned upgrade to the Google-developed web framework, with five beta releases delivered in September and two more in October. So far the proposed additions and changes include stricter types, router performance improvements, and the removal of support for certain Internet Explorer browser versions. The beta releases are available in GitHub. With Angular 11, stricter types would be added for DatePipe and number pipes, in order to catch misuses, such as passing an Observable or an array, at compile time. ",
      title: "16 Oct 2020",
      issue: "Issue No. 49",
      Link: ResourceRoute.NEWSISSUE49,
      category: "Web3",
      postedAt: "16 Oct 2020"
    },
    {
      content1:
        "Crypto interoperability evolves: From blockchain bridges to DeFi transfers | Coin Telegraph ",
      content2:
        "There is one thing that distinguishes traditional financial systems from cryptocurrencies: advanced interoperability. Thanks to interoperability, cardholders can make payments anywhere in the world without worrying about device compatibility and currency conversion. However, with the growing popularity of decentralized finance in 2020, the issue of cryptocurrency compatibility is getting more relevant than ever. The thing is that the DeFi industry itself was conceived as a single financial ecosystem, the products of which could be compatible with each other.",
      title: "09 Oct 2020",
      issue: "Issue No. 48",
      Link: ResourceRoute.NEWSISSUE48,
      category: "Fintech",
      postedAt: "09 Oct 2020,"
    },
    {
      content1:
        "Tech Giants Team Up to Fix Typography's Biggest Problem | Wired",
      content2:
        "The ideas behind variable fonts have been around awhile, but the technology has only now caught up with them. Even if you're not a developer or a typography nerd, this is a big deal. How big? Well, Apple, Google, Microsoft, and Adobe are working on it—together.__ __These unlikely collaborators, who are the four largest platforms for digital typography, unveiled variable fonts recently",
      title: "02 Oct 2020",
      issue: "Issue No. 47",
      Link: ResourceRoute.NEWSISSUE47,
      category: "Design",
      postedAt: "02 Oct 2020,"
    },
    {
      content1:
        "The Role Of Emotions In UX/UI: Understanding Emotional Design | Forbes",
      content2:
        "Humans are rational beings. But we are also driven by emotions. How many times have you picked up a bag of chips or a chocolate bar at the convenience store just because they looked appealing? It is not just physical products that can make us feel a certain way. It can happen in the digital world, too.Don Norman, a contemporary design visionary, in his book Emotional Design: Why We Love (Or Hate) Everyday Things breaks down people's emotional responses into three stages",
      title: "25 Sep 2020",
      issue: "Issue No. 46",
      Link: ResourceRoute.NEWSISSUE46,
      category: "Design",
      postedAt: "25 Sep 2020"
    },
    {
      content1: "How Data Centralization Ends by 2030 | Coindesk",
      content2:
        "The next 10 years will witness the systematic manipulation of human life at a scale unrivaled in history. For all the recent controversies over privacy and surveillance, the real threat is ahead of us. Unless new approaches to online identity and data management take hold, both governments and private actors will move inexorably from knowing you to shaping you. Blockchain-enabled decentralization will develop as the only viable response to the iron logic of data centralization.",
      title: "18 Sep 2020",
      issue: "Issue No. 45",
      Link: ResourceRoute.NEWSISSUE45,
      category: "Web3",
      postedAt: "18 Sep 2020"
    },
    {
      content1:
        "Blockchain Players Beat Gold and Bitcoin in the Pandemic | Bloomberg.com",
      content2:
        "Gold and its digital counterpart, Bitcoin, have had a pretty good run among investors seeking a haven or those willing to take a punt on cryptocurrency. But they’ve been pipped in the past year by another investment thesis that’s perhaps a little more tangible, highlighting the old adage that it’s smartest to be selling shovels in a gold rush.",
      title: "11 Sep 2020",
      issue: "Issue No. 44",
      Link: ResourceRoute.NEWSISSUE44,
      category: "Web3",
      postedAt: "11 Sep 2020"
    },
    {
      content1:
        "STAT Health Tech: Google’s government contract to use AI in cancer diagnosis & new data on virtual diabetes coaching | STAT Health Tech",
      content2:
        "The U.S. Department of Defense has struck a deal with Google Cloud to use its AI tools to improve cancer diagnosis. The project will use AI to analyze pathology slides on augmented reality microscopes, and test its ability to make a difference in a clinical setting. It will be rolled out at treatment facilities operated by the Defense Health Agency and the Department of Veterans Affairs, with the hope of reducing the 12 million misdiagnoses every year.",
      title: "04 Sep 2020",
      issue: "Issue No. 43",
      Link: ResourceRoute.NEWSISSUE43,
      category: "Healthtech",
      postedAt: "04 Sep 2020",
    },
    {
      content1:
        "Android Jetpack Compose to make app development easier, now in alpha | Slashgear",
      content2:
        "We live in an app-centric world, not just on smartphones but also on desktops and even on TVs. We have, in other words, become dependent on these apps, as well as the services that power then remotely, and, in the final analysis, on the developers that create these experiences. Although statistically the mobile platform, Android apps haven’t exactly been the most enjoyable to develop, something that Google’s new Jetpack Compose toolkit is aiming to fix once and for all.",
      title: "28 Aug 2020",
      issue: "Issue No. 42",
      Link: ResourceRoute.NEWSISSUE42,
      category: "Design",
      postedAt: "28 Aug 2020"
    },
    {
      content1:
        "Blockchain-Based Crypto Payments Need To Focus On User Experience To Go Mainstream | Forbes",
      content2:
        "Blockchain and blockchain-enabled payments are the future of transactions, but there are several issues that need to be resolved first. Ever since the idea of blockchain and cryptographically secured currency entered the marketplace, the benefits of widespread adoption have been discussed and analyzed at great length. Despite this engagement, however, the utilization of cryptocurrency as a legitimate fiat alternative, i.e., using crypto as money, remains something of an oddity, with only a relatively small percentage of cryptocurrencies being used as a medium of exchange.",
      title: "21 Aug 2020",
      issue: "Issue No. 41",
      Link: ResourceRoute.NEWSISSUE41,
      category: "Fintech",
      postedAt: "21 Aug 2020"
    },
    {
      content1:
        "The story of how web browsers changed us forever | The Next Web",
      content2:
        "One day, Internet Explorer was nearly the only game in town, powering 96% of website visits at its peak in 2002. Then, quickly it turned into the app you only used to download Firefox or Chrome, or so the joke went. And then Internet Explorer died and turned into Chrome. From creating an industry to lawsuits over monopolizing said industry in under a decade may be a record. But that’s the web. Everything moves faster online, from the dot-com boom and bust to today’s smartphone-powered world.",
      title: "12 Aug 2020",
      issue: "Issue No. 40",
      Link: ResourceRoute.NEWSISSUE40,
      category: "Web3",
      postedAt: "12 Aug 2020"
    },
    {
      content1:
        "Outdated Website Design Trends/Practices: What 2020 Taught Us | Digital Information World",
      content2:
        "We are only halfway through 2020 and there are several web design trends and practices that were popular just a few months ago that now appear outdated. This is due in part to the fact that every few months our mobile devices and mobile technology improves. Something that was considered a good interface just a few months ago now seems outdated. As technology keeps moving forward, website design needs to continue to improve. Web designers are constantly looking at the websites they create from a UI and UX point of view. They are finding that a lot of the design aspects that were popular just a year ago or even a few months ago are outdated and useless. Here is what 2020 has taught us so far about web design practices and trends.",
      title: "7 Aug 2020",
      issue: "Issue No. 39",
      Link: ResourceRoute.NEWSISSUE39,
      category: "Design",
      postedAt: "7 Aug 2020"
    },
    {
      content1:
        "Cryptodollars Are A Good Start, But The U.S. Needs A Blockchain Policy | Forbes",
      content2:
        "Blockchain technology, and the crypto asset ecosystem, might seem like an abstract idea or concept to business people, individuals, or entrepreneurs who are not immersed in the space on an everyday basis. That said, it is difficult to overstate just how important blockchain technology, and its related applications, will be for the wider economy now and going forward.",
      title: "31 Jul 2020",
      issue: "Issue No. 38",
      Link: ResourceRoute.NEWSISSUE38,
      category: "Fintech",
      postedAt: "31 Jul 2020"
    },
    {
      content1: "Microsoft teases its future Office UI | The Verge",
      content2:
        "Microsoft is teasing the future of its Office UI and design today, and it involves some big changes to the traditional ribbon interface. The software giant has been gradually improving Office with its Fluent Design system over the past couple of years — adding new icons, a dark mode, and overhauling the ribbon toolbar by making it smaller and easier to use. The next stage of Microsoft’s Office design sees the company focus even further on simplicity.",
      title: "24 Jul 2020",
      issue: "Issue No. 37",
      Link: ResourceRoute.NEWSISSUE37,
      category: "Design",
      postedAt: "24 Jul 2020"
    },
    {
      content1:
        "Blockchain technology will remove barriers to instant international settlement | Banking Drive",
      content2:
        "Barriers are springing up everywhere for remittance companies and banks. Overcoming obstacles to cross-border payments is especially daunting right now, with slow settlement times, high fees and fresh competition bringing new challenges. Participants on a next-generation network can transact and have the transaction visible and settled between parties in minutes or even seconds instead of days, writes Haohan Xu, the CEO of Apifiny.",
      title: "17 Jul 2020",
      issue: "Issue No. 36",
      Link: ResourceRoute.NEWSISSUE36,
      category: "Fintech",
      postedAt: "17 Jul 2020"
    },
    {
      content1: "The  hidden trackers in your phone, explained | Vox",
      content2:
        "You probably know that the apps on your phone report back tons of data about you to advertisers. Even developers may not know (or care) when and how their users’ privacy is being invaded.Companies want to put their SDKs in as many apps as possible in order to collect as much information from as many people as possible.This article takes a deep dive into why apps include  so many software development kits, or SDKS, that hoover up your personal info.",
      title: "10 Jul 2020",
      issue: "Issue No. 35",
      Link: ResourceRoute.NEWSISSUE35,
      category: "Web3",
      postedAt: "10 Jul 2020"
    },
    {
      content1:
        "Why you shouldn’t overlook user testing methods in UX design | The Next Web",
      content2:
        "The fundamental purpose of user testing is to better understand and empathize with the core users of a digital product. Unfortunately, user testing is often an afterthought. From card sorting to usability studies, user testing methods utilized in UX design are developed to include the user in the decision-making process. However, many projects are  with only stakeholder feedback of a prototype.",
      title: "03 Jul 2020",
      issue: "Issue No. 34",
      Link: ResourceRoute.NEWSISSUE34,
      category: "Design",
      postedAt: "03 Jul 2020"
    },
    {
      content1:
        "How ‘Sustainable’ Web Design Can Help Fight Climate Change | Wired",
      content2:
        "Danny Van Kooten, a Dutch programmer decided to reduce his carbon output by no longer eating beef or flying. Then, five months ago, he made a change that had an even bigger impact—and it took only a few keystrokes. Read on the link to know Van Kooten's aha moment that is popularly being shared by web designers around the planet. They call it “sustainable” software design, and it's propelled by technologists measuring the energy budget of nearly every swipe and click in our information ecosystem.",
      title: "26 Jun 2020",
      issue: "Issue No. 33",
      Link: ResourceRoute.NEWSISSUE33,
      category: "Design",
      postedAt: "26 Jun 2020"
    },
    {
      content1:
        "Unforgettable: How Blockchain Will Fundamentally Change the Human Experience | Coindesk",
      content2:
        "From the invention of the wheel to the printing press, new technology has changed the human experience. Our comprehension of the world is no longer limited to a village. Our collective knowledge grows by inconceivable exabytes of data every day. And our memories, our very recollections of the events that shape our lives, are changing too.In fact, according to neurobiologist Dr. James L. McGaugh, a researcher specializing in learning and memory, technological advancements right up to the advent of the internet have made it less necessary for humans to construct lasting records of our own memories.",
      title: "19 Jun 2020",
      issue: "Issue No. 32",
      Link: ResourceRoute.NEWSISSUE32,
      category: "Web3",
      postedAt: "19 Jun 2020"
    },
    {
      content1:
        "Microsoft releases a beta version of its decentralized identity tool on the Bitcoin blockchain | The Block Crypto",
      content2:
        "Microsoft's decentralized identity tool, called ION, is moving to the Bitcoin mainnet for a public beta test, according to an announcement from Microsoft. Decentralized identity tools enable greater ownership over one's digital identity credentials by creating unique identifiers that are cryptographically verifiable.",
      title: "12 Jun 2020",
      issue: "Issue No. 31",
      Link: ResourceRoute.NEWSISSUE31,
      category: "Web3",
      postedAt: "12 Jun 2020"
    },
    {
      content1:
        "How To Test A Design Concept For Effectiveness | Smashing Magazine",
      content2:
        "Getting a client or stakeholder to approve a design concept can be challenging. However, testing can make it easier, as well as ensuring you have the right solution. Most of us are reasonably comfortable with the idea of carrying out usability testing on a website or prototype. We don’t always get the opportunity, but most people accept that it is a good idea. And,when it comes to a design concept, opinion is more divided. Some designers feel it undermines their role, a view that seems to be somewhat backed up by the famous “Forty Shades of Blue” episode, where Google tested which one of forty shades of blue to use for link color.",
      title: "05 Jun 2020",
      issue: "Issue No. 30",
      Link: ResourceRoute.NEWSISSUE30,
      category: "Design",
      postedAt: "05 Jun 2020"
    },
    {
      content1:
        "Application threats and security trends you need to know about | Help Net Security",
      content2:
        "Applications are a gateway to valuable data, so it’s no wonder they are one of attackers’ preferred targets. And since modern applications aren’t a monolithic whole but consist of many separate components “glued together” over networks, attackers have at their disposal many “doors” through which they can attempt access to the data. Some of these doors are more popular than others, and it is best to be aware of these easy targets.",
      title: "29 May 2020",
      issue: "Issue No. 29",
      Link: ResourceRoute.NEWSISSUE29,
      category: "Web3",
      postedAt: "29 May 2020"
    },
    {
      content1:
        "Reddit is testing Community Points, a blockchain based system to encourage on platform engagement | Digital Information World",
      content2:
        "Social media platforms have not yet been able to integrate blockchain-based technology in their operations in any practical way. Facebook is working on its Libra Project, while Twitter CEO Jack Dorsey is seen to be a significant advocate for cryptocurrencies. However, it seems that Reddit has found a way to use this technology. This week, the platform announced the launch of the new ‘Community Points,’ a blockchain-based system, rolled out as a test and will enable Reddit moderators to assign scores to Reddit community members. The points will be allocated to members based on their engagement within their respective subreddits.",
      title: "22 May 2020",
      issue: "Issue No. 28",
      Link: ResourceRoute.NEWSISSUE28,
      category: "Web3",
      postedAt: "22 May 2020"
    },
    {
      content1: "A new normal for design | Abstract.com",
      content2:
        "As remote design sprints, AMAs and webinars have become the norm, we’re getting more under-the-hood peeks at folks’ remote work setups, virtual brainstorms, design systems, and more. While not everyone has the time or energy to increase their outputs, there’s a realness to the conversations happening around systems, processes, career advancement and development, and why our default understanding of collaboration is critically flawed in a remote-first world.",
      title: "15 May 2020",
      issue: "Issue No. 27",
      Link: ResourceRoute.NEWSISSUE27,
      category: "Design",
      postedAt: "15 May 2020"
    },
    {
      content1:
        "Bank as a service - the future of banks and fintech startups? | The Payers",
      content2:
        "Over half of Americans are refusing to use some products or services due to personal privacy concerns, according to survey results published on April 14, 2020 by Pew Research Center, a nonpartisan American think tank based in Washington. In light of this research you would think that we would see Facebook and Google reporting large losses in their subscriber base, but we don’t. Why? It's commonly referred to as the ‘privacy paradox’, as John Naughton wrote in The Guardian, ‘a dark shadow looms over our networked world. It’s called the privacy paradox’.",
      title: "08 May 2020",
      issue: "Issue No. 26",
      Link: ResourceRoute.NEWSISSUE26,
      category: "Fintech",
      postedAt: "08 May 2020"
    },
    {
      content1:
        "Solving the privacy paradox with blockchain tech | The Payers",
      content2:
        "Over half of Americans are refusing to use some products or services due to personal privacy concerns, according to survey results published on April 14, 2020 by Pew Research Center, a nonpartisan American think tank based in Washington. In light of this research you would think that we would see Facebook and Google reporting large losses in their subscriber base, but we don’t. Why? It's commonly referred to as the ‘privacy paradox’, as John Naughton wrote in The Guardian, ‘a dark shadow looms over our networked world. It’s called the privacy paradox’.",
      title: "01 May 2020",
      issue: "Issue No. 25",
      Link: ResourceRoute.NEWSISSUE25,
      category: "Web3",
      postedAt: "01 May 2020"
    },
    {
      content1:
        "The coronavirus crisis is fintech’s biggest test yet—and greatest opportunity to go mainstream | Fortune",
      content2:
        "As the novel coronavirus presents the world with its biggest economic challenge in more than a decade, fintech is having a moment of truth. Companies like SoFi, Robinhood, Chime, and others were built on promises of providing consumers and businesses with easier access to money in all its forms—investments, credit, person-to-person payments—via the Internet, and often without dealing with a brick-and-mortar bank. With the global economy largely on pause, millions of people abruptly out of work, and thousands of bank branches shuttered, the time for fintech to deliver on those promises has arrived.",
      title: "24 Apr 2020",
      issue: "Issue No. 24",
      Link: ResourceRoute.NEWSISSUE24,
      category: "Fintech",
      postedAt: "24 Apr 2020"
    },
    {
      content1:
        "Here’s when you can trust Zoom, and when you shouldn’t | Fast Company ",
      content2:
        "In the midst of global pandemic, the nine-year-old videoconferencing service Zoom skyrocketed into general awareness. “To zoom” is now a verb. But among its rise in stock and a twentyfold increase in usage between December 2019 and March 2020, with 200 million daily users now conducting meetings worldwide, a lot of other verbs have been used with less affection about the company’s software quality, installation methods, security, ties to China, and privacy policies and actions. At the same time, technologists have hailed the company’s ability to deliver reliable service despite the blistering increase in usage—a growth rate no Internet firm has ever had to contend with under normal circumstances—with the lion’s share producing no new revenue.",
      title: "17 Apr 2020",
      issue: "Issue No. 23",
      Link: ResourceRoute.NEWSISSUE23,
      category: "Web3",
      postedAt: "17 Apr 2020"
    },
    {
      content1:
        "Happy developers write secure code, report claims | Computer Weekly",
      content2:
        "Developers who are happy and satisfied in their work are 3.6 times less likely to neglect security considerations in their code, 2.3 times more likely to have automated security tools in place, and 1.3 times more likely to follow open source security best practice, according to new research conducted on behalf of DevOps services supplier Sonatype.",
      title: "10 Apr 2020",
      issue: "Issue No. 22",
      Link: ResourceRoute.NEWSISSUE22,
      category: "Web3",
      postedAt: "10 Apr 2020"
    },
    {
      content1:
        "16 of Google's best dev and design tools in 2020 | Creative Bloq",
      content2:
        "Working on the web usually means you will be working with Google in some shape or form. And seeing as Google Chrome is streets ahead of the competition, designers and developers need to think about how their project will work with the browser. How will it look? What technologies does it support, how secure is it and how will it perform?",
      title: "03 Apr 2020",
      issue: "Issue No. 21",
      Link: ResourceRoute.NEWSISSUE21,
      category: "Design",
      postedAt: "03 Apr 2020"
    },
    {
      content1:
        "This Unstable Moment Is a Chance for Crypto to Go Mainstream | Coindesk.com",
      content2:
        "With the impacts of COVID-19 still ravaging financial markets and trillions being deployed by governments and central banks to stem the economic carnage, one cannot help but wonder if this is cryptocurrency’s breakout moment. This unstable environment seems ripe for cryptocurrency to come out of the shadows and become a viable asset class and legitimate alternative to our fiat-based economies. With the proposals for a digital dollar, some legislators in the U.S. Congress seem to think so.",
      title: "27 Mar 2020",
      issue: "Issue No. 20",
      Link: ResourceRoute.NEWSISSUE20,
      category: "Fintech",
      postedAt: "27 Mar 2020"
    },
    {
      content1:
        "Delivery Technology Is Keeping Chinese Cities Afloat Through Coronavirus | HBR.org",
      content2:
        "Amazon and airport vendor OTG announced on Wednesday that Amazon’s technology would be installed in several CIBO Express stores at Newark Liberty and LaGuardia airports. This news comes two days after Amazon announced that it had begun selling its so-called “just walk out” technology — which combines ceiling cameras, computer vision, and weight sensors — to other retailers, in a move that could someday impact the millions of Americans who hold cashier jobs today.",
      title: "20 Mar 2020",
      issue: "Issue No. 19",
      Link: ResourceRoute.NEWSISSUE19,
      category: "Web3",
      postedAt: "20 Mar 2020"
    },
    {
      content1:
        "Why computers won’t be reading your mind any time soon | Wired.com",
      content2:
        "Headlines about mind-reading computers are overblown, but by working out what we're trying to say they could give a voice to people who can't speak",
      title: "13 Mar 2020",
      issue: "Issue No. 18",
      Link: ResourceRoute.NEWSISSUE18,
      category: "Healthtech",
      postedAt: "13 Mar 2020",
    },
    {
      content1:
        "What Does A Foldable Web Actually Mean? | Smashing Magazine",
      content2:
        "Foldable devices have brought with them talk of a ‘foldable web,’ and the idea that long-standing web conventions may be on the verge of a serious shakeup. Is it all hype, or is it time to get flexible?",
      title: "06 Mar 2020",
      issue: "Issue No. 17",
      Link: ResourceRoute.NEWSISSUE17,
      category: "Web3",
      postedAt: "06 Mar 2020"
    },
    {
      content1:
        "Seven UX/UI Design Trends For Mobile Apps To Look Out For In 2020 | Forbes",
      content2:
        "With or without 5G, we are going to see apps that are lighter by design and more flexible. Elements that are more data-intensive, such as immersive imagery, can be stored on a server, while the lighter elements can be stored locally on a phone or other device. Going into 2020, the changes with app design will be both philosophical and technological. You'll need to plan accordingly so your business can take advantage of the changing tide.",
      title: "28 Feb 2020",
      issue: "Issue No. 16",
      Link: ResourceRoute.NEWSISSUE16,
      category: "Design",
      postedAt: "28 Feb 2020"
    },
    {
      content1:
        "Is Neumorphism really 2020's hottest design trend? | Creative Bloq",
      content2:
        "Everybody's talking about Neumorphism, a hot new design trend that's appeared out of nowhere over the past couple of months – but is it really all that? Designers love a good trend, and there's much about Neumorphism that suggests this could be a big one (people are already talking about it as a potential look for iOS 14) rather than an exercise in experimental design.",
      title: "21 Feb 2020",
      issue: "Issue No. 15",
      Link: ResourceRoute.NEWSISSUE15,
      category: "Design",
      postedAt: "21 Feb 2020"
    },
    {
      content1:
        "Can Blockchain Survive Mass Adoption? Future Perils Disclosed | Coin Telegraph",
      content2:
        "Prediction can be a bit of a gamble. And when cryptocurrency and blockchain are involved, it wouldn’t be surprising to see people shying away from scrying experiments of any sort. Blockchain gets a bad rep because of its relation to Bitcoin (BTC), the cryptocurrency that many love to hate due to its apparently volatile value.",
      title: "14 Feb 2020",
      issue: "Issue No. 14",
      Link: ResourceRoute.NEWSISSUE14,
      category: "Web3",
      postedAt: "14 Feb 2020"
    },
    {
      content1:
        "The Scroll subscription service is an ingenious web technology hack | The Next Web",
      content2:
        "Scroll, a new $5 per month subscription service that gives you a bunch of websites without ads. I kept on experiencing successive waves of small revelations when I thought about it. I’ll disclose now that Vox Media (and therefore The Verge) are partners, but I had no idea this service existed until it was announced yesterday.",
      title: "07 Feb 2020",
      issue: "Issue No. 13",
      Link: ResourceRoute.NEWSISSUE13,
      category: "Fintech",
      postedAt: "07 Feb 2020"
    },
    {
      content1:
        "A brief history of UX design and its evolution | The Next Web",
      content2:
        "When you think about user experience design it’s a term we instantly associate with apps and websites. And especially when considering a typical job description of a UX designer, it can trick you into thinking that it’s a modern concept. The term was first coined in 1993 by the cognitive psychologist and designer Don Norman when he worked at Apple Computer — but the UX field is older than the term.",
      title: "31 Jan 2020",
      issue: "Issue No. 12",
      Link: ResourceRoute.NEWSISSUE12,
      category: "Design",
      postedAt: "31 Jan 2020"
    },
    {
      content1:
        "2030: from technology optimism to technology realism | WEForum.org",
      content2:
        "Today’s technological revolution is a time of enormous promise, but also new challenges. As we enter the 2020s it is clear that we are far from unlocking the potential of technology for our toughest challenges. We are entering a new era where powerful Fourth Industrial Revolution technologies like artificial intelligence (AI) are being infused at exponential speed into the world around us. As organizations and countries race to harness these new technologies to spur growth and competitiveness, we stand at a critical juncture to put these technologies to work in a responsible way for people and the planet.",
      title: "24 Jan 2020",
      issue: "Issue No. 11",
      Link: ResourceRoute.NEWSISSUE11,
      category: "Web3",
      postedAt: "24 Jan 2020"
    },
    {
      content1: "The 3 biggest design trends at CES 2020 | Fast Company ",
      content2:
        "Every January, the annual Consumer Electronics Show snaps the tech press out of its holiday stupor to fly to Las Vegas and walk through aisle after aisle of new televisions, robots, and plain old weird gadgets that are all billed as the next big thing. Here’s what happened at the biggest product show of the year that you actually need to know about.",
      title: "17 Jan 2020",
      issue: "Issue No. 10",
      Link: ResourceRoute.NEWSISSUE10,
      category: "Design",
      postedAt: "17 Jan 2020"
    },
    {
      content1:
        "The next decade of design will be about fixing the last century of excess | Fast Company",
      content2:
        "The 1980s have gone down in history as the decade of excess (as if the gas-guzzling V8 cars of the 1970s or the rise of fast fashion in the 1990s never happened). But the 2010s have put it all to shame. This was the decade where convenience crushed everything else. Prime two-day shipping became not a luxury, but a way of life—that would give way to one-day shipping, then same-day shipping.",
      title: "10 Jan 2020",
      issue: "Issue No. 9",
      Link: ResourceRoute.NEWSISSUE09,
      category: "Design",
      postedAt: "10 Jan 2020"
    },
    {
      content1:
        "Will 2020 be the year blockchain overcomes its hype? | World Economic Forum ",
      content2:
        "Another year has rolled on by, and while many things in the blockchain space have changed, a lot remains the same. 2019 saw a continued cooling of indiscriminate funding and a renewed focus on quality over hype. At the World Economic Forum, while they ensure that there will be greater blockchain adoption, which may deem inevitable, occurring in ways that support inclusion and avoid replicating the consolidation of power that currently exists, particularly in the financial system.",
      title: "03 Jan 2020",
      issue: "Issue No. 8",
      Link: ResourceRoute.NEWSISSUE08,
      category: "Web3",
    },
    {
      content1:
        "Apple Has Secret Team Working on Satellites to Beam Data to Devices | Bloomberg ",
      content2:
        "Apple Inc. has a secret team working on satellite technology that the iPhone maker could use to beam internet services directly to devices, bypassing wireless networks, according to people familiar with the work. The Cupertino, California-based iPhone maker has about a dozen engineers from the aerospace, satellite and antenna design industries working on the project with the goal of deploying their results within five years, said the people, who asked not to be identified discussing internal company efforts.",
      title: "27 Dec 2019",
      issue: "Issue No. 7",
      Link: ResourceRoute.NEWSISSUE07,
      category: "Web3",
    },
    {
      content1:
        "What Happens After Prisoners Learn to Code? | The Atlantic ",
      content2:
        "A very 2019 fairytale - Slack, one of Silicon Valley’s more diverse companies, has hired three formerly incarcerated coders.",
      title: "20 Dec 2019",
      issue: "Issue No. 6",
      Link: ResourceRoute.NEWSISSUE06,
      category: "Web3",
    },
    {
      content1:
        "Jack Dorsey Wants to Help You Create Your Own Twitter | WIRED ",
      content2:
        "Twitter has formed a new engineering team dedicated to building a new decentralized social media system. The CEO tweeted that he's hiring a team to develop open source standards for decentralized social networks. His hope: It will spawn better ways to combat hate and harassment.",
      title: "13 Dec 2019",
      issue: "Issue No. 5",
      Link: ResourceRoute.NEWSISSUE05,
      category: "Web3",
    },
    {
      content1:
        "The Environmental Benefits of Digital Design | MIT Sloan Review ",
      content2:
        "Digital advances in product design are creating new opportunities to boost both environmental sustainability and profit margins. By making product development more design- and information-intensive, companies are creating a new generation of digitally enhanced offerings that are reducing material use, lowering energy demands, and increasing revenues.",
      title: "06 Dec 2019",
      issue: "Issue No. 4",
      Link: ResourceRoute.NEWSISSUE04,
      category: "Design"
    },
    {
      content1: "AI for software development is already here | AI News ",
      content2:
        "Another week, another round of Crypto Tidbits. The past seven days were rather painful for Bitcoin (BTC), with the leading cryptocurrency tumbling by some 4% according to Coin360. Altcoins have posted similar losses. As of the time of writing this, BTC is trading for $8,500, seemingly poised to break down further as bulls fail to step in.",
      title: "29 Nov 2019",
      issue: "Issue No. 3",
      Link: ResourceRoute.NEWSISSUE03,
      category: "Web3",
      postedAt: "This month",
    },
    {
      content1:
        "Crypto Tidbits: RBC May Embrace Bitcoin, China Bashes Cryptocurrency, Ethereum DeFi Booming | News BTC ",
      content2:
        "Another week, another round of Crypto Tidbits. The past seven days were rather painful for Bitcoin (BTC), with the leading cryptocurrency tumbling by some 4% according to Coin360. Altcoins have posted similar losses. As of the time of writing this, BTC is trading for $8,500, seemingly poised to break down further as bulls fail to step in.",
      title: "22 Nov 2019",
      issue: "Issue No. 2",
      Link: ResourceRoute.NEWSISSUE02,
      category: "Web3",
    },
    {
      content1:
        "Q&A: Dark Design — the good, the bad and the very attractive | Digital Journal ",
      content2:
        "Dark pattern design is becoming more common, influencing the user experience promoted by many companies. Bhav Chohan explains the good and bad points about this approach, plus some notorious examples of UX. “Dark pattern design” is the practice of using software design to influence the behavior of users. The practice is becoming so large that the U.S. Senate is planning to pass a bill to control its use, which can lead to addiction in some extreme cases.",
      title: "15 Nov 2019",
      issue: "Issue No. 1",
      Link: ResourceRoute.NEWSISSUE01,
      category: "Design",
    },
  ]
