import React from 'react';
import "../../resources.css";
import {ResourceRoute} from '../../resource-routing';
import {Link} from "react-router-dom";
import NewsHeadings from "./common-action/news-heading";
import NewsSubscription from "./common-action/subscripbe-section";

class NewsIssue29 extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            pageEnabled: false,
        }

    }

    render() {
        return (
          <div className="newdesigned_issue_details">
            <NewsHeadings
              buttondDetail="Web3 & Blockchain"
              categoryType="web3"
              description="Application threats and security trends you need to know about | Help Net Security"
            />
            <div className="newsissuedetailed">
              <div className="detailed-common">
                {/* <div className="back-to-common">
                    <span className="back-to-icon-hold">
                    <img src="/assets/img/resource/back-to-new.svg"  alt="bact to new"></img>
                    </span>
                    <Link  to={ResourceRoute.NEWSLETTER}><span className="back-to-link">Newsletter</span></Link>
                </div> */}

                <div className="news-letter whats-on-hold">
                  <div className="issue-main-head">
                    <h4>What’s on?</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.forbes.com/sites/coryjohnson/2020/05/27/google-goes-blockchain/#5611ecef6593"
                      >
                        <h4>
                          Google Goes Blockchain? New Deal Opens A Door To
                          Crypto | Forbes
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Theta Labs, a venture-backed blockchain company, has
                        struck up a new partnership with Google Cloud, the
                        rapidly-growing Alphabet subsidiary. Google Cloud will
                        offer a new service allowing users to deploy and run
                        nodes of Theta’s blockchain network. Perhaps more
                        importantly, Google Cloud itself will operate a
                        validator for Theta’s network — servicing all of Europe
                      </p>
                    </div>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://cointelegraph.com/news/russias-biggest-bank-is-buying-5000-blockchain-atms-that-can-mine-crypto"
                      >
                        <h4>
                          Russia’s Biggest Bank Is Buying 5,000 Blockchain ATMs
                          That Can Mine Crypto | Coin Telegraph
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Russia’s largest bank, Sberbank, has called for tenders
                        to provide 4,917 ATMs with a built-in graphic card
                        capable of supporting “blockchain operations”. Sberbank
                        is Russia’s oldest and largest bank and holds almost 44%
                        of all personal deposits in the country. It operates
                        14,200 branches and 77,000 ATMs across the country. The
                        state is its largest shareholder and its Chairman and
                        CEO, Herman Gref, was Russia’s former minister of
                        Economics and Trade. He is known to be a big proponent
                        of new technology, including blockchain
                      </p>
                    </div>
                  </div>
                </div>
                <div className="news-letter top-picks-hold">
                  <div className="issue-main-head">
                    <h4>Top Picks</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.helpnetsecurity.com/2020/05/27/application-threats/"
                      >
                        <h4>
                          Application threats and security trends you need to
                          know about | Help Net Security
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Applications are a gateway to valuable data, so it’s no
                        wonder they are one of attackers’ preferred targets. And
                        since modern applications aren’t a monolithic whole but
                        consist of many separate components “glued together”
                        over networks, attackers have at their disposal many
                        “doors” through which they can attempt access to the
                        data. Some of these doors are more popular than others,
                        and it is best to be aware of these easy targets.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="news-letter other-reads-hold">
                  <div className="issue-main-head">
                    <h4>Other Reads</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.thehindu.com/opinion/editorial/a-brewing-storm-the-hindu-editorial-on-trump-vs-twitter/article31697846.ece"
                      >
                        <h4>
                          A brewing storm: On Trump vs Twitter | The Hindu
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        As political ironies go, U.S. President Donald Trump’s
                        tirade against social medial platforms is a class apart.
                        After Twitter flagged two of his posts as factually
                        inaccurate, the President threatened to “strongly
                        regulate” or “close down” all social media platforms.
                        Like most of his statements, this one too appeared on
                        Twitter where he has 80.4 million followers. When Mr.
                        Trump entered the race for the Republican presidential
                        nomination in 2015, critics scorned him as a mere,
                        inconsequential Twitter handle, for his acerbic
                        posturing on the platform.{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <NewsSubscription categoryType="Web3" />
          </div>
        );
    }

}

export default NewsIssue29;
