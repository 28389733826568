export const BASE_URL =
  "https://us-central1-ionixxtech-website-v1.cloudfunctions.net/sendMail/";
export const SUBSCRIBE_URL =
  "https://us-central1-ionixxtech-website-v1.cloudfunctions.net/sendMail/subscribe-for-newsletter";
export const UPLOAD_URL = "https://www.ionixxtech.com/";
export const CONTACT_API = {
  SEND_MAIL: "connect-with-us",
  SEND_NEWS_EMAIL: "subscribe-for-newsletter",
  UPLOAD: "upload-file",
  CONNECT: "connect-with-ionixx",
};
export const BLOG_URL = "https://blog.ionixxtech.com/wp-json/wp/v2/posts?per_page=5";
