import React, { useEffect, useState } from "react";
import "./defi-landing.css";
import * as contactAction from "../../action/contactAction";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { withRouter } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import { ROUTES } from "../../routes";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const DefiService = (props) => {
  const [name, SetName] = useState("");
  const [mailId, SetMailId] = useState("");
  const [mailErr, SetMailErr] = useState(false);
  const [company, SetCompany] = useState("");
  const [message, SetMessage] = useState("");
  const [showModalThanks, SetShowModalThanks] = useState(false);
  const [showCalendar, SetShowCalendar] = useState(false);
  const [showContact, SetShowContact] = useState(false);

  const [showThanks, SetShowThanks] = useState(false);
  // const [nameErr, SetNameErr] = useState(false);
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const HotjarTracking = () => {
  useEffect(() => {
    (function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid:3369494,hjsv:6};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
  }, []);

  return null;
};
  const formData = async () => {
    const payLoad = {
      name: name,
      emailID: mailId,
      companyName: company,
      message:message,
      page: "Defi Services",
      pageurl: "https://www.ionixxtech.com/defi-services",
    };
    // console.log(payLoad,"payload")
    try {
      const response = await props.sendEmail(payLoad);
      // console.log(response, "response")
      if (response.status === 200) {
        // alert("Success");
        SetName("");
        SetMailId("");
        SetCompany("");
        SetMessage("");

        // SetShowThanks(true);
        props.history.push(ROUTES.REQUESTDEMOCALLDEFI);
      }
    } catch (err) {
      console.log(err);
    }
  };
  const modalFormData = async () => {
    const payLoad = {
      name: name,
      emailID: mailId,
      companyName: company,
      message:message,
      page: "Defi Services",
      pageurl: "https://www.ionixxtech.com/defi-services",
    };
    // console.log(payLoad,"payload")
    try {
      const response = await props.sendEmail(payLoad);
      // console.log(response, "response")
      if (response.status === 200) {
        // alert("Success");
        // SetShowModalThanks(true);
        props.history.push(ROUTES.REQUESTDEMOCALLDEFI);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const scheduleCall = () => {
    SetShowCalendar(true);
  };
const scheduleContact = () => {
    SetShowContact(true);
  };
  const validateEmail = () => {
    let regex = new RegExp("^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$");
    if (!regex.test(mailId)) {
      SetMailErr(true);
    } else {
      SetMailErr(false);
    }
  };
  var settings = {
    dots: false,
    arrows:true,
    infinite: true,
    autoplay: true,
    speed: 3000,
    speed:2000,
    autoplaySpeed: 5000,
    pauseOnHover:true,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
        {
          breakpoint: 1098,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: false,
            dots: true
          }
        },
        {
          breakpoint: 800,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            initialSlide: 1,
            dots: false
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: false
          }
        }
      ]
  };

  var settingsNew = {
    dots: false,
    arrows:true,
    infinite: true,
    autoplay: true,
    speed: 3000,
    speed:2000,
    autoplaySpeed: 5000,
    pauseOnHover:true,
    slidesToShow: 3,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
        {
          breakpoint: 1098,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: false,
            dots: true
          }
        },
        {
          breakpoint: 800,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            initialSlide: 1,
            dots: false
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            dots: false
          }
        }
      ]
  };

  return (
    <div className="defi-services">
      <HotjarTracking />
      <section className="header">
        <div className="logo">
          <a href="https://www.ionixxtech.com/">
             <img src="/assets/img/defi-services/white-logo.svg" alt="logo"/>
          </a>
        </div>
      </section>
      <section className="settlement-solution" id="settlementSolution">
        <div className="container">
          <div className="settlement-solution-inner">
            <div className="left-content">
              <h1>
              Build Next Generation<br/> of Decentralized<br/> Finance Applications
              </h1>
              <em>Experienced DeFi Developers bringing<br/> your vision to life</em>
             
            </div>
            <div className="right-content">
              {/* <span>Book a demo call</span> */}
             
              {/* <iframe
                width="100%"
                height="500px"
                src="https://calendly.com/shaan-ionixx/15min?hide_event_type_details=1&hide_gdpr_banner=1"
              ></iframe> */}
              {!showCalendar ? (
                <div className="get-in-touch get-in-touch-form">
                <iframe
                  width="100%"
                  height="450px"
                  src="https://calendly.com/shaan-ionixx/15min?hide_event_type_details=1&hide_gdpr_banner=1"
                ></iframe>
                <div className="defi-button button-field"> 
                  <em>or</em>   
                  <button onClick={scheduleCall}>Request Demo Call Now</button>
                </div>
                
                </div>
              ) : (
                 
                <>
                
              <div className="get-in-touch">
                <strong>Get in touch</strong>
                <div className="input-field">
                   <label>Name</label>
                   <input type="text" value={name}
                    onChange={(e) => SetName(e.target.value)}/>
                </div>
                <div className="input-field">
                   <label>Email</label>
                   <input type="text" value={mailId}
                    onChange={(e) => SetMailId(e.target.value)}
                    onBlur={validateEmail}
                    className={mailErr ? "field-error" : ""}/>
                </div>
                <div className="input-field">
                   <label>Company</label>
                   <input type="text" value={company}
                    onChange={(e) => SetCompany(e.target.value)}/>
                </div>
                <div className="input-field ">
                   <label>Query</label>
                   <textarea type="text" value={message}
                    onChange={(e) => SetMessage(e.target.value)}/>
                </div>
                <div className="button-field">
                  <button 
                  disabled={ name === "" || mailId === "" || message === "" || mailErr === true}
                            onClick={formData}>Request Demo Call</button>
                  {/* <em>or</em>
                  <button onClick={scheduleCall}>Schedule Demo Call Now</button> */}
                </div>
              </div>
              {/* : (
                    <div className="thank-you-screen">
                      <i>
                        <img src="/assets/img/modern-post-trade/approval.svg" />
                      </i>
                      <b>Our team will contact you shortly</b>
                      <div className="button-field">
                        <button onClick={scheduleCall}>
                          Schedule Demo Call Now
                        </button>
                      </div>
                    </div>
                  ) */}

                  </>)}
                
            </div>
          </div>
        </div>
      </section>
      <section className="partners-say">
           <h2>What our partners say</h2>
           <div className="container">
           <div className="partner-say-list">
            <Slider {...settings}>
            <div className="partner-say-card">
              <strong>Muster Technologies Inc</strong>
              <b>Inderjit Vasudevan Moorthy</b>
              <em>CEO</em>
<p>"Ionixx Technologies developed solutions that stabilized and improved the app, resulting in high satisfaction from investors. The abundant number of users give the app high ratings thanks to the steady UX."
</p>
<i>
  <img src="/assets/img/defi-services/clutch.svg"/>
</i>
            </div>
            <div className="partner-say-card">
              <strong>Bitsian Inc</strong>
              <b>Stevan Brucato</b>
              <em>CTO & Co-founder</em>
              
<p>"The final product is fully-functional and stable. Ionixx Technologies demonstrated a complete commitment to the project, working through nights to adhere to an accelerated timeline."</p>
<i>
  <img src="/assets/img/defi-services/clutch.svg"/>
</i>
            </div>
            <div className="partner-say-card">
              <strong>P2E Technologies, Inc.</strong>
              <b>Jaccqueline Maduneme</b>
              <em>CEO</em>
              

<p>“The solution met the partner's expectations and has received positive feedback and interest from external parties. Ionixx Technologies worked in sprints and went the extra mile to challenge ideas that'd improve the final product.”</p>
<i>
  <img src="/assets/img/defi-services/clutch.svg"/>
</i>
            </div>
            <div className="partner-say-card">
              <strong>MSM Microfinance Ltd</strong>
              <b>Vasanth Vasu</b>
              <em>Sr. VP of Operations</em>
              
<p>“The system improved staff efficiency while reducing turnaround times and cutting costs per acquisition. Ionixx Technologies impressed with their smooth, agile methodology, robust project management processes, and proactive approach.”</p>
<i>
  <img src="/assets/img/defi-services/clutch.svg"/>
</i>
            </div>
            <div className="partner-say-card">
              <strong>Cholamandalam Investment & Finance Company</strong>
              <b>Dinesh Kumar</b>
              <em>Head Marketing</em>
              
<p>“While the project is still underway, the team has seamlessly implemented the auction and inventory funding functions. They've completely automated processes that were previously manual. ”</p>
<i>
  <img src="/assets/img/defi-services/clutch.svg"/>
</i>
            </div>
            <div className="partner-say-card">
              <strong>Ribbon Communications</strong>
              <b>Mark Kujanpaa</b>
              <em>Services Product Manager</em>
<p>“While the project is still ongoing, the results thus far have met the expectations of the internal team. Ionixx Technologies communicates regularly with the client to ensure both teams are on the same page.”</p>
<i>
  <img src="/assets/img/defi-services/clutch.svg"/>
</i>
            </div>
            <div className="partner-say-card">
              <strong>Venture Debt Provider</strong>
              <b>Brad C. Johnson</b>
              <em>Director of Technology</em>
<p>“The platform has greatly benefitted the business. Through their committed work ethic and proactive, efficient management, Ionixx delivers satisfactory results. The team accommodates a variety of schedules, development styles.”</p>
<i>
  <img src="/assets/img/defi-services/clutch.svg"/>
</i>
            </div>
            <div className="partner-say-card">
              <strong>Voyaj</strong>
              <b>Yasmine El Baggari</b>
              <em>Founder & CEO</em>
              
<p>“Ionixx Technologies was able to successfully create and launch the application in a timely manner. the team provided great workflow and communication throughout the project.”</p>
<i>
  <img src="/assets/img/defi-services/clutch.svg"/>
</i>
            </div>
            </Slider>
           </div>
           </div>
      </section>
      <section className="about-info">
        <div className="container">
          <div className="about-info-inner">
        <div className="about-detail">
          <strong>250+</strong>
          <span>Designers & <br/> developers</span>
        </div>
        <div className="about-detail">
          <strong>50+</strong>
          <span>Projects</span>
        </div>
        <div className="about-detail">
          <strong>10+</strong>
          <span>Years of industry <br/> experience</span>
        </div>
        <div className="about-detail">
          <strong>30+</strong>
          <span>Customers & <br/> growing</span>
        </div>
        </div>
        </div>
      </section>
      <section className="features">
        <div className="container">
        <h3>Solutions</h3>
        <div className="features-wrap">
        <div className="feature-list">
          <i>
            <img src="/assets/img/defi-services/dapp.svg" alt="Dapp"/>
          </i>
          <div className="feature-list-inner">
            <b>DeFi DApp</b>
            <p>We specialize in the development of secure, scalable, and reliable DeFi applications that are customized to meet your specific needs. Our expertise encompasses all DeFi applications, including decentralized exchanges, protocol lending, and more.</p>
          </div>
        </div>
        <div className="feature-list">
          <i>
          <img src="/assets/img/defi-services/token.svg" alt="token"/>
          </i>
          <div className="feature-list-inner">
            <b>DeFi Token</b>
            <p>Create native DeFi tokens with faster time to market and less hassle. Our team specializes in developing tradable assets that prioritize security, compliance, and the attraction of potential investors.</p>
          </div>
        </div>
        <div className="feature-list">
          <i>
          <img src="/assets/img/defi-services/wallet.svg" alt="wallet"/>
          </i>
          <div className="feature-list-inner">
            <b>Non-Custodial DeFi<br/> Wallet</b>
            <p>We excel at designing and developing non-custodial DeFi wallets that give users complete control over their funds. With our key-based wallet solutions, we ensure exceptional security and effortless management of digital assets, all while eliminating the reliance on third-party entities.</p>
          </div>
        </div>
        <div className="feature-list">
          <i>
          <img src="/assets/img/defi-services/lending.svg" alt="lending"/>
          </i>
          <div className="feature-list-inner">
            <b>DeFi Lending & <br/> Borrowing Platform</b>
            <p>Enable quick and secure lending and borrowing by leveraging smart contracts. Experience top-notch, feature-rich DeFi platforms that support multiple cryptocurrencies.</p>
          </div>
        </div>
        <div className="feature-list">
          <i>
          <img src="/assets/img/defi-services/exchange.svg" alt="exchange"/>
          </i>
          <div className="feature-list-inner">
            <b>DeFi Exchange</b>
            <p>Create a decentralized exchange that facilitates direct trading of tokens and digital assets without the need for central authorities. Our reliable and transparent custom solution development empowers users to have full control over their assets, accommodating various business requirements.</p>
          </div>
        </div>
        <div className="feature-list">
          <i>
          <img src="/assets/img/defi-services/contract.svg" alt="contract"/>
          </i>
          <div className="feature-list-inner">
            <b>DeFi Smart Contract</b>
            <p>Optimize your transaction processes through our meticulously designed smart contracts by automating agreement execution and removing intermediaries. Our Experts develops, verifies, and deploys secure protocols, offering comprehensive support throughout the entire process.</p>
          </div>
        </div>
        <div className="feature-list">
          <i>
          <img src="/assets/img/defi-services/crypto.svg" alt="crypto"/>
          </i>
          <div className="feature-list-inner">
            <b>Decentralized Crypto Banking</b>
            <p>Enable your users to leverage the potential of decentralized crypto banking with our custom DeFi solutions development . Our services offer a seamless user experience, facilitating direct value transfers and decentralization.</p>
          </div>
        </div>
        </div>
        </div>
      </section>
      <section className="why-choose-us">
        <div className="container">
        <h4>Why Choose us as Your<br/> Development Partner?</h4>
        <p className="sub-head">By entrusting your DeFi development to us, not only can you reduce costs compared to hiring and training in-house developers, but also enjoy a quicker time-to-market for your DeFi applications made by the industry experts.</p>
        <div className="why-choose-wrap">
          <Slider {...settingsNew}>
           <div className="why-choose-card">
              <strong>Blockchain Experts</strong>
              <p>With a focus on excellence, our 120 + skilled professionals provide exceptional DeFi solutions that are both customized and scalable. Our expertise guarantees top-level guidance throughout the process.</p>
           </div>
           <div className="why-choose-card">
              <strong>Accelerated Development</strong>
              <p>Our emphasis on a well-defined plan and fast entry into the market facilitates prompt feedback and iterative processes, leading to optimal outcomes. We specialize in crafting customized solutions that perfectly cater to your target audience.</p>
           </div>
           <div className="why-choose-card">
              <strong>Tailored To Your Needs</strong>
              <p>We collaborate with you closely at each step of the development to increase transparency and understanding your requirements, ensuring outputs that fit to your vision.</p>
           </div>
           <div className="why-choose-card">
              <strong>End-to-End Support</strong>
              <p>We provide consistent assistance from the start to after your launch, allowing you to focus on your business while our committed technical team remains readily available to serve you.</p>
           </div>
           <div className="why-choose-card">
              <strong>Faster time to market</strong>
              <p>Our DeFi solutions are engineered to accelerate your path to market, ensuring rapid deployment and swift access to innovative custom financial solutions.</p>
           </div>
           </Slider>
        </div>
        </div>
      </section>
      <section className="awards-section">
        <div className="container">
          <div className="award-container">
            <div className="award-list">
              <div className="award-img-wrap">
                <img
                  src="/assets/img/awards-new/award-4.png"
                  alt="top-software-developers"
                />
              </div>
              <b>2020</b>
              <p>Top Software Developers India</p>
            </div>
            <div className="award-list">
              <div className="award-img-wrap">
                <img
                  src="/assets/img/awards-new/award-7.png"
                  alt="top-100-blockchain"
                />
              </div>
              <b>2020</b>
              <p>Top 100 Blockchain Development Companies</p>
            </div>
            <div className="award-list">
              <div className="award-img-wrap">
                <img
                  src="/assets/img/awards-new/award-2.svg"
                  alt="software-development-company"
                />
              </div>
              <b>2020</b>
              <p>Top Software Development Company</p>
            </div>
            <div className="award-list">
              <div className="award-img-wrap">
                <img
                  src="/assets/img/awards-new/award-1.svg"
                  alt="blockchain-development-company"
                />
              </div>
              <b>2019</b>
              <p>Top Blockchain Development Company</p>
            </div>
            <div className="mob-view">
                <img
                  src="/assets/img/modern-post-trade/awards-info.png"
                  alt="Awards-info"
                />
              </div>
          </div>
          
          <button className="call-schedule" onClick={handleShow}>Schedule a Meet</button>
        </div>
      </section>
      
     <section className="question">
        <h2>Have questions?</h2>
        <div className="container">
          <div className="question-inner">
            <span>Email: <a href="mailto:info@ionixxtech.com">info@ionixxtech.com</a></span>
            <span>Phone: <a
                                className="link-to-connect"
                                href="tel:+1 (909) 757 - 3707"
                              >
                                +1 (909) 757 - 3707
                              </a></span>
          </div>
        </div>
     </section>

      <Modal
        size="lg"
        dialogClassName="modal-form"
        show={show}
        onHide={handleClose}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className="health-care-heading">
            Schedule a Meet
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <iframe
                  width="100%"
                  height="380px"
                  src="https://calendly.com/shaan-ionixx/15min?hide_event_type_details=1&hide_gdpr_banner=1"
                ></iframe>
        </Modal.Body>
      </Modal>
    </div>
  );
};
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(contactAction, dispatch);
};

export default connect(null, mapDispatchToProps)(withRouter(DefiService));
