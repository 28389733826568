import React from 'react';
import ReactSEOMetaTags from 'react-seo-meta-tags';
import Helmet from 'react-helmet';

class Dvs extends React.Component {
    constructor(props) {
        super(props)
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }


    render() {
        return (
			<>	
			<Helmet>
                <title>DVS:Blockchain Enabled Document Verification Tool</title>
                <meta name="description" content="Ionixx crafted a blockchain document verification tool for DVS, an all-in-one Saas-based app, enhancing document security and user experience." />
                <link rel="canonical" href="https://www.ionixxtech.com/portfolio/dvs" />
            </Helmet>
            <ReactSEOMetaTags
                render={(el) => <Helmet>{el}</Helmet>}
                website={{ 
                    name: "Ionixx Technologies", 
                    title:"DVS:Blockchain Enabled Document Verification Tool ", 
                    description: " Ionixx crafted a blockchain document verification tool for DVS, an all-in-one Saas-based app, enhancing document security and user experience.",
					type:"website",
					image:'/assets/img/ionixx-logo.png',
                    url:"https://www.ionixxtech.com/portfolio/dvs"
                }}
                twitter={{ 
                    twitter: "https://www.ionixxtech.com/portfolio/dvs" }}
                /> 
            <div className="dvs-port">
<section className="main-intro main-intro-bg">
	<div className="ix-container blockchain-all-contents block-main-intro-para brief-container">
		<div className="row brief-row">
			<div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 dvs-logo-sec">
				<div className="dvs-logo">
					<img src="/assets/img/portfolio-img/dvs-portfolio-img/dvs.png" alt="Dev portfolio" className="wow slideInLeft animated"/>
				</div>
			</div>
			<div className="col-xl-6 col-lg-6 col-md-6 col-sm-6">
				
			</div>
			<div className="col-xl-7 col-lg-7 col-md-7 col-sm-12 brief-col">
				<div className="dvs-brief-card">


					<div className="dvs-brief-card-inner-frame"> 

						<div className="dvs-ribbon">
							<span className="ribbon-head">The Challenge</span>
						</div>

					<div className="dvs-brief-card-top">

						<div className="dvs-port-head">
							<h5>Defining the problem</h5>
						</div>
						<div className="explanation">
							<ul className="prob-ul">
								<li className="prob-li">
								<span className="circle pulse"></span>
								<p>We live at a time and age where there is a wide proliferation of fake documents and certificates without a regulated system to verify their authenticity.</p>
								</li>
								<li className="prob-li">
									<span className="circle pulse"></span>
									<p>Existing background verifications solutions are challenged with cumbersome paper-driven processes and necessitate the involvement of middlemen notwithstanding poor results.</p>
								</li>
							</ul>
						</div>

					</div>
					<div className="dvs-brief-card-bottom">
						<div className="dvs-port-head">
							<h5>Devising a solution</h5>
						</div>
						<div className="explanation">
							<ul className="prob-ul">
								<li className="prob-li">
								<span className="circle pulse"></span>
								<p>DVS is an all-in-one SaaS-based blockchain application for organizations and institutions to verify and validate highly critical documents.</p>
								</li>
								<li className="prob-li">
								<span className="circle pulse"></span>
									<p>By creating a decentralized blockchain ledger that uses strong cryptography, we built DVS with the aim to enhance the safety and security of the most critical documents.</p>
								</li>
								<li className="prob-li">
									<span className="circle pulse"></span>
									<p>All contracts, documents, and communications are automatically encrypted and hashed to the blockchain.</p>
								</li>
							</ul>
						</div>
					</div>
					<div className="dvs-brief-card-bottom">
						<div className="dvs-port-head">
							<h5>Potential User Groups</h5>
						</div>
						<div className="explanation">
							<ul className="prob-ul">
								<li className="prob-li">
									<span className="circle pulse"></span>
								<p>Candidates, Authenticators & Syndicates.</p>
								</li>
							</ul>
						</div>
					</div>

				</div> 

				</div> 
			</div>
			<div className="col-xl-5 col-lg-5 col-md-5 col-sm-12 dvs-mac-col">
					<div className="dvs-login-screen-img">
						<img src="/assets/img/portfolio-img/dvs-portfolio-img/dvs-mac-image.png" alt="Dvs mac" className="wow slideInRight animated"/>
					</div>
			</div>
		</div>		
	</div>
	<div className="grad-bubbles-img">
		<img src="/assets/img/portfolio-img/dvs-portfolio-img/circle-effects.png" alt="circle effect"/>
	</div>
</section>


<section className="solution-section">
	<div className="solution-section-head">
		<h1 ><span>How we went about arriving at the solution</span></h1>
		<span className="bottom-bubble">
				<span className="circle pulse"></span>
		</span>
	</div>
	</section>



	<section className="unified-section">


	<div className="ix-container blockchain-all-contents block-main-intro-para">


		<div className="unified-solution-content">
			<div className="unified-head">
				<h5 className="sub-heads">1. Designing a single unified platform on Blockchain</h5>
				<p>We evaluated a few Blockchain frameworks and decided to build a DApp to leverage the decentralized nature of the Ethereum blockchain.</p>
			</div>
			<div className="block-enabled-platform-content">
				<div className="block-enabled-platform-head">
					<h6>The blockchain-enabled platform:</h6>
				</div>

				<div className="block-platform-cards">
					<div className="row">
						<div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 block-col">
							<div className="block-card-one block-card card top-blue-border">
								<div className="card-body">
									<div className="row cards-row">
										<div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 block-card-img-col block-card-img-col">
											<div className="unified-card-img">
												<img src="/assets/img/portfolio-img/dvs-portfolio-img/icon-1.svg" alt="Sesitive icon" className="wow tada animated"/>
											</div>
										</div>
										<div className="col-xl-9 col-lg-9 col-md-9 col-sm-12">
										<p className="card-para">Helped users upload sensitive documents, view and download them whenever necessary.</p>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 block-col">
							<div className="block-card-two block-card card">
								<div className="card-body">
									<div className="row cards-row">
										<div className="col-xl-3 col-lg-3 col-md-3 col-sm-12 block-card-img-col  block-card-img-col">
											<div className="unified-card-img">
										<img src="/assets/img/portfolio-img/dvs-portfolio-img/icon-2.svg" alt="Patient entity" className="wow tada animated"/>
									</div>
										</div>
										<div className="col-xl-9 col-lg-9 col-md-9 col-sm-12">
										<p className="card-para">Facilitated various participant entities to collaborate with each other seamlessly to complete the verification process. </p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div> 

					<div className="row">

						<div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 block-col">
							<div className="block-card-one block-card card">
								<div className="card-body">
									<div className="row cards-row">
										<div className="col-xl-3 col-lg-3 col-md-3 col-sm-12  block-card-img-col">
											<div className="unified-card-img">
											<img src="/assets/img/portfolio-img/dvs-portfolio-img/icon-3.svg"  alt="User complete" className="wow tada animated"/>
										</div>
										</div>
										<div className="col-xl-9 col-lg-9 col-md-9 col-sm-12">
										<p className="card-para">Enabled users to take complete control over their documents. They could upload the documents, store them in a secure way, and share with different employers as and when required.</p>
										</div>
									</div>
								</div>
							</div>
						</div>
						<div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 block-col">
							<div className="block-card-two block-card card bottom-blue-border">
								<div className="card-body">
									<div className="row cards-row">
										<div className="col-xl-3 col-lg-3 col-md-3 col-sm-12  block-card-img-col">
											<div className="unified-card-img">
											<img src="/assets/img/portfolio-img/dvs-portfolio-img/icon-4.svg"  alt="Enable employee" className="wow tada animated"/>
										</div>
										</div>
										<div className="col-xl-9 col-lg-9 col-md-9 col-sm-12">
										<p className="card-para">Enabled employers to gain access to candidates’ documents on candidate approval. This helped employers to easily verify the documents, thus completely avoiding delays encountered in the traditional process. </p>
										</div>
									</div>
								</div>
							</div>
						</div>


					</div>






				</div>

			</div>
		</div>


</div>

	<div className="grad-bubbles-img-unified">
		<img src="/assets/img/portfolio-img/dvs-portfolio-img/circle-effects.png"/>
	</div>

	<div className="landing-strip-top-left">
		<img src="/assets/img/portfolio-img/dvs-portfolio-img/strips.png" />
	</div>
	<div className="landing-strip-top-right">
		<img src="/assets/img/portfolio-img/dvs-portfolio-img/strips.png" />
	</div>
	</section>




	<section className="creating-prototype-section">


		<div className="ix-container blockchain-all-contents block-main-intro-para">

			<div className="creating-prototype-content">

				<span className="bottom-bubble-one">
					<span className="circle pulse"></span>
				</span>

				<div className="prototype-frame">
					<div className="creating-prototype-head bottom-blue-border">
						<h5 className="sub-heads top-blue-border"><span>2. Creating Prototypes</span></h5>
						<p className="proto-para">By prototyping, we could test the application upfront and identify any possible glitches.</p>
                        <p className="proto-para">By testing all assumptions with the prototype, we ensured there was seamless navigation before going live.</p>
					</div>
				</div>
				<div className="prototype-main-images">
					<div className="row">
						<div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 left-request-col">
							<div className="new-request-left-img">
								<img src="/assets/img/portfolio-img/dvs-portfolio-img/owner-verification-request-01.png"  alt="Owner verification" className="wow fadeIn animated"/>
							</div>
							<span className="pulse top-pulse">
							</span>
							<span className="pulse bottom-pulse">
							</span>

						</div>
						<div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 right-owner-col">
							<div className="contact-owner-right-img">
								<img src="/assets/img/portfolio-img/dvs-portfolio-img/owner-image.png" className="wow fadeIn animated"  alt="Owner Image"/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

	<div className="grad-bubbles-img-prototype">
		<img src="/assets/img/portfolio-img/dvs-portfolio-img/circle-effects.png" />
	</div>

	</section>

	<section className="focus-design">

		<div className="ix-container blockchain-all-contents block-main-intro-para">
			<div className="focus-contents">
				<div className="row">
					<div className="col-xl-6 col-lg-6 col-md-6 col-sm-12">
						<div className="dvs-left-mac">
						<img src="/assets/img/portfolio-img/dvs-portfolio-img/owner-verifiy.png" alt="Owner verify" className="wow slideInLeft animated"/>
						</div>
					</div>
					<div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 focus-right-col">

						<div className="focus-content-frame">

						<div className="focus-content-hold">
							<form className="bottom-blue-border">
							  <fieldset className="top-blue-border">
							    <legend>3. Focusing on Visual Design</legend>
							    
							    	<p className="proto-para">To add to the aesthetic value, we used imagery, colors, shapes, typography, and form to enhance the look and feel - besides improving the overall user experience.</p>

							    </fieldset>
							</form>
						</div>
					</div>

					<div className="bottom-pulse-last pulse">

					</div>
					</div>
				</div>
			</div>
		</div>

	<div className="grad-bubbles-img-focus">
		<img src="/assets/img/portfolio-img/dvs-portfolio-img/circle-effects.png"/>
	</div>
	<div className="landing-strip-bottom-right">
		<img src="/assets/img/portfolio-img/dvs-portfolio-img/strips.png"/>
	</div>
	</section>
            </div>
			</>		
	   );
    }

}

export default Dvs;
