import React from 'react';
import "../../resources.css";
import {ResourceRoute} from '../../resource-routing';
import {Link} from "react-router-dom";
import NewsHeadings from "./common-action/news-heading";
import NewsSubscription from "./common-action/subscripbe-section";

class NewsIssue67 extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            pageEnabled: false,
        }

    }

    render() {
        return (
          <div className="newdesigned_issue_details">
            <NewsHeadings
              buttondDetail="Design"
              categoryType="design"
              description="Building User Trust In UX Design | Smashing Magazine"
            />
            <div className="newsissuedetailed">
              <div className="detailed-common">
                {/* <div className="back-to-common">
                    <span className="back-to-icon-hold">
                        <img src="/assets/img/resource/back-to-new.svg"  alt="back to new"></img>
                    </span>
                    <Link  to={ResourceRoute.NEWSLETTER}><span className="back-to-link">Newsletter</span></Link>
                </div> */}

                <div className="news-letter whats-on-hold">
                  <div className="issue-main-head">
                    <h4>What’s on?</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://aithority.com/technology/blockchain/hashcash-to-provide-an-australian-crypto-enterprise-with-blockchain-and-cryptocurrency-solutions/"
                      >
                        <h4>
                          HashCash to Provide an Australian Crypto Enterprise
                          With Blockchain and Cryptocurrency Solutions|
                          AIThority.com
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Global blockchain pioneer HashCash Consultants ties up
                        with an Australian Crypto enterprise to help them with
                        cryptocurrency and blockchain solutions. They aim to
                        assist the crypto enterprise with their scalable and
                        agile solutions, thereby leading to successful
                        blockchain development in the Australian enterprise.
                        HashCash will offer a wide spectrum of services and
                        solutions to the Australian crypto enterprise, thereby
                        leveraging security and blockchain capabilities to
                        foster innovation.
                      </p>
                    </div>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://btcmanager.com/huobi-digital-asset-management-bitcoin-altcoin-fund/"
                      >
                        <h4>
                          Huobi Digital Asset Management to Launch Bitcoin and
                          Altcoins Fund | BTC Manager
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        With the global cryptocurrency markets now at a massive
                        $1.45 trillion market capitalization, Huobi Technology
                        Holdings, a fully-owned subsidiary of Huobi
                        cryptocurrency exchange, is aiming to make the most of
                        the crypto boom by launching new digital asset funds.
                        Per sources close to the matter, Huobi Technology
                        Holdings, which is listed on the Hong Kong stock
                        exchange, says it’s looking to launch a bitcoin price
                        tracker fund, an ether tracker fund and a multi-strategy
                        digital asset fund. The three investment vehicles will
                        be 100 percent invested in cryptocurrencies.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="news-letter top-picks-hold">
                  <div className="issue-main-head">
                    <h4>Top Picks</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.smashingmagazine.com/2021/02/building-user-trust-in-ux-design/"
                      >
                        <h4>
                          Building User Trust In UX Design | Smashing Magazine
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Building trust is one of the central goals of user
                        experience design. And yet trust is a concept that’s
                        very hard to define in a precise manner. We all know it
                        when we feel it but often fall short of putting it in
                        words. Being able to turn elusive and intangible into
                        actionable and concrete steps, however, is exactly what
                        makes UX so crucial in the modern business ecosystem.
                        Although a product experience that is useful and
                        coherent is what fundamentally builds a sense of
                        security and satisfaction, there’s a lot more nuance
                        that goes building it. That’s what this article is
                        about. We’ll take a deeper dive into users’ trust and
                        how we can use UX to build a lasting relationship with
                        your clientele.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="news-letter other-reads-hold">
                  <div className="issue-main-head">
                    <h4>Other Reads</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://thehustle.co/coinbases-100b-public-debut-explained/"
                      >
                        <h4>
                          Coinbase’s $100B+ public debut, explained | MIT Tech
                          Review
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Bitcoin recently hit the $50k milestone.If that feels
                        pricey, how about a share of the largest US
                        cryptocurrency exchange? Presenting Coinbase (ticker:
                        COIN). The company has been valued at $100B+ in private
                        secondary trading, which would make its direct listing
                        the largest tech debut since Facebook. According to
                        Coinbase’s S-1, the current financial system is an
                        inefficient and costly patchwork of intermediaries.
                        There’s a need for a new, digitally native financial
                        system -- AKA the crypto economy.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <NewsSubscription categoryType="Design" />
          </div>
        );
    }

}

export default NewsIssue67;
