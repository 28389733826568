import React from 'react';
import "../../resources.css"
import {ResourceRoute} from '../../resource-routing';
import {Link} from "react-router-dom";

class DecemberFortnight1 extends React.Component {
    constructor(props){
        super(props)

    }
    render(){
        return (
            <div className="BlockchainDetailed">
                <div className="back-to-common">
                    <span className="back-to-icon-hold">
                        <img src="/assets/img/resource/back-to-new.svg"  alt="bact to new"></img>
                    </span>
                    <Link  to={ResourceRoute.BLOCKCHAIN}><span className="back-to-link">Blockchain Bulletin</span></Link>
                </div>
                <div className="download-common inner-section-1">
                    <div>
                        <div className="main-head-div">
                            <h4 className="main-head-1">
                                <span className="primary-span">December Fortnight 1 Round-up Blockchain & Crypto Brief</span>
                            </h4>
                            <p className="press-info">( December 09, 2019 )</p>
                        </div>
                    </div>    
                </div>
                <div class="bulletin-head-details bulletin-sub-heads">
                    <h4>
                        <span className="primary-span">Finance</span>
                    </h4>
                    <ul className="bulletin-links-ul">
                        <li>
                            <a href="https://www.coindesk.com/makerdao-proposes-new-dai-ceiling-after-hitting-100-million-cap" target={'_blank'}>The Ethereum-based stablecoin MakerDai hit its predetermined limit of $100 million, post which the Maker Foundation has proposed a new debt ceiling of 120 million DAI, which the holders of MKR governance tokens will vote on.</a>
                        </li>
                        <li>
                            <a href="https://tokenpost.com/Bitcoincom-to-launch-200M-bitcoin-cash-investment-fund-in-2020-4237" target={'_blank'}>Bitcoin.com is planning on setting up a $200 million investment fund in 2020 to boost bitcoin cash (BCH) adoption.</a>
                        </li>
                        <li>
                            <a href="https://finance.yahoo.com/news/coinbase-custody-expands-tezos-staking-114516066.html" target={'_blank'}>Coinbase Custody has expanded Tezos staking service to all its international clients.</a>
                        </li>
                        <li>
                            <a href="https://www.theblockcrypto.com/post/48415/remittance-firm-transfergo-plans-to-move-to-ripple-solution-that-uses-xrp" target={'_blank'}>The UK-based remittance company TransferGo is planning to head to Ripple’s payments solution that uses XRP - the world’s third-largest cryptocurrency.</a>
                        </li>
                        <li>
                            <a href="https://www.coindesk.com/juventus-soccer-club-is-launching-a-crypto-token-to-give-fans-a-voice" target={'_blank'}>Ronaldo’s soccer club has launched its own token that gives over 400 million fans voting power and gives them a ‘voice.’</a>
                        </li>
                        <li>
                            <a href="https://finance.yahoo.com/news/binance-adding-support-tezos-staking-150022651.html?guccounter=1" target={'_blank'}>Binance has added support for Tezos staking at zero fee, started December 4.</a>
                        </li>
                    </ul>
                </div>
                <div class="bulletin-head-details bulletin-sub-heads">
                    <h4>
                        <span className="primary-span">Finance</span>
                    </h4>
                    <ul className="bulletin-links-ul">
                        <li>
                            <a href="https://bitcoinexchangeguide.com/ant-financial-of-alibaba-opens-up-blockchain-testing-for-small-and-medium-sized-businesses/" target={'_blank'}>Alibaba affiliate company Ant Financial has launched the testing stage for its blockchain network focused on supporting small andmedium-sized businesses.</a>
                        </li>
                        <li>
                            <a href="https://www.theblockcrypto.com/linked/47405/us-fed-admits-libra-has-potential-to-rapidly-achieve-widespread-adoption" target={'_blank'}>According to a US Federal Reserve new report, stablecoins similar to Facebook’s Libra have the potential to rapidly achieve widespread adoption. The report also claims that such stablecoins also pose risks to financial stability and if such a global stablecoin network isn’t designed properly, it could fail miserably and result in a loss of confidence, which has ripple effects across the financial system​ .</a>
                        </li>
                        <li>
                            <a href="https://www.coindesk.com/brics-nations-ponder-digital-currency-to-ease-trade-reduce-usd-reliance" target={'_blank'}>The BRICS, the international association of major emerging economies Brazil, Russia, India, China, and South Africa, could launch its own digital currency to reduce its reliance on the US dollar and ease trades.</a>
                        </li>
                        <li>
                            <a href="https://www.coindesk.com/bakkt-to-launch-crypto-consumer-app-in-first-half-of-2020" target={'_blank'}>Bakkt has announced its plans to launch a consumer-facing app to help retail customers transact with cryptocurrencies.</a>
                        </li>
                        <li>
                            <a href="https://www.blockchaintechnology-news.com/2019/11/19/nestle-and-carrefour-use-blockchain-for-infant-nutrition-range/" target={'_blank'}>Nesté and Carrefour have collaborated to track the provenance of the baby formula using distributed ledger technology developed by IBM.Consumers will be able to see the nutrition info and other data by scanning a QR code.</a>
                        </li>
                        <li>
                            <a href="https://www.theblockcrypto.com/linked/47752/bitcoin-miner-maker-canaan-creative-raises-90m-in-u-s-ipo" target={'_blank'}>Cryptocurrency mining hardware manufacturer Canaan Creative has raised $90 million in US IPO.</a>
                        </li>
                        <li>
                            <a href="https://finance.yahoo.com/news/south-korea-plans-invest-over-133012824.html" target={'_blank'}>South Korea has plans to invest about $380 million for blockchain research and development.</a>
                        </li>
                        <li>
                            <a href="https://finance.yahoo.com/news/china-central-bank-shanghai-unit-110009170.html" target={'_blank'}>China’s central bank’s Shanghai unit has officially announced that it will crack down on crypto exchanges.</a>
                        </li>
                        <li>
                            <a href="https://news.yahoo.com/bitmain-co-founder-crypto-startup-120516087.html" target={'_blank'}>Bitmain co-founder’s crypto startup Matrixport expands in Europe with a new Zurich office.</a>
                        </li>
                        <li>
                            <a href="https://economictimes.indiatimes.com/tech/internet/meity-planning-strategy-for-national-use-of-blockchain/articleshow/72259826.cms" target={'_blank'}>India’s Ministry of Electronics and Information Technology is underway developing a national blockchain strategy.</a>
                        </li>
                        <li>
                            <a href="https://finance.yahoo.com/news/ghana-could-issue-digital-currency-110513928.html" target={'_blank'}>The western African nation of Ghana could launch its digital currency soon.</a>
                        </li>
                        <li>
                            <a href="https://www.bloomberg.com/press-releases/2019-11-27/saga-announces-official-launch-date-of-sga-token-and-opens-online-onboarding-process" target={'_blank'}>Saga Monetary Technologies is going to launch its Saga Token onDecember 10. A full onboarding process is now accessible on its website.</a>
                        </li>
                        <li>
                            <a href="https://www.theblockcrypto.com/linked/48640/kraken-joins-silvergate-exchange-network-to-enable-real-time-funds-transfers" target={'_blank'}>Kraken has announced joining the Silvergate Exchange Network (SEN) to give its clients more choice in managing their assets.</a>
                        </li>
                        <li>
                            <a href="https://www.reuters.com/article/us-hsbc-hldg-blockchain/hsbc-swaps-paper-records-for-blockchain-to-track-20-billion-worth-of-assets-idUSKBN1Y11X2" target={'_blank'}>HSBC has switched to blockchain from paper records to track $20 billion worth of assets.</a>
                        </li>
                        <li>
                            <a href="https://economictimes.indiatimes.com/small-biz/startups/newsbuzz/binance-acquires-mumbais-bitcoin-exchange-wazirx/articleshow/72152901.cms?from=mdr" target={'_blank'}>Binance has acquired India’s most trusted Bitcoin exchange WazirX to launch multiple fiat-to-crypto gateways.</a>
                        </li>
                        <li>
                            <a href="https://finance.yahoo.com/news/india-most-populous-state-launch-093048885.html" target={'_blank'}>Uttar Pradesh, India’s most populous state, is testing a blockchain-powered solar energy trading platform.</a>
                        </li>
                        <li>
                            <a href="https://www.theblockcrypto.com/post/48765/south-africa-plans-for-new-rules-to-stop-crypto-from-being-used-to-evade-currency-controls" target={'_blank'}>South Africa is planning to introduce new roles to stop crypto from evading currency controls.</a>
                        </li>
                        <li>
                            <a href="https://www.coindesk.com/wisdomtree-launches-physically-backed-bitcoin-etp-on-six-swiss-exchange" target={'_blank'}>WisdomTree Investments has launched a physically-backed Bitcoin exchange- traded product (ETP).</a>
                        </li>
                        <li>
                            <a href="https://www.theblockcrypto.com/linked/49031/sec-appoints-new-cyber-unit-chief-to-police-icos-and-other-blockchain-violations" target={'_blank'}>The US Securities and Exchange Commission (SEC) has named Kristina Littman as the new chief of its cyber unity to target violations involving ICOs and blockchain technology.</a>
                        </li>
                        <li>
                            <a href="https://finance.yahoo.com/news/bakkt-ceo-kelly-loeffler-become-162022310.html" target={'_blank'}>Bakkt CEO Kelly Loeffler has become the second woman in Georgia’s history to serve as the US Senator representing the state.</a>
                        </li>
                        <li>
                            <a href="https://www.theblockcrypto.com/linked/49194/htcs-binance-edition-smartphone-going-on-sale-tomorrow-supports-bitcoin-bnb-payments" target={'_blank'}>HTC’s Binance edition smartphone the HTC EXODUS 1 - Binance is now available to buy since the 5th of December, and it retails for US$599 or SG$889.</a>
                        </li>
                        <li>
                            <a href="https://www.theblockcrypto.com/linked/51388/poloniex-exchange-drops-kyc-for-withdrawals-of-up-to-10000-per-day" target={'_blank'}>Since crypto exchanges Poloniex and Circle parted ways last month, the latter has said that if Poloniex’s US customers don’t withdraw funds from its platform, they’ll be charged with fees.</a>
                        </li>
                        <li>
                            <a href="https://www.theblockcrypto.com/post/49307/european-central-bank-is-in-favor-of-its-own-digital-currency-for-faster-and-cheaper-payments" target={'_blank'}>European Central Bank is planning to issue its own digital currency for faster and cheaper payments.</a>
                        </li>
                        <li>
                            <a href="https://finance.yahoo.com/news/anti-crypto-congressman-brad-sherman-100018774.html" target={'_blank'}>Anti-crypto US Congressman Brad Sherman has been elected as chairman of the Subcommittee on Investor Protection, Entrepreneurship and Capital Markets.</a>
                        </li>
                    </ul>
                </div>
            </div>        
        );
    }

}

export default DecemberFortnight1;
