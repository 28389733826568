import React from 'react';
import {Link} from "react-router-dom";
import { ROUTES } from '../../../../../routes';
import {ResourceRoute} from '../../../resource-routing';
import PaperBooksDownload from "./../paper-books-download";

class MobileAppDevelopmentApproach extends React.Component {
    constructor(props) {
        super(props)
    }
    render() {
        return (
            <>
                        
                        <div className="newsissuedetailed">
            <div className="detailed-common">
                <div className="back-to-common">
                    <span className="back-to-icon-hold">
                        <img src="/assets/img/resource/back-to-new.svg"  alt="bact to new"></img>
                    </span>
                    <Link to={ResourceRoute.WHITEPAPER_TAB}><span className="back-to-link">Whitepapers & e-Books</span></Link>
                </div>

                <div className="whats-on-hold">
                   
                    <div className="ebook-sec">
						<div className="row">
							<div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 resp-apprch-col">
								<div className="custom-section work-with-custom-section">
                                <div class="main-head-div">
                                    <h1 class="main-head-1">
                                        <span class="secondary-span">Technology Whitepaper: Addressing the Big Question in Mobile App Development - Native, Web or Hybrid?</span>
                                    </h1>
                                </div>
									<div className="main-para-div">
                                    <p>An organization should standardize its app development tools for better coordination among developers. A suitable <a target="_blank" href="https://blog.ionixxtech.com/get-those-sales-how-mobile-apps-fit-into-your-conversion-strategy/  ">mobile app strategy </a> is essential for enterprises focusing on  customer acquisition and retention.</p>
                            <p>Fill out the form to grab your free copy today!</p>
										{/* <a class="d-hide m-show" href="">View our software portfolio</a> */}
									</div>
								</div>
							</div>
							<div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 resp-apprch-col">
								<div class="ebook-form-sec">
                                  <div class="modal-title">
                                    <h4>Tell us a little about you</h4>
                                  </div>
                                  <div class="ebook-dwnld-form">
                                  <PaperBooksDownload pdfUrl={this.props?.location?.state} />
                                    </div>
                            
                                </div>
							</div>
						</div>	
		            </div>
                </div>

            </div>
        </div> 

            </>
        );
    }

}

export default MobileAppDevelopmentApproach;
