import React from 'react';
import "../../resources.css";
import { ResourceRoute } from '../../resource-routing';
import { Link } from "react-router-dom";
import NewsHeadings from "./common-action/news-heading";
import NewsSubscription from "./common-action/subscripbe-section";

class NewsIssue11 extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            pageEnabled: false,
        }

    }

    render() {
        return (
          <div className="newdesigned_issue_details">
            <NewsHeadings
              buttondDetail="Web3 & Blockchain"
              categoryType="web3"
              description="2030: from technology optimism to technology realism | WEForum.org"
            />

            <div className="newsissuedetailed">
              <div className="detailed-common">
                {/* <div className="back-to-common">
                            <span className="back-to-icon-hold">
                                <img src="/assets/img/resource/back-to-new.svg" alt="bact to new"></img>
                            </span>
                            <Link to={ResourceRoute.NEWSLETTER}><span className="back-to-link">Newsletter</span></Link>
                        </div> */}

                <div className="news-letter whats-on-hold">
                  <div className="issue-main-head">
                    <h4>What’s on?</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.fastcompany.com/90440198/san-diegos-massive-7-year-experiment-with-facial-recognition-technology-appears-to-be-a-flop"
                      >
                        <h4>
                          San Diego’s massive, 7-year experiment with facial
                          recognition technology appears to be a flop | Fast
                          Company
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        At the stroke of midnight on December 31, the City of
                        San Diego’s long experiment with facial recognition
                        technology came to an abrupt end. For seven years,
                        police had used a sophisticated network of as many as
                        1,300 mobile cameras (smartphones and tablets) and
                        compiled a database of some 65,500 face scans—placing
                        California’s second-largest city at the center of a
                        national debate about surveillance, privacy concerns,
                        and algorithmic bias
                      </p>
                    </div>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.thehindubusinessline.com/info-tech/blockchain-as-a-service-baas-in-government-services-to-get-a-boost-through-coe/article30594744.ece"
                      >
                        <h4>
                          Blockchain-as-a-Service (BaaS) in government services
                          to get a boost through CoE | Business Line
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        The Centre of Excellence (CoE) in Blockchain Technology,
                        which opened in Bengaluru, will focus on building a
                        robust infrastructure to provide Blockchain-as-a-Service
                        (BaaS).The CoE has developed Blockchain-based Proof of
                        Concepts (PoCs) for select government use-cases to
                        understand the potential benefits that this emerging
                        technology offers.{" "}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="news-letter top-picks-hold">
                  <div className="issue-main-head">
                    <h4>Top Picks</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.weforum.org/agenda/2020/01/decade-of-action-from-technology-optimism-to-technology-realism/"
                      >
                        <h4>
                          2030: from technology optimism to technology realism |
                          WEForum.org{" "}
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Today’s technological revolution is a time of enormous
                        promise, but also new challenges. As we enter the 2020s
                        it is clear that we are far from unlocking the potential
                        of technology for our toughest challenges. We are
                        entering a new era where powerful Fourth Industrial
                        Revolution technologies like artificial intelligence
                        (AI) are being infused at exponential speed into the
                        world around us. As organizations and countries race to
                        harness these new technologies to spur growth and
                        competitiveness, we stand at a critical juncture to put
                        these technologies to work in a responsible way for
                        people and the planet.
                      </p>
                    </div>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.wired.com/story/bezos-phone-hack-mbs-saudi-arabia/#intcid=recommendations_wired-homepage-right-rail-popular_05cad328-48e2-414b-97c9-28e09f20e08c_popular4-1"
                      >
                        <h4>
                          Everything We Know About the Jeff Bezos Phone Hack |
                          Wired{" "}
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        In May of 2018, Amazon CEO Jeff Bezos received an
                        unexpected text on WhatsApp from Saudi Arabian leader
                        Mohammed bin Salman: a video file that reportedly
                        extolled Saudi Arabia’s economy. But what Bezos didn't
                        know was that the video file was loaded with malware.
                        According to the UN’s findings, the Saudi regime began
                        exfiltrating large amounts of data from the phone within
                        hours of sending the tainted MP4 video file. The attack
                        raises questions about others who communicate with the
                        crown prince—like President Donald Trump, who is known
                        for using personal, consumer-grade smartphones.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="news-letter other-reads-hold">
                  <div className="issue-main-head">
                    <h4>Other Reads</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://thenextweb.com/syndication/2020/01/20/australian-bushfires-didnt-just-destroy-specific-species-but-entire-ecosystems/"
                      >
                        <h4>
                          Australian bushfires didn’t just destroy specific
                          species, but entire ecosystems | The Next Web{" "}
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        The sheer scale and intensity of the Australian bushfire
                        crisis have led to apocalyptic scenes making the front
                        pages of newspapers the world over. An estimated 10
                        million hectares (100,000 sq km) of land have burned
                        since 1 July 2019. At least 28 people have died. And
                        over a billion animals are estimated to have been killed
                        to date. Of course, the actual toll will be much higher
                        if major animal groups, such as insects, are included in
                        these estimates.The scientific evidence shows that
                        human-caused climate change is a key driver of the rapid
                        and unprecedented increases in wildfire activity. What
                        is particularly worrying is the extent to which this is
                        eroding the resilience of ecosystems across wide
                        regions. Yes, it is plausible to expect most plants and
                        animals that have adapted to fire will recover.
                      </p>
                    </div>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.wired.com/story/bezos-phone-hack-mbs-saudi-arabia/#intcid=recommendations_wired-homepage-right-rail-popular_05cad328-48e2-414b-97c9-28e09f20e08c_popular4-1"
                      >
                        <h4>
                          Everything We Know About the Jeff Bezos Phone Hack |
                          Wired{" "}
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        In May of 2018, Amazon CEO Jeff Bezos received an
                        unexpected text on WhatsApp from Saudi Arabian leader
                        Mohammed bin Salman: a video file that reportedly
                        extolled Saudi Arabia’s economy. But what Bezos didn't
                        know was that the video file was loaded with malware.
                        According to the UN’s findings, the Saudi regime began
                        exfiltrating large amounts of data from the phone within
                        hours of sending the tainted MP4 video file. The attack
                        raises questions about others who communicate with the
                        crown prince—like President Donald Trump, who is known
                        for using personal, consumer-grade smartphones.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <NewsSubscription categoryType="Web3" />
          </div>
        );
    }

}

export default NewsIssue11;
