import React from 'react';
import "../../resources.css";
import {ResourceRoute} from '../../resource-routing';
import {Link} from "react-router-dom";
import NewsHeadings from "./common-action/news-heading";
import NewsSubscription from "./common-action/subscripbe-section";

class NewsIssue30 extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            pageEnabled: false,
        }

    }

    render() {
        return (
          <div className="newdesigned_issue_details">
            <NewsHeadings
              buttondDetail="Design"
              categoryType="design"
              description="How To Test A Design Concept For Effectiveness | Smashing Magazine"
            />
            <div className="newsissuedetailed">
              <div className="detailed-common">
                {/* <div className="back-to-common">
                    <span className="back-to-icon-hold">
                    <img src="/assets/img/resource/back-to-new.svg"  alt="bact to new"></img>
                    </span>
                    <Link  to={ResourceRoute.NEWSLETTER}><span className="back-to-link">Newsletter</span></Link>
                </div> */}

                <div className="news-letter whats-on-hold">
                  <div className="issue-main-head">
                    <h4>What’s on?</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.geekwire.com/2020/slack-expands-amazon-partnership-will-switch-aws-chime-infrastructure-user-calls/"
                      >
                        <h4>
                          Slack expands Amazon partnership, will switch to AWS
                          Chime infrastructure for user calls | Geek Wire{" "}
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Slack will shift to Amazon Web Services’ Chime
                        infrastructure for voice and video calls on its platform
                        under an expanded partnership announced by the companies
                        Thursday afternoon.In addition, Amazon has licensed
                        Slack for use across all of its businesses, Slack CEO
                        Stewart Butterfield said as he announced record
                        quarterly results.
                      </p>
                    </div>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.google.com/url?q=https://www.iotforall.com/role-ai-shaping-future-mobile-app-development/&source=gmail&ust=1599233243533000&usg=AFQjCNHyAmuBNBDKGet5TPr-Z-xVLWiibQ"
                      >
                        <h4>
                          The Role of AI in Shaping the Future of Mobile
                          Application Development | IoT for All
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p></p>
                    </div>
                  </div>
                </div>
                <div className="news-letter top-picks-hold">
                  <div className="issue-main-head">
                    <h4>Top Picks</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.smashingmagazine.com/2020/06/test-design-concept-effectiveness/"
                      >
                        <h4>
                          How To Test A Design Concept For Effectiveness |
                          Smashing Magazine
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Getting a client or stakeholder to approve a design
                        concept can be challenging. However, testing can make it
                        easier, as well as ensuring you have the right solution.
                        Most of us are reasonably comfortable with the idea of
                        carrying out usability testing on a website or
                        prototype. We don’t always get the opportunity, but most
                        people accept that it is a good idea. And,when it comes
                        to a design concept, opinion is more divided. Some
                        designers feel it undermines their role, a view that
                        seems to be somewhat backed up by the famous “Forty
                        Shades of Blue” episode, where Google tested which one
                        of forty shades of blue to use for link color.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="news-letter other-reads-hold">
                  <div className="issue-main-head">
                    <h4>Other Reads</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.technologyreview.com/2020/06/02/1002505/black-lives-matter-protest-misinformation-advice/"
                      >
                        <h4>
                          How to protect yourself online from misinformation
                          right now | The Hindu
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        There wasn’t a communications blackout in Washington,
                        DC, on Sunday, but #dcblackout trended on Twitter
                        anyway, thanks to some extremely distressing tweets
                        telling people that, mysteriously, no messages were
                        getting out from the nation’s capital. The tweets,
                        Reddit posts, and Facebook messages about the “blackout”
                        got thousands of shares, fueled by pleas to spread the
                        information widely and ominous warnings about what would
                        happen next to protesters.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <NewsSubscription categoryType="Design" />
          </div>
        );
    }

}

export default NewsIssue30;
