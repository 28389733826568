import React from 'react';
import "../../resources.css";
import {ResourceRoute} from '../../resource-routing';
import {Link} from "react-router-dom";

class NovemberFortnight2 extends React.Component {
    constructor(props){
        super(props)

    }
    render(){
        return (
            <div className="BlockchainDetailed">
                                                <div className="back-to-common">
                    <span className="back-to-icon-hold">
                        <img src="/assets/img/resource/back-to-new.svg"  alt="bact to new"></img>
                    </span>
                    <Link  to={ResourceRoute.BLOCKCHAIN}><span className="back-to-link">Blockchain Bulletin</span></Link>
                </div>
                <div className="download-common inner-section-1">
                    <div>
                    {/* <div className="col-xl-7 col-lg-7 col-md-7 col-sm-12"> */}
                        <div className="main-head-div">
                            <h4 className="main-head-1">
                                <span className="primary-span">November Fortnight 2 Round-up Blockchain & Crypto Brief</span>
                            </h4>
                            <p className="press-info">( November 28, 2019 )</p>
                        </div>
                    </div>    
                </div>
                <div class="bulletin-head-details bulletin-sub-heads">
                    <h4>
                        <span className="primary-span">Finance</span>
                    </h4>
                    <ul className="bulletin-links-ul">
                        <li>
                            <a href="https://cointelegraph.com/news/canadas-largest-bank-mulls-crypto-exchange-after-bitcoin-ban-report" target={'_blank'}>Canada’s largest bank, the Royal Bank of Canada (RBC), is contemplating launching a cryptocurrency trading platform.</a>
                        </li>
                        <li>
                            <a href="https://www.coindesk.com/investment-app-abra-expands-us-offerings-with-60-new-cryptos" target={'_blank'}>Investment app Abra has announced that its users in the US will now be able to deposit and withdraw 60 new cryptocurrencies, totalling to nearly 100 and the users outside of the US will ​ soon ​ be able to invest in more than additional 200 cryptocurrencies.</a>
                        </li>
                        <li>
                            <a href="https://www.fintechfutures.com/2019/11/crypto-bank-seba-launches-swiss-offering-after-finma-greenlight/" target={'_blank'}>Switzerland-based cryptocurrency bank is now fully operational after receiving a banking license from Swiss Financial Market Supervisory Authority (FINMA) in August 2019.</a>
                        </li>
                        <li>
                            <a href="https://www.coindesk.com/data-provider-messari-closes-4-million-funding-round" target={'_blank'}>Crypto research startup Messari has announced closing a $4 million funding round, led by Uncork Capital with participation from CoinFund, Blockchain Capital, Fenbushi Capital, Danhua Capital, and former Coinbase CTO Balaji Srinivasan.</a>
                        </li>
                        <li>
                            <a href="https://www.coindesk.com/fake-website-prompts-chinas-central-bank-to-deny-digital-yuan-launch" target={'_blank'}>The People’s Bank of China, the country’s central bank, has released an official statement that says that they are still researching and testing digital currency and that the reports of launch are ‘fraudulent.’</a>
                        </li>
                        <li>
                            <a href="https://www.theblockcrypto.com/linked/47041/bitski-raises-1-8m-seed-round-to-develop-crypto-wallet-infrastructure" target={'_blank'}>Bitski, a developer-focused blockchain wallet infrastructure provider, has announced raising a $1.8 million seed round from investors such as Coinbase, Winklevoss Capital, and Galaxy Digital.</a>
                        </li>
                        <li>
                            <a href="https://in.yahoo.com/?p=us" target={'_blank'}>Binance is planning to support all of the global 180 fiat currencies for its direct fiat-to-crypto trading facility.</a>
                        </li>
                        <li>
                            <a href="https://news.yahoo.com/alibaba-ant-financial-starts-pre-103605765.html" target={'_blank'}>Alibaba has launched the testing stage for its blockchain network AntFinancial that aims to support small and medium-sized businesses.</a>
                        </li>
                        <li>
                            <a href="https://www.prnewswire.com/news-releases/walmart-canada-and-dlt-labs--launch-worlds-largest-full-production-blockchain-solution-for-industrial-application-300958198.html" target={'_blank'}>Walmart Canada and DLT Labs have come together to launch a full production blockchain for industrial application.</a>
                        </li>
                    </ul>
                </div>
                <div class="bulletin-head-details bulletin-sub-heads">
                    <h4>
                        <span className="primary-span">Other</span>
                    </h4>
                    <ul className="bulletin-links-ul">
                        <li>
                            <a href="https://www.theblockcrypto.com/daily/46787/fidelity-hires-a-product-specialist-to-focus-on-the-big-picture-for-its-crypto-operations" target={'_blank'}>Fidelity Investments has hired a new product specialist, Michael Zinaman to focus on the ‘big picture’ for its crypto operations.</a>
                        </li>
                        <li>
                            <a href="https://www.theblockcrypto.com/daily/46479/two-rival-trading-firms-have-sprung-out-of-circle-trade-which-faces-an-uncertain-future" target={'_blank'}>Former employees of Circle Trade, which is facing an uncertain future, have launched two new rival cryptocurrency trading firms.</a>
                        </li>
                        <li>
                            <a href="https://techcrunch.com/2019/03/07/etoro-bringing-crypto-trading-and-wallet-to-the-us/" target={'_blank'}>The world’s leading social trading and investing platform eToro has opened its popular CopyTraderTM feature in the U.S. The feature enables the customers to copy their favorite eToro users trades in real time.</a>
                        </li>
                        <li>
                            <a href="https://www.coindesk.com/bakkt-to-launch-crypto-consumer-app-in-first-half-of-2020" target={'_blank'}>https://www.businessfast.co.uk/bitfinex-looks-to-launch-options-trading-as-soon-as-q1-2020-investing-com/</a>
                        </li>
                        <li>
                            <a href="https://www.coindesk.com/htc-will-support-binance-chain-with-special-edition-smartphone" target={'_blank'}>Binance and HTC are coming together to launch new crypto smartphones that support Binance chain.</a>
                        </li>
                        <li>
                            <a href="https://www.coindesk.com/chinas-new-policy-isnt-an-automatic-bitcoin-mining-ban-heres-why" target={'_blank'}>Bitcoin mining is no longer illegal in China.</a>
                        </li>
                        <li>
                            <a href="https://finance.yahoo.com/news/china-official-army-newspaper-says-095536290.html" target={'_blank'}>Chinese military could adopt the blockchain technology and reward soldiers in tokens, a newspaper says.</a>
                        </li>
                        <li>
                            <a href="https://www.theblockcrypto.com/linked/47513/japans-central-bank-researching-digital-currencies-says-governor" target={'_blank'}>Japan is researching digital currencies. The Bank of Japan Deputy Governor Masayoshi Amamiya says that cryptocurrency operators must comply with rules.</a>
                        </li>
                        <li>
                            <a href="https://www.theblockcrypto.com/linked/47513/japans-central-bank-researching-digital-currencies-says-governor" target={'_blank'}>Whinstone US and Northern Bitcoin AG have merged to set up a 100 acre bitcoin mining farm with a capacity of 1 gigawatt - the world’s largest bitcoin mining facility - in Texas.</a>
                        </li>
                        <li>
                            <a href="https://www.theblockcrypto.com/linked/46196/bitmains-ousted-co-founder-micree-zhan-says-he-will-take-legal-action-to-return-to-the-firm" target={'_blank'}>https://www.theblockcrypto.com/linked/48383/ripple-made-another-20m-investment-in-moneygram-to-complete-its-50m-commitment</a>
                        </li>
                    </ul>
                </div>
            </div>        
        );
    }

}

export default NovemberFortnight2;