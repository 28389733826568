import React from 'react';
import "../../resources.css";
import {ResourceRoute} from '../../resource-routing';
import {Link} from "react-router-dom";
import NewsHeadings from "./common-action/news-heading";
import NewsSubscription from "./common-action/subscripbe-section";

class NewsIssue65 extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            pageEnabled: false,
        }

    }

    render() {
        return (
          <div className="newdesigned_issue_details">
            <NewsHeadings
              buttondDetail="Web3 & Blockchain"
              categoryType="web3"
              description="Blockchain firm aims to build crypto city in Nevada desert | Coin Telegraph"
            />
            <div className="newsissuedetailed">
              <div className="detailed-common">
                {/* <div className="back-to-common">
                    <span className="back-to-icon-hold">
                        <img src="/assets/img/resource/back-to-new.svg"  alt="back to new"></img>
                    </span>
                    <Link  to={ResourceRoute.NEWSLETTER}><span className="back-to-link">Newsletter</span></Link>
                </div> */}

                <div className="news-letter whats-on-hold">
                  <div className="issue-main-head">
                    <h4>What’s on?</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://arstechnica.com/gadgets/2021/02/apple-starts-hiring-engineers-to-work-on-6g-modems/"
                      >
                        <h4>
                          Apple is already working on developing 6G wireless
                          technology| ARS Technica
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Apple introduced its first 5G iPhones in the iPhone 12
                        lineup late last year, and those phones use Qualcomm
                        modems. But recent reports have already revealed that
                        Apple plans to design its own modems so it can drop the
                        Qualcomm components from future phones, just as it is
                        developing its own silicon for Macs now to ultimately
                        replace Intel chips in most of the product lineup.
                      </p>
                    </div>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.aftermarketnews.com/bosch-and-microsoft-to-develop-software-defined-vehicle-platform/"
                      >
                        <h4>
                          Bosch, Microsoft To Develop Software-Defined Vehicle
                          Platform | AfterMarketNews.com
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Bosch teams up with Microsoft to develop a software
                        platform to seamlessly connect cars to the cloud. The
                        goal of this collaboration is to simplify and accelerate
                        the development and deployment of vehicle software
                        throughout a car’s lifetime in accordance with
                        automotive quality standards. Collaboration brings
                        together automotive and cloud computing expertise to
                        shape the next generation of vehicle software.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="news-letter top-picks-hold">
                  <div className="issue-main-head">
                    <h4>Top Picks</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://cointelegraph.com/news/blockchain-firm-aims-to-build-crypto-city-in-nevada-desert"
                      >
                        <h4>
                          Blockchain firm aims to build crypto city in Nevada
                          desert | Coin Telegraph
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Blockchain incubator and investment firm Blockchains LLC
                        is stepping up its plans to build a smart city in the
                        Nevada desert east of Reno. In an interview with the
                        Associated Press released Saturday, Blockchains CEO
                        Jeffrey Berns said he had asked the state of Nevada to
                        let him form a local government on the 67,000 acres the
                        firm owns in Storey County.{" "}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="news-letter other-reads-hold">
                  <div className="issue-main-head">
                    <h4>Other Reads</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.nbcnews.com/think/opinion/gamestop-trades-meme-investing-make-stocks-ponzi-scheme-hurts-little-ncna1257680"
                      >
                        <h4>
                          GameStop trades and meme investing turn stocks into a
                          pump-and-dump scheme | NBC News
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        The hedge funds lost billions of dollars in just a few
                        weeks. So far so good. But the danger was just beginning
                        for internet-based consumers. Those who bought in early
                        and sold during the highs did very well. But those who
                        bought in later, at prices that were $200 or more, found
                        themselves out of luck. The price suddenly crashed, and,
                        as of Friday, GameStop stood at $51, leaving individual
                        investors, many of whom are younger, with thousands of
                        dollars in losses.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <NewsSubscription categoryType="Web3" />
          </div>
        );
    }

}

export default NewsIssue65;
