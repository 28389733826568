import React from 'react';
import "../../resources.css";
import {ResourceRoute} from '../../resource-routing';
import {Link} from "react-router-dom";
import NewsHeadings from "./common-action/news-heading";
import NewsSubscription from "./common-action/subscripbe-section";

class NewsIssue64 extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            pageEnabled: false,
        }

    }

    render() {
        return (
          <div className="newdesigned_issue_details">
            <NewsHeadings
              buttondDetail="Design"
              categoryType="design"
              description="The Future Of Customer Experience: Zero User Interfaces | Security Boulevard"
            />
            <div className="newsissuedetailed">
              <div className="detailed-common">
                {/* <div className="back-to-common">
                    <span className="back-to-icon-hold">
                        <img src="/assets/img/resource/back-to-new.svg"  alt="back to new"></img>
                    </span>
                    <Link  to={ResourceRoute.NEWSLETTER}><span className="back-to-link">Newsletter</span></Link>
                </div> */}

                <div className="news-letter whats-on-hold">
                  <div className="issue-main-head">
                    <h4>What’s on?</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.bloomberg.com/news/articles/2021-02-10/mastercard-to-allow-some-cryptocurrencies-in-digital-asset-boom"
                      >
                        <h4>
                          Mastercard to Allow Some Crypto in Digital Asset Boom
                          | Bloomberg.com{" "}
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Mastercard Inc. will begin allowing cardholders to
                        transact in certain cryptocurrencies on its network,
                        becoming the latest company to embrace digital assets.
                        The firm is also “actively engaging” with central banks
                        around the world on their plans to launch new digital
                        currencies, according to a Mastercard blog post on
                        Wednesday. The company will prioritize consumer
                        protections and compliance in its own plans, Raj
                        Dhamodharan, executive vice president of digital asset
                        and blockchain products and partnerships, said in the
                        post.{" "}
                      </p>
                    </div>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://news.bitcoin.com/apple-cryptocurrency-trading-bitcoin-treasury/"
                      >
                        <h4>
                          Apple Well Positioned to Offer Crypto Trading —
                          Analyst Says Company Should Hold Bitcoin in Treasury |
                          Techcrunch{" "}
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Apple Well Positioned to Offer Crypto Trading — Analyst
                        Says Company Should Hold Bitcoin in Treasury |
                        Techcrunch{" "}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="news-letter top-picks-hold">
                  <div className="issue-main-head">
                    <h4>Top Picks</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.forbes.com/sites/forbestechcouncil/2021/02/09/the-future-of-customer-experience-zero-user-interfaces/?sh=1c2f90a75e24"
                      >
                        <h4>
                          The Future Of Customer Experience: Zero User
                          Interfaces | Security Boulevard
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Customer experience stands to be a major transformative
                        element across disciples; from retail to telehealth, to
                        education to manufacturing, every industry is demanding
                        a unique customer experience (CX) strategy. Many
                        companies are taking steps to adjust their CX toward the
                        future and are getting ready to rebuild their CX
                        strategy. According to findings from Capgemini, 77% of
                        customers expect nontouch interactions for customer
                        experiences. More than 62% expect the same touchless
                        experience even after the current pandemic subsides.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="news-letter other-reads-hold">
                  <div className="issue-main-head">
                    <h4>Other Reads</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.barrons.com/amp/articles/not-just-tesla-why-big-companies-are-buying-into-crypto-mania-51613069805"
                      >
                        <h4>
                          Not Just Tesla: Why Big Companies are Buying into
                          Crypto-Mania | The New York Times{" "}
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        For months, there has been a consistent trickle of news
                        about mainstream businesses getting involved in
                        cryptocurrencies. In the past week, it has turned into a
                        flood, helping to push the price of Bitcoin to a record
                        of $48,297 on Thursday. The most buzzworthy move came
                        from Tesla (ticker: TSLA), which disclosed on Monday
                        that it has bought $1.5 billion worth of Bitcoin to hold
                        on its balance sheet. The company plans to let consumers
                        use the currency to pay for cars.{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <NewsSubscription categoryType="Design" />
          </div>
        );
    }

}

export default NewsIssue64;
