import React from 'react';
import "../../resources.css";
import { ResourceRoute } from '../../resource-routing';
import { Link } from "react-router-dom";
import NewsHeadings from "./common-action/news-heading";
import NewsSubscription from "./common-action/subscripbe-section";

class NewsIssue06 extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            pageEnabled: false,
        }

    }

    render() {
        return (
          <div className="newdesigned_issue_details">
            <NewsHeadings
              buttondDetail="Web3 & Blockchain"
              categoryType="web3"
              description="What Happens After Prisoners Learn to Code? | The Atlantic"
            />

            <div className="newsissuedetailed">
              <div className="detailed-common">
                {/* <div className="back-to-common">
                    <span className="back-to-icon-hold">
                        <img src="/assets/img/resource/back-to-new.svg"  alt="bact to new"></img>
                    </span>
                    <Link  to={ResourceRoute.NEWSLETTER}><span className="back-to-link">Newsletter</span></Link>
                </div> */}

                <div className="news-letter whats-on-hold">
                  <div className="issue-main-head">
                    <h4>What’s on?</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        href="https://bitcoinist.com/social-media-giants-see-bitcoin-technology-as-empowering/"
                        target={"_blank"}
                      >
                        <h4>
                          BigTech companies see Bitcoin Technology as empowering
                          | Bitcoinist
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Since Bitcoin’s inception, many in the financial and
                        technology sectors saw Bitcoin and its blockchain
                        technology as a threat. And, their first reaction was to
                        oppose it vehemently. Inevitably, however, the concept
                        of a decentralized Internet triggered by Satoshi
                        Nakamoto is now increasingly driving technological
                        innovation in all industrial and financial sectors.
                      </p>
                    </div>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.cnbc.com/2019/12/16/china-looks-to-become-blockchain-world-leader-with-xi-jinping-backing.html"
                      >
                        <h4>
                          With Xi’s backing, China looks to become a world
                          leader in blockchain as US policy is absent | CNBC{" "}
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        China is poised to take the lead in blockchain after it
                        was given strong backing by the country’s leader
                        President Xi Jinping, experts told CNBC. The move could
                        allow the world’s second-largest economy to control the
                        development of the nascent technology in the absence of
                        competition from other regions like Europe and the U.S.
                      </p>
                    </div>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.coindesk.com/htc-to-increase-focus-on-blockchain-phones-ai-after-new-staff-cuts"
                      >
                        <h4>
                          HTC to Increase Focus on Blockchain Phones, AI After
                          New Staff Cuts | Coindesk{" "}
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        HTC, which has around 3,000 employees, has not disclosed
                        the scale of the lay-offs or the departments that will
                        be most affected, according to a report from Taiwan
                        News. This will be the third round of job cuts staff at
                        the phone manufacturer have faced in the past five
                        years. The company let go 2,250 employees in 2015, and
                        cut a further 1,500 in July 2018.{" "}
                      </p>
                    </div>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.theguardian.com/technology/2019/dec/14/twenty-tech-trends-for-2020-tesla-cybertruck-iphone-12-5g-nintendo-vr-ai-amazon"
                      >
                        <h4>Twenty tech trends for 2020 | The Guardian</h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        From new gaming consoles to activism at Apple, The
                        Guardian Tech predict the things you will – or won’t –
                        see in tech in 2020{" "}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="news-letter top-picks-hold">
                  <div className="issue-main-head">
                    <h4>Top Picks</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.theatlantic.com/technology/archive/2019/12/from-prison-to-silicon-valley/603406/"
                      >
                        <h4>
                          What Happens After Prisoners Learn to Code? | The
                          Atlantic{" "}
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        A very 2019 fairytale - Slack, one of Silicon Valley’s
                        more diverse companies, has hired three formerly
                        incarcerated coders.
                      </p>
                    </div>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a target={"_blank"} href="https://designcensus.org/">
                        <h4>
                          State of the Art Industry - Results from Design Census
                          2019 | Design Census{" "}
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Curious about your fellow designers? Read up on the
                        results of the 2019 state-of-the-industry Design Census,
                        made in collaboration with AIGA Eye on Design.
                        Understanding the state of design and the people who
                        make it. Explore the results of this year’s survey,
                        available as raw data and data visualizations by
                        visiting the link
                      </p>
                    </div>
                  </div>
                </div>
                <div className="news-letter other-reads-hold">
                  <div className="issue-main-head">
                    <h4>Other Reads</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.nytimes.com/2019/12/20/opinion/india-citizenship-law-protests.html"
                      >
                        <h4>India Awakens to Fight for Its Soul | NY Times </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Prime Minister Narendra Modi of India and his Hindu
                        nationalist Bharatiya Janata Party government have
                        deployed propaganda very successfully since coming to
                        power in 2014. So much so that numerous egregious
                        decisions the government has made — demonetization,
                        electoral bonds allowing secret donations to political
                        parties, a flawed citizens registry in the state of
                        Assam, revoking the autonomy of Jammu and Kashmir — were
                        accepted by a majority of people. But over the past few
                        days, India seems to have risen up in countrywide mass
                        protests. A growing awareness of what the recently
                        passed Citizenship Amendment Act means for the country
                        seems to have jolted people, bringing them into the
                        streets.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <NewsSubscription categoryType="Web3" />
          </div>
        );
    }

}

export default NewsIssue06;
