import React, { lazy, useEffect, useState } from "react";
import Header from "../../components/header/header";
import "./landing.css";
import { Route, withRouter } from "react-router-dom";
import { ROUTES } from "../../routes";
import Technology from "../technology/technology";
import Startup from "../startup/startup";
import MarketPlace from "../market-place/market-place";
import Enterprise from "../enterprise/enterprise";
import AboutUs from "../about-us/about-us";
import ContactUs from "../contact-us/contact-us";
// import Kyc from "../kyc/kyc";
import Portfolio from "../portfolio/portfolio";
// import Blockchain from "../blockchain/blockchain";
import Web3Blockchain from "../blockchain_new_version/blockchain";
// import Blockchain_New from "../blockchain_New/blockchain";
import SecondaryFooter from "../../components/secondary-footer/secondary-footer";
import SideMenu from "../../components/menu";
import SwitchWithSlide from "../../components/switch-with-slide";
import HomeDesign from "../home-design/home-design";
import Design from "../design";

import { ResourceRoute } from "../../pages/resources/resource-routing";
import BlockchainGuide from "../../pages/resources/internal-components/whitePapers/blockchain-guide";
import DataAggregation from "../../pages/resources/internal-components/whitePapers/data-aggregation";
import MultiExchangeCryptoCurrency from "../../pages/resources/internal-components/whitePapers/multi-exchange-cryptocurrency";
import Healthcare from "../health-care/health-care";
//import Healthcarecontest from "../health-care-contest/health-care-contest";
import { PortfolioRoute } from "../portfolio/route-porfolio";
import FinTech from "../fin-tech/fin-tech.js";
import CapitalMarket from "../fin-tech/capital-markets/capital-market.js";
import TradeAutomation from "../fin-tech/trade-automation/trade-automation.js";
import TradeSurveillance from "../fin-tech/trade-surveillance/trade-surveillance.js";
import ToastComponent from "../../components/common/toast/toast";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as contactAction from "../../action/contactAction";
import PageNotFound from "../../pages/pageNotFound/pageNotFound";
import Dvs from "../../pages/portfolio/design-portfolio/dvs.js";
import GaadiBazar from "../../pages/portfolio/design-portfolio/gaadi-bazar.js";
import Tenshey from "../../pages/portfolio/design-portfolio/tenshey.js";
import P2E from "../../pages/portfolio/design-portfolio/p2e.js";
import Kyc from "../../pages/portfolio/design-portfolio/kyc.js";
// import CryptoDesign from "../crypto-design/crypto-design.js";
import LoanProcessing from "../../pages/portfolio/design-portfolio/loan-processing.js";
// import { Toast } from 'bootstrap';
import CaseStudyP2e from "../../pages/portfolio/internal-components/caseStudyRedirection/caseStudyP2e";
import caseStydyDriveWealth from "../portfolio/internal-components/caseStudyRedirection/caseStudyDriveWealth";
import CaseStudyTenshey from "../../pages/portfolio/internal-components/caseStudyRedirection/caseStudyTenshey";
import CaseStudyGaddiBazar from "../../pages/portfolio/internal-components/caseStudyRedirection/caseStudyGaadiBazar";
import CaseStudyDubbuku from "../../pages/portfolio/internal-components/caseStudyRedirection/caseStudyDubuku";
import CaseStudyCryptoSapce from "../../pages/portfolio/internal-components/caseStudyRedirection/caseStudyCryptoMarket";
import CryptoOTC from "../crypto-otc/crypto-otc";
import ALGOBOTVERSION_1 from "../algo-bot-version1/algo-bot";
import ALGOBOT from "../algo-bot/algo-bot";
import ALGOBOTDEVELOPMENT from "../algo-bot-development/algo-bot-development";
import CryptoOtcDevelopment from "../crypto-otc-development/crypto-otc-development";
import OtcSolution from "../otc-solution/otc-solution";
import algoBotDevelopment from "../algo-bot-development/algo-bot-development";
import algoBot from "../algo-bot/algo-bot";

import algoBotwithForm from "../algo-bot-withform/algo-bot";
import cryptoOTCwithForm from "../crypto-otc-withform/crypto-otc";
import algoBotwithFormDevelopment from "../algo-bot-withform-development/algo-bot";
import cryptoOTCwithFormDevelopment from "../crypto-otc-withform-development/crypto-otc";
import Careers from "../careers/career";
// import Brokerage from '../fin-tech/brokerage/brokerage';
import NewHomeDesign from "../new-home-design/new-home-design";
import TalkToUs from "../talk-to-us/talk-to-us";
import digitalBrokerage from "../fin-tech/digital-brokerage/digital-brokerage";
import OMS from "../oms/oms";
import postTrade from "../post-trade/post-trade";
import ThankYouComponent from "../../components/thank-you/thank-you";
import ThankYouDemoComponent from "../../components/thank-you/thank-you-demo-call";
import resources from "../resources/resources";
import Events from "../resources/events-news/events-news";
import WhitepaperEbook from "../resources/whitepaper-ebook/whitepaper-ebook";
import NewsletterComponent from "../resources/newsletter-component/newsletter-component";
import BlockChainContractsSmallContracts from "../resources/internal-components/whitePapers/ebooks/blockChainContractsSmallContracts";
import BlockChainCryptoMining from "../resources/internal-components/whitePapers/ebooks/blockChainCryptoMining";
import BlockChainLeagalImplication from "../resources/internal-components/whitePapers/ebooks/blockChainLeagalImplication";
import BlockChainFundamentals from "../resources/internal-components/whitePapers/ebooks/blockChainFundamentals";
import ChallengeSecurityBlockChain from "../resources/internal-components/whitePapers/ebooks/challengsSecurityBlockChain";
import CryptoCurrenciesSoftwareProtocol from "../resources/internal-components/whitePapers/ebooks/cryptoCurrenciesSoftwareProtocols";
import FormationOfBlockChain from "../resources/internal-components/whitePapers/ebooks/formationOfBlockChain";
import MobileAppDevelopmentApproach from "../resources/internal-components/whitePapers/ebooks/mobileAppDevelopmentApproach";
import ModernPostTrade from "../modern-post-trade/modern-post-trade";
import PostTradeService from "../post-trade-services/post-trade-service";
import DefiService from "../defi-landing/defi-landing.js";
import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";

// import CookieBot from 'react-cookiebot';

import {
  NewsLetter,
  NewsIssue83,
  NewsIssue82,
  NewsIssue81,
  NewsIssue80,
  NewsIssue79,
  NewsIssue78,
  NewsIssue77,
  NewsIssue76,
  NewsIssue75,
  NewsIssue74,
  NewsIssue73,
  NewsIssue72,
  NewsIssue71,
  NewsIssue70,
  NewsIssue69,
  NewsIssue68,
  NewsIssue67,
  NewsIssue66,
  NewsIssue65,
  NewsIssue64,
  NewsIssue63,
  NewsIssue62,
  NewsIssue61,
  NewsIssue60,
  NewsIssue59,
  NewsIssue58,
  NewsIssue57,
  NewsIssue56,
  NewsIssue55,
  NewsIssue54,
  NewsIssue53,
  NewsIssue52,
  NewsIssue51,
  NewsIssue50,
  NewsIssue49,
  NewsIssue48,
  NewsIssue47,
  NewsIssue46,
  NewsIssue45,
  NewsIssue44,
  NewsIssue43,
  NewsIssue42,
  NewsIssue41,
  NewsIssue40,
  NewsIssue39,
  NewsIssue38,
  NewsIssue37,
  NewsIssue36,
  NewsIssue35,
  NewsIssue34,
  NewsIssue33,
  NewsIssue32,
  NewsIssue31,
  NewsIssue30,
  NewsIssue29,
  NewsIssue28,
  NewsIssue27,
  NewsIssue26,
  NewsIssue25,
  NewsIssue24,
  NewsIssue23,
  NewsIssue22,
  NewsIssue21,
  NewsIssue20,
  NewsIssue19,
  NewsIssue18,
  NewsIssue17,
  NewsIssue16,
  NewsIssue15,
  NewsIssue14,
  NewsIssue13,
  NewsIssue12,
  NewsIssue11,
  NewsIssue10,
  NewsIssue09,
  NewsIssue08,
  NewsIssue07,
  NewsIssue06,
  NewsIssue05,
  NewsIssue04,
  NewsIssue03,
  NewsIssue02,
  NewsIssue01,
  NewsIssue84,
  NewsIssue85,
  NewsIssue86,
} from "../resources/internal-components/news";
import NeuronComponent from "../neuron/neuron";
import DesignStudioComponent from "../design-studio/design-studio.js";
import DesignProcessComponent from "../design-studio/design-process/design-process.js";
import OurWorkComponent from "../design-studio/our-work/our-work.js";
class Landing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      headerClassAdd: "",
      isMenuOpen: false,
      showToast: false,
      downloadToast: false,
      subscribeToast: false,
      subscribeNow: false,
      email: "",
      emailErr: "",
    };
    this.wrapperRef = React.createRef();
    this.onScrollHeader = this.onScrollHeader.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }
  componentDidUpdate(prevProps) { }
  componentDidMount() {
    window.scroll(0, 0);
    window.addEventListener("scroll", this.onScrollHeader);
    window.addEventListener("scroll", this.showSubscribeCard);
    window.addEventListener("mousedown", this.handleClickOutside);
    // this.addHeaderBackground()
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.onScrollHeader);
    window.removeEventListener("scroll", this.showSubscribeCard);
    window.removeEventListener("mousedown", this.handleClickOutside);
    document.body.style.overflow = "unset";
  }

  //this is for setting the background for portfolio or other routes
  addHeaderBackground = () => {
    // http://localhost:3000/#/portfolio/p2e
    let path = this.props.location.pathname;
    let splittedArray = path.split("/").filter((value) => value != "");
    if (
      splittedArray[splittedArray.length - 2] ==
      ROUTES.PORTFOLIO.replace("/", "")
    ) {
      this.setState({
        headerClassAdd: "scrollHeader customHeader",
      });
    }
  };

  onScrollHeader() {
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;

    const height =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;

    const scrolled = winScroll / height;

    if (scrolled === 0) {
      this.setState({
        headerClassAdd: "",
      });
    } else {
      this.setState({
        headerClassAdd: "scrollHeader",
      });
      this.addHeaderBackground();
    }
  }

  menuOnClick = () => {
    this.setState(
      (prevState) => ({
        isMenuOpen: !prevState.isMenuOpen,
      }),
      () => {
        if (!this.state.isMenuOpen) {
          window.scroll(0, 0);
        }
        //for restricting scroll
        document.body.style.overflow = this.state.isMenuOpen
          ? "hidden"
          : "unset";
      }
    );
  };
  menuOnClose = () => {
    this.setState({ isMenuOpen: false }, () => {
      document.body.style.overflow = "unset";
    });
  };

  onFooterResourceSelected = () => {
    window.scroll(0, 5);
  };
  handleChange(e) {
    var val = e.target.value;
    val = val.replace(/^\s+/, "");
    this.setState({
      email: val,
    });
  }
  handleStateChange(state) {
    this.setState((prevState) => ({
      menuOpen: !prevState.menuOpen,
    }));
  }
  handleClickOutside(event) {
    if (
      document.getElementById("sub-float-div") ||
      document.getElementById("subscribeCard")
    ) {
      if (this.wrapperRef && !this.wrapperRef.current.contains(event.target)) {
        {
          this.setState({ subscribeNow: false });
        }
      }
    }
  }
  showSubscribe = () => {
    this.setState({ subscribeNow: true });
  };
  showSubscribeCard = () => {
    if (document.getElementById("subscribeCard")) {
      const windowTop = window.scrollY + 170;
      const cardTop = document.getElementById("subscribeCard").offsetTop;
      // const subscribeTop = document.getElementById('subscribeBottom').offsetTop - 380;
      if (windowTop > cardTop && windowTop > 600) {
        this.setState({ showCard: true });
      }
      // else if (subscribeTop < windowTop){
      //     this.setState({showCard : false});
      // }
      else {
        this.setState({ showCard: false });
      }
    }

    if (document.getElementById("mediaIcon")) {
      const scrollTop = window.scrollY + 350;
      const mediaTop = document.getElementById("mediaIcon").offsetTop;
      const subscribeFloat = document.getElementById("sub-float-div").offsetTop;
      // const subscribeTop = document.getElementById('subscribeBottom').offsetTop - 200;
      // console.log(scrollTop, mediaTop, subscribeFloat)
      if (scrollTop > mediaTop && scrollTop > 600 && scrollTop < 1050) {
        this.setState({ showMedia: true });
        this.setState({ showSubscribeFloat: false });
      }
      // else if (subscribeTop < scrollTop){
      //     this.setState({showMedia : false});
      //     this.setState({showSubscribeFloat: false});
      // }
      else if (scrollTop >= 1050) {
        this.setState({ showMedia: true });
        // this.setState({showMedia : false});
        this.setState({ showSubscribeFloat: true });
      } else if (scrollTop >= 1650) {
        this.setState({ showMedia: false });
        // this.setState({showMedia : false});
        this.setState({ showSubscribeFloat: false });
      } else {
        this.setState({ showMedia: false });
        this.setState({ showSubscribeFloat: false });
      }
    }
  };
  // For modal start
  state = {
    showModal: false
  };
  openModal = () => {
    this.setState({ showModal: true });
  }
  closeModal = () => {
    this.setState({ showModal: false });
  }
  // For modal end

  render() {
    return (
      <>
        {/* <CookieBot domainGroupId={'3fb7312c-b052-4ec2-9048-94b7b24a9a9d'} /> */}

        <div className="Home">
          <Header
            headerClassAdd={this.state.headerClassAdd}
            menuOnClick={this.menuOnClick}
            history={this.props.history}
          />
          <SideMenu
            showMenu={this.state.isMenuOpen}
            dismissMenu={this.menuOnClick}
            closeMenu={this.menuOnClose}
          />

          <Modal size="lg"
            dialogClassName="modal-form"
            show={this.state.showModal} onHide={this.closeModal}
            centered >
            <Modal.Header closeButton>
              <Modal.Title className="health-care-heading">
                Schedule a Meet
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <iframe
                width="100%"
                height="380px"
                src="https://calendly.com/shaan-ionixx/15min?hide_event_type_details=1&hide_gdpr_banner=1"
              ></iframe>
            </Modal.Body>
          </Modal>
          <section
            className={
              this.props.location.pathname === ResourceRoute.NEWSLETTER
                ? "section-new ppr-dnld-custom-sec beige-bg newsletter-wrap"
                : "section-new ppr-dnld-custom-sec beige-bg"
            }
          >
            <div className="custom-container">
              <div className="tab-content">
                {this.newsIssueRef?.state?.letftStickyContnet ? (
                  <div
                    className={
                      this.state.showCard
                        ? "organized_by sticky left-sticky"
                        : "organized_by"
                    }
                    onClick={() => {
                      this.newsIssueRef.handleShow();
                    }}
                  >
                    {this.newsIssueRef?.state?.letftStickyContnet}
                  </div>
                ) : (
                  ""
                )}
                {
                  // this.props.location.pathname === ResourceRoute.EVENTS ? "" : this.props.location.pathname === ResourceRoute.BLOCKCHAIN ? "" : this.props.location.pathname === ResourceRoute.WHITEPAPER ? "" : this.props.location.pathname === ResourceRoute.PRESS ? "" :
                  //     this.props.location.pathname === ResourceRoute.NEWSLETTER ? "":
                  this.props.location.pathname.includes(
                    "/resources/newsletter/"
                  ) ? (
                    <div
                      ref={this.wrapperRef}
                      className={
                        this.state.showCard
                          ? "inspired-newsletter web-news-letter sticky"
                          : "inspired-newsletter web-news-letter"
                      }
                      id="subscribeCard"
                    >
                      <p>Stay inspired with our newsletter</p>

                      <button onClick={this.showSubscribe}>Subscribe</button>
                      {this.state.subscribeNow && (
                        <div className="subscribe-our-news">
                          <div className="form-group newsletter-form-group">
                            <input
                              type="text"
                              className="form-control"
                              id=""
                              placeholder="Enter your email*"
                              name={"email"}
                              value={this.state.email}
                              onChange={this.handleChange.bind(this)}
                            ></input>
                            <div
                              className="newsletter-arrow"
                              onClick={() => {
                                var emailRegx =
                                  /^(([a-zA-Z\d-_.]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                                    this.state.email
                                  );
                                if (emailRegx === false) {
                                  this.setState({
                                    emailErr: "Invalid Email",
                                  });
                                } else {
                                  console.log("props", this.props);
                                  this.props
                                    .subscribeForNewsLetter({
                                      emailID: this.state.email,
                                      name: this.state.name,
                                      category: this.props.category,
                                      url: this.props.location.pathname,
                                    })
                                    .then((res) => { })
                                    .catch((err) => {
                                      console.log(err);
                                    });
                                  this.props
                                    .sendNewsFeed(this.state.email)
                                    .then((res) => {
                                      this.setState({
                                        email: "",
                                        emailErr: "",
                                      });
                                      if (res.status === 200) {
                                        this.props.history.push({
                                          pathname: ROUTES.SUBSCRIBE,
                                          contactDetails: false,
                                          subscribeDetails: true,
                                          pdfDownload: false,
                                        });
                                      }
                                    })
                                    .catch((err) => {
                                      console.log(err);
                                    });
                                }
                              }}
                            >
                              <button>
                                <img src="/assets/newsletter_images/right-arrow.svg" />
                              </button>
                            </div>
                          </div>
                          {this.state.emailErr.length > 0 ? (
                            <div className="err-msg">
                              <span>{this.state.emailErr}</span>
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      )}

                      <ul>
                        <li>
                          <a
                            href="https://www.facebook.com/ionixxtech/"
                            target="_blank"
                          >
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g clip-path="url(#clip0_179_7027)">
                                <path
                                  d="M13.397 20.9972V12.8012H16.162L16.573 9.59217H13.397V7.54817C13.397 6.62217 13.655 5.98817 14.984 5.98817H16.668V3.12717C15.8487 3.03936 15.0251 2.99696 14.201 3.00017C11.757 3.00017 10.079 4.49217 10.079 7.23117V9.58617H7.33203V12.7952H10.085V20.9972H13.397Z"
                                  fill="#007CC1"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_179_7027">
                                  <rect width="24" height="24" fill="white" />
                                </clipPath>
                              </defs>
                            </svg>
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://twitter.com/IonixxTech"
                            target="_blank"
                          >
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g clip-path="url(#clip0_179_7031)">
                                <path
                                  d="M19.633 7.99656C19.646 8.17156 19.646 8.34556 19.646 8.51956C19.646 13.8446 15.593 19.9806 8.186 19.9806C5.904 19.9806 3.784 19.3196 2 18.1716C2.324 18.2086 2.636 18.2216 2.973 18.2216C4.78599 18.2259 6.54765 17.6197 7.974 16.5006C7.13342 16.4853 6.31858 16.208 5.64324 15.7073C4.9679 15.2066 4.46578 14.5075 4.207 13.7076C4.456 13.7446 4.706 13.7696 4.968 13.7696C5.329 13.7696 5.692 13.7196 6.029 13.6326C5.11676 13.4484 4.29647 12.9539 3.70762 12.2332C3.11876 11.5126 2.79769 10.6102 2.799 9.67955V9.62956C3.336 9.92856 3.959 10.1156 4.619 10.1406C4.06609 9.77314 3.61272 9.27458 3.29934 8.68935C2.98596 8.10411 2.82231 7.45041 2.823 6.78656C2.823 6.03856 3.022 5.35256 3.371 4.75456C4.38314 5.99958 5.6455 7.01811 7.07634 7.7442C8.50717 8.47031 10.0746 8.88776 11.677 8.96955C11.615 8.66955 11.577 8.35855 11.577 8.04656C11.5767 7.51752 11.6807 6.99361 11.8831 6.5048C12.0854 6.01598 12.3821 5.57183 12.7562 5.19774C13.1303 4.82366 13.5744 4.52697 14.0632 4.32463C14.5521 4.1223 15.076 4.01829 15.605 4.01856C16.765 4.01856 17.812 4.50454 18.548 5.29056C19.4498 5.11616 20.3145 4.78698 21.104 4.31755C20.8034 5.24837 20.1738 6.03766 19.333 6.53756C20.1328 6.44633 20.9144 6.23601 21.652 5.91356C21.1011 6.71665 20.4185 7.4209 19.633 7.99656Z"
                                  fill="#007CC1"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_179_7031">
                                  <rect width="24" height="24" fill="white" />
                                </clipPath>
                              </defs>
                            </svg>
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://www.linkedin.com/company/ionixx-technologies"
                            target="_blank"
                          >
                            <svg
                              width="24"
                              height="24"
                              viewBox="0 0 24 24"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g clip-path="url(#clip0_179_7034)">
                                <path
                                  d="M5.73944 7.71325C6.81552 7.71325 7.68786 6.84091 7.68786 5.76483C7.68786 4.68874 6.81552 3.81641 5.73944 3.81641C4.66335 3.81641 3.79102 4.68874 3.79102 5.76483C3.79102 6.84091 4.66335 7.71325 5.73944 7.71325Z"
                                  fill="#007CC1"
                                />
                                <path
                                  d="M9.52807 9.18902V19.9988H12.8844V14.6532C12.8844 13.2426 13.1497 11.8766 14.8987 11.8766C16.6236 11.8766 16.645 13.4893 16.645 14.7422V19.9997H20.0031V14.0717C20.0031 11.1597 19.3761 8.92188 15.9726 8.92188C14.3386 8.92188 13.2432 9.81861 12.7953 10.6673H12.7499V9.18902H9.52807ZM4.05859 9.18902H7.42024V19.9988H4.05859V9.18902Z"
                                  fill="#007CC1"
                                />
                              </g>
                              <defs>
                                <clipPath id="clip0_179_7034">
                                  <rect width="24" height="24" fill="white" />
                                </clipPath>
                              </defs>
                            </svg>
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://www.instagram.com/ionixxtech/"
                            target="_blank"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="22"
                              height="21"
                              viewBox="0 0 22 21"
                              fill="none"
                            >
                              <path
                                d="M14.36 10.5C14.36 11.1645 14.1629 11.8142 13.7937 12.3667C13.4245 12.9193 12.8998 13.3499 12.2858 13.6042C11.6719 13.8585 10.9963 13.9251 10.3445 13.7954C9.69272 13.6658 9.09402 13.3458 8.62412 12.8759C8.15422 12.406 7.83421 11.8073 7.70456 11.1555C7.57491 10.5037 7.64145 9.82814 7.89576 9.21418C8.15007 8.60022 8.58074 8.07546 9.13328 7.70626C9.68583 7.33706 10.3355 7.14 11 7.14C11.8908 7.14102 12.7449 7.49534 13.3748 8.12525C14.0047 8.75515 14.359 9.60919 14.36 10.5ZM21.5 5.88V15.12C21.4982 16.6789 20.8782 18.1735 19.7758 19.2758C18.6735 20.3782 17.1789 20.9982 15.62 21H6.38C4.82107 20.9982 3.32649 20.3782 2.22416 19.2758C1.12183 18.1735 0.501766 16.6789 0.5 15.12V5.88C0.501766 4.32107 1.12183 2.82649 2.22416 1.72416C3.32649 0.621831 4.82107 0.00176555 6.38 0H15.62C17.1789 0.00176555 18.6735 0.621831 19.7758 1.72416C20.8782 2.82649 21.4982 4.32107 21.5 5.88ZM16.04 10.5C16.04 9.50318 15.7444 8.52875 15.1906 7.69993C14.6368 6.8711 13.8497 6.22511 12.9287 5.84365C12.0078 5.46218 10.9944 5.36237 10.0167 5.55684C9.03908 5.75131 8.14104 6.23133 7.43618 6.93618C6.73133 7.64104 6.25131 8.53908 6.05684 9.51674C5.86237 10.4944 5.96218 11.5078 6.34365 12.4287C6.72511 13.3497 7.3711 14.1368 8.19993 14.6906C9.02875 15.2444 10.0032 15.54 11 15.54C12.3362 15.5385 13.6173 15.007 14.5621 14.0621C15.507 13.1173 16.0385 11.8362 16.04 10.5ZM17.72 5.04C17.72 4.7908 17.6461 4.54719 17.5077 4.33998C17.3692 4.13278 17.1724 3.97128 16.9422 3.87591C16.7119 3.78054 16.4586 3.75559 16.2142 3.80421C15.9698 3.85283 15.7453 3.97283 15.569 4.14904C15.3928 4.32526 15.2728 4.54977 15.2242 4.79419C15.1756 5.0386 15.2005 5.29195 15.2959 5.52218C15.3913 5.75242 15.5528 5.9492 15.76 6.08765C15.9672 6.2261 16.2108 6.3 16.46 6.3C16.7942 6.3 17.1147 6.16725 17.351 5.93095C17.5872 5.69466 17.72 5.37417 17.72 5.04Z"
                                fill="#007CC1"
                              />
                            </svg>
                          </a>
                        </li>
                      </ul>
                    </div>
                  ) : (
                    ""
                  )
                }

                {this.props.location.pathname ===
                  ResourceRoute.NEW_NEWSLETTER ? (
                  <>
                    {" "}
                    <ul
                      id="mediaIcon"
                      className={
                        this.state.showMedia
                          ? "social-media sticky"
                          : "social-media"
                      }
                    >
                      <li>
                        <a
                          href="https://www.facebook.com/ionixxtech/"
                          target="_blank"
                        >
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clip-path="url(#clip0_179_7027)">
                              <path
                                d="M13.397 20.9972V12.8012H16.162L16.573 9.59217H13.397V7.54817C13.397 6.62217 13.655 5.98817 14.984 5.98817H16.668V3.12717C15.8487 3.03936 15.0251 2.99696 14.201 3.00017C11.757 3.00017 10.079 4.49217 10.079 7.23117V9.58617H7.33203V12.7952H10.085V20.9972H13.397Z"
                                fill="#007CC1"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_179_7027">
                                <rect width="24" height="24" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://twitter.com/IonixxTech"
                          target="_blank"
                        >
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clip-path="url(#clip0_179_7031)">
                              <path
                                d="M19.633 7.99656C19.646 8.17156 19.646 8.34556 19.646 8.51956C19.646 13.8446 15.593 19.9806 8.186 19.9806C5.904 19.9806 3.784 19.3196 2 18.1716C2.324 18.2086 2.636 18.2216 2.973 18.2216C4.78599 18.2259 6.54765 17.6197 7.974 16.5006C7.13342 16.4853 6.31858 16.208 5.64324 15.7073C4.9679 15.2066 4.46578 14.5075 4.207 13.7076C4.456 13.7446 4.706 13.7696 4.968 13.7696C5.329 13.7696 5.692 13.7196 6.029 13.6326C5.11676 13.4484 4.29647 12.9539 3.70762 12.2332C3.11876 11.5126 2.79769 10.6102 2.799 9.67955V9.62956C3.336 9.92856 3.959 10.1156 4.619 10.1406C4.06609 9.77314 3.61272 9.27458 3.29934 8.68935C2.98596 8.10411 2.82231 7.45041 2.823 6.78656C2.823 6.03856 3.022 5.35256 3.371 4.75456C4.38314 5.99958 5.6455 7.01811 7.07634 7.7442C8.50717 8.47031 10.0746 8.88776 11.677 8.96955C11.615 8.66955 11.577 8.35855 11.577 8.04656C11.5767 7.51752 11.6807 6.99361 11.8831 6.5048C12.0854 6.01598 12.3821 5.57183 12.7562 5.19774C13.1303 4.82366 13.5744 4.52697 14.0632 4.32463C14.5521 4.1223 15.076 4.01829 15.605 4.01856C16.765 4.01856 17.812 4.50454 18.548 5.29056C19.4498 5.11616 20.3145 4.78698 21.104 4.31755C20.8034 5.24837 20.1738 6.03766 19.333 6.53756C20.1328 6.44633 20.9144 6.23601 21.652 5.91356C21.1011 6.71665 20.4185 7.4209 19.633 7.99656Z"
                                fill="#007CC1"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_179_7031">
                                <rect width="24" height="24" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.linkedin.com/company/ionixx-technologies"
                          target="_blank"
                        >
                          <svg
                            width="24"
                            height="24"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clip-path="url(#clip0_179_7034)">
                              <path
                                d="M5.73944 7.71325C6.81552 7.71325 7.68786 6.84091 7.68786 5.76483C7.68786 4.68874 6.81552 3.81641 5.73944 3.81641C4.66335 3.81641 3.79102 4.68874 3.79102 5.76483C3.79102 6.84091 4.66335 7.71325 5.73944 7.71325Z"
                                fill="#007CC1"
                              />
                              <path
                                d="M9.52807 9.18902V19.9988H12.8844V14.6532C12.8844 13.2426 13.1497 11.8766 14.8987 11.8766C16.6236 11.8766 16.645 13.4893 16.645 14.7422V19.9997H20.0031V14.0717C20.0031 11.1597 19.3761 8.92188 15.9726 8.92188C14.3386 8.92188 13.2432 9.81861 12.7953 10.6673H12.7499V9.18902H9.52807ZM4.05859 9.18902H7.42024V19.9988H4.05859V9.18902Z"
                                fill="#007CC1"
                              />
                            </g>
                            <defs>
                              <clipPath id="clip0_179_7034">
                                <rect width="24" height="24" fill="white" />
                              </clipPath>
                            </defs>
                          </svg>
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.instagram.com/ionixxtech/"
                          target="_blank"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="22"
                            height="21"
                            viewBox="0 0 22 21"
                            fill="none"
                          >
                            <path
                              d="M14.36 10.5C14.36 11.1645 14.1629 11.8142 13.7937 12.3667C13.4245 12.9193 12.8998 13.3499 12.2858 13.6042C11.6719 13.8585 10.9963 13.9251 10.3445 13.7954C9.69272 13.6658 9.09402 13.3458 8.62412 12.8759C8.15422 12.406 7.83421 11.8073 7.70456 11.1555C7.57491 10.5037 7.64145 9.82814 7.89576 9.21418C8.15007 8.60022 8.58074 8.07546 9.13328 7.70626C9.68583 7.33706 10.3355 7.14 11 7.14C11.8908 7.14102 12.7449 7.49534 13.3748 8.12525C14.0047 8.75515 14.359 9.60919 14.36 10.5ZM21.5 5.88V15.12C21.4982 16.6789 20.8782 18.1735 19.7758 19.2758C18.6735 20.3782 17.1789 20.9982 15.62 21H6.38C4.82107 20.9982 3.32649 20.3782 2.22416 19.2758C1.12183 18.1735 0.501766 16.6789 0.5 15.12V5.88C0.501766 4.32107 1.12183 2.82649 2.22416 1.72416C3.32649 0.621831 4.82107 0.00176555 6.38 0H15.62C17.1789 0.00176555 18.6735 0.621831 19.7758 1.72416C20.8782 2.82649 21.4982 4.32107 21.5 5.88ZM16.04 10.5C16.04 9.50318 15.7444 8.52875 15.1906 7.69993C14.6368 6.8711 13.8497 6.22511 12.9287 5.84365C12.0078 5.46218 10.9944 5.36237 10.0167 5.55684C9.03908 5.75131 8.14104 6.23133 7.43618 6.93618C6.73133 7.64104 6.25131 8.53908 6.05684 9.51674C5.86237 10.4944 5.96218 11.5078 6.34365 12.4287C6.72511 13.3497 7.3711 14.1368 8.19993 14.6906C9.02875 15.2444 10.0032 15.54 11 15.54C12.3362 15.5385 13.6173 15.007 14.5621 14.0621C15.507 13.1173 16.0385 11.8362 16.04 10.5ZM17.72 5.04C17.72 4.7908 17.6461 4.54719 17.5077 4.33998C17.3692 4.13278 17.1724 3.97128 16.9422 3.87591C16.7119 3.78054 16.4586 3.75559 16.2142 3.80421C15.9698 3.85283 15.7453 3.97283 15.569 4.14904C15.3928 4.32526 15.2728 4.54977 15.2242 4.79419C15.1756 5.0386 15.2005 5.29195 15.2959 5.52218C15.3913 5.75242 15.5528 5.9492 15.76 6.08765C15.9672 6.2261 16.2108 6.3 16.46 6.3C16.7942 6.3 17.1147 6.16725 17.351 5.93095C17.5872 5.69466 17.72 5.37417 17.72 5.04Z"
                              fill="#007CC1"
                            />
                          </svg>
                        </a>
                      </li>
                    </ul>
                    <div
                      id="sub-float-div"
                      ref={this.wrapperRef}
                      className={
                        this.state.showSubscribeFloat
                          ? "top-subscribe sticky"
                          : "top-subscribe"
                      }
                    >
                      <div className="subscribe-input">
                        {/* <form>
                                <input
                                  type="text"
                                  name={"email"}
                                  value={this.state.email}
                                  onChange={this.handleChange.bind(this)}
                                  placeholder="Stay inspired with our newsletter"
                                />
                              </form>  */}
                        <div className="subscribe-hover">
                          {" "}
                          <button onClick={this.showSubscribe}>
                            Subscribe{" "}
                          </button>
                        </div>

                        {this.state.subscribeNow && (
                          <div className="subscribe-our-news">
                            <div className="form-group newsletter-form-group">
                              <input
                                type="text"
                                className="form-control"
                                id=""
                                placeholder="Enter your email*"
                                name={"email"}
                                value={this.state.email}
                                onChange={this.handleChange.bind(this)}
                              ></input>
                              <div
                                className="newsletter-arrow"
                                onClick={() => {
                                  var emailRegx =
                                    /^(([a-zA-Z\d-_.]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                                      this.state.email
                                    );
                                  if (emailRegx === false) {
                                    this.setState({
                                      emailErr: "Invalid Email",
                                    });
                                  } else {
                                    this.props
                                      .subscribeForNewsLetter({
                                        emailID: this.state.email,
                                        name: this.state.name,
                                        category: this.props.category,
                                        url: this.props.location.pathname,
                                      })
                                      .then((res) => { })
                                      .catch((err) => {
                                        console.log(err);
                                      });
                                    this.props
                                      .sendNewsFeed(this.state.email)
                                      .then((res) => {
                                        this.setState({
                                          email: "",
                                          emailErr: "",
                                        });
                                        if (res.status === 200) {
                                          this.props.history.push({
                                            pathname: ROUTES.SUBSCRIBE,
                                            contactDetails: false,
                                            subscribeDetails: true,
                                            pdfDownload: false,
                                          });
                                        }
                                      })
                                      .catch((err) => {
                                        console.log(err);
                                      });
                                  }
                                }}
                              >
                                <button>
                                  <img src="/assets/newsletter_images/right-arrow.svg" />
                                </button>
                              </div>
                            </div>
                            {this.state.emailErr.length > 0 ? (
                              <div className="err-msg">
                                <span>{this.state.emailErr}</span>
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        )}
                      </div>
                    </div>
                  </>
                ) : (
                  ""
                )}
                <div id="subscribeBottom"></div>
              </div>
            </div>
          </section>
          {this.props.location.pathname === ROUTES.CASESTUDYDRIVEWEALTH ? <div className='stickyNotePostTrade'>
            <Link to={ROUTES.POSTTRADE}>Check Our Post Trade Solutions</Link>
          </div> : ""}
          {this.props.location.pathname === ROUTES.POSTTRADE ? <div className='stickyNotePostTrade'>
            <Link to={ROUTES.CASESTUDYDRIVEWEALTH}>Check Our Case Study</Link>
          </div> : ""}
          {this.props.location.pathname === ROUTES.HEALTHCARE ? <div className='stickyNotePostTrade icon-sticky'>

            <Link to={ROUTES.NEURON}>
              <h6>Featured Healthcare Soultion</h6>
              <span>
                <img src="/assets/img/health-img/calculated-health-logo.png"></img>
                <strong>Neuron</strong>
              </span>
              <p>Powered by Calculated Health</p>
            </Link>
          </div> : ""}
          {this.props.location.pathname === ROUTES.POSTTRADESERVICES ? <div className='stickyNotePostTrade icon-sticky'>

            <Link to={ROUTES.CASESTUDYDRIVEWEALTH}>
              <h6>Read Our<br></br> Case Study</h6>
            </Link>
          </div> : ""}
          {this.props.location.pathname === ROUTES.DEFISERVICE ? <div className='stickyNotePostTrade icon-sticky'>
            <Link onClick={this.openModal} >
              <h6>Initiate a Discussion<br></br> with Our Expert</h6>
            </Link>
          </div> : ""}
          <SwitchWithSlide>
            <Route exact path={"/"} component={NewHomeDesign} />
            {/* <Route exact path={ROUTES.NEWDESIGN} component={HomeDesign}/> */}
            <Route exact path={ROUTES.TALKTOUS} component={TalkToUs} />
            <Route exact path={ROUTES.TECHNOLOGY} component={Technology} />
            <Route exact path={ROUTES.STARTUP} component={Startup} />
            {/* <Route exact path={ROUTES.MARKETPLACE} component={MarketPlace}/> */}
            <Route exact path={ROUTES.ENTERPRISE} component={Enterprise} />
            <Route exact path={ROUTES.ABOUTUS} component={AboutUs} />
            {/* <Route exact path={ROUTES.KYC} component={Kyc}/> */}
            <Route
              exact
              path={ROUTES.CONTACTUS}
              render={(props) => (
                <ContactUs
                  {...props}
                  ref={(r) => {
                    this.contactRef = r;
                  }}
                />
              )}
            />
            <Route exact path={ROUTES.PORTFOLIO} component={Portfolio} />
            {/* <Route exact path={ROUTES.BLOCKCHAIN} component={Blockchain}/> */}
            <Route
              exact
              path={ROUTES.BlOCKCHAIN_NEW}
              component={Web3Blockchain}
            />
            {/* <Route exact path={ROUTES.BlockchainNew} component={Blockchain_New}/> */}

            <Route exact path={ROUTES.DESIGN} component={Design} />
            {/* <Route exact path={ROUTES.CRYPTODESIGN} component={CryptoDesign} /> */}
            <Route exact path={ROUTES.CRYPTOOTC} component={CryptoOTC} />
            <Route
              exact
              path={ROUTES.CRYPTOOTCDEVELOPMENT}
              component={CryptoOtcDevelopment}
            />
            <Route exact path={ROUTES.OTCSOLUTION} component={OtcSolution} />
            <Route exact path={ROUTES.ALGOBOT} component={algoBot} />
            <Route exact path={ROUTES.OMS} component={OMS} />
            <Route exact path={ROUTES.POSTTRADE} component={postTrade} />

            <Route
              exact
              path={ROUTES.CRYPTOOTCWITHFORM}
              component={cryptoOTCwithForm}
            />
            <Route
              exact
              path={ROUTES.ALGOBOTWITHFORM}
              component={algoBotwithForm}
            />
            <Route
              exact
              path={ROUTES.ALGOBOTWITHFORMDEVELOPMENT}
              component={algoBotwithFormDevelopment}
            />
            <Route
              exact
              path={ROUTES.CRYPTOOTCWITHFORMDEVELOPMENT}
              component={cryptoOTCwithFormDevelopment}
            />

            <Route
              exact
              path={ROUTES.ALGOBOTDEVELOPMENT}
              component={algoBotDevelopment}
            />
            <Route
              exact
              path={ROUTES.ALGOBOTVERSION_1}
              component={ALGOBOTVERSION_1}
            />
            <Route
              exact
              path={ResourceRoute.BLOCKCHAINGUIDE}
              component={BlockchainGuide}
            />
            <Route
              exact
              path={ResourceRoute.DATAAGGREGATION}
              component={DataAggregation}
            />
            <Route
              exact
              path={ResourceRoute.MULTIEXCHANGECRYPTOCURRENCY}
              component={MultiExchangeCryptoCurrency}
            />
            {/*Portfolio page sub routes*/}
            <Route exact path={PortfolioRoute.Dvs} component={Dvs} />
            <Route
              exact
              path={PortfolioRoute.GaadiBazaar}
              component={GaadiBazar}
            />
            <Route
              exact
              path={PortfolioRoute.LoanProcessing}
              component={LoanProcessing}
            />
            <Route exact path={PortfolioRoute.P2E} component={P2E} />
            <Route exact path={PortfolioRoute.Kyc} component={Kyc} />
            <Route exact path={PortfolioRoute.Tenshey} component={Tenshey} />
            <Route exact path={ROUTES.CASESTUDYP2E} component={CaseStudyP2e} />
            <Route
              exact
              path={ROUTES.CASESTUDYDUBUKU}
              component={CaseStudyDubbuku}
            />
            <Route
              exact
              path={ROUTES.CASESTUDYCRYPTOSPACE}
              component={CaseStudyCryptoSapce}
            />
            <Route
              exact
              path={ROUTES.CASESTUDYGADDIBAZZAR}
              component={CaseStudyGaddiBazar}
            />
            <Route
              exact
              path={ROUTES.CASESTUDYTENSHEY}
              component={CaseStudyTenshey}
            />
            <Route
              exact
              path={ROUTES.CASESTUDYDRIVEWEALTH}
              component={caseStydyDriveWealth}
            />

            <Route exact path={ROUTES.HEALTHCARE} component={Healthcare} />
            {/* <Route exact path={ROUTES.HEALTHCARECONTEST} component={Healthcarecontest} /> */}
            <Route exact path={ROUTES.FINTECH} component={FinTech} />
            <Route exact path={ROUTES.CAPITAL_MARKET} component={CapitalMarket} />
            <Route exact path={ROUTES.TRADE_AUTOMATION} component={TradeAutomation} />
            <Route exact path={ROUTES.TRADE_SURVEILLANCE} component={TradeSurveillance} />
            <Route exact path={ROUTES.TOAST} component={ToastComponent} />
            <Route exact path={ROUTES.CAREERS} component={Careers} />
            <Route exact path={ROUTES.DESIGNSTUDIO} component={DesignStudioComponent}/>
            <Route exact path={ROUTES.DESIGNPROCESS} component={DesignProcessComponent}/>
            <Route exact path={ROUTES.DESIGNOURWORK} component={OurWorkComponent}/>
            <Route
              exact
              path={ROUTES.SUBSCRIBE}
              component={ThankYouComponent}
            />
            <Route
              exact
              path={ROUTES.REQUESTDEMOCALL}
              component={ThankYouDemoComponent}
            />
            <Route
              exact
              path={ROUTES.REQUESTDEMOCALL1}
              component={ThankYouDemoComponent}
            />
            <Route
              exact
              path={ROUTES.REQUESTDEMOCALLDEFI}
              component={ThankYouDemoComponent}
            />
            {/* <Route exact path={ROUTES.BROKERAGE} component={Brokerage} /> */}
            <Route
              exact
              path={ROUTES.DIGITALBROKERAGE}
              component={digitalBrokerage}
            />
            <Route
              exact
              path={ROUTES.MODERNPOSTTRADE}
              component={ModernPostTrade}
            />
            <Route
              exact
              path={ROUTES.DEFISERVICE}
              component={DefiService}
            />
            <Route
              exact
              path={ROUTES.POSTTRADESERVICES}
              component={PostTradeService}
            />
            <Route exact path={ROUTES.NEURON} component={NeuronComponent} />

            <Route
              exact
              path={ResourceRoute.EVENTSNEWS_TAB}
              component={Events}
            />
            <Route
              exact
              path={ResourceRoute.WHITEPAPER_TAB}
              component={WhitepaperEbook}
            />
            <Route
              exact
              path={ResourceRoute.NEWSLETTER_TAB}
              component={NewsletterComponent}
            />
            {/* <Route exact path={ResourceRoute.BLOCKCHAIN} component={BlockChain}/> */}
            <Route
              exact
              path={ResourceRoute.BlockChainContractsSmallContracts}
              component={BlockChainContractsSmallContracts}
            />
            <Route
              exact
              path={ResourceRoute.BlockChainFundamentals}
              component={BlockChainFundamentals}
            />
            <Route
              exact
              path={ResourceRoute.BlockChainLeagalImplication}
              component={BlockChainLeagalImplication}
            />
            <Route
              exact
              path={ResourceRoute.BlockChainCryptoMining}
              component={BlockChainCryptoMining}
            />
            <Route
              exact
              path={ResourceRoute.ChallengeSecurityBlockChain}
              component={ChallengeSecurityBlockChain}
            />
            <Route
              exact
              path={ResourceRoute.CryptoCurrenciesSoftwareProtocol}
              component={CryptoCurrenciesSoftwareProtocol}
            />
            <Route
              exact
              path={ResourceRoute.MobileAppDevelopmentApproach}
              component={MobileAppDevelopmentApproach}
            />
            <Route
              exact
              path={ResourceRoute.FormationOfBlockChain}
              component={FormationOfBlockChain}
            />
            <Route
              exact
              path={ResourceRoute.NEW_NEWSLETTER}
              component={NewsLetter}
            />
            <Route
              exact
              path={ResourceRoute.NEWSISSUE86}
              render={(props) => (
                <NewsIssue86
                  {...props}
                  ref={(r) => {
                    this.newsIssueRef = r;
                  }}
                />
              )}
            />
            <Route
              exact
              path={ResourceRoute.NEWSISSUE85}
              render={(props) => (
                <NewsIssue85
                  {...props}
                  ref={(r) => {
                    this.newsIssueRef = r;
                  }}
                />
              )}
            />
            <Route
              exact
              path={ResourceRoute.NEWSISSUE84}
              render={(props) => (
                <NewsIssue84
                  {...props}
                  ref={(r) => {
                    this.newsIssueRef = r;
                  }}
                />
              )}
            />
            <Route
              exact
              path={ResourceRoute.NEWSISSUE83}
              render={(props) => (
                <NewsIssue83
                  {...props}
                  ref={(r) => {
                    this.newsIssueRef = r;
                  }}
                />
              )}
            />
            <Route
              exact
              path={ResourceRoute.NEWSISSUE82}
              render={(props) => (
                <NewsIssue82
                  {...props}
                  ref={(r) => {
                    this.newsIssueRef = r;
                  }}
                />
              )}
            />
            <Route
              exact
              path={ResourceRoute.NEWSISSUE81}
              component={NewsIssue81}
            />
            <Route
              exact
              path={ResourceRoute.NEWSISSUE80}
              component={NewsIssue80}
            />
            <Route
              exact
              path={ResourceRoute.NEWSISSUE79}
              component={NewsIssue79}
            />
            <Route
              exact
              path={ResourceRoute.NEWSISSUE78}
              component={NewsIssue78}
            />
            <Route
              exact
              path={ResourceRoute.NEWSISSUE77}
              component={NewsIssue77}
            />
            <Route
              exact
              path={ResourceRoute.NEWSISSUE76}
              component={NewsIssue76}
            />
            <Route
              exact
              path={ResourceRoute.NEWSISSUE75}
              component={NewsIssue75}
            />
            <Route
              exact
              path={ResourceRoute.NEWSISSUE74}
              component={NewsIssue74}
            />
            <Route
              exact
              path={ResourceRoute.NEWSISSUE73}
              component={NewsIssue73}
            />
            <Route
              exact
              path={ResourceRoute.NEWSISSUE72}
              component={NewsIssue72}
            />
            <Route
              exact
              path={ResourceRoute.NEWSISSUE71}
              component={NewsIssue71}
            />
            <Route
              exact
              path={ResourceRoute.NEWSISSUE70}
              component={NewsIssue70}
            />
            <Route
              exact
              path={ResourceRoute.NEWSISSUE69}
              component={NewsIssue69}
            />
            <Route
              exact
              path={ResourceRoute.NEWSISSUE68}
              component={NewsIssue68}
            />
            <Route
              exact
              path={ResourceRoute.NEWSISSUE67}
              component={NewsIssue67}
            />
            <Route
              exact
              path={ResourceRoute.NEWSISSUE66}
              component={NewsIssue66}
            />
            <Route
              exact
              path={ResourceRoute.NEWSISSUE65}
              component={NewsIssue65}
            />
            <Route
              exact
              path={ResourceRoute.NEWSISSUE64}
              component={NewsIssue64}
            />
            <Route
              exact
              path={ResourceRoute.NEWSISSUE63}
              component={NewsIssue63}
            />
            <Route
              exact
              path={ResourceRoute.NEWSISSUE62}
              component={NewsIssue62}
            />
            <Route
              exact
              path={ResourceRoute.NEWSISSUE60}
              component={NewsIssue60}
            />
            <Route
              exact
              path={ResourceRoute.NEWSISSUE59}
              component={NewsIssue59}
            />
            <Route
              exact
              path={ResourceRoute.NEWSISSUE58}
              component={NewsIssue58}
            />
            <Route
              exact
              path={ResourceRoute.NEWSISSUE57}
              component={NewsIssue57}
            />
            <Route
              exact
              path={ResourceRoute.NEWSISSUE56}
              component={NewsIssue56}
            />
            <Route
              exact
              path={ResourceRoute.NEWSISSUE55}
              component={NewsIssue55}
            />
            <Route
              exact
              path={ResourceRoute.NEWSISSUE54}
              component={NewsIssue54}
            />
            <Route
              exact
              path={ResourceRoute.NEWSISSUE53}
              component={NewsIssue53}
            />
            <Route
              exact
              path={ResourceRoute.NEWSISSUE52}
              component={NewsIssue52}
            />
            <Route
              exact
              path={ResourceRoute.NEWSISSUE51}
              component={NewsIssue51}
            />
            <Route
              exact
              path={ResourceRoute.NEWSISSUE50}
              component={NewsIssue50}
            />
            <Route
              exact
              path={ResourceRoute.NEWSISSUE49}
              component={NewsIssue49}
            />
            <Route
              exact
              path={ResourceRoute.NEWSISSUE48}
              component={NewsIssue48}
            />
            <Route
              exact
              path={ResourceRoute.NEWSISSUE47}
              component={NewsIssue47}
            />
            <Route
              exact
              path={ResourceRoute.NEWSISSUE46}
              component={NewsIssue46}
            />
            <Route
              exact
              path={ResourceRoute.NEWSISSUE45}
              component={NewsIssue45}
            />
            <Route
              exact
              path={ResourceRoute.NEWSISSUE44}
              component={NewsIssue44}
            />
            <Route
              exact
              path={ResourceRoute.NEWSISSUE43}
              component={NewsIssue43}
            />
            <Route
              exact
              path={ResourceRoute.NEWSISSUE42}
              component={NewsIssue42}
            />
            <Route
              exact
              path={ResourceRoute.NEWSISSUE41}
              component={NewsIssue41}
            />
            <Route
              exact
              path={ResourceRoute.NEWSISSUE40}
              component={NewsIssue40}
            />
            <Route
              exact
              path={ResourceRoute.NEWSISSUE39}
              component={NewsIssue39}
            />
            <Route
              exact
              path={ResourceRoute.NEWSISSUE38}
              component={NewsIssue38}
            />
            <Route
              exact
              path={ResourceRoute.NEWSISSUE37}
              component={NewsIssue37}
            />
            <Route
              exact
              path={ResourceRoute.NEWSISSUE36}
              component={NewsIssue36}
            />
            <Route
              exact
              path={ResourceRoute.NEWSISSUE35}
              component={NewsIssue35}
            />
            <Route
              exact
              path={ResourceRoute.NEWSISSUE34}
              component={NewsIssue34}
            />
            <Route
              exact
              path={ResourceRoute.NEWSISSUE33}
              component={NewsIssue33}
            />
            <Route
              exact
              path={ResourceRoute.NEWSISSUE32}
              component={NewsIssue32}
            />
            <Route
              exact
              path={ResourceRoute.NEWSISSUE31}
              component={NewsIssue31}
            />
            <Route
              exact
              path={ResourceRoute.NEWSISSUE30}
              component={NewsIssue30}
            />
            <Route
              exact
              path={ResourceRoute.NEWSISSUE29}
              component={NewsIssue29}
            />
            <Route
              exact
              path={ResourceRoute.NEWSISSUE28}
              component={NewsIssue28}
            />
            <Route
              exact
              path={ResourceRoute.NEWSISSUE27}
              component={NewsIssue27}
            />
            <Route
              exact
              path={ResourceRoute.NEWSISSUE26}
              component={NewsIssue26}
            />
            <Route
              exact
              path={ResourceRoute.NEWSISSUE25}
              component={NewsIssue25}
            />
            <Route
              exact
              path={ResourceRoute.NEWSISSUE24}
              component={NewsIssue24}
            />
            <Route
              exact
              path={ResourceRoute.NEWSISSUE23}
              component={NewsIssue23}
            />
            <Route
              exact
              path={ResourceRoute.NEWSISSUE22}
              component={NewsIssue22}
            />
            <Route
              exact
              path={ResourceRoute.NEWSISSUE21}
              component={NewsIssue21}
            />
            <Route
              exact
              path={ResourceRoute.NEWSISSUE20}
              component={NewsIssue20}
            />
            <Route
              exact
              path={ResourceRoute.NEWSISSUE19}
              component={NewsIssue19}
            />
            <Route
              exact
              path={ResourceRoute.NEWSISSUE18}
              component={NewsIssue18}
            />
            <Route
              exact
              path={ResourceRoute.NEWSISSUE17}
              component={NewsIssue17}
            />
            <Route
              exact
              path={ResourceRoute.NEWSISSUE16}
              component={NewsIssue16}
            />
            <Route
              exact
              path={ResourceRoute.NEWSISSUE15}
              component={NewsIssue15}
            />
            <Route
              exact
              path={ResourceRoute.NEWSISSUE14}
              component={NewsIssue14}
            />
            <Route
              exact
              path={ResourceRoute.NEWSISSUE13}
              component={NewsIssue13}
            />
            <Route
              exact
              path={ResourceRoute.NEWSISSUE12}
              component={NewsIssue12}
            />
            <Route
              exact
              path={ResourceRoute.NEWSISSUE11}
              component={NewsIssue11}
            />
            <Route
              exact
              path={ResourceRoute.NEWSISSUE10}
              component={NewsIssue10}
            />
            <Route
              exact
              path={ResourceRoute.NEWSISSUE09}
              component={NewsIssue09}
            />
            <Route
              exact
              path={ResourceRoute.NEWSISSUE08}
              component={NewsIssue08}
            />
            <Route
              exact
              path={ResourceRoute.NEWSISSUE07}
              component={NewsIssue07}
            />
            <Route
              exact
              path={ResourceRoute.NEWSISSUE06}
              component={NewsIssue06}
            />
            <Route
              exact
              path={ResourceRoute.NEWSISSUE05}
              component={NewsIssue05}
            />
            <Route
              exact
              path={ResourceRoute.NEWSISSUE04}
              component={NewsIssue04}
            />
            <Route
              exact
              path={ResourceRoute.NEWSISSUE03}
              component={NewsIssue03}
            />
            <Route
              exact
              path={ResourceRoute.NEWSISSUE02}
              component={NewsIssue02}
            />
            <Route
              exact
              path={ResourceRoute.NEWSISSUE01}
              component={NewsIssue01}
            />
            <Route
              exact
              path={ResourceRoute.NEWSISSUE83}
              component={NewsIssue83}
            />
            <Route
              exact
              path={ResourceRoute.NEWSISSUE82}
              component={NewsIssue82}
            />
            <Route
              exact
              path={ResourceRoute.NEWSISSUE81}
              component={NewsIssue81}
            />
            <Route
              exact
              path={ResourceRoute.NEWSISSUE80}
              component={NewsIssue80}
            />
            <Route
              exact
              path={ResourceRoute.NEWSISSUE79}
              component={NewsIssue79}
            />
            <Route
              exact
              path={ResourceRoute.NEWSISSUE78}
              component={NewsIssue78}
            />
            <Route
              exact
              path={ResourceRoute.NEWSISSUE77}
              component={NewsIssue77}
            />
            <Route
              exact
              path={ResourceRoute.NEWSISSUE76}
              component={NewsIssue76}
            />
            <Route
              exact
              path={ResourceRoute.NEWSISSUE75}
              component={NewsIssue75}
            />
            <Route
              exact
              path={ResourceRoute.NEWSISSUE74}
              component={NewsIssue74}
            />
            <Route
              exact
              path={ResourceRoute.NEWSISSUE73}
              component={NewsIssue73}
            />
            <Route
              exact
              path={ResourceRoute.NEWSISSUE72}
              component={NewsIssue72}
            />
            <Route
              exact
              path={ResourceRoute.NEWSISSUE71}
              component={NewsIssue71}
            />
            <Route
              exact
              path={ResourceRoute.NEWSISSUE70}
              component={NewsIssue70}
            />
            <Route
              exact
              path={ResourceRoute.NEWSISSUE69}
              component={NewsIssue69}
            />
            <Route
              exact
              path={ResourceRoute.NEWSISSUE68}
              component={NewsIssue68}
            />
            <Route
              exact
              path={ResourceRoute.NEWSISSUE67}
              component={NewsIssue67}
            />
            <Route
              exact
              path={ResourceRoute.NEWSISSUE66}
              component={NewsIssue66}
            />
            <Route
              exact
              path={ResourceRoute.NEWSISSUE65}
              component={NewsIssue65}
            />
            <Route
              exact
              path={ResourceRoute.NEWSISSUE64}
              component={NewsIssue64}
            />
            <Route
              exact
              path={ResourceRoute.NEWSISSUE63}
              component={NewsIssue63}
            />
            <Route
              exact
              path={ResourceRoute.NEWSISSUE62}
              component={NewsIssue62}
            />
            <Route
              exact
              path={ResourceRoute.NEWSISSUE60}
              component={NewsIssue60}
            />
            <Route
              exact
              path={ResourceRoute.NEWSISSUE59}
              component={NewsIssue59}
            />
            <Route
              exact
              path={ResourceRoute.NEWSISSUE58}
              component={NewsIssue58}
            />
            <Route
              exact
              path={ResourceRoute.NEWSISSUE57}
              component={NewsIssue57}
            />
            <Route
              exact
              path={ResourceRoute.NEWSISSUE56}
              component={NewsIssue56}
            />
            <Route
              exact
              path={ResourceRoute.NEWSISSUE55}
              component={NewsIssue55}
            />
            <Route
              exact
              path={ResourceRoute.NEWSISSUE54}
              component={NewsIssue54}
            />
            <Route
              exact
              path={ResourceRoute.NEWSISSUE53}
              component={NewsIssue53}
            />
            <Route
              exact
              path={ResourceRoute.NEWSISSUE52}
              component={NewsIssue52}
            />
            <Route
              exact
              path={ResourceRoute.NEWSISSUE51}
              component={NewsIssue51}
            />
            <Route
              exact
              path={ResourceRoute.NEWSISSUE50}
              component={NewsIssue50}
            />
            <Route
              exact
              path={ResourceRoute.NEWSISSUE49}
              component={NewsIssue49}
            />
            <Route
              exact
              path={ResourceRoute.NEWSISSUE48}
              component={NewsIssue48}
            />
            <Route
              exact
              path={ResourceRoute.NEWSISSUE47}
              component={NewsIssue47}
            />
            <Route
              exact
              path={ResourceRoute.NEWSISSUE46}
              component={NewsIssue46}
            />
            <Route
              exact
              path={ResourceRoute.NEWSISSUE45}
              component={NewsIssue45}
            />
            <Route
              exact
              path={ResourceRoute.NEWSISSUE44}
              component={NewsIssue44}
            />
            <Route
              exact
              path={ResourceRoute.NEWSISSUE43}
              component={NewsIssue43}
            />
            <Route
              exact
              path={ResourceRoute.NEWSISSUE42}
              component={NewsIssue42}
            />
            <Route
              exact
              path={ResourceRoute.NEWSISSUE41}
              component={NewsIssue41}
            />
            <Route
              exact
              path={ResourceRoute.NEWSISSUE40}
              component={NewsIssue40}
            />
            <Route
              exact
              path={ResourceRoute.NEWSISSUE39}
              component={NewsIssue39}
            />
            <Route
              exact
              path={ResourceRoute.NEWSISSUE38}
              component={NewsIssue38}
            />
            <Route
              exact
              path={ResourceRoute.NEWSISSUE37}
              component={NewsIssue37}
            />
            <Route
              exact
              path={ResourceRoute.NEWSISSUE36}
              component={NewsIssue36}
            />
            <Route
              exact
              path={ResourceRoute.NEWSISSUE35}
              component={NewsIssue35}
            />
            <Route
              exact
              path={ResourceRoute.NEWSISSUE34}
              component={NewsIssue34}
            />
            <Route
              exact
              path={ResourceRoute.NEWSISSUE33}
              component={NewsIssue33}
            />
            <Route
              exact
              path={ResourceRoute.NEWSISSUE32}
              component={NewsIssue32}
            />
            <Route
              exact
              path={ResourceRoute.NEWSISSUE31}
              component={NewsIssue31}
            />
            <Route
              exact
              path={ResourceRoute.NEWSISSUE30}
              component={NewsIssue30}
            />
            <Route
              exact
              path={ResourceRoute.NEWSISSUE29}
              component={NewsIssue29}
            />
            <Route
              exact
              path={ResourceRoute.NEWSISSUE28}
              component={NewsIssue28}
            />
            <Route
              exact
              path={ResourceRoute.NEWSISSUE27}
              component={NewsIssue27}
            />
            <Route
              exact
              path={ResourceRoute.NEWSISSUE26}
              component={NewsIssue26}
            />
            <Route
              exact
              path={ResourceRoute.NEWSISSUE25}
              component={NewsIssue25}
            />
            <Route
              exact
              path={ResourceRoute.NEWSISSUE24}
              component={NewsIssue24}
            />
            <Route
              exact
              path={ResourceRoute.NEWSISSUE23}
              component={NewsIssue23}
            />
            <Route
              exact
              path={ResourceRoute.NEWSISSUE22}
              component={NewsIssue22}
            />
            <Route
              exact
              path={ResourceRoute.NEWSISSUE21}
              component={NewsIssue21}
            />
            <Route
              exact
              path={ResourceRoute.NEWSISSUE20}
              component={NewsIssue20}
            />
            <Route
              exact
              path={ResourceRoute.NEWSISSUE19}
              component={NewsIssue19}
            />
            <Route
              exact
              path={ResourceRoute.NEWSISSUE18}
              component={NewsIssue18}
            />
            <Route
              exact
              path={ResourceRoute.NEWSISSUE17}
              component={NewsIssue17}
            />
            <Route
              exact
              path={ResourceRoute.NEWSISSUE16}
              component={NewsIssue16}
            />
            <Route
              exact
              path={ResourceRoute.NEWSISSUE15}
              component={NewsIssue15}
            />
            <Route
              exact
              path={ResourceRoute.NEWSISSUE14}
              component={NewsIssue14}
            />
            <Route
              exact
              path={ResourceRoute.NEWSISSUE13}
              component={NewsIssue13}
            />
            <Route
              exact
              path={ResourceRoute.NEWSISSUE12}
              component={NewsIssue12}
            />
            <Route
              exact
              path={ResourceRoute.NEWSISSUE11}
              component={NewsIssue11}
            />
            <Route
              exact
              path={ResourceRoute.NEWSISSUE10}
              component={NewsIssue10}
            />
            <Route
              exact
              path={ResourceRoute.NEWSISSUE09}
              component={NewsIssue09}
            />
            <Route
              exact
              path={ResourceRoute.NEWSISSUE08}
              component={NewsIssue08}
            />
            <Route
              exact
              path={ResourceRoute.NEWSISSUE07}
              component={NewsIssue07}
            />
            <Route
              exact
              path={ResourceRoute.NEWSISSUE06}
              component={NewsIssue06}
            />
            <Route
              exact
              path={ResourceRoute.NEWSISSUE05}
              component={NewsIssue05}
            />
            <Route
              exact
              path={ResourceRoute.NEWSISSUE04}
              component={NewsIssue04}
            />
            <Route
              exact
              path={ResourceRoute.NEWSISSUE03}
              component={NewsIssue03}
            />
            <Route
              exact
              path={ResourceRoute.NEWSISSUE02}
              component={NewsIssue02}
            />
            <Route
              exact
              path={ResourceRoute.NEWSISSUE01}
              component={NewsIssue01}
            />
            {/* <Route exact path={ROUTES.NOT_FOUND} component={PageNotFound} /> */}
          </SwitchWithSlide>

          <SecondaryFooter
            onResourceSelected={this.onFooterResourceSelected}
            onclickAddress={() => {
              this.contactRef.onclickAddress();
            }}
          />
        </div>
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return state.commonReducer;
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return bindActionCreators(contactAction, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(Landing);
