import React from 'react';
import "../../resources.css"
import WatchVideo from "../../../../components/watch-video";
import {Link} from "react-router-dom";
import {SlideDown} from 'react-slidedown'
import 'react-slidedown/lib/slidedown.css'
import ReactSEOMetaTags from 'react-seo-meta-tags';
import Helmet from 'react-helmet';
import {VIDEO_LINK} from "../../../../utilities/constants";
class Events extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            headerClassAdd: "",
            showVideo: false,
            videoModalUrl: '',
            videoModalTitle: '',
            pageEnabled: false,
            buttonShow: true,
            start: 0,
            end: 3,
            cardList: [
                {
                    content: "The Evolution, Impact, and Opportunities of ePrescribing",
                    authorName: ["Dr. Mannas Samant ,"],
                    date: "22 June 2023",
                    link1: "The Evolution, Impact, and Opportunities of ePrescribing",
                    link2:  "https://www.youtube.com/watch?v=4bFj3PBJDnQ",
                    href: "",
                    img: "/assets/img/health-img/e-prescribe-banner.jpg",
                    link:true,
                    alt:"The Evolution, Impact, and Opportunities of ePrescribing",
                    title:"The Evolution, Impact, and Opportunities of ePrescribing"
                },{
                    content: "The Evolving Role of AI in Diagnosing and Treating Patients",
                    authorName: ["Dr. Mannas Samant ,"],
                    date: "13 April 2023",
                    link1: "The Evolving Role of AI in Diagnosing and Treating Patients",
                    link2:  "https://www.youtube.com/watch?v=Ppc0rBR78BY",
                    href: "",
                    img: "/assets/img/health-img/evolve-of-ai.jpg",
                    link:true,
                    alt:"The Evolving Role of AI in Diagnosing and Treating Patients",
                    title:"The Evolving Role of AI in Diagnosing and Treating Patients"
                },
                {
                    content: "Technology’s Impact on Healthcare Ep 6: Healthcare AI",
                    authorName: ["Dr. Mannas Samant ,"],
                    date: "04 November 2022",
                    link1: "Technology’s Impact on Healthcare Ep 6: Healthcare AI",
                    link2:  "https://www.youtube.com/watch?v=6UfmzV9F8lc",
                    href: "",
                    img: "/assets/img/health-img/HCWebinar_Ep-6.jpg",
                    link:true,
                    alt:"Technology’s Impact on Healthcare Ep 6: Healthcare AI",
                    title:"Technology’s Impact on Healthcare Ep 6: Healthcare AI"
                },
                {
                    content: "Technology’s Impact on Healthcare Ep 5: Healthcare Security",
                    authorName: ["Dr. Mannas Samant ,"],
                    date: "28 October 2022",
                    link1: "Technology’s Impact on Healthcare Ep 5: Healthcare Security",
                    link2:  "https://www.youtube.com/watch?v=N1ONUTWnqyU",
                    href: "",
                    img: "/assets/img/health-img/HCWebinar_Ep-5.jpg",
                    link:true,
                    alt:"Technology’s Impact on Healthcare Ep 5: Healthcare Security",
                    title:"Technology’s Impact on Healthcare Ep 5: Healthcare Security"
                },
                {
                    content: "Technology’s Impact on Healthcare Ep 4: Medical Device Software",
                    authorName: ["Dr. Mannas Samant ,"],
                    date: "21 October 2022",
                    link1: "Technology’s Impact on Healthcare Ep 4: Medical Device Software",
                    link2:  "https://www.youtube.com/watch?v=i6pgH8Cf238",
                    href: "",
                    img: "/assets/img/health-img/HCWebinar_Ep-4.jpg",
                    link:true,
                    alt:"Technology’s Impact on Healthcare Ep 4: Medical Device Software",
                    title:"Technology’s Impact on Healthcare Ep 4: Medical Device Software"
                },
                {
                    content: "Technology’s Impact on Healthcare Ep 3: Remote Patient Monitoring",
                    authorName: ["Dr. Mannas Samant ,"],
                    date: "14 October 2022",
                    link1: "Technology’s Impact on Healthcare Ep 3: Remote Patient Monitoring",
                    link2:  "https://www.youtube.com/watch?v=lTYhSdPSXCU",
                    href: "",
                    img: "/assets/img/health-img/HCWebinar_Ep-3.jpg",
                    link:true,
                    alt:"Technology’s Impact on Healthcare Ep 3: Remote Patient Monitoring",
                    title:"Technology’s Impact on Healthcare Ep 3: Remote Patient Monitoring"
                },
                {
                    content: "Technology’s Impact on Healthcare Ep 2: FHIR/Interoperability",
                    authorName: ["Dr. Mannas Samant ,"],
                    date: "07 October 2022",
                    link1: "Technology’s Impact on Healthcare Ep 2: FHIR/Interoperability",
                    link2:  "https://www.youtube.com/watch?v=5jJAqOdKD_4",
                    href: "",
                    img: "/assets/img/health-img/HCWebinar_Ep-2.jpg",
                    link:true,
                    alt:"Technology’s Impact on Healthcare Ep 2: FHIR/Interoperability",
                    title:"Technology’s Impact on Healthcare Ep 2: FHIR/Interoperability"
                },
                {
                    content: "Technology's Impact on Healthcare Ep 1: EHRs",
                    authorName: ["Dr. Mannas Samant ,"],
                    date: "30 September 2022",
                    link1: "Technology's Impact on Healthcare Ep 1: EHRs",
                    link2:  "https://www.youtube.com/watch?v=1nF0K3lOjv4",
                    href: "",
                    img: "/assets/img/health-img/HCWebinar_Ep-1.jpg",
                    link:true,
                    alt:"Technology's Impact on Healthcare Ep 1: EHRs",
                    title:"Technology's Impact on Healthcare Ep 1: EHRs"
                },
                {
                    content: "Webinar On A Deep Dive into DeFi",
                    authorName: ["Shaan Ray ,"],
                    date: "26 August 2022",
                    link1: "Webinar On A Deep Dive into DeFi",
                    link2:  "https://www.youtube.com/watch?v=eIQi5BjkNm8",
                    href: "",
                    img: "/assets/img/resource/DAOs-SmartContracts.jpg",
                    link:true,
                    alt:"Webinar On A Deep Dive into DeFi",
                    title:"Webinar On A Deep Dive into DeFi"
                }, 
                {
                    content: "DAOs & Smart Contracts",
                    authorName: ["Shaan Ray ,"],
                    date: "19 August 2022",
                    link1: "DAOs & Smart Contracts",
                    link2:  "/assets/img/webinar-videos/DAOs-SmartContracts.mp4",
                    href: "",
                    img: "/assets/img/resource/DAOs-SmartContracts.jpg",
                    link:true,
                    alt:"DAOs & Smart Contracts",
                    title:"DAOs & Smart Contracts"
                }, 
                {
                    content: "What is a DAO?",
                    authorName: ["Shaan Ray ,"],
                    date: "12 August 2022",
                    link1: "What is a DAO?",
                    link2:  "/assets/img/webinar-videos/DAO.mp4",
                    href: "",
                    img: "/assets/img/resource/DAO.jpg",
                    link:true,
                    alt:"What is a DAO?",
                    title:"What is a DAO?"
                }, 
                  {
                    content: "Understanding Non Fungible Token Standards",
                    authorName: ["Shaan Ray ,"],
                    date: "05 Aguest 2022",
                    link1: "Understanding Non Fungible Token Standards",
                    link2:  "/assets/img/webinar-videos/non-fungilible-token.mp4",
                    href: "",
                    img: "/assets/img/resource/non-fungilible-token.jpg",
                    link:true,
                    alt:"Understanding Non Fungible Token Standards",
                    title:"Understanding Non Fungible Token Standards"
                }, 
                {
                    content: "Understanding Crypto Security",
                    authorName: ["Shaan Ray"],
                    date: "28 October 2020",
                    link1: "Understanding Crypto Security",
                    link2:  "/assets/img/webinar-videos/crypto-security-event.mp4",
                    href: "",
                    img: "/assets/img/webinar-videos/crypto-security-event.jpg",
                    link:true,
                    alt:"Understanding Crypto Security",
                    title:"Understanding Crypto Security"
                }, 
                 {
                    content: "Blockchain Applications in Online Marketplace",
                    authorName: ["Shaan Ray ," , "John Chappell"],
                    date: "25 June 2020",
                    link1: "Blockchain Applications in Online Marketplace",
                    link2:  VIDEO_LINK.URL + "blockchain-app-in-online-market-place(1).mp4",
                    href: "",
                    img: "/assets/img/resource/1.png",
                    link:true,
                    alt:"Blockchain Applications in Online Marketplace",
                    title:"Blockchain Applications in Online Marketplace"
                },
                {
                    content: "Exploring the Possibilities of Blockchain in Healthcare",
                    authorName: ["Shaan Ray"],
                    date: "25 March 2020",
                    link1: "Exploring the Possibilities of Blockchain in Healthcare",
                    link2: VIDEO_LINK.URL + "explore-the-possibilities-in-healthcare(2).mp4",
                    href: "",
                    img: "/assets/img/resource/2.png",
                    link:true,
                    alt:"Exploring the Possibilities of Blockchain in Healthcare",
                    title:"Exploring the Possibilities of Blockchain in Healthcare"
                },
                {
                    content: "How can blockchain be leveraged for fintech data aggregation?",
                    authorName: ["Shaan Ray"],
                    date: "30 Jan 2020 & 31 Jan 2020",
                    link1: "How can blockchain be leveraged for fintech data aggregation?",
                    link2: VIDEO_LINK.URL + "how-can-blockchain-be-leveraged-for-fintech-data-aggregation-webinar-recording-Jan-30-31(3).mp4",
                    href: "",
                    link:true,
                    img: "/assets/img/resource/3.png",
                    alt:"How can blockchain be leveraged for fintech data aggregation?",
                    title:"How can blockchain be leveraged for fintech data aggregation?"
                },
                {
                    content: "STABLE COINS: Currency for a digitized world (Repeat)",
                    authorName: ["Shaan Ray"],
                    date: "17 Dec 2019",
                    link1: "STABLE COINS: Currency for a digitized world (Repeat)",
                    link2: VIDEO_LINK.URL + "how-can-blockchain-be-leveraged-for-fintech-data-aggregation-webinar-recording-Jan-30-31(3).mp4",
                    href: "",
                    link:true,
                    img: "/assets/img/resource/4.png",
                    alt:"STABLE COINS: Currency for a digitized world (Repeat)",
                    title:"STABLE COINS: Currency for a digitized world (Repeat)"
                },
                {
                    content: "STABLE COINS: Currency for a digitized world",
                    authorName: ["Shaan Ray"],
                    date: "29 Nov 2019",
                    link1: "STABLE COINS: Currency for a digitized world (Repeat)",
                    link2: VIDEO_LINK.URL + "stable-coins-webinar(5).mp4",
                    href: "",
                    link:true,
                    img: "/assets/img/resource/5.png",
                    alt:"STABLE COINS: Currency for a digitized world",
                    title:"STABLE COINS: Currency for a digitized world"
                },
                {
                    content: "Co-creating an Effective Design Brief",
                    authorName: ["Chippy Diac Vivekanandah"],
                    date: "07 Nov 2019",
                    link1: "",
                    link2: "",
                    href: "https://www.slideshare.net/ionixxtechnologies/cocreating-an-effective-design-brief",
                    img: "/assets/img/resource/6.jpeg",
                    link:false,
                    alt:"Co-creating an Effective Design Brief",
                    title:"Co-creating an Effective Design Brief"
                },
                {
                    content: "Blockchain and the Future of Data Management",
                    authorName: ["Shaan Ray"],
                    date: "13 Sep 2019",
                    link1: "Blockchain and the Future of Data Management",
                    link2: "/assets/img/webinar-videos/stable-coins-webinar(5).mp4",
                    href: VIDEO_LINK.URL + "Webinar_Blockchain and the Future of Data Management_Sep 13 2019 (online-video-cutter.com)(7).mp4",
                    link:true,
                    img: "/assets/img/resource/7.jpg",
                    alt:"Blockchain and the Future of Data Management",
                    title:"Blockchain and the Future of Data Management"
                },
                {
                    content: "Blockchain Technology's Legal Implications",
                    authorName: ["Shaan Ray"],
                    date: "26 Jun 2019",
                    link1: "",
                    link2: "",
                    href: "https://www.bigmarker.com/ionixx-technologies1/Blockchain-Technology-s-Legal-Implications3-2019-06-26-08-30-pm?bmid=1cb3556ba4fe",
                    img: "/assets/img/resource/8.jpg",
                    link:true,
                    alt:"Blockchain Technology's Legal Implications",
                    title:"Blockchain Technology's Legal Implications"
                },
                {
                    content: "Blockchain Oracles",
                    authorName: ["Shaan Ray"],
                    date: "23 Apr 2019",
                    link1: "",
                    link2: "",
                    href: "https://www.bigmarker.com/ionixx-technologies1/Blockchain-Oracles?bmid=1cb3556ba4fe",
                    link:true,
                    img: "/assets/img/resource/9.jpg",
                    alt:"Blockchain Oracles",
                    title:"Blockchain Oracles"
                },
                {
                    content: "JPM Coin",
                    authorName: ["Shaan Ray"],
                    date: "28 Feb 2019",
                    link1: "JPM Coin",
                    link2: VIDEO_LINK.URL + "stable-coins-webinar(5).mp4",
                    href: VIDEO_LINK.URL + "Webinar_Blockchain_JPM Coin_28 Feb 2019.mp4",
                    link:true,
                    img: "/assets/img/resource/10.jpg",
                    alt:"JPM Coin",
                    title:"JPM Coin"
                },
            ]
        }
    }
    viewMoreOnPress = () => {
        let arrLength = this.state.cardList.length;
        let remainder = this.state.cardList.slice(this.state.end, arrLength).length;
         this.setState({
             buttonShow : !this.state.buttonShow
         },()=>{
             if(this.state.buttonShow){
                 this.setState({
                     end:  3
                 })
             window.scrollTo(0,850);        
             }else{
                 this.setState({
                     end: this.state.end + remainder
                 })
             }
         })
        
     }
    configureVideoModalState = (videoModalTitle, videoModalUrl) => {
        this.setState({videoModalTitle, videoModalUrl, showVideo: true})
    }

    render() {
        return (
            <>	
             <Helmet>
                <title>Events,Webinars,Workshops & Conferences for Ionixx Technologies </title>
                <meta name="description" content="Keep updated on our Events, Webinars, Workshops, Conferences and other useful resources. Ionixx organizes various activities for easy participation and access to all information in one place." />
                <link rel="canonical" href="https://www.ionixxtech.com/resources/events" />
            </Helmet>
            <ReactSEOMetaTags
                render={(el) => <Helmet>{el}</Helmet>}
                website={{ 
                    name: "Ionixx Technologies", 
                    title:"Events,Webinars,Workshops & Conferences for Ionixx Technologies", 
                    description: "Keep updated on our Events, Webinars, Workshops, Conferences and other useful resources. Ionixx organizes various activities for easy participation and access to all information in one place. ",
                    type:"website",
                    image:'/assets/img/ionixx-logo.png',
                    url:"https://www.ionixxtech.com/resources/events"
                }}
                twitter={{ 
                    twitter: "https://www.ionixxtech.com/resources/events" }}
                /> 
            <div className="Resources Events">
                <div className="blog-content">
                    <div className="">
                        <div className="main-head-div">
                            <h4 className="main-head-1">
                                <span className="primary-span">Events</span>
                            </h4>
                            <p className="press-info">Ionixx hosts regular events & webinars to showcase our thought
                                leadership and industry insights on a wide array of topics that cover Software
                                Development, Design, Blockchain, and FinTech and other topics of interest.
                            </p>
                        </div>
                        <div>
                            <div className="in-news-common">
                                <div className="main-head-div">
                                    <h4 className="main-head-1">
                                        <span className="primary-span">Latest Event</span>
                                    </h4>
                                </div>
                                {/* <div className="upcoming-detail-common">
                                    <div className="upcoming-detail-card">
                                        <div className="event-detail-card-img">
                                            <img src="/assets/img/resource/detailed-event.png" alt="Cryptocurrency Wallets"></img>
                                        </div>
                                        <div className="event-detailed-right-info">
                                            <h4 className="detailed-head">Cryptocurrency Wallets : " A Secure Future For Digital Financial Ecosystems."</h4>
                                            
                                                <div className="webinar-info-paras">
                                            <p>
                                                <span className="event-info-head">Webinar Speaker :</span>
                                                <span className="event-info-detail">Shaan Ray</span>
                                            </p>
                                            <p>
                                                <span className="event-info-head">Date :</span>
                                                <span className="event-info-detail">24 September 2020</span>
                                            </p>
                                            </div>
                                            <div className="btn register-btn btn-primary">
                                                <a className="event-link" href="https://meeting.zoho.in/meeting/register?sessionId=1359707193" target={'_blank'}>
                                                    <span>Register Now</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                                <div className="upcoming-detail-common">
                                    <div className="upcoming-detail-card">
                                        <div className="event-detail-card-img">
                                            <img src="/assets/newsletter_images/news-feed.jpg" alt=" Digital IDs and the Internet of Things: The Future of Identity & Security. "></img>
                                        </div>
                                        <div className="event-detailed-right-info">
                                            <h4 className="detailed-head">Boosting Security in IoT Devices with Digital IDs </h4>
                                            
                                                <div className="webinar-info-paras">
                                            <p>
                                                <span className="event-info-head">Webinar Speaker :</span>
                                                <span className="event-info-detail">Shaan Ray</span>
                                            </p>
                                            <p>
                                                <span className="event-info-head">Date :</span>
                                                <span className="event-info-detail">04 August 2023 | 10 AM EST</span>
                                            </p>
                                            </div>
                                            <div className="btn register-btn btn-primary">
                                                <a className="event-link" href="https://youtu.be/BO9tUxK_DLw" target={'_blank'}>
                                                    <span>Watch Now</span>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="main-head-div">
                                    <h4 className="main-head-1">
                                        <span className="primary-span">Past Events</span>
                                    </h4>
                                </div>
                                
                                <div className="tab-card event-card porti-card">
                                  
                                    <div className="row press-row" >
                                       
                                    {
                                        this.state.cardList.length > 0 ? this.state.cardList.slice(this.state.start, this.state.end)?.map((item)=>{
                                            return ( item.link === true ? 
                                                <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 press-col">
                                                    <div className="port-card press-card event-card">
                                                    <Link onClick={() => this.configureVideoModalState(item.link1, item.link2)}>
                                                        <div className="whole-port">
                                                            <div className="card-img">
                                                            <img className="" src={item.img} title={item.title} alt={item.alt}></img>
                                                            </div>
                                                            <div className="press-card-type">
                                                                <span className="press-purple-bg">EVENT</span>
                                                            </div>
                                                            <div className="detailed-card-head event-card-head">
                                                             <h4>{item.content}</h4>
                                                            </div>
                                                            <div className="press-content-author">
                                                                    <div className="author-common-hold">
                                                                        <div className="author-title">
                                                                            <p>Webinar Speaker :</p>
                                                                        </div>
                                                                        <div  className="author-names">
                                                                            <p className="author-name">
                                                                                {item.authorName?.map((data)=>{return <span className="cust-auth-name">{data}</span>})}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                    <div className="author-common-hold">
                                                                        <p className="author-title">
                                                                            <span>Date :</span>
                                                                        </p>
                                                                        <p className="webinar-time">
                                                                            <span className="created-date">{item.date}</span>
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            <div className="watch-link-hold">
                                                                <span> Watch Now</span>
                                                            </div>
                                                        </div>
                                                        </Link>
                                                    </div>
                                                </div>
                                                : <div className="col-xl-4 col-lg-4 col-md-4 col-sm-12 press-col">
                                                <div className="port-card press-card event-card">
                                                <a href={item.href} target={'_blank'}>
                                                    <div className="whole-port">
                                                        <div className="card-img">
                                                        <img className="" src={item.img}></img>
                                                        </div>
                                                        <div className="press-card-type">
                                                            <span className="press-purple-bg">EVENT</span>
                                                        </div>
                                                        <div className="detailed-card-head event-card-head">
                                                            <h4>{item.content}</h4>
                                                        </div>
                                                        <div className="press-content-author">
                                                                <div className="author-common-hold">
                                                                    <div className="author-title">
                                                                        <p>Webinar Speaker :</p>
                                                                    </div>
                                                                    <div  className="author-names">
                                                                        <p className="author-name">
                                                                        {item.authorName?.map((data)=>{return <span className="cust-auth-name">{data}</span>})}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <div className="author-common-hold">
                                                                    <p className="author-title">
                                                                        <span>Date :</span>
                                                                    </p>
                                                                    <p className="webinar-time">
                                                                        <span className="created-date">{item.date}</span>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        <div className="watch-link-hold">
                                                        <span> Watch Now</span>
                                                        </div>
                                                    </div>
                                                    </a>
                                                </div>
                                            </div>
                                            )
                                        }) : 
                                        <div className="text-center no-data">No data found</div>
                                    }
                                {
                                    this.state.cardList.length > 3 && this.state.buttonShow === true? <div className="view-more">
                                    <button onClick={this.viewMoreOnPress} >
                                    {this.state.cardList.length > 3 && this.state.buttonShow ?  "View More" : "View Less" }
                                        <span><img className={!this.state.buttonShow ?"rotate-view-more": "" }src="/assets/img/blue-arrow.svg"/></span>
                                    </button>
                                </div>  : ""
                                }   
                                        
                                    </div>
                                    

                                </div>
                                {
                                    this.state.cardList.length > 3 && this.state.buttonShow === false? <div className="view-more">
                                    <button onClick={this.viewMoreOnPress} >
                                    <span><img className={!this.state.buttonShow ?"rotate-view-more mt-0 mb-0": "" }src="/assets/img/blue-arrow.svg"/></span>   
                                    {this.state.cardList.length > 3 && this.state.buttonShow ?  "View More" : "View Less" }
                                    </button>
                                </div>  : ""
                                }   
                            </div>
                        </div>
                    </div>
                </div>
                <WatchVideo show={this.state.showVideo} title={this.state.videoModalTitle}
                            videoUrl={this.state.videoModalUrl} handleClose={() => {
                    this.setState({showVideo: false})
                }}/>
            </div>
            </>    
        );
    }

}

export default Events;
