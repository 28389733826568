import React from "react";
import "../../resources.css";
import { ResourceRoute } from "../../resource-routing";
import { Link } from "react-router-dom";
import NewsHeadings from "./common-action/news-heading";
import NewsSubscription from "./common-action/subscripbe-section";

class NewsIssue79 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pageEnabled: false,
    };
  }

  render() {
    return (
      <div className="newdesigned_issue_details">
        <NewsHeadings
          buttondDetail="Design"
          categoryType="design"
          description="Android 12 preview: First look at Google's radical new design | The Verge"
        />

        <div className="newsissuedetailed">
          <div className="detailed-common">
            {/* <div className="back-to-common">
                    <span className="back-to-icon-hold">
                        <img src="/assets/img/resource/back-to-new.svg"  alt="back to new"></img>
                    </span>
                    <Link  to={ResourceRoute.NEWSLETTER}><span className="back-to-link">Newsletter</span></Link>
                </div> */}

            <div className="news-letter whats-on-hold">
              <div className="issue-main-head">
                <h4>What’s on?</h4>
              </div>
              <div className="issue-main-info">
                <div className="issue-sub-head">
                  <a
                    target={"_blank"}
                    href="https://techxplore.com/news/2021-05-microsoft-internet-explorer.html"
                  >
                    <h4>
                      Microsoft announces retirement of Internet Explorer|
                      Techxplore.com
                    </h4>
                  </a>
                </div>
                <div className="issue-news-info">
                  <p>
                    Microsoft said it is retiring Internet Explorer, the browser
                    it created more than 25 years and which is now largely
                    abandoned as people instead use competitors like Google's
                    Chrome or Apple's Safari. "We are announcing that the future
                    of Internet Explorer on Windows 10 is in Microsoft Edge,"
                    the company said in a blog post Wednesday, referring to its
                    other browser.
                  </p>
                </div>
              </div>
              <div className="issue-main-info">
                <div className="issue-sub-head">
                  <a
                    target={"_blank"}
                    href="https://www.foxbusiness.com/markets/paypal-cryptocurrency-withdrawal-third-party-wallet-transfers"
                  >
                    <h4>
                      PayPal developing cryptocurrency withdrawal function for
                      third-party wallet transfers | Fox Business
                    </h4>
                  </a>
                </div>
                <div className="issue-news-info">
                  <p>
                    PayPal is working on a new feature that will allow users to
                    withdraw Bitcoin and other cryptocurrencies and transfer
                    them into third-party digital wallets. Paypal's general
                    manager of blockchain, crypto and digital currencies, Jose
                    Fernandez da Ponte, opened up about the withdrawal
                    function's development during Coindesk's Consensus 2021
                    conference on Wednesday.
                  </p>
                </div>
              </div>
              <div className="issue-main-info">
                <div className="issue-sub-head">
                  <a
                    target={"_blank"}
                    href="https://techcrunch.com/2021/05/26/visa-takes-a-swipe-in-fintech-builds-new-online-marketplace/"
                  >
                    <h4>
                      Visa takes a swipe in fintech, builds new online
                      marketplace | Techcrunch
                    </h4>
                  </a>
                </div>
                <div className="issue-news-info">
                  <p>
                    Visa said today it has expanded its Visa FinTechPartner
                    Connect, a program designed to help financial institutions
                    quickly connect with a “vetted and curated” set of
                    technology providers. The program initially launched in
                    Europe in November of 2020, and now is available in the
                    U.S., Asia Pacific, Latin American and CEMEA (Central
                    Europe, Middle East and Africa). Visa has worked to identify
                    fintechs that can help banks and financial institutions
                    (that are clients of Visa’s) as well as other fintechs
                    “create digital-first experiences, without the cost and
                    complexity of building the back-end technology in-house.”
                  </p>
                </div>
              </div>
            </div>
            <div className="news-letter top-picks-hold">
              <div className="issue-main-head">
                <h4>Top Picks</h4>
              </div>
              <div className="issue-main-info">
                <div className="issue-sub-head">
                  <a
                    target={"_blank"}
                    href="https://www.theverge.com/22439777/android-12-design-features-widgets-first-look-google"
                  >
                    <h4>
                      Android 12 preview: First look at Google's radical new
                      design | The Verge
                    </h4>
                  </a>
                </div>
                <div className="issue-news-info">
                  <p>
                    Google announced the latest beta for Android 12 at Google
                    I/O. It has an entirely new design based on a system called
                    “Material You,” featuring big, bubbly buttons, shifting
                    colors, and smoother animations. It is “the biggest design
                    change in Android’s history,” according to Sameer Samat, VP
                    of product management, Android and Google Play. That might
                    be a bit of hyperbole, especially considering how many
                    design iterations Android has seen over the past decade, but
                    it’s justified.
                  </p>
                </div>
              </div>
            </div>
            <div className="news-letter other-reads-hold">
              <div className="issue-main-head">
                <h4>Other Reads</h4>
              </div>
              <div className="issue-main-info">
                <div className="issue-sub-head">
                  <a
                    target={"_blank"}
                    href="https://fortune.com/2021/05/21/china-ban-bitcoin-price-bubble-crypto/"
                  >
                    <h4>Why China cracked down on Bitcoin | Fortune</h4>
                  </a>
                </div>
                <div className="issue-news-info">
                  <p>
                    China just cracked down on privately mined cryptocurrency
                    and we saw the market for Bitcoin fall down a cliff. In an
                    international context of economic instability due to the
                    continuing coronavirus pandemic, skyrocketing commodity
                    prices, and geopolitical tensions, the People’s Bank of
                    China has emphasized its orientation toward financial,
                    employment, trade, and investment stability.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <NewsSubscription categoryType="Design" />
      </div>
    );
  }
}

export default NewsIssue79;
