import React from 'react';
import "../../resources.css";
import { ResourceRoute } from '../../resource-routing';
import { Link } from "react-router-dom";
import NewsHeadings from "./common-action/news-heading";
import NewsSubscription from "./common-action/subscripbe-section";

class NewsIssue05 extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            pageEnabled: false,
        }

    }

    render() {
        return (
          <div className="newdesigned_issue_details">
            <NewsHeadings
              buttondDetail="Web3 & Blockchain"
              categoryType="web3"
              description="Jack Dorsey Wants to Help You Create Your Own Twitter | WIRED"
            />

            <div className="newsissuedetailed">
              <div className="detailed-common">
                {/* <div className="back-to-common">
                    <span className="back-to-icon-hold">
                        <img src="/assets/img/resource/back-to-new.svg"  alt="bact to new"></img>
                    </span>
                    <Link  to={ResourceRoute.NEWSLETTER}><span className="back-to-link">Newsletter</span></Link>
                </div> */}

                <div className="news-letter whats-on-hold">
                  <div className="issue-main-head">
                    <h4>What’s on?</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        href="https://edition.cnn.com/2019/12/09/tech/china-us-computers-software/index.html"
                        target={"_blank"}
                      >
                        <h4>
                          China reportedly bans foreign technology in its
                          government and public offices | CNN
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        China is reportedly planning to ban all government
                        offices and public institutions from using foreign
                        software and computers in a move that could dent sales
                        by US tech companies and increase tensions at a crucial
                        moment in the trade war. Beijing had ordered that all
                        hardware and software be removed within three years,
                        according to a report by the Financial Times. It cited
                        brokerage firm China Securities as estimating that as
                        many 30 million pieces of hardware would need to be
                        replaced as a result.
                      </p>
                    </div>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        href="https://www.benzinga.com/markets/cryptocurrency/19/12/14968732/nike-gets-blockchain-patent-to-let-users-breed-own-shoes?utm_source=feedburner&utm_medium=feed&utm_campaign=Feed%3A+benzinga+%28Benzinga+News+Feed%29"
                        target={"_blank"}
                      >
                        <h4>
                          Nike Gets Blockchain Patent To Let Users 'Breed' Own
                          Shoes | Benzinga{" "}
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Oregon-based footwear giant Nike Inc has been awarded a
                        blockchain patent by the U.S. Patent and Trademark
                        Office to create digital versions of its shoes. In the
                        patent published on the U.S. Patent and Trademark Office
                        website on Tuesday, Nike said that its customers would
                        be able to register the purchase of their physical shoes
                        with a unique identification number.{" "}
                      </p>
                    </div>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        href="https://www.bloomberg.com/news/articles/2019-12-10/next-big-wave-of-technology-unicorn-listings-could-be-in-india"
                        target={"_blank"}
                      >
                        <h4>
                          Next Big Wave of Tech Unicorn Listings Could Be in
                          India | Bloomberg Technology{" "}
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        India’s stock markets are set for a wave of unicorn
                        listings, according to technology venture capitalist and
                        former Infosys Ltd. official Mohandas Pai. Pai, who is
                        also chairman of Securities and Exchange Board of India
                        committees on primary markets and financial technology,
                        said 10 to 15 internet and tech companies are looking to
                        list in India over the next three years. These firms are
                        likely to be valued at $300 million to $10 billion, he
                        said in an interview.
                      </p>
                    </div>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        href="https://www.theverge.com/2019/12/12/21012997/microsoft-new-windows-logo-fluent-design-icons"
                        target={"_blank"}
                      >
                        <h4>
                          Microsoft reveals new Windows logo design and 100
                          modern app icons | The Verge{" "}
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Microsoft is tweaking its Windows logo and the icons for
                        many of the operating system’s apps. We’ve known for a
                        year that the software maker has been planning an icon
                        overhaul, and the company’s new Office icons were only
                        the start. Microsoft is now redesigning more than 100
                        icons across the company with new colors, materials, and
                        finishes.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="news-letter top-picks-hold">
                  <div className="issue-main-head">
                    <h4>Top Picks</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        href="https://www.wired.com/story/jack-dorsey-help-you-create-own-twitter/"
                        target={"_blank"}
                      >
                        <h4>
                          Jack Dorsey Wants to Help You Create Your Own Twitter
                          | WIRED
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Twitter has formed a new engineering team dedicated to
                        building a new decentralized social media system. The
                        CEO tweeted that he's hiring a team to develop open
                        source standards for decentralized social networks. His
                        hope: It will spawn better ways to combat hate and
                        harassment.{" "}
                      </p>
                    </div>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        href="https://www.forbes.com/sites/georgebradt/2019/12/10/how-to-win-with-a-design-focused-strategy/#50ecf6c5135b"
                        target={"_blank"}
                      >
                        <h4>
                          How To Win With A Design Focused Strategy | CNET{" "}
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        As described in my earlier article on What It Takes To
                        Accelerate Through A Strategic Inflection Point, if
                        there is a change in your situation or your ambitions,
                        you need to jump-shift your strategy, organization and
                        operations altogether, all at the same time. There are
                        four primary areas of strategic focus: design, produce,
                        deliver, and service. The choice of which of those areas
                        on which to focus dictates your organizational and
                        operational choices.{" "}
                      </p>
                    </div>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        href="https://www.technologyreview.com/2019/10/10/132652/why-its-time-to-start-talking-about-blockchain-ethics/"
                        target={"_blank"}
                      >
                        <h4>
                          Why it’s time to start talking about blockchain ethics
                          | MIT Technolgy Review
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        If blockchain technology can be reasonably expected to
                        make a significant difference in society, then it
                        deserves its own field of ethics, just like
                        biotechnology, artificial intelligence, and nuclear
                        technology, argues Rhys Lindmark, head of community and
                        long-term societal impact at MIT’s Digital Currency
                        Initiative.{" "}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="news-letter other-reads-hold">
                  <div className="issue-main-head">
                    <h4>Other Reads</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        href="https://www.telegraphindia.com/opinion/nrc-and-the-citizenship-bill-are-the-greatest-threat-to-indian-democracy-today/cid/1695315"
                        target={"_blank"}
                      >
                        <h4>
                          The greatest threat to Indian democracy today |
                          Telegraph{" "}
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        A friend, demoralized by the Bharatiya Janata Party’s
                        emphatic victory in May, said ruefully that Narendra
                        Modi could now write Annihilation of Caste, given how
                        decisively his party swept Uttar Pradesh despite the
                        subaltern caste mosaic pieced together by Mayawati and
                        Akhilesh Yadav. Having suggested in print on the eve of
                        the election that the mahagathbandhan might be the
                        fulcrum on which India’s politics might turn, I could
                        only nod. The National Register of Citizens and the
                        citizenship bill are part of the BJP's agenda to
                        institutionalize discrimination opines Historian Mukul
                        Kesavan.{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <NewsSubscription categoryType="Web3" />
          </div>
        );
    }

}

export default NewsIssue05;
