import React from 'react';
import "../../resources.css";
import { ResourceRoute } from '../../resource-routing';
import { Link } from "react-router-dom";
import NewsHeadings from "./common-action/news-heading";
import NewsSubscription from "./common-action/subscripbe-section";

class NewsIssue08 extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            pageEnabled: false,
        }

    }

    render() {
        return (
          <div className="newdesigned_issue_details">
            <NewsHeadings
              buttondDetail="Web3 & Blockchain"
              categoryType="web3"
              description="Will 2020 be the year blockchain overcomes its hype? | World Economic Forum"
            />

            <div className="newsissuedetailed">
              <div className="detailed-common">
                {/* <div className="back-to-common">
                    <span className="back-to-icon-hold">
                        <img src="/assets/img/resource/back-to-new.svg"  alt="bact to new"> </img>
                    </span>
                    <Link  to={ResourceRoute.NEWSLETTER}><span className="back-to-link">Newsletter</span></Link>
                </div> */}

                <div className="news-letter whats-on-hold">
                  <div className="issue-main-head">
                    <h4>What’s on?</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.coindesk.com/china-may-soon-have-its-first-blockchain-exchange-traded-fund"
                      >
                        <h4>
                          China May Soon Have Its First Blockchain
                          Exchange-Traded Fund | CoinDesk
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        China Securities Regulatory Commission (CSRC), the
                        country's financial watchdog, has recently received an
                        application for listing an exchange-traded fund (ETF)
                        that will track blockchain-related stocks as underlying
                        assets.
                      </p>
                    </div>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://techbeacon.com/devops/best-techbeacon-2019-devops-lessons-learn"
                      >
                        <h4>
                          Best of TechBeacon 2019: DevOps lessons to learn from
                          | TechBeacon.com{" "}
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Even so, many companies struggled to get the most out of
                        their DevOps initiatives because of process
                        inefficiencies, infrastructure deficiencies, and a
                        variety of other issues. TechBeacon's list of the 12
                        best DevOps stories for 2019 examines many of the issues
                        that are top of mind for DevOps practitioners and for
                        organizations that are considering implementing it, or
                        that are trying to figure out ways to improve upon
                        existing processes.{" "}
                      </p>
                    </div>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://fortune.com/2019/12/18/california-consumer-privacy-act-data-nationwide/"
                      >
                        <h4>
                          New California Law Giving Consumers Control Over Their
                          Data Sets Off a Scramble | Fortune{" "}
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        New California Law Giving Consumers Control Over Their
                        Data Sets Off a Scramble | Fortune
                      </p>
                    </div>
                  </div>
                </div>
                <div className="news-letter top-picks-hold">
                  <div className="issue-main-head">
                    <h4>Top Picks</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.weforum.org/agenda/2020/01/blockchain-predictions-2020/"
                      >
                        <h4>
                          Will 2020 be the year blockchain overcomes its hype? |
                          World Economic Forum{" "}
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Another year has rolled on by, and while many things in
                        the blockchain space have changed, a lot remains the
                        same. 2019 saw a continued cooling of indiscriminate
                        funding and a renewed focus on quality over hype. At the
                        World Economic Forum, while they ensure that there will
                        be greater blockchain adoption, which may deem
                        inevitable, occurring in ways that support inclusion and
                        avoid replicating the consolidation of power that
                        currently exists, particularly in the financial system.
                      </p>
                    </div>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="fastcompany.com/90444313/these-design-trends-ruled-the-2010s-its-time-to-let-them-go"
                      >
                        <h4>
                          These design trends ruled the 2010s. It’s time to let
                          them go | Fast Company{" "}
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        The end of a decade is a time for reflection. Ten years
                        is enough time for culture to truly shift, for better
                        and for worse—and for design to follow suit. Looking
                        back at the 2010s, FastCo. has compiled a list of the
                        reigning design trends that—while perhaps right for the
                        time—are no longer right for where the world is going.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="news-letter other-reads-hold">
                  <div className="issue-main-head">
                    <h4>Other Reads</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.nytimes.com/2020/01/01/world/australia/fires.html"
                      >
                        <h4>
                          Why the Fires in Australia Are So Bad | New York Times{" "}
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        This fire season has been one of the worst in
                        Australia’s history, with at least 15 people killed,
                        hundreds of homes destroyed and millions of acres
                        burned. And summer is far from over. This week,
                        thousands of residents and vacationers in southeastern
                        Australia were forced to evacuate to shorelines as bush
                        fires encircled communities and razed scores of
                        buildings. Military ships and aircraft were deployed on
                        Wednesday to deliver water, food, and fuel to towns cut
                        off by the fires.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <NewsSubscription categoryType="Web3" />
          </div>
        );
    }

}

export default NewsIssue08;
