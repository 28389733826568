import React from 'react';
import "../../resources.css";
import {ResourceRoute} from '../../resource-routing';
import {Link} from "react-router-dom";

class DecemberFortnight2 extends React.Component {
    constructor(props){
        super(props)

    }
    render(){
        return (
            <div className="BlockchainDetailed">
                                <div className="back-to-common">
                    <span className="back-to-icon-hold">
                        <img src="/assets/img/resource/back-to-new.svg"  alt="bact to new"></img>
                    </span>
                    <Link  to={ResourceRoute.BLOCKCHAIN}><span className="back-to-link">Blockchain Bulletin</span></Link>
                </div>
                <div className="download-common inner-section-1">
                    <div>
                        <div className="main-head-div">
                            <h4 className="main-head-1">
                                <span className="primary-span">December Fortnight 2 Round-up Blockchain & Crypto Brief</span>
                            </h4>
                            <p className="press-info">( December 28, 2019 )</p>
                        </div>
                    </div>    
                </div>
                <div class="bulletin-head-details bulletin-sub-heads">
                    <h4>
                        <span className="primary-span">Finance</span>
                    </h4>
                    <ul className="bulletin-links-ul">
                        <li>
                            <a href="https://www.coindesk.com/basket-backed-saga-stablecoin-goes-live-in-the-uk" target={'_blank'}>Saga Monetary Technologies has launched its SGA stablecoin backed by a basket of fiat currencies.</a>
                        </li>
                        <li>
                            <a href="https://www.tokendaily.co/p/if-bitcoin-looks-like-it-isn-t-trading-it-s-because-it-isn-t" target={'_blank'}>About 9.1 million bitcoins in circulation, representing about 51%, haven’t been exchanged in the past 6 months.</a>
                        </li>
                    </ul>
                </div>
                <div class="bulletin-head-details bulletin-sub-heads">
                    <h4>
                        <span className="primary-span">Law</span>
                    </h4>
                    <ul className="bulletin-links-ul">
                        <li>
                            <a href="https://www.theblockcrypto.com/linked/51024/sec-proposes-wider-definition-of-accredited-investor-to-expand-market-access" target={'_blank'}>SEC has proposed a wider definition of ‘accredited investor’ to increase market access to investments.</a>
                        </li>
                        <li>
                            <a href="https://cointelegraph.com/news/new-draft-bill-aiming-to-classify-crypto-assets-introduced-in-us-congress" target={'_blank'}>A U.S. lawmaker has introduced a draft bill dubbed ‘Crypto-Currency Act of 2020’ to bring regulatory clarity in the crypto sector. The bill aims to clarify which federal agencies should regulate cryptocurrencies.</a>
                        </li>
                    </ul>
                </div>
                <div class="bulletin-head-details bulletin-sub-heads">
                    <h4>
                        <span className="primary-span">Other</span>
                    </h4>
                    <ul className="bulletin-links-ul">
                        <li>
                            <a href="https://www.theblockcrypto.com/daily/49715/upstart-exchange-btse-to-raise-50m-for-exchange-token-on-blockstreams-liquid-network" target={'_blank'}>Upstart exchange BTSE has launched a token sale to raise $50 million on Blockstream’s Liquid Network.</a>
                        </li>
                        <li>
                            <a href="https://www.theblockcrypto.com/post/49779/ledgerx-loses-both-co-founders-paul-and-juthica-chou" target={'_blank'}>Effective immediately, the cofounders of derivative bitcoin exchange LedgerX have been placed on leave and replaced by 30-year Wall Street veteran. This comes after its botched bitcoin futures launch.</a>
                        </li>
                        <li>
                            <a href="https://finance.yahoo.com/news/nike-receives-patent-tokenize-shoes-201839752.html?guccounter=1&guce_referrer=aHR0cHM6Ly93d3cuZ29vZ2xlLmNvbS8&guce_referrer_sig=AQAAABDr2jUJguYmoz4h7q1PJA1_Ipv5Bh9srVp3cdutM-qWM-vysDw2iuJ1Ltgh7zD_hITMx0mSUtUMj9eOdMSvlFM0xlsC-YHcMDgl4w5tZy8MusTYjLmNgMLWX1-_hbx0cckYMtx3_qhuGszPURB-RRYPvUO9P5mAHlFkeCmGZiTc" target={'_blank'}>Nike has received patent to tokenize shoes on the Ethereum Blockchain. It intends to generate unique IDs and ERC 721 tokens for some shoes that people can unlock on buying them. The users could get more control over their shoes after this, for example, they can set limits on how many copies or clones of their proprietary shoes can be made.</a>
                        </li>
                        <li>
                            <a href="https://bloomberg.com/news/articles/2019-12-09/money-laundering-battle-drives-japan-banks-to-jpmorgan-network" target={'_blank'}>Over 80 Japanese banks have shown interest in joining JPMorgan’s blockchain payments network, the Interbank Information Network, which is the highest from any single country on the network, an executive director at JPMorgan told Bloomberg.</a>
                        </li>
                        <li>
                            <a href="https://www.coindesk.com/new-york-regulator-details-changes-to-contentious-bitlicense" target={'_blank'}>The New York Department of Financial Services is preparing to introduce changes to BitLicense, which could also make the process for listing coins more rigorous.</a>
                        </li>
                        <li>
                            <a href="https://www.theblockcrypto.com/post/50089/elliptic-builds-a-solution-for-banks-to-help-them-decide-which-crypto-exchanges-to-do-business-with" target={'_blank'}>Elliptic, a blockchain analytics firm, has built a solution to help banks decide which crypto exchanges they should work with.</a>
                        </li>
                        <li>
                            <a href="https://www.theblockcrypto.com/post/50089/elliptic-builds-a-solution-for-banks-to-help-them-decide-which-crypto-exchanges-to-do-business-with" target={'_blank'}>The People’s Bank of China may not be too far from launching its digital renminbi in two cities.</a>
                        </li>
                        <li>
                            <a href="https://www.reuters.com/article/us-crypto-currencies-ing-exclusive/exclusive-ing-working-on-digital-assets-custody-technology-sources-idUSKBN1YF2GN" target={'_blank'}>Dutch Bank ING is developing technology to help clients store their digital assets safely.</a>
                        </li>
                        <li>
                            <a href="https://www.coindesk.com/russias-largest-darknet-market-is-hawking-an-ico-to-fund-global-expansion" target={'_blank'}>Russia’s largest darknet drug market called Hydra is planning to raise $146 million via an ICO to fund global expansion.</a>
                        </li>
                        <li>
                            <a href="https://www.coindesk.com/chinas-internet-firewall-has-blocked-access-to-ethereum-block-explorer-etherscan-io" target={'_blank'}>China’s Great Firewall used by the government to access foreign internet sites has blocked etherscan.io, a block explorer site that can be used to view Ethereum transactions.</a>
                        </li>
                        <li>
                            <a href="https://www.reuters.com/article/us-crypto-currencies-mining/chinas-bitcoin-miners-scoop-up-greater-production-power-research-idUSKBN1YF1PB" target={'_blank'}>Bitcoin miners in China control over 65% of the network’s processing power, according to new research.</a>
                        </li>
                        <li>
                            <a href="https://www.engadget.com/2019-12-11-twitter-open-social-media-standard.html" target={'_blank'}>Twitter CEO Jack Dorsey has announced that it will fund a small independent team of architects, engineers, and designers to develop an open and decentralized standard for social media that Twitter will ultimately be a client of.</a>
                        </li>
                        <li>
                            <a href="https://www.theblockcrypto.com/linked/50496/uk-crypto-payments-firm-to-shut-down-due-to-new-eu-anti-money-laundering-gui" target={'_blank'}>UK crypto payments firm Bottle Pay, three months after raising a $2 million seed round, is shutting down due to concerns over European Union’s 5th Anti-Money- Laundering-Derivative, which will take effect in January 2020.</a>
                        </li>
                        <li>
                            <a href="https://www.coindesk.com/state-street-teams-up-with-winklevoss-founded-gemini-exchange-for-digital-assets-trial" target={'_blank'}>State Street has collaborated with crypto custodian Gemini Trust for a new digital assets trial.</a>
                        </li>
                        <li>
                            <a href="https://www.theblockcrypto.com/post/50964/blockchain-venture-firm-fenbushi-gets-into-staking-business-aiming-to-attract-the-asian-market" target={'_blank'}>Aiming to attract the Asian market, blockchain venture company Fenbushi has stepped into staking business.</a>
                        </li>
                        <li>
                            <a href="https://www.theblockcrypto.com/post/50882/ecb-builds-proof-of-concept-for-anonymous-transactions-using-central-bank-backed-digital-currency" target={'_blank'}>The European Central Bank has developed a proof of concept for a digital currency system to allow users to make low-value expenditures anonymously - which they call anonymity vouchers.</a>
                        </li>
                        <li>
                            <a href="https://www.theblockcrypto.com/post/51237/ripple-raises-200m-series-c-to-boost-xrp-adoption" target={'_blank'}>Ripple has raised a whopping $200 million in Series C funding and looks to boost the adoption of its cryptocurrency XRP and its native blockchain network, the XRP ledger.</a>
                        </li>
                    </ul>
                </div>
            </div>          
        );
    }

}

export default DecemberFortnight2;
