import React from "react";
import "../../resources.css";
import { ResourceRoute } from "../../resource-routing";
import { Link } from "react-router-dom";
import NewsHeadings from "./common-action/news-heading";
import NewsSubscription from "./common-action/subscripbe-section";
import ReactSEOMetaTags from "react-seo-meta-tags";
import Helmet from "react-helmet";
import { Modal } from "react-bootstrap";

class NewsIssue85 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pageEnabled: false,
      modalShow: false,
      letftStickyContnet: (
        <div className="news-sticky">
          <div className="left-text">
            ePrescribing <span>webinar</span> By Dr. Maanas Samant
          </div>
          <div className="news-pic">
            <img src="/assets/img/health-img/maanas.png" />
          </div>
        </div>
      ),
    };
  }
  handleClose() {
    this.setState({ modalShow: false });
  }
  handleShow() {
    this.setState({ modalShow: true });
  }
  render() {
    return (
      <>
        <Helmet>
          <title>Newsletter | Ionixx Technologies</title>
          <meta
            name="description"
            content="Explore ePrescription in healthcare with Dr. Maanas who takes us through the evolution, roadblocks, and future of eRx."
          />
          <link
            rel="canonical"
            href="https://www.ionixxtech.com/resources/newsletter"
          />
        </Helmet>
        <ReactSEOMetaTags
          render={(el) => <Helmet>{el}</Helmet>}
          website={{
            name: "Ionixx Technologies",
            title:
              "Evolution of ePrescribing: Revolutionizing Healthcare & Navigating the Digital Shift ",
            description:
              "Explore ePrescription in healthcare with Dr. Maanas who takes us through the evolution, roadblocks, and future of eRx.",
            type: "website",
            image: "/assets/newsletter_images/issue_85/banner.png",
            url: "https://www.ionixxtech.com/resources/newsletter/news-issue-85",
          }}
          twitter={{
            twitter:
              "https://www.ionixxtech.com/resources/newsletter/news-issue-85",
          }}
        />
        <div className="newdesigned_issue_details issue_83_design issue-85">
          <NewsHeadings
            buttondDetail="HealthTech"
            categoryType="healthtech"
            description="Evolution of ePrescribing: Revolutionizing Healthcare & Navigating the Digital Shift."
            newDetail={true}
          />
          {/* <div className='sticky-button banner-modal-button'>
                    <button onClick={() => this.handleShow()}>ePrescription webinar with Dr.Maanas Samant</button>
                 </div> */}
          {/*modal content */}
          <Modal
            className="newsletter-modal theme-change"
            centered
            show={this.state.modalShow}
            onHide={() => this.handleClose()}
          >
            <Modal.Header closeButton>
              <div className="banner-section">
                <div className="banner-left">
                  <img
                    src="/assets/newsletter_images/issue_85/banner.png"
                    alt="defi"
                  />
                </div>
                <div className="banner-right">
                  <span>Webinar | 22 August 2023</span>
                  {/* <strong>Digital IDs and the Internet of Things: The Future of Identity & Security </strong> */}
                  <p>
                    Explore ePrescription in healthcare with Dr. Maanas Samant
                    who takes us through the evolution, roadblocks, and future
                    of eRx.
                  </p>
                  <a
                    className="read-more"
                    href="https://www.bigmarker.com/ionixx-technologies1/The-Evolution-Impact-and-Opportunities-of-ePrescribing-006672d4a45239798841b834"
                    target="_blank"
                  >
                    Watch Now
                  </a>
                </div>
              </div>
            </Modal.Header>
          </Modal>

          <div className="newsissuedetailed">
            <div className="detailed-common">
              {/* <div className="back-to-common">
                            <span className="back-to-icon-hold">
                                <img src="/assets/img/resource/back-to-new.svg" alt="back to new"></img>
                            </span>
                            <Link to={ResourceRoute.NEWSLETTER}><span className="back-to-link">Newsletter</span></Link>
                        </div> */}

              <div className="headlines-warp">
                <div className="top-news">
                  <i>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="234"
                      height="140"
                      viewBox="0 0 234 140"
                      fill="none"
                    >
                      <g clip-path="url(#clip0_462_1735)">
                        <path
                          d="M45.2302 29.1322L42.6203 21.6729H0.319092V29.1322V34.9968V130.329H140.56V29.1322H45.2302Z"
                          fill="#00AEEF"
                          stroke="#263238"
                          stroke-miterlimit="10"
                        />
                        <path
                          d="M137.93 39.0251H2.94177V124.135H137.93V39.0251Z"
                          fill="white"
                          stroke="#263238"
                          stroke-miterlimit="10"
                        />
                        <path
                          d="M112.278 31.947H45.2111V36.0325H112.278V31.947Z"
                          fill="white"
                          stroke="#263238"
                          stroke-miterlimit="10"
                        />
                        <path
                          d="M6.1898 31.2925L4.25629 33.1478L6.26 35.289"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M4.25629 33.1477H8.55086"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M17.1528 31.2925L19.0927 33.1478L17.0826 35.289"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M19.0927 33.1477H14.7917"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M28.3263 32.8999C28.1158 32.0612 27.2607 31.3687 26.3545 31.3687C25.2889 31.3687 24.421 32.2328 24.421 33.2939C24.421 34.3549 25.2889 35.2191 26.3545 35.2191C26.8204 35.2191 27.2479 35.0539 27.5797 34.7807"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M27.0245 33.116H28.5305V31.3369"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M38.581 32.487L36.7943 31.0447L35.0969 32.487V35.6131H38.581V32.487Z"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M37.4707 33.332H36.1115V35.505H37.4707V33.332Z"
                          fill="#263238"
                        />
                        <path
                          d="M35.0969 32.4871L34.0759 33.4147"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M38.581 32.4871L39.5382 33.3321"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M37.9365 25.5295L40.6549 28.3252"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M40.6996 25.574L37.8918 28.2807"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M130.822 32.6968H136.201"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M130.822 33.9041H136.201"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M130.822 35.1113H136.201"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M130.822 36.3184H136.201"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M105.622 7.77701L103.012 0.317627H60.7111V7.77701V13.6416V108.974H200.952V7.77701H105.622Z"
                          fill="#00AEEF"
                          stroke="#263238"
                          stroke-miterlimit="10"
                        />
                        <path
                          d="M198.329 17.6699H63.3402V102.779H198.329V17.6699Z"
                          fill="white"
                          stroke="#263238"
                          stroke-miterlimit="10"
                        />
                        <path
                          d="M172.67 10.5918H105.603V14.6773H172.67V10.5918Z"
                          fill="white"
                          stroke="#263238"
                          stroke-miterlimit="10"
                        />
                        <path
                          d="M66.5818 9.93726L64.6483 11.7926L66.6584 13.9338"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M64.6483 11.7925H68.9493"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M77.5512 9.93726L79.4847 11.7926L77.481 13.9338"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M79.4847 11.7925H75.1838"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M88.7248 11.5447C88.5142 10.706 87.6591 10.0134 86.753 10.0134C85.6873 10.0134 84.8195 10.8775 84.8195 11.9386C84.8195 12.9997 85.6873 13.8638 86.753 13.8638C87.2188 13.8638 87.6463 13.6986 87.9782 13.4254"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M87.423 11.7608H88.9226V9.98169"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M98.9731 11.1318L97.1863 9.68945L95.4889 11.1318V14.2642H98.9731V11.1318Z"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M97.8691 11.9768H96.5099V14.1498H97.8691V11.9768Z"
                          fill="#263238"
                        />
                        <path
                          d="M95.4889 11.1318L94.4679 12.0595"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M98.9731 11.1318L99.9366 11.9769"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M98.3285 4.18066L101.047 6.96999"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M101.092 4.21875L98.2902 6.92548"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M191.22 11.3416H196.6"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M191.22 12.5488H196.6"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M191.22 13.7561H196.6"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M191.22 14.9631H196.6"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M138.358 38.485L135.742 31.032H93.4468V38.485V44.3559V139.682H233.681V38.485H138.358Z"
                          fill="#00AEEF"
                          stroke="#263238"
                          stroke-miterlimit="10"
                        />
                        <path
                          d="M231.058 48.3779H96.0695V133.487H231.058V48.3779Z"
                          fill="white"
                          stroke="#263238"
                          stroke-miterlimit="10"
                        />
                        <path
                          d="M205.406 41.3062H138.339V45.3917H205.406V41.3062Z"
                          fill="white"
                          stroke="#263238"
                          stroke-miterlimit="10"
                        />
                        <path
                          d="M99.3176 40.6516L97.3777 42.5069L99.3878 44.6482"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M97.3777 42.5068H101.679"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M110.281 40.6516L112.214 42.5069L110.21 44.6482"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M112.214 42.5068H107.913"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M121.454 42.2591C121.244 41.4204 120.388 40.7278 119.482 40.7278C118.417 40.7278 117.549 41.5919 117.549 42.653C117.549 43.7141 118.417 44.5782 119.482 44.5782C119.948 44.5782 120.376 44.413 120.707 44.1398"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M120.152 42.4751H121.658V40.6897"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M131.702 41.8398L129.922 40.4038L128.218 41.8398V44.9722H131.702V41.8398Z"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M130.598 42.6848H129.239V44.8578H130.598V42.6848Z"
                          fill="#263238"
                        />
                        <path
                          d="M128.218 41.8398L127.197 42.7739"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M131.702 41.8398L132.666 42.6913"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M131.064 34.8887L133.783 37.678"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M133.821 34.9331L131.02 37.6398"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M223.95 42.0559H229.329"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M223.95 43.2632H229.329"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M223.95 44.4705H229.329"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M223.95 45.6775H229.329"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M116.668 91.6094H144.797"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M116.668 95.2246H144.797"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M116.668 98.8401H144.797"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M116.668 102.462H144.797"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M116.668 106.077H144.797"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M116.668 109.692H144.797"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M116.668 113.308H144.797"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M116.668 116.929H144.797"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M116.668 120.545H144.797"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M149.748 91.6094H177.871"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M149.748 95.2246H177.871"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M149.748 98.8401H177.871"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M149.748 102.462H177.871"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M149.748 106.077H177.871"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M149.748 109.692H177.871"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M149.748 113.308H177.871"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M149.748 116.929H177.871"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M149.748 120.545H177.871"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M149.748 62.96H177.871"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M149.748 66.5752H177.871"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M149.748 70.197H177.871"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M149.748 73.8123H177.871"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M149.748 77.4275H177.871"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M149.748 81.043H177.871"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M149.748 84.6646H177.871"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M149.748 88.28H177.871"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M182.688 91.6094H210.817"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M182.688 95.2246H210.817"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M182.688 98.8401H210.817"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M182.688 102.462H210.817"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M182.688 106.077H210.817"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M182.688 109.692H210.817"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M182.688 113.308H210.817"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M182.688 116.929H210.817"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M182.688 120.545H210.817"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M135.48 68.7228C135.627 68.3098 135.697 67.7825 135.697 67.3949C135.697 65.7492 134.025 65.6539 132.372 65.6539H131.958V65.2664H139.143V65.6539C137.273 65.6539 136.616 66.8358 136.01 68.6021L132.2 79.9818C131.907 80.8269 131.664 81.4559 130.771 81.4559H130.701L127.325 70.5845L124.173 79.9818C123.879 80.8269 123.636 81.4559 122.743 81.4559H122.673L117.772 65.6539H116.049V65.2664H122.36V65.6539H120.567L124.204 78.1201H124.351L127.095 69.8093L125.806 65.6539H124.083V65.2664H130.394V65.6539H128.601L132.238 78.1201H132.385L135.493 68.7228H135.48Z"
                          fill="#00AEEF"
                        />
                        <path
                          d="M211.085 61.6638H182.14V87.7145H211.085V61.6638Z"
                          fill="#00AEEF"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M211.085 72.5479C210.3 72.586 209.349 72.7067 208.194 72.9545C203.798 73.9076 202.649 80.1851 197.678 80.1851C193.275 80.1851 192.898 74.2825 186.587 73.52C184.679 73.2913 183.231 73.6725 182.133 74.3079V87.7017H211.079V72.5415L211.085 72.5479Z"
                          fill="#263238"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M191.756 67.6237C191.756 69.4092 190.301 70.8578 188.508 70.8578C186.715 70.8578 185.26 69.4092 185.26 67.6237C185.26 65.8383 186.715 64.3896 188.508 64.3896C190.301 64.3896 191.756 65.8383 191.756 67.6237Z"
                          fill="white"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_462_1735">
                          <rect width="234" height="140" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </i>
                  <h4>Top Blog</h4>
                  <span>
                    Navigating AI's Impact in Healthcare From Telemedicine to
                    Personalized Care. Discover the transformative influence of
                    AI, ML, and DL in healthcare while addressing challenges and
                    optimizing patient care.
                  </span>
                  <a
                    href="https://blog.ionixxtech.com/ai-in-healthtech/"
                    target="_blank"
                  >
                    Read More...
                  </a>
                </div>
                <div className="headlines-content">
                  <strong className="overall-heading">
                    HealthTech News Roundup
                  </strong>
                  <div className="headlines-list">
                    <a
                      href="https://www.meddeviceonline.com/doc/key-factors-to-gain-regulatory-approvals-for-innovative-rapid-diagnostic-devices-0001"
                      target="_blank"
                    >
                      5 Key Factors To Gain Regulatory Approvals for Innovative
                      Rapid Diagnostic Devices
                    </a>
                    <p>
                      COVID-19 showcased rapid diagnostics' global impact with
                      6.5B tests. Post-pandemic, healthcare players embrace
                      rapid tests' potential. Point-of-care and at-home testing
                      markets anticipate substantial growth due to care model
                      shifts.{" "}
                    </p>
                  </div>
                  <div className="headlines-list">
                    <a
                      href="https://healthtechmagazine.net/article/2023/08/5-questions-prepare-generative-ai-healthcare"
                      target="_blank"
                    >
                      5 Questions to Prepare for Generative AI in Healthcare
                    </a>
                    <p>
                      What will the wider adoption of artificial
                      intelligence-powered tools look like in healthcare? Two
                      legal experts share their perspectives.{" "}
                    </p>
                  </div>
                  <div className="headlines-list">
                    <a href="https://healthtechmagazine.net/article/2023/08/integrating-smart-hospital-solutions-into-health-it-ecosystems">
                      Integrating Smart Hospital Solutions into Health IT
                      Ecosystems
                    </a>
                    <p>
                      Healthcare organizations don’t need to start from zero
                      when creating a well-connected environment that’s prepared
                      for the future.
                    </p>
                  </div>
                  <div className="headlines-list">
                    <a
                      href="https://www.healthcareitnews.com/video/point-care-data-exchange-gets-boost-ai"
                      target="_blank"
                    >
                      Point-of-care data exchange gets a boost from AI
                    </a>
                    <p>
                      John Nebergall, COO of Consensus Cloud Solutions, explains
                      why interoperability standards must make the transition
                      from securing patient health data to delivering a clear
                      output for clinicians, and how AI can help.
                    </p>
                  </div>
                  <div className="headlines-list">
                    <a
                      href="https://www.forbes.com/sites/katiejennings/2023/08/09/digital-health-company-babylon-shutters-us-business-lays-off-employees/?sh=52cbec5b6205"
                      target="_blank"
                    >
                      Digital Health Company Babylon Shutters U.S. Business,
                      Lays Off Employees
                    </a>
                    <p>
                      Babylon, the troubled digital health company that’s been
                      looking for a buyer, told the Texas Workforce Commission
                      it would permanently close.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="new-detail-option">
            <div className="news-first-section">
              <div className="heading">
                <div className="detail-heading">
                  <h5>
                    <span>1</span>Evolution of ePrescribing
                  </h5>
                </div>
              </div>
              <div className="newsissuedetailed">
                <div className="news-left">
                  <i>
                    {" "}
                    <img
                      src="/assets/newsletter_images/issue_85/issue85-1.png"
                      alt="piggy-bank"
                    />
                  </i>
                  {/* <strong>Tackling Alert Fatigue</strong> */}
                  <b>
                    The conventional paper and faxed prescriptions are being
                    replaced by a computer-based process that involves
                    generating, transmitting, and completing medical
                    prescriptions.{" "}
                  </b>
                </div>
                <div className="news-right">
                  <p>
                    In the rapidly changing landscape of healthcare, one
                    technological advancement has stood out for its
                    transformative impact: ePrescribing. The roots of
                    ePrescribing can be traced back over two decades, coinciding
                    with the rise of the internet and digitalization. Initially
                    met with skepticism, this digital transition marked a
                    departure from traditional paper prescriptions, paving the
                    way for a more efficient, accurate, and interconnected
                    healthcare system.
                  </p>
                  <ul className="stroke-list">
                    <li>
                      <span></span>
                      <b>Challenges and Triumphs: Overcoming Resistance</b>
                      <p>
                        The early days of ePrescribing were not without
                        challenges. Healthcare providers were accustomed to the
                        familiarity of handwritten prescriptions, and adapting
                        to a digital format required a shift in mindset.
                      </p>
                    </li>
                    <li>
                      <span></span>
                      <b>Legislative Impetus: A Turning Point in Adoption</b>
                      <p>
                        The pivotal moment in the evolution of ePrescribing
                        arrived in 2003 with the introduction of the Medicare
                        Prescription Drug, Improvement, and Modernization Act.
                        This legislation played a crucial role in incentivizing
                        the adoption of electronic prescriptions, propelling
                        ePrescribing into the mainstream of healthcare
                        practices. The 21st Century Cures Act of 2016 solidified
                        ePrescribing's embrace. It mandated 'Electronic
                        Prescribing for Controlled Substances' (EPCS), demanding
                        physicians electronically prescribe 70% of controlled
                        substances for Medicare Part D patients, except in cases
                        where an exception or an approved waiver.
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="news-first-section">
              <div className="heading">
                <div className="detail-heading">
                  <h5>
                    <span>2</span>A Welcome Change: ePrescribing's Positive
                    Impact on Care Continuum
                  </h5>
                </div>
              </div>
              <div className="newsissuedetailed">
                <div className="news-left">
                  <i>
                    <img
                      src="/assets/newsletter_images/issue_85/issue85-2.png"
                      alt="bitcoin-logo"
                    />
                  </i>
                  {/* <strong>Tackling Alert Fatigue</strong> */}
                  <b>
                    ePrescribing revolutionizes healthcare for providers,
                    pharmacists, and patients. Automation optimizes renewals,
                    accesses records, and checks interactions, elevating care
                    quality
                  </b>
                </div>
                <div className="news-right">
                  <p>
                    {" "}
                    ePrescribing has etched a significant and lasting imprint,
                    extending its influence across healthcare providers,
                    pharmacists, and, most crucially, patients.
                  </p>
                  <ul className="stroke-list">
                    <li>
                      <span></span>
                      <b>
                        Automated Precision: Elevating Patient Care through
                        ePrescribing
                      </b>
                      <p>
                        Seamlessly integrating with Electronic Health Records
                        (EHRs) and Pharmacy Benefit Managers (PBMs), this
                        innovation opens pathways to patient medication
                        histories. Real-time checks for potential drug
                        interactions, allergies, and insurance formulary
                        adherence have emerged as pivotal tools, shaping a new
                        era of optimized patient care.
                      </p>
                    </li>
                    <li>
                      <span></span>
                      <b>Empowering Pharmacists, Enhancing Safety</b>
                      <p>
                        Pharmacists, too, have reaped the rewards of
                        ePrescribing's prowess. The perennial challenge of
                        deciphering illegible prescriptions has been vanquished,
                        effectively mitigating the risks of dispensing errors.
                        The result is a streamlined prescription process,
                        characterized by heightened efficiency, diminished wait
                        times, and an elevated standard of service.
                      </p>
                    </li>
                    <li>
                      <span></span>
                      <b>
                        Patient-Centric Paradigm: A Prescription for Empowerment
                      </b>
                      <p>
                        The convenience of arriving at the pharmacy to find
                        prescriptions promptly prepared eliminates the stress of
                        misplaced paper prescriptions. Heightened medication
                        adherence and a notable reduction in medication errors
                        and adverse drug events synergistically amplify patient
                        safety and treatment effectiveness, fostering a more
                        secure and confident path to well-being.
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="news-first-section">
              <div className="heading">
                <div className="detail-heading">
                  <h5>
                    <span>3</span>Roadblocks in ePrescribing
                  </h5>
                </div>
              </div>
              <div className="newsissuedetailed">
                <div className="news-left">
                  <i>
                    {" "}
                    <i>
                      <img
                        src="/assets/newsletter_images/issue_85/issue85-3.png"
                        alt="cryptocurrency"
                      />
                    </i>
                  </i>
                  <b>
                    ePrescribing revolutionizes healthcare but faces obstacles.
                    Time-intensive input, user experience issues, data silos,
                    and security gaps hinder its potential. Addressing these
                    challenges is crucial for transformative and secure
                    healthcare."
                  </b>
                </div>

                <div className="news-right">
                  <p>
                    ePrescribing has ushered in a new era of healthcare
                    transformation, touching the lives of providers,
                    pharmacists, and patients. However, beneath its remarkable
                    achievements lie intricate challenges that warrant our
                    attention.
                  </p>
                  <ul className="stroke-list">
                    <li>
                      <span></span>
                      <b>Time Constraints: A Double-Edged Sword</b>
                      <p>
                        One formidable challenge lies in the time investment
                        ePrescribing demands. The transition from swiftly
                        jotting a prescription on paper to the meticulous input
                        of details and dosage in electronic systems can be
                        time-intensive. This is particularly evident in bustling
                        clinical settings where efficiency is paramount. The
                        delegation of this task to physician assistants or
                        nurses introduces another layer of potential
                        miscommunication.
                      </p>
                    </li>
                    <li>
                      <span></span>
                      <b>User Experience: A Design Imperative</b>
                      <p>
                        The subpar user experience (UX) within ePrescribing
                        systems stands as another hurdle. Cumbersome interfaces
                        hinder healthcare providers' navigation through
                        essential steps. While regulatory and safety concerns
                        have informed design choices, there's a clear call for
                        user-centric design enhancements.{" "}
                      </p>
                    </li>
                    <li>
                      <span></span>
                      <b>
                        Breaking Down Silos: The Interoperability Imperative
                      </b>
                      <p>
                        Data silos pose a critical challenge, often locking
                        patients within closed ecosystems. This fragmentation
                        results from intricate business decisions, spanning
                        insurance, health data, and more. Though
                        interoperability strides have been made, there's a
                        pressing need for broader data integration.
                      </p>
                    </li>
                    <li>
                      <span></span>
                      <b>Security in the Spotlight</b>
                      <p>
                        Enhanced security measures notwithstanding, ePrescribing
                        systems remain vulnerable to exploitation. Instances of
                        fraudulent activity expose gaps that require urgent
                        attention. The ethical responsibility to safeguard
                        patient information fuels the call for more robust
                        security protocols.{" "}
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="news-first-section"></div>

            <div className="news-first-section">
              <div className="heading">
                <div className="detail-heading">
                  <h5>
                    <span>4</span>Ahead of the Curve: Opportunities in
                    ePrescribing
                  </h5>
                </div>
              </div>
              <div className="newsissuedetailed">
                <div className="newissuedetailed_banner">
                  <span className="banner-wall">
                    <img src="/assets/newsletter_images/ebook-notepad-clip.png" />
                  </span>
                  <p>
                    As we set our sights on the future of ePrescribing, it
                    becomes evident that financial incentivization holds the key
                    to propelling the Healthcare market in a positive direction.
                    While patient outcomes are undeniably paramount, the dynamic
                    landscape of evolving healthcare policies will intricately
                    shape the opportunities that lie ahead and illuminate
                    pathways for process enhancements.
                  </p>
                  <ul className="stroke-list list-style">
                    <li>
                      <b>
                        Streamlining Through Connectivity: Harnessing the Power
                        of APIs
                      </b>
                      <p>
                        While ePrescribing's time-intensive nature has posed
                        challenges, the horizon is painted with a promise of
                        streamlined efficiency. Innovations like the Common SIG
                        API, exemplified by FDB, illustrate the potential to
                        significantly reduce prescription writing time
                      </p>
                    </li>
                    <li>
                      <b>
                        AI's Transformative Touch: Elevating ePrescribing
                        Through Intelligence
                      </b>
                      <p>
                        The integration of Artificial Intelligence (AI) and
                        Machine Learning (ML) marks a profound shift in the
                        ePrescribing landscape. Predictive capabilities hold the
                        potential to revolutionize medication management.
                        Envision a scenario where AI-driven insights anticipate
                        patient medication needs and personalized
                        recommendations, and flag potential interactions or
                        allergies with unparalleled accuracy.{" "}
                      </p>
                    </li>
                    <li>
                      <b>Interoperability: A Bridge to Enhanced Efficiency</b>
                      <p>
                        Enhanced interoperability forms the cornerstone of an
                        efficient healthcare ecosystem. By fostering seamless
                        information flow across platforms, a holistic view of a
                        patient's health emerges, empowering clinicians with
                        comprehensive insights for informed clinical
                        decision-making. It is within this convergence that the
                        true potential of ePrescribing as a catalyst for
                        coordinated care emerges.
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="final-section newsissuedetailed">
            <strong>
              That’s a wrap
              <br />
              <span>Hope you enjoyed it!</span> <br />
            </strong>
            <div className="checkout-interesting">
              <a href="https://blog.ionixxtech.com/">
                Check out our interesting Blogs
              </a>
            </div>
          </div>
          <div className="events-wrap">
            <strong>Events</strong>
            <div className="events-list">
              <div className="events">
                <img src="/assets/newsletter_images/issue_83_events.png" />
                <p>
                  Here’s team Ionixx at NFTNYC along with the awesome guys from
                  @badcryptopodcast.m{" "}
                </p>
              </div>
              <div className="events">
                <img src="/assets/newsletter_images/event-2.png" />
                <p>
                  Live from #HIMSS23 in Chicago: Ionixx's CMIO, Dr. Maanas
                  Samant, MD, is connecting with healthcare innovators from
                  around the world!
                </p>
              </div>
            </div>
          </div>
          <NewsSubscription categoryType="HealthTech" />
        </div>
      </>
    );
  }
}

export default NewsIssue85;
