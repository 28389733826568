import React from 'react';
import "../../resources.css";
import {ResourceRoute} from '../../resource-routing';
import {Link} from "react-router-dom";
import NewsHeadings from "./common-action/news-heading";
import NewsSubscription from "./common-action/subscripbe-section";

class NewsIssue47 extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            pageEnabled: false,
        }

    }

    render() {
        return (
          <div className="newdesigned_issue_details">
            <NewsHeadings
              buttondDetail="Design"
              categoryType="design"
              description="Tech Giants Team Up to Fix Typography's Biggest Problem | Wired"
            />
            <div className="newsissuedetailed">
              <div className="detailed-common">
                {/* <div className="back-to-common">
                    <span className="back-to-icon-hold">
                    <img src="/assets/img/resource/back-to-new.svg"  alt="bact to new"></img>
                    </span>
                    <Link  to={ResourceRoute.NEWSLETTER}><span className="back-to-link">Newsletter</span></Link>
                </div> */}

                <div className="news-letter whats-on-hold">
                  <div className="issue-main-head">
                    <h4>What’s on?</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.forbes.com/sites/seansteinsmith/2020/09/30/blockchain-regulation-is-making-headlines-and-that-is-great-for-cryptocurrency-development/#3f35882895f6"
                      >
                        <h4>
                          Blockchain Regulation Is Making Headlines, And That Is
                          Great For Cryptocurrency Development | Forbes
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Blockchain and cryptocurrencies have been part of the
                        financial and economic conversation ever since bitcoin
                        burst into the mainstream during 2017. That said, in
                        order to become an integrated part of the financial
                        system, and to ultimately serve as the basis for an
                        alternative financial system, the blockchain and crypto
                        space need to work with some of the very regulators it
                        was designed to disrupt.
                      </p>
                    </div>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.fintechmagazine.com/digital-payments/chainstack-and-digital-asset-making-blockchain-mainstream"
                      >
                        <h4>
                          Chainstack and Digital Asset: making blockchain
                          mainstream | FinTechMagazine{" "}
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Chainstack and Digital Asset have formed a dynamic
                        partnership that will simplify blockchain deployments
                        for SMBs, corporations, and software developers. The
                        collaboration will now provide market-ready strategies
                        that benefit businesses of all sizes, regardless of
                        infrastructure.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="news-letter top-picks-hold">
                  <div className="issue-main-head">
                    <h4>Top Picks</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.wired.com/2016/09/apple-google-adobe-microsoft-join-forces-make-typographic-history/"
                      >
                        {" "}
                        <h4>
                          Tech Giants Team Up to Fix Typography's Biggest
                          Problem | Wired
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        The ideas behind variable fonts have been around awhile,
                        but the technology has only now caught up with them.
                        Even if you're not a developer or a typography nerd,
                        this is a big deal. How big? Well, Apple, Google,
                        Microsoft, and Adobe are working on it—together.__
                        __These unlikely collaborators, who are the four largest
                        platforms for digital typography, unveiled variable
                        fonts recently.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="news-letter other-reads-hold">
                  <div className="issue-main-head">
                    <h4>Other Reads</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.digitaltrends.com/features/friederike-otto-angry-weather-book/"
                      >
                        {" "}
                        <h4>
                          Supercomputers, simulations, and the new science of
                          extreme weather attribution | Digital Trends{" "}
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        In 2017, Hurricane Harvey dumped 60 inches of rain on
                        Nederland, Texas. That was over the course of a few
                        days. Notoriously rainy Seattle gets about 38 inches a
                        year. The storm caused over $125 billion worth of
                        damage, according to the National Ocean and Atmospheric
                        Administration. Was it just a bizarre event, or was it
                        caused by climate change? In the past, climate
                        scientists have been hesitant to say any particular
                        weather event, no matter how wild, was due to the
                        effects of global warming, greenhouse gases, and other
                        human causes. But Dr. Friederike Otto and the World
                        Weather Attribution team studied Harvey and determined
                        that climate change made the rainfall more intense,
                        causing between 12% and 22% more water to drop on
                        Houston and its surrounding area.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <NewsSubscription categoryType="Design" />
          </div>
        );
    }

}

export default NewsIssue47;
