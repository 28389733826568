import React from 'react';
import "../../resources.css"
import {ResourceRoute} from '../../resource-routing';
import {Link} from "react-router-dom";
import {SlideDown} from 'react-slidedown';
import 'react-slidedown/lib/slidedown.css';
import {Modal} from 'react-bootstrap';
import PaperBooksDownload from "./paper-books-download";
import {withRouter} from "react-router-dom";

// import { ToastComponent } from '../../../../components/common';
import * as contactAction from "../../../../action/contactAction";
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import ReactSEOMetaTags from 'react-seo-meta-tags';
import Helmet from 'react-helmet';
import { ROUTES } from '../../../../routes';

class WhitePapers extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            pdfUrl: "",
            pageEnabled: false,
            show: false,
            start: 0,
            end: 3,
            toast: false,
            buttonShow: false,
            cardList: [
                {
                    content1: "Blockchain 101: Lesson 1 - Blockchain Fundamentals",
                    content2: "Get your blockchain basics right with this e-book on blockchain fundamentals.",
                    title: "E-BOOK",
                    downloadEnable: true,
                    link: "",
                    downloadPage:"whitepaper-ebooks/blockchain-fundamentals",
                    downloadLink:"/assets/resource-files/Blockchain 101_Lesson 1_Blockchain Fundamentals.pdf"
                },
                {
                    content1: "Blockchain 101: Lesson 2 - Cryptography, Mining and Consensus Mechanism",
                    content2:"We break down the complexity surrounding blockchain technology with this easy-to-use e-book on cryptography and mining.",
                    title: "E-BOOK",
                    downloadEnable: true,
                    link: "",
                    downloadPage:"whitepaper-ebooks/blockchain-cryptography-mining",
                    downloadLink:"/assets/resource-files/Blockchain 101_Lesson 2_Cryptography mining and consensus mechanism.pdf"
                },
                {
                    content1: "Blockchain 101: Lesson 3 - Formation of Blockchain",
                    content2: "With this e-book, you will be able to gather the right amount of perspective on what goes into the formation of blockchain; how individual transactions are grouped together to be organized by a set of crypto rules.",
                    title: "E-BOOK",
                    downloadEnable: true,
                    link: "",
                    downloadPage:"whitepaper-ebooks/formation-of-blockchain",
                    downloadLink:"/assets/resource-files/Blockchain 101_Lesson 3_Formation of Blockchain.pdf"
                },
                {
                    content1: "Blockchain 101: Lesson 4 - Challenges of Performance and Scalability Issues with Blockchain",
                    content2: "By now, you must be familiar with the promise that the blockchain technology holds. With the bright side on top of our minds, there is also reason to pause and think if blockchain comes with its own share of challenges. In this e-book, we will explore some of the contemporary issues that it is challenged with - namely, scalability and sustainability of blockchain.",
                    title: "E-BOOK",
                    downloadEnable: true,
                    link: "",
                    downloadPage:"whitepaper-ebooks/challenges-security-blockchain",
                    downloadLink:"/assets/resource-files/Ionixx Blockchain 101_Lesson 4_Performance and Scability of Blockchain.pdf"

                },
                {
                    content1: "Blockchain 101: Lesson 5 - Transactions, Wallets, and Smart Contracts",
                    content2:"As opposed to traditional wallets where transactions happen in their physical shape and form, blockchain wallets are characterized by transactions that are largely driven by cryptos. A typical crypto transaction consists of a private and public key and are governed by laid-down crypto rules. In this lesson, we will get an understanding of how transactions work in the blockchain world and how smart contracts behave in predefined ways to execute a certain automated function.",
                    title: "E-BOOK",
                    downloadEnable: true,
                    link: "",
                    downloadPage:"whitepaper-ebooks/blockchain-wallets-smart-contracts",
                    downloadLink:"/assets/resource-files/Ionixx Blockchain 101_Lesson 5_Transactions, Wallets, and Smart Contracts.pdf"

                },
                {
                    content1: "Blockchain 101: Lesson 6 - Cryptocurrencies and Software Protocols",
                    content2:"What are protocols and why are they important, specifically in the context of cryptocurrencies? As the cryptocurrency ecosystem is growing by the day, the need to introduce new protocols are assuming prominence and relevance. In this lesson, we will look at how software protocols form a big piece of the blockchain and crypto puzzle.",
                    title: "E-BOOK",
                    downloadEnable: true,
                    link: "",
                    downloadPage:"whitepaper-ebooks/cryptocurrencies-software-protocols",
                    downloadLink:"/assets/resource-files/Ionixx Blockchain 101_Lesson 6_ Cryptocurrencies and Software Protocols.pdf"


                },
                {
                    content1: "Blockchain Technology and its Legal Implications",
                    content2:"Get the big picture about the major legal issues and litigation risks surrounding blockchain technology, dealing with enforcement, jurisdiction and other AML issues and tax implications.",
                    title: "E-BOOK",
                    downloadEnable: true,
                    link: "",
                    downloadPage:"whitepaper-ebooks/blockchain-legal-implications",
                    downloadLink:"/assets/resource-files/Blockchain Webinar_26 June_Blockchain Legal Implications.pdf"

                },
                {
                    content1: "Technology Whitepaper: Addressing the Big Question in Mobile App Development - Native, Web or Hybrid?",
                    content2:"What mobile app development approach works best for you? Native, web or hybrid? Our detailed whitepaper will walk you through several considerations to factor in and examines all three approaches, outlining the pros and cons of each.",
                    title: "WHITEPAPAER",
                    downloadEnable: true,
                    link: "",
                    downloadPage:"whitepaper-ebooks/Mobile-app-development-approach",
                    downloadLink:"/assets/resource-files/White Paper - What kind of app is right for you.pdf"
                },
                {
                    content1: "Your Ultimate Guide to Understanding Blockchain for Business!",
                    content2: "The blockchain development guide is a comprehensive resource to help business owners understand how a blockchain developer can help them solve business problems through blockchain-driven technological solutions.",
                    title: "",
                    downloadEnable: false,
                    link: ResourceRoute.BLOCKCHAINGUIDE,
                    downloadPage:"",
                    downloadLink:""
                },
                {
                    content1: "Data Aggregation: Understanding its Role, Function, and Benefits across Several Industries in the Technological World",
                    content2: "In this extensive guide, we elaborate how data aggregation and its related functions are underpinning the operations of several mainstream industries such as Fintech and Commerce. By offering several benefits such as reduced costs and faster growth rates, automating data aggregation capabilities has paved the way for exceptional customer service. Read more to understand how to optimize your company's approach to data aggregation.",
                    title: "",
                    downloadEnable: false,
                    link: ResourceRoute.DATAAGGREGATION,
                    downloadPage:"",
                    downloadLink:""
                },
                {
                    content1: "What is a multi-exchange crypto trading platform and how does it help institutional traders with algorithmic crypto trading?",
                    content2: "Our guide on crypto trading offers deep insights into what it takes to build a multi-exchange crypto trading platform and why it is important to understand the workings of the Fintech industry to be able to offer both individual and institutional traders a seamless trading experience. By delving deep into associated subjects such as microservices, cloud computing, containerization, and so on, our guide is a useful resource and works as a useful starting point to help you navigate the crypto trading space.",
                    title: "",
                    downloadEnable: false,
                    link: ResourceRoute.MULTIEXCHANGECRYPTOCURRENCY,
                    downloadPage:"",
                    downloadLink:""
                }
            ]
        }
        this.handleClose = this.handleClose.bind(this);
        //this.pushToPaperBooksDownload = this.pushToPaperBooksDownload.bind(this)
    }
    handleClose(){
        this.setState({
            show: false
        })
    }
    viewMoreOnPress = () => {
        let arrLength = this.state.cardList.length;
        let remainder = this.state.cardList.slice(this.state.end, arrLength).length;
         this.setState({
             buttonShow : !this.state.buttonShow
         },()=>{
             if(this.state.buttonShow === false && this.state.cardList.length > 3){
                 this.setState({
                     end:  3
                 })
                 window.scrollTo(0,0);
             }else{
                
                 this.setState({
                     end: this.state.end + remainder
                    
                 })
             }
         })
        
     }
    // pushToPaperBooksDownload = (url) => {
    //     // this.props.history.push(ResourceRoute.PAPERSBOOKSDOWNLOAD);
    //     this.setState({
    //         pdfUrl: url
    //     })
    // }
    pushToData = (val) => {
        this.props.history.push(val);
    }
    
   

    render() {
        return (
            <>	
            <Helmet>
                <title>Valuable Industry Insights | Whitepapers & E-Books | Ionixx Technologies</title>
                <meta name="description" content="Explore our comprehensive resource hub for the latest tech insights, including blogs, whitepapers, podcasts, infographics, and case studies, all in one place." />
                <link rel="canonical" href="https://www.ionixxtech.com/resources/whitepaper-ebooks" />
            </Helmet>
            <ReactSEOMetaTags
                render={(el) => <Helmet>{el}</Helmet>}
                website={{ 
                    name: "Ionixx Technologies", 
                    title:"Valuable Industry Insights | Whitepapers & E-Books | Ionixx Technologies ", 
                    description: "Explore our comprehensive resource hub for the latest tech insights, including blogs, whitepapers, podcasts, infographics, and case studies, all in one place.",
                    image:'/assets/img/ionixx-logo.png',
                    type:"website",
                    image:'/assets/img/ionixx-logo.png',
                    url:"https://www.ionixxtech.com/resources/whitepaper-ebooks"
                }}
                twitter={{ 
                    twitter: "https://www.ionixxtech.com/resources/whitepaper-ebooks" }}
                />
            <div className="Resources">
                <div className="blog-content newsletter-content">
                    <div className="newsletter-common">
                        <div className="main-head-div">
                            <h4 className="main-head-1">
                                <span className="primary-span">Whitepapers & e-Books</span>
                            </h4>
                            <p className="press-info">An extensive curation of useful information tailored to suit your
                                specific needs. We aim to provide our audience an enjoyable learning experience through
                                our insightful and comprehensive collection of tech resources.</p>
                        </div>
                        <div className="tab-card porti-card">
                            {
                                this.state.cardList.length > 0 ? 
                                this.state.cardList.slice(this.state.start, this.state.end)?.map((item, i)=>{
                                    return (
                                        <div className="list" key={i}>
                                            <div className="list-capt">
                                                <h4><span>{item.content1}</span></h4>
                                                <p>{item.content2}</p>
                                                {
                                                    item.title?.length > 0 && <div className="whitepaper-type"><span className="paper-yellow-bg">{item.title}</span></div>
                                                }
                                            </div>
                                            {
                                                item.downloadEnable ? <div className="download-btn">
                                                {/* <button
                                                    onClick={() => this.setState({
                                                        show:true
                                                    },()=>{
                                                        
                                                        this.pushToPaperBooksDownload(item.downloadLink)
                                                    }) }><span><img
                                                    src="/assets/img/download.svg"/></span>Download
                                                </button> */}
                                                
                                                {/* <Link
                                                    to={{
                                                        pathname: item.downloadPage,
                                                        state : item.downloadLink
                                                    }}
                                                    target="blank"
                                                    onClick={() => {
                                                        this.setState({
                                                            pdfUrl: item.downloadLink
                                                        })
                                                    }
                                                    }><span>
                                                        </span>Read more
                                                </Link> */}
                                                <span onClick={()=>{
                                                        this.props.history.push({
                                                            pathname: item.downloadPage,
                                                            state: item.downloadLink                                                     })
                                                }}>
                                                Read more </span>
                                                </div> : 
                                                <div className="download-btn">
                                                    <Link onClick={()=>
                                                        this.pushToData(item.link)
                                                    }>Read More</Link>
                                                 </div>   
                                            }
                                             
                                        </div>
                                    )
                                }): ""
                            }
                            {
                            this.state.cardList.length > 3 && this.state.buttonShow === true ? <div className={"view-more"}>
                            <button onClick={this.viewMoreOnPress} >
                            <span><img className={this.state.buttonShow ?"rotate-view-more mb-0 mt-0": "" }src="/assets/img/blue-arrow.svg" alt="arrow"/></span>
                            {this.state.cardList.length > 3 && !this.state.buttonShow ?  "View More" : "View Less" }
                               
                            </button>
                        </div>  : ""
                        } 
                             </div>      
                        {
                            this.state.cardList.length > 3 && this.state.buttonShow === false ? <div className={"view-more"}>
                            <button onClick={this.viewMoreOnPress} >
                            {this.state.cardList.length > 3 && !this.state.buttonShow ?  "View More" : "View Less" }
                                <span><img className={this.state.buttonShow ?"rotate-view-more": "" }src="/assets/img/blue-arrow.svg" alt="arrow"/></span>
                            </button>
                        </div>  : ""
                        } 
                    
                 </div>
                 <Modal className="white-paper-modal" centered show={this.state.show} onHide={this.handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Tell us a little about you</Modal.Title>
                        <span className="modal-side-close">
                            <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M19.6313 3.36869C17.4581 1.1955 14.5734 0 11.5 0C8.42656 0 5.54188 1.1955 3.36869 3.36869C1.1955 5.54188 0 8.42656 0 11.5C0 14.5734 1.1955 17.4581 3.36869 19.6313C5.54188 21.8045 8.42656 23 11.5 23C14.5734 23 17.4581 21.8045 19.6313 19.6313C21.8045 17.4581 23 14.5734 23 11.5C23 8.42656 21.8045 5.54188 19.6313 3.36869ZM18.7069 18.7069C16.7805 20.6332 14.2201 21.6932 11.5 21.6932C8.77988 21.6932 6.21949 20.6332 4.29314 18.7069C0.319444 14.7332 0.319444 8.26684 4.29314 4.29314C6.21949 2.36679 8.77988 1.30682 11.5 1.30682C14.2201 1.30682 16.7805 2.36679 18.7069 4.29314C22.6806 8.26684 22.6806 14.7332 18.7069 18.7069Z" fill="#C4C4C4"/>
                                <path d="M16.5677 6.43238C16.3112 6.17586 15.8998 6.17586 15.6433 6.43238L11.5002 10.5755L7.35708 6.43238C7.10056 6.17586 6.68915 6.17586 6.43263 6.43238C6.1761 6.68891 6.1761 7.10031 6.43263 7.35684L10.5757 11.4999L6.43263 15.643C6.1761 15.8996 6.1761 16.311 6.43263 16.5675C6.55847 16.6933 6.72787 16.7611 6.89243 16.7611C7.057 16.7611 7.2264 16.6982 7.35224 16.5675L11.4953 12.4244L15.6384 16.5675C15.7643 16.6933 15.9337 16.7611 16.0983 16.7611C16.2677 16.7611 16.4322 16.6982 16.5581 16.5675C16.8146 16.311 16.8146 15.8996 16.5581 15.643L12.4246 11.4999L16.5677 7.35684C16.8243 7.10031 16.8243 6.68891 16.5677 6.43238Z" fill="#C4C4C4"/>
                        </svg>
                        {/* <svg width="45" height="34" viewBox="0 0 45 34" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="5.5293" y="31.8203" width="45" height="3" transform="rotate(-45 5.5293 31.8203)"
                            fill="#444544"/>
                        <rect x="7.65039" width="45" height="3" transform="rotate(45 7.65039 0)" fill="#444544"/>
                        </svg> */}
                        </span>
                                {/* <img className="modal-side-close" src="assets/img/header/closeOrange.svg"></img> */}
                    </Modal.Header>
                    <Modal.Body>
                        <PaperBooksDownload pdfUrl={this.state.pdfUrl} closePopup={()=>{this.setState({show:false})}}/>
                    </Modal.Body>
                </Modal>      
                </div> 
                 
            </div>
            </>            
        );
    }

}

const mapStateToProps = (state, ownProps) => {
    return state.commonReducer;
};
  
const mapDispatchToProps = (dispatch, ownProps) => {
    return bindActionCreators(contactAction, dispatch);
};
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(withRouter(WhitePapers));
