import React from 'react';
import "../../resources.css";
import { ResourceRoute } from '../../resource-routing';
import { Link } from "react-router-dom";
import NewsHeadings from "./common-action/news-heading";
import NewsSubscription from "./common-action/subscripbe-section";

class NewsIssue55 extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            pageEnabled: false,
        }

    }

    render() {
        return (
          <div className="newdesigned_issue_details">
            <NewsHeadings
              buttondDetail="Design"
              categoryType="design"
              description="These two trends will transform product development in 2021 | Technical.ly"
            />
            <div className="newsissuedetailed">
              <div className="detailed-common">
                {/* <div className="back-to-common">
                            <span className="back-to-icon-hold">
                                <img src="/assets/img/resource/back-to-new.svg" alt="back to new"></img>
                            </span>
                            <Link to={ResourceRoute.NEWSLETTER}><span className="back-to-link">Newsletter</span></Link>
                        </div> */}

                <div className="news-letter whats-on-hold">
                  <div className="issue-main-head">
                    <h4>What’s on?</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.coindesk.com/blockchain-coalition-launches-tradable-carbon-credit-token"
                      >
                        <h4>
                          Blockchain Coalition Launches Tradable Carbon Credit
                          Token| Coin Desk
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        The Universal Protocol Alliance, a coalition of
                        blockchain companies led by Uphold and including Bittrex
                        Global, Ledger, Certik and Infinigold, announced the
                        Universal Carbon (UPCO2) token Tuesday. Each
                        blockchain-based UPCO2 token represents a certified
                        measure of carbon dioxide. They can be bought and held
                        as an investment, or burned to offset a company or
                        individual’s carbon footprint, the group said.
                      </p>
                    </div>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://techcrunch.com/2020/12/03/as-crypto-comes-back-binance-backed-injective-protocol-launches-testnet-for-its-defi-trading-platform/"
                      >
                        <h4>
                          As Crypto comes back, Binance-backed Injective
                          protocol launches Testnet for its DeFI trading
                          platform | Techcrunch
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Decentralized exchange protocols that allow crypto
                        traders and investors to trade across different
                        blockсhains have been in development for a while. A
                        significant new development now comes with the launch of
                        the “Testnet” from Injective Protocol. Injective has
                        been backed by Binance, one of the biggest centralized
                        exchanges in the crypto world. Injective Protocol is one
                        of the first universal “DeFi” (Decentralised Finance)
                        protocols for cross-chain derivatives trading, so the
                        launch of the Testnet is an important milestone.
                        Injective’s main competitors (centralized and
                        decentralized exchanges) include CME Group, BitMEX,
                        LedgerX and OKEx, among others.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="news-letter top-picks-hold">
                  <div className="issue-main-head">
                    <h4>Top Picks</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://technical.ly/dc/2020/12/02/product-development-trends-2021/"
                      >
                        <h4>
                          These two trends will transform product development in
                          2021 | Technical.ly
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        In recent years, innovation has led to revenue gains and
                        customer adoption and loyalty — think Tesla cars or
                        Peloton bikes. These products are excellent examples of
                        how technologies like software-run cars and digital
                        workout platforms can form lasting customer bonds. Yet
                        while these products are technically impressive, great
                        marketing made them beloved by the masses. In 2021, CMOs
                        will model the success of these and other companies by
                        becoming closely aligned with product development.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="news-letter other-reads-hold">
                  <div className="issue-main-head">
                    <h4>Other Reads</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://hbr.org/2020/11/put-doctors-at-the-center-of-health-care-tech"
                      >
                        <h4>
                          Put Doctors at the Center of Health Care Tech |
                          HBR.org{" "}
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        The medical field is in the midst of a major
                        transformation, fueled by a combination of legislation
                        and rapid adaptation. For some patients, this ability to
                        conduct through their computer or smartphone has been
                        life changing. It is clear that the Covid-19 pandemic,
                        despite providing some of the toughest challenges, has
                        also brought out some of the best traits in healthcare
                        organizations: agility, adaptability, and
                        innovativeness. However, we still do not know the full
                        impact of telehealth. Will it be good for patients? Of
                        course, the telehealth explosion was born out of extreme
                        necessity. But if necessity is the mother of invention,
                        is all invention good? The answer may depend on how we
                        envision success.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <NewsSubscription categoryType="Design" />
          </div>
        );
    }

}

export default NewsIssue55;
