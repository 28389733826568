import React from 'react';
import "../../resources.css";
import { ResourceRoute } from '../../resource-routing';
import { Link } from "react-router-dom";
import NewsHeadings from "./common-action/news-heading";
import NewsSubscription from "./common-action/subscripbe-section";

class NewsIssue60 extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            pageEnabled: false,
        }

    }

    render() {
        return (
          <div className="newdesigned_issue_details">
            <NewsHeadings
              buttondDetail="Design"
              categoryType="design"
              description="5 Proven Ways To Speed Up Digital Product Development | Hackernoon"
            />
            <div className="newsissuedetailed">
              <div className="detailed-common">
                {/* <div className="back-to-common">
                            <span className="back-to-icon-hold">
                                <img src="/assets/img/resource/back-to-new.svg" alt="back to new"></img>
                            </span>
                            <Link to={ResourceRoute.NEWSLETTER}><span className="back-to-link">Newsletter</span></Link>
                        </div> */}

                <div className="news-letter whats-on-hold">
                  <div className="issue-main-head">
                    <h4>What’s on?</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.coindesk.com/enterprise-blockchain-private-public-crossroads"
                      >
                        <h4>
                          Enterprise Blockchain Is at a Private-Public
                          Crossroads | Coin Desk
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        To say that 2020 was a complete miss for enterprise
                        blockchain would not be accurate. A few new networks and
                        major corporate initiatives were announced, including
                        PharmaLedger, Dole and a group of major Japanese
                        maintenance companies, the Japanese government and
                        IOTA.But many of these projects barely grew. According
                        to Prysm Group internal data dating back to 2016, the
                        average enterprise blockchain consortium has gained less
                        than one new participant beyond its founding members.
                        There are a few outliers such as Italy’s banking network
                        Spunta. But, for an industry whose primary purpose is to
                        build a network adopted by other future members, this is
                        not an encouraging figure.{" "}
                      </p>
                    </div>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.kmworld.com/Articles/News/News/Infragistics-provides-new-version-of-its-UX-and-UI-solution-to-support-Microsofts-latest-release-of-the-.NET-platform-144625.aspx"
                      >
                        <h4>
                          Infragistics provides new version of its UX and UI
                          solution to support Microsoft’s latest release of the
                          .NET platform | KMworld.com
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Infragistics, the software company responsible for the
                        UI and UX solutions powering cross-platform
                        applications, is releasing Infragistics Ultimate 20.2, a
                        complete UX and UI solution for design and development
                        teams. The new release is fully compatible with .NET 5,
                        Microsoft’s latest version of the .NET development
                        platform. With this new release of Infragistics’
                        flagship product, organizations can streamline app
                        creation from design to code with the release of the
                        all-new Indigo Design App Builder, according to the
                        vendor.{" "}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="news-letter top-picks-hold">
                  <div className="issue-main-head">
                    <h4>Top Picks</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://hackernoon.com/5-proven-ways-to-speed-up-digital-product-development-1w3j31c5"
                      >
                        <h4>
                          5 Proven Ways To Speed Up Digital Product Development
                          | Hackernoon
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        The faster you can develop a product, the sooner it gets
                        to market, and the more sales you make. Your brand
                        improves its market position and credibility by beating
                        competitors to the punch. It is important, however, not
                        to sacrifice product quality. This list of proven
                        strategies will help you achieve those things.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="news-letter other-reads-hold">
                  <div className="issue-main-head">
                    <h4>Other Reads</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.theguardian.com/commentisfree/2021/jan/08/brexit-tabloid-englishness-labour-scottish-independence"
                      >
                        <h4>
                          Brexit may spell the end of the tabloid version of
                          Englishness. Can Labour redefine it? | The Guardian
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        For too long, one version of Englishness has dominated
                        British politics. Proud, white, both confident and
                        defensive, often xenophobic, always anti-Europe, this
                        Englishness has changed as little as the tabloid front
                        pages that have bellowed it out for decades. Brexit is
                        one of its greatest victories. The continuing
                        Conservative ascendancy is another.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <NewsSubscription categoryType="Design" />
          </div>
        );
    }

}

export default NewsIssue60;
