
import {ResourceRoute} from '../resource-routing';
export const WHITE_PAPAER = [
    {
        content1: "Technology Whitepaper: Addressing the Big Question in Mobile App Development - Native, Web or Hybrid?",
        content2:"What mobile app development approach works best for you? Native, web or hybrid? Our detailed whitepaper will walk you through several considerations to factor in and examines all three approaches, outlining the pros and cons of each.",
        title: "WHITEPAPAER",
        downloadEnable: true,
        link: "",
        downloadPage:"whitepaper-ebooks/Mobile-app-development-approach",
        img:"/assets/img/press/whitepaper-1.png",
        "category": "Technology",
        downloadLink:"/assets/resource-files/White Paper - What kind of app is right for you.pdf"
    },
    {
        content1: "Your Ultimate Guide to Understanding Blockchain for Business!",
        content2: "The blockchain development guide is a comprehensive resource to help business owners understand how a blockchain developer can help them solve business problems through blockchain-driven technological solutions.",
        title: "WHITEPAPAER",
        downloadEnable: false,
        link: ResourceRoute.BLOCKCHAINGUIDE,
        downloadPage:"whitepaper-ebooks/blockchain-guide",
        img:"/assets/img/press/whitepaper-2.png",
        "category": "Healthtech",
        downloadLink:""
    },
    {
        content1: "Data Aggregation: Understanding its Role, Function, and Benefits across Several Industries in the Technological World",
        content2: "In this extensive guide, we elaborate how data aggregation and its related functions are underpinning the operations of several mainstream industries such as Fintech and Commerce. By offering several benefits such as reduced costs and faster growth rates, automating data aggregation capabilities has paved the way for exceptional customer service. Read more to understand how to optimize your company's approach to data aggregation.",
        title: "WHITEPAPAER",
        downloadEnable: false,
        link: ResourceRoute.DATAAGGREGATION,
        downloadPage:"whitepaper-ebooks/data-aggregation-platform-development",
        img:"/assets/img/press/whitepaper-3.png",
        "category": "Technology",
        downloadLink:""
    },
    {
        content1: "What is a multi-exchange crypto trading platform and how does it help institutional traders with algorithmic crypto trading?",
        content2: "Our guide on crypto trading offers deep insights into what it takes to build a multi-exchange crypto trading platform and why it is important to understand the workings of the Fintech industry to be able to offer both individual and institutional traders a seamless trading experience. By delving deep into associated subjects such as microservices, cloud computing, containerization, and so on, our guide is a useful resource and works as a useful starting point to help you navigate the crypto trading space.",
        title: "WHITEPAPAER",
        downloadEnable: false,
        link: ResourceRoute.MULTIEXCHANGECRYPTOCURRENCY,
        downloadPage:"whitepaper-ebooks/multi-exchange-crypto-trading-platform-development",
        img:"/assets/img/press/whitepaper-4.png",
        "category": "Technology",
        downloadLink:""
    }
];
export const EBOOK = [
    {
        content1: "Blockchain 101: Lesson 1 - Blockchain Fundamentals",
        content2: "Get your blockchain basics right with this e-book on blockchain fundamentals.",
        title: "E-BOOK",
        downloadEnable: true,
        link: "",
        downloadPage:"whitepaper-ebooks/blockchain-fundamentals",
        img:"/assets/img/press/lesson-1.png",
        "category": "Fintech",
        downloadLink:"/assets/resource-files/Blockchain 101_Lesson 1_Blockchain Fundamentals.pdf"
    },
    {
        content1: "Blockchain 101: Lesson 2 - Cryptography, Mining and Consensus Mechanism",
        content2:"We break down the complexity surrounding blockchain technology with this easy-to-use e-book on cryptography and mining.",
        title: "E-BOOK",
        downloadEnable: true,
        link: "",
        downloadPage:"whitepaper-ebooks/blockchain-cryptography-mining",
        img:"/assets/img/press/lesson-2.png",
        "category": "Web3",
        downloadLink:"/assets/resource-files/Blockchain 101_Lesson 2_Cryptography mining and consensus mechanism.pdf"
    },
    {
        content1: "Blockchain 101: Lesson 3 - Formation of Blockchain",
        content2: "With this e-book, you will be able to gather the right amount of perspective on what goes into the formation of blockchain; how individual transactions are grouped together to be organized by a set of crypto rules.",
        title: "E-BOOK",
        downloadEnable: true,
        link: "",
        downloadPage:"whitepaper-ebooks/formation-of-blockchain",
        img:"/assets/img/press/lesson-3.png",
        "category": "Design",
        downloadLink:"/assets/resource-files/Blockchain 101_Lesson 3_Formation of Blockchain.pdf"
    },
    {
        content1: "Blockchain 101: Lesson 4 - Challenges of Performance and Scalability Issues with Blockchain",
        content2: "By now, you must be familiar with the promise that the blockchain technology holds. With the bright side on top of our minds, there is also reason to pause and think if blockchain comes with its own share of challenges. In this e-book, we will explore some of the contemporary issues that it is challenged with - namely, scalability and sustainability of blockchain.",
        title: "E-BOOK",
        downloadEnable: true,
        link: "",
        downloadPage:"whitepaper-ebooks/challenges-security-blockchain",
        img:"/assets/img/press/lesson-4.png",
        "category": "Healthtech",
        downloadLink:"/assets/resource-files/Ionixx Blockchain 101_Lesson 4_Performance and Scability of Blockchain.pdf"

    },
    {
        content1: "Blockchain 101: Lesson 5 - Transactions, Wallets, and Smart Contracts",
        content2:"As opposed to traditional wallets where transactions happen in their physical shape and form, blockchain wallets are characterized by transactions that are largely driven by cryptos. A typical crypto transaction consists of a private and public key and are governed by laid-down crypto rules. In this lesson, we will get an understanding of how transactions work in the blockchain world and how smart contracts behave in predefined ways to execute a certain automated function.",
        title: "E-BOOK",
        downloadEnable: true,
        link: "",
        downloadPage:"whitepaper-ebooks/blockchain-wallets-smart-contracts",
        img:"/assets/img/press/lesson-5.png",
        "category": "Web3",
        downloadLink:"/assets/resource-files/Ionixx Blockchain 101_Lesson 5_Transactions, Wallets, and Smart Contracts.pdf"

    },
    {
        content1: "Blockchain 101: Lesson 6 - Cryptocurrencies and Software Protocols",
        content2:"What are protocols and why are they important, specifically in the context of cryptocurrencies? As the cryptocurrency ecosystem is growing by the day, the need to introduce new protocols are assuming prominence and relevance. In this lesson, we will look at how software protocols form a big piece of the blockchain and crypto puzzle.",
        title: "E-BOOK",
        downloadEnable: true,
        link: "",
        downloadPage:"whitepaper-ebooks/cryptocurrencies-software-protocols",
        img:"/assets/img/press/lesson-6.png",
        "category": "Fintech",
        downloadLink:"/assets/resource-files/Ionixx Blockchain 101_Lesson 6_ Cryptocurrencies and Software Protocols.pdf"


    },
    {
        content1: "Blockchain Technology and its Legal Implications",
        content2:"Get the big picture about the major legal issues and litigation risks surrounding blockchain technology, dealing with enforcement, jurisdiction and other AML issues and tax implications.",
        title: "E-BOOK",
        downloadEnable: true,
        link: "",
        downloadPage:"whitepaper-ebooks/blockchain-legal-implications",
        img:"/assets/img/press/lesson-7.png",
        "category": "Design",
        downloadLink:"/assets/resource-files/Blockchain Webinar_26 June_Blockchain Legal Implications.pdf"

    }
];