import React from 'react';
import "../../resources.css";
import {ResourceRoute} from '../../resource-routing';
import {Link} from "react-router-dom";
import NewsHeadings from "./common-action/news-heading";
import NewsSubscription from "./common-action/subscripbe-section";

class NewsIssue40 extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            pageEnabled: false,
        }

    }

    render() {
        return (
          <div className="newdesigned_issue_details">
            <NewsHeadings
              buttondDetail="Web3 & Blockchain"
              categoryType="web3"
              description="The story of how web browsers changed us forever | The Next Web"
            />
            <div className="newsissuedetailed">
              <div className="detailed-common">
                {/* <div className="back-to-common">
                    <span className="back-to-icon-hold">
                    <img src="/assets/img/resource/back-to-new.svg"  alt="bact to new"></img>
                    </span>
                    <Link  to={ResourceRoute.NEWSLETTER}><span className="back-to-link">Newsletter</span></Link>
                </div> */}

                <div className="news-letter whats-on-hold">
                  <div className="issue-main-head">
                    <h4>What’s on?</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.google.com/url?q=https://www.forbes.com/sites/seansteinsmith/2020/08/12/blockchain-adoption-is-critical-for-the-5g-economy-to-thrive/%2348fa725e1ce4&source=gmail&ust=1599218374406000&usg=AFQjCNHq5P3FLnHYO3-o0Pfjwymf3j_SWg"
                      >
                        <h4>
                          Blockchain Adoption Is Critical For The 5G Economy To
                          Thrive | Forbes
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Next generation technology tools and applications have
                        the potential to redefine the economy; blockchain will
                        need to play a leading role.There is no shortage of
                        emerging technology applications and tools that are
                        rapidly moving from the realm of science fiction to
                        practical reality. Self-driving cars, the internet of
                        things, smart devices for both individual and
                        institutional use, digital twins of physical devices,
                        and all kinds of other new ideas are quickly becoming
                        mainstream.
                      </p>
                    </div>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.google.com/url?q=https://techcrunch.com/2020/08/07/a-brief-look-at-gmails-product-development-process/&source=gmail&ust=1599218374406000&usg=AFQjCNFhB7dNqiAHp5InAQR6odbgoPrFcw"
                      >
                        <h4>
                          A look inside Gmail’s product development process |
                          Tech Crunch
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Despite Google’s stronghold on the email market, other
                        competitors have emerged over the years. Most recently,
                        we’ve seen paid email products like Superhuman and Hey
                        emerge. In light of new competitors to the space, as
                        well as Google’s latest version of Gmail that more
                        deeply integrates with Meet, Chat and Rooms, we asked
                        Gmail Design Lead Jeroen Jillissen about what makes good
                        email, how he and the team think about product design
                        and more.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="news-letter top-picks-hold">
                  <div className="issue-main-head">
                    <h4>Top Picks</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.google.com/url?q=https://thenextweb.com/syndication/2020/08/13/the-story-of-how-web-browsers-changed-us-forever/&source=gmail&ust=1599218374406000&usg=AFQjCNGshX7LuycWjB4hhjWGdqIIXEB9fQ"
                      >
                        <h4>
                          The story of how web browsers changed us forever | The
                          Next Web
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        One day, Internet Explorer was nearly the only game in
                        town, powering 96% of website visits at its peak in
                        2002. Then, quickly it turned into the app you only used
                        to download Firefox or Chrome, or so the joke went. And
                        then Internet Explorer died and turned into Chrome. From
                        creating an industry to lawsuits over monopolizing said
                        industry in under a decade may be a record. But that’s
                        the web. Everything moves faster online, from the
                        dot-com boom and bust to today’s smartphone-powered
                        world.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="news-letter other-reads-hold">
                  <div className="issue-main-head">
                    <h4>Other Reads</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.google.com/url?q=https://arstechnica.com/gadgets/2020/08/android-is-now-the-worlds-largest-earthquake-detection-network/&source=gmail&ust=1599218374406000&usg=AFQjCNEi721HXke7C3f5lwaC1k28-zLtcQ"
                      >
                        <h4>
                          Android is now the world’s largest earthquake
                          detection network | Ars Technica{" "}
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Back in 2016, Ars reported on an interesting use for the
                        bundle of sensors we carry around every day in our
                        smartphones—earthquake detection. The accelerometers in
                        your phone make a passable-enough seismometer, and
                        together with location data and enough users, you could
                        detect earthquakes and warn users as the shocks roll
                        across the landscape. The University of
                        California-Berkeley, along with funding from the state
                        of California, built an app called "MyShake" and a
                        cheap, effective earthquake detection network was born,
                        at least, it was born for people who installed the app.
                        What if you didn't need to install the app? What if
                        earthquake detection was just built in to the operating
                        system? That's the question Google is going to answer,
                        with today's announcement of the "Android Earthquake
                        Alerts System." Google is going to build what it calls
                        "the world’s largest earthquake detection network" by
                        rolling earthquake detection out to nearly every Google
                        Play Android phone.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <NewsSubscription categoryType="Web3" />
          </div>
        );
    }

}

export default NewsIssue40;
