import React from 'react';
import ReactSEOMetaTags from 'react-seo-meta-tags';
import Helmet from 'react-helmet';


class Tenshey extends React.Component {
    constructor(props) {
        super(props)
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }


    render() {
        return (
            <>	
            <Helmet>
                <title>Executive Coaching Platform for Leadership | Ionixx Tech</title>
                <meta name="description" content="Ionixx developed Tehshey, an online executive coaching platform that links diverse coaches and coachees, fostering empowered leadership." />
                <link rel="canonical" href="https://www.ionixxtech.com/portfolio/tenshey" />
            </Helmet>
           <ReactSEOMetaTags
                render={(el) => <Helmet>{el}</Helmet>}
                website={{ 
                    name: "Ionixx Technologies", 
                    title:"Executive Coaching Platform for Leadership | Ionixx Tech", 
                    description: "Ionixx developed Tehshey, an online executive coaching platform that links diverse coaches and coachees, fostering empowered leadership.",
                    type:"website",
                    image:'/assets/img/ionixx-logo.png',
                    url:"https://www.ionixxtech.com/portfolio/tenshey"
                }}
                twitter={{ 
                    twitter: "https://www.ionixxtech.com/portfolio/tenshey" }}
                /> 
            <div className="tenshey-port">
            <section className="ten-portfolio wow pulse">
                <div className="ix-container">
                   <div className="sec1">
                       <div className="row">
           
           
                           <div className="col-lg-6 col-md-12 col-12 wow slideInLeft">
                               <div className="tenshey">
                                   <img src="/assets/img/portfolio-img/tenshey-img/tenshey-bg.png" alt="Tenshey" className="tenshey-bg"/> 
                                   <img src="/assets/img/portfolio-img/tenshey-img/tensheylogo.png" alt="Tenshey" className="tensheylogo"/>
                               </div>	
                           </div>
                           <div className="col-lg-6 col-md-12 col-12 wow slideInRight">
                               <div className="ix-abt-ten">
                                   <div className="sub-txt">
                                       <h1> A platform designed to create a strong confident and empowered pipeline of female leaders through executive coaching programs. </h1>
                                       <div><b> Is the brain child of </b>
                                           <p>
                                               Maggie Chan Jones, Founder &amp; CEO
                                           Maggie is the first female CMO of SAP and has recently been named as one of the world’s most influential CMOs by Forbes.	</p></div>
           
                                           <div><b>Built for </b>
                                               <p>	Women who are seeking to accelerate their career with a mission to advance gender diversity and leadership development via executive coaching.</p></div>
                                               <a href="assets/case-studies/Tenshey-case-study.pdf" target="_blank">
                                                   <button className="btn case-btn">Read case study</button>
                                               </a>
                                           </div>
                                       </div>
                                   </div>
                               </div>	
           
                               <div className="row rect">
                                   <div className="col-lg-6 col-md-12 col-12 content-box1 wow bounceIn">
                                       <div className="content-1">
                                           <h4> Ionixx likes to find simple solutions to complex challenges! </h4>
                                           <p> Our challenge with Tenshey was to help the high-profile coaches to set their availability for Tenshey and publish a calendar to begin coaching. </p>
                                       </div>
                                   </div>
                                   <div className="col-lg-6 col-md-12 col-12 content-box2 wow bounceIn">
                                       <div className="content-2">
                                           <p> Our design process to </p>
                                           <h4> breakdown a challenge! </h4>
                                       </div>
                                   </div>
                               </div>
                           </div>	
                       </div>
                   </section>
           
                   <section>
                       <div className="ix-container bg-1">
                           <div className="row margin-space">
                               <div className="col-lg-6 col-md-12 col-12 content-idea wow flipInX">
                                   <div className="idea-text">
                                       <fieldset>
                                           <legend>  Ideation </legend>
                                           <div className="idea-sub-content">
                                               <h6> Time Availability Setting for Coaches </h6>
                                               <div><b>Use the mental model of time :</b><span>When our design team sat to ideate on devising an efficient time scheduling system for Tenshey’s coaches, we realized that we could tap into the users’ mental models to understand how they perceived time. </span></div>
                                               <div><b>Solution-focused to user-focused :</b><span>We shifted our thought perspective from solution-focused to user-focused since we found that most users defined time in terms of days and months, rather than hours. </span></div>
                                           </div>	
                                       </fieldset>
                                   </div>
                               </div>
                               <div className="col-lg-6 col-md-12 col-12 idea-img-pd wow flipInX">
                                   <div className="ideation">
                                       <img src="/assets/img/portfolio-img/tenshey-img/Group-ideation.png" alt="Group ideation" className=""/>
                                       <img src="/assets/img/portfolio-img/tenshey-img/Ideation.png" alt="Ideation" className=""/>
                                   </div>
                               </div>
                           </div>
                       </div>
                   </section>
           
                   <section className="task-section">
                       <div className="ix-container bg-2">
                           <div className="row sec-margin">
                               <div className="col-lg-12 col-md-12 col-12 content-idea">
                                   <div className="taskflow">
                                       <img src="/assets/img/portfolio-img/tenshey-img/taskflow.png" alt="taskflow"/>
                                   </div>	
                                   <div className="task">
                                       <h4> Task Flow </h4>
                                       <p> We created a detailed task flow while designing distinct features for Tenshey’s executive coaching platform.</p> 
                                       <p>Task flow for Time Availability Indicator/Scheduler feature </p>
                                       <h3>Task Flow</h3>
                                   </div>
                               </div>
                           </div>
                       </div>
                   </section>	
                           
                   <section className="bg-3">
                       <div className="row">
                           <div className="col-lg-12 col-md-12 col-12 pad-0 mob-scroll">
                               <div className="list-content">
                                   <ul className="wow pulse">
                                       <li className="item"> 
                                           <div className="list-border">
                                               Once confirmed, publish your calendar
                                           </div>
                                       </li>
                                       <li className="item">
                                           <div className="list-border"> 
                                               Review the availability you have set. This gives you a view on how your calendar is being displayed to the member
                                           </div>
                                       </li>
                                       <li className="item"> 
                                           <div className="list-border">
                                               To set multiple timings for the days, keep adding new timeframes 
                                           </div>
                                       </li>
                                       <li className="item">
                                           <div className="list-border"> 
                                               Select the timings you want to allocate for Tenshey and specify days
                                           </div>
                                       </li>
                                       <li className="item"> 
                                           <div className="list-border">
                                               To set different availability for various set of dates, keep adding multiple set of dates
                                           </div>
                                       </li>
                                       <li className="item"> 
                                           <div className="list-border">
                                               To set different availability for various set of dates, keep adding multiple set of dates
                                           </div>
                                       </li> 
                                   </ul>
                               </div>
                           </div>
                       </div>
                   </section>
                       
                   <section className="">
                       <div className="ix-container bg-4">
                           <div className="row">
                               <div className="col-lg-6 col-md-12 col-12 frame-pd bg-5 wow bounceInLeft">
                                   <div className="wireframe-img">
                                       <img src="/assets/img/portfolio-img/tenshey-img/wireframes.png"  alt="Visual portfolio"/>
                                   </div>
                                   <div className="mob-wireframe">
                                       <img src="/assets/img/portfolio-img/tenshey-img/wireframe2.png" alt="wireframe" className=""/>
                                       <img src="/assets/img/portfolio-img/tenshey-img/mobilewireframe.png" alt="mobilewireframe" className=""/>
                                   </div>
                               </div>
                               <div className="col-lg-6 col-md-12 col-12 wireframe wow bounceInRight">
                                   <div className="wireframe-text">
                                       <fieldset>
                                           <legend>  Wireframes </legend>
                                           <div className="frame-sub-content">
                                               <p>We built low-fidelity wireframes from the existing task flows to make early design inroads and check for functionality, behavior, and hierarchy of content. This step helped us validate our ideas with users before moving forward.</p>
           
                                               <p>When viewers look at a monochrome wireframe, all they see is structure. There aren’t any colors or shades on particular elements that fight for their attention. Instead, everything is equal weight so you can focus on the whole user experience.
                                               </p>
                                           </div>	
                                       </fieldset>
                                   </div>
                                   <img src="/assets/img/portfolio-img/tenshey-img/wireframes.png" alt="Wireframes"/>
                               </div>
                           </div>
                       </div>	
                   </section>
           
                   <section className="visual-bg">
                       <div className="ix-container bg-5">
                           <div className="row">
                               <div className="col-lg-6 col-md-12 col-12 wireframe mob-frame wow slideInLeft">
                                   <div className="wireframe-text visual">
                                       <fieldset>
                                           <legend> Visual Design </legend>
                                           <div className="frame-sub-content">
                                               <p>We then focused on visual design to shape and improve the user experience considering the effects of illustrations, photography, typography, space, layouts, and color on the usability of products and on their aesthetic appeal.</p>
                                           </div>	
                                       </fieldset>
                                   </div>
                                   <div className="visual-text-img">
                                       <img src="/assets/img/portfolio-img/tenshey-img/visualdesign.png" alt="viusal design"/>
                                   </div>
                               </div>
                               <div className="col-lg-6 col-md-12 col-12">
                                   <div className="visual-img">
                                       <div className="lap-img wow bounceInLeft">
                                           <img src="/assets/img/portfolio-img/tenshey-img/lap-img.png" alt="Visual portfolio"/>
                                       </div>
                                       <div className="mob-img wow bounceInRight">
                                           <img src="/assets/img/portfolio-img/tenshey-img/mob-img.png"  alt="Visual portfolio"/>
                                       </div>
                                   </div>
                               </div>
                           </div>
                       </div>
                   </section>
           
                   <section className="tenshey-coach">
                       <div className="row m-0">
                           <div className="col-lg-12 col-md-12 col-12">
                               <div className="tenshey-build">
                                   <h1>We Enabled Tenshey to Build a Learner-centric & Efficient Executive Coaching Platform</h1>
                               </div>
                               <div className="ix-container">
                                   <div className="icon-text wow slideInUp">
                                       <div className="col-lg-4 col-md-12 col-12 pad-0">
                                           <div className="icon1">
                                               <img src="/assets/img/portfolio-img/tenshey-img/icon1.png"  alt="Coaching platform"/>
                                           </div>
                                       </div>
                                       <div className="col-lg-4 col-md-12 col-12 pad-0">
                                           <div className="card-text">
                                               <h4>A win-win for both the coach and the coachees</h4>
                                               <p>By creating a user-centric design powered by robust mapping algorithms and chemistry calling features, we helped both coaches and coachees to have an enjoyable learning experience. </p>
                                           </div>
                                       </div>
                                       <div className="col-lg-4 col-md-12 col-12 pad-0">
                                           <div className="icon1">
                                               <img src="/assets/img/portfolio-img/tenshey-img/icon2.png" alt="Learning experience"/>
                                           </div>
                                       </div>
                                       <div className="col-lg-4 col-md-12 col-12 pad-0">
                                           <div className="card-text bg-lightgreen">
                                               <h4>An intent-rich learning experience</h4>
                                               <p>By connecting micro-moments (moments when someone wants to learn, go, do) through a well-defined data and analytics system, we were able to drive better experiences throughout the customer journey for Tenshey.</p>
                                           </div>
                                       </div>
                                       <div className="col-lg-4 col-md-12 col-12 pad-0">
                                           <div className="icon1 pad-45">
                                               <img src="/assets/img/portfolio-img/tenshey-img/icon3.png"  alt="UX researched "/>
                                           </div>
                                       </div>
                                       <div className="col-lg-4 col-md-12 col-12 pad-0">
                                           <div className="card-text bg-lightgreen">
                                               <h4>A well-defined UX research backed by a shared vision for success</h4>
                                               <p>By conducting structured research of users and their needs and behavior, we were able to develop a user-centric design that strongly resonated with Tenshey’s vision for success. </p>
                                           </div>
                                       </div>
                                   </div>
                               </div>
                           </div>
                       </div>
                   </section>
                       </div>
        </>
        );
    }

}

export default Tenshey;
