import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Wizard, Steps, Step } from 'react-multistep-wizard';
import './wizard.css';
import { ROUTES } from '../../routes';
import * as contactAction from '../../action/contactAction';
import { sendEmail } from '../../action/contactAction';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from "react-router-dom";
import { useRef } from 'react';

const knowUsOption = [
  "Google search",
  "Friend / Colleague",
  "You Tube",
  "Blog / Article",
  "Podcast / webinar",
  "Linkedin"
];

const wizardStepConstants = {
  MESSAGE: 'MESSAGE',
  NAME: 'NAME',
  EMAIL: 'EMAIL',
  PHONE: 'PHONE'
}

function WizardComponent(props) {
  const [conversation, setConversation] = useState({
    name: '',
    email: '',
    phoneNumber: '',
    hearAboutUs: '',
    message: '',
    others: '',
    hearAboutUsOpen: false
  });

  const [validationErr, setValidationErr] = useState({
    messageErr: "",
    nameErr: "",
    emailErr: "",
    emailInfoErr: "",
    phoneErr: "",
    submitted: false
  });

  const dropDownWrap = useRef();

  // const handleTypeForm = () => {
  //   // alert('asd')
  //   // wizardStepConstants.EMAIL:
  //       // let regex = new RegExp('^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$');
  //       if (conversation.email.includes("gmail") || conversation.email.includes("yahoo")) {
  //         setValidationErr(state => ({ ...state, emailInfoErr: "Please enter a company ID. Click next to proceed anyway." }));
  //         return;
  //       }
  //       // if (!regex.test(conversation.email)) {
  //       //   setValidationErr(state => ({ ...state, emailErr: "Invalid Email" }));
  //       //   return;
  //       // }
  // }

  useEffect(() => {
    // factors
    if(window.factors) {
      window.factors.addUserProperties({'visited_contact_us_page': 'yes'});
      window.factors.track("Start the Conversation");
      window.factors.track("Contact Name");
      window.factors.track("Contact Email");
      window.factors.track("Contact Phone");
      window.factors.track("Contact Submit");
    }
  }, []);

  useEffect(() => {
    if(!conversation.email) return;
    const timer = setTimeout(() => {
      if (conversation.email.includes("gmail") || conversation.email.includes("yahoo")) {
        setValidationErr(state => ({ ...state, emailErr: "Please enter work email" }));
      }
    }, 500);
    
    return () => {
      clearTimeout(timer)
    }
  }, [conversation.email])

  useEffect(() => {
    function checkOutSideClick(event) {
      if(dropDownWrap.current && !dropDownWrap.current.contains(event.target)) {
        handleFormUpdate('hearAboutUsOpen', false)
      }
    };
    document.addEventListener('click', (e) => {
      checkOutSideClick(e);
    });
    return () => {
      document.addEventListener('click', (e) => {
        checkOutSideClick(e);
      });
    }
  }, [dropDownWrap])

  const handleNext = useCallback((ctx, step = '') => {
    switch (step) {
      case wizardStepConstants.MESSAGE:
        if (!conversation.message) {
          setValidationErr(state => {
            return { ...state, messageErr: "Please enter message" }
          });
          return;
        }
        // if (conversation.message.length < 50) {
        //   setValidationErr(state => {
        //     return { ...state, messageErr: "Please provide some more explanation. We will be able to help you better." }
        //   });
        //   return;
        // }
        ctx.next();
        break;
      case wizardStepConstants.NAME:
        if (!conversation.name) {
          setValidationErr(state => ({ ...state, nameErr: "Please enter name" }));
          return;
        }
        ctx.next();
        break;
      case wizardStepConstants.EMAIL:
        let regex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;;
        // if (conversation.email.includes("gmail") || conversation.email.includes("yahoo")) {
        //   setValidationErr(state => ({ ...state, emailErr: "Please enter a company ID. Click next to proceed anyway." }));
        //   // return;
        // }
        if (!regex.test(conversation.email)) {
          setValidationErr(state => ({ ...state, emailErr: "Invalid Email" }));
          return;
        }
        ctx.next();
        break;
      // case wizardStepConstants.PHONE:
      //   if (conversation.phoneNumber.length < 8) {
      //     setValidationErr(state => ({ ...state, phoneErr: "Invalid phone number" }));
      //     return;
      //   }
      //   ctx.next();
      //   break;
      default:
        ctx.next();
    }
    // alert('ads');
  }, [conversation]);

  const handleFormUpdate = useCallback((key, value) => {
    setConversation(state => {
      return { ...state, [key]: value }
    });
    setValidationErr({
      messageErr: "",
      nameErr: "",
      emailErr: "",
      phoneErr: "",
      submitted: false
    });
  }, []);

  async function sumbitContactForm() {
    setValidationErr(state => {
      return { ...state, submitted: true }
    });
    const requestPayload = {
      emailID: conversation.email,
      name: conversation.name,
      mobileNumber: conversation.phoneNumber || '0000000000',
      message: conversation.message,
      discussionTopic: conversation.hearAboutUs,
      others: conversation.others,
      to: "info@ionixxtech.com",
      isMob: true
    };
    // console.log('requestPayload', requestPayload);
    try {
      const response = await props.sendEmail(requestPayload);
      if (response.status === 200) {
        props.history.push({
          pathname: ROUTES.TOAST,
          contactDetails: true,
          pdfDownload: false,
          subscribeDetails: false
        });
        setConversation({
          email: "",
          name: "",
          phoneNumber: "",
          message: "",
          hearAboutUs: ""
        }, () => {
          props.history.push(ROUTES.TOAST)
        })

      }
    }
    catch (err) {
      console.log(err);
    }
  }

  return (
    <div className='wizard-wrapper'>
      <Wizard >
        <Steps>
          <Step>
            {ctx => (
              <div className='first-step wizard-content'>
                <span className='count'><b>1</b> of 5</span>
                {/* <strong>What would you like to talk about?</strong> */}
                <div className='wizard-detail'>
                  <label>What would you like to talk about? *</label>
                  <textarea className={validationErr.messageErr ? 'validation-err' : null} value={conversation.message} placeholder='Please add your message here...' onChange={(e) => handleFormUpdate('message', e.target.value)}></textarea>
                  {
                    validationErr.messageErr ? <div className="err-msg">{validationErr.messageErr}</div> : ""
                  }
                  <div className='wizard-bottom'>
                    <button name="Start the Conversation" className='wizard-button contact-us-startconversation' onClick={() => handleNext(ctx, wizardStepConstants.MESSAGE)}>Start the conversation</button>
                    <span className='proceed-anyone'>Or</span>
                    <a href='https://calendly.com/ionixxtechnologies-office/30min?month=2023-07' target='_blank' className='book-appointment'>Book an appointment</a>
                  </div>
                </div>
                <button onClick={() => handleNext(ctx, wizardStepConstants.MESSAGE)} className='next-btn'><i><svg xmlns="http://www.w3.org/2000/svg" width="7" height="10" viewBox="0 0 7 10" fill="none">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M1.48127 0.296753L0.542402 1.23879L4.30974 4.99343L0.555359 8.7605L1.4974 9.69936L5.25177 5.9323L5.25259 5.93311L6.19145 4.99107L6.19064 4.99026L6.19171 4.98918L5.24968 4.05031L5.2486 4.05139L1.48127 0.296753Z" fill="#1E4B5E" />
                </svg></i></button>
              </div>
            )}
          </Step>
          <Step>
            {ctx => (
              <div className='second-step wizard-content'>
                <span className='count'><b>2</b> of 5</span>
                {/* <strong>What would you like to talk about?</strong> */}
                <div className='wizard-detail'>
                  <label>How should we address you? *</label>
                  <input className={validationErr.nameErr ? 'validation-err' : null} value={conversation.name} type='text' placeholder='Please add your name here' 
                  onChange={(e) => {let regex = new RegExp('^[a-zA-Z ]+$');
                  if(e.target.value && !regex.test(e.target.value)) return e.preventDefault();
                  handleFormUpdate('name', e.target.value)}} />
                  {
                    validationErr.nameErr ? <div className="err-msg">{validationErr.nameErr}</div> : ""
                  }
                  <div className='wizard-bottom'>
                    <button name="Contact Name" className='wizard-button contact-us-name' onClick={() => handleNext(ctx, wizardStepConstants.NAME)}>Next</button>
                  </div>
                </div>
                <button onClick={ctx.previous} className='prev-btn'><i><svg xmlns="http://www.w3.org/2000/svg" width="7" height="10" viewBox="0 0 7 10" fill="none">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M1.48127 0.296753L0.542402 1.23879L4.30974 4.99343L0.555359 8.7605L1.4974 9.69936L5.25177 5.9323L5.25259 5.93311L6.19145 4.99107L6.19064 4.99026L6.19171 4.98918L5.24968 4.05031L5.2486 4.05139L1.48127 0.296753Z" fill="#1E4B5E" />
                </svg></i></button>
                <button onClick={() => handleNext(ctx, wizardStepConstants.NAME)} className='next-btn'><i><svg xmlns="http://www.w3.org/2000/svg" width="7" height="10" viewBox="0 0 7 10" fill="none">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M1.48127 0.296753L0.542402 1.23879L4.30974 4.99343L0.555359 8.7605L1.4974 9.69936L5.25177 5.9323L5.25259 5.93311L6.19145 4.99107L6.19064 4.99026L6.19171 4.98918L5.24968 4.05031L5.2486 4.05139L1.48127 0.296753Z" fill="#1E4B5E" />
                </svg></i></button>
              </div>
            )}
          </Step>
          <Step>
            {ctx => (
              <div className='third-step wizard-content'>
                <span className='count'><b>3</b> of 5</span>
                {/* <strong>What would you like to talk about?</strong> */}
                <div className='wizard-detail'>
                  <label>How can we reach you? *</label>
                  <input className={validationErr.emailErr ? 'validation-err' : null} value={conversation.email} type='text' placeholder='Please add your company email here' onChange={(e) => handleFormUpdate('email', e.target.value)}/>
                  {
                    validationErr.emailErr ? <div className="err-msg">{validationErr.emailErr}</div> : ""
                  }
                  <div className='wizard-bottom'>
                    <button name="Contact Email" className='wizard-button contact-us-email' onClick={() => handleNext(ctx, wizardStepConstants.EMAIL)}>Next</button>
                  </div>
                  <div className='err-msg-info'>
                  {
                    validationErr.emailInfoErr ? <div className="err-msg">{validationErr.emailInfoErr}</div> : ""
                  }
                  </div>
                </div>
                <button onClick={ctx.previous} className='prev-btn'><i><svg xmlns="http://www.w3.org/2000/svg" width="7" height="10" viewBox="0 0 7 10" fill="none">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M1.48127 0.296753L0.542402 1.23879L4.30974 4.99343L0.555359 8.7605L1.4974 9.69936L5.25177 5.9323L5.25259 5.93311L6.19145 4.99107L6.19064 4.99026L6.19171 4.98918L5.24968 4.05031L5.2486 4.05139L1.48127 0.296753Z" fill="#1E4B5E" />
                </svg></i></button>
                <button onClick={() => handleNext(ctx, wizardStepConstants.EMAIL)} className='next-btn'><i><svg xmlns="http://www.w3.org/2000/svg" width="7" height="10" viewBox="0 0 7 10" fill="none">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M1.48127 0.296753L0.542402 1.23879L4.30974 4.99343L0.555359 8.7605L1.4974 9.69936L5.25177 5.9323L5.25259 5.93311L6.19145 4.99107L6.19064 4.99026L6.19171 4.98918L5.24968 4.05031L5.2486 4.05139L1.48127 0.296753Z" fill="#1E4B5E" />
                </svg></i></button>
              </div>
            )}
          </Step>
          <Step>
            {ctx => (
              <div className='fourth-step wizard-content'>
                <span className='count'><b>4</b> of 5</span>
                {/* <strong>What would you like to talk about?</strong> */}
                <div className='wizard-detail'>
                  <label>We would love to call you on phone.</label>
                  <input className={validationErr.phoneErr ? 'validation-err' : null} value={conversation.phoneNumber} type='text' placeholder='Please add your phone number here' 
                  onChange={(e) => {let regex = new RegExp('^[0-9]+$');
                    if(e.target.value && !regex.test(e.target.value)) return e.preventDefault();
                    handleFormUpdate('phoneNumber', e.target.value)}} />
                  {/* {
                    validationErr.phoneErr ? <div className="err-msg">{validationErr.phoneErr}</div> : ""
                  } */}
                  <div className='wizard-bottom'>
                    <button name="Contact Phone" className='wizard-button contact-us-number' onClick={() => handleNext(ctx, wizardStepConstants.PHONE)}>Next</button>
                    <span className='skip-to-next contact-us-skip-number' onClick={() => handleNext(ctx, wizardStepConstants.PHONE)}>Skip</span>
                  </div>
                </div>
                <button onClick={ctx.previous} className='prev-btn'><i><svg xmlns="http://www.w3.org/2000/svg" width="7" height="10" viewBox="0 0 7 10" fill="none">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M1.48127 0.296753L0.542402 1.23879L4.30974 4.99343L0.555359 8.7605L1.4974 9.69936L5.25177 5.9323L5.25259 5.93311L6.19145 4.99107L6.19064 4.99026L6.19171 4.98918L5.24968 4.05031L5.2486 4.05139L1.48127 0.296753Z" fill="#1E4B5E" />
                </svg></i></button>
                <button onClick={() => handleNext(ctx, wizardStepConstants.PHONE)} className='next-btn'><i><svg xmlns="http://www.w3.org/2000/svg" width="7" height="10" viewBox="0 0 7 10" fill="none">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M1.48127 0.296753L0.542402 1.23879L4.30974 4.99343L0.555359 8.7605L1.4974 9.69936L5.25177 5.9323L5.25259 5.93311L6.19145 4.99107L6.19064 4.99026L6.19171 4.98918L5.24968 4.05031L5.2486 4.05139L1.48127 0.296753Z" fill="#1E4B5E" />
                </svg></i></button>
              </div>
            )}
          </Step>
          <Step>
            {ctx => (
              <div className='fifth-step wizard-content'>
                <span className='count'><b>5</b> of 5</span>
                {/* <strong>What would you like to talk about?</strong> */}
                <div className='wizard-detail'>
                <label>How did you find us?</label>
                <div ref={dropDownWrap} className=' c-form-select-grp'>
                <div className="c-form-select" as="select" placeholder="Select" onChange={(e) => handleFormUpdate('hearAboutUs', e.target.value)}
                 onClick={() => { 
                  setConversation(prev => ({...prev, hearAboutUsOpen: !prev.hearAboutUsOpen}))
                 }}
                 >
                    <div className="selected-value">{!conversation.hearAboutUs && !conversation.others ? <label>Select</label> : ""}<span>{conversation.hearAboutUs || conversation.others}</span></div>
                    <div className="cfsel-drop-span"><svg xmlns="http://www.w3.org/2000/svg" width="13" height="8" viewBox="0 0 13 8" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M12.0694 1.55324L10.8357 0.442483L6.39331 5.37629L1.45943 0.933809L0.348677 2.16743L5.28255 6.60991L5.28195 6.61058L6.51557 7.72134L6.51617 7.72067L6.51678 7.72121L7.62754 6.48759L7.62693 6.48705L12.0694 1.55324Z" fill="#555555" />
                    </svg>
                    </div>
                  </div>
                  <div>
                    {
                      conversation.hearAboutUsOpen &&
                      <div className="select-option">
                        {knowUsOption?.map((data, i) => {
                          return <div className="d-block" onClick={() => {
                            handleFormUpdate('hearAboutUsOpen', false)
                            handleFormUpdate('others', "")
                            handleFormUpdate('hearAboutUs', data)
                          }}>{data}</div>
                        })}
                        <input type="name" placeholder="Others"
                          value={conversation.others}
                          onChange={(e) => {
                            handleFormUpdate('others', e.target.value)
                            handleFormUpdate('hearAboutUs', '')
                          }}
                          onKeyDown={(e) => {
                            if(e.key === "Enter") handleFormUpdate('hearAboutUsOpen', false)
                          }}
                        />
                      </div>
                    }
                  </div>
                  </div>
                  <div className='wizard-bottom'>
                    <button name="Contact Submit" className='wizard-button contact-us-findus' onClick={sumbitContactForm}>Submit</button>
                    <span className='skip-to-next contact-us-skip-findus' onClick={sumbitContactForm}>Skip</span>
                  </div>
                </div>
                <button onClick={ctx.previous} className='prev-btn'><i><svg xmlns="http://www.w3.org/2000/svg" width="7" height="10" viewBox="0 0 7 10" fill="none">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M1.48127 0.296753L0.542402 1.23879L4.30974 4.99343L0.555359 8.7605L1.4974 9.69936L5.25177 5.9323L5.25259 5.93311L6.19145 4.99107L6.19064 4.99026L6.19171 4.98918L5.24968 4.05031L5.2486 4.05139L1.48127 0.296753Z" fill="#1E4B5E" />
                </svg></i></button>
                <button onClick={sumbitContactForm} className='next-btn'><i><svg xmlns="http://www.w3.org/2000/svg" width="7" height="10" viewBox="0 0 7 10" fill="none">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M1.48127 0.296753L0.542402 1.23879L4.30974 4.99343L0.555359 8.7605L1.4974 9.69936L5.25177 5.9323L5.25259 5.93311L6.19145 4.99107L6.19064 4.99026L6.19171 4.98918L5.24968 4.05031L5.2486 4.05139L1.48127 0.296753Z" fill="#1E4B5E" />
                </svg></i></button>
              </div>
            )}
          </Step>
        </Steps>
      </Wizard>
    </div>
  )
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(contactAction, dispatch);
}
export default connect(null, mapDispatchToProps)(withRouter(WizardComponent));
// export default WizardComponent;
