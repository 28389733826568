import React from 'react';
import "../../resources.css"
import {ResourceRoute} from '../../resource-routing';
import {Link} from "react-router-dom";

import {SlideDown} from 'react-slidedown';
import 'react-slidedown/lib/slidedown.css';
import ReactSEOMetaTags from 'react-seo-meta-tags';
import Helmet from 'react-helmet';
import {ROUTES} from "../../../../routes";
import ContactUS from "../../../../components/contact-us";

class BlockChain extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            pageEnabled: false,
            buttonShow: true,
            showContactUS: false,
            start: 0,
            end: 3,
            cardList: [
                {
                    content1: "February has seen a positive wave of embracing blockchain in the form of increased adoption of digital assets. The blockchain bandwagon is growing and how! Here's more on those stories from our Bulletin!",
                    title: "28 Feb 2020",
                    Link: ResourceRoute.BLOCKCHAINDETAILED
                },
                {
                    content1: "With Wuhan's coronavirus crisis dominating headlines, several blockchain firms have pledged to donate appropriately to help the country tackle the crisis effectively. For details on this and more, check our Bulletin's top stories.",
                    title: "11 Feb 2020",
                    Link: ResourceRoute.FEBRUARYFORTNIGHT1
                },
                {
                    content1: "Increased adoption of stablecoins, framework for central bank digital currencies and more. Here's a roundup of what's made the news during the second half of January 2020!",
                    title: "28 Jan 2020",
                    Link: ResourceRoute.JANUARYFORTNIGHT2
                },
                {
                    content1: "The new year has seen a wave of blockchain development with more focus on regulations. Here are some highlights from our Bulletin.",
                    title: "15 Jan 2020",
                    Link:ResourceRoute.JANUARYFORTNIGHT1            
                },
                {
                    content1: "We are wrapping 2019 on a pretty positive note with countries all over the world looking to embrace blockchain technology for several use cases such as digital identity protection, social media platforms, and staking infrastructure. Here are the nitty-gritties!",
                    title: "28 Dec 2019",
                    Link:ResourceRoute.DECEMBERFORTNIGHT2
                },
                {
                    content1: "We are seeing quite a lot of buzz in the blockchain and crypto spaces even with the year drawing to a close. To provide you with a snapshot, we have curated some exciting pieces from our Bulletin, exclusively for you.",
                    title: "09 Dec 2019",
                    Link:ResourceRoute.DECEMBERFORTNIGHT1
                },
                {
                    content1: "The second half of the month has been pretty eventful for blockchain enthusiasts, worldwide. What with China almost excluding Bitcoin mining from its list of restricted activities, thereby giving an indirect go-ahead on the activity. Read on for more interesting bytes!",
                    title: "28 Nov 2019",
                    Link:ResourceRoute.NOVEMBERFORTNIGHT1
                },
                {
                    content1: "November's been a pretty eventful month for blockchain and crypto enthusiasts, worldwide. Here's a quick round-up of some good-to-know, hand-picked insights from our Bulletin - exclusively for you.",
                    title: "12 Nov 2019",
                    Link: ResourceRoute.NOVEMBERFORTNIGHT2
                },
                {
                    content1: "Blockchain technology's witnessing dynamic changes with every passing day. Interesting and exciting developments in this emerging field are dominating everyday headlines within tech circles! How did the last fortnight pan out?",
                    title: "31 Oct 2019",
                    Link:ResourceRoute.OCTOBERFORTNIGHT2
                }
            ]
        }
        
    }

    pushToRoute = (val) => {
        this.props.history.push(val);
    }
    viewMoreOnPress = () => {
        let arrLength = this.state.cardList.length;
        let remainder = this.state.cardList.slice(this.state.end, arrLength).length;
         this.setState({
             buttonShow : !this.state.buttonShow
         },()=>{
             if(this.state.buttonShow){
                 this.setState({
                     end:  3
                 })
                 window.scrollTo(0,0);

             }else{
                 this.setState({
                     end: this.state.end + remainder
                 })
             }
         })
        
     };
     sendToast ()  {
        this.setState({ showContactUS: true });
      }
     
    render() {
        return (
            <>	
            <Helmet>
                <meta charSet="utf-8" />
                <title>Blockchain Bulletin | Ionixx Technologies</title>
                <meta name="description" content="Stay updated with the latest news and discussions making the rounds in the industry. Read our news highlights to get a quick overview." />
                <link rel="canonical" href="https://www.ionixxtech.com/resources/blockchain-bulletin" />
            </Helmet>
            <ReactSEOMetaTags
                render={(el) => <Helmet>{el}</Helmet>}
                website={{ 
                    name: "Ionixx Technologies", 
                    title:"Blockchain Bulletin | Ionixx Technologies", 
                    description: "Stay updated with the latest news and discussions making the rounds in the industry. Read our news highlights to get a quick overview.",
                    type:"website",
                    image:'/assets/img/ionixx-logo.png',
                    url:"https://www.ionixxtech.com/resources/blockchain-bulletin"
                }}
                twitter={{ 
                    twitter: "https://www.ionixxtech.com/resources/blockchain-bulletin" }}
                />
            <div className="Resources">
                <div className="blog-content newsletter-content yellow-bg">
                <div className="newsletter-common">
                        <div className="main-head-div">
                            <h4 className="main-head-1">
                                <span className="primary-span">Blockchain Bulletin</span>
                            </h4>
                            <p className="press-info">Get the latest insider news on blockchain and crypto technology
                                trends much before it makes the big splash on our Blockchain Bulletin!</p>
                        </div>
                        <div class="main-para-div short-div">
                                <Link to={ROUTES.CONTACTUS} className="btn btn-primary connect-btn">Talk to us</Link>
                            </div>
                        
                                    <div className="tab-card porti-card">
                                    {
                                        this.state.cardList.length > 0 ? this.state.cardList.slice(this.state.start, this.state.end)?.map((item, i)=>{
                                            return (
                                                <div className="list" key={i}>
                                                <div className="list-capt">
                                            <p>{item.content1}</p>
                                            <p className="newsletter-publish-info"><span>{item.title}</span></p>
                                                </div>
                                                <div className="download-btn">
                                                <Link onClick={()=>{this.pushToRoute(item.Link)}}>Read More</Link>
                                                </div>
                                            </div>
                                            )
                                        }) : 
                                        <div className="text-center no-data">No data found</div>
                                    }
                                    {
                                    this.state.cardList.length > 3 && this.state.buttonShow === true? <div className="view-more">
                                    <button onClick={this.viewMoreOnPress} >
                                    {this.state.cardList.length > 3 && this.state.buttonShow ?  "View More" : "View Less" }
                                        <span><img className={!this.state.buttonShow ?"rotate-view-more": "" }src="/assets/img/blue-arrow.svg"  alt="Arrow"/></span>
                                    </button>
                                </div>  : ""
                                }    
                                </div> 

                
                 
                                {
                                    this.state.cardList.length > 3 && this.state.buttonShow === false? <div className="view-more">
                                    <button onClick={this.viewMoreOnPress} >
                                    <span><img className={!this.state.buttonShow ?"rotate-view-more mb-0 mt-0": "" }src="/assets/img/blue-arrow.svg"  alt="Arrow"/></span>
                                    {this.state.cardList.length > 3 && this.state.buttonShow ?  "View More" : "View Less" }
                                    </button>
                                </div>  : ""
                                }     
                            
                            
                         
                        </div>
                       
                    </div>
            </div>
            </>                    
        );
    }

}

export default BlockChain;
