import React from 'react';

class LoanProcessing extends React.Component {
    constructor(props) {
        super(props)
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }


    render() {
        return (
            <div className="Loan-portfolio">
<section className="epl-portfolio wow pulse">
	<div className="ix-container">
		<div className="row">
			<div className="col-xl-6 col-lg-6 col-md-12 col-sm-12  wow slideInLeft">
				<div className="sec1-content">
					<h1><span>An Online </span>Personal Lending Solution</h1>
					<div className="sec1-subcnt">
						<fieldset>
							<legend> Product Brief </legend>
							<div className="product">
								<p>A comprehensive suite of mobile applications for performing a wide range of real time, online personal lending activities that automatically manages end-to-end lending operations for the Indian market. The operations include KYC (Know Your Customer) capture, loan disbursements, loan collection, PAR (Portfolio At Risk)/NPA (Non-Performing Assets) management and complete financial accounting.</p>
								<h3> <span>Client</span> </h3>
								<p>MSM is the product owner of this personal loan mobile solution. It is a leading microfinance company with a distinguished portfolio of about 40 crores with 65,000 borrowers.</p>
							</div>
						</fieldset>
					</div>
				</div>
			</div>		
			<div className="col-xl-6 col-lg-6 col-md-12 col-sm-12  wow slideInRight">
				<div className="sec1-image">
					<img src="/assets/img/portfolio-img/epl-portfolio-img/mob-img1.png"  alt="Product"/>
				</div>
			</div>
		</div>
	</div>	
</section>
<section className="sec2">
	<div className="ix-container">
		<div className="row">
		   <div className="col-xl-6 col-lg-6 col-md-12 col-12 wow bounceIn">
				<div className="sec2-content">
					<div className="sec2-img">
						<img src="/assets/img/portfolio-img/epl-portfolio-img/image2.png"  alt="Data uint"/>
					</div>
					<div className="unique-point">
						<fieldset>
							<legend> Minimizing the Need for Data Input </legend>
							<div className="data">
								<p>By designing the <span>OCR Scanning</span> feature, we enabled users to save time in terms of minimizing their efforts toward mundane data entry.</p>
							</div>
						</fieldset>
                    </div>
                 </div>
             </div>


             <div className="col-xl-6 col-lg-6 col-md-12 col-12 wow bounceIn">
                 <div className="sec2-content">
					<div className="unique-point unique-point-two">
						<h2> Unique Selling Point </h2>
						<fieldset className="sec-2-one">
							<legend> Enabling Instant Results for the User </legend>
							<div className="data1">
								<p>Through the <span>Rating Engine Parameter</span> feature we designed, we gave users the opportunity to check for their loan eligibility within seconds; thereby expediting the initial pre-screening process.</p>
							</div>
						</fieldset>
					</div>
					<div className="sec2-img">
						<img src="/assets/img/portfolio-img/epl-portfolio-img/image3.png"  alt="Selling point"/>
					</div>
				</div>
		    </div>
	  	</div>
	</div>
</section>

<section className="sec3">
	<div className="ix-container">
		<div className="sec-head2 wow bounceIn">
			<h3> Design First Approach </h3>
		</div>
		<div className="row">
			<div className="col-xl-12 col-lg-12 col-md-12 col-12 padd-5">
				<div className="sec3-head">
					<div className="part-1 top">
						<ul className="fields">
							<li className="li-mar wow bounceInLeft">
								<fieldset className="width-className">
									<p>1. Design Brief</p>
								</fieldset>
							</li>
							<li className="wow bounceInDown">
								<fieldset>
									<legend> Product Strategy </legend>
									<p>2. Conceptualization </p>
									<p>3. User stories </p>
								</fieldset>
							</li>
							<li className=" pre-line wow bounceInUp">
							<fieldset>
								<legend> Pre Design </legend>
								<p>4. Task flows</p>
								<p>5. Ideation and sketching </p>
								<p>6. Wireframing</p>
							</fieldset>
						</li>
						<li className="no-border wow bounceInRight">
							<fieldset>
								<legend> Iterative Process </legend>
								<p>7. UX Design </p>
								<p>8. Visual Design </p>
								<p>9. Rapid prototyping</p>
							</fieldset>
						</li>
						</ul>
					</div>
					<div className="sec3-1 wow bounceIn">
						<div className="sec3-img1">
							<img src="/assets/img/portfolio-img/epl-portfolio-img/sec3-3.png"  alt="Ux design "/>
						</div>
						<div className="sec3-img2">
							<img src="/assets/img/portfolio-img/epl-portfolio-img/sec3-2.jpg" alt="Visual desing" />
						</div>
						<div className="sec3-img3">
							<img src="/assets/img/portfolio-img/epl-portfolio-img/sec3-1.png"  alt="Rapid prototyping" />
						</div>
					</div>
				</div>
			</div>
			
		</div>
	</div>
</section>


<section className="sec4">
	<h1 className="wow bounceIn"> <span>Design</span> Challenges</h1>
	<div className="ix-container">
		<div className="row">
			<div className="col-lg-6 col-md-12 col-sm-12 col-12 wow slideInRight">
				<div className="sec4-image">
					<div className="sec4-1">
						<img src="/assets/img/portfolio-img/epl-portfolio-img/image5.png"  alt="Design challen" />
					</div>
					<div className="sec4-2">
						<img src="/assets/img/portfolio-img/epl-portfolio-img/image6.png" alt="Design challenge"/>
					</div>
				</div>
			</div>
			<div className="col-lg-6 col-md-12 col-sm-12 col-12 wow slideInLeft">
				<div className="design">
					<fieldset>
						<legend> Informed Decision Making </legend>
						<div className="data1">
							<p>With the integration of a <span> customized EMI calculator</span>, we helped users in their decision making process without having to switch several applications </p>
						</div>
					</fieldset>
					<fieldset>
						<legend> Dense Yet Usable </legend>
						<div className="data1">
							<p>By designing a screen that displayed an all-round view of loan features on a single screen, we were able to provide users with a holistic view of the application features through a least amount of time.</p>
						</div>
					</fieldset>
				</div>
			</div>
		</div>
	</div>
</section>

<section className="last-sec">
	<div className="ix-container">
		<div className="last-head-sec wow bounceIn">
			<h1>Design At The Core of <span> A Sustainable Business!</span></h1>
		</div>
		
		<div className="row">
			<div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 wow bounceInLeft">
				<div className="left-sec-one">
					<div className="last-process">
						<fieldset>
						<ul>
							<li>
							  <div className="last-sec-img">
							  	<img src="/assets/img/portfolio-img/epl-portfolio-img/clock.png" alt="Saving time" width="40px;"/>
							  </div>
							</li>
							<li className="pad-left">
								<div className="head-1">
									<h6>Saving Time Through a Paperless Process</h6>
								</div>
								<div className="head-2">
									<p>By designing an intuitive field verification screen enriched with several distinct features such as Filter option and GPRS tracking, we enabled users (Field Officers) to track and complete verification processes in no time.</p>
								</div>
							</li>
					    </ul>
					 </fieldset>
					</div>

					<div className="last-process">
						<fieldset>
						<ul>
							<li>
							  <div className="last-sec-img">
							  	<img src="/assets/img/portfolio-img/epl-portfolio-img/badge.png" alt="Enhance value" width="40px;"/>
							  </div>
							</li>
							<li className="pad-left">
								<div className="head-1">
									<h6>Creating Enhanced Value by Mitigating Risk</h6>
								</div>
								<div className="head-2">
									<p>Designing a feature-rich loan applicant portfolio analysis screen enabled field officers to validate and verify loan applicants with minimal risk.This ensured that all data is populated and validated through a robust verification mechanism.</p>
								</div>
							</li>
					    </ul>
					 </fieldset>
					</div>
				</div>
			</div>
			<div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 wow bounceInRight">
				<div className="right-sec-one">

 				<div className="right-sec-img">
						<img src="/assets/img/portfolio-img/epl-portfolio-img/image7.png" alt="create enhance" width="100%"/>
					</div>
				</div>
			</div>
		</div>
	</div>
</section>
            </div>
        );
    }

}

export default LoanProcessing;
