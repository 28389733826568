import React from 'react';
import "../../resources.css";
import { ResourceRoute } from '../../resource-routing';
import { Link } from "react-router-dom";
import NewsHeadings from "./common-action/news-heading";
import NewsSubscription from "./common-action/subscripbe-section";
import { Subscriptions } from '@material-ui/icons';

class NewsIssue04 extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            pageEnabled: false,
        }

    }

    render() {
        return (
          <div className="newdesigned_issue_details">
            <NewsHeadings
              buttondDetail="Design"
              categoryType="design"
              description="The Environmental Benefits of Digital Design | MIT Sloan Review"
            />

            <div className="newsissuedetailed">
              <div className="detailed-common">
                {/* <div className="back-to-common">
                    <span className="back-to-icon-hold">
                        <img src="/assets/img/resource/back-to-new.svg"  alt="bact to new"></img>
                    </span>
                    <Link  to={ResourceRoute.NEWSLETTER}><span className="back-to-link">Newsletter</span></Link>
                </div> */}

                <div className="news-letter whats-on-hold">
                  <div className="issue-main-head">
                    <h4>What’s on?</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        href="https://www.insurancejournal.com/news/international/2019/12/03/550022.htm"
                        target={"_blank"}
                      >
                        <h4>
                          HSBC to Shift $20 Billion Worth of Assets to
                          Blockchain-Based Platform | Insurance Journal{" "}
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        The blockchain platform developed by HSBC will re-create
                        the existing paper ledgers into fully traceable and
                        real-time auditable on-chain transactions. HSBC, which
                        is the seventh-largest bank in the world, is projecting
                        that the global value of private placements will be at
                        $7.7 trillion by 2022, which is a jump of 60 percent
                        from five years earlier.{" "}
                      </p>
                    </div>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        href="https://www.theverge.com/2019/12/4/20994361/google-alphabet-larry-page-sergey-brin-sundar-pichai-co-founders-ceo-timeline"
                        target={"_blank"}
                      >
                        <h4>
                          The Rise, Disappearance and Retirement of Google
                          Co-Founders Larry Page And Sergey Brin | The Verge{" "}
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        No two tech executives are quite as enigmatic and
                        private as Google co-founders Larry Page and Sergey
                        Brin. The two men, who started Google more than 20 years
                        ago while computer science graduate students at Stanford
                        University, have hardly been seen or heard from in the
                        last half-decade or so, since restructuring the company
                        to create Google parent Alphabet and leaving Sundar
                        Pichai in charge of a newly streamlined Google.
                      </p>
                    </div>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        href="https://www.forbes.com/sites/michaeldelcastillo/2019/12/05/bitfury-launches-first-enterprise-blockchain-secured-by-bitcoin/#4c928805c6cf"
                        target={"_blank"}
                      >
                        <h4>
                          Bitfury Launches First Enterprise Blockchain Secured
                          By Bitcoin | Forbes{" "}
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Bitcoin mining giant Bitfury has taken another step in
                        its transformation into an enterprise blockchain
                        software developer. After previously releasing
                        open-source software that anyone could use for free to
                        run a permissioned blockchain similar to bitcoin,
                        Bitfury today launched Exonum Enterprise, the first such
                        blockchain built from the ground up to let large
                        corporations streamline their business and increase
                        transparency using the bitcoin blockchain.
                      </p>
                    </div>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        href="https://www.npr.org/2019/12/04/784884508/instagram-now-requires-users-to-provide-their-age"
                        target={"_blank"}
                      >
                        <h4>
                          Instagram Now Requires Users To Provide Their Age |
                          NPR{" "}
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Users on Instagram will soon be required to enter their
                        birth date in order to use the social networking app.
                        The Facebook-owned company previously only checked that
                        the new user was at least 13 years old. The changes are
                        being made in the hopes of making the platform safer for
                        younger users, Instagram said in a statement. The birth
                        dates will be used to recommend different privacy
                        settings and features. Birthdays will not be visible to
                        the public.{" "}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="news-letter top-picks-hold">
                  <div className="issue-main-head">
                    <h4>Top Picks</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        href="https://sloanreview.mit.edu/article/the-environmental-benefits-of-digital-design/"
                        target={"_blank"}
                      >
                        <h4>
                          The Environmental Benefits of Digital Design | MIT
                          Sloan Review{" "}
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Digital advances in product design are creating new
                        opportunities to boost both environmental sustainability
                        and profit margins. By making product development more
                        design- and information-intensive, companies are
                        creating a new generation of digitally enhanced
                        offerings that are reducing material use, lowering
                        energy demands, and increasing revenues.
                      </p>
                    </div>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        href="https://www.cnet.com/news/from-airpower-to-zune-a-decade-of-tech-and-companies-that-died/"
                        target={"_blank"}
                      >
                        <h4>
                          From AirPower to Zune, a decade of Tech in review |
                          CNET{" "}
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        The Apple iPad Mini, Mac Mini, MacBook Air, Mac Pro and
                        iPod were on our endangered list for years before Apple
                        surprised us with upgrades to all. On the other hand,
                        the company's AirPort router line and Time Capsule
                        backup drive also were on a long death watch until they
                        finally landed on Apple's vintage and obsolete product
                        list this year.{" "}
                      </p>
                    </div>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        href="https://techcrunch.com/2019/12/05/design-may-be-the-next-entrepreneurial-gold-rush/"
                        target={"_blank"}
                      >
                        <h4>
                          Design may be the next entrepreneurial gold rush |
                          TechCrunch{" "}
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Ten years ago, the vast majority of designers were
                        working in Adobe Photoshop, a powerful tool with
                        fine-tuned controls for almost every kind of image
                        manipulation one could imagine. But it was a tool built
                        for an analog world focused on photos, flyers and print
                        magazines; there were no collaborative features, and
                        much more importantly for designers, there were no other
                        options. Since then, a handful of major players have
                        stepped up to dominate the market alongside the
                        behemoth, including InVision, Sketch, Figma and Canva.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="news-letter other-reads-hold">
                  <div className="issue-main-head">
                    <h4>Other Reads</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        href="https://theweek.com/articles/882074/why-kamala-harris-failed"
                        target={"_blank"}
                      >
                        <h4>
                          Who is Kamala Harris and why did she drop out of the
                          2020 election race | The Week
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Kamala Harris was the perfect presidential candidate for
                        the institutional Democratic Party in 2019. By which I
                        mean that she talked, looked, and acted like she was
                        grown in a top-secret high-tech lab deep in the bowls of
                        the Democratic National Committee. A graduate of Howard
                        University and the Hastings College of Law in the
                        University of California system, she had elite
                        credentials without seeming too elite. Her law career
                        was spent in the public sector prosecuting criminals —
                        first for the office of the San Francisco District
                        Attorney, then as the DA herself, and finally as
                        Attorney General for the state of California, before
                        finally winning the Senate seat vacated by a retiring
                        Barbara Boxer in 2016. A tough-as-nails, law-and-order
                        black woman to take down President Trump: What could be
                        better than that?
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <NewsSubscription categoryType="Design" />
          </div>
        );
    }

}

export default NewsIssue04;
