import React from 'react';
import ReactSEOMetaTags from 'react-seo-meta-tags';
import Helmet from 'react-helmet';
import {Link} from "react-router-dom";
import {ROUTES} from "../../../routes";

class Kyc extends React.Component {
    constructor(props) {
        super(props)
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }


    render() {
        return (
			<>	
			<Helmet>
                <title>IX-KYC:Easy Onboarding Solution for Successful Customer Engagement | Ionixx Technologies</title>
                <meta name="description" content="With IX KYC, we helped simplify complex onboarding processes for financial institutions by building an efficient digital product to pave the way for paperless operations." />
                <link rel="canonical" href="https://www.ionixxtech.com/portfolio/kyc" />
            </Helmet>
            <ReactSEOMetaTags
                render={(el) => <Helmet>{el}</Helmet>}
                website={{ 
                    name: "Ionixx Technologies", 
                    title:"IX-KYC:Easy Onboarding Solution for Successful Customer Engagement | Ionixx Technologies", 
                    description: " With IX KYC, we helped simplify complex onboarding processes for financial institutions by building an efficient digital product to pave the way for paperless operations.",
					type:"website",
					image:'/assets/img/ionixx-logo.png',
                    url:"https://www.ionixxtech.com/portfolio/kyc"
                }}
                twitter={{ 
                    twitter: "https://www.ionixxtech.com/portfolio/kyc" }}
                /> 
                 <div className="kyc-doc kyc-body">
           <section class="section_1" >
            <div class="bc-pink"></div>
              <div class="kyc-index">
                <h1>IX-KYC</h1>
                <p>Easy Onboarding Solution for Successful<span class="break-up"> Customer Engagement</span></p>
                <h6>With IX KYC, we helped simplify complex onboarding processes for financial institutions by building an<span class="break-up">efficient digital product to pave the way for paperless operations.</span> </h6>
                <a href="#section_1"><img  src="/assets/img/kyc/downarrow.svg" alt="downarrow" class="downarrow"/>
                </a></div>

                <div class="images" id="section_1">
                  <p><span>IX &nbsp;KYC</span></p>
                  <div class="bc-img"  >
                    <img src="/assets/img/kyc/impact_2.svg" class="bc-img1"/> 
                    <img src="/assets/img/kyc/index2.png" class="bc-img2"/>
                    <img src="/assets/img/kyc/index3.png" class="bc-img3"/>  
                    <img src="/assets/img/kyc/index4.png" class="bc-img4"/> 
                  </div>
                </div>
            </section>
      
            <section class="section_2">
             <div class="heading-line"><a href="#section_2">Understanding the why’s for <span class="break-up"> solution: IX KYC</span></a></div>
            <div class="inline-solution ">
             <div class="kyc-solution">
               <p>As we are inching towards an increasingly digital age, customers’ expectations <span class="break-up">in the realm of banking operations are growing more dynamically. This means,</span> creating a convenient and enjoyable digital onboarding process is significant <span class="break-up"> for staying relevant in the changing financial landscape.</span></p>
                <p>By enabling digital entry of data for all the stakeholders involved, <br/><Link class="default-custom-link" to={ROUTES.HOME}>Ionixx</Link> helped <span class="">build a stable product that would collect KYC details digitally for customers of</span> banks, online payment companies, and financial/ non-financial institutions.</p>
              </div>
              {/* <div class="solution-img">
                <img src="/assets/img/kyc/solution1.svg" class="solution1"/> 
                <img src="/assets/img/kyc/solution2.png" class="solution2"/>  
              </div> */}
              <div class="sec2-img">
             <div class="sec2-images">
                <img src="/assets/img/kyc/solution1.svg" class="img2_1"/> 
                <img src="/assets/img/kyc/solution2.png" class="img2_2"/> 
             </div>
           </div>
            </div>
            </section>

                    
            <section class="section_3" id="section_2">
              <div class="heading-line"><a href="#section_3">Overcoming Challenges</a></div>
              <div class="inline-solution ">
              <div class="sec3-img">
             <div class="sec3-images">
             <img  src="/assets/img/kyc/overcoming.svg" class="img3"/> 
             </div>
           </div>
        
              <div class="kyc-solution">
                <h4>Optimization of a time-consuming <span class="break-up">data collection process</span></h4>
                <p>On initial analysis, we identified that some of the major challenges that <span class="break-up">currently plague onboarding operations for financial institutions include:</span></p>
                <p class="mrgn-adjest">High turnaround time for filling out KYC forms </p>
                <p>Labor-intensive manual processes in filling and verification of information</p>
              </div>
              <div class="bc-pink"></div>
            </div>
             </section>

             <section class="section_4"  id="section_3">
          <div class="kyc-doc">
              <div class="heading-line">Creating an impact</div>
              <div class="inline-solution ">
          <div class="kyc-solution">
            <h4 class="digital">Digitization of information</h4>
            <p>After extensive understanding of the business’ <span class="break-up">requirements, we thought it fit.</span></p>
            <p>to gather only the most important details of customers in a bid <span class="break-up">to save time and maximize reliability.</span></p>
            <p>to enable customer onboarding by reducing manual effort and<span class="break-up"> digitizing data entry functions resulting in simpler operations.</span></p>
            <p>to facilitate increased efficiency of time by minimizing chances <span class="break-up">of failures.</span></p>
            
          </div>
           <div class="sec4-img">
             <div class="arrow-point">
                <img src="/assets/img/kyc/impact-point.svg"/>
             </div>
             <div class="sec4-images">
                <img src="/assets/img/kyc/impact_2.svg" class="img4_1"/> 
                <img src="/assets/img/kyc/impact_1.png" class="img4_2"/> 
             </div>
           </div>
          </div>
        </div>
        
        <div class="kyc-doc even">
          <div class="inline-solution ">
          <div class="sec4-img">
             <div class="arrow-point">
                <img src="/assets/img/kyc/impact-point.svg"/>
             </div>
             <div class="sec4-images">
                <img src="/assets/img/kyc/impact_2.svg" class="img4_1"/> 
                <img src="/assets/img/kyc/impact_4.png" class="img4_2"/> 
             </div>
           </div>
        <div class="kyc-solution">
        <h4>Paperless onboarding via an<span class="break-up"> omnichannel automation</span> platform</h4>
          <p><Link class="default-custom-link" to={ROUTES.HOME}>Ionixxs</Link> built an omnichannel automation platform to help <span class="break-up">auto-fetch details from customer proof documents, thus</span> requiring customers to enter only a part of the details <span class="break-up">manually.</span></p>
          <p>This greatly reduced time to onboard thus enabling instant <span class="break-up">paperless onboarding.</span></p>
          <p>This also offered adequate flexibility in that it allowed<span class="break-up"> customers to choose from the list of steps or details and use</span> it in real-time for their own KYC process.</p>
         
        </div>
        
        </div>
        </div>
        
        <div class="kyc-doc">
          <div class="inline-solution ">

          <div class="kyc-solution">
        <h4>Streamlined guarantor<span class="break-up"> verification process</span></h4>
        <p><Link class="default-custom-link" to={ROUTES.HOME}>Ionixx</Link> built a stable system to ensure seamless verification of <span class="break-up">guarantor details, precluding customers the external guidance </span>needed to complete the complex process of checking eligibility.</p>
        <p>This helped streamline the customer flow and made guarantor <span class="break-up">verification a lot simpler by asking for minimal information</span> thereby ensuring a hassle-free onboarding process for<span class="break-up"> customers.</span></p>
        </div>
             <div class="sec4-img">
             <div class="arrow-point last-img">
                <img src="/assets/img/kyc/impact-point.svg"/>
             </div>
             <div class="sec4-images">
                <img src="/assets/img/kyc/impact_2.svg" class="img4_1"/> 
                <img src="/assets/img/kyc/kyc-last-img.png" class="img4_2"/> 
             </div>
           </div>
        
        </div>
        </div>
        
         </section>
            </div>
			</>		
	   );
    }

}

export default Kyc;
