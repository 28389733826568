import React from "react";
import {Button, Collapse, Modal, Form} from 'react-bootstrap';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./talk-to-us.css";
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css';
import {connect} from "react-redux";
import {bindActionCreators} from "redux";
import * as contactAction from "../../action/contactAction";
import Helmet from 'react-helmet';
import {ROUTES} from "../../routes";
class TalkToUs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        name: '',
        email: '',
        companyName:'',
        phoneNumber: '',
        hearAboutUs: '',
        message: '',
        others:"",
        friendName:'',
        phoneErr: '',
        emailErr:"",
        processInformation: false,
        validated: false,
        shortLoader: false,
        hearAboutUsDropData: [
          "Google search",
          "Friend / Colleague",
          "You Tube",
         " Blog / Article",
          "Podcast / webinar",
          "Linkedin"
        ],
        hearAboutUsOpen:false

    }
    this.onSubmitHandler = this.onSubmitHandler.bind(this);
}

async onSubmitHandler(event) {
  
    if(this.state.phoneNumber.length < 8){
        this.setState({
            phoneErr: "Invalid phone number"
        })
    }else{
        const requestPayload = {
            emailID: this.state.email,
            name: this.state.name,
            companyName: this.state.companyName,
            mobileNumber: this.state.phoneNumber,
            message: this.state.message,
            refPerson: this.state.friendName,
            hearAboutUs: this.state.hearAboutUs,
            others:this.state.others,
            to: "info@ionixxtech.com",
            isMob:true
        };
        try {
            const response = await this.props.sendEmail(requestPayload);
            if(response.status === 200){
                this.props.history.push({
                    pathname: ROUTES.TOAST,
                    contactDetails: true,
                    pdfDownload: false,
                    subscribeDetails:false
                  });
                this.setState({
                    email: "",
                    name: "",
                    phoneNumber: "",
                    message: "",
                    hearAboutUs: "",
                    validated: false,
                    processInformation: false,
                    shortLoader: false
                },()=>{
                    this.props.history.push(ROUTES.TOAST)
                })
                
            }
        }
        catch(err) {
            console.log(err);
        }
    }
       
    // }
}
  render() {
    var settings = {
      // className: "center",
      dots: false,
      arrows: false,
      infinite: true,
      autoplay: true,
      centerMode: true,
      // speed:8000,
      // autoplaySpeed: 12000,
      speed: 2000,
      autoplaySpeed: 5000,
      pauseOnHover: true,
      slidesToShow: 5,
      slidesToScroll: 1,
      initialSlide: 0,
      responsive: [
        {
          breakpoint: 1098,
          settings: {
            slidesToShow: 5,
            slidesToScroll: 1,
            infinite: true,
            dots: false,
          },
        },
        {
          breakpoint: 768,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            initialSlide: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        },
      ],
    };
    const metaTitle =
      "Ionixx Technologies: Custom Software & Blockchain Development Company | UI UX & Product Design Services";
    const metaDescription =
      "Ionixx Technologies, a top custom software development company, offers UI and UX design, Blockchain development, Fintech solution, healthcare care solution,Online marketplace solutions, web application, and mobile application development services. Talk to us on your blockchain development or custom software development needs. Get free consultation now.";
    return (
      <>
        <Helmet>
          <meta charSet="utf-8" />
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0, shrink-to-fit=no, user-scalable=no, maximum-scale=1"
          />
          <title>
            Ionixx Technologies: Custom Software & Blockchain Development
            Company | UI UX & Product Design Services
          </title>
          <meta name="description" content={metaDescription} />
          <link rel="canonical" href="https://www.ionixxtech.com/talk-to-us" />
          <meta property="og:title" content={metaTitle} />
          <meta property="og:type" content={`website`} />
          <meta property="og:url" content={"https://www.ionixxtech.com/talk-to-us"} />
          <meta property="og:description" content={metaDescription} />
        </Helmet>
        {/* <ReactSEOMetaTags
                render={(el) => <Helmet>{el}</Helmet>}
                website={{ 
                    name: "Ionixx Technologies", 
                    title:"Ionixx Technologies: Custom Software & Blockchain Development Company | UI UX & Product Design Services", 
                    description: "Ionixx Technologies, a top custom software development company, offers UI and UX design, Blockchain development, Fintech solution, healthcare care solution,Online marketplace solutions, web application, and mobile application development services. Talk to us on your blockchain development or custom software development needs. Get free consultation now.",
                    type:"website",
                    image:'/assets/img/ionixx-logo.png',
                    url:"https://www.ionixxtech.com/"
                }}
                twitter={{ 
                    twitter: "https://www.ionixxtech.com/" }}
                /> */}
        <div className="talk-to-us">
          <div className="top-section">
            <div className="custom-container">
            <div className="main-head-div">
              <h1 className="main-head-1">
                <span className="primary-span">We Are Here To Help!</span>
              </h1>
              <h4 className="main-head-1">
                <span className="secondary-span">Want to start a conversation around new product ideas, business opportunities, or technology consulting? You are at the right place.</span>
              </h4>
            </div>
            </div>
          </div>
          <div className="section-1 design-cont">
            <div className="custom-container">
              
              <div className="content-section">
                <div className="col-lg-5 col-xl-5 col-md-12 col-sm-12 col-xs-12 no-pad">
                <div className="custom-section">
                <div className="main-head-div">
                  <strong className="box-heading">Get in touch</strong>
                  <p className="main-para">Drop in your details, and we'll be sure to reach out to you shortly. We value you and are committed to building user-centric technology solutions. We appreciate your questions, comments, and feedback so we can better serve you!</p>
                </div>
                <div className="contact-info-detail">
                    <div className="contact-info-list">
                      <span>
                      For sales inquiries, <br/>
                      write to <a href="mailto:info@ionixxtech.com">info@ionixxtech.com</a>
                      </span>

                      <span> For career related inquires, <br/>
                      write to <a href="mailto:hr@ionixxtech.com">hr@ionixxtech.com</a>
                      </span>

                     <span> For press & media inquires, <br/>
                      write to <a href="mailto:ixmarketing@ionixxtech.com">ixmarketing@ionixxtech.com</a>
                      </span>
                    </div>
                </div>
              </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 connect-form">
                  <b>Let's connect</b>
                <Form noValidate validated={this.state.validated} >
                        <Form.Group controlId="formBasicName">
                            <Form.Label className="contact-form-label">First Name*</Form.Label>
                            <Form.Control type="name" placeholder="Enter" title="First Name" value={this.state.name} required
                                          onChange={(e) => {
                                              this.setState({name: e.target.value})
                                          }} />
                           
                        </Form.Group>
                        <Form.Group controlId="formBasicEmail">
                            <Form.Label className="contact-form-label">Work email*</Form.Label>
                            <Form.Control type="email" placeholder="Enter" required
                                          onChange={(e) => {
                                            
                                              this.setState({email: e.target.value},()=>{
                                                let regex = new RegExp('^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$');
                                                if(!regex.test(this.state.email)){
                                                 this.setState({
                                                  emailErr:"Invalid Email"
                                                 })
                                                }else if(this.state.email.includes("gmail") || this.state.email.includes("yahoo")  ){
                                                  this.setState({
                                                    emailErr:"Please enter work email"
                                                   })
                                                }else{
                                                  this.setState({
                                                    emailErr:""
                                                   })
                                                }
                                              })
                                          }} onError={(err) => {
                                              console.log(err);
                                          }}/>
                                 {
                                this.state.emailErr.length >  0 ? <div className="err-msg">{this.state.emailErr}</div> : ""
                            }           
                         
                        </Form.Group>
                        <Form.Group controlId="formBasicEmail">
                            <Form.Label className="contact-form-label">Company name*</Form.Label>
                            <Form.Control type="name" placeholder="Enter" required
                                          onChange={(e) => {
                                              this.setState({companyName: e.target.value})
                                          }} onError={(err) => {
                                              console.log(err);
                                          }}/>
                            
                          
                        </Form.Group>
                        <Form.Group controlId="formBasicName" className="custom-rct-phn">
                            <Form.Label className="contact-form-label">Phone number*</Form.Label>
                            
                            <PhoneInput
                                country={'us'}
                                value={this.state.phoneNumber}
                                onChange={phoneNumber => this.setState({ phoneNumber, phoneErr:"" })}
                                enableSearch={false}
                                />              
                        
                            {
                                this.state.phoneErr.length >  0 ? <div className="err-msg">{this.state.phoneErr}</div> : ""
                            }
                        </Form.Group>

                      
                        <Form.Group controlId="formBasicName" className="c-form-select-grp">
                            <Form.Label className="contact-form-label">How did you hear about us?*</Form.Label>
                            <div className="d-flex justify-space-between c-form-select" as="select" placeholder="Select" onChange={(e) => {this.setState({hearAboutUs: e.target.value})}} 
                            onClick={()=>{
                              this.setState({
                                hearAboutUsOpen:!this.state.hearAboutUsOpen
                              })
                            }} >
                              <div className="selected-value">{this.state.others || this.state.hearAboutUs}</div>
                              <div className="cfsel-drop-span"><svg xmlns="http://www.w3.org/2000/svg" width="13" height="8" viewBox="0 0 13 8" fill="none">
<path fill-rule="evenodd" clip-rule="evenodd" d="M12.0694 1.55324L10.8357 0.442483L6.39331 5.37629L1.45943 0.933809L0.348677 2.16743L5.28255 6.60991L5.28195 6.61058L6.51557 7.72134L6.51617 7.72067L6.51678 7.72121L7.62754 6.48759L7.62693 6.48705L12.0694 1.55324Z" fill="#555555"/>
</svg>
                                </div>
                            </div>
                            
                            <div>
                              {
                                this.state.hearAboutUsOpen &&
                                <div className="select-option">
                              {this.state.hearAboutUsDropData?.map((data, i)=>{
                                  return <div className="d-block" onClick={()=>{
                                    this.setState({hearAboutUs:data,others:"", hearAboutUsOpen:false})
                                  }}>{data}</div>
                              })}
                               <Form.Control type="name" placeholder="Others"
                               value={this.state.others}
                                          onChange={(e) => {
                                              this.setState({others: e.target.value,hearAboutUs:""})
                                          }} 
                                          
                                          />
                            </div>
                              }
                            
                           
                            </div>
                           

                           
                        </Form.Group>    
                        {
                          this.state.hearAboutUs === "Friend / Colleague" && 
                          <Form.Group controlId="formBasicName">
                          <Form.Label className="contact-form-label">Who is the awesome person? we can thank him later</Form.Label>
                          <Form.Control type="name" placeholder="Enter" value={this.state.friendName} 
                                        onChange={(e) => {
                                            this.setState({friendName: e.target.value})
                                        }} />
                        
                      </Form.Group>   
                        }
                                 
                        <Form.Group controlId="exampleForm.ControlTextarea1">
                            <Form.Label className="contact-form-label">Enter your message</Form.Label>
                            <Form.Control className="contact-form-txt-area" as="textarea" rows="3" maxlength="1000"
                                          value={this.state.message}
                                          onChange={(e) => {
                                              this.setState({message: e.target.value})
                                          }}/>
                        </Form.Group>
                        <Form.Group controlId="formBasicCheckbox">
                            <div className="form-element">
                                <div class="form-element sp-form pd-tb ripple-effect check-content ">
                                    <input class="inp-cbx" id="bx-2" type="checkbox"
                                           defaultChecked={this.state.processInformation}
                                           onChange={() => {
                                               this.setState((prevState) => ({processInformation: !prevState.processInformation}))
                                           }}>
                                    </input>
                                    <label class="cbx m-0 theme-change" for="bx-2">
                                {/* <span>
                                    <svg height="10px" viewBox="0 0 12 10" width="12px">
                                        <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                                    </svg>
                                </span> */}
                                        <span class="sp-txt-upload">&nbsp;</span>
                                        <span className="check-custom-text">I consent Ionixx to store and process the information I submit. </span>
                                    </label>
                                </div>
                            </div>
                            {/* <Form.Text className="text-muted">
                                We will never share your personal information with anyone else.
                            </Form.Text> */}
                        </Form.Group>
                        {/* <Form.Group className="contact-form-group">
                            <Button className="contact-submit" variant="primary" type="submit" disabled={!(this.state.processInformation && this.state.phoneNumber && this.state.email && this.state.name)}
                                    onClick={() => {
                                        this.setState({
                                            validated: true
                                        },()=>{
                                           this.onSubmitHandler
                                        })
                                    }}>
                                Submit {this.state.shortLoader ? <span className={"short-loader ml-2"}></span> :""}
                            </Button>
                        </Form.Group> */}
                    </Form>
                    <Button 
                    className="contact-submit" 
                    variant="primary" 
                    type="submit" 
                    disabled={!(this.state.processInformation && this.state.phoneNumber && this.state.email && this.state.name && this.state.companyName && (this.state.hearAboutUs || this.state.others) && !this.state.emailErr)}
                    onClick={()=>{this.onSubmitHandler()} }>
                      Submit {this.state.shortLoader ? <span className={"short-loader ml-2"}></span> :""}
                    </Button>
                </div>
              </div>
            </div>
          </div>
          

        </div>
      </>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return state.commonReducer;
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return bindActionCreators(contactAction, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps)(TalkToUs);
