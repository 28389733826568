import React from 'react';
import {Link} from "react-router-dom";
import { ROUTES } from '../../../../routes';
import {PortfolioRoute} from "../../../portfolio/route-porfolio";
import Helmet from 'react-helmet';
import ReactSEOMetaTags from "react-seo-meta-tags";

class CaseStudyGaddiBazar extends React.Component {
    constructor(props) {
        super(props)
        this.state={
            downloadLink: '/assets/case-studies/Gaadi-Bazaar-case-study.pdf'
        }
    }

    

    render() {
        return (
            <>  
             <Helmet>
            <meta charSet="utf-8" />
            <meta
                name="viewport"
                content="width=device-width, initial-scale=1.0, shrink-to-fit=no, user-scalable=no, maximum-scale=1"
            />
            <title>Multivendor Online Vehicle Trading Platform | Ionixx Tech</title>
            <meta name="description" content="Are you looking to build an online marketplace for your business? Here’s a highly-customizable solution we built to streamline online vehicle trading operations." />
            <link rel="canonical" href="https://www.ionixxtech.com/case-studies/vehicle-trading-platform" />
            <meta
                property="og:image"
                content={
                "https://www.ionixxtech.com/assets/img/SEO_OG_IMAGE/Gaadi Bazaar OG image.png"
                }
            />

        </Helmet>
        <ReactSEOMetaTags
      render={(el) => <Helmet>{el}</Helmet>}
      website={{
        name: "Ionixx Technologies",
        title:
          "Multivendor Online Vehicle Trading Platform | Ionixx Tech",
        description:
          "Are you looking to build an online marketplace for your business? Here’s a highly-customizable solution we built to streamline online vehicle trading operations.",
        type: "website",
        image: "https://www.ionixxtech.com/assets/img/SEO_OG_IMAGE/Gaadi Bazaar OG image.png",
        url:
          "https://www.ionixxtech.com/case-studies/vehicle-trading-platform",

      }}
      twitter={{
        twitter:
          "https://www.ionixxtech.com/case-studies/vehicle-trading-platform",
      }}
    />

<div class="Resources">
                <section class="section-2 ppr-dnld-custom-sec beige-bg">
                    <div class="custom-container">
                        <div class="tab-content">

                            
            <div className="newsissuedetailed">
            <div className="detailed-common">
                <div className="back-to-common">
                    <span className="back-to-icon-hold">
                        <img src="/assets/img/resource/back-to-new.svg"  alt="bact to new"></img>
                    </span>
                    <Link to={ROUTES.PORTFOLIO}><span className="back-to-link">Portfolio</span></Link>
                </div>

                <div className="whats-on-hold">
                    <div className="ebook-sec">
						<div className="row">
							<div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 resp-apprch-col">
								<div className="custom-section work-with-custom-section">
                                    <div class="main-head-div">
                                        <h1 class="main-head-1">
                                            <span class="secondary-span">Digitizing Automobile Trading Business</span>
                                        </h1>
                                    </div>
                                    <div className="main-para-div">
                                    
                                            {/* <a class="d-hide m-show" href="">View our software portfolio</a> */}
                                        <p>Gaadi Bazaar, an initiative of the Cholamandalam Investment and Finance Company Limited, approached Ionixx with a specific business requirement. </p>

<p>They were looking to build a structured dealer/vendor trading ecosystem, driven by values of transparency, for buying and selling commercial vehicles online. </p>

<p>They sought to develop a <Link to={PortfolioRoute.GaadiBazaar}>vehicle trading platforms</Link>, equipped with information and functionalities on pricing, promotional offers and discounts, insurance policies, market price comparisons, and expert recommendations.</p>

<p>At its core, the need was to build a robust and open platform to empower users to carry out vehicle trading activities in the automobile market in a secure and seamless manner. 
</p>
                                    </div>
								</div>
							</div>
							<div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 resp-apprch-col">
								<div class="ebook-form-sec">
                                    <div class="modal-title">
                                        <h4>Click download to get PDF</h4>
                                    </div>
                                    <a href={this.state.downloadLink} target="_blank">
                                            <button class="download-case-study contact-submit btn btn-primary">
                                                Download
                                            </button>
                                        </a>
                                </div>
							</div>
						</div>	
		            </div>
                </div>

            </div>
        </div> 


                
        </div>
        </div>
        </section>
        </div>

            </>
        );
    }

}

export default CaseStudyGaddiBazar;
