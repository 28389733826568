import React from 'react';
import { bindActionCreators } from "redux";
import * as contactAction from "../../action/contactAction";
import { connect } from "react-redux";
import "./algo-bot.css";
import { Button, Collapse, Modal, Form } from 'react-bootstrap';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css';
import { SimpleSelect } from '../../components/common';
import ReactSEOMetaTags from 'react-seo-meta-tags';
import Helmet from 'react-helmet';
import ReactPlayer from 'react-player'
import {ROUTES} from "../../routes";

// import Slider from "react-slick";
// import "slick-carousel/slick/slick.css";
// import "slick-carousel/slick/slick-theme.css";
class AlgoBot extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            name: '',
            message: '',
            email: '',
            emailErr: '',
            phoneNumber: '',
            phoneErr: '',
            successMessage: false,
            countDown: 5,
            // videoModalUrl: 'https://www.dropbox.com/s/xmaun14tzgdur2r/Ionixx%20-%20Crypto%20Bot.mp4?dl=0'
        }
        this.onSubmitHandler = this.onSubmitHandler.bind(this);
    }

    async onSubmitHandler() {

        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        console.log(re.test(String(this.state.email).toLowerCase()))
        if (re.test(String(this.state.email).toLowerCase())) {
            const requestPayload = {
                businessEmailID: this.state.email,
                campaignName: "crypto-bot-campaign"
            };
            if (this.state.phoneNumber) {
                requestPayload['mobileNumber'] = this.state.phoneNumber;
            }
            //social@ionixxtech.com
            try {
                const response = await this.props.sendEmailForContact(requestPayload);
                if (response.status === 200) {
                    // this.onDownloadPressed()
                    // this.props.closePopup();
                    this.setState({
                        name: '',
                        message: '',
                        email: '',
                        emailErr: '',
                        phoneNumber: '',
                        phoneErr: '',
                        successMessage: true,
                        // videoModalUrl: 'https://www.dropbox.com/s/xmaun14tzgdur2r/Ionixx%20-%20Crypto%20Bot.mp4?dl=0'
                    }, () => {
                        /* this.props.history.push({
                            pathname: ROUTES.TOAST,
                            contactDetails: false,
                            subscribeDetails: true
                        });
                        this.props.toastShow(true) */
                        setInterval(()=>{
                            this.setState({...this.state, countDown: this.state.countDown-1})  
                        },1000);
                        setTimeout(()=>{
                            this.setState({...this.state, successMessage: false})
                            this.props.history.push(ROUTES.ALGOBOTDEVELOPMENT);
                        }, 5000)
                    });
                    
                }
            }
            catch (err) {
                console.log(err);
                this.setState({
                    ...this.state,
                    emailErr: false
                })
            }
        } else {
            this.setState({
                ...this.state,
                emailErr: true
            })
        }

        //}
    }

    render() {

        const metaTitle = " Crypto Trading Bot Development Company | Ionixx Tech";
        const metaDescription = "Automate your crypto trading strategies with crypto trading algorithmic bots. Build proficient algorithmic software with Ionixx.";

        return (


            <>
                <Helmet>
                    <title>{metaTitle}</title>
                    <meta name="description" content={metaDescription} />
                    <link rel="canonical" href="https://www.ionixxtech.com/crypto-bot-development" />
                    <meta property="og:title" content={metaTitle} />
                    <meta property="og:type" content={`website`} />
                    <meta property="og:url" content={"https://www.ionixxtech.com/crypto-bot-development"} />
                    <meta property="og:description" content={metaDescription} />
                    <meta property="og:image" content={"https://www.ionixxtech.com/assets/img/SEO_OG_IMAGE/crypto-bot-development.png"} />
                </Helmet>
                <div className="about-us section">
                    <div className="algorith-bot">
                    <div className="crypto-banner-otc white-bg">
                            <div className="">
                                    <div className="custom-section">
                                        <div className="main-head-div">
                                            <h1 className="main-head-1">
                                                <span className="primary-span">Automated Algorithmic Bot Solutions <em>from Ionixx</em></span>
                                            </h1>
                                        </div>
                                       </div>
                                <div className="row">

                                    <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                                        <div className="professionals-hold">
                                            <ul className="professionals-ul">
                                                <li>
                                                    {/* <div className="professionals-num">
                                                        <span>
                                                            <svg width="25" height="25" viewBox="0 0 36 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                <circle cx="18" cy="20" r="9" fill="#24A989" />
                                                            </svg>
                                                        </span>
                                                    </div> */}
                                                    <div className="professionals-info">
                                                        <strong> Fully automated, real-time experience:</strong> 
                                                        <span>We build robust algorithmic software that helps run fully automated bots, 24/7.</span>
                                                    </div>
                                                </li>

                                                <li>
                                                    {/* <div className="professionals-num">
                                                        <span> <svg width="25" height="25" viewBox="0 0 36 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <circle cx="18" cy="20" r="9" fill="#24A989" />
                                                        </svg>
                                                        </span>
                                                    </div> */}
                                                    <div className="professionals-info">
                                                        <strong>Executable ready-to-go strategies:</strong>
                                                        <span>Customizable to suit both basic and advanced trading strategies</span> 
                                                    </div>
                                                </li>

                                                <li>
                                                    {/* <div className="professionals-num">
                                                        <span> <svg width="25" height="25" viewBox="0 0 36 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <circle cx="18" cy="20" r="9" fill="#24A989" />
                                                        </svg>
                                                        </span>
                                                    </div> */}
                                                    <div className="professionals-info">
                                                        <strong>Low-latency, customizable bots:</strong> 
                                                        <span>Our algorithmic bot development services are custom fit to suit your business requirements.</span>
                                                    </div>
                                                </li>

                                                <li>
                                                    {/* <div className="professionals-num">
                                                        <span> <svg width="25" height="25" viewBox="0 0 36 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <circle cx="18" cy="20" r="9" fill="#24A989" />
                                                        </svg>
                                                        </span>
                                                    </div> */}
                                                    <div className="professionals-info">
                                                        <strong>Intuitive GUI based algorithmic software:</strong> 
                                                        <span>We provide highly design-driven, end-to-end algorithmic software development services with data-intensive charts, backtesting software, and historical data download.</span> 
                                                    </div>
                                                </li>
                                                <li>
                                                    {/* <div className="professionals-num">
                                                        <span> <svg width="25" height="25" viewBox="0 0 36 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <circle cx="18" cy="20" r="9" fill="#24A989" />
                                                        </svg>
                                                        </span>
                                                    </div> */}
                                                    <div className="professionals-info">
                                                        <strong>Easily executable ready-to-go strategies: </strong>
                                                        <span>Our algorithmic software bot development services allow easy implementation of arbitrage, market making, and several other algorithmic strategies.</span> 
                                                    </div>
                                                </li>
                                                <li>
                                                <div class="main-para-div">
                                                <a href="https://www.ionixxtech.com/crypto-bot-development/develop-crypto-trading-bot" target="_blank"><button class="btn btn-primary connect-btn" type="">
                                                    Read more</button></a>
                                                    </div>
                                                   
                                                </li>
                                            </ul>
                                            
                                        </div>
                                    </div>
                               
                                    <div className="col-lg-6 col-md-6 col-sm-12 col-12">
                                         <div className="contact-details">
                                                <div className="main-head-div">
                                                <img src="/assets/img/algo-bot/version-new/bot-solution.png"></img>

                                                    {/* <h2 className="main-head-1">
                                                        <span className="secondary-span">Drop in your contact details and our experts will get in touch with you</span>
                                                    </h2> */}
                                                </div>
                                                {/* <div className="contact-modal">
                                                <div className="secondary-span">
                                                {this.state.successMessage ? 
                                                    <> <span>Thank you!! Our experts will get in touch with you shortly</span><span>You will be redirected in { this.state.countDown }</span></>:
                        
                                                    <Form>
                                                        
                                                        <Form.Group controlId="formBasicEmail">
                                                            <Form.Label className="contact-form-label">Email Address</Form.Label>
                                                            <Form.Control type="email" placeholder="Your Work Email"
                                                                required
                                                                value={this.state.email}
                                                                onChange={(e) => {
                                                                    this.setState({ email: e.target.value })
                                                                }} />
                                                            {
                                                                this.state.emailErr && <div class="invalid-div">Invalid email</div>
                                                            }
                                                           
                                                        </Form.Group>


                                                        <Form.Group controlId="formBasicName" className="custom-rct-phn">
                                                            <Form.Label className="contact-form-label">Mobile Number (Optional)</Form.Label>
                                                            <PhoneInput
                                                                country={'us'}
                                                                value={this.state.phoneNumber}
                                                                onChange={phoneNumber => this.setState({ phoneNumber })}
                                                                enableSearch={false}
                                                            />
                                                        </Form.Group>

                                                        <div className="contact-submit-btn">
                                                            <Button className="contact-submit" variant="primary" disabled={!this.state.email} onClick={
                                                                this.onSubmitHandler
                                                            }>
                                                                Submit
                                                            </Button>
                                                        </div>

                                                    </Form>
                                                    }</div>
                                                </div>
                                                <p className="mail-to-ix">Mail us at <a>info@ionixxtech.com</a> with your requirements</p> */}

                                            </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="lets-connect-form responsive-mode">
                            <div className="heading main-para-div">
                                <div className="main-head-1">
                                    <span className="secondary-span">Have a Query</span>
                                    <span className="primary-span">Let’s Connect</span>
                                </div>
                            </div>
                            <div className="contact-modal ">
                                <Form noValidate validated={this.state.validated} >
                                    <Form.Group controlId="formBasicName ">
                                        <Form.Label className="contact-form-label">Name</Form.Label>
                                        <Form.Control type="name" placeholder="Enter your name" value={this.state.name} required
                                            onChange={(e) => {
                                                this.setState({ ...this.state, name: e.target.value })
                                            }} />
                                        <Form.Control.Feedback type="invalid">
                                            Name cannot be empty.
                                         </Form.Control.Feedback>
                                         <a className="default-alert">We’ll never share your email with anyone else.</a>
                                    </Form.Group>
                                    <Form.Group controlId="formBasicEmail">
                                        <Form.Label className="contact-form-label">Email Address</Form.Label>
                                        <Form.Control type="email" placeholder="Your email" required
                                            onChange={(e) => {
                                                this.setState({ ...this.state, email: e.target.value })
                                            }} onError={(err) => {
                                                console.log(err);
                                            }} />

                                        <Form.Control.Feedback type="invalid">
                                            Email invalid.
                            </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group controlId="formBasicName" className="custom-rct-phn">
                                        <Form.Label className="contact-form-label">Phone Number</Form.Label>

                                        <PhoneInput
                                            country={'us'}
                                            value={this.state.phoneNumber}
                                            onChange={phoneNumber => this.setState({ ...this.state, phoneNumber, phoneErr: "" })}
                                            enableSearch={false}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            Phone Number cannot be empty.
                                     </Form.Control.Feedback>
                                        {
                                            this.state.phoneErr.length > 0 ? <div className="err-msg">{this.state.phoneErr}</div> : ""
                                        }
                                    </Form.Group>
                                    <Form.Group controlId="formBasicName ">
                                        <Form.Label className="contact-form-label">Interested in</Form.Label>
                                        <Form.Control type="name" placeholder="Enter your name" value={this.state.name} required
                                            onChange={(e) => {
                                                this.setState({ ...this.state, name: e.target.value })
                                            }} />
                                        <Form.Control.Feedback type="invalid">
                                            Name cannot be empty.
                                     </Form.Control.Feedback>
                                    </Form.Group>

                                    <Form.Group controlId="exampleForm.ControlTextarea1">
                                        <Form.Label className="contact-form-label">Description (max 250 characters)?</Form.Label>
                                        <Form.Control className="contact-form-txt-area" as="textarea" rows="3" maxlength="1000"
                                            value={this.state.message}
                                            onChange={(e) => {
                                                this.setState({ ...this.state, message: e.target.value })
                                            }} />
                                    </Form.Group>
                                    <Form.Group controlId="formBasicCheckbox">
                                        <div className="form-element">
                                            <div class="form-element sp-form pd-tb ripple-effect check-content ">
                                                <input class="inp-cbx" id="bx-2" type="checkbox"
                                                    defaultChecked={this.state.processInformation}
                                                    onChange={() => {
                                                        this.setState((prevState) => ({ processInformation: !prevState.processInformation }))
                                                    }}>
                                                </input>
                                                <label class="cbx m-0 theme-change" for="bx-2">
                                                    <span>
                                                        <svg height="10px" viewBox="0 0 12 10" width="12px">
                                                            <polyline points="1.5 6 4.5 9 10.5 1"></polyline>
                                                        </svg>
                                                    </span>
                                                    <span class="sp-txt-upload">&nbsp;</span>
                                                    <span className="check-custom-text ">I consent Ionixx to store and process the information I submit</span>
                                                </label>
                                            </div>
                                        </div>
                                    </Form.Group>
                                </Form>
                                <Button className="contact-submit" variant="primary" type="submit"
                                    disabled={!(this.state.message && this.state.phoneNumber && this.state.email && this.state.name)}
                                    onClick={
                                        () => { this.onSubmitHandler() }

                                    }>
                                    Submit {this.state.shortLoader ? <span className={"short-loader ml-2"}></span> : ""}
                                </Button>

                            </div>
                        </div>
                       

                        <div>

                        </div>
                    </div>
                </div>


            </>
        );
    }
}

const mapStateToProps = (state, ownProps) => {
    return state.commonReducer;
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return bindActionCreators(contactAction, dispatch);
};

export default connect(mapStateToProps, mapDispatchToProps, null,
    { forwardRef: true }
)(AlgoBot);
