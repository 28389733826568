import React, {Component} from 'react';
import "../resources.css"
import WatchVideo from "../../../components/watch-video";
import {Link} from "react-router-dom";
import {SlideDown} from 'react-slidedown'
import 'react-slidedown/lib/slidedown.css'
import ReactSEOMetaTags from 'react-seo-meta-tags';
import Helmet from 'react-helmet';
import {VIDEO_LINK} from "../../../utilities/constants";
import { withRouter,useLocation } from 'react-router-dom';
import Events from '../events-news/events-news';
import { ROUTES } from '../../../routes';
import { ResourceRoute } from '../resource-routing';
class ResourceTop extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            headerClassAdd: "",
            showVideo: false,
            videoModalUrl: '',
            videoModalTitle: '',
            pageEnabled: false,
            buttonShow: true,
            start: 0,
            end: 3,
            showMedia: false,
        }
    }

    componentDidMount() {
        window.addEventListener('scroll', this.showSubscribe);
    }
    showSubscribe = () => {
        if(document.getElementById('mediaIcon')){
            const scrollTop = window.scrollY + 100;
            const mediaTop = document.getElementById('mediaIcon').offsetTop;
            // const subscribeFloat = document.getElementById('sub-float-div').offsetTop;
            // const subscribeTop = document.getElementById('subscribeBottom').offsetTop - 200;
            console.log(scrollTop, mediaTop)
            if (scrollTop > mediaTop && scrollTop && scrollTop < 1050){
                this.setState({showMedia: true});
               this.setState({showSubscribeFloat: false})
            }
            // else if (subscribeTop < scrollTop){
            //     this.setState({showMedia : false});
            //     this.setState({showSubscribeFloat: false});
            // }
            else if (scrollTop >= 1050){
                this.setState({showMedia: true});
                // this.setState({showMedia : false});
                this.setState({showSubscribeFloat: true});
            }else{
                this.setState({showMedia : false});
               this.setState({showSubscribeFloat: false})
            }
        }
    }

    render() {
        let pathName = this.props.location.pathname;
        return (
            <>	
             <Helmet>
                <title>Events,Webinars,Workshops & Conferences for Ionixx Technologies </title>
                <meta name="description" content="Keep updated on our Events, Webinars, Workshops, Conferences and other useful resources. Ionixx organizes various activities for easy participation and access to all information in one place." />
                <link rel="canonical" href="https://www.ionixxtech.com/resources/events" />
            </Helmet>
            <ReactSEOMetaTags
                render={(el) => <Helmet>{el}</Helmet>}
                website={{ 
                    name: "Ionixx Technologies", 
                    title:"Events,Webinars,Workshops & Conferences for Ionixx Technologies", 
                    description: "Keep updated on our Events, Webinars, Workshops, Conferences and other useful resources. Ionixx organizes various activities for easy participation and access to all information in one place. ",
                    type:"website",
                    image:'/assets/img/ionixx-logo.png',
                    url:"https://www.ionixxtech.com/resources/events"
                }}
                twitter={{ 
                    twitter: "https://www.ionixxtech.com/resources/events" }}
                /> 
            <div className='resource-top'>
                    {/* <h1>Our Resources</h1> */}
                    <div className='resource-menu'>
                        <ul>
                            <li>
                                <a href="https://blog.ionixxtech.com/">
                                <span>Blog</span>
                                <img src='/assets/img/resource/blog-menu.svg'/>
                                </a>
                            </li>
                            <li className={pathName === ResourceRoute.EVENTSNEWS_TAB ? "active" : ""}>
                                <Link to={ResourceRoute.EVENTSNEWS_TAB}>
                                <span>Events & News</span>
                                <img src='/assets/img/resource/event-menu.svg'/>
                                </Link></li>
                            <li className={pathName === ResourceRoute.WHITEPAPER_TAB ? "active" : ""}> 
                                <Link to={ResourceRoute.WHITEPAPER_TAB}>
                                <span>Whitepapers & E-Books</span>
                            <img src='/assets/img/resource/whitepaper-menu.svg'/>
                            </Link>
                            </li>
                            <li className={pathName === ResourceRoute.NEWSLETTER_TAB ? "active" : ""}>
                                <Link to={ResourceRoute.NEWSLETTER_TAB}>
                                <span>Newsletter</span>
                            <img src='/assets/img/resource/newsletter-menu.svg'/>
                            </Link>
                            </li>
                        </ul>
                    </div>
                </div>
            </>    
        );
    }

}

export default withRouter(ResourceTop);
