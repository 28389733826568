import React from 'react';
import Header from "../../components/header/header";
import {Link} from "react-router-dom";
import {ROUTES} from "../../routes";
import "./enterprise.css"
import ContactUS from "../../components/contact-us";
// import { ToastComponent } from '../../components/common';

import * as contactAction from "../../action/contactAction";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

class Enterprise extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            headerClassAdd: "",
            showContactUS: false,
            showToast: false
		}
		this.onScrollHeader = this.onScrollHeader.bind(this)
	}
	componentDidMount() {
		window.addEventListener('scroll', this.onScrollHeader);
	}
	
	componentWillUnmount() {
		window.removeEventListener('scroll', this.onScrollHeader);
	}
	onScrollHeader(){
	const winScroll =
    document.body.scrollTop || document.documentElement.scrollTop

  	const height =
		document.documentElement.scrollHeight -
		document.documentElement.clientHeight

	const scrolled = winScroll / height
			
	if(scrolled === 0){
		this.setState({
			headerClassAdd: ""
		})
	}else {
		this.setState({
			headerClassAdd: "scrollHeader"
		})
	}
    }
    sendToast(){
		this.setState({showContactUS:true})
	}
    render(){
        return (
        <div className="Enterprise mob-view">
                <div className="section-1 common-hero-bg white-bg">
                    <div className="custom-container">
                        <div className="custom-section">
                            <div className="main-head-div">
                                <h4 className="main-head-1">
                                    <span className="secondary-span">Implement new</span>
                                    <span className="primary-span">Business Ideas</span>
                                </h4>
                            </div>
                            <div className="main-para-div short-div">
                                <p>Ionixx provides diverse, reliable, and scalable technology solutions to enterprises.</p>
                            </div>
                            {/* <div className="main-para-div">
                                <button className="btn btn-primary connect-btn" type=""><span>Connect with us</span></button>
                            </div> */}
                        </div>
                    </div>
                </div>

                <div className="company-info">
                    <div className="section-2 beige-bg">
                        <div className="custom-container">
                            <div className="custom-section">
                                <div className="row work-with-row">
                                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 work-with-col">
                                    <div className="left-info-hold">
                                        <div className="main-head-div custom-main-head-div">
                                            <h4 className="main-head">
                                                <span  className="primary-span">Catalyst to</span>
                                                <span  className="secondary-span">Digital Transformation</span>
                                            </h4>
                                        </div>
                                        <div className="main-para-div">
                                            <p>Challenge the status quo and experiment in a controlled setting to deliver greater value to your customers by integrating digital technologies into all areas of your business.</p>
                                            <p>Our design experts will help you to systematically think through how to align your business goals with your customers’ expectations. While our technologists bring efficiency and reliability to anything you wish to build and implement.</p>
                                        </div>
                                        <div className="more-text">
                                        <Link className="nav-link" to={ROUTES.DESIGN}>Understand our design methodology</Link></div>
                                    </div>
                                    </div>
                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 comp-info-right-col">
                                    <div className="right-img-hold">
                                        <div className="comp-info-right-img">
                                            <span class="company-offer-imgs">
                                                <svg fill="none" viewBox="0 0 512 467" xmlns="http://www.w3.org/2000/svg">
                                                <rect x="183.31" y="370.35" width="60.123" height="87.044" fill="#FFCC4A"/>
                                                <path d="m389.1 456.42h-20.078v-155.6c0-1.331-0.528-2.608-1.47-3.549-0.941-0.941-2.218-1.47-3.549-1.47h-30.115v-15.058c0-1.331-0.529-2.608-1.471-3.549-0.941-0.942-2.218-1.47-3.549-1.47h-20.077c-1.331 0-2.608 0.528-3.549 1.47-0.941 0.941-1.47 2.218-1.47 3.549v15.058h-25.096v-56.216c0.023-1.174-0.366-2.32-1.099-3.237-0.734-0.917-1.766-1.548-2.917-1.782l-120.46-30.116h-4.016c-0.623 0.467-1.129 1.074-1.478 1.771-0.348 0.697-0.529 1.465-0.529 2.244v247.95h-20.078c-1.331 0-2.607 0.529-3.549 1.47-0.941 0.942-1.47 2.218-1.47 3.55 0 1.331 0.529 2.607 1.47 3.549 0.942 0.941 2.218 1.47 3.549 1.47h261c1.331 0 2.607-0.529 3.549-1.47 0.941-0.942 1.47-2.218 1.47-3.549 0-1.332-0.529-2.608-1.47-3.55-0.942-0.941-2.218-1.47-3.549-1.47zm-150.58-1.003h-50.192v-79.305h50.192v79.305zm30.116-154.59v154.59h-20.077v-84.324c0-1.331-0.529-2.608-1.47-3.549-0.941-0.942-2.218-1.471-3.549-1.471h-60.232c-1.331 0-2.607 0.529-3.549 1.471-0.941 0.941-1.47 2.218-1.47 3.549v84.324h-20.077v-239.92l110.42 28.108v57.22zm45.173-15.058h10.039v10.039h-10.039v-10.039zm45.174 170.66h-80.308v-150.58h80.308v150.58z" fill="#BCC4C7"/>
                                                <path d="m182.31 272.72h20.077c1.332 0 2.608-0.529 3.55-1.47 0.941-0.942 1.47-2.218 1.47-3.55 0-1.331-0.529-2.607-1.47-3.549-0.942-0.941-2.218-1.47-3.55-1.47h-20.077c-1.331 0-2.608 0.529-3.549 1.47-0.941 0.942-1.47 2.218-1.47 3.549 0 1.332 0.529 2.608 1.47 3.55 0.941 0.941 2.218 1.47 3.549 1.47z" fill="#BCC4C7"/>
                                                <path d="m223.46 272.72h20.077c1.331 0 2.608-0.529 3.549-1.47 0.941-0.942 1.47-2.218 1.47-3.55 0-1.331-0.529-2.607-1.47-3.549-0.941-0.941-2.218-1.47-3.549-1.47h-20.077c-1.332 0-2.608 0.529-3.55 1.47-0.941 0.942-1.47 2.218-1.47 3.549 0 1.332 0.529 2.608 1.47 3.55 0.942 0.941 2.218 1.47 3.55 1.47z" fill="#BCC4C7"/>
                                                <path d="m182.31 307.86h20.077c1.332 0 2.608-0.529 3.55-1.47 0.941-0.941 1.47-2.218 1.47-3.549 0-1.332-0.529-2.608-1.47-3.549-0.942-0.942-2.218-1.471-3.55-1.471h-20.077c-1.331 0-2.608 0.529-3.549 1.471-0.941 0.941-1.47 2.217-1.47 3.549 0 1.331 0.529 2.608 1.47 3.549s2.218 1.47 3.549 1.47z" fill="#BCC4C7"/>
                                                <path d="m223.46 307.86h20.077c1.331 0 2.608-0.529 3.549-1.47s1.47-2.218 1.47-3.549c0-1.332-0.529-2.608-1.47-3.549-0.941-0.942-2.218-1.471-3.549-1.471h-20.077c-1.332 0-2.608 0.529-3.55 1.471-0.941 0.941-1.47 2.217-1.47 3.549 0 1.331 0.529 2.608 1.47 3.549 0.942 0.941 2.218 1.47 3.55 1.47z" fill="#BCC4C7"/>
                                                <path d="m182.31 342.99h20.077c1.332 0 2.608-0.529 3.55-1.47 0.941-0.942 1.47-2.218 1.47-3.55 0-1.331-0.529-2.607-1.47-3.549-0.942-0.941-2.218-1.47-3.55-1.47h-20.077c-1.331 0-2.608 0.529-3.549 1.47-0.941 0.942-1.47 2.218-1.47 3.549 0 1.332 0.529 2.608 1.47 3.55 0.941 0.941 2.218 1.47 3.549 1.47z" fill="#BCC4C7"/>
                                                <path d="m223.46 342.99h20.077c1.331 0 2.608-0.529 3.549-1.47 0.941-0.942 1.47-2.218 1.47-3.55 0-1.331-0.529-2.607-1.47-3.549-0.941-0.941-2.218-1.47-3.549-1.47h-20.077c-1.332 0-2.608 0.529-3.55 1.47-0.941 0.942-1.47 2.218-1.47 3.549 0 1.332 0.529 2.608 1.47 3.55 0.942 0.941 2.218 1.47 3.55 1.47z" fill="#BCC4C7"/>
                                                <path d="m310.8 335.96h-17.066c-1.331 0-2.608 0.529-3.549 1.47s-1.47 2.218-1.47 3.549 0.529 2.608 1.47 3.549c0.941 0.942 2.218 1.47 3.549 1.47h17.066c1.331 0 2.608-0.528 3.549-1.47 0.941-0.941 1.47-2.218 1.47-3.549s-0.529-2.608-1.47-3.549-2.218-1.47-3.549-1.47z" fill="#BCC4C7"/>
                                                <path d="m321.84 340.98c0 1.331 0.529 2.608 1.47 3.549 0.941 0.942 2.218 1.47 3.549 1.47h17.066c1.331 0 2.608-0.528 3.549-1.47 0.941-0.941 1.47-2.218 1.47-3.549s-0.529-2.608-1.47-3.549-2.218-1.47-3.549-1.47h-17.066c-1.331 0-2.608 0.529-3.549 1.47s-1.47 2.218-1.47 3.549z" fill="#BCC4C7"/>
                                                <path d="m310.8 376.12h-17.066c-1.331 0-2.608 0.529-3.549 1.47s-1.47 2.218-1.47 3.549 0.529 2.608 1.47 3.549 2.218 1.47 3.549 1.47h17.066c1.331 0 2.608-0.529 3.549-1.47s1.47-2.218 1.47-3.549-0.529-2.608-1.47-3.549-2.218-1.47-3.549-1.47z" fill="#BCC4C7"/>
                                                <path d="m343.92 376.12h-17.066c-1.331 0-2.608 0.529-3.549 1.47s-1.47 2.218-1.47 3.549 0.529 2.608 1.47 3.549 2.218 1.47 3.549 1.47h17.066c1.331 0 2.608-0.529 3.549-1.47s1.47-2.218 1.47-3.549-0.529-2.608-1.47-3.549-2.218-1.47-3.549-1.47z" fill="#BCC4C7"/>
                                                <path d="m310.8 416.27h-17.066c-1.331 0-2.608 0.529-3.549 1.47-0.941 0.942-1.47 2.218-1.47 3.549 0 1.332 0.529 2.608 1.47 3.55 0.941 0.941 2.218 1.47 3.549 1.47h17.066c1.331 0 2.608-0.529 3.549-1.47 0.941-0.942 1.47-2.218 1.47-3.55 0-1.331-0.529-2.607-1.47-3.549-0.941-0.941-2.218-1.47-3.549-1.47z" fill="#BCC4C7"/>
                                                <path d="m343.92 416.27h-17.066c-1.331 0-2.608 0.529-3.549 1.47-0.941 0.942-1.47 2.218-1.47 3.549 0 1.332 0.529 2.608 1.47 3.55 0.941 0.941 2.218 1.47 3.549 1.47h17.066c1.331 0 2.608-0.529 3.549-1.47 0.941-0.942 1.47-2.218 1.47-3.55 0-1.331-0.529-2.607-1.47-3.549-0.941-0.941-2.218-1.47-3.549-1.47z" fill="#BCC4C7"/>
                                                <path d="m198.57 220.49v-46.663" stroke="#BCC4C7" stroke-width="8"/>
                                                <path d="m318.82 277.03v-64.61" stroke="#BCC4C7" stroke-width="8"/>
                                                <path d="m349.33 297.67v-46.663" stroke="#BCC4C7" stroke-width="8"/>
                                                <path d="m228.18 230.36v-30.511l22.882-21.985" stroke="#BCC4C7" stroke-width="8"/>
                                                <circle cx="198.57" cy="168.45" r="6.4736" fill="#FFCC4A" stroke="#BCC4C7" stroke-width="5"/>
                                                <circle cx="253.31" cy="176.52" r="6.4736" fill="#FFCC4A" stroke="#BCC4C7" stroke-width="5"/>
                                                <circle cx="318.82" cy="208.83" r="6.4736" fill="#FFCC4A" stroke="#BCC4C7" stroke-width="5"/>
                                                <circle cx="349.33" cy="247.42" r="6.4736" fill="#FFCC4A" stroke="#BCC4C7" stroke-width="5"/>
                                                <path d="m116.05 433.17 4.864-17.022-24.419-24.418-49.624 14.178-24.812-42.535 37.022-35.84-9.0585-33.083-50.018-12.997v-49.625l50.018-12.603 9.0585-33.083-37.022-35.84 24.812-42.929 49.624 14.178 24.419-24.418-14.179-49.625 42.929-24.812 35.84 37.022 33.083-9.0584 12.604-50.018h49.624l12.603 50.018 33.083 9.0584 35.84-37.022 42.929 24.812-14.178 49.625 24.418 24.418 49.625-14.178 24.812 42.929-37.021 35.84 9.058 33.083 50.019 12.603v49.625l-50.019 12.603-9.058 33.083 37.021 35.84-24.812 42.929-49.625-14.178-24.418 24.418 4.863 17.022h-8.012l-5.516-19.385 30.72-30.72 48.443 13.785 18.511-32.296-36.234-35.052 11.422-42.142 48.837-12.209v-37.021l-48.837-12.21-11.422-42.141 36.234-35.052-18.511-32.296-48.443 13.785-30.72-30.72 13.785-48.443-32.295-18.511-35.053 36.234-42.141-11.422-12.209-48.837h-37.022l-12.209 48.837-42.142 11.422-35.052-36.234-32.295 18.511 13.784 48.443-30.72 30.72-48.443-13.785-18.511 32.296 36.234 35.052-11.422 42.141-48.837 12.21v37.021l48.837 12.209 11.422 42.142-36.234 35.052 18.511 32.296 48.443-13.785 30.72 30.72-5.516 19.385h-8.012zm264.69-74.113c22.946-27.893 36.742-63.58 36.742-102.42 0-89.009-72.467-161.48-161.48-161.48-89.01 0-161.48 72.468-161.48 161.48 0 38.837 13.796 74.525 36.744 102.42v-12.889c-18.16-25.225-28.867-56.153-28.867-89.529 0-84.677 68.923-153.6 153.6-153.6 84.676 0 153.6 68.923 153.6 153.6 0 33.375-10.707 64.302-28.865 89.527v12.89z" clip-rule="evenodd" fill="#BCC4C7" fill-rule="evenodd"/>
                                                </svg>
                                            </span>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="section-2 blue-bg">
		        	<div className="custom-container custom-blk-container">
						<div className="row sec-2-row work-with-row">
							<div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 work-with-col">
								<div className="custom-section work-with-custom-section">
									<div className="main-head-div">
										<h4 className="main-head-1">
											<span className="primary-span">Integration with</span>
											<span className="secondary-span">existing systems.</span>
										</h4>
									</div>
									<div className="main-para-div">
										<p>We integrate modern and legacy systems alike to work in harmony with one another. We start with understanding and analyzing the other systems, designing new architecture to suit the overall systems architecture and implementing it in a scalable and secure manner. 
										</p>
									</div>
                                    <div class="connect-us">
                                        <button onClick={this.sendToast.bind(this)} class="btn connect-btn"><span>Talk to an expert</span></button>
                                    </div>
								</div>
							</div>
							<div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 our-process-col">
								<div className="work-with-sub-head">
									<p className="app-p">Our process</p>
								</div>

								<div className="work-class">
									<ul>
										<li>
											<div className="head-img ylow-bx">
                                                <svg width="130" height="130" viewBox="0 0 130 130" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M110.966 38.7923L113.865 27.9728L103.045 30.8719L105.034 32.8607L90.3796 47.5151C84.9067 38.5129 75.0058 32.5 63.7006 32.5C46.4693 32.5 32.5006 46.4687 32.5006 63.7C32.5006 68.2967 33.4946 72.6612 35.2795 76.5903L14.4375 97.4322L18.1641 101.159L37.9707 81.3521C43.595 89.5345 53.0212 94.9 63.7006 94.9C71.1835 94.9 78.0511 92.2657 83.4267 87.8739L103.053 107.5C103.834 108.281 105.1 108.281 105.881 107.5L107.5 105.881C108.281 105.1 108.281 103.834 107.5 103.053L87.8739 83.4268C92.2661 78.0511 94.9006 71.1832 94.9006 63.7C94.9006 59.7449 94.1646 55.9616 92.8222 52.4798L108.738 36.5643L110.966 38.7923ZM63.7006 90.35C78.419 90.35 90.3506 78.4184 90.3506 63.7C90.3506 48.9816 78.419 37.05 63.7006 37.05C48.9822 37.05 37.0506 48.9816 37.0506 63.7C37.0506 78.4184 48.9822 90.35 63.7006 90.35Z" fill="#2692AA"/>
                                                <path fill-rule="evenodd" clip-rule="evenodd" d="M85.3347 51.7705C86.4759 53.8356 87.3301 56.0815 87.8432 58.4544L75.7495 70.5481C74.4803 71.8173 72.4225 71.8173 71.1533 70.5481L60.4514 59.8462L43.0469 77.2507C41.7893 75.3376 40.7902 73.2393 40.0996 71.0056L58.1533 52.9519C59.4225 51.6827 61.4803 51.6827 62.7495 52.9519L73.4514 63.6538L85.3347 51.7705Z" fill="#FFD028"/>
                                                </svg>
											</div>
											<div className="head-cap">
												<p>Systems Analysis</p>
											</div>
										</li>
										<li>
											<div className="head-img ylow-bx">
                                                <svg width="130" height="130" viewBox="0 0 130 130" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M81.2064 38.48H48.793C47.6208 38.48 46.4965 38.0143 45.6676 37.1854C44.8387 36.3565 44.373 35.2322 44.373 34.06V25.22C44.373 24.0477 44.8387 22.9235 45.6676 22.0946C46.4965 21.2657 47.6208 20.8 48.793 20.8H81.2064C82.3786 20.8 83.5029 21.2657 84.3318 22.0946C85.1607 22.9235 85.6264 24.0477 85.6264 25.22V34.06C85.6264 35.2322 85.1607 36.3565 84.3318 37.1854C83.5029 38.0143 82.3786 38.48 81.2064 38.48ZM48.793 23.7467C48.4023 23.7467 48.0275 23.9019 47.7512 24.1782C47.4749 24.4545 47.3197 24.8292 47.3197 25.22V34.06C47.3197 34.4507 47.4749 34.8255 47.7512 35.1018C48.0275 35.3781 48.4023 35.5333 48.793 35.5333H81.2064C81.5971 35.5333 81.9719 35.3781 82.2482 35.1018C82.5245 34.8255 82.6797 34.4507 82.6797 34.06V25.22C82.6797 24.8292 82.5245 24.4545 82.2482 24.1782C81.9719 23.9019 81.5971 23.7467 81.2064 23.7467H48.793Z" fill="#2692AA"/>
                                                <path d="M64.9996 70.8932C62.6684 70.8932 60.3896 70.202 58.4512 68.9068C56.5129 67.6117 55.0022 65.7709 54.1101 63.6171C53.218 61.4634 52.9846 59.0935 53.4394 56.8071C53.8942 54.5207 55.0167 52.4205 56.6651 50.7721C58.3135 49.1237 60.4137 48.0012 62.7001 47.5464C64.9865 47.0916 67.3564 47.325 69.5101 48.2171C71.6639 49.1092 73.5047 50.6199 74.7998 52.5582C76.095 54.4965 76.7862 56.7754 76.7862 59.1066C76.7827 62.2315 75.5398 65.2275 73.3301 67.4371C71.1205 69.6468 68.1245 70.8897 64.9996 70.8932ZM64.9996 50.2666C63.2512 50.2666 61.5421 50.785 60.0883 51.7564C58.6346 52.7277 57.5015 54.1083 56.8325 55.7236C56.1634 57.3389 55.9883 59.1164 56.3294 60.8312C56.6705 62.546 57.5124 64.1211 58.7487 65.3574C59.985 66.5937 61.5602 67.4356 63.275 67.7767C64.9898 68.1178 66.7672 67.9427 68.3825 67.2737C69.9978 66.6046 71.3784 65.4715 72.3498 64.0178C73.3211 62.5641 73.8396 60.855 73.8396 59.1066C73.8372 56.7628 72.9051 54.5156 71.2478 52.8583C69.5905 51.201 67.3434 50.2689 64.9996 50.2666Z" fill="#2692AA"/>
                                                <path d="M39.9531 69.4199H25.2198C24.0475 69.4199 22.9233 68.9542 22.0944 68.1253C21.2655 67.2964 20.7998 66.1721 20.7998 64.9999V53.2132C20.7998 52.041 21.2655 50.9167 22.0944 50.0878C22.9233 49.2589 24.0475 48.7932 25.2198 48.7932H39.9531C41.1254 48.7932 42.2496 49.2589 43.0786 50.0878C43.9075 50.9167 44.3731 52.041 44.3731 53.2132V64.9999C44.3731 66.1721 43.9075 67.2964 43.0786 68.1253C42.2496 68.9542 41.1254 69.4199 39.9531 69.4199ZM25.2198 51.7399C24.8291 51.7399 24.4543 51.8951 24.178 52.1714C23.9017 52.4477 23.7465 52.8225 23.7465 53.2132V64.9999C23.7465 65.3906 23.9017 65.7654 24.178 66.0417C24.4543 66.318 24.8291 66.4732 25.2198 66.4732H39.9531C40.3439 66.4732 40.7186 66.318 40.9949 66.0417C41.2712 65.7654 41.4265 65.3906 41.4265 64.9999V53.2132C41.4265 52.8225 41.2712 52.4477 40.9949 52.1714C40.7186 51.8951 40.3439 51.7399 39.9531 51.7399H25.2198Z" fill="#2692AA"/>
                                                <path d="M104.78 69.4199H90.047C88.8747 69.4199 87.7505 68.9542 86.9215 68.1253C86.0926 67.2964 85.627 66.1721 85.627 64.9999V53.2132C85.627 52.041 86.0926 50.9167 86.9215 50.0878C87.7505 49.2589 88.8747 48.7932 90.047 48.7932H104.78C105.953 48.7932 107.077 49.2589 107.906 50.0878C108.735 50.9167 109.2 52.041 109.2 53.2132V64.9999C109.2 66.1721 108.735 67.2964 107.906 68.1253C107.077 68.9542 105.953 69.4199 104.78 69.4199ZM90.047 51.7399C89.6562 51.7399 89.2815 51.8951 89.0051 52.1714C88.7288 52.4477 88.5736 52.8225 88.5736 53.2132V64.9999C88.5736 65.3906 88.7288 65.7654 89.0051 66.0417C89.2815 66.318 89.6562 66.4732 90.047 66.4732H104.78C105.171 66.4732 105.546 66.318 105.822 66.0417C106.098 65.7654 106.254 65.3906 106.254 64.9999V53.2132C106.254 52.8225 106.098 52.4477 105.822 52.1714C105.546 51.8951 105.171 51.7399 104.78 51.7399H90.047Z" fill="#2692AA"/>
                                                <path d="M104.78 109.2H90.047C88.8747 109.2 87.7505 108.734 86.9215 107.905C86.0926 107.076 85.627 105.952 85.627 104.78V92.9933C85.627 91.821 86.0926 90.6968 86.9215 89.8679C87.7505 89.039 88.8747 88.5733 90.047 88.5733H104.78C105.953 88.5733 107.077 89.039 107.906 89.8679C108.735 90.6968 109.2 91.821 109.2 92.9933V104.78C109.2 105.952 108.735 107.076 107.906 107.905C107.077 108.734 105.953 109.2 104.78 109.2ZM90.047 91.52C89.6562 91.52 89.2815 91.6752 89.0051 91.9515C88.7288 92.2278 88.5736 92.6026 88.5736 92.9933V104.78C88.5736 105.171 88.7288 105.545 89.0051 105.822C89.2815 106.098 89.6562 106.253 90.047 106.253H104.78C105.171 106.253 105.546 106.098 105.822 105.822C106.098 105.545 106.254 105.171 106.254 104.78V92.9933C106.254 92.6026 106.098 92.2278 105.822 91.9515C105.546 91.6752 105.171 91.52 104.78 91.52H90.047Z" fill="#2692AA"/>
                                                <path d="M72.3662 109.2H57.6329C56.4606 109.2 55.3364 108.734 54.5075 107.905C53.6786 107.076 53.2129 105.952 53.2129 104.78V92.9933C53.2129 91.821 53.6786 90.6968 54.5075 89.8679C55.3364 89.039 56.4606 88.5733 57.6329 88.5733H72.3662C73.5385 88.5733 74.6627 89.039 75.4916 89.8679C76.3205 90.6968 76.7862 91.821 76.7862 92.9933V104.78C76.7862 105.952 76.3205 107.076 75.4916 107.905C74.6627 108.734 73.5385 109.2 72.3662 109.2ZM57.6329 91.52C57.2421 91.52 56.8674 91.6752 56.5911 91.9515C56.3148 92.2278 56.1596 92.6026 56.1596 92.9933V104.78C56.1596 105.171 56.3148 105.545 56.5911 105.822C56.8674 106.098 57.2421 106.253 57.6329 106.253H72.3662C72.757 106.253 73.1317 106.098 73.408 105.822C73.6843 105.545 73.8396 105.171 73.8396 104.78V92.9933C73.8396 92.6026 73.6843 92.2278 73.408 91.9515C73.1317 91.6752 72.757 91.52 72.3662 91.52H57.6329Z" fill="#2692AA"/>
                                                <path d="M39.9531 109.2H25.2198C24.0475 109.2 22.9233 108.734 22.0944 107.905C21.2655 107.076 20.7998 105.952 20.7998 104.78V92.9933C20.7998 91.821 21.2655 90.6968 22.0944 89.8679C22.9233 89.039 24.0475 88.5733 25.2198 88.5733H39.9531C41.1254 88.5733 42.2496 89.039 43.0786 89.8679C43.9075 90.6968 44.3731 91.821 44.3731 92.9933V104.78C44.3731 105.952 43.9075 107.076 43.0786 107.905C42.2496 108.734 41.1254 109.2 39.9531 109.2ZM25.2198 91.52C24.8291 91.52 24.4543 91.6752 24.178 91.9515C23.9017 92.2278 23.7465 92.6026 23.7465 92.9933V104.78C23.7465 105.171 23.9017 105.545 24.178 105.822C24.4543 106.098 24.8291 106.253 25.2198 106.253H39.9531C40.3439 106.253 40.7186 106.098 40.9949 105.822C41.2712 105.545 41.4265 105.171 41.4265 104.78V92.9933C41.4265 92.6026 41.2712 92.2278 40.9949 91.9515C40.7186 91.6752 40.3439 91.52 39.9531 91.52H25.2198Z" fill="#2692AA"/>
                                                <path d="M65.0007 50.2668C64.6099 50.2668 64.2352 50.1116 63.9589 49.8353C63.6826 49.559 63.5273 49.1842 63.5273 48.7935V37.0068C63.5273 36.616 63.6826 36.2413 63.9589 35.965C64.2352 35.6887 64.6099 35.5334 65.0007 35.5334C65.3914 35.5334 65.7662 35.6887 66.0425 35.965C66.3188 36.2413 66.474 36.616 66.474 37.0068V48.7935C66.474 49.1842 66.3188 49.559 66.0425 49.8353C65.7662 50.1116 65.3914 50.2668 65.0007 50.2668Z" fill="#2692AA"/>
                                                <path d="M54.6868 60.5802H42.9001C42.5093 60.5802 42.1346 60.4249 41.8583 60.1486C41.582 59.8723 41.4268 59.4976 41.4268 59.1068C41.4268 58.7161 41.582 58.3413 41.8583 58.065C42.1346 57.7887 42.5093 57.6335 42.9001 57.6335H54.6868C55.0775 57.6335 55.4523 57.7887 55.7286 58.065C56.0049 58.3413 56.1601 58.7161 56.1601 59.1068C56.1601 59.4976 56.0049 59.8723 55.7286 60.1486C55.4523 60.4249 55.0775 60.5802 54.6868 60.5802Z" fill="#2692AA"/>
                                                <path d="M87.1008 60.5802H75.3142C74.9234 60.5802 74.5487 60.4249 74.2723 60.1486C73.996 59.8723 73.8408 59.4976 73.8408 59.1068C73.8408 58.7161 73.996 58.3413 74.2723 58.065C74.5487 57.7887 74.9234 57.6335 75.3142 57.6335H87.1008C87.4916 57.6335 87.8663 57.7887 88.1426 58.065C88.4189 58.3413 88.5742 58.7161 88.5742 59.1068C88.5742 59.4976 88.4189 59.8723 88.1426 60.1486C87.8663 60.4249 87.4916 60.5802 87.1008 60.5802Z" fill="#2692AA"/>
                                                <path d="M97.4133 91.5201C97.0225 91.5201 96.6478 91.3649 96.3715 91.0886C96.0952 90.8123 95.9399 90.4375 95.9399 90.0468V81.2068H34.0599V90.0468C34.0599 90.4375 33.9047 90.8123 33.6284 91.0886C33.3521 91.3649 32.9774 91.5201 32.5866 91.5201C32.1959 91.5201 31.8211 91.3649 31.5448 91.0886C31.2685 90.8123 31.1133 90.4375 31.1133 90.0468V79.7335C31.1133 79.3427 31.2685 78.968 31.5448 78.6917C31.8211 78.4154 32.1959 78.2601 32.5866 78.2601H97.4133C97.804 78.2601 98.1788 78.4154 98.4551 78.6917C98.7314 78.968 98.8866 79.3427 98.8866 79.7335V90.0468C98.8866 90.4375 98.7314 90.8123 98.4551 91.0886C98.1788 91.3649 97.804 91.5201 97.4133 91.5201Z" fill="#2692AA"/>
                                                <path d="M65.0007 91.5199C64.6099 91.5199 64.2352 91.3647 63.9589 91.0884C63.6826 90.8121 63.5273 90.4373 63.5273 90.0466V69.4199C63.5273 69.0292 63.6826 68.6544 63.9589 68.3781C64.2352 68.1018 64.6099 67.9466 65.0007 67.9466C65.3914 67.9466 65.7662 68.1018 66.0425 68.3781C66.3188 68.6544 66.474 69.0292 66.474 69.4199V90.0466C66.474 90.4373 66.3188 90.8121 66.0425 91.0884C65.7662 91.3647 65.3914 91.5199 65.0007 91.5199Z" fill="#2692AA"/>
                                                <path d="M71 94.0002V103H59" stroke="#FFCC4A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M39 94.0002V103H27" stroke="#FFCC4A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M39 54.0002V64.0002H26" stroke="#FFCC4A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M104 54.0002V64.0002H91" stroke="#FFCC4A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                <path d="M103 94.0002V103H91" stroke="#FFCC4A" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                                </svg>
											</div>
											<div className="head-cap">
												<p>Architecture Design</p>
											</div>
										</li>
										<li>
											<div className="head-img ylow-bx">
                                               <svg width="130" height="130" viewBox="0 0 130 130" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M85 57V85H57" stroke="#FFD028" stroke-width="5" stroke-linecap="round"/>
                                                <path d="M104 47.9329H99.1346V30.8657H82.0674V26H104V47.9329Z" fill="#2692AA"/>
                                                <path d="M67.976 58.5867L99.8635 26.7141L103.316 30.1662L71.443 62.024L67.976 58.5867ZM47.9329 104H26V82.0671H30.8657V99.1343H47.9329V104Z" fill="#2692AA"/>
                                                <path d="M26.6992 99.8337L58.557 67.9759L62.024 71.4281L30.1513 103.286L26.6992 99.8337ZM40.6267 52.8134H35.7462V35.7462H52.8133V40.6268H40.6267V52.8134ZM35.7462 57.6791H40.6267V72.3209H35.7462V57.6791ZM57.679 35.7462H72.3208V40.6268H57.679V35.7462ZM94.2537 94.2537H77.1865V89.3732H89.3731V77.1866H94.2537V94.2537ZM89.3731 57.6791H94.2537V72.3209H89.3731V57.6791ZM57.679 89.3732H72.3208V94.2537H57.679V89.3732Z" fill="#2692AA"/>
                                                </svg>
											</div>
											<div className="head-cap">
												<p>Scalable Implementation</p>
											</div>
										</li>
									</ul>
								</div>

							</div>
						</div>	
		        	</div>
		        </div>


                <div className="company-info">
                    <div className="section-2 beige-bg">
                        <div className="custom-container">
                            <div className="custom-section">
                                <div className="row work-with-row">
                                    <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 work-with-col">
                                    <div className="left-info-hold">
                                        <div className="main-head-div custom-main-head-div">
                                            <h4 className="main-head">
                                                <span  className="primary-span">Stability in</span>
                                                <span  className="secondary-span">Technology</span>
                                            </h4>
                                        </div>
                                        <div className="main-para-div">
                                            <p>As an enterprise you need to ensure the technology you choose to work with has been tried, tested and proven - there is no room for error with your established customer base.</p>
                                            <p>We know that safety, security and sustainability is a priority. We build on technologies and architectures that can support the magnitude of your business.</p>
                                        </div>
                                        <div className="more-text">
                                        <Link className="nav-link" to={ROUTES.TECHNOLOGY}>Understand our approach to technology</Link></div>
                                    </div>
                                    </div>
                                    <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 comp-info-right-col">
                                    <div className="right-img-hold">
                                        <div className="comp-info-right-img">
                                            <span class="company-offer-imgs">
                                            <svg width="501" height="481" viewBox="0 0 501 481" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M225.688 117.281C224.601 117.297 223.536 117.577 222.583 118.095C221.63 118.614 220.82 119.356 220.222 120.257C208.091 137.851 169.638 152.809 125.644 153.068C125.558 153.066 125.471 153.066 125.384 153.068C125.098 153.019 124.809 152.988 124.519 152.977C122.981 152.97 121.489 153.492 120.294 154.454C119.099 155.415 118.276 156.757 117.964 158.253C117.964 158.253 111.314 188.156 107.983 224.325C104.652 260.495 103.966 302.851 118.586 330.197C129.539 350.684 155.453 367.149 178.588 379.683C199.986 391.275 217.634 398.291 220.411 399.384C221.668 400.353 223.232 400.844 224.822 400.768C226.433 400.847 228.019 400.343 229.284 399.349C229.293 399.349 229.302 399.349 229.311 399.349C232.202 398.213 249.762 391.251 271.082 379.701C294.216 367.167 320.096 350.701 331.049 330.215C345.67 302.868 344.983 260.513 341.652 224.343C338.321 188.174 331.672 158.27 331.672 158.27C331.354 156.745 330.505 155.381 329.275 154.416C328.044 153.451 326.51 152.948 324.943 152.995C324.759 152.987 324.574 152.987 324.389 152.995C282.118 152.827 244.982 138.662 232.026 121.567C231.551 120.28 230.685 119.17 229.548 118.393C228.411 117.615 227.059 117.207 225.678 117.225L225.688 117.281ZM225.566 134.526C244.145 154.123 279.796 165.142 319.608 166.11C320.942 172.412 325.574 194.956 328.394 225.575C331.64 260.809 330.963 302.174 319.279 324.027C311.263 339.02 286.986 356.02 264.691 368.098C244.508 379.033 228.107 385.599 224.823 386.9C221.538 385.598 205.137 379.033 184.954 368.098C162.659 356.02 138.382 339.02 130.366 324.027C118.683 302.174 118.006 260.809 121.251 225.575C124.069 194.971 128.7 172.446 130.037 166.126C170.54 165.162 206.737 154.15 225.566 134.526Z" fill="#BCC4C7"/>
                                                <path d="M199.187 192.649C195.517 192.649 192.542 195.624 192.542 199.294V226.795C183.363 226.795 174.185 226.795 165.007 226.795C161.337 226.795 158.362 229.771 158.361 233.441V318.813C158.362 322.484 161.337 325.459 165.007 325.459H284.63C288.3 325.459 291.275 322.484 291.276 318.813V233.441C291.275 229.771 288.3 226.795 284.63 226.795H257.182V199.381V199.275V199.186C257.182 199.027 257.176 198.858 257.164 198.699C257.159 198.61 257.155 198.584 257.146 198.53C257.12 198.371 257.093 198.203 257.058 198.043C256.978 197.627 256.866 197.21 256.711 196.814H256.693H256.676C256.517 196.415 256.319 196.034 256.087 195.672H256.07C256.034 195.583 255.999 195.565 255.966 195.512C255.872 195.379 255.775 195.255 255.672 195.131C255.636 195.043 255.592 195.043 255.55 194.99C255.445 194.866 255.335 194.759 255.222 194.644C255.067 194.485 254.906 194.334 254.737 194.192C254.581 194.068 254.419 193.953 254.252 193.847L254.199 193.758H254.164C254.023 193.669 253.878 193.59 253.731 193.519L253.628 193.43C253.574 193.43 253.512 193.341 253.455 193.341C253.313 193.253 253.169 193.208 253.022 193.146C252.969 193.146 252.907 193.058 252.85 193.058C252.702 192.969 252.552 192.952 252.399 192.898H252.346C252.169 192.81 251.99 192.81 251.81 192.774H251.739H251.722C251.521 192.774 251.319 192.686 251.115 192.686H251.08H251.062C250.855 192.686 250.647 192.686 250.439 192.686L199.187 192.649ZM205.833 205.94H243.89V226.795H205.833V205.94ZM171.653 240.086C207.097 240.086 242.54 240.086 277.984 240.086V312.168H171.653V240.086Z" fill="#BCC4C7"/>
                                                <path d="M170 151.5V96.5C169.667 79.3333 176.7 40.4 207.5 22" stroke="#BCC4C7" stroke-width="10"/>
                                                <path d="M116 258.989L75.6525 258.989C63.0592 259.326 34.4981 252.201 21 221" stroke="#BCC4C7" stroke-width="10"/>
                                                <path d="M297.011 354L297.011 385.853C296.674 395.795 303.799 418.344 335 429" stroke="#BCC4C7" stroke-width="10"/>
                                                <path d="M287.5 155V102.5" stroke="#BCC4C7" stroke-width="10"/>
                                                <path d="M147 403V350" stroke="#BCC4C7" stroke-width="10"/>
                                                <path d="M190 453L190 377" stroke="#BCC4C7" stroke-width="10"/>
                                                <path d="M334.5 236.5H475.5" stroke="#BCC4C7" stroke-width="10"/>
                                                <path d="M335 276H420" stroke="#BCC4C7" stroke-width="10"/>
                                                <circle cx="216" cy="16" r="12" fill="#FFCC4A" stroke="#BCC4C7" stroke-width="8"/>
                                                <circle cx="288" cy="90" r="12" fill="#FFCC4A" stroke="#BCC4C7" stroke-width="8"/>
                                                <circle cx="485" cy="237" r="12" fill="#FFCC4A" stroke="#BCC4C7" stroke-width="8"/>
                                                <circle cx="429" cy="276" r="12" fill="#FFCC4A" stroke="#BCC4C7" stroke-width="8"/>
                                                <circle cx="344" cy="433" r="12" fill="#FFCC4A" stroke="#BCC4C7" stroke-width="8"/>
                                                <circle cx="190" cy="465" r="12" fill="#FFCC4A" stroke="#BCC4C7" stroke-width="8"/>
                                                <circle cx="16" cy="214" r="12" fill="#FFCC4A" stroke="#BCC4C7" stroke-width="8"/>
                                                <circle cx="147" cy="412" r="12" fill="#FFCC4A" stroke="#BCC4C7" stroke-width="8"/>
                                                </svg>
                                            </span>
                                        </div>
                                    </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


                
                <div className="section-2 marketplace blue-bg">
                    <div className="custom-container">
                        <div className="custom-section">
                            <div className="main-head-div">
                                <h4 className="main-head-1">
                                    <span className="secondary-span">Enterprise</span>
                                    <span className="primary-span">Portfolio</span>
                                </h4>
                            </div>
                        </div>
                    </div>
                         <div class="tab-card">
                                <div class="row">
                                <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                                        <div class="port-card">
                                            <div class="whole-port">
                                                    <div class="card-img">
                                                        <img src="/assets/img/tenshey.png" alt="Tenshey" />
                                                    </div>
                                                    <div class="card-head">
                                                        <h4>Tenshey</h4>
                                                    </div>
                                                    <div class="card-cap">
                                                        <p>Executive Coaching Platform for Leadership Training</p>
                                                    </div>
                                                    <div class="more-text">
                                                    <a href="">Learn More</a>
                                                    </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                                        <div class="port-card">
                                            <div class="whole-port">
                                                    <div class="card-img">
                                                      <img src="/assets/img/gaadibazaar.png" alt="Gaadi Bazaar"/>
                                                    </div>
                                                    <div class="card-head">
                                                        <h4>Gaadi Bazaar</h4>
                                                    </div>
                                                    <div class="card-cap">
                                                        <p>Trusted Online Vehicle Trading Platform</p>
                                                    </div>
                                                    <div class="more-text">
                                                    <a href="">Learn More</a>
                                                    </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-xl-4 col-lg-4 col-md-4 col-sm-12">
                                        <div class="port-card">
                                            <div class="whole-port">
                                                    <div class="card-img">
                                                     <img src="/assets/img/p2e.png" alt="P2E"/>
                                                    </div>
                                                    <div class="card-head">
                                                        <h4>P2E</h4>
                                                    </div>
                                                    <div class="card-cap">
                                                        <p>State-of-the-art regulatory change management system</p>
                                                    </div>
                                                    <div class="more-text">
                                                    <a href="">Learn More</a>
                                                    </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                    <div className="custom-container">
                        <div className="port-more">
                             <div class="more-text"><a>View our complete portfolio</a></div>
                        </div>
                    </div>
                </div> 

                <div className="section-5 beige-bg">
                    <div className="custom-container">
                        <div className="custom-section connect-with-custom-section">
                            <div className="main-head-div">
                                <h4 className="main-head-1">
                                    <span  className="secondary-span">We understand picking a technology partner</span>
                                    <span  className="primary-span">is one of the toughest decisions to make</span>
                                </h4>
                            </div>
                            <div className="explore-hold">
                           <p>Let's have a chat and figure how to build your product together.</p>
                            </div>
                            {/* <div className="main-para-div">
                                <button onClick={this.sendToast.bind(this)} className="btn btn-primary connect-btn" type=""><span>Connect with us</span></button>
                            </div> */}
                             <div className="main-para-div">
                            <Link to={ROUTES.CONTACTUS} className="btn btn-primary connect-btn">Talk to us</Link>
                            </div>
                        </div>
                    </div>
                </div>
            <ContactUS show={this.state.showContactUS} handleClose={()=>this.setState({showContactUS:false})}/>
        </div>
    );
}

}


const mapStateToProps = (state, ownProps) => {
    return state.commonReducer;
  };
  
  const mapDispatchToProps = (dispatch, ownProps) => {
    return bindActionCreators(contactAction, dispatch);
  };
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(Enterprise);
