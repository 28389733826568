import React from 'react';
import "../../resources.css";
import { ResourceRoute } from '../../resource-routing';
import { Link } from "react-router-dom";
import NewsHeadings from "./common-action/news-heading";
import NewsSubscription from "./common-action/subscripbe-section";

class NewsIssue78 extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            pageEnabled: false,
        }

    }

    render() {
        return (
          <div className="newdesigned_issue_details">
            <NewsHeadings
              buttondDetail="Web3 & Blockchain"
              categoryType="web3"
              description="Why is Mobile App Security “Shifting Left?”| DevproJournal.com"
            />

            <div className="newsissuedetailed">
              <div className="detailed-common">
                {/* <div className="back-to-common">
                    <span className="back-to-icon-hold">
                        <img src="/assets/img/resource/back-to-new.svg"  alt="back to new"></img>
                    </span>
                    <Link  to={ResourceRoute.NEWSLETTER}><span className="back-to-link">Newsletter</span></Link>
                </div> */}

                <div className="news-letter whats-on-hold">
                  <div className="issue-main-head">
                    <h4>What’s on?</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.google.com/url?q=https://www.devprojournal.com/technology-trends/security/why-is-mobile-app-security-shifting-left/&source=gmail&ust=1622279851594000&usg=AFQjCNGKOJ6nB2bMfGZaxc6hUt1bdSMPWA"
                      >
                        <h4>
                          Why is Mobile App Security “Shifting Left?”|
                          DevproJournal.com
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        As COVID continues to ravage the globe, companies across
                        industries have pivoted to moving brands and employees
                        online and onto mobile devices through mobile
                        applications. Since many of these companies have chosen
                        to prioritize the user experience and time-to-market for
                        a variety of reasons, mobile application security has
                        fallen behind.
                      </p>
                    </div>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.cnbc.com/2021/05/19/the-crypto-collapse-heres-whats-behind-bitcoins-sudden-drop.html"
                      >
                        <h4>
                          The crypto collapse: Here’s what’s behind bitcoin’s
                          sudden drop | CNBC.com
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        The dramatic pullback in bitcoin and other
                        cryptocurrencies comes as a flurry of negative headlines
                        and catalysts, from Tesla CEO Elon Musk to a new round
                        of regulations by the Chinese government, have hit an
                        asset sector that has been characterized by extreme
                        volatility since it was created.The flagship
                        cryptocurrency fell to more than three-month lows on
                        Wednesday, dropping to about $30,000 at one point for a
                        pullback of more than 30% and continuing a week of
                        selling in the crypto space
                      </p>
                    </div>
                  </div>
                </div>
                <div className="news-letter top-picks-hold">
                  <div className="issue-main-head">
                    <h4>Top Picks</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://techcrunch.com/2021/05/14/solana-a-blockchain-platform-followed-by-top-crypto-investors-says-its-a-lot-faster-than-ethereum/"
                      >
                        <h4>
                          Solana, a blockchain platform followed by top crypto
                          investors, says its faster than Ethereum | Tech Crunch
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Solana isn’t widely known yet outside of the crypto
                        community. But insiders think the blockchain platform is
                        interesting for a wide variety of reasons, beginning
                        with its amiable founder, Anatoly Yakovenko, who spent
                        more than a dozen years as an engineer working on
                        wireless protocols at Qualcomm and who says he had a
                        lightbulb moment at a San Francisco cafe several years
                        ago following two coffees and a beer. His big idea
                        centered on creating an historical record to speed along
                        “consensus,” which is how decisions are made on
                        blockchains, which are themselves peer-to-peer systems.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="news-letter other-reads-hold">
                  <div className="issue-main-head">
                    <h4>Other Reads</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://fortune.com/2021/05/21/china-ban-bitcoin-price-bubble-crypto/"
                      >
                        <h4>Why China cracked down on Bitcoin | Fortune</h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        China just cracked down on privately mined
                        cryptocurrency and we saw the market for Bitcoin fall
                        down a cliff. In an international context of economic
                        instability due to the continuing coronavirus pandemic,
                        skyrocketing commodity prices, and geopolitical
                        tensions, the People’s Bank of China has emphasized its
                        orientation toward financial, employment, trade, and
                        investment stability.{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <NewsSubscription categoryType="Web3" />
          </div>
        );
    }

}

export default NewsIssue78;
