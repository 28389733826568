import React from 'react';
import "../../resources.css";
import {ResourceRoute} from '../../resource-routing';
import {Link} from "react-router-dom";
import NewsHeadings from "./common-action/news-heading";
import NewsSubscription from "./common-action/subscripbe-section";

class NewsIssue56 extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            pageEnabled: false,
        }

    }

    render() {
        return (
          <div className="newdesigned_issue_details">
            <NewsHeadings
              buttondDetail="Web3 & Blockchain"
              categoryType="web3"
              description="IoT goes: 5G, whiz | 2020-11-11 | DC Velocity"
            />
            <div className="newsissuedetailed">
              <div className="detailed-common">
                {/* <div className="back-to-common">
                    <span className="back-to-icon-hold">
                        <img src="/assets/img/resource/back-to-new.svg"  alt="back to new"></img>
                    </span>
                    <Link  to={ResourceRoute.NEWSLETTER}><span className="back-to-link">Newsletter</span></Link>
                </div> */}

                <div className="news-letter whats-on-hold">
                  <div className="issue-main-head">
                    <h4>What’s on?</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://techcrunch.com/2020/12/06/singapores-government-launches-blockchain-innovation-program-with-8-9-million-in-funding/?guccounter=1"
                      >
                        <h4>
                          Singapore’s government launches blockchain innovation
                          program with $8.9 million in funding | TechCrunch{" "}
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        A group of Singaporean government agencies is launching
                        a new research program for blockchain technology with
                        $12 million SGD (about $8.9 million USD) in funding.
                        Called the Singapore Blockchain Innovation Programme
                        (SBIP), the project is a collaboration between
                        Enterprise Singapore, Infocomm Media Development
                        Authority and the National Research Foundation
                        Singapore. It has support from the Monetary Authority of
                        Singapore, the country’s central bank and financial
                        regulator.
                      </p>
                    </div>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://qz.com/1941560/steve-wozniak-co-founds-a-cryptocurrency-energy-efficiency-startup/"
                      >
                        <h4>
                          Steve Wozniak co-founds a cryptocurrency energy
                          efficiency startup | Newyork Post
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Decentralized exchange protocols that allow crypto
                        traders and investors to trade across different
                        blockсhains have been in development for a while. A
                        significant new development now comes with the launch of
                        the “Testnet” from Injective Protocol. Injective has
                        been backed by Binance, one of the biggest centralized
                        exchanges in the crypto world. Injective Protocol is one
                        of the first universal “DeFi” (Decentralised Finance)
                        protocols for cross-chain derivatives trading, so the
                        launch of the Testnet is an important milestone.
                        Injective’s main competitors (centralized and
                        decentralized exchanges) include CME Group, BitMEX,
                        LedgerX and OKEx, among others.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="news-letter top-picks-hold">
                  <div className="issue-main-head">
                    <h4>Top Picks</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.dcvelocity.com/articles/47951-iot-goes-5g-whiz"
                      >
                        <h4>IoT goes: 5G, whiz | 2020-11-11 | DC Velocity </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        The IoT (internet of things) is a fairly new item in the
                        logistics toolbox, but technology experts across the
                        industry say it’s about to get a major upgrade, thanks
                        to the national rollout of fifth-generation (5G)
                        wireless networks. Supply chains have relied on IoT
                        technology for several years, using its collection of
                        distributed sensors to monitor variables like open dock
                        doors at a DC, the speed of conveyor belts in a
                        fulfillment center, and the location of trailers in a
                        yard. Those applications have proved the technology’s
                        business case, demonstrating the IoT’s value in
                        connecting physical supply chain assets to the digital
                        web.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="news-letter other-reads-hold">
                  <div className="issue-main-head">
                    <h4>Other Reads</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.vox.com/recode/22166437/facebook-instagram-ftc-attorneys-general-antitrust-monopoly-whatsapp"
                      >
                        <h4>
                          Why the US government wants Facebook to sell off
                          Instagram and WhatsApp | Recode - Vox{" "}
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        On Wednesday, the US Federal Trade Commission and 48 US
                        state attorneys general filed major lawsuits against
                        Facebook that argue the social media giant is a monopoly
                        whose anti-competitive practices harm Americans. The two
                        lawsuits, which follow more than a year of
                        investigations, are the biggest antitrust challenge
                        Facebook has faced. They both essentially call for
                        Facebook to be broken up by forcing it to undo its
                        acquisitions of Instagram and WhatsApp, which together
                        have billions of users. The lawsuits allege that such
                        action may be necessary because Facebook has crushed its
                        competitors and achieved dominance by buying potential
                        rivals, and that this limits American consumers’ choices
                        and reduces their access to privacy protections.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <NewsSubscription categoryType="Web3" />
          </div>
        );
    }

}

export default NewsIssue56;
