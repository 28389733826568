import React from 'react';
import {Link} from "react-router-dom";
import { ROUTES } from '../../../../routes';
import {PortfolioRoute} from "../../../portfolio/route-porfolio";

class CaseStudyTenshey extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            downloadLink: '/assets/case-studies/Tenshey-case-study.pdf'
        }
    }

    

    render() {
        return (
            <>

<div class="Resources">
                <section class="section-2 ppr-dnld-custom-sec beige-bg">
                    <div class="custom-container">
                        <div class="tab-content">


            <div className="newsissuedetailed">
            <div className="detailed-common">
                <div className="back-to-common">
                    <span className="back-to-icon-hold">
                        <img src="/assets/img/resource/back-to-new.svg"  alt="bact to new"></img>
                    </span>
                    <Link to={ROUTES.PORTFOLIO}><span className="back-to-link">Portfolio</span></Link>
                </div>

                <div className="whats-on-hold">
                    <div className="ebook-sec">
						<div className="row">
							<div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 resp-apprch-col">
								<div className="custom-section work-with-custom-section">
                                    <div class="main-head-div">
                                        <h1 class="main-head-1">
                                            <span class="secondary-span">Customized leadership Training Solution for Women Workforce: Tenshey</span>
                                        </h1>
                                    </div>
                                    <div className="main-para-div">
                                        <p>Tenshey is a start-up dedicated to advancing gender diversity through executive coaching, one female leader at a time. Tenshey provides customized executive coaching programs, driven by proprietary algorithm systems, to empower budding female leaders.</p>
                                        <p>At the point of time Ionixx was approached by Tenshey for its business requirement, Tenshey was undergoing a major ramp up of its operations. Considering this, Ionixx had to pitch in extensively right from the stage of gathering requirements in order to build a seamless <Link to={PortfolioRoute.Tenshey}>executive coaching platform </Link> that was characterized by a unique coach-coachee mapping system.</p>
                                            {/* <a class="d-hide m-show" href="">View our software portfolio</a> */}
                                    </div>
								</div>
							</div>
							<div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 resp-apprch-col">
								<div class="ebook-form-sec">
                                    <div class="modal-title">
                                    <h4>Click download to get PDF</h4>
                                    </div>
                                    <a href={this.state.downloadLink} target="_blank">
                                            <button class="download-case-study contact-submit btn btn-primary">
                                                Download
                                            </button>
                                        </a>
                                </div>
							</div>
						</div>	
		            </div>
                </div>

            </div>
        </div> 


        </div>
        </div>
        </section>
        </div>

            </>
        );
    }

}

export default CaseStudyTenshey;
