import React from 'react';
import "../../resources.css";
import { ResourceRoute } from '../../resource-routing';
import { Link } from "react-router-dom";
import NewsHeadings from "./common-action/news-heading";
import NewsSubscription from "./common-action/subscripbe-section";

class NewsIssue13 extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            pageEnabled: false,
        }

    }

    render() {
        return (
          <div className="newdesigned_issue_details">
            <NewsHeadings
              buttondDetail="FinTech"
              categoryType="fintech"
              description="The Scroll subscription service is an ingenious web technology hack | The Next Web"
            />

            <div className="newsissuedetailed">
              <div className="detailed-common">
                {/* <div className="back-to-common">
                    <span className="back-to-icon-hold">
                    <img src="/assets/img/resource/back-to-new.svg"  alt="bact to new"></img>
                    </span>
                    <Link  to={ResourceRoute.NEWSLETTER}><span className="back-to-link">Newsletter</span></Link>
                </div> */}

                <div className="news-letter whats-on-hold">
                  <div className="issue-main-head">
                    <h4>What’s on?</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.nature.com/articles/d41586-020-00288-x"
                      >
                        <h4>India bets big on Quantum Technology | Nature</h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Quantum technology has been given a massive boost in
                        India’s latest budget, receiving 80 billion rupees
                        (US$1.12 billion) over five years as part of a new
                        national quantum mission. India’s considerable
                        investment in the field places it alongside the United
                        States, Europe and Russia. In December 2018, US
                        President Donald Trump signed a bill to invest US$1.2
                        billion over five years in a national quantum
                        initiative, and in 2016, Europe pledged US$1.13 billion
                        for quantum technologies. Russia is also spending the
                        equivalent of hundreds of millions of dollars on quantum
                        technologies.{" "}
                      </p>
                    </div>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.nasdaq.com/articles/already-in-2020-ibm-announces-wealth-of-blockchain-products-targeted-at-many-industries"
                      >
                        <h4>
                          Already in 2020, IBM Announces Wealth of Blockchain
                          Products Targeted at Many Industries | NASDAQ
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        In the first days of February 2020, information
                        technology giant IBM (IBM) announced a wide array of new
                        blockchain initiatives that it is planning as part of a
                        wider effort to develop sophisticated distributed ledger
                        technology. IBM has come out swinging, demonstrating
                        dominance in the world of creating and implementing
                        novel use cases for distributed ledger technology.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="news-letter top-picks-hold">
                  <div className="issue-main-head">
                    <h4>Top Picks</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.theverge.com/2020/1/29/21113003/scroll-subscription-service-publisher-web-cookie-hack"
                      >
                        <h4>
                          The Scroll subscription service is an ingenious web
                          technology hack | The Next Web
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Scroll, a new $5 per month subscription service that
                        gives you a bunch of websites without ads. I kept on
                        experiencing successive waves of small revelations when
                        I thought about it. I’ll disclose now that Vox Media
                        (and therefore The Verge) are partners, but I had no
                        idea this service existed until it was announced
                        yesterday.
                      </p>
                    </div>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.vice.com/en_us/article/3a8k93/the-cias-infamous-unsolved-cryptographic-puzzle-gets-a-final-clue"
                      >
                        <h4>
                          The CIA’s Infamous, Unsolved Cryptographic Puzzle Gets
                          a ‘Final Clue’ | Vice.com
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Almost exactly 30 years ago, the artist and sculptor Jim
                        Sanborn was devising an encrypted code for his sculpture
                        complex at the new CIA headquarters. The centerpiece of
                        the complex, called Kryptos, is an eight-foot-tall
                        sculpture of a copper scroll, with four paragraphs of
                        letters cut from the metal. At first glance, the letters
                        seem to be gibberish. But cryptologists, including NSA
                        experts and the American scientist James Gillogly,
                        gradually decrypted the first three paragraphs of the
                        text. But the full solution has eluded cryptographers,
                        and the 74-year-old Sanborn has just released a new clue
                        in order to help hobbyists solve it.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="news-letter other-reads-hold">
                  <div className="issue-main-head">
                    <h4>Other Reads</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.theguardian.com/commentisfree/2020/feb/07/loneliness-social-connect-local-communities"
                      >
                        <h4>
                          Loneliness is a national crisis. But there is a way to
                          tackle it | The Guardian
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        A desire for social connection is fundamentally
                        hardwired into our psychology, and so being deprived of
                        it has devastating mental and physical consequences. Yet
                        we live in a society which has become ever more
                        fragmented and atomised. From pubs to factories, the
                        spaces where we socially connect are in decline.
                        Thankfully there’s a group bringing local people
                        together. Click on the link to read more.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <NewsSubscription categoryType="FinTech" />
          </div>
        );
    }

}

export default NewsIssue13;
