import React from 'react';
import "../../resources.css";
import { ResourceRoute } from '../../resource-routing';
import { Link } from "react-router-dom";
import NewsHeadings from "./common-action/news-heading";
import NewsSubscription from "./common-action/subscripbe-section";

class NewsIssue52 extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            pageEnabled: false,
        }

    }

    render() {
        return (
          <div className="newdesigned_issue_details">
            <NewsHeadings
              buttondDetail="FinTech"
              categoryType="fintech"
              description="Banking, FinTechAnd The Second Pandemic Wave: What's Next For 2020? | Forbes"
            />
            <div className="newsissuedetailed">
              <div className="detailed-common">
                {/* <div className="back-to-common">
                            <span className="back-to-icon-hold">
                                <img src="/assets/img/resource/back-to-new.svg" alt="back to new"></img>
                            </span>
                            <Link to={ResourceRoute.NEWSLETTER}><span className="back-to-link">Newsletter</span></Link>
                        </div> */}

                <div className="news-letter whats-on-hold">
                  <div className="issue-main-head">
                    <h4>What’s on?</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://blockchaintechnology-news.com/2020/11/verizon-to-use-blockchain-in-its-newsroom-for-comms-verification/"
                      >
                        <h4>
                          Verizon to use blockchain in its newsroom for comms
                          verification | blockchaintechnology-news.com
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Verizon has announced the launch of a blockchain-based
                        product which aims to provide an authoritative record of
                        changes to company news releases.Full Transparency by
                        Verizon is a proof of concept built with open source
                        blockchain technology. The product is put together in
                        partnership with AdLedger, a consortium which explores
                        standards for blockchain and cryptography in media and
                        advertising, authentication infrastructure provider
                        MadNetwork, and marketing company Huge.
                      </p>
                    </div>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://aithority.com/it-and-devops/cloud/wallarm-launches-a-first-in-its-class-cloud-web-application-and-api-protection-waap-with-full-support-of-api-technologies/"
                      >
                        <h4>
                          Wallarm Launches A First-In-Its-Class Cloud Web
                          Application And API Protection (WAAP) With Full
                          Support Of API Technologies | AIThority.com
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Wallarm launches a first-in-its-class Cloud Web
                        Application and API Protection (WAAP) with full support
                        of API technologies including REST, SOAP, WebSocket,
                        graphQL and gRPC. Most CDN vendors have basic WAF
                        capabilities and lack API protection, which makes a
                        whole set of assets unprotected. A recent report
                        published by Gartner found that, “by 2023, over 50% of
                        B2B transactions will be performed through real-time
                        APIs versus traditional approaches.” APIs are the new
                        apps and a new target for attackers.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="news-letter top-picks-hold">
                  <div className="issue-main-head">
                    <h4>Top Picks</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.forbes.com/sites/forbesfinancecouncil/2020/11/03/banking-fintech-and-the-second-pandemic-wave-whats-next-for-2020/?sh=12e0b3536ad3"
                      >
                        <h4>
                          Banking, FinTechAnd The Second Pandemic Wave: What's
                          Next For 2020? | Forbes{" "}
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Now, with Covid-19 casualties having surpassed the one
                        million mark worldwide, it's clear that the crisis is
                        far from over. Conversely, the second wave is just
                        beginning. So, what does this mean for businesses moving
                        forward, and in particular, the banking and fintech
                        sectors? What measures can be taken to protect
                        businesses in the future?
                      </p>
                    </div>
                  </div>
                </div>
                <div className="news-letter other-reads-hold">
                  <div className="issue-main-head">
                    <h4>Other Reads</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.technologyreview.com/2020/11/03/1011616/ai-godfather-geoffrey-hinton-deep-learning-will-do-everything/"
                      >
                        <h4>
                          Now, with Covid-19 casualties having surpassed the one
                          million mark worldwide, it's clear that the crisis is
                          far from over. Conversely, the second wave is just
                          beginning. So, what does this mean for businesses
                          moving forward, and in particular, the banking and
                          FinTechsectors? What measures can be taken to protect
                          businesses in the future?
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Now, with Covid-19 casualties having surpassed the one
                        million mark worldwide, it's clear that the crisis is
                        far from over. Conversely, the second wave is just
                        beginning. So, what does this mean for businesses moving
                        forward, and in particular, the banking and fintech
                        sectors? What measures can be taken to protect
                        businesses in the future?
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <NewsSubscription categoryType="FinTech" />
          </div>
        );
    }

}

export default NewsIssue52;
