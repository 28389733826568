import React from "react";
import "../../resources.css";
import { ResourceRoute } from "../../resource-routing";
import { Link } from "react-router-dom";
import NewsHeadings from "./common-action/news-heading";
import NewsSubscription from "./common-action/subscripbe-section";
import ReactSEOMetaTags from "react-seo-meta-tags";
import Helmet from "react-helmet";
import { Button, Collapse, Modal, Form } from "react-bootstrap";

class NewsIssue83 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pageEnabled: false,
      modalShow: false,
      letftStickyContnet: "FinTechWebinar by Shaan",
    };
  }
  handleClose() {
    this.setState({ modalShow: false });
  }
  handleShow() {
    this.setState({ modalShow: true });
  }

  render() {
    return (
      <>
        <Helmet>
          <title>Newsletter | Ionixx Technologies</title>
          <meta
            name="description"
            content="Ix Digest is a weekly tech newsletter featuring technology,blockchain news, and other interesting tech insights."
          />
          <link
            rel="canonical"
            href="https://www.ionixxtech.com/resources/newsletter"
          />
        </Helmet>
        <ReactSEOMetaTags
          render={(el) => <Helmet>{el}</Helmet>}
          website={{
            name: "Ionixx Technologies",
            title: "Newsletter | Ionixx Technologies",
            description:
              "Ix Digest is a weekly tech newsletter featuring technology,blockchain news, and other interesting tech insights.",
            type: "website",
            image: "/assets/img/SEO_OG_IMAGE/newsletter.png",
            url: "https://www.ionixxtech.com/resources/newsletter",
          }}
          twitter={{
            twitter: "https://www.ionixxtech.com/resources/newsletter",
          }}
        />
        <div className="newdesigned_issue_details issue_83_design">
          <NewsHeadings
            buttondDetail="Web3"
            categoryType="web3"
            description="Can the current US banking crisis drive the demand and adoption of cryptocurrencies?"
            newDetail={true}
          />

          {/* <div className='sticky-button banner-modal-button'>
                    <button onClick={() => this.handleShow()}>FinTechWebinar with Mr.Shaan Ray</button>
                 </div> */}
          {/*modal content */}
          <Modal
            className="newsletter-modal theme-change height-changes"
            centered
            show={this.state.modalShow}
            onHide={() => this.handleClose()}
          >
            <Modal.Header closeButton>
              <div className="banner-section">
                <div className="banner-left">
                  <img
                    src="/assets/newsletter_images/news-feed.jpg"
                    alt="defi"
                  />
                </div>
                <div className="banner-right">
                  <span>Webinar | 12 June 2023</span>
                  <p>
                    In the realm of Internet of Things (IoT) devices, security
                    is a critical concern and poses significant risks of device
                    hacking. In this unregulated landscape, leveraging
                    blockchain-led digital ID holds promise as a potential
                    solution.
                  </p>
                  <p>
                    Join Shaan Ray, Head of Web3 Partnerships at Ionixx, as he
                    explores the paradigm shift that awaits at the intersection
                    of blockchain and IoT devices.
                  </p>
                  <a
                    className="read-more"
                    href="https://bit.ly/44INlXa"
                    target="_blank"
                  >
                    Register Now
                  </a>
                </div>
              </div>
            </Modal.Header>
          </Modal>
          <div className="newsissuedetailed">
            <div className="detailed-common">
              {/* <div className="back-to-common">
                            <span className="back-to-icon-hold">
                                <img src="/assets/img/resource/back-to-new.svg" alt="back to new"></img>
                            </span>
                            <Link to={ResourceRoute.NEWSLETTER}><span className="back-to-link">Newsletter</span></Link>
                        </div> */}

              <div className="headlines-warp">
                <div className="top-news">
                  <i>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="234"
                      height="140"
                      viewBox="0 0 234 140"
                      fill="none"
                    >
                      <g clip-path="url(#clip0_462_1735)">
                        <path
                          d="M45.2302 29.1322L42.6203 21.6729H0.319092V29.1322V34.9968V130.329H140.56V29.1322H45.2302Z"
                          fill="#00AEEF"
                          stroke="#263238"
                          stroke-miterlimit="10"
                        />
                        <path
                          d="M137.93 39.0251H2.94177V124.135H137.93V39.0251Z"
                          fill="white"
                          stroke="#263238"
                          stroke-miterlimit="10"
                        />
                        <path
                          d="M112.278 31.947H45.2111V36.0325H112.278V31.947Z"
                          fill="white"
                          stroke="#263238"
                          stroke-miterlimit="10"
                        />
                        <path
                          d="M6.1898 31.2925L4.25629 33.1478L6.26 35.289"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M4.25629 33.1477H8.55086"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M17.1528 31.2925L19.0927 33.1478L17.0826 35.289"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M19.0927 33.1477H14.7917"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M28.3263 32.8999C28.1158 32.0612 27.2607 31.3687 26.3545 31.3687C25.2889 31.3687 24.421 32.2328 24.421 33.2939C24.421 34.3549 25.2889 35.2191 26.3545 35.2191C26.8204 35.2191 27.2479 35.0539 27.5797 34.7807"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M27.0245 33.116H28.5305V31.3369"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M38.581 32.487L36.7943 31.0447L35.0969 32.487V35.6131H38.581V32.487Z"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M37.4707 33.332H36.1115V35.505H37.4707V33.332Z"
                          fill="#263238"
                        />
                        <path
                          d="M35.0969 32.4871L34.0759 33.4147"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M38.581 32.4871L39.5382 33.3321"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M37.9365 25.5295L40.6549 28.3252"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M40.6996 25.574L37.8918 28.2807"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M130.822 32.6968H136.201"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M130.822 33.9041H136.201"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M130.822 35.1113H136.201"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M130.822 36.3184H136.201"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M105.622 7.77701L103.012 0.317627H60.7111V7.77701V13.6416V108.974H200.952V7.77701H105.622Z"
                          fill="#00AEEF"
                          stroke="#263238"
                          stroke-miterlimit="10"
                        />
                        <path
                          d="M198.329 17.6699H63.3402V102.779H198.329V17.6699Z"
                          fill="white"
                          stroke="#263238"
                          stroke-miterlimit="10"
                        />
                        <path
                          d="M172.67 10.5918H105.603V14.6773H172.67V10.5918Z"
                          fill="white"
                          stroke="#263238"
                          stroke-miterlimit="10"
                        />
                        <path
                          d="M66.5818 9.93726L64.6483 11.7926L66.6584 13.9338"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M64.6483 11.7925H68.9493"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M77.5512 9.93726L79.4847 11.7926L77.481 13.9338"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M79.4847 11.7925H75.1838"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M88.7248 11.5447C88.5142 10.706 87.6591 10.0134 86.753 10.0134C85.6873 10.0134 84.8195 10.8775 84.8195 11.9386C84.8195 12.9997 85.6873 13.8638 86.753 13.8638C87.2188 13.8638 87.6463 13.6986 87.9782 13.4254"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M87.423 11.7608H88.9226V9.98169"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M98.9731 11.1318L97.1863 9.68945L95.4889 11.1318V14.2642H98.9731V11.1318Z"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M97.8691 11.9768H96.5099V14.1498H97.8691V11.9768Z"
                          fill="#263238"
                        />
                        <path
                          d="M95.4889 11.1318L94.4679 12.0595"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M98.9731 11.1318L99.9366 11.9769"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M98.3285 4.18066L101.047 6.96999"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M101.092 4.21875L98.2902 6.92548"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M191.22 11.3416H196.6"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M191.22 12.5488H196.6"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M191.22 13.7561H196.6"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M191.22 14.9631H196.6"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M138.358 38.485L135.742 31.032H93.4468V38.485V44.3559V139.682H233.681V38.485H138.358Z"
                          fill="#00AEEF"
                          stroke="#263238"
                          stroke-miterlimit="10"
                        />
                        <path
                          d="M231.058 48.3779H96.0695V133.487H231.058V48.3779Z"
                          fill="white"
                          stroke="#263238"
                          stroke-miterlimit="10"
                        />
                        <path
                          d="M205.406 41.3062H138.339V45.3917H205.406V41.3062Z"
                          fill="white"
                          stroke="#263238"
                          stroke-miterlimit="10"
                        />
                        <path
                          d="M99.3176 40.6516L97.3777 42.5069L99.3878 44.6482"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M97.3777 42.5068H101.679"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M110.281 40.6516L112.214 42.5069L110.21 44.6482"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M112.214 42.5068H107.913"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M121.454 42.2591C121.244 41.4204 120.388 40.7278 119.482 40.7278C118.417 40.7278 117.549 41.5919 117.549 42.653C117.549 43.7141 118.417 44.5782 119.482 44.5782C119.948 44.5782 120.376 44.413 120.707 44.1398"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M120.152 42.4751H121.658V40.6897"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M131.702 41.8398L129.922 40.4038L128.218 41.8398V44.9722H131.702V41.8398Z"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M130.598 42.6848H129.239V44.8578H130.598V42.6848Z"
                          fill="#263238"
                        />
                        <path
                          d="M128.218 41.8398L127.197 42.7739"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M131.702 41.8398L132.666 42.6913"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M131.064 34.8887L133.783 37.678"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M133.821 34.9331L131.02 37.6398"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M223.95 42.0559H229.329"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M223.95 43.2632H229.329"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M223.95 44.4705H229.329"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M223.95 45.6775H229.329"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M116.668 91.6094H144.797"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M116.668 95.2246H144.797"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M116.668 98.8401H144.797"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M116.668 102.462H144.797"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M116.668 106.077H144.797"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M116.668 109.692H144.797"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M116.668 113.308H144.797"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M116.668 116.929H144.797"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M116.668 120.545H144.797"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M149.748 91.6094H177.871"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M149.748 95.2246H177.871"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M149.748 98.8401H177.871"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M149.748 102.462H177.871"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M149.748 106.077H177.871"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M149.748 109.692H177.871"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M149.748 113.308H177.871"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M149.748 116.929H177.871"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M149.748 120.545H177.871"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M149.748 62.96H177.871"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M149.748 66.5752H177.871"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M149.748 70.197H177.871"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M149.748 73.8123H177.871"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M149.748 77.4275H177.871"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M149.748 81.043H177.871"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M149.748 84.6646H177.871"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M149.748 88.28H177.871"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M182.688 91.6094H210.817"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M182.688 95.2246H210.817"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M182.688 98.8401H210.817"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M182.688 102.462H210.817"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M182.688 106.077H210.817"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M182.688 109.692H210.817"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M182.688 113.308H210.817"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M182.688 116.929H210.817"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M182.688 120.545H210.817"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M135.48 68.7228C135.627 68.3098 135.697 67.7825 135.697 67.3949C135.697 65.7492 134.025 65.6539 132.372 65.6539H131.958V65.2664H139.143V65.6539C137.273 65.6539 136.616 66.8358 136.01 68.6021L132.2 79.9818C131.907 80.8269 131.664 81.4559 130.771 81.4559H130.701L127.325 70.5845L124.173 79.9818C123.879 80.8269 123.636 81.4559 122.743 81.4559H122.673L117.772 65.6539H116.049V65.2664H122.36V65.6539H120.567L124.204 78.1201H124.351L127.095 69.8093L125.806 65.6539H124.083V65.2664H130.394V65.6539H128.601L132.238 78.1201H132.385L135.493 68.7228H135.48Z"
                          fill="#00AEEF"
                        />
                        <path
                          d="M211.085 61.6638H182.14V87.7145H211.085V61.6638Z"
                          fill="#00AEEF"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M211.085 72.5479C210.3 72.586 209.349 72.7067 208.194 72.9545C203.798 73.9076 202.649 80.1851 197.678 80.1851C193.275 80.1851 192.898 74.2825 186.587 73.52C184.679 73.2913 183.231 73.6725 182.133 74.3079V87.7017H211.079V72.5415L211.085 72.5479Z"
                          fill="#263238"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M191.756 67.6237C191.756 69.4092 190.301 70.8578 188.508 70.8578C186.715 70.8578 185.26 69.4092 185.26 67.6237C185.26 65.8383 186.715 64.3896 188.508 64.3896C190.301 64.3896 191.756 65.8383 191.756 67.6237Z"
                          fill="white"
                          stroke="#263238"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_462_1735">
                          <rect width="234" height="140" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  </i>
                  <h4>Top Blog</h4>
                  <span>
                    The use of blockchain in international trade decreases costs
                    and delays, optimizes efficiency, and helps reduce fraud.
                  </span>
                  <a
                    href="https://blog.ionixxtech.com/benefits-of-blockchain-in-logistics-and-supply-chain-management/"
                    target="_blank"
                  >
                    Read More...
                  </a>
                </div>
                <div className="headlines-content">
                  <strong className="overall-heading">Web3 News Roundup</strong>
                  <div className="headlines-list">
                    <a
                      href="https://www.forbes.com/sites/digital-assets/2023/04/01/crypto-suddenly-at-pivotal-moment-after-hyper-speed-bank-crisis-spurs-bitcoin-and-ethereum-price-pump/?sh=35714f15af27"
                      target="_blank"
                    >
                      ‘Pivotal Moment’ For Crypto After ‘Hyper-Speed’ Bank
                      Crisis Spurs Bitcoin And Ethereum Price Pump.
                    </a>
                    <p>
                      The bitcoin price has rocketed over the first three months
                      of 2023, adding around 70% and helping the ethereum price
                      and other smaller cryptocurrencies soar ahead of what
                      could be a $300 trillion crypto "quantum leap."
                    </p>
                  </div>
                  <div className="headlines-list">
                    <a
                      href="https://www.economist.com/finance-and-economics/2023/03/21/americas-banks-are-missing-hundreds-of-billions-of-dollars"
                      target="_blank"
                    >
                      America’s banks are missing hundreds of billions of
                      dollars.
                    </a>
                    <p>
                      Over the past year Deposits in commercial banks have sunk
                      by half a trillion dollars, a drop of nearly 3%. This
                      makes the financial system more fragile, since banks must
                      shrink to repay their deposits. Where is the money going?
                    </p>
                  </div>
                  <div className="headlines-list">
                    <a href="https://decrypt.co/136810/nft-nyc-2023-gmoney-ordinals-bitcoin-wrangler">
                      NFT NYC Again Showcased the Resilience of Web3 Culture.
                    </a>
                    <p>
                      From an NFT scavenger hunt to NFT-enabled physical
                      fashion, there was a lot to like at NFT NYC, even amid an
                      extended bear market.
                    </p>
                  </div>
                  <div className="headlines-list">
                    <a
                      href="https://www.coindesk.com/policy/2023/04/14/uks-new-technology-department-to-tackle-countrys-metaverse-web3-strategy/"
                      target="_blank"
                    >
                      UK’s New Technology Department to Tackle Country’s
                      Metaverse, Web3 Strategy.
                    </a>
                    <p>
                      The department will explore economic growth, investment
                      and business opportunities in these areas, as well as
                      implications on regulation.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="new-detail-option">
            <div className="news-first-section">
              <div className="heading">
                <div className="detail-heading">
                  <h5>
                    <span>1</span>What Caused The Collapse of The Banking
                    Systems?
                  </h5>
                </div>
              </div>
              <div className="newsissuedetailed">
                <div className="news-left">
                  <i>
                    {" "}
                    <img
                      src="/assets/newsletter_images/piggy-bank.png"
                      alt="piggy-bank"
                    />
                  </i>
                  {/* <strong>Tackling Alert Fatigue</strong> */}
                  <b>
                    One theory is that reckless lending practices may have led
                    to these bank collapses, as some banks extended credit to
                    borrowers incapable of repaying their loans. Another
                    possibility is that a rise in defaulters and foreclosures
                    may have contributed to the issue.{" "}
                  </b>
                </div>
                <div className="news-right">
                  <p>
                    In recent weeks, we have seen extensive news about
                    large-scale bank runs in the US, which has caused panic and
                    anxiety among investors. Three banks - Silicon Valley Bank,
                    Signature, and Silvergate - have collapsed and held a
                    combined $45 trillion in assets across the US banking
                    system. The financial industry is trying to determine the
                    root cause of these bank failures to prevent similar
                    situations from happening in the future.
                  </p>
                  <p>
                    Some people also believe that the bank's poor public
                    relations may have contributed to the problem, as it caused
                    panic among investors.
                  </p>
                  <p>
                    However, many financial experts point to digital currencies,
                    such as cryptocurrencies, as the main reason for the
                    collapse of Signature and Silvergate banks. These banks
                    heavily invested in the crypto industry, which is known for
                    its volatility, and as a result, they may have suffered
                    significant losses. This topic is getting debated within the
                    financial services industry, and brainstorming is going on
                    how to address the situation moving forward.
                  </p>
                </div>
              </div>
            </div>

            <div className="news-first-section">
              <div className="heading">
                <div className="detail-heading">
                  <h5>
                    <span>2</span>Bitcoin, The Saviour
                  </h5>
                </div>
              </div>
              <div className="newsissuedetailed">
                <div className="news-left">
                  <i>
                    <img
                      src="/assets/newsletter_images/bitcoin-logo.png"
                      alt="bitcoin-logo"
                    />
                  </i>
                  {/* <strong>Tackling Alert Fatigue</strong> */}
                  <b>
                    What is clear is that Bitcoin's impressive rally during this
                    time demonstrated just how powerful and disruptive
                    cryptocurrencies can be. By challenging traditional
                    financial systems and exposing their inherent risks and poor
                    decision-making, Bitcoin showed that there are options
                    available out there that could provide a more stable and
                    secure way of conducting financial transactions.{" "}
                  </b>
                </div>
                <div className="news-right">
                  <p>
                    Despite the widespread fear and anxiety that followed the
                    collapse of several US banks, there was a glimmer of hope in
                    the crypto market. Bitcoin's price surged by more than 18%
                    during the same time, and the second largest stablecoin,
                    USDC, also experienced a 4% recovery the day after the SVB
                    crisis.
                  </p>
                  <p>
                    It is difficult to say with certainty whether the financial
                    instability caused by the banking debacle helped prop up the
                    crypto market and restore confidence among investors who
                    felt disarrayed. But it is possible.
                  </p>
                  <p>
                    Of course, the situation is still unfolding, and there is
                    uncertainty about the future of the banking and crypto
                    industries. But events like these can majorly impact global
                    markets and spark a new wave of interest in alternative
                    financial systems.
                  </p>
                </div>
              </div>
            </div>

            <div className="news-first-section">
              <div className="heading">
                <div className="detail-heading">
                  <h5>
                    <span>3</span>The Benefits of Adopting Cryptocurrencies
                  </h5>
                </div>
              </div>
              <div className="newsissuedetailed">
                <div className="news-left">
                  <i>
                    {" "}
                    <i>
                      <img
                        src="/assets/newsletter_images/cryptocurrency.png"
                        alt="cryptocurrency"
                      />
                    </i>
                  </i>
                  <b>
                    Are alternative financial systems the holy grail in the wake
                    of the banking fallout? What do cryptocurrencies offer that
                    traditional banking systems lack? The answer is pretty
                    straightforward.
                  </b>
                </div>

                <div className="news-right">
                  <ul className="stroke-list">
                    <li>
                      <span></span>
                      <b>Decentralization:</b>
                      <p>
                        Cryptocurrencies are decentralized, which allows for
                        more transparency and security in transactions.
                      </p>
                    </li>
                    <li>
                      <span></span>
                      <b>Privacy:</b>
                      <p>
                        Unlike traditional financial systems, cryptocurrency
                        transactions do not require the disclosure of personal
                        information.
                      </p>
                    </li>
                    <li>
                      <span></span>
                      <b>Security:</b>
                      <p>
                        Cryptocurrencies are secured by advanced cryptography
                        and distributed ledger technology, which makes them
                        extremely difficult to counterfeit or hack.
                      </p>
                    </li>
                    <li>
                      <span></span>
                      <b>Low transaction fees: </b>
                      <p>
                        {" "}
                        Cryptocurrency transactions typically have lower charges
                        because they do not require intermediaries like banks or
                        credit card companies.
                      </p>
                    </li>
                    <li>
                      <span>5</span>
                      <b>Accessibility: </b>
                      <p>
                        The requirement to use cryptocurrencies is an internet
                        connection and a digital wallet.
                      </p>
                    </li>
                    <li>
                      <span>6</span>
                      <b>Global reach: </b>
                      <p>
                        Cryptocurrencies are not limited by geographic
                        boundaries, making them ideal for cross-border
                        transactions.
                      </p>
                    </li>
                    <li>
                      <span>7</span>
                      <b>Growth potential:</b>
                      <p>
                        {" "}
                        As more businesses and individuals adopt
                        cryptocurrencies, their value will probably increase.
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="news-first-section"></div>

            <div className="news-first-section">
              <div className="heading">
                <div className="detail-heading">
                  <h5>
                    <span>4</span>Does That Mean -- Cryptocurrencies Become A
                    Mainstream Alternative to Fiat Currencies And Traditional
                    Banking Systems?
                  </h5>
                </div>
              </div>
              <div className="newsissuedetailed">
                <div className="newissuedetailed_banner">
                  <span className="banner-wall">
                    <img src="/assets/newsletter_images/ebook-notepad-clip.png" />
                  </span>
                  <p>
                    The current instability in the traditional financial system
                    has resulted in a loss of trust in conventional banks and
                    fiat currencies, leading people to explore alternative forms
                    of currency and unconventional models that leverage the
                    power of decentralization, such as blockchain technology.
                  </p>
                  <p>
                    Due to their limited supply not being subjected to control
                    by central banks, cryptocurrencies are getting perceived as
                    a safeguard against inflation. Moreover, the growing
                    adoption of decentralized finance (DeFi) and blockchain
                    technology establishes the crypto industry as a more
                    legitimate and attractive option for protecting assets and
                    investing in safe and stable financial systems.
                  </p>
                  <p>
                    Although crypto markets are still volatile and not entirely
                    immune to economic downturns, the long-term investment
                    potential of cryptocurrencies is undeniable. As industry
                    experts suggest, crypto is not going anywhere but is here to
                    stay, thrive, and possibly dominate.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="final-section newsissuedetailed">
            <strong>
              That’s a wrap
              <br />
              <span>Hope you enjoyed it!</span> <br />
            </strong>
            <div className="checkout-interesting">
              <a href="https://blog.ionixxtech.com/">
                Check out our interesting Blogs
              </a>
            </div>
          </div>
          <div className="events-wrap">
            <strong>Events</strong>
            <div className="events-list">
              <div className="events">
                <img src="/assets/newsletter_images/issue_83_events.png" />
                <p>
                  Here’s team Ionixx at NFTNYC along with the awesome guys from
                  @badcryptopodcast.m{" "}
                </p>
              </div>
              <div className="events">
                <img src="/assets/newsletter_images/event-2.png" />
                <p>
                  Live from #HIMSS23 in Chicago: Ionixx's CMIO, Dr. Maanas
                  Samant, MD, is connecting with healthcare innovators from
                  around the world!
                </p>
              </div>
            </div>
          </div>
          <NewsSubscription categoryType="Web3" />
        </div>
      </>
    );
  }
}

export default NewsIssue83;
