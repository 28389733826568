import React from 'react';
import { Link } from "react-router-dom";
import { ROUTES } from "../../../routes";
// import "../oms/oms.css";
import "./capital-market.css"
import ContactUS from "../../../components/contact-us";
import { PortfolioRoute } from "../../portfolio/route-porfolio";
// import { ToastComponent } from '../../components/common';
import * as contactAction from "../../../action/contactAction";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ReactSEOMetaTags from 'react-seo-meta-tags';
import Helmet from 'react-helmet';
import Testimonials from '../../../components/testimonials/testimonials';
import TestimonialsNew from '../../../components/testimonials-new/testimonialsNew';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Slider from 'react-slick';
class CapitalMarket extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            headerClassAdd: "",
            showContactUS: false,
            showToast: false
        }
        this.onScrollHeader = this.onScrollHeader.bind(this)
    }
    componentDidMount() {
        window.addEventListener('scroll', this.onScrollHeader);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.onScrollHeader);
    }
    onScrollHeader() {
        const winScroll =
            document.body.scrollTop || document.documentElement.scrollTop

        const height =
            document.documentElement.scrollHeight -
            document.documentElement.clientHeight

        const scrolled = winScroll / height

        if (scrolled === 0) {
            this.setState({
                headerClassAdd: ""
            })
        } else {
            this.setState({
                headerClassAdd: "scrollHeader"
            })
        }
    }
    sendToast() {
        this.setState({ showContactUS: true })
    }
    render() {
        var blockSettings = {
            // className: "center",
            dots: false,
            arrows: true,
            infinite: true,
            autoplay: true,
            centerMode: false,
            speed: 3000,
            speed: 2000,
            autoplaySpeed: 5000,
            pauseOnHover: false,
            slidesToShow: 3,
            slidesToScroll: 1,
            initialSlide: 0,
            responsive: [
                {
                    breakpoint: 1240,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: false,
                    },
                },
                {
                    breakpoint: 1098,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: false,
                    },
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                    },
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        centerMode: false,
                    },
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        centerMode: false,
                    },
                },
            ],
        };
        return (
            <>
                <Helmet>
                    <title>Capital Markets Software Solutions and Services - Ionixx Tech</title>
                    <meta name="description" content="At Ionixx, we are dedicated to providing capital markets solutions and services for investors, enterprises, broker-dealers, and financial institutions to streamline business operations." />
                    <link rel="canonical" href="https://www.ionixxtech.com/fintech-solutions" />
                    <meta property="og:image" content={"https://www.ionixxtech.com/assets/img/SEO_OG_IMAGE/Fintech-Solutions.jpg"} />

                </Helmet>
                <ReactSEOMetaTags
                    render={(el) => <Helmet>{el}</Helmet>}
                    website={{
                        name: "Ionixx Technologies",
                        title: "Capital Markets Software Solutions and Services - Ionixx Tech",
                        description: "At Ionixx, we are dedicated to providing capital markets solutions and services for investors, enterprises, broker-dealers, and financial institutions to streamline business operations.",
                        type: "website",
                        image: 'https://www.ionixxtech.com/assets/img/SEO_OG_IMAGE/Fintech-Solutions.jpg',
                        url: "https://www.ionixxtech.com/fintech-solutions"
                    }}
                    twitter={{
                        twitter: "https://www.ionixxtech.com/fintech-solutions"
                    }}
                />
                <div className="Enterprise fintech-updaed-design Healthcare oms cap-market">
                    <div className="section-1 common-hero-bg white-bg">
                        <div className="custom-container">
                            <div className="custom-section">
                                <div className="main-head-div">
                                    <h2 className="main-head-1 custom-htech-head">
                                        <span className="primary-span">Custom-Built Technology Solutions for Capital Markets</span>
                                    </h2>
                                    <h2>Innovate. Adapt. Succeed</h2>
                                    <p>Drive transformation across the entire capital markets<br></br> value chain with cloud, APIs, and automation.</p>
                                </div>

                                <div className="main-para-div">
                                    <Link to={ROUTES.CONTACTUS} className="btn btn-primary connect-btn">Talk to us</Link>
                                </div>
                                
                            </div>
                        </div>
                        
                    </div>
                    <div className='section-7 financial-industries newcamp-section2'>
                        <div className='custom-container'>
                            <div className='title-center'>
                                <div className='subheading'>
                                    Equip yourself with future-proof<br></br> <h1>capital markets technology solutions.</h1>
                                </div>
                            </div>
                        </div> 
                    </div>

                    <div className="section-3 section-2 beige-bg newcamp-section3">
                        <div className="custom-containers">
                            <div className="custom-section">
                                <div className="row work-with-row">
                                    {/*<div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 work-with-col">
                                         <div className="left-info-hold">
                                            <div className="main-head-div custom-main-head-div">
                                                <h2 className="main-head-1 main-head-2">
                                                    <span className="primary-span text-center">Our Order Management Solution Process</span>
                                                    <span className="secondary-span text-center">Everything</span>
                                                </h2>
                                            </div>

                                        </div> 
                                    </div>*/}
                                    <div className='content-section oms-sol-pro'>
                                        <div className='col-lg-4 col-md-4 col-sm-6 solution-list'>
                                            {/* <i><img src='/assets/img/oms/fix-connectivity.png' /></i>
                                            <strong>FIX/API<br></br> Connectivity</strong> */}
                                            <p>Custom application and platform development</p>
                                        </div>
                                        <div className='col-lg-4 col-md-4 col-sm-6 solution-list'>
                                            <p>Data handling and management solutions</p>
                                        </div>
                                        <div className='col-lg-4 col-md-4 col-sm-6 solution-list'>
                                            <p>Process (front,middle, and back-office) optimization</p>
                                        </div>
                                        <div className='col-lg-4 col-md-4 col-sm-6 solution-list'>
                                            <p>Data/platform integration</p>
                                        </div>
                                        <div className='col-lg-4 col-md-4 col-sm-6 solution-list'>
                                             <p>Workflow<br></br> automation</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='section-5 newcamp-section4'>
                        <div className="custom-container">
                            <div className="custom-section">
                                <div className="row work-with-row">
                                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 work-with-col">
                                        <div className="left-info-hold">
                                            <div className="main-head-div custom-main-head-div">
                                                <h4 className="main-head-1 main-head-2">
                                                    <span className="primary-span new-heading1">Realize the 3-fold benefit of</span>
                                                </h4>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="compilance-section">
                                        <div className='compilance-list col-xl-4 col-lg-4 col-md-6 col-sm-12'>
                                            <i><img src='/assets/img/capital-market/Improved-efficiency.svg' /></i>
                                            <span>Improved <br></br>efficiency</span>
                                        </div>
                                        <div className='compilance-list col-xl-4 col-lg-4 col-md-6 col-sm-12'>
                                            <i><img src='/assets/img/capital-market/Reduced-time-to-marke.svg' /></i>
                                            <span>Reduced time<br></br> to market</span>
                                        </div>
                                        <div className='compilance-list col-xl-4 col-lg-4 col-md-6 col-sm-12'>
                                            <i><img src='/assets/img/capital-market/Optimized-costs.svg' /></i>
                                            <span>Optimized<br></br> costs</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="company-info beige-bg newcamp-section5">
                        <div className="section-2 white-bg">
                            <div className="custom-container">
                                <div className="custom-section">
                                    <div className="row work-with-row">
                                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 work-with-col">
                                            <div className="left-info-hold">
                                                <div className="main-head-div custom-main-head-div">
                                                    <h2 className="main-head-1 main-head-2">
                                                        <span className="primary-span ">Key Benefits of Our Capital Markets Solutions</span>
                                                    </h2>
                                                    <p>At Ionixx, we are dedicated to providing investors, enterprises,<br></br> broker-dealers, and financial institutions with modern capital markets solutions<br></br> that deliver operational excellence.</p>
                                                </div>

                                            </div>
                                        </div>
                                        <div className='content-section'>
                                            <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                                                <div className='solution-list-item'>
                                                    <span className='oms-img-tag'>
                                                        <img src='/assets/img/capital-market/Devops.svg' />
                                                    </span>
                                                    <div className='solution-card-data'>
                                                        <strong>Streamlined Operations</strong>
                                                        <span>Harness the power of automation and digital transformation to enhance efficiency in your capital markets processes.</span>
                                                    </div>
                                                </div>
                                            </div>


                                            <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                                                <div className='solution-list-item'>
                                                    <span className='oms-img-tag'>
                                                        <img src='/assets/img/capital-market/Low-Risk.svg' />
                                                    </span>
                                                    <div className='solution-card-data'>
                                                        <strong>Efficient Risk Management</strong>
                                                        <span>Mitigate risks effectively with state-of-the-art risk assessment tools and frameworks.</span>

                                                    </div>
                                                </div>
                                            </div>



                                            <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                                                <div className='solution-list-item'>
                                                    <span className='oms-img-tag'>
                                                        <img src='/assets/img/capital-market/Web-Analytics.svg' />
                                                    </span>
                                                    <div className='solution-card-data'>
                                                        <strong>Robust Data Analytics</strong>
                                                        <span>Make informed decisions with real-time data insights and predictive analytics, driving your business forward.</span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                                                <div className='solution-list-item'>
                                                    <span className='oms-img-tag'>
                                                        <img src='/assets/img/capital-market/Inspection.svg' />
                                                    </span>
                                                    <div className='solution-card-data'>
                                                        <strong>Strong Compliance</strong>
                                                        <span>Stay ahead of regulatory changes with our compliance and reporting tools, ensuring your business remains compliant at all times.</span>
                                                    </div>
                                                </div>
                                            </div>



                                            <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                                                <div className='solution-list-item'>
                                                    <span className='oms-img-tag'>
                                                        <img src='/assets/img/capital-market/Safety-Collection-Place.svg' />
                                                    </span>
                                                    <div className='solution-card-data'>
                                                        <strong>Sustained Client Engagement</strong>
                                                        <span>Enhance client experiences with bespoke, personalized solutions, creating lasting relationships and trust.</span>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="company-info beige-bg newcamp-section5 newcamp-section6">
                        <div className="section-2 white-bg">
                            <div className="custom-container">
                                <div className="custom-section">
                                    <div className="row work-with-row">
                                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 work-with-col">
                                            <div className="left-info-hold">
                                                <div className="main-head-div custom-main-head-div">
                                                    <h3 className="main-head-1 main-head-2">
                                                        <span className="primary-span ">Segments We Cater to in The<br></br> Capital Markets industry</span>
                                                    </h3>
                                                    <h4 className="main-head-1 main-head-2">
                                                        <span className="primary-span ">Full-cycle Trading Platforms & Solutions</span>
                                                    </h4>
                                                    <p>By leveraging our expertise in financial technology and trading environments,<br></br> we empower capital market businesses with our end-to-end automation services<br></br> that cover everything from ordering to clearing.</p>
                                                </div>

                                            </div>
                                        </div>
                                        <div className='content-section'>
                                            <div className='list-first-content'>
                                                <div className='col-lg-12 col-md-12 col-sm-12 col-12'>
                                                    <div className='solution-list-item'>
                                                        <span className='oms-img-tag'>
                                                            <img src='/assets/img/capital-market/Candlestick-Chart.svg' />
                                                        </span>
                                                        <div className='solution-card-data'>
                                                            <strong>Electronic Trading Platforms</strong>
                                                            <span>Our custom-built online trading platforms for retail and institutional clients provide them with electronic access to financial markets for a seamless trading experience.</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            


                                            <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                                                <div className='solution-list-item'>
                                                    <span className='oms-img-tag'>
                                                        <img src='/assets/img/capital-market/Control-Panel.svg' />
                                                    </span>
                                                    <div className='solution-card-data'>
                                                        <strong>Order Management Systems (OMS)</strong>
                                                        <span>We tailor our solutions to facilitate the efficient execution and management of trades by allowing broker-dealers to handle order entry, routing, and execution across multiple asset classes.</span>

                                                    </div>
                                                </div>
                                            </div>



                                            <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                                                <div className='solution-list-item'>
                                                    <span className='oms-img-tag'>
                                                        <img src='/assets/img/capital-market/Control-Panel1.svg' />
                                                    </span>
                                                    <div className='solution-card-data'>
                                                        <strong>Execution management System(EMS)</strong>
                                                        <span>Our trade execution services focus on providing clients with advanced trading tools, algorithmic trading capabilities, and connectivity to various liquidity venues for optimized trade execution.</span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                                                <div className='solution-list-item'>
                                                    <span className='oms-img-tag'>
                                                        <img src='/assets/img/capital-market/High-Risk.svg' />
                                                    </span>
                                                    <div className='solution-card-data'>
                                                        <strong>Risk Management Systems</strong>
                                                        <span>We provide a repository of tools for real-time risk assessment, stress testing, and compliance monitoring that enable broker-dealers to monitor and manage market, credit, and operational risks.</span>
                                                    </div>
                                                </div>
                                            </div>



                                            <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                                                <div className='solution-list-item'>
                                                    <span className='oms-img-tag'>
                                                        <img src='/assets/img/capital-market/Test-Passed.svg' />
                                                    </span>
                                                    <div className='solution-card-data'>
                                                        <strong>Compliance and Regulatory Reporting Tools</strong>
                                                        <span>We equip broker-dealers with tools for reporting trades, monitoring compliance with financial regulations, and staying updated on regulatory changes to minimize potential pitfalls.</span>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                    <div className="row work-with-row">
                                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 work-with-col">
                                            <div className="left-info-hold">
                                                <div className="main-head-div custom-main-head-div">
                                                    <h4 className="main-head-1 main-head-2">
                                                        <span className="primary-span ">Market Data Solutions</span>
                                                    </h4>
                                                    <p>We've strategically designed our services to provide resilient solutions<br></br> that offer time and historical market feeds to generate accurate<br></br> and timely market data for informed trading decisions.</p>
                                                </div>

                                            </div>
                                        </div>
                                        <div className='content-section'>
                                            <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                                                <div className='solution-list-item'>
                                                    <span className='oms-img-tag'>
                                                        <img src='/assets/img/capital-market/Services.svg' />
                                                    </span>
                                                    <div className='solution-card-data'>
                                                        <strong>Prime Brokerage Services</strong>
                                                        <span>Our prime brokerage services for broker-dealers provide a suite of financial solutions, including securities lending, custodial services, and financing.</span>

                                                    </div>
                                                </div>
                                            </div>



                                            <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                                                <div className='solution-list-item'>
                                                    <span className='oms-img-tag'>
                                                        <img src='/assets/img/capital-market/Transaction.svg' />
                                                    </span>
                                                    <div className='solution-card-data'>
                                                        <strong>Clearing and Settlement Solutions</strong>
                                                        <span>Our services provide a range of solutions to facilitate trade clearing and settlement to ensure the timely and accurate transfer of securities and funds, multi-clearing and self-clearing support, ACATS, real-time margin, and SMA calculations.</span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                                                <div className='solution-list-item'>
                                                    <span className='oms-img-tag'>
                                                        <img src='/assets/img/capital-market/Liquidity.svg' />
                                                    </span>
                                                    <div className='solution-card-data'>
                                                        <strong>Liquidity and Connectivity Solutions</strong>
                                                        <span>Broker-dealers can connect with various liquidity providers, exchanges, and alternative trading systems, enhancing access to liquidity for executing trades.</span>
                                                    </div>
                                                </div>
                                            </div>



                                            <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                                                <div className='solution-list-item'>
                                                    <span className='oms-img-tag'>
                                                        <img src='/assets/img/capital-market/Cryptocurrency-Global.svg' />
                                                    </span>
                                                    <div className='solution-card-data'>
                                                        <strong>Digital Transformation Solutions</strong>
                                                        <span>Broker-dealers can fast-track their digital transformation by accelerating the adoption of mobile trading apps, robo-advisors, and other innovative financial technologies.</span>

                                                    </div>
                                                </div>
                                            </div>

                                            <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                                                <div className='solution-list-item'>
                                                    <span className='oms-img-tag'>
                                                        <img src='/assets/img/capital-market/Cyber-Security.svg' />
                                                    </span>
                                                    <div className='solution-card-data'>
                                                        <strong>Cybersecurity Solutions</strong>
                                                        <span>Our robust cybersecurity measures ensure protection for sensitive financial data and maintain the integrity and confidentiality of client information.
</span>
                                                    </div>
                                                </div>
                                            </div>



                                            <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                                                <div className='solution-list-item'>
                                                    <span className='oms-img-tag'>
                                                        <img src='/assets/img/capital-market/Code.svg' />
                                                    </span>
                                                    <div className='solution-card-data'>
                                                        <strong>Custom Software Development</strong>
                                                        <span>Get tailored software solutions that meet specific business needs, such as proprietary trading systems, customer portals, or unique risk management tools.</span>

                                                    </div>
                                                </div>
                                            </div>

                                            <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                                                <div className='solution-list-item'>
                                                    <span className='oms-img-tag'>
                                                        <img src='/assets/img/capital-market/Time-to-Pay.svg' />
                                                    </span>
                                                    <div className='solution-card-data'>
                                                        <strong>Fully Paid Securities Lending</strong>
                                                        <span>Securities finance is an integral component of capital market solutions, often encompassing lending and borrowing securities, repo transactions, and collateral management.</span>

                                                    </div>
                                                </div>
                                            </div>


                                            <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                                                <div className='solution-list-item'>
                                                    <span className='oms-img-tag'>
                                                        <img src='/assets/img/capital-market/Investment.svg' />
                                                    </span>
                                                    <div className='solution-card-data'>
                                                        <strong>Investment Banking</strong>
                                                        <span>Fully automated solutions for the modern capital markets landscape covering a wide array of asset classes, ensuring reduced costs and minimized regulatory risk and complexity.</span>

                                                    </div>
                                                </div>
                                            </div>

                                            <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                                                <div className='solution-list-item'>
                                                    <span className='oms-img-tag'>
                                                        <img src='/assets/img/capital-market/High-Risk.svg' />
                                                    </span>
                                                    <div className='solution-card-data'>
                                                        <strong>Risk Management and Compliance</strong>
                                                        <span>Safeguard operations and enhancing your overall resilience with integrated technology solutions built with industry best practices. Mitigate risks and ensure regulatory compliance with our specialized risk management and compliance solutions.</span>

                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>


                    <div className='newcamp-section7'>
                        <div className="section-3 section-2 white-bg">
                            <div className="custom-container">
                                <div className="custom-section">
                                    <div className="row work-with-row">
                                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 work-with-col">
                                            <div className="left-info-hold">
                                                <div className="main-head-div custom-main-head-div">
                                                    <h4 className="main-head-1 main-head-2">
                                                        <span className="primary-span text-center">Experience the Ionixx Advantage</span>
                                                        {/* <span className="secondary-span text-center">Everything</span> */}
                                                    </h4>
                                                </div>

                                            </div>
                                        </div>
                                        <div className='content-section oms-sol-pro'>
                                            <div className='col-lg-4 col-md-4 col-sm-6 solution-list'>
                                                <i><img src='/assets/img/capital-market/Control-Panel-3.svg' /></i>
                                                <strong>Build a competitive<br></br> advantage</strong>
                                                <p>by improving trading and treasury performance while enhancing monitoring and reporting.</p>
                                            </div>
                                            <div className='col-lg-4 col-md-4 col-sm-6 solution-list'>
                                                <i><img src='/assets/img/capital-market/Process.svg' /></i>
                                                <strong>Transform your<br></br> current ecosystem</strong>
                                                    <p>to drive efficiency and capture innovations by creating coherent and flexible IT architectures. </p>
                                            </div>
                                            <div className='col-lg-4 col-md-4 col-sm-6 solution-list'>
                                                <i><img src='/assets/img/capital-market/Router.svg' /></i>
                                                <strong>Extend your business<br></br> capabilities</strong>
                                                <p>by analyzing and forecasting with one integrated solution, creating automated processes and coherent data.</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>




                    <div className='newcamp-section8'>
                        <div className="section-7 grey-bg">
                            <div className="custom-container">
                                <div className="custom-section">
                                    <div className="row work-with-row">
                                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 work-with-col">
                                            <div className="left-info-hold">
                                                <div className="main-head-div custom-main-head-div">
                                                    <h4 className="main-head-1 main-head-2">
                                                        <span className="primary-span text-center">Success Stories</span>
                                                    </h4>
                                                </div>

                                            </div>
                                        </div>
                                        
                                        <div className='content-section row'>
                                            
                                            <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                                                <div className='solution-list-item'>
                                                    <strong>
                                                        <img src='/assets/img/portfolio-img/icons/postTrade.svg' />
                                                    </strong>
                                                    <p>Ionixx partnered with a Brokerage firm to lead the digital transformation of their back-office trading operations.</p>
                                                    <a href='https://www.ionixxtech.com/case-studies/post-trade-operations'  target='_blank'> Read more...</a>
                                                </div>
                                            </div>

                                            <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                                                <div className='solution-list-item'>
                                                    <strong>
                                                        <img src='/assets/img/portfolio-img/icons/p2e.svg' />
                                                    </strong>
                                                    <p>Ionixx worked with P2E to build a cutting-edge compliance management soluti</p>
                                                    <a  href='https://www.ionixxtech.com/portfolio/p2e'  target='_blank'> Read more...</a>
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    
                    <div className="company-info beige-bg newcamp-section5 newcamp-section9">
                        <div className="section-2 white-bg">
                            <div className="custom-container">
                                <div className="custom-section">
                                    <div className="row work-with-row">
                                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 work-with-col">
                                            <div className="left-info-hold">
                                                <div className="main-head-div custom-main-head-div">
                                                    <h2 className="main-head-1 main-head-2">
                                                        <span className="primary-span ">Why Choose Ionixx for Capital Market<br></br> Software Solutions & Services?</span>
                                                    </h2>
                                                </div>

                                            </div>
                                        </div>

                                        <div className='content-section'>
                                            <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                                                <div className='solution-list-item'>
                                                    <span className='oms-img-tag'>
                                                        <img src='/assets/img/capital-market/shild-icon.svg' />
                                                    </span>
                                                    <div className='solution-card-data'>
                                                        <strong>Proven Expertise</strong>
                                                        <span>Benefit from the combined knowledge and experience of  industry experts, technologists, and financial professionals with extensive knowledge and experience in capital markets.</span>

                                                    </div>
                                                </div>
                                            </div>



                                            <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                                                <div className='solution-list-item'>
                                                    <span className='oms-img-tag'>
                                                        <img src='/assets/img/capital-market/shild-icon.svg' />
                                                    </span>
                                                    <div className='solution-card-data'>
                                                        <strong>Cost-Effective Solutions</strong>
                                                        <span>Realize significant cost savings while maintaining a competitive edge in the capital markets.</span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                                                <div className='solution-list-item'>
                                                    <span className='oms-img-tag'>
                                                        <img src='/assets/img/capital-market/shild-icon.svg' />
                                                    </span>
                                                    <div className='solution-card-data'>
                                                        <strong>Scalability</strong>
                                                        <span>Our solutions are designed to grow with your business, ensuring you are always equipped to adapt to changing market dynamics.</span>
                                                    </div>
                                                </div>
                                            </div>



                                            <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                                                <div className='solution-list-item'>
                                                    <span className='oms-img-tag'>
                                                        <img src='/assets/img/capital-market/shild-icon.svg' />
                                                    </span>
                                                    <div className='solution-card-data'>
                                                        <strong>Customizability</strong>
                                                        <span>Our solutions are not one-size-fits-all. We collaborate closely with clients to understand their unique needs, tailoring our services to align with their strategic objectives.</span>

                                                    </div>
                                                </div>
                                            </div>

                                            <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                                                <div className='solution-list-item'>
                                                    <span className='oms-img-tag'>
                                                        <img src='/assets/img/capital-market/shild-icon.svg' />
                                                    </span>
                                                    <div className='solution-card-data'>
                                                        <strong>Customer-centricity</strong>
                                                        <span>Count on our dedicated team for ongoing support and maintenance, ensuring seamless operations 24/7. We prioritize client satisfaction. Our collaborative approach and dedicated support ensure a seamless experience throughout the project lifecycle.
</span>
                                                    </div>
                                                </div>
                                            </div>



                                            <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                                                <div className='solution-list-item'>
                                                    <span className='oms-img-tag'>
                                                        <img src='/assets/img/capital-market/shild-icon.svg' />
                                                    </span>
                                                    <div className='solution-card-data'>
                                                        <strong>Futuristic & Innovative </strong>
                                                        <span>Stay at the forefront of the latest and emerging technology trends with our mission to continuously innovate, keeping your business agile and future-ready.</span>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                    


                                </div>
                            </div>
                        </div>
                    </div>
                    

                    
                    

                    








                    <ContactUS show={this.state.showContactUS} handleClose={() => this.setState({ showContactUS: false })} />
                </div>
            </>
        );
    }

}


const mapStateToProps = (state, ownProps) => {
    return state.commonReducer;
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return bindActionCreators(contactAction, dispatch);
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CapitalMarket);
function ExIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="14" viewBox="0 0 24 14" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M23.5227 3.04159L13.1523 13.412C12.5159 14.0484 11.4841 14.0484 10.8477 13.412L0.477287 3.04159C-0.159096 2.40521 -0.159096 1.37343 0.477287 0.737051C1.11367 0.100669 2.14545 0.100669 2.78183 0.737051L12 9.95522L21.2182 0.737052C21.8546 0.10067 22.8863 0.10067 23.5227 0.737052C24.1591 1.37343 24.1591 2.40521 23.5227 3.04159Z" fill="#42C2A2" />
        </svg>
    )
}
