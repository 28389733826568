import React from 'react';
import "../../resources.css";
import { ResourceRoute } from '../../resource-routing';
import { Link } from "react-router-dom";
import NewsHeadings from "./common-action/news-heading";
import NewsSubscription from "./common-action/subscripbe-section";

class NewsIssue19 extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            pageEnabled: false,
        }

    }

    render() {
        return (
          <div className="newdesigned_issue_details">
            <NewsHeadings
              buttondDetail="Web3 & Blockchain"
              categoryType="web3"
              description="Delivery Technology Is Keeping Chinese Cities Afloat Through Coronavirus | HBR.org"
            />

            <div className="newsissuedetailed">
              <div className="detailed-common">
                {/* <div className="back-to-common">
                    <span className="back-to-icon-hold">
                    <img src="/assets/img/resource/back-to-new.svg"  alt="bact to new"></img>
                    </span>
                    <Link  to={ResourceRoute.NEWSLETTER}><span className="back-to-link">Newsletter</span></Link>
                </div> */}

                <div className="news-letter whats-on-hold">
                  <div className="issue-main-head">
                    <h4>What’s on?</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://cointelegraph.com/news/us-takes-regulatory-steps-for-blockchain-technology-adoption"
                      >
                        <h4>
                          US takes regulatory steps for blockchain technology
                          adoption | Coin Telegraph{" "}
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        The United States’ concerns about the rise of
                        cryptocurrency use in illegal activities have only been
                        growing as developments in the space continue to push
                        the envelope. There is a global race to launch
                        stablecoins that could be potentially utilized by more
                        than half the world’s population. Meanwhile, Facebook is
                        committed to launching a Libra stablecoin that is
                        regulatorily compliant and can be used by over 2.5
                        billion Facebook subscribers.
                      </p>
                    </div>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://9to5mac.com/2020/05/08/facebook-web-new-design-dark-mode-rolling-out/"
                      >
                        <h4>
                          Facebook rolling out new web design with dark mode,
                          here’s how to opt-in | 9to5mac.com
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Sliding in just a day later than its announced deadline,
                        Facebook is starting to roll out its new design for its
                        web experience to all users. The new UI matches what the
                        company launched on mobile last year and comes with a
                        dark mode option when using Facebook in a browser.
                        Follow along for how to opt-in and turn on the new dark
                        theme
                      </p>
                    </div>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a href="https://www.technologyreview.com/2020/03/13/905301/mit-central-bank-digital-currency-bitcoin/">
                        <h4>
                          Three things central bankers can learn from Bitcoin |
                          MIT Review
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        For central bankers, the game changed last summer when
                        Facebook unveiled its proposal for Libra. Many have
                        responded by seriously exploring whether and how they
                        should issue their own digital money. This article
                        discusses the practical concerns central bankers should
                        be considering as they begin to design their own digital
                        money systems. One common theme: central bankers have
                        plenty to learn from Bitcoin.{" "}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="news-letter top-picks-hold">
                  <div className="issue-main-head">
                    <h4>Top Picks</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://hbr.org/2020/03/delivery-technology-is-keeping-chinese-cities-afloat-through-coronavirus"
                      >
                        <h4>
                          Delivery Technology Is Keeping Chinese Cities Afloat
                          Through Coronavirus | HBR.org
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Amazon and airport vendor OTG announced on Wednesday
                        that Amazon’s technology would be installed in several
                        CIBO Express stores at Newark Liberty and LaGuardia
                        airports. This news comes two days after Amazon
                        announced that it had begun selling its so-called “just
                        walk out” technology — which combines ceiling cameras,
                        computer vision, and weight sensors — to other
                        retailers, in a move that could someday impact the
                        millions of Americans who hold cashier jobs today.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="news-letter other-reads-hold">
                  <div className="issue-main-head">
                    <h4>Other Reads</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.theatlantic.com/family/archive/2020/03/coronavirus-what-does-social-distancing-mean/607927/"
                      >
                        <h4>
                          The Dos and Don’ts of ‘Social Distancing’ | The
                          Atlantic{" "}
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        The Centers for Disease Control and Prevention has
                        issued guidelines for “community mitigation strategies”
                        to limit the spread of COVID-19, the disease caused by
                        the coronavirus, which include recommendations for
                        “social distancing”—a term that epidemiologists are
                        using to refer to a conscious effort to reduce close
                        contact between people and hopefully stymie community
                        transmission of the virus.
                      </p>
                    </div>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.bbc.com/worklife/article/20200312-coronavirus-covid-19-update-work-from-home-in-a-pandemic"
                      >
                        <h4>How to work from home, the right way | BBC</h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Google, Microsoft, Twitter. Hitachi, Apple, Amazon.
                        Chevron, Salesforce, Spotify. From the UK to the US,
                        Japan to South Korea, these are all global companies
                        that have, in the last few days, rolled out mandatory
                        work-from-home policies amid the spread of
                        Covid-19.Whether you’re a newbie or WFH veteran, here’s
                        what you need to do to stay productive.{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <NewsSubscription categoryType="Web3" />
          </div>
        );
    }

}

export default NewsIssue19;
