import React from 'react';
import "../../resources.css";
import { ResourceRoute } from '../../resource-routing';
import { Link } from "react-router-dom";
import NewsHeadings from "./common-action/news-heading";
import NewsSubscription from "./common-action/subscripbe-section";

class NewsIssue74 extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            pageEnabled: false,
        }

    }

    render() {
        return (
          <div className="newdesigned_issue_details">
            <NewsHeadings
              buttondDetail="FinTech"
              categoryType="fintech"
              description="Amazon’s cashierless checkout technology is coming to its new supermarkets | CNBC.com"
            />

            <div className="newsissuedetailed">
              <div className="detailed-common">
                {/* <div className="back-to-common">
                            <span className="back-to-icon-hold">
                                <img src="/assets/img/resource/back-to-new.svg" alt="back to new"></img>
                            </span>
                            <Link to={ResourceRoute.NEWSLETTER}><span className="back-to-link">Newsletter</span></Link>
                        </div> */}

                <div className="news-letter whats-on-hold">
                  <div className="issue-main-head">
                    <h4>What’s on?</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.bloomberg.com/news/articles/2021-04-21/blockchain-company-digital-asset-holdings-raises-120-million"
                      >
                        <h4>
                          Blockchain Company Digital Asset Raises $120 Million |
                          Bloomberg.com
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Digital Asset Holdings LLC, the blockchain software
                        developer that caters to corporate applications, raised
                        more than $120 million from investors 7Ridge and
                        Eldridge. Investors have poured record funding into
                        cryptocurrency and blockchain-related companies this
                        year. In the first quarter, 129 startups focusing on
                        digital technology raised about $2.6 billion, according
                        to CB Insights.
                      </p>
                    </div>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://techcrunch.com/2021/04/22/tiger-global-backs-indian-crypto-startup-coinswitch-kuber-at-over-500m-valuation/"
                      >
                        <h4>
                          Tiger Global backs Indian crypto startup CoinSwitch at
                          over $500m valuation | Techcrunch
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        CoinSwitch Kuber, a startup that allows young users in
                        India to invest in cryptocurrencies, said on Thursday it
                        has raised $25 million in a new financing round as it
                        looks to expand its reach in India, the world’s
                        second-largest internet market. Tiger Global financed
                        the entire Series B funding round of CoinSwitch Kuber
                        and valued the three-year-old Indian startup at more
                        than $500 million. The announcement of the Series B
                        comes just three months after CoinSwitch closed its $15
                        million Series A round from Ribbit Capital, Sequoia
                        Capital India and Kunal Shah. The Bangalore-based
                        startup has raised $41.5 million to date.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="news-letter top-picks-hold">
                  <div className="issue-main-head">
                    <h4>Top Picks</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.cnbc.com/2021/04/22/amazons-cashierless-checkout-technology-is-coming-to-its-new-supermarkets.html"
                      >
                        <h4>
                          Amazon’s cashierless checkout technology is coming to
                          its new supermarkets | CNBC.com
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Amazon’s cashierless checkout technology could soon be
                        coming to its growing line of Fresh grocery stores.
                        Features of Amazon’s automated checkout technology
                        called “Just Walk Out,” appear in planning documents for
                        a store under construction in a suburban shopping plaza
                        in Brookfield, Connecticut. The technology allows
                        customers to skip the checkout line by tracking any
                        items they grab and charging them when they leave.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="news-letter other-reads-hold">
                  <div className="issue-main-head">
                    <h4>Other Reads</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.technologyreview.com/2021/04/16/1021871/geoffrey-hinton-glom-godfather-ai-neural-networks/"
                      >
                        <h4>
                          Geoffrey Hinton has a hunch about what’s next for AI |
                          MIT Tech Review
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Back in November, the computer scientist and cognitive
                        psychologist Geoffrey Hinton had a hunch. After a
                        half-century’s worth of attempts—some wildly
                        successful—he’d arrived at another promising insight
                        into how the brain works and how to replicate its
                        circuitry in a computer. Hinton wrote up his hunch, and
                        posted a 44-page paper on the arXiv preprint server in
                        February. He began with a disclaimer: “This paper does
                        not describe a working system,” he wrote. Rather, it
                        presents an “imaginary system" named "GLOM." Hinton
                        thinks of GLOM as a way to model human perception in a
                        machine. It also gets at the elusive goal of modelling
                        intuition—our ability to effortlessly make analogies to
                        make sense of the world. Hinton hopes GLOM might be one
                        of several breakthroughs that he reckons are needed
                        before AI is capable of truly nimble problem solving
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <NewsSubscription categoryType="FinTech" />
          </div>
        );
    }

}

export default NewsIssue74;
