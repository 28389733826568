import NetworkManager from "../utilities/networkManager";
import {
  BASE_URL,
  CONTACT_API,
  UPLOAD_URL,
  SUBSCRIBE_URL,
  BLOG_URL
} from "../utilities/apiRoutes";
import CommonActionType from "../action/actionTypes/commonActionType";
console.log(UPLOAD_URL);
export function sendEmail(requestPayload) {
  return function () {
    return NetworkManager.post(
      BASE_URL + CONTACT_API.SEND_MAIL,
      requestPayload,
      false
    );
  };
}
export function uploadImage(file) {
  return function () {
    return NetworkManager.postMultipart(
      UPLOAD_URL + CONTACT_API.UPLOAD,
      file,
      false
    );
  };
}
export function sendEmailForContest(requestPayload) {
  return function () {
    return NetworkManager.post(
      UPLOAD_URL + CONTACT_API.SEND_MAIL,
      requestPayload,
      false
    );
  };
}

export function sendEmailForContact(requestPayload) {
  return function () {
    return NetworkManager.post(
      UPLOAD_URL + CONTACT_API.CONNECT,
      requestPayload,
      false
    );
  };
}

export function sendNewsFeed(val) {
  let emailObj = {
    emailID: val,
  };
  return function () {
    return NetworkManager.post(
      BASE_URL + CONTACT_API.SEND_NEWS_EMAIL,
      emailObj,
      false
    );
  };
}
export function subscribeForNewsLetter(obj) {
  let subscribeObj = {
    name: obj.name || "",
    category: obj.category || "",
    url: obj.url || "",
    emailID: obj.emailID || "",
  };
  return function () {
    return NetworkManager.post(SUBSCRIBE_URL, subscribeObj, false);
  };
}
export function toastShow(subscribeToast) {
  return function (dispatch) {
    return dispatch({
      type: CommonActionType.TOAST,
      payload: subscribeToast,
    });
  };
}

export function getBlogs() {
  return function () {
    return NetworkManager.get(
      BLOG_URL
    );
  };
}
