import React from 'react';
import { Link } from "react-router-dom";
import { ROUTES } from "../../../routes";
// import "../oms/oms.css";
import "./trade-automation.css"
import ContactUS from "../../../components/contact-us";
import { PortfolioRoute } from "../../portfolio/route-porfolio";
// import { ToastComponent } from '../../components/common';
import * as contactAction from "../../../action/contactAction";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import ReactSEOMetaTags from 'react-seo-meta-tags';
import Helmet from 'react-helmet';
import Testimonials from '../../../components/testimonials/testimonials';
import TestimonialsNew from '../../../components/testimonials-new/testimonialsNew';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Slider from 'react-slick';
class TradeAutomation extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            headerClassAdd: "",
            showContactUS: false,
            showToast: false
        }
        this.onScrollHeader = this.onScrollHeader.bind(this)
    }
    componentDidMount() {
        window.addEventListener('scroll', this.onScrollHeader);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.onScrollHeader);
    }
    onScrollHeader() {
        const winScroll =
            document.body.scrollTop || document.documentElement.scrollTop

        const height =
            document.documentElement.scrollHeight -
            document.documentElement.clientHeight

        const scrolled = winScroll / height

        if (scrolled === 0) {
            this.setState({
                headerClassAdd: ""
            })
        } else {
            this.setState({
                headerClassAdd: "scrollHeader"
            })
        }
    }
    sendToast() {
        this.setState({ showContactUS: true })
    }
    render() {
        var blockSettings = {
            // className: "center",
            dots: false,
            arrows: true,
            infinite: true,
            autoplay: true,
            centerMode: false,
            speed: 3000,
            speed: 2000,
            autoplaySpeed: 5000,
            pauseOnHover: false,
            slidesToShow: 3,
            slidesToScroll: 1,
            initialSlide: 0,
            responsive: [
                {
                    breakpoint: 1240,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: false,
                    },
                },
                {
                    breakpoint: 1098,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: false,
                    },
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                    },
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        centerMode: false,
                    },
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        centerMode: false,
                    },
                },
            ],
        };
        return (
            <>
                <Helmet>
                    <title>Trade Automation Solutions - Ionixx Tech</title>
                    <meta name="description" content="Transform your trading strategies with Ionixx's cutting-edge trade automation solutions designed to automate complex trading tasks and minimize risks throughout the trade lifecycle." />
                    <link rel="canonical" href="https://www.ionixxtech.com/fintech-solutions" />
                    <meta property="og:image" content={"https://www.ionixxtech.com/assets/img/SEO_OG_IMAGE/Fintech-Solutions.jpg"} />

                </Helmet>
                <ReactSEOMetaTags
                    render={(el) => <Helmet>{el}</Helmet>}
                    website={{
                        name: "Ionixx Technologies",
                        title: "Trade Automation Solutions - Ionixx Tech",
                        description: "Transform your trading strategies with Ionixx's cutting-edge trade automation solutions designed to automate complex trading tasks and minimize risks throughout the trade lifecycle.",
                        type: "website",
                        image: 'https://www.ionixxtech.com/assets/img/SEO_OG_IMAGE/Fintech-Solutions.jpg',
                        url: "https://www.ionixxtech.com/fintech-solutions"
                    }}
                    twitter={{
                        twitter: "https://www.ionixxtech.com/fintech-solutions"
                    }}
                />
                <div className="Enterprise fintech-updaed-design Healthcare oms cap-market trade-automation">
                    <div className="section-1 common-hero-bg white-bg">
                        <div className="custom-container">
                            <div className="custom-section">
                                <div className="main-head-div">
                                    <h2 className="main-head-1 custom-htech-head">
                                        <span className="primary-span">Start Automating Your Trades</span>
                                    </h2>
                                    {/* <h2>with Ionixx’s Trade Automation Solutions</h2> */}
                                    <p>with Ionixx’s Trade Automation Solutions</p>
                                </div>

                                <div className="main-para-div">
                                    <Link to={ROUTES.CONTACTUS} className="btn btn-primary connect-btn">Talk to us</Link>
                                </div>
                                
                            </div>
                        </div>
                        
                    </div>
                    <div className='section-2 beige-bg newcamp-section2'>
                        <div className='custom-container'>
                            <div className="custom-section">
                                <div className="row work-with-row">
                                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 work-with-col">
                                        <div className="left-info-hold">
                                            <div className="main-head-div custom-main-head-div title-center">
                                                <h2 className="main-head-1 main-head-2">
                                                    <span >Trade on the Go. Anywhere and Anytime</span>
                                                </h2>
                                                <h1 className="main-head-1 main-head-2">
                                                    <span >Trade Automation Solutions</span>
                                                </h1>
                                                <p>At Ionixx, we revolutionize trading through cutting-edge automation solutions<br></br> designed to elevate your trade execution efficiency. Our suite of services<br></br> combines advanced algorithmic trading, intelligent order management systems,<br></br> and seamless trade lifecycle automation to empower your business in<br></br> today's dynamic financial markets.</p>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div> 
                    </div>

                    <div className="company-info beige-bg newcamp-section3">
                        <div className="section-2 white-bg">
                            <div className="custom-container">
                                <div className="custom-section">
                                    <div className="row work-with-row">
                                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 work-with-col">
                                            <div className="left-info-hold">
                                                <div className="main-head-div custom-main-head-div">
                                                    <h3 className="main-head-1 main-head-2">
                                                        <span className="primary-span ">Our Key Offerings</span>
                                                    </h3>
                                                </div>

                                            </div>
                                        </div>
                                        <div className='content-section'>
                                            <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                                                <div className='solution-list-item'>
                                                    <span className='oms-img-tag'>
                                                        <img src='/assets/img/trade-automation/key-off-ico1.svg' />
                                                    </span>
                                                    <div className='solution-card-data'>
                                                        <strong>Trade Lifecycle Automation</strong>
                                                        <span>Our comprehensive trade lifecycle automation solutions enable seamless trade processing, from strategy formulation and testing to execution and settlement, ensuring a swift and error-free trading experience.</span>
                                                    </div>
                                                </div>
                                            </div>


                                            <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                                                <div className='solution-list-item'>
                                                    <span className='oms-img-tag'>
                                                        <img src='/assets/img/trade-automation/key-off-ico2.svg' />
                                                    </span>
                                                    <div className='solution-card-data'>
                                                        <strong>Automated Order Management</strong>
                                                        <span>Maximize your trade potential with our advanced Order Management Systems (OMS) that can process 12,000 transactions per second, streamlining trade execution, mitigating risk, and enhancing decision-making processes in real-time.</span>

                                                    </div>
                                                </div>
                                            </div>



                                            <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                                                <div className='solution-list-item'>
                                                    <span className='oms-img-tag'>
                                                        <img src='/assets/img/trade-automation/key-off-ico3.svg' />
                                                    </span>
                                                    <div className='solution-card-data'>
                                                        <strong>Post Trade Management</strong>
                                                        <span>We offer automated clearing and custody post-trade solutions to support end-to-end post-trade processing functions, such as trade reconciliation, validation and exception management.</span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                                                <div className='solution-list-item'>
                                                    <span className='oms-img-tag'>
                                                        <img src='/assets/img/trade-automation/key-off-ico4.svg' />
                                                    </span>
                                                    <div className='solution-card-data'>
                                                        <strong>Cutting-edge Technology Integration</strong>
                                                        <span>Ionixx integrates cutting-edge technology, leveraging trading robots, smart order routing, and real-time market data feeds to drive superior performance and competitive advantage.</span>
                                                    </div>
                                                </div>
                                            </div>



                                            <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                                                <div className='solution-list-item'>
                                                    <span className='oms-img-tag'>
                                                        <img src='/assets/img/trade-automation/key-off-ico5.svg' />
                                                    </span>
                                                    <div className='solution-card-data'>
                                                        <strong>Risk Management Tools</strong>
                                                        <span>Ionixx's risk management tools provide comprehensive risk assessment and mitigation capabilities, allowing you to safeguard your trading activities.</span>

                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                                                <div className='solution-list-item'>
                                                    <span className='oms-img-tag'>
                                                        <img src='/assets/img/trade-automation/key-off-ico6.svg' />
                                                    </span>
                                                    <div className='solution-card-data'>
                                                        <strong>Compliance and Regulatory Adherence</strong>
                                                        <span>Ionixx provides compliance and regulatory automation tools to ensure that your trading operations meet stringent industry standards effortlessly.</span>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='newcamp-section4'>
                        <div className="section-3 section-2 beige-bg">
                            <div className="custom-container">
                                <div className="custom-section">
                                    <div className="row work-with-row">
                                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 work-with-col">
                                            <div className="left-info-hold">
                                                <div className="main-head-div custom-main-head-div">
                                                    <h2 className="main-head-1 main-head-2">
                                                        <span className="primary-span text-center">Our Trade Automation Highlights</span>
                                                        {/* <span className="secondary-span text-center">Everything</span> */}
                                                    </h2>
                                                </div>

                                            </div>
                                        </div>
                                        <div className='content-section oms-sol-pro'>
                                            <div className='col-lg-4 col-md-6 col-sm-12 solution-list'>
                                                <i><img src='/assets/img/trade-automation/market-ico.svg' /></i>
                                                <p>Real-time Market Monitoring</p>
                                            </div>
                                            <div className='col-lg-4 col-md-6 col-sm-12 solution-list'>
                                                <i><img src='/assets/img/trade-automation/back-testing.svg' /></i>
                                                <p>Multi-Platform Compatibility</p>
                                            </div>
                                            <div className='col-lg-4 col-md-6 col-sm-12 solution-list'>
                                                <i><img src='/assets/img/trade-automation/trade-analytics.svg' /></i>
                                                <p>Trade Reporting and Analytics</p>
                                            </div>
                                            <div className='col-lg-4 col-md-6 col-sm-12 solution-list'>
                                                <i><img src='/assets/img/trade-automation/data-feeds.svg' /></i>
                                                <p>Integration with Market Data Feeds</p>
                                            </div>
                                            <div className='col-lg-4 col-md-6 col-sm-12 solution-list'>
                                                <i><img src='/assets/img/trade-automation/ui-ico.svg' /></i>
                                                <p>User-Friendly Interface</p>
                                            </div>
                                            <div className='col-lg-4 col-md-6 col-sm-12 solution-list'>
                                                <i><img src='/assets/img/trade-automation/scale-flexi.svg' /></i>
                                                <p>Scalability and Flexibility</p>
                                            </div>
                                            <div className='col-lg-4 col-md-6 col-sm-12 solution-list'>
                                                <i><img src='/assets/img/trade-automation/alerts-notify.svg' /></i>
                                                <p>Alerts and Notifications</p>
                                            </div>
                                            <div className='col-lg-4 col-md-6 col-sm-12 solution-list'>
                                                <i><img src='/assets/img/trade-automation/multi-platform.svg' /></i>
                                                <p>Trade Simulation</p>
                                            </div>
                                            <div className='col-lg-4 col-md-6 col-sm-12 solution-list'>
                                                <i><img src='/assets/img/trade-automation/trade-security.svg' /></i>
                                                <p>Security Measures</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='section-5 newcamp-section5'>
                        <div className="custom-container">
                            <div className="custom-section">
                                <div className="row work-with-row">
                                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 work-with-col">
                                        <div className="left-info-hold">
                                            <div className="main-head-div custom-main-head-div">
                                                <h2 className="main-head-1 main-head-2">
                                                    <span className="primary-span new-heading1">Trade Finance Automation Technologies</span>
                                                </h2>
                                                <p>Trade finance automation involves leveraging technology to streamline and<br></br> optimize various aspects of trade finance operations.</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="compilance-section">
                                        <div className='compilance-list col-xl-4 col-lg-4 col-md-6 col-sm-12'>
                                            <i><img src='/assets/img/trade-automation/Ledger-ico.svg' /></i>
                                            <span>Distributed Ledger<br></br> Technology(DLT)</span>
                                        </div>
                                        <div className='compilance-list col-xl-4 col-lg-4 col-md-6 col-sm-12'>
                                            <i><img src='/assets/img/trade-automation/AI-ico.svg' /></i>
                                            <span>Artificial Intelligence(AI)<br></br> & Machine Learning(ML)</span>
                                        </div>
                                        <div className='compilance-list col-xl-4 col-lg-4 col-md-6 col-sm-12'>
                                            <i><img src='/assets/img/trade-automation/Bot-ico.svg' /></i>
                                            <span>Robotic Proces<br></br> Automation (RPA)</span>
                                        </div>
                                        <div className='compilance-list col-xl-4 col-lg-4 col-md-6 col-sm-12'>
                                            <i><img src='/assets/img/trade-automation/Agreement-ico.svg' /></i>
                                            <span>Smart<br></br> Contracts</span>
                                        </div>
                                        <div className='compilance-list col-xl-4 col-lg-4 col-md-6 col-sm-12'>
                                            <i><img src='/assets/img/trade-automation/API-ico.svg' /></i>
                                            <span>Application Programming<br></br> Interfaces (APIs)</span>
                                        </div>
                                        <div className='compilance-list col-xl-4 col-lg-4 col-md-6 col-sm-12'>
                                            <i><img src='/assets/img/trade-automation/OCR-ico.svg' /></i>
                                            <span>Optical Character<br></br> Recognition (OCR)</span>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                    
                    <div className="company-info beige-bg newcamp-section5 newcamp-section9">
                        <div className="section-2 white-bg">
                            <div className="custom-container">
                                <div className="custom-section">
                                    <div className="row work-with-row">
                                        <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 work-with-col">
                                            <div className="left-info-hold">
                                                <div className="main-head-div custom-main-head-div">
                                                    <h2 className="main-head-1 main-head-2">
                                                        <span className="primary-span ">Why Choose Ionixx for<br></br> Trade Automation Solutions?</span>
                                                    </h2>
                                                </div>

                                            </div>
                                        </div>

                                        <div className='content-section'>
                                            <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                                                <div className='solution-list-item'>
                                                    <span className='oms-img-tag'>
                                                        <img src='/assets/img/capital-market/shild-icon.svg' />
                                                    </span>
                                                    <div className='solution-card-data'>
                                                        <strong>Advanced Algorithmic Expertise</strong>
                                                        <span>Benefit from our cutting-edge algorithmic trading strategies meticulously crafted by seasoned experts. Our algorithms leverage high-frequency trading techniques and intelligent data analysis to capitalize on market opportunities swiftly and effectively.</span>

                                                    </div>
                                                </div>
                                            </div>



                                            <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                                                <div className='solution-list-item'>
                                                    <span className='oms-img-tag'>
                                                        <img src='/assets/img/capital-market/shild-icon.svg' />
                                                    </span>
                                                    <div className='solution-card-data'>
                                                        <strong>Tailored Solutions for Diverse Strategies</strong>
                                                        <span>We understand that every trading strategy is unique. Our customizable solutions cater to a wide array of trading styles, empowering you with the flexibility to implement strategies that align perfectly with your specific objectives.</span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                                                <div className='solution-list-item'>
                                                    <span className='oms-img-tag'>
                                                        <img src='/assets/img/capital-market/shild-icon.svg' />
                                                    </span>
                                                    <div className='solution-card-data'>
                                                        <strong>Seamless Automation Across the Trade Lifecycle</strong>
                                                        <span>From strategy formulation to execution and settlement, our trade automation solutions ensure a seamless process, minimizing manual intervention and optimizing trade performance at every stage.</span>
                                                    </div>
                                                </div>
                                            </div>



                                            <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                                                <div className='solution-list-item'>
                                                    <span className='oms-img-tag'>
                                                        <img src='/assets/img/capital-market/shild-icon.svg' />
                                                    </span>
                                                    <div className='solution-card-data'>
                                                        <strong>Compliance and Security Assurance</strong>
                                                        <span>Rest assured of our commitment to compliance and security. Our solutions adhere to the highest industry standards, ensuring your trading activities are fully compliant and your data remains secure at all times.</span>

                                                    </div>
                                                </div>
                                            </div>

                                            <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                                                <div className='solution-list-item'>
                                                    <span className='oms-img-tag'>
                                                        <img src='/assets/img/capital-market/shild-icon.svg' />
                                                    </span>
                                                    <div className='solution-card-data'>
                                                        <strong>Continuous Innovation and Support</strong>
                                                        <span>We are dedicated to continual innovation. Our team works tirelessly to refine and enhance our solutions, providing ongoing support to ensure your trading strategies remain competitive and efficient.
</span>
                                                    </div>
                                                </div>
                                            </div>



                                            <div className='col-lg-6 col-md-6 col-sm-12 col-12'>
                                                <div className='solution-list-item'>
                                                    <span className='oms-img-tag'>
                                                        <img src='/assets/img/capital-market/shild-icon.svg' />
                                                    </span>
                                                    <div className='solution-card-data'>
                                                        <strong>Client-Centric Approach</strong>
                                                        <span>At Ionixx, your success is our priority. We work closely with our clients, offering personalized attention and solutions that align perfectly with your trading goals and aspirations.</span>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                    


                                </div>
                            </div>
                        </div>
                    </div>
                    
                    

                    <div className="company-info beige-bg newcamp-section6">
                        <div className="section-2 white-bg grey-fintech-bg">
                            <div className='custom-container'>
                                <div className="marketting-teams">
                                    <div className="main-head-div main-head-1">
                                        <h4>
                                            <span className="primary-span">Join with us</span>
                                        </h4>
                                    </div>
                                    <p>
                                    Trade like never before with the help of our advanced automation tools. Start automating your trades and witness unprecedented growth and success in the financial markets.
                                    </p>
                                    <div className='team-detail'>
                                        <a href="https://calendly.com/ionixxtechnologies-office/30min?" target="_blank"><button name="Book a demo" className='Book-a-demo' >
                                            Book a demo
                                        </button></a>
                                        <a href="https://www.ionixxtech.com/contactus"><button className='consultation' name="Get a consultation" >
                                            Get a consultation
                                        </button></a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="section-10 beige-bg company-info development-solution faq-listwrp">
                        <div className="custom-container">
                            <div className="custom-section">
                                <div className="main-head-div heading_lines">
                                    <h1 className="main-head-1">
                                        <span className="primary-span">

                                            Frequently <em>Asked</em> Questions
                                        </span>
                                    </h1>
                                </div>
                                <div className="row work-with-row">
                                    <div className="col-xl-12 col-lg-12 col-md-12 col-sm-12 work-with-col">
                                        <div className={`parent ${this.state.showMore && 'active'}`}>
                                            <Accordion className="faq-list">
                                                <AccordionSummary
                                                    expandIcon={<ExIcon />}
                                                    aria-controls="panel1a-content"
                                                    id="panel1a-header"
                                                    className="summary"
                                                >
                                                    <h2 className="summary-content">
                                                        <span className="query-content">How does Ionixx’s trade automation work?</span></h2>
                                                </AccordionSummary>
                                                <AccordionDetails className="faq-details">
                                                    <Typography>
                                                        <span className="padding-left">Trade automation refers to the use of technology and algorithms to execute trades automatically, without human intervention. It works by analyzing predefined trading strategies, market data, and predefined rules to make trading decisions and execute trades efficiently.
                                                        </span>
                                                    </Typography>
                                                </AccordionDetails>
                                            </Accordion>
                                            <Accordion className="faq-list">
                                                <AccordionSummary
                                                    expandIcon={<ExIcon />}
                                                    aria-controls="panel1a-content"
                                                    id="panel1a-header"
                                                    className="summary"
                                                >
                                                    <h2 className="summary-content">
                                                        <span className="query-content">How do I get started with Ionixx's trade automation solutions?</span></h2>
                                                </AccordionSummary>
                                                <AccordionDetails className="faq-details">
                                                    <Typography>
                                                        <span className="padding-left">Getting started is simple. Reach out to our team, and we will guide you through the entire process. We offer comprehensive onboarding and support, ensuring a smooth and successful integration of our trade automation services into your trading routine.</span>
                                                    </Typography>
                                                </AccordionDetails>
                                            </Accordion>
                                            <Accordion className="faq-list">
                                                <AccordionSummary
                                                    expandIcon={<ExIcon />}
                                                    aria-controls="panel1a-content"
                                                    id="panel1a-header"
                                                    className="summary"
                                                >
                                                    <h2 className="summary-content">
                                                        <span className="query-content">Can I customize the trade automation strategies according to my preferences?</span></h2>
                                                </AccordionSummary>
                                                <AccordionDetails className="faq-details">
                                                    <Typography>
                                                        <span className="padding-left">Absolutely! Ionixx's trade automation solutions are highly customizable. We understand the importance of tailoring the strategies to your trading style and goals, ensuring optimal performance and desired outcomes.</span>

                                                    </Typography>

                                                </AccordionDetails>
                                            </Accordion>
                                            <Accordion className="faq-list">
                                                <AccordionSummary
                                                    expandIcon={<ExIcon />}
                                                    aria-controls="panel1a-content"
                                                    id="panel1a-header"
                                                    className="summary">
                                                    <h2 className="summary-content">
                                                        <span className="query-content">Is trade automation suitable for beginners in trading</span></h2>
                                                </AccordionSummary>
                                                <AccordionDetails className="faq-details">
                                                    <Typography className="padding-left">Yes, trade automation can be beneficial for both experienced traders and beginners. It eliminates the need for in-depth market knowledge and emotional decision-making, providing a more systematic and disciplined approach to trading.</Typography>
                                                </AccordionDetails>
                                            </Accordion>

                                            <div className="child">
                                                <Accordion className="faq-list">
                                                    <AccordionSummary
                                                        expandIcon={<ExIcon />}
                                                        aria-controls="panel1a-content"
                                                        id="panel1a-header"
                                                        className="summary">
                                                        <h2 className="summary-content">
                                                            <span className="query-content">How secure is the trade automation system?</span></h2>
                                                    </AccordionSummary>
                                                    <AccordionDetails className="faq-details">
                                                        <Typography className="padding-left">At Ionixx, we prioritize the security and integrity of our clients' trading activities. Our automated systems are designed with robust security measures, encrypted connections, and stringent data protection protocols to ensure your trades and sensitive information are safeguarded.
                                                            
                                                        </Typography>
                                                    </AccordionDetails>
                                                </Accordion>
                                                <Accordion className="faq-list">
                                                    <AccordionSummary
                                                        expandIcon={<ExIcon />}
                                                        aria-controls="panel1a-content"
                                                        id="panel1a-header"
                                                        className="summary">
                                                        <h2 className="summary-content">
                                                            <span className="query-content">Can I monitor and manage my automated trades in real-time?</span></h2>
                                                    </AccordionSummary>
                                                    <AccordionDetails className="faq-details">
                                                        <Typography className="padding-left">
                                                        Absolutely! Our trade automation solutions provide real-time monitoring and management capabilities. You can track the performance of your automated trades, modify settings, and intervene if necessary, ensuring you remain in control.
                                                        </Typography>
                                                    </AccordionDetails>
                                                </Accordion>
                                                <Accordion className="faq-list">
                                                    <AccordionSummary
                                                        expandIcon={<ExIcon />}
                                                        aria-controls="panel1a-content"
                                                        id="panel1a-header"
                                                        className="summary">
                                                        <h2 className="summary-content">
                                                            <span className="query-content">
                                                            Are there any hidden fees or charges associated with using Ionixx's trade automation solutions?
                                                            </span></h2>
                                                    </AccordionSummary>
                                                    <AccordionDetails className="faq-details">
                                                        <Typography className="padding-left" >
                                                        No, we maintain the utmost transparency in our services. Our pricing structure is clear and comprehensive, with no hidden fees or charges. You can focus on your trades without worrying about unexpected costs.
                                                        </Typography>
                                                    </AccordionDetails>
                                                </Accordion>

                                                <Accordion className="faq-list">
                                                    <AccordionSummary
                                                        expandIcon={<ExIcon />}
                                                        aria-controls="panel1a-content"
                                                        id="panel1a-header"
                                                        className="summary" >
                                                        <h2 className="summary-content">
                                                            <span className="query-content">
                                                            Can I combine automated trading with manual trading?
                                                            </span></h2>
                                                    </AccordionSummary>
                                                    <AccordionDetails className="faq-details">
                                                        <Typography className="padding-left">
                                                        Yes, you have the flexibility to integrate automated trading strategies alongside manual trading. Our solutions can complement your existing trading practices, providing an additional layer of efficiency and precision.   
                                                        </Typography>
                                                    </AccordionDetails>
                                                </Accordion>

                                                <Accordion className="faq-list">
                                                    <AccordionSummary
                                                        expandIcon={<ExIcon />}
                                                        aria-controls="panel1a-content"
                                                        id="panel1a-header"
                                                        className="summary" >
                                                        <h2 className="summary-content">
                                                            <span className="query-content">
                                                            Can trade automation guarantee profits?
                                                            </span></h2>
                                                    </AccordionSummary>
                                                    <AccordionDetails className="faq-details">
                                                        <Typography className="padding-left">
                                                        While trade automation can enhance trading efficiency and improve decision-making, it does not guarantee profits. The success of your trades will depend on various factors, including market conditions, trading strategies, and risk management.  
                                                        </Typography>
                                                    </AccordionDetails>
                                                </Accordion>

                                                <Accordion className="faq-list">
                                                    <AccordionSummary
                                                        expandIcon={<ExIcon />}
                                                        aria-controls="panel1a-content"
                                                        id="panel1a-header"
                                                        className="summary" >
                                                        <h2 className="summary-content">
                                                            <span className="query-content">
                                                            How can trade automation benefit large financial institutions and businesses?
                                                            </span></h2>
                                                    </AccordionSummary>
                                                    <AccordionDetails className="faq-details">
                                                        <Typography className="padding-left">
                                                        For large financial institutions and businesses, trade automation brings enhanced scalability, efficiency, and risk management capabilities. It allows them to handle large-scale trading volumes, reduce human error, and execute trades seamlessly across multiple markets.
                                                        </Typography>
                                                    </AccordionDetails>
                                                </Accordion>

                                            </div>

                                        </div>

                                        <div className="btn-wrp">
                                            <button className="btn" onClick={() => { this.setState({ showMore: !this.state.showMore }) }}>{this.state.showMore ? 'View Less' : 'View More'} </button>
                                        </div>



                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    
                    

                    








                    <ContactUS show={this.state.showContactUS} handleClose={() => this.setState({ showContactUS: false })} />
                </div>
            </>
        );
    }

}


const mapStateToProps = (state, ownProps) => {
    return state.commonReducer;
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return bindActionCreators(contactAction, dispatch);
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(TradeAutomation);
function ExIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="14" viewBox="0 0 24 14" fill="none">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M23.5227 3.04159L13.1523 13.412C12.5159 14.0484 11.4841 14.0484 10.8477 13.412L0.477287 3.04159C-0.159096 2.40521 -0.159096 1.37343 0.477287 0.737051C1.11367 0.100669 2.14545 0.100669 2.78183 0.737051L12 9.95522L21.2182 0.737052C21.8546 0.10067 22.8863 0.10067 23.5227 0.737052C24.1591 1.37343 24.1591 2.40521 23.5227 3.04159Z" fill="#42C2A2" />
        </svg>
    )
}
