import React from 'react';
import "./testimonialsNew.css";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { withRouter } from 'react-router-dom'

class TestimonialsNew extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            pathName: ""
        }
    }
    componentDidMount() {
        let pathName = this.props.location.pathname.split('/')[1]
        this.setState({
            pathName: pathName
        })
    }
    render() {
        var settings = {
            dots: false,
            arrows: true,
            infinite: true,
            autoplay: true,
            speed: 3000,
            speed: 2000,
            autoplaySpeed: 5000,
            pauseOnHover: true,
            slidesToShow: 2,
            slidesToScroll: 1,
            initialSlide: 0,
            responsive: [
                {
                    breakpoint: 2600,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: false
                    }
                },
                {
                    breakpoint: 1900,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        infinite: false,
                        dots: false
                    }
                },
                {
                    breakpoint: 1098,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        infinite: false,
                        dots: true
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        initialSlide: 1,
                        dots: false
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        dots: false
                    }
                }
            ]
        };
        return (
            <div class="testimonials new-testi">
                <div class="section-4 MarketPlace">
                    <div class="custom-container">
                        <div class="mp-common-hold">
                            <div class="main-head-div">
                                <React.Fragment>
                                    <h3 class="main-head-1">
                                        {/* <span class="secondary-span">Our customers love</span> */}
                                        <span class="primary-span">Testimonials</span>
                                    </h3>
                                </React.Fragment>
                            </div>
                            <div class="mp-testimonials">
                                <Slider {...settings} >
                                    {/* <div class="row"> */}
                                    {/* <div class="col-xl-6 xol-lg-6 col-md-12 col-sm-12 testi-col"> */}
                                    {
                                        this.props.restrictData !== true &&
                                        <div class="testi-col">
                                            <div class="card testi-card">
                                                <div class="testi-client-head">
                                                    <div class="testi-comp-logo">
                                                        <img src="/assets/img/health-img/gaadi.png" alt="Gaadi Bazaar" title="Gaadi Bazaar"></img>
                                                    </div>
                                                    <b>Gaadi Bazaar
                                                    </b>
                                                </div>
                                                <div class="testi-review-common">
                                                    <div class="testi-client-review">
                                                        <p>The team has seamlessly implemented the auction and inventory funding functions. They've completely automated processes that were previously manual.</p>
                                                    </div>

                                                </div>
                                                <div class="testi-footer">
                                                    <div class="testi-comp-info">
                                                        <p>Dinesh Kumar</p>
                                                        <p><span class="role">Head Marketing,</span></p>
                                                        <span>Cholamandalam Investment & Finance Company</span>
                                                    </div>
                                                    <div class="testi-detailed-review">
                                                        <a href="https://clutch.co/profile/ionixx-technologies#review-1393131" target="_blank">Read More</a>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {
                                        this.props.restrictData !== true &&
                                        <div class="testi-col">
                                            <div class="card testi-card">
                                                <div class="testi-client-head">
                                                    <div class="testi-comp-logo">
                                                        <img src="/assets/img/health-img/tenshey.png" alt="Tenshey" title="Tenshey"></img>
                                                    </div>
                                                    <b>Tenshey
                                                    </b>
                                                </div>
                                                <div class="testi-review-common">
                                                    <div class="testi-client-review">
                                                        <p>Users praise its transparent layout and intuitive interface. The team added value with their attentive, thorough project management style.</p>
                                                    </div>

                                                </div>
                                                <div class="testi-footer">
                                                    <div class="testi-comp-info">
                                                        <p>Maggie Chan Jones</p>
                                                        <p><span class="role">CEO & Founder,</span></p>
                                                        <span>Tenshey group of <br />
                                                            Company</span>
                                                    </div>
                                                    <div class="testi-detailed-review">
                                                        <a href="https://clutch.co/profile/ionixx-technologies#review-1043809" target="_blank">Read More</a>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>

                                    }

                                    {
                                        this.props.restrictData !== true &&
                                        <div class="testi-col">
                                            <div class="card testi-card">
                                                <div class="testi-client-head">
                                                    <div class="testi-comp-logo">
                                                        <img src="/assets/img/market-place-imgs/bitsian-logo.png" alt="Bitsian" title="Tenshey"></img>
                                                    </div>
                                                    <b>Bitsian Inc
                                                    </b>
                                                </div>
                                                <div class="testi-review-common">
                                                    <div class="testi-client-review">
                                                        <p>The final product is fully-functional and stable. Ionixx Technologies demonstrated a complete commitment to the project, working through nights to adhere to an accelerated timeline.</p>
                                                    </div>

                                                </div>
                                                <div class="testi-footer">
                                                    <div class="testi-comp-info">
                                                        <p>Steven Brucato</p>
                                                        <p><span class="role">CEO & Founder,</span></p>
                                                        <span>Bitsian Inc <br />
                                                            Company</span>
                                                    </div>
                                                    <div class="testi-detailed-review">
                                                        <a href="https://clutch.co/profile/ionixx-technologies#review-989469" target="_blank">Read More</a>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>

                                    }
                                    {
                                        this.props.restrictData !== true &&
                                        <div class="testi-col">
                                            <div class="card testi-card">
                                                <div class="testi-client-head">
                                                    <div class="testi-comp-logo">
                                                        <img src="/assets/img/market-place-imgs/ribbon-logo.png" alt="Ribbon Communication" title="Ribbon Communication"></img>
                                                    </div>
                                                    <b>Ribbon Communication
                                                    </b>
                                                </div>
                                                <div class="testi-review-common">
                                                    <div class="testi-client-review">
                                                        <p>While the project is still ongoing, the results thus far have met the expectations of the internal team. Ionixx Technologies communicates regularly with the client to ensure both teams are on the same page.</p>
                                                    </div>

                                                </div>
                                                <div class="testi-footer">

                                                    <div class="testi-comp-info">
                                                        <p>Mark Kujanpaa</p>
                                                        <p><span class="role">Services Product Manager,</span>
                                                            <span>Ribbon Communication</span></p>
                                                    </div>
                                                    <div class="testi-detailed-review">
                                                        <a href="https://clutch.co/profile/ionixx-technologies#review-1472009" target="_blank">Read More</a>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>

                                    }

                                    {
                                        this.props.restrictData !== true &&
                                        <div class="testi-col">
                                            <div class="card testi-card">
                                                <div class="testi-client-head">
                                                    <div class="testi-comp-logo">
                                                        <img src="/assets/img/market-place-imgs/default-logo.svg" alt="Venture Debt Provider" title="Venture Debt Provider"></img>
                                                    </div>
                                                    <b>Venture Debt Provider
                                                    </b>
                                                </div>
                                                <div class="testi-review-common">
                                                    <div class="testi-client-review">
                                                        <p>The platform has greatly benefitted the business. Through their committed work ethic and proactive, efficient management, Ionixx delivers satisfactory results.</p>
                                                    </div>

                                                </div>
                                                <div class="testi-footer">

                                                    <div class="testi-comp-info">
                                                        <p>Brad C. Johnson</p>
                                                        <p><span class="role">Director of Technology,</span><span>Venture Debt Provider</span></p>
                                                    </div>
                                                    <div class="testi-detailed-review">
                                                        <a href="https://clutch.co/profile/ionixx-technologies#review-542475" target="_blank">Read More</a>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>

                                    }
                                    {
                                        this.props.restrictData !== true &&
                                        <div class="testi-col">
                                            <div class="card testi-card">
                                                <div class="testi-client-head">
                                                    <div class="testi-comp-logo">
                                                    <img src="/assets/img/market-place-imgs/default-logo.svg" alt="Muster Technologies Inc" title="Muster Technologies Inc"></img>
                                                    </div>
                                                    <b>Muster Technologies Inc
                                                    </b>
                                                </div>
                                                <div class="testi-review-common">
                                                    <div class="testi-client-review">
                                                    <p>Ionixx Technologies developed solutions that stabilized and improved the app, resulting in high satisfaction from investors. The abundant number of users give the app high ratings thanks to the steady UX.</p>
                                                    </div>

                                                </div>
                                                <div class="testi-footer">

                                                <div class="testi-comp-info">
                                                    <p>Inderjit Vasudevan </p>
                                                    <p><span class="role">CEO,</span><span>Muster Technologies Inc</span></p>
                                                </div>
                                                    <div class="testi-detailed-review">
                                                    <a href="https://clutch.co/profile/ionixx-technologies#review-514594" target="_blank">Read More</a>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>

                                    }
                                      {
                                        this.props.restrictData !== true &&
                                        <div class="testi-col">
                                            <div class="card testi-card">
                                                <div class="testi-client-head">
                                                    <div class="testi-comp-logo">
                                                    <img src="/assets/img/market-place-imgs/default-logo.svg" alt="Harmoni" title="Harmoni"></img>
                                                    </div>
                                                    <b>Harmoni
                                                    </b>
                                                </div>
                                                <div class="testi-review-common">
                                                    <div class="testi-client-review">
                                                    <p>The app had an impressive and user-friendly UX/UI. As a first version, it fulfilled requirements and had the decks needed for its subsequent iterations.</p>
                                                    </div>

                                                </div>
                                                <div class="testi-footer">

                                                <div class="testi-comp-info">
                                                    <p>Megan Majd</p>
                                                    <p><span class="role">Founder & CEO,</span><span>Harmoni</span></p>
                                                </div>
                                                    <div class="testi-detailed-review">
                                                    <a href="https://clutch.co/profile/ionixx-technologies#review-454095" target="_blank">Read More</a>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>

                                    }
                                    
                                  


                                    {/* </div> */}
                                </Slider>

                            </div>

                            {/* <div className="more-text mt-5">
							    {/* <a href="">
								<Link className="nav-link" to={ResourceRoute.WHITEPAPER}>Read more about our Microservices white paper</Link>
								</a> 
                                <a href="https://clutch.co/profile/ionixx-technologies" target="_blank">Read more testimonials on </a>
                                <a href="https://clutch.co/profile/ionixx-technologies" target="_blank" className="clutch-logo">
                                    <img src="/assets/img/clutch.png" alt="clutch" title="clutch"></img>
                                </a>
						</div> */}
                        </div>
                    </div>
                </div>
                {/* section 4 ends */}
            </div>
        );
    }

}

export default withRouter(TestimonialsNew);
