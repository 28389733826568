import React from "react";
import Zoom from "react-reveal/Fade";

const OverView = ({opacity, page}) => {

    return (<section id={'discovery'} className={"section-2 beige-bg company-info"+(page===0?"":"")} style={{opacity: opacity}}>
            <div className={"custom-container " + ((page === 0 || page === 2 ) ? "beige-bg " : "blue-bg ")+(page === 4 ? "d-none" : "d-block")}>
                    <div className="custom-section">
                        <div className="row ww-row">
                            <div className="main-head-div custom-main-head-div">
                                <h2 className="main-head-1">
                                    <span className="secondary-span">Here’s what you can expect</span>
                                    <span className="primary-span">working with us</span>
                                </h2>
                            </div>
                        </div>
                    </div>
                </div>

            <div className="custom-container">
                <div className="custom-section">
                    {/* <Zoom delay={400} key={page} top when={page === 0}> */}
                    <div className="row work-with-row">
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 des-tab-resp">
                            <div className="left-info-hold">
                                <div className="overal-sec">
                                    <div className="design-section">
                                        <div className="step-count step-div">
                                            <h3>Step 1</h3>
                                        </div>
                                        <div className="highlight-text">
                                            <h4><span>Discovery</span></h4>
                                        </div>
                                        <div className="main-para-div">
                                            <p>Good design starts with understanding the problem that needs to be
                                                solved.</p>
                                            <p>Our design team guides you through a discovery process to understand your
                                                product requirements and the value you wish to create for end users.</p>
                                        </div>
                                    </div>
                                    <div className="design-section mt-3">
                                        <div className="step-div">
                                            <h6>This leads to →</h6>
                                        </div>
                                        <div className="highlight-blue">
                                            <h4>Alignment</h4>
                                        </div>
                                        <div className="main-para-div">
                                            <p>Grounding our work in your context helps ensure we understand your vision
                                                from the start.</p>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 comp-info-right-col">
                            <div className="right-img-hold">
                                <div className="comp-info-right-img">
                                            <span className="company-offer-imgs">
                                                <img className="img-width" src="/assets/img/design-img/discovery.svg" alt="Discovery" title="Discovery"/>
                                            </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* </Zoom> */}
                </div>
            </div>
        </section>
    )
}

export default OverView
