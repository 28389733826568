import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { ROUTES } from "../../routes";
import { ResourceRoute } from "../../pages/resources/resource-routing";
import { Button, Collapse } from "react-bootstrap";
import "./menu.css";
import { withRouter } from "react-router-dom";

const styles = {
  overlay: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    overflow: "hidden",
    backgroundColor: "gray",
  },
};

const SideMenu = ({ showMenu = false, dismissMenu, closeMenu, location }) => {
  const subMenuRef = useRef(null);
  const [openSolution, setOpenSolution] = useState(false);
  const [openDesign, setOpenDesign] = useState(false);
  const [openResource, setOpenResource] = useState(false);
  const [openFintech, setOpenFintech] = useState(false);
  const [currentRoute, setCurrentRoute] = useState("");
  const [openMenu, setOpenMenu] = useState(false);
  const [webCloseMenu, setWebMenuClose] = useState(closeMenu);
  const [secOpenMenu, setSecOpenMenu] = useState(false);

  useEffect(() => {
    // console.log('location', location.pathname);
    setCurrentRoute(location.pathname);
    setWebMenuClose(false);
    // window.addEventListener("scroll", solutionMenu);
  });

  // useEffect(() => {
  //     if(document.getElementById('web-menu')){
  //         function handleOutsideClick(e){
  //             if (subMenuRef.current && !subMenuRef.current.contains(e.target)) {
  //                  setOpenMenu(false);
  //                 //  document.body.classList.toggle('modal-open');
  //             }
  //         }
  //         document.addEventListener("mouseover" , handleOutsideClick);
  //     }
  // },[openMenu])

  function solutionMenu() {
    document.addEventListener("mouseover", setOpenMenu(!openMenu));
    // setOpenMenu(!openMenu);
  }
  function fintectMenu() {
    setSecOpenMenu(!secOpenMenu);
  }
  function checkMenuOpen() {
    setOpenMenu(false);
  }
  return (
    <div className="ham-menu">
      {currentRoute !== ROUTES.SUBSCRIBE &&
      currentRoute !== ROUTES.MODERNPOSTTRADE &&
      currentRoute !== ROUTES.POSTTRADESERVICES &&
      currentRoute !== ROUTES.DEFISERVICE &&
      currentRoute !== ROUTES.REQUESTDEMOCALL &&
      currentRoute !== ROUTES.REQUESTDEMOCALLDEFI && 
      currentRoute !== ROUTES.REQUESTDEMOCALL1 ? (
        <div className="web-menu-list" id="web-menu">
          <div className="menu-list">
            <ul>
            <li className="dropdown">
                <Link
                  className={currentRoute === ROUTES.DESIGNSTUDIO ? "" : ""}
                  to={"#"}
                >
                  Design studio
                  <i>
                    <img
                      src="/assets/img/health-img/white-arrow.svg"
                      className="white-arrow"
                    />
                    <img
                      src="/assets/img/health-img/blue-arrow.svg"
                      className="blue-arrow"
                    />
                  </i>
                </Link>
                <ul className="sub-menu">
                <li>
                    <Link to={ROUTES.DESIGNSTUDIO}>Studio iX</Link>
                  </li>
                  <li>
                    <Link to={ROUTES.DESIGNPROCESS}>Design Process</Link>
                  </li>
                  <li>
                    <Link to={ROUTES.DESIGNOURWORK}>Our Work</Link>
                  </li>
                  <li>
                    <a href={"https://blog.ionixxtech.com/category/design/"}>Our Blog</a>
                  </li>
                </ul>
              </li>
              <li className="dropdown">
                <Link
                  to={"#"}
                  className={
                    currentRoute === ROUTES.FINTECH ||
                    currentRoute === ROUTES.HEALTHCARE ||
                    currentRoute === ROUTES.BlOCKCHAIN_NEW
                      ? "active"
                      : ""
                  }
                  href="#"
                >
                  Solutions
                  <i>
                    <img
                      src="/assets/img/health-img/white-arrow.svg"
                      className="white-arrow"
                    />
                    <img
                      src="/assets/img/health-img/blue-arrow.svg"
                      className="blue-arrow"
                    />
                  </i>
                </Link>
                <ul className="sub-menu">
                  {/* <li>
                    <Link to={ROUTES.DESIGN}>Design</Link>
                  </li> */}
                  <li>
                    <Link to={ROUTES.HEALTHCARE}>HealthTech</Link>
                  </li>
                  <li>
                    <Link to={ROUTES.BlOCKCHAIN_NEW}>Web3</Link>
                  </li>
                  <li className="sec-dropdown">
                    <Link>
                      FinTech
                      <i>
                        <img
                          src="/assets/img/health-img/white-arrow.svg"
                          className="white-arrow"
                        />
                        <img
                          src="/assets/img/health-img/blue-arrow.svg"
                          className="blue-arrow"
                        />
                      </i>
                    </Link>
                    <ul className="sec-sub-menu">
                      <li>
                        <Link to={ROUTES.FINTECH}>Financial Solutions</Link>
                      </li>
                      <li>
                        <Link to={ROUTES.DIGITALBROKERAGE}>
                          Digital Brokerage
                        </Link>
                      </li>
                      <li>
                        <Link to={ROUTES.OMS}>OMS</Link>
                      </li>
                      <li>
                        <Link to={ROUTES.POSTTRADE}>Post Trade</Link>
                      </li>
                      <li>
                        <Link to={ROUTES.CAPITAL_MARKET}>Capital Markets</Link>
                      </li>
                      <li>
                        <Link to={ROUTES.TRADE_AUTOMATION}>Trade Automation</Link>
                      </li>
                      <li>
                        <Link to={ROUTES.TRADE_SURVEILLANCE}>Trade Surveillance</Link>
                      </li>
                      {/* <li>
                        <Link to={ROUTES.CRYPTODESIGN}>
                          Crypto Trading Solutions
                        </Link>
                      </li> */}
                    </ul>
                  </li>
                </ul>
              </li>
              <li onClick={checkMenuOpen}>
                <Link
                  className={currentRoute === ROUTES.TECHNOLOGY ? "active" : ""}
                  to={ROUTES.TECHNOLOGY}
                >
                  Technology
                </Link>
              </li>
              <li onClick={checkMenuOpen}>
                <Link
                  className={currentRoute === ROUTES.PORTFOLIO ? "active" : ""}
                  to={ROUTES.PORTFOLIO}
                >
                  Work
                </Link>
              </li>
              <li onClick={checkMenuOpen}>
                <Link
                  className={currentRoute === ROUTES.ABOUTUS ? "active" : ""}
                  to={ROUTES.ABOUTUS}
                >
                  Company
                </Link>
              </li>
              <li onClick={checkMenuOpen} className="dropdown">
                <Link to={ResourceRoute.EVENTSNEWS_TAB}>
                  Resources
                  <i>
                    <img
                      src="/assets/img/health-img/white-arrow.svg"
                      className="white-arrow"
                    />
                    <img
                      src="/assets/img/health-img/blue-arrow.svg"
                      className="blue-arrow"
                    />
                  </i>
                </Link>
                <ul className="sub-menu">
                  <li>
                    <a href={"https://blog.ionixxtech.com/"}>Blog</a>
                  </li>
                  <li>
                    <Link to={ResourceRoute.EVENTSNEWS_TAB}>Events & News</Link>
                  </li>
                  <li>
                    <Link to={ResourceRoute.WHITEPAPER_TAB}>
                      WhitePapers & E-Books
                    </Link>
                  </li>
                  <li>
                    <Link to={ResourceRoute.NEWSLETTER_TAB}>Newsletter</Link>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div>
      ) : (
        ""
      )}

      <div
        style={{
          position: "fixed",
          left: 0,
          right: 0,
          bottom: 0,
          top: 0,
          "background-color": "gray",
          width: "100%",
          height: "100%",
          zIndex: 10002,
          transition: "0.3s ease",
          opacity: showMenu ? 0.3 : 0,
          display: showMenu ? "" : "none",
        }}
        onClick={closeMenu}
      ></div>
      <div className="show-mobile-menu">
        {showMenu ? (
          <div className={"side-menu-container"}>
            <img
              className="side-close"
              src={"../../assets/img/header/closeOrange.svg"}
              alt="close menu"
              onClick={closeMenu}
            />
            <div className="menu-list">
              <ul className="nav flex-column">
                <li
                  className={
                    "nav-item dropdown " +
                    (currentRoute === ROUTES.HEALTHCARE ||
                    currentRoute === ROUTES.FINTECH ||
                    currentRoute === ROUTES.MARKETPLACE ||
                    currentRoute === ROUTES.BlOCKCHAIN_NEW ||
                    currentRoute === ROUTES.BROKERAGE ||
                    openSolution
                      ? "open"
                      : "")
                  }
                  onClick={() => {
                    if (
                      !(
                        currentRoute === ROUTES.HEALTHCARE ||
                        currentRoute === ROUTES.FINTECH ||
                        currentRoute === ROUTES.MARKETPLACE ||
                        currentRoute === ROUTES.BlOCKCHAIN_NEW ||
                        currentRoute === ROUTES.BROKERAGE
                      )
                    ) {
                      setOpenSolution(!openSolution);
                    }
                  }}
                >
                  <div
                    className={"nav-link dropdown-toggle"}
                    aria-controls="solution-collapse"
                    aria-expanded={openSolution}
                  >
                    Solutions
                  </div>
                  <Collapse
                    in={
                      currentRoute === ROUTES.HEALTHCARE ||
                      currentRoute === ROUTES.FINTECH ||
                      currentRoute === ROUTES.MARKETPLACE ||
                      currentRoute === ROUTES.BlOCKCHAIN_NEW ||
                      currentRoute === ROUTES.BROKERAGE
                        ? true
                        : openSolution
                    }
                  >
                    <div id={"solution-collapse"}>
                      <Link
                        className={`nav-link ${
                          currentRoute == ROUTES.DIGITALBROKERAGE ||
                          currentRoute == ROUTES.FINTECH
                            ? "active"
                            : ""
                        }`}
                        to={ROUTES.FINTECH}
                        onClick={() => {
                          if (
                            !(
                              currentRoute === ROUTES.HEALTHCARE ||
                              currentRoute === ROUTES.DIGITALBROKERAGE ||
                              currentRoute === ROUTES.FINTECH ||
                              currentRoute === ROUTES.MARKETPLACE ||
                              currentRoute === ROUTES.BlOCKCHAIN_NEW
                            )
                          ) {
                            setOpenFintech(openFintech);
                          }
                        }}
                      >
                        <div
                          className={"dropdown-toggle sub-dropdown-toggle"}
                          aria-controls="solution-collapse"
                          aria-expanded={openFintech}
                        >
                          FinTech
                        </div>
                        <Collapse
                          in={
                            currentRoute === ROUTES.FINTECH ||
                            currentRoute === ROUTES.DIGITALBROKERAGE
                              ? true
                              : openFintech
                          }
                        >
                          <Link
                            className={`nav-link sub-link ${
                              currentRoute == ROUTES.DIGITALBROKERAGE
                                ? "active"
                                : ""
                            }`}
                            to={ROUTES.DIGITALBROKERAGE}
                            onClick={dismissMenu}
                          >
                            Digital Brokerage
                          </Link>
                          {/* <Link
                                            className={`nav-link sub-link ${currentRoute == ROUTES.OMS ? 'active' : ''}`}
                                            to={ROUTES.OMS}
                                            onClick={dismissMenu}>OMS</Link> */}
                        </Collapse>
                      </Link>

                      {/* <Link
                                            className={`nav-link ${currentRoute == ROUTES.FINTECH ? 'active' : ''}`}
                                            to={ROUTES.FINTECH}
                                            onClick={dismissMenu}>FinTech</Link> */}
                      <Link
                        className={`nav-link ${
                          currentRoute == ROUTES.HEALTHCARE ? "active" : ""
                        }`}
                        to={ROUTES.HEALTHCARE}
                        onClick={dismissMenu}
                      >
                        HealthTech
                      </Link>
                      <Link
                        className={`nav-link ${
                          currentRoute == ROUTES.BlOCKCHAIN_NEW ? "active" : ""
                        }`}
                        to={ROUTES.BlOCKCHAIN_NEW}
                        onClick={dismissMenu}
                      >
                        Web3
                      </Link>

                      {/* <Link
                                            className={`nav-link ${currentRoute == ROUTES.BLOCKCHAIN ? 'active' : ''}`}
                                            to={ROUTES.BLOCKCHAIN}
                                            onClick={dismissMenu}>Blockchain & Crypto</Link>       */}
                      {/* <Link className={`nav-link ${currentRoute == ROUTES.MARKETPLACE ? 'active' : ''}`}
                                              to={ROUTES.MARKETPLACE} onClick={dismissMenu}>Online
                                            Marketplace</Link> */}
                    </div>
                  </Collapse>
                </li>
                <li className="nav-item" onClick={dismissMenu}>
                  <Link
                    className={`nav-link ${
                      currentRoute == ROUTES.DESIGN ? "active" : ""
                    }`}
                    to={ROUTES.DESIGN}
                  >
                    Design
                  </Link>
                </li>
                <li className="nav-item" onClick={dismissMenu}>
                  <Link
                    className={`nav-link ${
                      currentRoute == ROUTES.TECHNOLOGY ? "active" : ""
                    }`}
                    to={ROUTES.TECHNOLOGY}
                  >
                    Development
                  </Link>
                </li>

                <li className="nav-item" onClick={dismissMenu}>
                  <Link
                    className={`nav-link ${
                      currentRoute == ROUTES.PORTFOLIO ? "active" : ""
                    }`}
                    to={ROUTES.PORTFOLIO}
                  >
                    Work
                  </Link>
                </li>
                <li className="nav-item" onClick={dismissMenu}>
                  <Link
                    className={`nav-link ${
                      currentRoute == ROUTES.ABOUTUS ? "active" : ""
                    }`}
                    to={ROUTES.ABOUTUS}
                  >
                    Company
                  </Link>
                </li>
                <li className="nav-item" onClick={dismissMenu}>
                  <Link className="nav-link" to={ROUTES.RESOURCES + "/events"}>
                    Resources
                  </Link>
                </li>
                <li className="nav-item" onClick={dismissMenu}>
                  <Link
                    className={`nav-link ${
                      currentRoute == ROUTES.CAREERS ? "active" : ""
                    }`}
                    to={ROUTES.CAREERS}
                  >
                    Careers
                  </Link>
                </li>
                <li className="nav-item" onClick={dismissMenu}>
                  <Link
                    className={`nav-link ${
                      currentRoute == ROUTES.CONTACTUS ? "active" : ""
                    }`}
                    to={ROUTES.CONTACTUS}
                  >
                    Contact us
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        ) : null}
      </div>
      {showMenu ? (
        <div className={"side-menu-container"}>
          <img
            className="side-close"
            src={"../../assets/img/header/closeOrange.svg"}
            alt="close menu"
            onClick={closeMenu}
          />
          <div className="menu-list">
            <ul className="nav flex-column">
              <li className={
                  "nav-item dropdown " +
                  (currentRoute === ROUTES.DESIGNSTUDIO ||
                  currentRoute === ROUTES.DESIGNPROCESS ||
                  currentRoute === ROUTES.DESIGNOURWORK ||
                  openDesign
                    ? "open"
                    : "")}
                    onClick={() => {
                      if (
                        !(
                          currentRoute === ROUTES.DESIGNSTUDIO ||
                          currentRoute === ROUTES.DESIGNPROCESS ||
                          currentRoute === ROUTES.DESIGNOURWORK 
                        )
                      ) {
                        setOpenDesign(!openDesign);
                      }
                    }}>
                  <div
                  className={"nav-link dropdown-toggle"}
                  aria-controls="design-collapse"
                  aria-expanded={openDesign}
                >
                  Design Studio
                  </div>
                  <Collapse
                  in={
                    currentRoute === ROUTES.DESIGNSTUDIO ||
                    currentRoute === ROUTES.DESIGNPROCESS ||
                    currentRoute === ROUTES.DESIGNOURWORK
                      ? true
                      : openDesign
                  }
                >
                  <div id={"design-collapse"}>
                    <Link className={`nav-link ${
                        currentRoute == ROUTES.DESIGNSTUDIO ? "active" : ""
                      }`} to={ROUTES.DESIGNSTUDIO} onClick={dismissMenu}>Studio Ix</Link>
                      <Link className={`nav-link ${
                        currentRoute == ROUTES.DESIGNPROCESS ? "active" : ""
                      }`} to={ROUTES.DESIGNPROCESS} onClick={dismissMenu}>Design Process</Link>
                      <Link className={`nav-link ${
                        currentRoute == ROUTES.DESIGNOURWORK ? "active" : ""
                      }`} to={ROUTES.DESIGNOURWORK} onClick={dismissMenu}>Our Work</Link>
                      <a
                      href={"https://blog.ionixxtech.com/category/design"}
                      className="nav-link"
                    >
                      Our Blog
                    </a>
                    </div>
                    </Collapse>
                    </li>
              <li
                className={
                  "nav-item dropdown " +
                  (currentRoute === ROUTES.HEALTHCARE ||
                  currentRoute === ROUTES.FINTECH ||
                  currentRoute === ROUTES.MARKETPLACE ||
                  currentRoute === ROUTES.BlOCKCHAIN_NEW ||
                  currentRoute === ROUTES.BROKERAGE ||
                  currentRoute === ROUTES.BROKERAGE ||
                  openSolution
                    ? "open"
                    : "")
                }
                onClick={() => {
                  if (
                    !(
                      currentRoute === ROUTES.HEALTHCARE ||
                      currentRoute === ROUTES.FINTECH ||
                      currentRoute === ROUTES.MARKETPLACE ||
                      currentRoute === ROUTES.BlOCKCHAIN_NEW ||
                      currentRoute === ROUTES.BROKERAGE
                    )
                  ) {
                    setOpenSolution(!openSolution);
                  }
                }}
              >
                <div
                  className={"nav-link dropdown-toggle"}
                  aria-controls="solution-collapse"
                  aria-expanded={openSolution}
                >
                  Solutions
                </div>
                <Collapse
                  in={
                    currentRoute === ROUTES.HEALTHCARE ||
                    currentRoute === ROUTES.FINTECH ||
                    currentRoute === ROUTES.MARKETPLACE ||
                    currentRoute === ROUTES.BlOCKCHAIN_NEW ||
                    currentRoute === ROUTES.BROKERAGE ||
                    currentRoute === ROUTES.DESIGN ||
                    currentRoute === ROUTES.DIGITALBROKERAGE ||
                    currentRoute === ROUTES.OMS
                      ? true
                      : openSolution
                  }
                >
                  <div id={"solution-collapse"}>
                    {/* <Link
                      className={`nav-link ${
                        currentRoute == ROUTES.DESIGN ? "active" : ""
                      }`}
                      to={ROUTES.DESIGN}
                      onClick={dismissMenu}
                    >
                      Design
                    </Link> */}

                    <Link
                      className={`nav-link ${
                        currentRoute == ROUTES.HEALTHCARE ? "active" : ""
                      }`}
                      to={ROUTES.HEALTHCARE}
                      onClick={dismissMenu}
                    >
                      HealthTech
                    </Link>
                    <Link
                      className={`nav-link ${
                        currentRoute == ROUTES.BlOCKCHAIN_NEW ? "active" : ""
                      }`}
                      to={ROUTES.BlOCKCHAIN_NEW}
                      onClick={dismissMenu}
                    >
                      Web3
                    </Link>
                    <Link
                      className={`nav-link ${
                        currentRoute == ROUTES.DIGITALBROKERAGE ||
                        currentRoute == ROUTES.FINTECH ||
                        currentRoute == ROUTES.OMS
                          ? "active"
                          : ""
                      }`}
                      onClick={() => {
                        if (
                          !(
                            currentRoute === ROUTES.DIGITALBROKERAGE ||
                            currentRoute === ROUTES.FINTECH ||
                            currentRoute === ROUTES.OMS
                          )
                        ) {
                          setOpenFintech(!openFintech);
                        }
                      }}
                    >
                      <div
                        className={"dropdown-toggle sub-dropdown-toggle"}
                        aria-controls="fintech-collapse"
                        aria-expanded={openFintech}
                      >
                        FinTech
                      </div>
                      <Collapse
                        in={
                          currentRoute === ROUTES.FINTECH ||
                          currentRoute === ROUTES.DIGITALBROKERAGE ||
                          currentRoute === ROUTES.OMS ||
                          currentRoute === ROUTES.POSTTRADE
                            ? true
                            : openFintech
                        }
                      >
                        <div id={"fintech-collapse"}>
                          <Link
                            className={`nav-link sub-link ${
                              currentRoute == ROUTES.FINTECH ? "active" : ""
                            }`}
                            to={ROUTES.FINTECH}
                            onClick={dismissMenu}
                          >
                            Financial Solutions
                          </Link>
                          <Link
                            className={`nav-link sub-link ${
                              currentRoute == ROUTES.DIGITALBROKERAGE
                                ? "active"
                                : ""
                            }`}
                            to={ROUTES.DIGITALBROKERAGE}
                            onClick={dismissMenu}
                          >
                            Digital Brokerage
                          </Link>
                          <Link
                            className={`nav-link sub-link ${
                              currentRoute == ROUTES.OMS ? "active" : ""
                            }`}
                            to={ROUTES.OMS}
                            onClick={dismissMenu}
                          >
                            OMS
                          </Link>
                          <Link
                            className={`nav-link sub-link ${
                              currentRoute == ROUTES.POSTTRADE ? "active" : ""
                            }`}
                            to={ROUTES.POSTTRADE}
                            onClick={dismissMenu}
                          >
                            Post Trade
                          </Link>
                          <Link
                            className={`nav-link sub-link ${
                              currentRoute == ROUTES.CAPITAL_MARKET ? "active" : ""
                            }`}
                            to={ROUTES.CAPITAL_MARKET}
                            onClick={dismissMenu}
                          >
                            Capital Markets
                          </Link>
                          <Link
                            className={`nav-link sub-link ${
                              currentRoute == ROUTES.TRADE_AUTOMATION ? "active" : ""
                            }`}
                            to={ROUTES.TRADE_AUTOMATION}
                            onClick={dismissMenu}
                          >
                            Trade Automation
                          </Link>
                          <Link
                            className={`nav-link sub-link ${
                              currentRoute == ROUTES.TRADE_SURVEILLANCE ? "active" : ""
                            }`}
                            to={ROUTES.TRADE_SURVEILLANCE}
                            onClick={dismissMenu}
                          >
                            Trade Surveillance
                          </Link>
                          {/* <Link
                            className={`nav-link sub-link ${
                              currentRoute == ROUTES.CRYPTODESIGN
                                ? "active"
                                : ""
                            }`}
                            to={ROUTES.CRYPTODESIGN}
                            onClick={dismissMenu}
                          >
                            Crypto Trading Solutions
                          </Link> */}
                        </div>
                      </Collapse>
                    </Link>
                    {/* <Link
                                            className={`nav-link ${currentRoute == ROUTES.BLOCKCHAIN ? 'active' : ''}`}
                                            to={ROUTES.BLOCKCHAIN}
                                            onClick={dismissMenu}>Blockchain & Crypto</Link>       */}
                    {/* <Link className={`nav-link ${currentRoute == ROUTES.MARKETPLACE ? 'active' : ''}`}
                                              to={ROUTES.MARKETPLACE} onClick={dismissMenu}>Online
                                            Marketplace</Link> */}
                  </div>
                </Collapse>
              </li>
              <li className="nav-item" onClick={dismissMenu}>
                <Link
                  className={`nav-link ${
                    currentRoute == ROUTES.TECHNOLOGY ? "active" : ""
                  }`}
                  to={ROUTES.TECHNOLOGY}
                >
                  Technology
                </Link>
              </li>

              <li className="nav-item" onClick={dismissMenu}>
                <Link
                  className={`nav-link ${
                    currentRoute == ROUTES.PORTFOLIO ? "active" : ""
                  }`}
                  to={ROUTES.PORTFOLIO}
                >
                  Work
                </Link>
              </li>
              <li className="nav-item" onClick={dismissMenu}>
                <Link
                  className={`nav-link ${
                    currentRoute == ROUTES.ABOUTUS ? "active" : ""
                  }`}
                  to={ROUTES.ABOUTUS}
                >
                  Company
                </Link>
              </li>
              <li
                className={
                  "nav-item dropdown" +
                  (currentRoute === ROUTES.EVENTSNEWS_TAB ||
                  currentRoute === ROUTES.WHITEPAPER_TAB ||
                  currentRoute === ROUTES.NEWSLETTER_TAB ||
                  openResource
                    ? "open"
                    : "")
                }
                onClick={() => {
                  if (
                    !(
                      currentRoute === ROUTES.EVENTSNEWS_TAB ||
                      currentRoute === ROUTES.WHITEPAPER_TAB ||
                      currentRoute === ROUTES.NEWSLETTER_TAB
                    )
                  ) {
                    setOpenResource(!openResource);
                  }
                }}
              >
                <div
                  className={"nav-link dropdown-toggle"}
                  aria-controls="resource-collapse"
                  aria-expanded={openResource}
                >
                  Resources
                </div>
                <Collapse
                  in={
                    currentRoute === ROUTES.EVENTSNEWS_TAB ||
                    currentRoute === ROUTES.WHITEPAPER_TAB ||
                    currentRoute === ROUTES.NEWSLETTER_TAB ||
                    currentRoute === ROUTES.BlOCKCHAIN_NEW ||
                    currentRoute === ROUTES.BROKERAGE ||
                    currentRoute === ROUTES.DESIGN ||
                    currentRoute === ROUTES.DIGITALBROKERAGE ||
                    currentRoute === ROUTES.OMS
                      ? true
                      : openResource
                  }
                >
                  <div id="resource-collapse">
                    <a
                      href={"https://blog.ionixxtech.com/"}
                      className="nav-link"
                    >
                      Blog
                    </a>
                    <Link
                      className={`nav-link ${
                        currentRoute == ROUTES.EVENTSNEWS_TAB ? "active" : ""
                      }`}
                      to={ResourceRoute.EVENTSNEWS_TAB}
                    >
                      Events & News
                    </Link>
                    <Link
                      className={`nav-link ${
                        currentRoute == ROUTES.WHITEPAPER_TAB ? "active" : ""
                      }`}
                      to={ResourceRoute.WHITEPAPER_TAB}
                    >
                      Whitepapers & Ebooks
                    </Link>
                    <Link
                      className={`nav-link ${
                        currentRoute == ROUTES.NEWSLETTER_TAB ? "active" : ""
                      }`}
                      to={ResourceRoute.NEWSLETTER_TAB}
                    >
                      Newsletter
                    </Link>
                  </div>
                </Collapse>
              </li>
            </ul>
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default withRouter(SideMenu);

// export default ;
