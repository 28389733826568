import React from 'react';
import "../../resources.css";
import { ResourceRoute } from '../../resource-routing';
import { Link } from "react-router-dom";
import NewsHeadings from "./common-action/news-heading";
import NewsSubscription from "./common-action/subscripbe-section";

class NewsIssue77 extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            pageEnabled: false,
        }

    }

    render() {
        return (
          <div className="newdesigned_issue_details">
            <NewsHeadings
              buttondDetail="FinTech"
              categoryType="fintech"
              description="'Speculative excess': ethereum finds new peak in sizzling crypto market | Reuters"
            />

            <div className="newsissuedetailed">
              <div className="detailed-common">
                {/* <div className="back-to-common">
                    <span className="back-to-icon-hold">
                        <img src="/assets/img/resource/back-to-new.svg"  alt="back to new"></img>
                    </span>
                    <Link  to={ResourceRoute.NEWSLETTER}><span className="back-to-link">Newsletter</span></Link>
                </div> */}

                <div className="news-letter whats-on-hold">
                  <div className="issue-main-head">
                    <h4>What’s on?</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.theverge.com/2021/5/11/22430827/ebay-nft-collectibles-blockchain-sale"
                      >
                        <h4>
                          You can now buy NFTs on eBay, and ‘blockchain-driven
                          collectibles’ | The Verge
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        eBay is now allowing NFTs to be sold on its platform,
                        making the digital collectibles available side by side
                        with physical ones. Whether you’re looking for a
                        physical Dogecoin replica or a digital representation of
                        Elon Musk holding Doge, eBay is apparently now the place
                        to get both. At the moment, eBay wants to make sure that
                        NFTs are listed by trusted sellers, and only in certain
                        categories like trading cards, music, entertainment, and
                        art.
                      </p>
                    </div>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.reuters.com/technology/ethereum-extends-gains-fresh-record-above-3400-2021-05-04/"
                      >
                        <h4>
                          'Speculative excess': ethereum finds new peak in
                          sizzling crypto market | Reuters
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Cryptocurrency ether rose to a fresh record peak on
                        Tuesday before dropping sharply as some investors pulled
                        profits from a white-hot market bulging with
                        questionable new entrants. A day after blowing past
                        $3,000 for the first time, ether, the token traded over
                        the ethereum blockchain, hit $3,457.64 on the bitstamp
                        exchange before dropping 6% to $3,244.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="news-letter top-picks-hold">
                  <div className="issue-main-head">
                    <h4>Top Picks</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.forbes.com/sites/forbestechcouncil/2021/05/14/how-cryptos-rise-is-paving-the-way-for-technological-innovation/?sh=6ab897867348"
                      >
                        <h4>
                          How Crypto's Rise Is Paving The Way For Technological
                          Innovation | Forbes
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        When Elon Musk recently converted 10% of Tesla’s cash
                        reserves to Bitcoin (over $1.5 billion), the markets
                        went crazy. But the intense buzz about Bitcoin and
                        cryptocurrency is part of a huge growth trend that began
                        last year and that our company expects to continue to
                        have tremendous growth in 2021 based on the insights
                        into market trends that our software provides.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="news-letter other-reads-hold">
                  <div className="issue-main-head">
                    <h4>Other Reads</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.thenationalnews.com/business/technology/quicktake-what-is-darkside-and-how-does-it-operate-1.1220920"
                      >
                        <h4>
                          Quicktake: What is DarkSide and how does it operate? |
                          The National News
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        DarkSide, the criminal group identified by the FBI for
                        being behind the ransomware attack on the Colonial
                        Pipeline, said its aim is to "make money" but not create
                        problems for society. The article looks at the group’s
                        history and how it works. What is DarkSide? DarkSide is
                        a relatively new group that released a ransomware strain
                        which made its first appearance in August. Recently, it
                        announced the release of an advanced version called
                        DarkSide 2.0. The new version is twice as fast as the
                        previous one and encrypts files more quickly than any
                        other ransomware on the market, according to cyber
                        experts.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <NewsSubscription categoryType="FinTech" />
          </div>
        );
    }

}

export default NewsIssue77;
