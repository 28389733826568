import React from 'react';
import "../../resources.css"
import {ResourceRoute} from '../../resource-routing';
import {Link} from "react-router-dom";

class BlockchainDetailed extends React.Component {
    constructor(props){
        super(props)

    }
    render(){
        return (
            <div className="BlockchainDetailed">
                <div className="back-to-common">
                    <span className="back-to-icon-hold">
                        <img src="/assets/img/resource/back-to-new.svg"  alt="bact to new"></img>
                    </span>
                    <Link  to={ResourceRoute.BLOCKCHAIN}><span className="back-to-link">Blockchain Bulletin</span></Link>
                </div>
                <div className="download-common inner-section-1">
                    <div>
                        <div className="main-head-div">
                            <h4 className="main-head-1">
                                <span className="primary-span">February Fortnight 2 Round-up Blockchain & Crypto Brief</span>
                            </h4>
                            <p className="press-info">( February 28, 2020 )</p>
                        </div>
                    </div>    
                </div>
                <div class="bulletin-head-details bulletin-sub-heads">
                    <h4>
                        <span className="primary-span">Finance</span>
                    </h4>
                    <ul className="bulletin-links-ul">
                        <li>
                            <a href="https://finance.yahoo.com/news/binance-p2p-trading-platform-adds-085008770.html" target={'_blank'}>Crypto exchange Binance’s peer-to-peer (P2P) trading platform now supports Russian rubles (RUB). It allows users to buy and sell bitcoin, ether, Tether stable coin, and Binance’s native coin directly with rubles.</a>
                        </li>
                        <li>
                            <a href="https://cointelegraph.com/news/egyptian-national-bank-turns-to-blockchain-to-boost-remittance-business" target={'_blank'}>Egypt’s largest bank, the National Bank of Egypt (NBE), has joined Ripple’s blockchain- based payments network RippleNet to provide more effective channels in the field of remittances.</a>
                        </li>
                        <li>
                            <a href="https://www.theblockcrypto.com/post/56191/cryptocurrency-exchange-fcoin-expects-to-default-on-as-much-as-125m-of-users-bitcoin" target={'_blank'}>In a tell-all blog post titled FCoin Truth, Chinese cryptocurrency exchange FCoin has revealed that it may not be able to pay back around 7,000 to 13,000 BTC (about $67 to 125 million) to its users as the platform has gone default.</a>
                        </li>
                        <li>
                            <a href="https://www.theblockcrypto.com/post/56482/brazils-central-bank-to-launch-instant-qr-code-enabled-payments-system-this-year" target={'_blank'}>Brazil’s central bank is all set to roll out an instant 24/7 payments system called PIX enabled via QR codes. The system will be operational in November and will allow the users to transact instantly via phones.</a>
                        </li>
                        <li>
                            <a href="https://uk.reuters.com/article/us-cenbank-digital-sweden/sweden-starts-testing-worlds-first-central-bank-digital-currency-idUKKBN20E26G" target={'_blank'}>The central bank of Sweden has begun testing its digital currency ‘e-krona.’</a>
                        </li>
                        <li>
                            <a href="https://www.telegraph.co.uk/business/2020/02/22/bank-england-chief-cashier-signals-support-forofficial-cryptocurrency/" target={'_blank'}>The Bank of England’s chief cashier official has signalled at the consideration for digital currencies by central banks being ‘crucial.’</a>
                        </li>
                        <li>
                            <a href="https://uk.finance.yahoo.com/news/crypto-centric-bank-taking-shape-154824290.html" target={'_blank'}>The US may soon get its first bank dedicated to digital assets in the state of Wyoming; efforts underway.</a>
                        </li>
                    </ul>
                </div>
                <div class="bulletin-head-details bulletin-sub-heads">
                    <h4>
                        <span className="primary-span">Law</span>
                    </h4>
                    <ul className="bulletin-links-ul">
                        <li>
                            <a href="https://www.theblockcrypto.com/post/55734/trump-administration-to-release-new-fincen-requirements-for-cryptos-mnuchin-tells-congress" target={'_blank'}>The US Treasury Secretary Steven Mnuchin told Congress that the Trump administration is likely to release new US Financial Crimes Enforcement Network (FinCEN) requirements for cryptocurrencies.</a>
                        </li>
                        <li>
                            <a href="https://uk.finance.yahoo.com/news/irs-inviting-crypto-firms-summit-211513369.html" target={'_blank'}>The Internal Revenue Service (IRS) has invited a number of crypto startups to a summit in DC on March 3 to discuss its existing approach to taxing cryptocurrencies and enforcement efforts according to invitation copies obtained by Bloomberg Tax and CoinDesk.</a>
                        </li>
                        <li>
                            <a href="https://uk.finance.yahoo.com/news/binance-applied-singapore-license-under-061543909.html" target={'_blank'}>Binance’s Singapore unit has applied for a license under the country’s new Payment Services Act that came to effect on January 28.</a>
                        </li>
                    </ul>
                </div>
                <div class="bulletin-head-details bulletin-sub-heads">
                    <h4>
                        <span className="primary-span">Other</span>
                    </h4>
                    <ul className="bulletin-links-ul">
                        <li>
                            <a href="https://cloud.google.com/blog/products/gcp/working-with-hedera-hashgraph-to-support-the-next-generation-of-distributed-ledger-technology" target={'_blank'}>Google has joined the governing body of distributed ledger startup Hedera Hashgraph to support its next generation.</a>
                        </li>
                        <li>
                            <a href="https://www.coindesk.com/trumps-2021-budget-proposal-seeks-to-optimize-crypto-policing" target={'_blank'}>Trump’s budget proposal for fiscal year 2021 seeks to move the U.S. Secret Service back to the Department of Treasury to fight crypto-related crimes</a>
                        </li>
                        <li>
                            <a href="https://www.theblockcrypto.com/linked/55474/blockstack-to-pause-its-app-mining-program-due-to-several-structural-challenges" target={'_blank'}>Decentralized computing platform Blockstack has put its App Mining program to an indefinite pause citing the need to solve three ‘structural’ challenges, which include objectively fair distribution, decentralization, and privacy-preserving analytics.</a>
                        </li>
                        <li>
                            <a href="https://www.theblockcrypto.com/linked/55653/crypto-miner-riot-blockchain-expanding-its-oklahoma-facility" target={'_blank'}>Crypto miner Riot Blockchain is expanding its facility in Oklahoma City by installing over 1,000 Bitmain machines. The company has received an additional 1,067 units of S17 Pro Antiminers from Bitmain, which are expected to increase its mining hashrate by 240%.</a>
                        </li>
                        <li>
                            <a href="https://coinvigilance.com/user-onboarding-platform-authereum-goes-live-today-following-a-1-1m-seed-round/" target={'_blank'}>Authereum, a decentralized apps or dapps onboarding platform, is now live on the Ethereum network following a $1.1 million seed round from investors, such as Coinbase Ventures and Synapse Capital.</a>
                        </li>
                        <li>
                            <a href="https://www.theblockcrypto.com/post/55665/defi-pulse-decentralized-finance-new-listing-system" target={'_blank'}>Decentralized finance analytics site DeFi Pulse has developed a new system that builds the adaptor to obtain statistics to speed up the listing process.</a>
                        </li>
                        <li>
                            <a href="https://www.technologyreview.com/2020/02/11/844871/north-korea-cryptocurrency-mining-monero/" target={'_blank'}>North Korea appears to have stepped up its monero mining efforts as a way to circumvent international sanctions according to a new report.</a>
                        </li>
                        <li>
                            <a href="https://www.theblockcrypto.com/linked/55874/wells-fargo-invests-5m-in-blockchain-analytics-firm-elliptic" target={'_blank'}>Wells Fargo Strategic Capital, an investing division of the third-largest US bank, has invested $5 million in blockchain analytics company Elliptic, which brings its total series B funding to $28 million.</a>
                        </li>
                        <li>
                            <a href="https://cointelegraph.com/news/us-navy-bets-95m-on-blockchain-to-keep-messaging-secret" target={'_blank'}>The US Navy has awarded crypto startup SIMBA Chain with $9.5 million for a new blockchain messaging system, which is expected to make the Navy’s messaging system more secure</a>
                        </li>
                        <li>
                            <a href="https://www.theblockcrypto.com/post/55834/lionel-messi-led-fc-barcelona-set-to-have-its-own-token-for-over-300m-fans-worldwide" target={'_blank'}>Lionel Messi led soccer club FC Barcelona is set to have its own token for over 300 million fans worldwide to give them voting power.</a>
                        </li>
                        <li>
                            <a href="https://uk.finance.yahoo.com/news/irs-removes-ether-two-popular-112039007.html" target={'_blank'}>The Internal Revenue Service (IRS) has removed ether and two popular gaming tokens - Fortnite’s V-bucks and Roblox’s Robux - as examples of convertible virtual currencies.</a>
                        </li>
                        <li>
                            <a href="http://www.cryptoluxguy.com/en/crypto-news/12069/key-management-network-torus-exits-beta-open-sources-basecode" target={'_blank'}>Private key management network Torus has quit beta and open-sourced its codebase for community use.</a>
                        </li>
                        <li>
                            <a href="https://uk.finance.yahoo.com/news/blockchain-based-social-media-platform-160041426.html" target={'_blank'}>The Tron Foundation and Steemit Inc have partnered to work on a blockchain-based social media platform.</a>
                        </li>
                        <li>
                            <a href="https://www.theblockcrypto.com/post/55656/voyager-to-acquire-circles-retail-trading-platform-in-an-all-stock-deal" target={'_blank'}>Crypto brokerage firm Voyager has signed a definitive agreement with Circle to acquire its Circle Invest app. The deal also includes the issuance of Voyager’s common shares to Circle - representing a near 4% ownership stake.</a>
                        </li>
                        <li>
                            <a href="https://www.theblockcrypto.com/linked/56244/2020-presidential-candidate-bloomberg-promises-clearer-rules-on-crypto-taxes-initial-coin-offerings" target={'_blank'}>Presidential candidate Michael Bloomberg has promised clearer regulations for cryptocurrencies and initial coin offerings in his proposed financial reform plan.</a>
                        </li>
                    </ul>
                </div>
            </div>
            
        );
    }

}

export default BlockchainDetailed;
