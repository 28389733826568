import React from 'react';
import "../../resources.css";
import { ResourceRoute } from '../../resource-routing';
import { Link } from "react-router-dom";
import NewsHeadings from "./common-action/news-heading";
import NewsSubscription from "./common-action/subscripbe-section";

class NewsIssue07 extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            pageEnabled: false,
        }

    }

    render() {
        return (
          <div className="newdesigned_issue_details">
            <NewsHeadings
              buttondDetail="Web3 & Blockchain"
              categoryType="web3"
              description="Apple Has Secret Team Working on Satellites to Beam Data to Devices | Bloomberg"
            />

            <div className="newsissuedetailed">
              <div className="detailed-common">
                {/* <div className="back-to-common">
                            <span className="back-to-icon-hold">
                                <img src="/assets/img/resource/back-to-new.svg" alt="bact to new"></img>
                            </span>
                            <Link to={ResourceRoute.NEWSLETTER}><span className="back-to-link">Newsletter</span></Link>
                        </div> */}

                <div className="news-letter whats-on-hold">
                  <div className="issue-main-head">
                    <h4>What’s on?</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.nytimes.com/reuters/2019/12/24/business/24reuters-china-forex-blockchain.html"
                      >
                        <h4>
                          China to Expand Blockchain Pilot, Study Forex Reforms
                          for Cryptocurrency: Regulator | New York Times{" "}
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        China will expand the scope of its blockchain
                        cross-border financing pilot platform, a senior official
                        at the country's foreign exchange regulator said on
                        Tuesday. Lu Lei, deputy head of the State Administration
                        of Foreign Exchange (SAFE) make his remarks at a forum
                        in Beijing, where he said the regulator will strengthen
                        the integration of FinTechand the foreign exchange
                        market, while maintaining a grip on supervising
                        technology development.
                      </p>
                    </div>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.technologyreview.com/2019/12/23/131426/worst-technologies-biggest-technology-failures-2019/"
                      >
                        <h4>
                          The biggest technology failures of 2019 | MIT Tech
                          Review
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        From Autopilot run amok, bogus agriculture bots, and
                        genetic gaydar, check out the technology failures of the
                        year curated by MIT Technology Review.
                      </p>
                    </div>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.coindesk.com/2020-vision-7-trends-bringing-blockchain-into-focus-in-the-year-ahead"
                      >
                        <h4>
                          2020 Vision: 7 Trends Bringing Blockchain Into Focus
                          in the Year Ahead | Coindesk{" "}
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        2020 brings us the start of a new decade, and with it
                        comes the gradual maturation of blockchain technology,
                        bringing it down from the stratosphere to tangible
                        impact on real-world problems. We may be a turning point
                        for the industry. Read these seven trends for 2020, as
                        the industry moves from experimentation to
                        implementation.{" "}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="news-letter top-picks-hold">
                  <div className="issue-main-head">
                    <h4>Top Picks</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.bloomberg.com/news/articles/2019-12-20/apple-has-top-secret-team-working-on-internet-satellites"
                      >
                        <h4>
                          Apple Has Secret Team Working on Satellites to Beam
                          Data to Devices | Bloomberg
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Apple Inc. has a secret team working on satellite
                        technology that the iPhone maker could use to beam
                        internet services directly to devices, bypassing
                        wireless networks, according to people familiar with the
                        work. The Cupertino, California-based iPhone maker has
                        about a dozen engineers from the aerospace, satellite
                        and antenna design industries working on the project
                        with the goal of deploying their results within five
                        years, said the people, who asked not to be identified
                        discussing internal company efforts.{" "}
                      </p>
                    </div>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://fortune.com/longform/facebook-libra-stablecoin-digital-currency-crypto/"
                      >
                        <h4>
                          Why Facebook’s Libra Hangs in Limbo—and What’s Next in
                          the Digital Currency Race | Fortune{" "}
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Banks, other tech companies, and national
                        governments—most notably, China’s—are readying digital
                        currency pilots of their own; Facebook’s Libra stumbles
                        might ease the path for those that come later, or at
                        least force regulators to clarify what they’ll allow.
                        Libra itself, meanwhile, promises to course-correct
                        based on the (often scorching) feedback it has received.
                        How will Libra, or any new currency, satisfy global
                        regulators? What will it look like in its final form?
                        Will Libra be the first globally viable, price-stable
                        e‑cash? Or will someone else beat the association to it?
                        Fortune explores these questions and canvasses the
                        financial and digital worlds for this account.{" "}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="news-letter other-reads-hold">
                  <div className="issue-main-head">
                    <h4>Other Reads</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.nbcnews.com/specials/2019-in-photos/"
                      >
                        <h4>2019’s Year in Pictures | NBCnews.com </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        The beginning of the end of the decade began with chaos
                        at the U.S. border and “yellow vest” protests in Paris.
                        A photograph of a drowned migrant and his daughter
                        shocked the world, and a teen climate activist gained
                        global attention. Sometimes tragic, sometimes thrilling,
                        sometimes amusing, take a look at the images that
                        captivated photo editors in 2019.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <NewsSubscription categoryType="Web3" />
          </div>
        );
    }

}

export default NewsIssue07;
