import React from 'react';
import "../../resources.css";
import { ResourceRoute } from '../../resource-routing';
import { Link } from "react-router-dom";
import NewsHeadings from "./common-action/news-heading";
import NewsSubscription from "./common-action/subscripbe-section";

class NewsIssue25 extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            pageEnabled: false,
        }

    }

    render() {
        return (
          <div className="newdesigned_issue_details">
            <NewsHeadings
              buttondDetail="Web3 & Blockchain"
              categoryType="web3"
              description="Solving the privacy paradox with blockchain tech | The Payers"
            />
            <div className="newsissuedetailed">
              <div className="detailed-common">
                {/* <div className="back-to-common">
                            <span className="back-to-icon-hold">
                                <img src="/assets/img/resource/back-to-new.svg" alt="bact to new"></img>
                            </span>
                            <Link to={ResourceRoute.NEWSLETTER}><span className="back-to-link">Newsletter</span></Link>
                        </div> */}

                <div className="news-letter whats-on-hold">
                  <div className="issue-main-head">
                    <h4>What’s on?</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.vox.com/recode/2020/4/24/21234958/facebook-messenger-rooms-zoom-video-chat-houseparty"
                      >
                        <h4>
                          Facebook’s latest ripoff product is a Zoom clone
                          called “Rooms” | Vox.com{" "}
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Facebook is rolling out its own video conferencing
                        service called Messenger Rooms. The chat tool looks and
                        works a lot like the popular (but troubled) video chat
                        app Zoom. You might even say Facebook ripped off all the
                        good features that made Zoom so successful while dumping
                        the ones that made it so controversial. Facebook does
                        this all the time.Like many other apps, Messenger Rooms
                        lets multiple people video chat at the same time. Rooms
                        allows for up to 50 people to join, which is more than
                        the 32 people who can FaceTime simultaneously and less
                        than the 100 people who can join a Zoom meeting.
                      </p>
                    </div>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.smartcitiesworld.net/news/news/blockchain-platform-developed-for-mobility-as-a-service--5239"
                      >
                        <h4>
                          Blockchain platform developed for
                          mobility-as-a-service | Smart Cities{" "}
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Sony Corporation has announced the development of a
                        blockchain-based common database platform to help
                        facilitate mobility-as-a-service (MaaS) solutions. It
                        follows Sony’s participation in a blockchain challenge
                        from the Netherlands Ministry of Infrastructure and
                        Water Management. MaaS relies on providing users with
                        information regarding optimal routes to desired
                        destinations and recommended multi-modal transportation
                        services. Typically, it presents details including the
                        route, required time and total cost, and covers the
                        entire process from booking to clearing.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="news-letter top-picks-hold">
                  <div className="issue-main-head">
                    <h4>Top Picks</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://thepaypers.com/expert-opinion/solving-the-privacy-paradox-with-blockchain-tech--1242021"
                      >
                        <h4>
                          Solving the privacy paradox with blockchain tech | The
                          Payers
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        Over half of Americans are refusing to use some products
                        or services due to personal privacy concerns, according
                        to survey results published on April 14, 2020 by Pew
                        Research Center, a nonpartisan American think tank based
                        in Washington. In light of this research you would think
                        that we would see Facebook and Google reporting large
                        losses in their subscriber base, but we don’t. Why? It's
                        commonly referred to as the ‘privacy paradox’, as John
                        Naughton wrote in The Guardian, ‘a dark shadow looms
                        over our networked world. It’s called the privacy
                        paradox’.{" "}
                      </p>
                    </div>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.infosecurity-magazine.com/opinions/bad-ux-design/"
                      >
                        <h4>
                          Bad UX Design Always Leads to Compromised Security |
                          Info Security{" "}
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        According to Jared Spool; writer, researcher, and
                        founding principal of User Interface Engineering, “If
                        it’s not usable, it’s not secure.” That is, if you put
                        protections in place on your website and app, but it’s
                        not intuitive to the user (like a randomly generated
                        password they must remember), it will indubitably become
                        insecure. A great example: Kanye’s infamous all-zero
                        passcode to unlock his smartphone device. The passcode
                        system isn’t usable so customers resort to shortcuts,
                        therefore, it’s not secure.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="news-letter other-reads-hold">
                  <div className="issue-main-head">
                    <h4>Other Reads</h4>
                  </div>
                  <div className="issue-main-info">
                    <div className="issue-sub-head">
                      <a
                        target={"_blank"}
                        href="https://www.theguardian.com/world/2020/apr/20/the-antidote-your-favourite-weekend-reads-beyond-coronavirus"
                      >
                        <h4>
                          The antidote: your favourite weekend reads beyond
                          coronavirus{" "}
                        </h4>
                      </a>
                    </div>
                    <div className="issue-news-info">
                      <p>
                        If you’re feeling overwhelmed by coverage of the
                        pandemic, try The Guardian’s list of non-coronavirus
                        articles that the readers spent the most time with over
                        the weekend.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <NewsSubscription categoryType="Web3" />
          </div>
        );
    }

}

export default NewsIssue25;
