import React from 'react';
import Testimonials from "../../components/testimonials-design/testimonials";
import "./portfolio.css"
import {DesignPortfolio, CaseStudies} from './internal-components'
import ContactUS from "../../components/contact-us";
import { ToastComponent } from '../../components/common';
import * as contactAction from "../../action/contactAction";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Helmet from 'react-helmet';
import { Link } from "react-router-dom";
import { ROUTES } from "../../routes";
import Tab from '../../components/common/tab/tab';
class Portfolio extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            headerClassAdd: "",
            selectedMenu: 'portfolio',
            showToast: false
        }
        this.onScrollHeader = this.onScrollHeader.bind(this);
    }
    
    componentDidMount() {
        if(window.factors) {
            window.factors.addUserProperties({'visited_portfolio_page': 'yes'});
        }
        window.addEventListener('scroll', this.onScrollHeader);
    }

    componentWillUnmount() {
        window.removeEventListener('scroll', this.onScrollHeader);
    }

    onScrollHeader() {
        const winScroll =
            document.body.scrollTop || document.documentElement.scrollTop

        const height =
            document.documentElement.scrollHeight -
            document.documentElement.clientHeight

        const scrolled = winScroll / height

        if (scrolled === 0) {
            this.setState({
                headerClassAdd: ""
            })
        } else {
            this.setState({
                headerClassAdd: "scrollHeader"
            })
        }
    }

    renderContent = () => {
        if (this.state.selectedMenu === 'portfolio') {
            return <DesignPortfolio popupCall={()=>{this.sendToast()}}/>
        } else {
            return <CaseStudies popupCall={()=>{this.sendToast()}}/>
        }
    }
    sendToast(){
		this.setState({showContactUS:true})
	}
    render() {
       
        const menu = this.state.selectedMenu;
        return (
            <>	
             <Helmet>
                <meta charSet="utf-8" />
                <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1.0, shrink-to-fit=no, user-scalable=no, maximum-scale=1"
                />
                <title>Portfolio | Ionixx Technologies</title>
                <meta name="description" content="Ionixx developed a RegTech \system that automates regulatory change management to ensure compliance and mitigate risks." />
                <link rel="canonical" href="https://www.ionixxtech.com/portfolio" />
            </Helmet>
  
            <div className="portfolio">
                <div className='section-1'>
                    <div className='custom-container'>
                    <h1>Ionixx Customer Success stories</h1>
                    <p>Since 2012, Ionixx has been leading technology innovation and digital transformation at scale.<br/>
With expertise across industries such as Fintech, HealthTech, Web3, and Cryptocurrency, <br/>
we have empowered our customers to achieve tangible business value through cutting-edge technology solutions.</p>
<div className='top-section-btn'>
    <Link to={ROUTES.CONTACTUS} className="btn btn-primary connect-btn">Talk to us</Link>
</div>
</div>
                </div>
                <div className='section-2'>
                    <div className='custom-container'>
                    <div className="main-head-div">
                                <h4 className="main-head-1">
                                    <span className="primary-span">Helping customer's succeed</span>
                                    <span className="secondary-span">Driving change with technology</span>
                                </h4>
                    </div>
                    <div className='tab-wrapper'>
                    <Tab/>
                    </div>
                    </div>
                </div>
                <div class="section-3 pb-0">
                    <div class="custom-container">
                        <div class="custom-section connect-with-custom-section">
                            <div class="main-head-div mb-0">
                                <h1 class="main-head-1">
                                    <span class="primary-span mb-0">Explore Our Portfolio</span>
                                </h1>
                            </div>

                            {/* <div class="tab-sec">
                                <ul class="nav nav-pills" id="pills-tab" role="tablist">
                                    <li class="nav-item" onClick={() => this.setState({selectedMenu: 'portfolio'})}>
                                        <a className={"nav-link " + (menu == 'portfolio' ? 'active' : '')}
                                           data-toggle="pill" role="tab"
                                           aria-controls="pills-home" aria-selected="false">Portfolio</a>
                                    </li>
                                    <li class="nav-item" onClick={() => this.setState({selectedMenu: 'caseStudies'})}>
                                        <a className={"nav-link " + (menu == 'caseStudies' ? 'active' : '')}
                                           data-toggle="pill" role="tab" aria-controls="pills-profile"
                                           aria-selected="false">Case Studies</a>
                                    </li>
                                </ul>
                            </div> */}
                        </div>
                    </div>
                </div>

                <div class="section-4 pad-class">
                    <div class="custom-container">
                        <div class="tab-content" id="pills-tabContent">
                            {this.renderContent()}
                        </div>
                    </div>
                </div>


                <div className="beige-bg">
                    <Testimonials />
                </div>

                <div className="section-5">
                    <div className="custom-container">
                        <div className="custom-section connect-with-custom-section">
                            <div className="main-head-div">
                                <h4 className="main-head-1">
                                    <span className="secondary-span">Want to see</span>
                                    <span className="primary-span">what we can do for you?</span>
                                </h4>
                            </div>
                            <div className="main-para-div">
                            <Link to={ROUTES.CONTACTUS} className="btn btn-primary connect-btn">Start the conversation</Link>
                            {/* <span onClick={this.sendToast.bind(this)} className="btn btn-primary connect-btn">Talk to us</span> */}
                            </div>
                        </div>
                    </div>
                </div>
                <ContactUS show={this.state.showContactUS} handleClose={()=>this.setState({showContactUS:false})} />
            </div>
            </>        
        );
    }

}


const mapStateToProps = (state, ownProps) => {
    return state.commonReducer;
  };
  
  const mapDispatchToProps = (dispatch, ownProps) => {
    return bindActionCreators(contactAction, dispatch);
  };
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(Portfolio);
