import {ROUTES} from "../../routes";


export const ResourceRoute = {
    BLOG: "https://blog.ionixxtech.com/",
    EVENTS: ROUTES.RESOURCES + '/events', //this will be by default
    PRESS: ROUTES.RESOURCES + '/press',
    BLOCKCHAIN: ROUTES.NEW_RESOURCES + '/blockchain-bulletin',
    NEWSLETTER: ROUTES.RESOURCES + '/newsletter',
    NEW_NEWSLETTER: ROUTES.NEW_RESOURCES + '/newsletter',
    NEWSISSUE86: ROUTES.NEW_RESOURCES + '/newsletter/news-issue-86',
    NEWSISSUE85: ROUTES.NEW_RESOURCES + '/newsletter/news-issue-85',
    NEWSISSUE84: ROUTES.NEW_RESOURCES + '/newsletter/news-issue-84',
    NEWSISSUE83: ROUTES.NEW_RESOURCES + '/newsletter/news-issue-83',
    NEWSISSUE82: ROUTES.NEW_RESOURCES + '/newsletter/news-issue-82',
    NEWSISSUE81: ROUTES.NEW_RESOURCES + '/newsletter/news-issue-81',
    NEWSISSUE80: ROUTES.NEW_RESOURCES + '/newsletter/news-issue-80',
    NEWSISSUE79: ROUTES.NEW_RESOURCES + '/newsletter/news-issue-79',
    NEWSISSUE78: ROUTES.NEW_RESOURCES + '/newsletter/news-issue-78',
    NEWSISSUE77: ROUTES.NEW_RESOURCES + '/newsletter/news-issue-77',
    NEWSISSUE76: ROUTES.NEW_RESOURCES + '/newsletter/news-issue-76',
    NEWSISSUE75: ROUTES.NEW_RESOURCES + '/newsletter/news-issue-75',
    NEWSISSUE74: ROUTES.NEW_RESOURCES + '/newsletter/news-issue-74',
    NEWSISSUE73: ROUTES.NEW_RESOURCES + '/newsletter/news-issue-73',
    NEWSISSUE72: ROUTES.NEW_RESOURCES + '/newsletter/news-issue-72',
    NEWSISSUE71: ROUTES.NEW_RESOURCES + '/newsletter/news-issue-71',
    NEWSISSUE70: ROUTES.NEW_RESOURCES + '/newsletter/news-issue-70',
    NEWSISSUE69: ROUTES.NEW_RESOURCES + '/newsletter/news-issue-69',
    NEWSISSUE68: ROUTES.NEW_RESOURCES + '/newsletter/news-issue-68',
    NEWSISSUE67: ROUTES.NEW_RESOURCES + '/newsletter/news-issue-67',
    NEWSISSUE66: ROUTES.NEW_RESOURCES + '/newsletter/news-issue-66',
    NEWSISSUE65: ROUTES.NEW_RESOURCES + '/newsletter/news-issue-65',
    NEWSISSUE64: ROUTES.NEW_RESOURCES + '/newsletter/news-issue-64',
    NEWSISSUE63: ROUTES.NEW_RESOURCES + '/newsletter/news-issue-63',
    NEWSISSUE62: ROUTES.NEW_RESOURCES + '/newsletter/news-issue-62',
    NEWSISSUE61: ROUTES.NEW_RESOURCES + '/newsletter/news-issue-61',
    NEWSISSUE60: ROUTES.NEW_RESOURCES + '/newsletter/news-issue-60',
    NEWSISSUE59: ROUTES.NEW_RESOURCES + '/newsletter/news-issue-59',
    NEWSISSUE58: ROUTES.NEW_RESOURCES + '/newsletter/news-issue-58',
    NEWSISSUE57: ROUTES.NEW_RESOURCES + '/newsletter/news-issue-57',
    NEWSISSUE56: ROUTES.NEW_RESOURCES + '/newsletter/news-issue-56',
    NEWSISSUE55: ROUTES.NEW_RESOURCES + '/newsletter/news-issue-55',
    NEWSISSUE54: ROUTES.NEW_RESOURCES + '/newsletter/news-issue-54',
    NEWSISSUE53: ROUTES.NEW_RESOURCES + '/newsletter/news-issue-53',
    NEWSISSUE52: ROUTES.NEW_RESOURCES + '/newsletter/news-issue-52',
    NEWSISSUE51: ROUTES.NEW_RESOURCES + '/newsletter/news-issue-51',
    NEWSISSUE50: ROUTES.NEW_RESOURCES + '/newsletter/news-issue-50',
    NEWSISSUE49: ROUTES.NEW_RESOURCES + '/newsletter/news-issue-49',
    NEWSISSUE48: ROUTES.NEW_RESOURCES + '/newsletter/news-issue-48',
    NEWSISSUE47: ROUTES.NEW_RESOURCES + '/newsletter/news-issue-47',
    NEWSISSUE46: ROUTES.NEW_RESOURCES + '/newsletter/news-issue-46',
    NEWSISSUE45: ROUTES.NEW_RESOURCES + '/newsletter/news-issue-45',
    NEWSISSUE44: ROUTES.NEW_RESOURCES + '/newsletter/news-issue-44',
    NEWSISSUE43: ROUTES.NEW_RESOURCES + '/newsletter/news-issue-43',
    NEWSISSUE42: ROUTES.NEW_RESOURCES + '/newsletter/news-issue-42',
    NEWSISSUE41: ROUTES.NEW_RESOURCES + '/newsletter/news-issue-41',
    NEWSISSUE40: ROUTES.NEW_RESOURCES + '/newsletter/news-issue-40',
    NEWSISSUE39: ROUTES.NEW_RESOURCES + '/newsletter/news-issue-39',
    NEWSISSUE38: ROUTES.NEW_RESOURCES + '/newsletter/news-issue-38',
    NEWSISSUE37: ROUTES.NEW_RESOURCES + '/newsletter/news-issue-37',
    NEWSISSUE36: ROUTES.NEW_RESOURCES + '/newsletter/news-issue-36',
    NEWSISSUE35: ROUTES.NEW_RESOURCES + '/newsletter/news-issue-35',
    NEWSISSUE34: ROUTES.NEW_RESOURCES + '/newsletter/news-issue-34',
    NEWSISSUE33: ROUTES.NEW_RESOURCES + '/newsletter/news-issue-33',
    NEWSISSUE32: ROUTES.NEW_RESOURCES + '/newsletter/news-issue-32',
    NEWSISSUE31: ROUTES.NEW_RESOURCES + '/newsletter/news-issue-31',
    NEWSISSUE30: ROUTES.NEW_RESOURCES + '/newsletter/news-issue-30',
    NEWSISSUE29: ROUTES.NEW_RESOURCES + '/newsletter/news-issue-29',
    NEWSISSUE28: ROUTES.NEW_RESOURCES + '/newsletter/news-issue-28',
    NEWSISSUE27: ROUTES.NEW_RESOURCES + '/newsletter/news-issue-27',
    NEWSISSUE26: ROUTES.NEW_RESOURCES + '/newsletter/news-issue-26',
    NEWSISSUE25: ROUTES.NEW_RESOURCES + '/newsletter/news-issue-25',
    NEWSISSUE24: ROUTES.NEW_RESOURCES + '/newsletter/news-issue-24',
    NEWSISSUE23: ROUTES.NEW_RESOURCES + '/newsletter/news-issue-23',
    NEWSISSUE22: ROUTES.NEW_RESOURCES + '/newsletter/news-issue-22',
    NEWSISSUE21: ROUTES.NEW_RESOURCES + '/newsletter/news-issue-21',
    NEWSISSUE20: ROUTES.NEW_RESOURCES + '/newsletter/news-issue-20',
    NEWSISSUE19: ROUTES.NEW_RESOURCES + '/newsletter/news-issue-19',
    NEWSISSUE18: ROUTES.NEW_RESOURCES + '/newsletter/news-issue-18',
    NEWSISSUE17: ROUTES.NEW_RESOURCES + '/newsletter/news-issue-17',
    NEWSISSUE16: ROUTES.NEW_RESOURCES + '/newsletter/news-issue-16',
    NEWSISSUE15: ROUTES.NEW_RESOURCES + '/newsletter/news-issue-15',
    NEWSISSUE14: ROUTES.NEW_RESOURCES + '/newsletter/news-issue-14',
    NEWSISSUE13: ROUTES.NEW_RESOURCES + '/newsletter/news-issue-13',
    NEWSISSUE12: ROUTES.NEW_RESOURCES + '/newsletter/news-issue-12',
    NEWSISSUE11: ROUTES.NEW_RESOURCES + '/newsletter/news-issue-11',
    NEWSISSUE10: ROUTES.NEW_RESOURCES + '/newsletter/news-issue-10',
    NEWSISSUE09: ROUTES.NEW_RESOURCES + '/newsletter/news-issue-09',
    NEWSISSUE08: ROUTES.NEW_RESOURCES + '/newsletter/news-issue-08',
    NEWSISSUE07: ROUTES.NEW_RESOURCES + '/newsletter/news-issue-07',
    NEWSISSUE06: ROUTES.NEW_RESOURCES + '/newsletter/news-issue-06',
    NEWSISSUE05: ROUTES.NEW_RESOURCES + '/newsletter/news-issue-05',
    NEWSISSUE04: ROUTES.NEW_RESOURCES + '/newsletter/news-issue-04',
    NEWSISSUE03: ROUTES.NEW_RESOURCES + '/newsletter/news-issue-03',
    NEWSISSUE02: ROUTES.NEW_RESOURCES + '/newsletter/news-issue-02',
    NEWSISSUE01: ROUTES.NEW_RESOURCES + '/newsletter/news-issue-01',
    WHITEPAPER: ROUTES.RESOURCES + '/whitepaper-ebooks',
    EVENTSNEWS_TAB: ROUTES.NEW_RESOURCES + ROUTES.EVENTSNEWS,
    WHITEPAPER_TAB: ROUTES.NEW_RESOURCES + ROUTES.WHITEPAPEREBOOK,
    NEWSLETTER_TAB: ROUTES.NEW_RESOURCES + ROUTES.NEWSLETTER,
    BlockChainContractsSmallContracts: ROUTES.NEW_RESOURCES + '/whitepaper-ebooks/blockchain-wallets-smart-contracts',
    BlockChainCryptoMining:ROUTES.NEW_RESOURCES + '/whitepaper-ebooks/blockchain-cryptography-mining',
    BlockChainLeagalImplication:ROUTES.NEW_RESOURCES + '/whitepaper-ebooks/blockchain-legal-implications',
    BlockChainFundamentals:ROUTES.NEW_RESOURCES + '/whitepaper-ebooks/blockchain-fundamentals',
    ChallengeSecurityBlockChain:ROUTES.NEW_RESOURCES + '/whitepaper-ebooks/challenges-security-blockchain',
    CryptoCurrenciesSoftwareProtocol:ROUTES.NEW_RESOURCES + '/whitepaper-ebooks/cryptocurrencies-software-protocols',
    FormationOfBlockChain: ROUTES.NEW_RESOURCES + '/whitepaper-ebooks/formation-of-blockchain',
    MobileAppDevelopmentApproach:ROUTES.NEW_RESOURCES + '/whitepaper-ebooks/Mobile-app-development-approach',
    STORY: ROUTES.RESOURCES + '/press/story',
    STORYSTRATO: ROUTES.RESOURCES + '/press/story-strato',
    PAPERSBOOKSDOWNLOAD: ROUTES.RESOURCES + '/whitepaper-ebooks/paper-books-download',
    BLOCKCHAINDETAILED: ROUTES.RESOURCES + '/blockchain/blockchain-detailed',
    FEBRUARYFORTNIGHT1: ROUTES.RESOURCES + '/blockchain/february-fortnight-1',
    JANUARYFORTNIGHT1: ROUTES.RESOURCES + '/blockchain/january-fortnight-1',
    JANUARYFORTNIGHT2: ROUTES.RESOURCES + '/blockchain/january-fortnight-2',
    DECEMBERFORTNIGHT1: ROUTES.RESOURCES + '/blockchain/december-fortnight-1',
    DECEMBERFORTNIGHT2: ROUTES.RESOURCES + '/blockchain/december-fortnight-2',
    NOVEMBERFORTNIGHT1: ROUTES.RESOURCES + '/blockchain/november-fortnight-1',
    NOVEMBERFORTNIGHT2: ROUTES.RESOURCES + '/blockchain/november-fortnight-2',
    OCTOBERFORTNIGHT2: ROUTES.RESOURCES + '/blockchain/october-fortnight-2',
    BLOCKCHAINGUIDE: ROUTES.NEW_RESOURCES + '/whitepaper-ebooks/blockchain-guide',
    DATAAGGREGATION: ROUTES.NEW_RESOURCES + '/whitepaper-ebooks/data-aggregation-platform-development',
    MULTIEXCHANGECRYPTOCURRENCY: ROUTES.NEW_RESOURCES + '/whitepaper-ebooks/multi-exchange-crypto-trading-platform-development',
};
