import React from "react";
import "../../resources.css";
import { ResourceRoute } from "../../resource-routing";
import { Link } from "react-router-dom";
import * as contactAction from "../../../../action/contactAction";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { ROUTES } from "../../../../routes";
class Story extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      emailErr: "",
    };
  }
  handleChange(e) {
    var val = e.target.value;
    val = val.replace(/^\s+/, "");
    this.setState({
      email: val,
    });
  }
  render() {
    return (
      <div className="Resources">
        <div className="detailed-news-common">
          <div className="detailed-news">
            <div className="back-to-common">
              <span className="back-to-icon-hold">
                <img
                  src="/assets/img/resource/back-to-new.svg"
                  alt="back to new"
                ></img>
              </span>
              <Link to={ResourceRoute.PRESS}>
                <span className="back-to-link">Press and Media</span>
              </Link>
            </div>
            <div className="detailed-news-main-info">
              <h4 className="">
                Manju Mohan, CEO & Co-Founder accepted into Forbes Business
                Council
              </h4>
              <p>
                <span>Baskar Natarajan,</span> <span>13 Aug 2020</span>
              </p>
            </div>
            <div className="detialed-news-sub-info">
              <h4 className="news-sub-head">
                Forbes Business Council Is an Invitation-Only Community for
                Successful Business Owners and Leaders
              </h4>
              <p className="full-new-content">
                Manju Mohan, CEO & Co-Founder, Ionixx Technologies Inc. has been
                accepted into the Forbes Business Council, the foremost growth
                and networking organization for successful business owners and
                leaders worldwide.
              </p>
              <p className="full-new-content">
                Manju’s profile was vetted and selected by a review committee
                based on the depth and diversity of her experience. Criteria for
                acceptance include a track record of successfully impacting
                business growth metrics, as well as personal and professional
                achievements and honors.{" "}
              </p>
              <p className="full-new-content">
                “We are honored to welcome Manju into the community,” said Scott
                Gerber, Founder of Forbes Councils, the collective that includes
                Forbes Business Council. “Our mission with Forbes Councils is to
                bring together proven leaders from every industry, creating a
                curated, social capital-driven network that helps every member
                grow professionally and make an even greater impact on the
                business world.”
              </p>
              <p className="full-new-content">
                As an accepted member of the Council, Manju has access to a
                variety of exclusive opportunities designed to help her reach
                peak professional influence. She will connect and collaborate
                with other respected local leaders in a private forum and at
                members-only events, able to share expert opinions on various
                business insights on Forbes.com and also benefit from exclusive
                access to a number of business service partners and high touch
                support of the Forbes Councils concierge team.
              </p>
              <p className="full-new-content">
                Announcing the membership, Manju Mohan, CEO & Co-Founder, Ionixx
                Technologies Inc., said, “I am honored to be part of this robust
                network by Forbes. Excited to engage with leaders and inculcate
                new business models in this fast-emerging tech revolution era.”{" "}
              </p>
            </div>
            <div className="detialed-news-sub-info">
              <h4 className="news-sub-head">About Forbes Councils:</h4>
              <p className="full-new-content">
                Forbes Councils is a collective of invitation-only communities
                created in partnership with Forbes and the expert community
                builders who founded Young Entrepreneur Council (YEC). In Forbes
                Councils, exceptional business owners and leaders come together
                with the people and resources that can help them thrive. To
                learn more about Forbes Councils, visit forbescouncils.com.{" "}
              </p>
            </div>
          </div>
          <div className="news-floating-sidebar">
            <div className="stories-card card">
              <div className="stories-card-body card-body">
                <h4>More Stories</h4>
                <ul className="stories-ul">
                  <Link to={ResourceRoute.STORYSTRATO}>
                    <li>
                      <div>
                        <span className="story-type story-orange-bg">
                          BLOCKCHAIN
                        </span>
                        <p className="story-desc">
                          BlockApps and Ionixx Technologies announce partnership
                          to build successful blockchain applications for
                          businesses through STRATO Network
                        </p>
                      </div>
                    </li>
                  </Link>
                  {/* <li>
                                        <div>
                                            <span className="story-type story-green-bg">BUSINESS</span>
                                            <p className="story-desc">Tips to Organize Your Small Business During the
                                                COVID-19 Pandemic</p>
                                        </div>
                                    </li> */}
                </ul>
              </div>
            </div>
            <div className="email-signup-common">
              <h4>Newsletter</h4>
              <p className="signup-head">Sign up for Ionixx emails</p>
              <div className="form-group newsletter-form-group">
                <input
                  type="text"
                  className="form-control"
                  id=""
                  placeholder="Your email"
                  name={"email"}
                  value={this.state.email}
                  onChange={this.handleChange.bind(this)}
                ></input>
                <div
                  className="newsletter-arrow"
                  onClick={() => {
                    var emailRegx =
                      /^(([a-zA-Z\d-_.]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                        this.state.email
                      );
                    if (emailRegx === false) {
                      this.setState({
                        emailErr: "Invalid Email",
                      });
                    } else {
                      this.props
                        .subscribeForNewsLetter({
                          emailID: this.state.email,
                          name: this.state.name,
                          category: "",
                          url: this.props.location.pathname,
                        })
                        .then((res) => {})
                        .catch((err) => {
                          console.log(err);
                        });
                      this.props
                        .sendNewsFeed(this.state.email)
                        .then((res) => {
                          this.setState({
                            email: "",
                            emailErr: "",
                          });
                          if (res.status === 200) {
                            this.props.history.push({
                              pathname: ROUTES.TOAST,
                              contactDetails: false,
                              subscribeDetails: true,
                              pdfDownload: false,
                            });
                          }
                        })
                        .catch((err) => {
                          console.log(err);
                        });
                    }
                  }}
                >
                  <span>
                    <svg
                      width="17"
                      height="16"
                      viewBox="0 0 17 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1 7C0.447715 7 0 7.44772 0 8C0 8.55228 0.447715 9 1 9V7ZM16.7071 8.70711C17.0976 8.31658 17.0976 7.68342 16.7071 7.29289L10.3431 0.928932C9.95262 0.538408 9.31946 0.538408 8.92893 0.928932C8.53841 1.31946 8.53841 1.95262 8.92893 2.34315L14.5858 8L8.92893 13.6569C8.53841 14.0474 8.53841 14.6805 8.92893 15.0711C9.31946 15.4616 9.95262 15.4616 10.3431 15.0711L16.7071 8.70711ZM1 9H16V7H1V9Z"
                        fill="#1E4B5E"
                      />
                    </svg>
                  </span>
                </div>
              </div>
              {this.state.emailErr.length > 0 ? (
                <div className="err-msg">
                  <span>{this.state.emailErr}</span>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  return state.commonReducer;
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return bindActionCreators(contactAction, dispatch);
};
export default connect(mapStateToProps, mapDispatchToProps)(Story);
